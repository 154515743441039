import React, { useState } from "react";
import PropTypes from "prop-types";

import { promisify } from "../../../utilities";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbtc from "../../GB/GBTableConst";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";

/**
 * @typedef {object} PIConstraintsTotalCostTableProps
 * @property {Array<object>} modVarObjList
 * @property {() => void} onModVarsChange
 * @property {string} tableKey
 * @property {number} activeTable
 * @property {(table: number) => void} onTableChanged
 */

/**
 * @param {PIConstraintsTotalCostTableProps} props
 * @returns
 */
export default function PIConstraintsTotalCostTable({
  modVarObjList,
  onModVarsChange: onModVarsChangeOrig,
  tableKey,
  activeTable,
  onTableChanged,
}) {
  const onModVarsChange = promisify(onModVarsChangeOrig);

  const [tableRDecs, setTableRDecs] = useState([]);

  /** @type {number} */
  const totalCost = piasu.getModVarValue(modVarObjList, "PI_TargetTotalCostConstraint") ?? 0.0;

  const hiddenCol = 0;
  const costCol = 1;
  const numCols = costCol + 1;

  const firstRow = 0;
  const costRow = 1;
  const numRows = costRow + 1;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  gbtu.setValue(packTable, firstRow, costCol, RS(SC.GB_stTotalCost) + " (" + RS(SC.GB_stUSD) + ")");

  gbtu.setValue(packTable, costRow, costCol, totalCost);

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
  gbtu.setRowHeight(packTable, firstRow, 50);
  gbtu.setColWidth(packTable, hiddenCol, 0);
  gbtu.setColWidth(packTable, costCol, Theme.dataColWidthLarge);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);

  gbtu.restoreRDecsFromCopy(packTable, tableRDecs);

  const onPackTableChange = async (newPackTable) => {
    const newModVarObjList = structuredClone(modVarObjList);
    piasu.setModVarValue(
      newModVarObjList,
      "PI_TargetTotalCostConstraint",
      gbtu.getValue(newPackTable, costRow, costCol)
    );
    await onModVarsChange(newModVarObjList, false);

    setTableRDecs(structuredClone(packTable.RDec));
  };

  return (
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      key={"table"}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={[
        ["s", "s"],
        ["s", "n"],
      ]}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        tableFont: Theme.tableFont,
        padding: 0,
      }}
      activeContextMenuItems={["copy", "copy-all", "copy-to-json"]}
      undoDisabled={false}
    />
  );
}

PIConstraintsTotalCostTable.propTypes = {
  modVarObjList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onModVarsChange: PropTypes.func,
  tableKey: PropTypes.string,
  activeTable: PropTypes.number,
  onTableChanged: PropTypes.func,
};

PIConstraintsTotalCostTable.defaultProps = {
  onModVarsChange: () => {},
  tableKey: "",
  activeTable: 1,
  onTableChanged: () => {},
};
