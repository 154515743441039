import React from "react";
import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";
import { useState, useEffect } from "react";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as gbtu from "../../GB/GBTableUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as pitu from "../NonComponents/PITableUtil";
import { onCalculateAsync } from "../NonComponents/PICalc";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import { combinePriorPops, disaggregatePriorPops, contCurveCombinedPops } from "../NonComponents/PIPriorPopUtil";

const PIImpactTable = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);
  const onCalculatingChange = promisify(props.onCalculatingChange);

  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  const selectedMethod = piasu.getModVarValue(modVarObjList, "PI_SelectedMethod");
  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
  const selectedMethodIdx = methods.findIndex((v) => v.mstID === selectedMethod);

  // const priorPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  // const adjFactors = piasu.getModVarValue(modVarObjList, "PI_AdjustmentFactor");
  // const eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  let priorPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let adjFactors = piasu.getModVarValue(modVarObjList, "PI_AdjustmentFactor");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");
  let infAverted = piasu.getModVarValue(modVarObjList, "PI_InfAvertPerPerson_CONSTANT_COVERAGE");
  let toAvertOne = piasu.getModVarValue(modVarObjList, "PI_ToAvert1Infection_CONSTANT_COVERAGE");

  if (isPSEMode) {
    const combined = combinePriorPops(modVarObjList, contCurveCombinedPops, [
      "PI_Eligibility",
      "PI_AdjustmentFactor",
      "PI_InfAvertPerPerson_PSE",
      "PI_ToAvert1Infection_PSE",
    ]);

    priorPops = combined.PI_PriorityPop;
    adjFactors = combined.PI_AdjustmentFactor;
    eligibility = combined.PI_Eligibility;
    infAverted = combined.PI_InfAvertPerPerson_PSE;
    toAvertOne = combined.PI_ToAvert1Infection_PSE;
  }

  // These are arrays of method[pop[]]
  // const impactBaseDef = piasu.getModVarValue(modVarObjList, "PI_ImpactBaseDef_PSE");
  // const infAverted = piasu.getModVarValue(modVarObjList, "PI_InfAvertPerPerson_PSE");
  // const toAvertOne = piasu.getModVarValue(modVarObjList, "PI_ToAvert1Infection_PSE");

  const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));

  const impactFactorOffset = 0;
  const adjFactorOffset = impactFactorOffset + (props.showAdjFactor ? 1 : 0);
  const infAvertedOffset = adjFactorOffset + 1;
  const pyOnPrepOffset = infAvertedOffset + 1;

  const numColsPerCat = pyOnPrepOffset + 1;

  const numRows = priorPops.length + 1;
  // Pop name, 3x(Default factor, Adj Factor, Adjusted, Adjusted PY)
  const numCols = 1 + numColsPerCat;

  const firstCol = 1;

  // State
  const [rDec, setRDecs] = useState([]);

  // Reset RDecs on method change, otherwise pops that were ineligible under the previous
  // method but now are eligible will have incorrect RDecs.
  useEffect(() => {
    setRDecs([]);
  }, [selectedMethod]);

  // if (!isPSEMode) {
  //   return <div>PSEImpactTable requires PSE mode</div>;
  // }

  // Callbacks
  const onPackTableChange = async (newPackTable) => {
    // Update RDecs
    setRDecs(structuredClone(newPackTable.RDec));

    if (!props.showAdjFactor) return;

    // Update adj factors

    let newAdjFactors = structuredClone(adjFactors);
    const methodFactors = newAdjFactors[selectedMethodIdx]?.factors;

    for (let pp = 1; pp <= priorPops.length; ++pp) {
      if (piasu.getPriorPopMethodElig(eligibility, selectedMethod, pp) !== pisc.yesMstID) continue;

      const value = gbtu.getValue(newPackTable, pp, firstCol + adjFactorOffset);

      // newAdjFactors[newMethodAdjIdx].factors[pp][categories[i][0]] = value;
      methodFactors[pp - 1] = value;
    }

    const modVarObjListClone = structuredClone(modVarObjList);

    if (isPSEMode) {
      const newData = {
        PI_PriorityPop: priorPops,
        PI_AdjustmentFactor: newAdjFactors,
      };

      const disagg = disaggregatePriorPops(modVarObjList, newData, contCurveCombinedPops);
      newAdjFactors = disagg.PI_AdjustmentFactor;
    }

    piasu.setModVarValue(modVarObjListClone, "PI_AdjustmentFactor", newAdjFactors);

    try {
      await onCalculatingChange(true);
      await onModVarsChange(modVarObjListClone, false);
      const newModVars = await onCalculateAsync(modVarObjListClone, "", props.onDialogChange);
      await onModVarsChange(newModVars, false);
    } catch (err) {
      console.log(err);
    } finally {
      await onCalculatingChange(false);
    }
  };

  // Table
  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  // Headings
  gbtu.setValue(packTable, 0, 0, RS(SC.GB_stPriorityPop));

  gbtu.setValue(packTable, 0, firstCol + impactFactorOffset, RS(SC.GB_stImpactFactor));
  if (props.showAdjFactor) gbtu.setValue(packTable, 0, firstCol + adjFactorOffset, RS(SC.GB_stAdjustFactor));
  gbtu.setValue(packTable, 0, firstCol + infAvertedOffset, RS(SC.GB_stAdjInfAvtdPerPYPrEP));
  gbtu.setValue(packTable, 0, firstCol + pyOnPrepOffset, RS(SC.GB_stAdjPYOnPrEPPerInfAvtd));

  // Table Data
  const curAdjFactors = adjFactors[selectedMethodIdx]?.factors;
  for (let pp = 1; pp <= priorPops.length; ++pp) {
    const pop = priorPops[pp - 1];
    const factors = curAdjFactors[pp - 1];

    gbtu.setValue(packTable, pp, 0, pop.name);

    if (piasu.getPriorPopMethodElig(eligibility, selectedMethod, pp) === pisc.yesMstID) {
      const impactKey = isPSEMode ? "impact_pse" : "impact_constant";
      const impact = pop?.[impactKey]?.[selectedMethodIdx].defaults ?? 0.0;

      gbtu.setValue(packTable, pp, firstCol + impactFactorOffset, impact);
      if (props.showAdjFactor) gbtu.setValue(packTable, pp, firstCol + adjFactorOffset, factors);

      const adjInfAverted = infAverted[selectedMethodIdx][pp - 1];
      gbtu.setValue(packTable, pp, firstCol + infAvertedOffset, adjInfAverted);

      const adjToAvertOne = toAvertOne[selectedMethodIdx][pp - 1];
      gbtu.setValue(packTable, pp, firstCol + pyOnPrepOffset, adjToAvertOne);

      gbtu.lockCol(packTable, firstCol + impactFactorOffset, true);
      gbtu.lockCol(packTable, firstCol + infAvertedOffset, true);
      gbtu.lockCol(packTable, firstCol + pyOnPrepOffset, true);
    } else {
      gbtu.lockCells(packTable, pp, true, true);
      gbtu.setRowBGColor(packTable, pp, gainsboroBase10);
    }
  }

  // Settings
  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setColWidths(packTable, Theme.dataColWidthMed);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
  gbtu.setWordWrappedCol(packTable, 0, true);

  gbtu.setRDecs(packTable, 1);
  if (rDec.length === 0) {
    gbtu.setRDecByCol(packTable, firstCol + impactFactorOffset, 4);
    if (props.showAdjFactor) gbtu.setRDecByCol(packTable, firstCol + adjFactorOffset, 1);
    gbtu.setRDecByCol(packTable, firstCol + infAvertedOffset, 4);
    gbtu.setRDecByCol(packTable, firstCol + pyOnPrepOffset, 0);
  } else {
    for (let r = 0; r < rDec.length; r++) {
      for (let c = 0; c < rDec[r].length; c++) {
        gbtu.setRDec(packTable, r, c, rDec[r][c]);
      }
    }
  }

  return (
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      gridKey={props.tableKey}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PIImpactTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  tableKey: PropTypes.string,
  showAdjFactor: PropTypes.bool,
};

PIImpactTable.defaultProps = {
  tableKey: "",
  onModVarsChange: () => {},
  onCalculatingChange: () => {},
  onDialogChange: () => {},
};

export default PIImpactTable;
