import * as piasu from "./PIAppStateUtil";
import * as pias from "./PIAppState";
import * as pip from "./PIProps";
import * as pisc from "./PIServerConst";
import * as pic from "./PIConst";
import * as piu from "./PIUtil";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import { onCalculate } from "./PICalc";

export function onTimePeriodChange(
  timePeriodByte,
  monthOrYearInt,
  startOrEndInt,
  modVarObjArr,
  onModVarsChange,
  onCalculatingChange,
  onDialogChange
) {
  let modVarObjArrClone = structuredClone(modVarObjArr);

  piasu.setTimePeriods(modVarObjArrClone, timePeriodByte, monthOrYearInt, startOrEndInt);

  if (typeof onCalculatingChange !== "undefined") {
    onCalculatingChange(true, () => {
      onModVarsChange(modVarObjArrClone, true, () => {
        onCalculate(
          modVarObjArrClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false);
            });
          },
          () => onCalculatingChange(false)
        );
      });
    });
  } else {
    onModVarsChange(modVarObjArrClone, false);
  }
}

/* We only want to validate the time period, set it to the ModVars, and potentially run calculations if the user
  clicks a 'Set period' button now.

  timePeriodObjs: Object containing one or more time period objects. These should be local to components (forms) and
  not the ones we will pull directly from app state via modVarObjArr.

  forcedTimePeriod: If we are using this method to just force constraints and do not need to change any
  time period objects kept in local state (which is the primary ussage of this method), set this value to the
  time period whose constraint logic we want to run.

  PDP: Program Data Period
  TSP: Target Setting Period
  DRD: Date Range for Display

  returns final PDP, TSP, DRD period objects as successFn arg.
*/
export function onSetTimePeriod(
  localTimePeriodObjs,
  forcedTimePeriod = undefined,
  uploadingProgDataTemplateBool,
  modVarObjArr,
  origModVarObjArr,
  onModVarsChange,
  onCalculatingChange,
  onDialogChange,
  successFn
) {
  function _showErrorDialog(message) {
    let dialogObj = pias.getDefaultDialogObj();
    dialogObj[pias.contentStr] = message;
    dialogObj[pias.headerStr] = RS(SC.GB_stError);
    dialogObj[pias.maxWidthStr] = "sm";
    dialogObj[pias.showBool] = true;
    dialogObj[pias.styleObj] = { width: 500 };

    onDialogChange(dialogObj);
  }

  const _changeModVars = (modVarObjArr, successFn) => {
    onModVarsChange(modVarObjArr, false, () => {
      onCalculatingChange(true, () => {
        // Put this here because after the template is uploaded, the user needs to see the graph under it update.
        onCalculate(
          modVarObjArr,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false, () => {
                const newTimePeriodObjs = {
                  [pip.progDataPeriodObj]: piasu.getProgDataPeriodObj(response),
                  [pip.targSettingPeriodObj]: piasu.getTargSettingPeriodObj(response),
                  [pip.dateRangeDisplayObj]: piasu.getDateRangeDisplayObj(response),
                };

                successFn?.(newTimePeriodObjs);
              });
            });
          },
          () => onCalculatingChange(false)
        );
      });
    });
  };

  const progDataUploadedBool = piasu.getModVarValue(modVarObjArr, pisc.progDataTemplateUploadedMVTag);

  // Program Data Period (PDP)
  const getPDPFromModVar = typeof localTimePeriodObjs[pip.progDataPeriodObj] === "undefined";

  const pdpDates = piu.getDateObjectAsJSDates(
    getPDPFromModVar ? piasu.getProgDataPeriodObj(modVarObjArr) : localTimePeriodObjs[pip.progDataPeriodObj]
  );

  // Target-setting Period (TSP)
  const getTSPFromModVar = typeof localTimePeriodObjs[pip.targSettingPeriodObj] === "undefined";

  const tspDates = piu.getDateObjectAsJSDates(
    getTSPFromModVar ? piasu.getTargSettingPeriodObj(modVarObjArr) : localTimePeriodObjs[pip.targSettingPeriodObj]
  );

  // Date Range for Display (DRD)
  const getDRDFromModVar = typeof localTimePeriodObjs[pip.dateRangeDisplayObj] === "undefined";

  const drdDates = piu.getDateObjectAsJSDates(
    getDRDFromModVar ? piasu.getDateRangeDisplayObj(modVarObjArr) : localTimePeriodObjs[pip.dateRangeDisplayObj]
  );

  //**********************   Logic   ***********************

  const userChangingPDP = !getPDPFromModVar;
  const userChangingTSP = !getTSPFromModVar && getPDPFromModVar;
  const userChangingDRD = !getDRDFromModVar && getTSPFromModVar;

  const tspStartUpperLimit = new Date(pdpDates.end);
  tspStartUpperLimit.setMonth(pdpDates.end.getMonth() + 5);

  if (userChangingPDP && pdpDates.start > pdpDates.end) _showErrorDialog(RS(SC.GB_steStartMonthLaterError));
  else if (userChangingTSP && progDataUploadedBool && tspDates.start > tspStartUpperLimit)
    _showErrorDialog(RS(SC.GB_steTargSetPeriodFiveMonthsError));
  else if (userChangingTSP && tspDates.start > tspDates.end) _showErrorDialog(RS(SC.GB_steEndMonthGreaterStart));
  else if (userChangingTSP && tspDates.start <= pdpDates.start) _showErrorDialog(RS(SC.GB_stTSPStartAfterPDPStart));
  else if (userChangingDRD && (drdDates.start < tspDates.start || drdDates.end > tspDates.end))
    _showErrorDialog(RS(SC.GB_stRangeNotInTargSetPeriod));
  else if (userChangingDRD && drdDates.start > drdDates.end) _showErrorDialog(RS(SC.GB_steStartMonthLaterError));
  else {
    if (userChangingPDP || forcedTimePeriod === pic.progDataPeriod) {
      // The program data period (PDP) controls the target-setting period (TSP), which controls the data range
      // for display (DRD).
      //
      // When the PDP changes, change the TSP so that it starts one month after the PDP ends and ends 24 months
      // after the TSP starts. Then change the DRD to be the same as the TSP.
      tspDates.start = new Date(pdpDates.end);
      tspDates.end = new Date(pdpDates.end);
      tspDates.start.setMonth(tspDates.start.getMonth() + 1);
      tspDates.end.setMonth(tspDates.end.getMonth() + 24);

      const revisedTSPObj = piu.getJSDatesAsDateObject(tspDates);
      const revisedDRDObj = structuredClone(revisedTSPObj);

      piasu.setProgDataPeriodObj(modVarObjArr, piu.getJSDatesAsDateObject(pdpDates));
      piasu.setTargSettingPeriodObj(modVarObjArr, revisedTSPObj);
      piasu.setDateRangeDisplayObj(modVarObjArr, revisedDRDObj);

      if (!uploadingProgDataTemplateBool) {
        piasu.shiftProgDataYears(modVarObjArr, origModVarObjArr);
      }

      _changeModVars(modVarObjArr, successFn);
    } else if (userChangingTSP || forcedTimePeriod === pic.targSetPeriod) {
      // PDP start/end must be earlier than TSP start
      if (pdpDates.start >= tspDates.start) {
        pdpDates.start = new Date(tspDates.start);
        pdpDates.start.setMonth(pdpDates.start.getMonth() - 1);
      }
      if (pdpDates.end >= tspDates.start) {
        pdpDates.end = new Date(tspDates.start);
        pdpDates.end.setMonth(pdpDates.end.getMonth() - 1);
      }

      // Any change to the TSP should cause the DRD to be the same as the TSP
      const revisedPDPObj = piu.getJSDatesAsDateObject(pdpDates);
      const revisedDRDObj = piu.getJSDatesAsDateObject(tspDates);
      piasu.setProgDataPeriodObj(modVarObjArr, revisedPDPObj);
      piasu.setTargSettingPeriodObj(modVarObjArr, piu.getJSDatesAsDateObject(tspDates));
      piasu.setDateRangeDisplayObj(modVarObjArr, revisedDRDObj);

      // Just in case PDP shifted into a new data range or requires a new upload
      piasu.shiftProgDataYears(modVarObjArr, origModVarObjArr);

      // Ensure all method start offsets are within the new TSP
      const tspSize = piu.getMonthsBetweenDates(tspDates.start, tspDates.end);
      const methods = piasu.getModVarValue(modVarObjArr, pisc.methodsMVTag);
      for (const method of methods) {
        method.start_month_offset = Math.min(method.start_month_offset, tspSize);
      }

      _changeModVars(modVarObjArr, successFn);
    } else if (userChangingDRD || forcedTimePeriod === pic.dateRangeDisplayPeriod) {
      piasu.setDateRangeDisplayObj(modVarObjArr, piu.getJSDatesAsDateObject(drdDates));

      _changeModVars(modVarObjArr, successFn);
    }
  }
}
