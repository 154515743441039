/**
 * Common helper functionality that applies to several types of modvars
 */
import * as pisc from "../PIServerConst";

export function getModVarObj(modVarObjList, modVarTagStr) {
  let modVarObj = {};

  let stop = false;
  let mv = 0;
  while (mv < modVarObjList.length && !stop) {
    let mvObj = modVarObjList[mv];

    if (mvObj[pisc.mvTag] === modVarTagStr) {
      modVarObj = mvObj;
      stop = true;
    }

    mv++;
  }

  return modVarObj;
}

export function getModVarExists(modVarObjList, modVarTagStr) {
  let modVarExists = false;

  let mv = 0;
  while (mv < modVarObjList.length && !modVarExists) {
    let mvObj = modVarObjList[mv];

    if (mvObj[pisc.mvTag] === modVarTagStr) {
      modVarExists = true;
    }

    mv++;
  }

  return modVarExists;
}

export function getModVarIdx(modVarObjList, modVarTagStr) {
  let idx = -1;

  let mv = 0;
  while (mv < modVarObjList.length && idx === -1) {
    let mvObj = modVarObjList[mv];

    if (mvObj[pisc.mvTag] === modVarTagStr) {
      idx = mv;
    }

    mv++;
  }

  return idx;
}

export function getModVarValue(modVarObjList, modVarTagStr, returnListAggBool = false) {
  let value;

  /* ModVars will look different in Aggregate mode. We need to adjust how we
       get ModVar values depending on the mode, since the value field will change. */

  let modVarObj = getModVarObj(modVarObjList, modVarTagStr);

  /* This one should get hit for standard and easy start mode always. */
  if (typeof modVarObj[pisc.mvValue] !== "undefined") {
    value = modVarObj[pisc.mvValue];
  } else if (typeof modVarObj[pisc.mvSumAG] !== "undefined") {
    /* The next two will usually be the ones to get hit for Aggregate mode. */
    value = modVarObj[pisc.mvSumAG];
  } else if (typeof modVarObj[pisc.mvListAG] !== "undefined") {
    /* The server returns most things in aggregate mode as a list rather than
             a single value, even though many things don't make sense like that. Therefore,
             only return a list if we request the list. */
    if (returnListAggBool) {
      value = modVarObj[pisc.mvListAG];
    } else {
      value = modVarObj[pisc.mvListAG][0];
    }
  }

  return value;
}

export function setModVarValue(modVarObjList, modVarTagStr, value) {
  // /* ModVars will look different in Aggregate mode. We need to adjust how we
  //    set ModVar values depending on the mode, since the value field will change. */

  let modVarObj = getModVarObj(modVarObjList, modVarTagStr);

  /* This one should get hit for standard and easy start mode always. */
  if (typeof modVarObj[pisc.mvValue] !== "undefined") {
    modVarObj[pisc.mvValue] = value;
  } else if (typeof modVarObj[pisc.mvSumAG] !== "undefined") {
    /* The next two will usually be the ones to get hit for Aggregate mode. */
    modVarObj[pisc.mvSumAG] = value;
  } else if (typeof modVarObj[pisc.mvListAG] !== "undefined") {
    modVarObj[pisc.mvListAG][0] = value;
  }
}

export function addModVarObj(modVarObjList, value) {
  modVarObjList.push(value);
}

export function deleteModVar(modVarObjArr, tagStr) {
  let mv = 0;
  let foundModVar = false;
  while (mv < modVarObjArr.length && !foundModVar) {
    const modVar = modVarObjArr[mv];
    const modVarTag = modVar[pisc.mvTag];

    if (modVarTag === tagStr) {
      modVarObjArr.splice(mv, 1);
      foundModVar = true;
    }

    mv++;
  }
}

export function addNewModVars(startingModVarObjArr, newModVarObjArr, replaceExistingModVars = false) {
  /* After calculating, some result ModVars may have been added that did not exist before.
         If the original ModVar array does not have them, we need to add them. This should only
         happen once after each type of calculation. */
  for (let mv = 0; mv < newModVarObjArr.length; mv++) {
    const modVar = newModVarObjArr[mv];
    const modVarTag = modVar[pisc.mvTag];

    if (!getModVarExists(startingModVarObjArr, modVarTag)) {
      addModVarObj(startingModVarObjArr, modVar);
    } else {
      if (replaceExistingModVars) {
        const modVarIdx = getModVarIdx(startingModVarObjArr, modVarTag);
        startingModVarObjArr[modVarIdx] = modVar;
      }
    }
  }
}
