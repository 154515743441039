import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";
import PISelectDate from "../Other/PISelectDate";

import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";
// import { onCalculate } from "../NonComponents/PICalc";

import {
  PICustomRowTableProps,
  firstRow,
  hiddenColOffset,
  sharedPropTypes,
  sharedDefaultProps,
  initialState,
  getDeleteBtnCol,
  getNumCols,
  AddButton,
  DeleteButton,
  InformationTooltip,
  handleCustomItemCalculate,
} from "../Other/PICustomRowsUtil";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

/**
 * @typedef {import('../NonComponents/PIUtil').AvenirDateRangeObject} AvenirDateRangeObject
 */

const itemNameColumn = hiddenColOffset;
const unitOfCommodityColumn = itemNameColumn + 1;
const monthsCoverageColumn = unitOfCommodityColumn + 1;
const unitCostColumn = monthsCoverageColumn + 1;
const informationColumn = unitCostColumn + 1;
const effectivenessColumn = informationColumn + 1;
const startDateColumn = effectivenessColumn + 1;
const lastColumn = startDateColumn;

export class PIMethodUnitsTable extends Component {
  static propTypes = {
    ...sharedPropTypes,
  };

  static defaultProps = {
    ...sharedDefaultProps,

    [pip.tableKey]: this.props?.[pip.tableKey] ?? "",
  };

  state = initialState;

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];
      const modVarObjListClone = structuredClone(props[pias.modVarObjList]);

      const objList = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const numObjects = piasu.getTotalNumMethods(objList);

      const impactEffectiveness = piasu.getModVarValue(modVarObjListClone, "PI_ImpactEffectiveness");

      const priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      const costCatObjList = piasu.getModVarValue(modVarObjListClone, pisc.costCategoriesLiteMVTag);

      for (let row = 1; row <= numObjects; row++) {
        const methodMstID = piasu.methodMstID(objList, row);

        /* Users can change the name in the first visible column now. */
        const itemName = gbtu.getValue(newPackTable, row, itemNameColumn);
        piasu.setMethodName(objList, row, itemName);

        const unitOfCommodityStr = gbtu.getValue(newPackTable, row, unitOfCommodityColumn);
        piasu.methodUnitComm(objList, row, unitOfCommodityStr);

        const monthsCovPerUnitInt = gbtu.getValue(newPackTable, row, monthsCoverageColumn);
        piasu.monthsCovPerUnit(objList, row, monthsCovPerUnitInt);

        // Get the old unit cost first because we only want to change the value set
        // by setCostPerPersMonthCostsLite if the user actually changed the method unit cost.
        const unitCostFltOld = piasu.methodUnitCost(objList, row);
        const unitCostFltNew = gbtu.getValue(newPackTable, row, unitCostColumn);
        piasu.methodUnitCost(objList, row, unitCostFltNew);

        if (!gbu.equal(unitCostFltOld, unitCostFltNew)) {
          for (let pp = 1; pp <= numPriorPops; pp++) {
            piasu.setARVCostLite(methodMstID, costCatObjList, pp, unitCostFltNew);

            const adhereSupportCost = piasu.getAdhereSupportCostLite(methodMstID, costCatObjList, pp);

            piasu.setCostPerPersMonthCostsLite(methodMstID, costCatObjList, pp, unitCostFltNew + adhereSupportCost);
          }
        }

        const effectiveness = gbtu.getValue(newPackTable, row, effectivenessColumn);
        piasu.setImpactEffectiveness(impactEffectiveness, row, effectiveness);
      }

      this.setState({ [pip.rDec]: newPackTable[gbtc.rDec] }, async () =>
        handleCustomItemCalculate(onCalculatingChange, onModVarsChange, modVarObjListClone, onDialogChange)
      );
    } catch (exception) {
      alert(exception.name + " (PIMethodUnitsTable.onPackTableChange) : " + exception.message);
    }
  };

  /**
   *
   * @param {AvenirDateRangeObject} date New date
   * @param {number} row Row index
   */
  onDateChange = (date, row) => {
    const onDialogChange = this.props[pias.onDialogChange];
    const onCalculatingChange = this.props[pias.onCalculatingChange];
    const onModVarsChange = this.props[pias.onModVarsChange];
    const modVarObjListClone = structuredClone(this.props[pias.modVarObjList]);

    const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjListClone);
    const offset =
      piu.getMonthsBetween(
        targSettingPeriodObj.startMonthInt,
        targSettingPeriodObj.startYearInt,
        date.month,
        date.year
      ) - 1;

    console.log(`Date changed to ${date.month}/${date.year} for row ${row} (${offset} months into TSP)`);

    const methods = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
    methods[row - 1].start_month_offset = offset;

    handleCustomItemCalculate(onCalculatingChange, onModVarsChange, modVarObjListClone, onDialogChange);
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const allowEditsBool = props[PICustomRowTableProps.allowEditsBoolC];
      const tableKey = this.state[pip.tableKey];

      const state = this.state;
      const focusedCell = state[pip.focusedCell];
      const selectedRegions = state[pip.selectedRegions];

      const methods = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const numObjects = piasu.getTotalNumMethods(methods);

      const impactEffectiveness = piasu.getModVarValue(modVarObjList, "PI_ImpactEffectiveness");

      const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);
      const tspDates = piu.getDateObjectAsJSDates(targSettingPeriodObj);

      const deleteBtnCol = getDeleteBtnCol(this.props, lastColumn);
      const numCols = getNumCols(this.props, lastColumn);

      const numRows = numObjects + 1;

      let packTable = gbtu.getNewPackTable();

      let frameworkComponents = allowEditsBool ? {} : null;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, itemNameColumn, RS(SC.GB_stMethod));
      gbtu.setValue(packTable, firstRow, unitOfCommodityColumn, RS(SC.GB_stUnitOfCommodity));
      gbtu.setValue(packTable, firstRow, monthsCoverageColumn, RS(SC.GB_stNumMonthsCovPerUnit));
      gbtu.setValue(packTable, firstRow, unitCostColumn, RS(SC.GB_stUnitCost) + " (" + RS(SC.GB_stUSD) + ")");
      gbtu.setValue(packTable, firstRow, effectivenessColumn, RS(SC.GB_stEffectiveness) + " (%)");
      gbtu.setValue(packTable, firstRow, startDateColumn, RS(SC.GB_stStartMonth));
      if (allowEditsBool) {
        gbtu.setValue(packTable, firstRow, deleteBtnCol, RS(SC.GB_stDeleteBtn));
      }

      for (let item = 1; item <= numObjects; item++) {
        const method = methods[item - 1];

        /* Set row values. */
        gbtu.setValue(packTable, item, itemNameColumn, method.name);
        gbtu.setValue(packTable, item, unitOfCommodityColumn, method.unitOfCommodity);
        gbtu.setValue(packTable, item, monthsCoverageColumn, method.months_cov_per_unit);
        gbtu.setValue(packTable, item, unitCostColumn, method.unitCost);

        const effectiveness = piasu.getImpactEffectiveness(impactEffectiveness, item) || 0;
        gbtu.setValue(packTable, item, effectivenessColumn, effectiveness);

        const itemId = piasu.methodMstID(methods, item);
        if (itemId === "ARVS") {
          /* Need to save these into variables before passing or the link component will be sent the wrong row, col. */
          let rowPassed = item;
          let colPassed = informationColumn;

          let btnKey = "btn row" + rowPassed + "_col" + colPassed;
          packTable.components[rowPassed][colPassed] = () => (
            <InformationTooltip id={btnKey} message={RS(SC.GB_stPillInformation)} />
          );
        }

        const rowPassed = item;
        /** @type {number} */
        const offset = method.start_month_offset ?? 0;
        const startDate = new Date(tspDates.start);
        startDate.setMonth(startDate.getMonth() + offset);

        packTable.components[rowPassed][startDateColumn] = () => (
          <PISelectDate
            selectedDate={{
              month: startDate.getMonth() + 1,
              year: startDate.getFullYear(),
            }}
            dateRange={targSettingPeriodObj}
            info={rowPassed}
            onDateChange={this.onDateChange}
            outOfRangeMessage={RS(SC.GB_stMethodStartWithinTSPPeriod)}
            onDialogChange={this.props[pias.onDialogChange]}
          />
        );

        if (allowEditsBool) {
          /* Need to save these into variables before passing or the link component will be sent the wrong row, col. */
          const colPassed = deleteBtnCol;

          const btnKey = "btn row" + rowPassed + "_col" + colPassed;
          packTable.components[rowPassed][colPassed] = () => (
            <DeleteButton
              id={btnKey}
              row={rowPassed}
              itemType={pic.methodItems}
              props={this.props}
              state={this.state}
              setState={this.setState.bind(this)}
            />
          );
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setColAlignment(packTable, informationColumn, gbtc.hAlign.center);
      gbtu.setColAlignment(packTable, deleteBtnCol, gbtc.hAlign.center);
      /* Needed when entering text. */
      gbtu.setMinAllowedValByCol(packTable, itemNameColumn, 0);
      gbtu.setMaxAllowedValByCol(packTable, itemNameColumn, 0);
      /* Hide the first column so the user can type in the first visible column. */
      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, itemNameColumn, Theme.dataColWidthLarge);
      gbtu.setColWidth(packTable, unitOfCommodityColumn, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, monthsCoverageColumn, Theme.dataColWidthSmall);
      gbtu.setColWidth(packTable, unitCostColumn, Theme.dataColWidthSmall);
      gbtu.setColWidth(packTable, informationColumn, Theme.dataColWidthIcon);
      gbtu.setColWidth(packTable, effectivenessColumn, 125);
      gbtu.setColWidth(packTable, startDateColumn, Theme.itemNameColWidth);
      if (allowEditsBool) {
        gbtu.setColWidth(packTable, deleteBtnCol, Theme.dataColWidthSmall);
      }
      gbtu.setRowHeight(packTable, firstRow, 120);

      if (!allowEditsBool) {
        gbtu.lockPackTable(packTable, true);
      }
      gbtu.lockCol(packTable, informationColumn, true, false);
      gbtu.lockCol(packTable, startDateColumn, true, false);
      gbtu.lockCol(packTable, deleteBtnCol, true, false);

      gbtu.setRDecs(packTable, 2);
      gbtu.setRDecByCol(packTable, monthsCoverageColumn, 0);

      // gbtu.setMinAllowedValByCol(packTable, unitOfCommodityColumn, 0);
      // gbtu.setMaxAllowedValByCol(packTable, unitOfCommodityColumn, 0);
      gbtu.setMaxAllowedValByCol(packTable, unitCostColumn, gbtc.maxInt);

      if (window.DebugMode) {
        console.log("Component: PIMethodUnitsTable");
        console.log("ModVar(s):");
        console.log(pisc.methodsMVTag);
        console.log(methods);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          focusedCell={focusedCell}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          frameworkComponents={frameworkComponents}
          gridKey={tableKey}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{
            ...packTable,
            GBFixedCols: 0,
            GBRowHeights: packTable.GBRowHeights.map((rowHeight, index) => (index === 0 ? rowHeight : -1)),
          }}
          types={generateTypes(packTable, [
            ...repeat("s", packTable.GBFixedCols + 2),
            ...repeat("n", packTable.GBColCount - packTable.GBFixedCols - 6),
            "cm", // information
            "n", // effectiveness
            "cm", // start date
            "cm", // delete
          ])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={selectedRegions}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          limitWidthToContainer={true}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIMethodUnitsTable");
  };

  render() {
    return (
      <>
        {this.renderTable()}
        {
          <AddButton
            itemType={pic.methodItems}
            props={this.props}
            state={this.state}
            setState={this.setState.bind(this)}
          />
        }
      </>
    );
  }
}
