export const GB_sPBWAbbr = "GB_sPBWAbbr";
export const GB_stAbout = "GB_stAbout";
export const GB_stAboutPara1 = "GB_stAboutPara1";
export const GB_stAboutPara2 = "GB_stAboutPara2";
export const GB_stAboutPrEPit = "GB_stAboutPrEPit";
export const GB_stAboveSiteCosts = "GB_stAboveSiteCosts";
export const GB_stAck = "GB_stAck";
export const GB_stAckPara1 = "GB_stAckPara1";
export const GB_stAckPara2 = "GB_stAckPara2";
export const GB_stAckPara3 = "GB_stAckPara3";
export const GB_stAckPara4 = "GB_stAckPara4";
export const GB_stAckPara5 = "GB_stAckPara5";
export const GB_stActualCovToBeAchieved = "GB_stActualCovToBeAchieved";
export const GB_stAddBtn = "GB_stAddBtn";
export const GB_stAddCategory = "GB_stAddCategory";
export const GB_stAddContCurveNote = "GB_stAddContCurveNote";
export const GB_stAddContVisitNote = "GB_stAddContVisitNote";
export const GB_stAddContVisitSchedNote = "GB_stAddContVisitSchedNote";
export const GB_stAddCurve = "GB_stAddCurve";
export const GB_stAddDistrictPop = "GB_stAddDistrictPop";
export const GB_stAddLabTestNote = "GB_stAddLabTestNote";
export const GB_stAddMethodNote = "GB_stAddMethodNote";
export const GB_stAddMinPatNote = "GB_stAddMinPatNote";
export const GB_stAddPersTypeNote = "GB_stAddPersTypeNote";
export const GB_stAddPriorPopNote = "GB_stAddPriorPopNote";
export const GB_stAddSavingsAchTargeting = "GB_stAddSavingsAchTargeting";
export const GB_stAddScaleUpTrendNote = "GB_stAddScaleUpTrendNote";
export const GB_stAddServDelivStratNote = "GB_stAddServDelivStratNote";
export const GB_stAddServDelivUnitNote = "GB_stAddServDelivUnitNote";
export const GB_stAddStaffTypeNote = "GB_stAddStaffTypeNote";
export const GB_stAdhereSupport = "GB_stAdhereSupport";
export const GB_stAdhereSupportOtherCosts = "GB_stAdhereSupportOtherCosts";
export const GB_stAdjInfAvtdPerPYPrEP = "GB_stAdjInfAvtdPerPYPrEP";
export const GB_stAdjInfectAvtd = "GB_stAdjInfectAvtd";
export const GB_stAdjPYOnPrEPPerInfAvtd = "GB_stAdjPYOnPrEPPerInfAvtd";
export const GB_stAdjustFactor = "GB_stAdjustFactor";
export const GB_stAdjustFactorExpl = "GB_stAdjustFactorExpl";
export const GB_stAdolGirlsYoungWomen = "GB_stAdolGirlsYoungWomen";
export const GB_stAfter12Months = "GB_stAfter12Months";
export const GB_stAfterOneMonth = "GB_stAfterOneMonth";
export const GB_stAfterSixMonths = "GB_stAfterSixMonths";
export const GB_stAfterThreeMonths = "GB_stAfterThreeMonths";
export const GB_stAge = "GB_stAge";
export const GB_stAggregate = "GB_stAggregate";
export const GB_stAggregateFiles = "GB_stAggregateFiles";
export const GB_stAggregateInstructions = "GB_stAggregateInstructions";
export const GB_stAggToolDescription = "GB_stAggToolDescription";
export const GB_stAGYW = "GB_stAGYW";
export const GB_stAGYW15t19 = "GB_stAGYW15t19";
export const GB_stAGYW15t24 = "GB_stAGYW15t24";
export const GB_stAGYW15t24CC = "GB_stAGYW15t24CC";
export const GB_stAGYW18t19 = "GB_stAGYW18t19";
export const GB_stAGYW20t24 = "GB_stAGYW20t24";
export const GB_stAGYWAbbr = "GB_stAGYWAbbr";
export const GB_stAGYWAndDisagTargOptions = "GB_stAGYWAndDisagTargOptions";
export const GB_stAGYWGeoPrior = "GB_stAGYWGeoPrior";
export const GB_stAGYWNotInclExcercise = "GB_stAGYWNotInclExcercise";
export const GB_stAGYWOptions = "GB_stAGYWOptions";
export const GB_stAGYWResults = "GB_stAGYWResults";
export const GB_stAGYWTab1Note = "GB_stAGYWTab1Note";
export const GB_stAGYWUploadSpecs = "GB_stAGYWUploadSpecs";
export const GB_stAlerts = "GB_stAlerts";
export const GB_stAllMethodsCombined = "GB_stAllMethodsCombined";
export const GB_stAllPopulations = "GB_stAllPopulations";
export const GB_stALT = "GB_stALT";
export const GB_stAnalProgDat = "GB_stAnalProgDat";
export const GB_stAnalyzeCascIndQuestion = "GB_stAnalyzeCascIndQuestion";
export const GB_stAnalyzePerPersCosts = "GB_stAnalyzePerPersCosts";
export const GB_stAnnAboveSiteLumpSumCosts = "GB_stAnnAboveSiteLumpSumCosts";
export const GB_stAnnCostsAdjPerMonth = "GB_stAnnCostsAdjPerMonth";
export const GB_stAnnLumpSumCosts = "GB_stAnnLumpSumCosts";
export const GB_stAnnPrEPCap = "GB_stAnnPrEPCap";
export const GB_stAnnualDiscountRate = "GB_stAnnualDiscountRate";
export const GB_stAnnualUnitCosts = "GB_stAnnualUnitCosts";
export const GB_stAnnUnitCosts = "GB_stAnnUnitCosts";
export const GB_stAnnUnitCostsExcDeliveryEtc = "GB_stAnnUnitCostsExcDeliveryEtc";
export const GB_stAnnUnitCostsInclDeliveryEtc = "GB_stAnnUnitCostsInclDeliveryEtc";
export const GB_stApply = "GB_stApply";
export const GB_stAprAbbr = "GB_stAprAbbr";
export const GB_stApril = "GB_stApril";
export const GB_stArea = "GB_stArea";
export const GB_stAreaName = "GB_stAreaName";
export const GB_stAreaSelectionConstraint = "GB_stAreaSelectionConstraint";
export const GB_stAreasUploaded = "GB_stAreasUploaded";
export const GB_stARVCosts = "GB_stARVCosts";
export const GB_stARVs = "GB_stARVs";
export const GB_stAssignContCurves = "GB_stAssignContCurves";
export const GB_stAssignContSchedToPriorPop = "GB_stAssignContSchedToPriorPop";
export const GB_stAssignContSchedToServDelivStrat = "GB_stAssignContSchedToServDelivStrat";
export const GB_stAssignContVisits = "GB_stAssignContVisits";
export const GB_stAssignContVisitsByMonth = "GB_stAssignContVisitsByMonth";
export const GB_stAssignContVisitSchedules = "GB_stAssignContVisitSchedules";
export const GB_stAssignCurves = "GB_stAssignCurves";
export const GB_stAssignImpactFactors = "GB_stAssignImpactFactors";
export const GB_stAssignMinPatServDelivStrat = "GB_stAssignMinPatServDelivStrat";
export const GB_stAssignScaleUpTrends = "GB_stAssignScaleUpTrends";
export const GB_stAssignStaffTypes = "GB_stAssignStaffTypes";
export const GB_stAssignStrategies = "GB_stAssignStrategies";
export const GB_stAssignTrends = "GB_stAssignTrends";
export const GB_stAugAbbr = "GB_stAugAbbr";
export const GB_stAugust = "GB_stAugust";
export const GB_stAverageMonthlyCost = "GB_stAverageMonthlyCost";
export const GB_stAvgMonthlyCostPerPerson = "GB_stAvgMonthlyCostPerPerson";
export const GB_stAvgMonthsPrEP = "GB_stAvgMonthsPrEP";
export const GB_stAvgMonthsProdDistPerPersonContMonthOne = "GB_stAvgMonthsProdDistPerPersonContMonthOne";
export const GB_stAvgMoOnPrEPPerPersonInit = "GB_stAvgMoOnPrEPPerPersonInit";
export const GB_stAvgMoProdDistrPerPersonInit = "GB_stAvgMoProdDistrPerPersonInit";
export const GB_stAvgUnitCost = "GB_stAvgUnitCost";
export const GB_stAvgUnitCostByMonth = "GB_stAvgUnitCostByMonth";
export const GB_stAvgUnitCostByMonthPrEPUse = "GB_stAvgUnitCostByMonthPrEPUse";
export const GB_stBack = "GB_stBack";
export const GB_stBaseDefault = "GB_stBaseDefault";
export const GB_stBegin = "GB_stBegin";
export const GB_stBFW = "GB_stBFW";
export const GB_stBFWomenDef = "GB_stBFWomenDef";
export const GB_stBottleCosts = "GB_stBottleCosts";
export const GB_stBottleLower = "GB_stBottleLower";
export const GB_stBottleneck = "GB_stBottleneck";
export const GB_stBottleneckCap = "GB_stBottleneckCap";
export const GB_stByPop = "GB_stByPop";
export const GB_stByPriorPop = "GB_stByPriorPop";
export const GB_stByPriorPopCap = "GB_stByPriorPopCap";
export const GB_stByPriorPops = "GB_stByPriorPops";
export const GB_stByServDelivStrat = "GB_stByServDelivStrat";
export const GB_stByServDelivStratCap = "GB_stByServDelivStratCap";
export const GB_stByStrat = "GB_stByStrat";
export const GB_stCABLA = "GB_stCABLA";
export const GB_stCalcd = "GB_stCalcd";
export const GB_stCalcTargets = "GB_stCalcTargets";
export const GB_stCancel = "GB_stCancel";
export const GB_stCapacity = "GB_stCapacity";
export const GB_stCapacityOptDescription = "GB_stCapacityOptDescription";
export const GB_stCapacityTargSetTableLab = "GB_stCapacityTargSetTableLab";
export const GB_stCapCosts = "GB_stCapCosts";
export const GB_stCapitalCosts = "GB_stCapitalCosts";
export const GB_stCatchAreas = "GB_stCatchAreas";
export const GB_stCategoryHeader = "GB_stCategoryHeader";
export const GB_stCheckAllThatApply = "GB_stCheckAllThatApply";
export const GB_stChooseAnAreaType = "GB_stChooseAnAreaType";
export const GB_stChooseCostMode = "GB_stChooseCostMode";
export const GB_stChooseCostPerPYPrEP = "GB_stChooseCostPerPYPrEP";
export const GB_stChooseMode = "GB_stChooseMode";
export const GB_stChooseOptionBelow = "GB_stChooseOptionBelow";
export const GB_stChooseScenario = "GB_stChooseScenario";
export const GB_stChooseThresholdCrit = "GB_stChooseThresholdCrit";
export const GB_stChooseWayCostPerPYART = "GB_stChooseWayCostPerPYART";
export const GB_stClear = "GB_stClear";
export const GB_stClickCalcTargUpdate = "GB_stClickCalcTargUpdate";
export const GB_stClickHere = "GB_stClickHere";
export const GB_stClickHerePDF = "GB_stClickHerePDF";
export const GB_stClickHereViewDemo = "GB_stClickHereViewDemo";
export const GB_stClickSetPeriod = "GB_stClickSetPeriod";
export const GB_stClickSetPeriodEither = "GB_stClickSetPeriodEither";
export const GB_stClientMonthsPrEP = "GB_stClientMonthsPrEP";
export const GB_stClinicalOfficer = "GB_stClinicalOfficer";
export const GB_stClose = "GB_stClose";
export const GB_stCommForecastPeriod = "GB_stCommForecastPeriod";
export const GB_stCommoditiesForecasting = "GB_stCommoditiesForecasting";
export const GB_stConfiguration = "GB_stConfiguration";
export const GB_stConfigurationSDC = "GB_stConfigurationSDC";
export const GB_stConstant = "GB_stConstant";
export const GB_stConstCov = "GB_stConstCov";
export const GB_stCont12Months = "GB_stCont12Months";
export const GB_stCont1Month = "GB_stCont1Month";
export const GB_stCont3Months = "GB_stCont3Months";
export const GB_stCont6Months = "GB_stCont6Months";
export const GB_stContCalcDownload = "GB_stContCalcDownload";
export const GB_stContCalcInfo = "GB_stContCalcInfo";
export const GB_stContCalculator = "GB_stContCalculator";
export const GB_stContCurve = "GB_stContCurve";
export const GB_stContCurveExplan = "GB_stContCurveExplan";
export const GB_stContCurveExplanation1 = "GB_stContCurveExplanation1";
export const GB_stContCurveExplanation2 = "GB_stContCurveExplanation2";
export const GB_stContCurves = "GB_stContCurves";
export const GB_stContCurvesCantIncrease = "GB_stContCurvesCantIncrease";
export const GB_stContinuation = "GB_stContinuation";
export const GB_stContinuationVisit = "GB_stContinuationVisit";
export const GB_stContLabTestsPerVisit = "GB_stContLabTestsPerVisit";
export const GB_stContRate = "GB_stContRate";
export const GB_stContVisit = "GB_stContVisit";
export const GB_stContVisitExclMETHOD = "GB_stContVisitExclMETHOD";
export const GB_stContVisitExclPill = "GB_stContVisitExclPill";
export const GB_stContVisitExclRings = "GB_stContVisitExclRings";
export const GB_stContVisitLabs = "GB_stContVisitLabs";
export const GB_stContVisits = "GB_stContVisits";
export const GB_stContVisitSched = "GB_stContVisitSched";
export const GB_stContVisitSchedules = "GB_stContVisitSchedules";
export const GB_stCostCategory = "GB_stCostCategory";
export const GB_stCosting = "GB_stCosting";
export const GB_stCostPerBottle = "GB_stCostPerBottle";
export const GB_stCostPerIAInvalid = "GB_stCostPerIAInvalid";
export const GB_stCostPerIAThreshold = "GB_stCostPerIAThreshold";
export const GB_stCostPerInfAvtd = "GB_stCostPerInfAvtd";
export const GB_stCostPerPersInit = "GB_stCostPerPersInit";
export const GB_stCostPerPersonYrART = "GB_stCostPerPersonYrART";
export const GB_stCostPerPersonYrPrEP = "GB_stCostPerPersonYrPrEP";
export const GB_stCostPerRing = "GB_stCostPerRing";
export const GB_stCostPerUNITCOMM = "GB_stCostPerUNITCOMM";
export const GB_stCosts = "GB_stCosts";
export const GB_stCostsInfoBox = "GB_stCostsInfoBox";
export const GB_stCostsInfoBoxNoTargets = "GB_stCostsInfoBoxNoTargets";
export const GB_stCostsLite = "GB_stCostsLite";
export const GB_stCostsNotProvided = "GB_stCostsNotProvided";
export const GB_stCostsPerPersMonth = "GB_stCostsPerPersMonth";
export const GB_stCostsPerVisit = "GB_stCostsPerVisit";
export const GB_stCouldNotConnectServer = "GB_stCouldNotConnectServer";
export const GB_stCounselor = "GB_stCounselor";
export const GB_stCoverage = "GB_stCoverage";
export const GB_stCoverageByMethod = "GB_stCoverageByMethod";
export const GB_stCoverageByPop = "GB_stCoverageByPop";
export const GB_stCoverageOptDescription = "GB_stCoverageOptDescription";
export const GB_stCoverageOptionsContraintsDescription = "GB_stCoverageOptionsContraintsDescription";
export const GB_stCoverageTargSetTableLab = "GB_stCoverageTargSetTableLab";
export const GB_stCovWithConstraints = "GB_stCovWithConstraints";
export const GB_stCreatinine = "GB_stCreatinine";
export const GB_stCredits = "GB_stCredits";
export const GB_stCurrentlyOnPrEP = "GB_stCurrentlyOnPrEP";
export const GB_stCurrOnPrEPDescription = "GB_stCurrOnPrEPDescription";
export const GB_stCurveE = "GB_stCurveE";
export const GB_stCurveF = "GB_stCurveF";
export const GB_stCustomContCurve = "GB_stCustomContCurve";
export const GB_stCustomContCurves = "GB_stCustomContCurves";
export const GB_stCustomContVisit = "GB_stCustomContVisit";
export const GB_stCustomContVisits = "GB_stCustomContVisits";
export const GB_stCustomContVisitSched = "GB_stCustomContVisitSched";
export const GB_stCustomContVisitScheds = "GB_stCustomContVisitScheds";
export const GB_stCustomInd = "GB_stCustomInd";
export const GB_stCustomLabTest = "GB_stCustomLabTest";
export const GB_stCustomLabTests = "GB_stCustomLabTests";
export const GB_stCustomMethod = "GB_stCustomMethod";
export const GB_stCustomMethods = "GB_stCustomMethods";
export const GB_stCustomMinPat = "GB_stCustomMinPat";
export const GB_stCustomMinPats = "GB_stCustomMinPats";
export const GB_stCustomPersonnelType = "GB_stCustomPersonnelType";
export const GB_stCustomPersTypes = "GB_stCustomPersTypes";
export const GB_stCustomPriorPop = "GB_stCustomPriorPop";
export const GB_stCustomPriorPops = "GB_stCustomPriorPops";
export const GB_stCustomScaleUpTrend = "GB_stCustomScaleUpTrend";
export const GB_stCustomScaleUpTrends = "GB_stCustomScaleUpTrends";
export const GB_stCustomServDelivStrat = "GB_stCustomServDelivStrat";
export const GB_stCustomServDelivStrats = "GB_stCustomServDelivStrats";
export const GB_stCustomServDelivUnit = "GB_stCustomServDelivUnit";
export const GB_stCustomServDelivUnits = "GB_stCustomServDelivUnits";
export const GB_stCustomStaffTypes = "GB_stCustomStaffTypes";
export const GB_stDataNeedsTargSetting = "GB_stDataNeedsTargSetting";
export const GB_stDateRangeForDisplay = "GB_stDateRangeForDisplay";
export const GB_stDateRangeForIndicators = "GB_stDateRangeForIndicators";
export const GB_stDaysPerWeekLabsProc = "GB_stDaysPerWeekLabsProc";
export const GB_stDecAbbr = "GB_stDecAbbr";
export const GB_stDecember = "GB_stDecember";
export const GB_stDefaultCostPatterns = "GB_stDefaultCostPatterns";
export const GB_stDefaultImpFactors = "GB_stDefaultImpFactors";
export const GB_stDefaultPriorPop = "GB_stDefaultPriorPop";
export const GB_stDefaultPriorPops = "GB_stDefaultPriorPops";
export const GB_stDefaultValues = "GB_stDefaultValues";
export const GB_stDefContCurves = "GB_stDefContCurves";
export const GB_stDefCostsPerVisit = "GB_stDefCostsPerVisit";
export const GB_stDefineContSched = "GB_stDefineContSched";
export const GB_stDefineCurves = "GB_stDefineCurves";
export const GB_stDefineScaleUpTrends = "GB_stDefineScaleUpTrends";
export const GB_stDefineVisitSchedules = "GB_stDefineVisitSchedules";
export const GB_stDefinitions = "GB_stDefinitions";
export const GB_stDefMinsPats = "GB_stDefMinsPats";
export const GB_stDefScaleUpTrends = "GB_stDefScaleUpTrends";
export const GB_stDeleteBtn = "GB_stDeleteBtn";
export const GB_stDemand = "GB_stDemand";
export const GB_stDemandCreation = "GB_stDemandCreation";
export const GB_stDemandCreationCat = "GB_stDemandCreationCat";
export const GB_stDetailedCosts = "GB_stDetailedCosts";
export const GB_stDisagSetup = "GB_stDisagSetup";
export const GB_stDisagTargets = "GB_stDisagTargets";
export const GB_stDisagTargetsInfo1 = "GB_stDisagTargetsInfo1";
export const GB_stDisagTargetsSubnat1 = "GB_stDisagTargetsSubnat1";
export const GB_stDisagTargetsSubnat2 = "GB_stDisagTargetsSubnat2";
export const GB_stDisplay = "GB_stDisplay";
export const GB_stDistName = "GB_stDistName";
export const GB_stDistrict = "GB_stDistrict";
export const GB_stDistrictPops = "GB_stDistrictPops";
export const GB_stDoNotSetTargets = "GB_stDoNotSetTargets";
export const GB_stDoubleQuotesTest = "GB_stDoubleQuotesTest";
export const GB_stDownloadNewTemplate = "GB_stDownloadNewTemplate";
export const GB_stDownloadPepfarTargets = "GB_stDownloadPepfarTargets";
export const GB_stDownloadPepfarTargetsInstructions = "GB_stDownloadPepfarTargetsInstructions";
export const GB_stDownloadSession = "GB_stDownloadSession";
export const GB_stDownloadTargOutPEPFAR = "GB_stDownloadTargOutPEPFAR";
export const GB_stDownloadTemplate = "GB_stDownloadTemplate";
export const GB_stDownloadVersionHistory = "GB_stDownloadVersionHistory";
export const GB_stDoYouWishToContinue = "GB_stDoYouWishToContinue";
export const GB_stDropInCenters = "GB_stDropInCenters";
export const GB_stDrugCap = "GB_stDrugCap";
export const GB_stEasyStart = "GB_stEasyStart";
export const GB_stEasyStartToolDescription = "GB_stEasyStartToolDescription";
export const GB_stEdCode = "GB_stEdCode";
export const GB_stEditContCurveInstruct = "GB_stEditContCurveInstruct";
export const GB_stEditContVisitInstruct = "GB_stEditContVisitInstruct";
export const GB_stEditContVisitSchedInstruct = "GB_stEditContVisitSchedInstruct";
export const GB_stEditIndInstruct = "GB_stEditIndInstruct";
export const GB_stEditLabTestInstruct = "GB_stEditLabTestInstruct";
export const GB_stEditMethodsInstruct = "GB_stEditMethodsInstruct";
export const GB_stEditMinPatInstruct = "GB_stEditMinPatInstruct";
export const GB_stEditPersTypesInstruct = "GB_stEditPersTypesInstruct";
export const GB_stEditPriorPopInstruct = "GB_stEditPriorPopInstruct";
export const GB_stEditScaleUpTrendInstruct = "GB_stEditScaleUpTrendInstruct";
export const GB_stEditServDelivStratInstruct = "GB_stEditServDelivStratInstruct";
export const GB_stEditServDelivUnitsInstruct = "GB_stEditServDelivUnitsInstruct";
export const GB_stEditStaffTypesInstruct = "GB_stEditStaffTypesInstruct";
export const GB_steEndMonthGreaterStart = "GB_steEndMonthGreaterStart";
export const GB_stEffectiveness = "GB_stEffectiveness";
export const GB_stEffectivenessCitationsIntroduction = "GB_stEffectivenessCitationsIntroduction";
export const GB_stEffectivenessCitationsTitle = "GB_stEffectivenessCitationsTitle";
export const GB_stEffectivenessInfoBlurb = "GB_stEffectivenessInfoBlurb";
export const GB_stEffectiveUse = "GB_stEffectiveUse";
export const GB_steFileReadError = "GB_steFileReadError";
export const GB_stElevatedRiskNote = "GB_stElevatedRiskNote";
export const GB_stElevatedRiskPerc = "GB_stElevatedRiskPerc";
export const GB_stEligibility = "GB_stEligibility";
export const GB_stELISA = "GB_stELISA";
export const GB_stEmpCurvesPriorStudies = "GB_stEmpCurvesPriorStudies";
export const GB_stEndAge = "GB_stEndAge";
export const GB_stEndMonth = "GB_stEndMonth";
export const GB_stEndYear = "GB_stEndYear";
export const GB_stEnglish = "GB_stEnglish";
export const GB_stEnterByAreaBelow = "GB_stEnterByAreaBelow";
export const GB_stEnterConstraints = "GB_stEnterConstraints";
export const GB_stEnterCostData = "GB_stEnterCostData";
export const GB_stEnterCostPerPYARTByAreaBelow = "GB_stEnterCostPerPYARTByAreaBelow";
export const GB_stEnterDataTotalDrugCap = "GB_stEnterDataTotalDrugCap";
export const GB_stEnteredByAreaBelow = "GB_stEnteredByAreaBelow";
export const GB_stEnterExistTargsMan = "GB_stEnterExistTargsMan";
export const GB_stEnterInfoCalcPotUsers = "GB_stEnterInfoCalcPotUsers";
export const GB_stEnterPersMinutes = "GB_stEnterPersMinutes";
export const GB_stEnterSingleCostPerPYART = "GB_stEnterSingleCostPerPYART";
export const GB_stEnterSubnatLevel1 = "GB_stEnterSubnatLevel1";
export const GB_stEnterTargInits = "GB_stEnterTargInits";
export const GB_stePercReachedNot100 = "GB_stePercReachedNot100";
export const GB_stePotUsersNot100 = "GB_stePotUsersNot100";
export const GB_steProjDefError = "GB_steProjDefError";
export const GB_stErrAggFiles = "GB_stErrAggFiles";
export const GB_stErrOccProcTemplate = "GB_stErrOccProcTemplate";
export const GB_stError = "GB_stError";
export const GB_stErrorValidatingSession = "GB_stErrorValidatingSession";
export const GB_steSomeCovTargetsNotMet = "GB_steSomeCovTargetsNotMet";
export const GB_steStartMonthLaterError = "GB_steStartMonthLaterError";
export const GB_stEstContCurves = "GB_stEstContCurves";
export const GB_stEstContVisitSchedules = "GB_stEstContVisitSchedules";
export const GB_stEstCostEffectTargets1 = "GB_stEstCostEffectTargets1";
export const GB_stEstCostEffectTargets2 = "GB_stEstCostEffectTargets2";
export const GB_stEstCostEffectTargets3 = "GB_stEstCostEffectTargets3";
export const GB_stEstCostsTargets1 = "GB_stEstCostsTargets1";
export const GB_stEstCostsTargets2 = "GB_stEstCostsTargets2";
export const GB_stEstCostsTargets3 = "GB_stEstCostsTargets3";
export const GB_stEstImpactTargets1 = "GB_stEstImpactTargets1";
export const GB_stEstImpactTargets2 = "GB_stEstImpactTargets2";
export const GB_stEstImpactTargets3 = "GB_stEstImpactTargets3";
export const GB_stEstInc15to24 = "GB_stEstInc15to24";
export const GB_stEstLabTestsContVisits = "GB_stEstLabTestsContVisits";
export const GB_stEstMinPats = "GB_stEstMinPats";
export const GB_stEstPropClientVisitsPrEPInit = "GB_stEstPropClientVisitsPrEPInit";
export const GB_stEstScaleUpTrends = "GB_stEstScaleUpTrends";
export const GB_stEstsServDelivStratsPersTypes = "GB_stEstsServDelivStratsPersTypes";
export const GB_stEstStaffTimeReq = "GB_stEstStaffTimeReq";
export const GB_stEstStaffTypes = "GB_stEstStaffTypes";
export const GB_steTargSetPeriodFiveMonthsError = "GB_steTargSetPeriodFiveMonthsError";
export const GB_stEverywhere = "GB_stEverywhere";
export const GB_stExclDrugs = "GB_stExclDrugs";
export const GB_stExtendPDPEraseData = "GB_stExtendPDPEraseData";
export const GB_stFacility = "GB_stFacility";
export const GB_stFebAbbr = "GB_stFebAbbr";
export const GB_stFebruary = "GB_stFebruary";
export const GB_stFemale = "GB_stFemale";
export const GB_stFemales = "GB_stFemales";
export const GB_stFile = "GB_stFile";
export const GB_stForecastPrevProdNeeds1 = "GB_stForecastPrevProdNeeds1";
export const GB_stForecastPrevProdNeeds2 = "GB_stForecastPrevProdNeeds2";
export const GB_stFrancais = "GB_stFrancais";
export const GB_stFSW = "GB_stFSW";
export const GB_stFSWAbbr = "GB_stFSWAbbr";
export const GB_stFSWAbbrCC = "GB_stFSWAbbrCC";
export const GB_stFunction1 = "GB_stFunction1";
export const GB_stFunction2 = "GB_stFunction2";
export const GB_stFunction3 = "GB_stFunction3";
export const GB_stFunction4 = "GB_stFunction4";
export const GB_stFunction5 = "GB_stFunction5";
export const GB_stFunction6 = "GB_stFunction6";
export const GB_stFunctions = "GB_stFunctions";
export const GB_stGenAreaTemplate = "GB_stGenAreaTemplate";
export const GB_stGenDataEntryTemplate = "GB_stGenDataEntryTemplate";
export const GB_stGeneralPopulation = "GB_stGeneralPopulation";
export const GB_stGenPopMen = "GB_stGenPopMen";
export const GB_stGenPopWomen = "GB_stGenPopWomen";
export const GB_stGenTemplateError = "GB_stGenTemplateError";
export const GB_stGettingStarted = "GB_stGettingStarted";
export const GB_stGettingStartedInfo1 = "GB_stGettingStartedInfo1";
export const GB_stGettingStartedInfo2 = "GB_stGettingStartedInfo2";
export const GB_stGettingStartedInfo3 = "GB_stGettingStartedInfo3";
export const GB_stGettingStartedInfo4 = "GB_stGettingStartedInfo4";
export const GB_stGettingStartedInfo5 = "GB_stGettingStartedInfo5";
export const GB_stGettingStartedPara1 = "GB_stGettingStartedPara1";
export const GB_stGuideNatTargSetEx = "GB_stGuideNatTargSetEx";
export const GB_stHaveDataOnNumScrQuestion = "GB_stHaveDataOnNumScrQuestion";
export const GB_stHBsAg = "GB_stHBsAg";
export const GB_stHelp = "GB_stHelp";
export const GB_stHelpPara1 = "GB_stHelpPara1";
export const GB_stHighIncidence = "GB_stHighIncidence";
export const GB_stHighRiskHeteroMen = "GB_stHighRiskHeteroMen";
export const GB_stHighRiskMen = "GB_stHighRiskMen";
export const GB_stHIVByAgeSex = "GB_stHIVByAgeSex";
export const GB_stHIVIncidByAgeSex = "GB_stHIVIncidByAgeSex";
export const GB_stHIVPrev = "GB_stHIVPrev";
export const GB_stHIVRapidTest = "GB_stHIVRapidTest";
export const GB_stHome = "GB_stHome";
export const GB_stHotspots = "GB_stHotspots";
export const GB_stHowEnterCostPerPY = "GB_stHowEnterCostPerPY";
export const GB_stHowSetTargs = "GB_stHowSetTargs";
export const GB_stHowToSetTargetsQuestion = "GB_stHowToSetTargetsQuestion";
export const GB_stIdentifyHotspots = "GB_stIdentifyHotspots";
export const GB_stIDU = "GB_stIDU";
export const GB_stIDUAbbr = "GB_stIDUAbbr";
export const GB_stIfScaleUpSShaped = "GB_stIfScaleUpSShaped";
export const GB_stIfSShaped = "GB_stIfSShaped";
export const GB_stImpact = "GB_stImpact";
export const GB_stImpactAdjustment = "GB_stImpactAdjustment";
export const GB_stImpactEffectRefNote = "GB_stImpactEffectRefNote";
export const GB_stImpactFactor = "GB_stImpactFactor";
export const GB_stImpactFactorsColon = "GB_stImpactFactorsColon";
export const GB_stImpactInfo1 = "GB_stImpactInfo1";
export const GB_stImpactInfo2 = "GB_stImpactInfo2";
export const GB_stImpactInfo3 = "GB_stImpactInfo3";
export const GB_stImpactPara1 = "GB_stImpactPara1";
export const GB_stImpactPara2 = "GB_stImpactPara2";
export const GB_stImpactPara3 = "GB_stImpactPara3";
export const GB_stImpCostsBasedTargs = "GB_stImpCostsBasedTargs";
export const GB_stInclQ = "GB_stInclQ";
export const GB_stIncludeCost = "GB_stIncludeCost";
export const GB_stIncludeIndicatorsForPop = "GB_stIncludeIndicatorsForPop";
export const GB_stInit = "GB_stInit";
export const GB_stInitiationPeriod = "GB_stInitiationPeriod";
export const GB_stInitiationVisit = "GB_stInitiationVisit";
export const GB_stInitLabsProcDaily = "GB_stInitLabsProcDaily";
export const GB_stInitLabTestsPerVisit = "GB_stInitLabTestsPerVisit";
export const GB_stInitOnPrEPByMonth = "GB_stInitOnPrEPByMonth";
export const GB_stInitOnPrEPByPop = "GB_stInitOnPrEPByPop";
export const GB_stInits = "GB_stInits";
export const GB_stInitSpeedOfScaleUp = "GB_stInitSpeedOfScaleUp";
export const GB_stInitVisit = "GB_stInitVisit";
export const GB_stInitVisitExclMETHOD = "GB_stInitVisitExclMETHOD";
export const GB_stInitVisitExclPill = "GB_stInitVisitExclPill";
export const GB_stInitVisitExclRings = "GB_stInitVisitExclRings";
export const GB_stInitVisitLabs = "GB_stInitVisitLabs";
export const GB_stInjectableFPDef = "GB_stInjectableFPDef";
export const GB_stInjectionLower = "GB_stInjectionLower";
export const GB_stInputs = "GB_stInputs";
export const GB_stInputsByArea = "GB_stInputsByArea";
export const GB_stInstructVideos = "GB_stInstructVideos";
export const GB_stInstructVideosPara = "GB_stInstructVideosPara";
export const GB_stIntroduction = "GB_stIntroduction";
export const GB_stInvalidProgramDataReUpload = "GB_stInvalidProgramDataReUpload";
export const GB_stIsPrEPCostEffective = "GB_stIsPrEPCostEffective";
export const GB_stIWouldLikeTo = "GB_stIWouldLikeTo";
export const GB_stJanAbbr = "GB_stJanAbbr";
export const GB_stJanuary = "GB_stJanuary";
export const GB_stJuly = "GB_stJuly";
export const GB_stJulyAbbr = "GB_stJulyAbbr";
export const GB_stJune = "GB_stJune";
export const GB_stJuneAbbr = "GB_stJuneAbbr";
export const GB_stKeyPopCC = "GB_stKeyPopCC";
export const GB_stLabCap = "GB_stLabCap";
export const GB_stLaboratoryTest = "GB_stLaboratoryTest";
export const GB_stLaboratoryTests = "GB_stLaboratoryTests";
export const GB_stLabs = "GB_stLabs";
export const GB_stLabTestsContVisits = "GB_stLabTestsContVisits";
export const GB_stLabTestUnitCostInstruct = "GB_stLabTestUnitCostInstruct";
export const GB_stLevel1 = "GB_stLevel1";
export const GB_stLevel2 = "GB_stLevel2";
export const GB_stLifetimeARTCostAvtd = "GB_stLifetimeARTCostAvtd";
export const GB_stLinear = "GB_stLinear";
export const GB_stLowIncidence = "GB_stLowIncidence";
export const GB_stLumpSumAmount = "GB_stLumpSumAmount";
export const GB_stMadePossibleByHeader = "GB_stMadePossibleByHeader";
export const GB_stMadePossibleByP1 = "GB_stMadePossibleByP1";
export const GB_stMadePossibleByP2 = "GB_stMadePossibleByP2";
export const GB_stMadePossibleByP3 = "GB_stMadePossibleByP3";
export const GB_stMakeSessionNotes = "GB_stMakeSessionNotes";
export const GB_stMale = "GB_stMale";
export const GB_stMales = "GB_stMales";
export const GB_stManageCustomContCurves = "GB_stManageCustomContCurves";
export const GB_stManageCustomContVisits = "GB_stManageCustomContVisits";
export const GB_stManageCustomContVisitScheds = "GB_stManageCustomContVisitScheds";
export const GB_stManageCustomLabTests = "GB_stManageCustomLabTests";
export const GB_stManageCustomMethods = "GB_stManageCustomMethods";
export const GB_stManageCustomMinPats = "GB_stManageCustomMinPats";
export const GB_stManageCustomPersTypes = "GB_stManageCustomPersTypes";
export const GB_stManageCustomPriorPops = "GB_stManageCustomPriorPops";
export const GB_stManageCustomScaleUpTrends = "GB_stManageCustomScaleUpTrends";
export const GB_stManageCustomServDelivStrats = "GB_stManageCustomServDelivStrats";
export const GB_stManageCustomServDelivUnits = "GB_stManageCustomServDelivUnits";
export const GB_stManageCustomStaffTypes = "GB_stManageCustomStaffTypes";
export const GB_stManagementCat = "GB_stManagementCat";
export const GB_stManual = "GB_stManual";
export const GB_stManualOptDescription = "GB_stManualOptDescription";
export const GB_stManualTargSetTableLab = "GB_stManualTargSetTableLab";
export const GB_stMarAbbr = "GB_stMarAbbr";
export const GB_stMarch = "GB_stMarch";
export const GB_stMaxNumItemsIs = "GB_stMaxNumItemsIs";
export const GB_stMay = "GB_stMay";
export const GB_stMayAbbr = "GB_stMayAbbr";
export const GB_stMediumIncidence = "GB_stMediumIncidence";
export const GB_stMedRiskMen = "GB_stMedRiskMen";
export const GB_stMedRiskWomen = "GB_stMedRiskWomen";
export const GB_stMethod = "GB_stMethod";
export const GB_stMethodMix = "GB_stMethodMix";
export const GB_stMethodMixInstructions = "GB_stMethodMixInstructions";
export const GB_stMethodMixTitle = "GB_stMethodMixTitle";
export const GB_stMethodNotUsed = "GB_stMethodNotUsed";
export const GB_stMethods = "GB_stMethods";
export const GB_stMethodsPerUnit = "GB_stMethodsPerUnit";
export const GB_stMethodStartWithinTSPPeriod = "GB_stMethodStartWithinTSPPeriod";
export const GB_stMethodsUnitCostMessage = "GB_stMethodsUnitCostMessage";
export const GB_stMetric = "GB_stMetric";
export const GB_stMidpointOfCurve = "GB_stMidpointOfCurve";
export const GB_stMinPerTypeByStrat = "GB_stMinPerTypeByStrat";
export const GB_stMinutePattern = "GB_stMinutePattern";
export const GB_stMinutePatterns = "GB_stMinutePatterns";
export const GB_stMinutes = "GB_stMinutes";
export const GB_stMonth = "GB_stMonth";
export const GB_stMonthCapWksht = "GB_stMonthCapWksht";
export const GB_stMonthDrugCap = "GB_stMonthDrugCap";
export const GB_stMonthLabCap = "GB_stMonthLabCap";
export const GB_stMonthlyCapacity = "GB_stMonthlyCapacity";
export const GB_stMonthlyCapInstructions = "GB_stMonthlyCapInstructions";
export const GB_stMonths = "GB_stMonths";
export const GB_stMonthsAfterInit = "GB_stMonthsAfterInit";
export const GB_stMonthsARTViralSupp = "GB_stMonthsARTViralSupp";
export const GB_stMonthsOnARTExplan = "GB_stMonthsOnARTExplan";
export const GB_stMonthsOnPrEp = "GB_stMonthsOnPrEp";
export const GB_stMonthsPrEPCont = "GB_stMonthsPrEPCont";
export const GB_stMonthsPrEPInit = "GB_stMonthsPrEPInit";
export const GB_stMonthsPrEPUse = "GB_stMonthsPrEPUse";
export const GB_stMonthTypeAStaffCap = "GB_stMonthTypeAStaffCap";
export const GB_stMonthTypeBStaffCap = "GB_stMonthTypeBStaffCap";
export const GB_stMoreInitiationsThanUsers = "GB_stMoreInitiationsThanUsers";
export const GB_stMoreInitiationsThanUsersP2 = "GB_stMoreInitiationsThanUsersP2";
export const GB_stMoreInitiationsThanUsersP3 = "GB_stMoreInitiationsThanUsersP3";
export const GB_stMoveContCurveDownNote = "GB_stMoveContCurveDownNote";
export const GB_stMoveContCurveUpNote = "GB_stMoveContCurveUpNote";
export const GB_stMoveContVisitDownNote = "GB_stMoveContVisitDownNote";
export const GB_stMoveContVisitSchedDownNote = "GB_stMoveContVisitSchedDownNote";
export const GB_stMoveContVisitSchedUpNote = "GB_stMoveContVisitSchedUpNote";
export const GB_stMoveContVisitUpNote = "GB_stMoveContVisitUpNote";
export const GB_stMoveLabTestDownNote = "GB_stMoveLabTestDownNote";
export const GB_stMoveLabTestUpNote = "GB_stMoveLabTestUpNote";
export const GB_stMoveMethodDownNote = "GB_stMoveMethodDownNote";
export const GB_stMoveMethodUpNote = "GB_stMoveMethodUpNote";
export const GB_stMoveMinPatDownNote = "GB_stMoveMinPatDownNote";
export const GB_stMoveMinPatUpNote = "GB_stMoveMinPatUpNote";
export const GB_stMovePersTypeDownNote = "GB_stMovePersTypeDownNote";
export const GB_stMovePersTypeUpNote = "GB_stMovePersTypeUpNote";
export const GB_stMovePriorPopDownNote = "GB_stMovePriorPopDownNote";
export const GB_stMovePriorPopUpNote = "GB_stMovePriorPopUpNote";
export const GB_stMoveScaleUpTrendDownNote = "GB_stMoveScaleUpTrendDownNote";
export const GB_stMoveScaleUpTrendUpNote = "GB_stMoveScaleUpTrendUpNote";
export const GB_stMoveServDelivStratDownNote = "GB_stMoveServDelivStratDownNote";
export const GB_stMoveServDelivStratUpNote = "GB_stMoveServDelivStratUpNote";
export const GB_stMoveServDelivUnitDownNote = "GB_stMoveServDelivUnitDownNote";
export const GB_stMoveServDelivUnitUpNote = "GB_stMoveServDelivUnitUpNote";
export const GB_stMoveStaffTypeDownNote = "GB_stMoveStaffTypeDownNote";
export const GB_stMoveStaffTypeUpNote = "GB_stMoveStaffTypeUpNote";
export const GB_stMSM = "GB_stMSM";
export const GB_stMSMAbbr = "GB_stMSMAbbr";
export const GB_stMSMAbbrCC = "GB_stMSMAbbrCC";
export const GB_stMultiPartnerCondomlessSex = "GB_stMultiPartnerCondomlessSex";
export const GB_stMultiPartnerSexDef = "GB_stMultiPartnerSexDef";
export const GB_stName = "GB_stName";
export const GB_stNameLowercase = "GB_stNameLowercase";
export const GB_stNational = "GB_stNational";
export const GB_stNatName = "GB_stNatName";
export const GB_stNatPriorPopSize = "GB_stNatPriorPopSize";
export const GB_stNeedOneItem = "GB_stNeedOneItem";
export const GB_stNewCategory = "GB_stNewCategory";
export const GB_stNext = "GB_stNext";
export const GB_stNinetyExpl = "GB_stNinetyExpl";
export const GB_stNinetyFiveX3 = "GB_stNinetyFiveX3";
export const GB_stNo = "GB_stNo";
export const GB_stNoData = "GB_stNoData";
export const GB_stNone = "GB_stNone";
export const GB_stNonRegularPartners = "GB_stNonRegularPartners";
export const GB_stNote = "GB_stNote";
export const GB_stNotes = "GB_stNotes";
export const GB_stNovAbbr = "GB_stNovAbbr";
export const GB_stNovember = "GB_stNovember";
export const GB_stNPills = "GB_stNPills";
export const GB_stNull = "GB_stNull";
export const GB_stNumAGYWBelowThreshold = "GB_stNumAGYWBelowThreshold";
export const GB_stNumAGYWInitiatingPrEP = "GB_stNumAGYWInitiatingPrEP";
export const GB_stNumber = "GB_stNumber";
export const GB_stNumBottlesDistrCont = "GB_stNumBottlesDistrCont";
export const GB_stNumBottlesDistrInit = "GB_stNumBottlesDistrInit";
export const GB_stNumBottlesReq = "GB_stNumBottlesReq";
export const GB_stNumContVisits = "GB_stNumContVisits";
export const GB_stNumDaysPerWeekAvail = "GB_stNumDaysPerWeekAvail";
export const GB_stNumInit = "GB_stNumInit";
export const GB_stNumInitPrEP = "GB_stNumInitPrEP";
export const GB_stNumInitVisits = "GB_stNumInitVisits";
export const GB_stNumMinAvailPerDay = "GB_stNumMinAvailPerDay";
export const GB_stNumMonthsCovPerUnit = "GB_stNumMonthsCovPerUnit";
export const GB_stNumPillsPerClientDay = "GB_stNumPillsPerClientDay";
export const GB_stNumPillsReq = "GB_stNumPillsReq";
export const GB_stNumPotentialUsers = "GB_stNumPotentialUsers";
export const GB_stNumPotInitClientsPerMonth = "GB_stNumPotInitClientsPerMonth";
export const GB_stNumPotVisitsPerMonth = "GB_stNumPotVisitsPerMonth";
export const GB_stNumReinitPrEP = "GB_stNumReinitPrEP";
export const GB_stNumRestartVisits = "GB_stNumRestartVisits";
export const GB_stNumRingsDistrCont = "GB_stNumRingsDistrCont";
export const GB_stNumRingsDistrInit = "GB_stNumRingsDistrInit";
export const GB_stNumRingsReq = "GB_stNumRingsReq";
export const GB_stNumScrRiskElig = "GB_stNumScrRiskElig";
export const GB_stNumTestedHIV = "GB_stNumTestedHIV";
export const GB_stNumTypeAAvailPerDay = "GB_stNumTypeAAvailPerDay";
export const GB_stNumTypeBAvailPerDay = "GB_stNumTypeBAvailPerDay";
export const GB_stNumUNITCOMMAdminCont = "GB_stNumUNITCOMMAdminCont";
export const GB_stNumUNITCOMMAdminInit = "GB_stNumUNITCOMMAdminInit";
export const GB_stNumUNITCOMMDistrCont = "GB_stNumUNITCOMMDistrCont";
export const GB_stNumUNITCOMMDistrInit = "GB_stNumUNITCOMMDistrInit";
export const GB_stNumUNITCOMMRequired = "GB_stNumUNITCOMMRequired";
export const GB_stNurse = "GB_stNurse";
export const GB_stOctAbbr = "GB_stOctAbbr";
export const GB_stOctober = "GB_stOctober";
export const GB_stOk = "GB_stOk";
export const GB_stOneContCurveReq = "GB_stOneContCurveReq";
export const GB_stOneStep = "GB_stOneStep";
export const GB_stOptions = "GB_stOptions";
export const GB_stOptParen = "GB_stOptParen";
export const GB_stOtherCC = "GB_stOtherCC";
export const GB_stOtherFixedCostsAnnLumpSum = "GB_stOtherFixedCostsAnnLumpSum";
export const GB_stOtherRecurOtherCommEtc = "GB_stOtherRecurOtherCommEtc";
export const GB_stOtherRecurr = "GB_stOtherRecurr";
export const GB_stOtherRecurrent = "GB_stOtherRecurrent";
export const GB_stOtherRecurSiteTrainingEtc = "GB_stOtherRecurSiteTrainingEtc";
export const GB_stOutreach = "GB_stOutreach";
export const GB_stOutreachWorker = "GB_stOutreachWorker";
export const GB_stOverhead = "GB_stOverhead";
export const GB_stParameters = "GB_stParameters";
export const GB_stPatDefMinFromLiterature = "GB_stPatDefMinFromLiterature";
export const GB_stPBW = "GB_stPBW";
export const GB_stPBWAbbr = "GB_stPBWAbbr";
export const GB_stPeerEducator = "GB_stPeerEducator";
export const GB_stPEPFARIndicators = "GB_stPEPFARIndicators";
export const GB_stPercClientsInitPrEPByMonth = "GB_stPercClientsInitPrEPByMonth";
export const GB_stPercClientsOnPrEP = "GB_stPercClientsOnPrEP";
export const GB_stPercent = "GB_stPercent";
export const GB_stPercentageOfTotal = "GB_stPercentageOfTotal";
export const GB_stPercIndicPrEP = "GB_stPercIndicPrEP";
export const GB_stPercOrNum = "GB_stPercOrNum";
export const GB_stPercPrEPEligAreas = "GB_stPercPrEPEligAreas";
export const GB_stPercReached = "GB_stPercReached";
export const GB_stPercReachedInstruct = "GB_stPercReachedInstruct";
export const GB_stPercRecPregTest = "GB_stPercRecPregTest";
export const GB_stPercTargNumInit = "GB_stPercTargNumInit";
export const GB_stPercYrOnPrEP = "GB_stPercYrOnPrEP";
export const GB_stPersMinByType = "GB_stPersMinByType";
export const GB_stPersonnel = "GB_stPersonnel";
export const GB_stPersonnelMinutes = "GB_stPersonnelMinutes";
export const GB_stPersonnelTypes = "GB_stPersonnelTypes";
export const GB_stPersonYrsAvt1Inf = "GB_stPersonYrsAvt1Inf";
export const GB_stPersType = "GB_stPersType";
export const GB_stPersYearCostMETHOD = "GB_stPersYearCostMETHOD";
export const GB_stPersYearCostPill = "GB_stPersYearCostPill";
export const GB_stPersYearCostRing = "GB_stPersYearCostRing";
export const GB_stPharmTech = "GB_stPharmTech";
export const GB_stPhotoCreditPROMISE = "GB_stPhotoCreditPROMISE";
export const GB_stPill = "GB_stPill";
export const GB_stPillCosts = "GB_stPillCosts";
export const GB_stPillInformation = "GB_stPillInformation";
export const GB_stPleaseFillOutTemplate = "GB_stPleaseFillOutTemplate";
export const GB_stPop15Plus = "GB_stPop15Plus";
export const GB_stPopAGYW15to24 = "GB_stPopAGYW15to24";
export const GB_stPopHIVPrevalence = "GB_stPopHIVPrevalence";
export const GB_stPopulation = "GB_stPopulation";
export const GB_stPopulations = "GB_stPopulations";
export const GB_stPopulationSize = "GB_stPopulationSize";
export const GB_stPopulationSizeFemale = "GB_stPopulationSizeFemale";
export const GB_stPopulationSizeMale = "GB_stPopulationSizeMale";
export const GB_stPopulationsLowercase = "GB_stPopulationsLowercase";
export const GB_stPotentialUsersWhoInitiated = "GB_stPotentialUsersWhoInitiated";
export const GB_stPotUsersLessThan100 = "GB_stPotUsersLessThan100";
export const GB_stPregnantWomenDef = "GB_stPregnantWomenDef";
export const GB_stPregTestUrine = "GB_stPregTestUrine";
export const GB_stPreinitCosts = "GB_stPreinitCosts";
export const GB_stPrEPCost = "GB_stPrEPCost";
export const GB_stPrEPCostInfectAvtd = "GB_stPrEPCostInfectAvtd";
export const GB_stPrEPCT = "GB_stPrEPCT";
export const GB_stPrEPCTDescription = "GB_stPrEPCTDescription";
export const GB_stPrEPCurr = "GB_stPrEPCurr";
export const GB_stPrEPDrugLevelTest = "GB_stPrEPDrugLevelTest";
export const GB_stPrEPDrugSupply = "GB_stPrEPDrugSupply";
export const GB_stPrEPEfficacy = "GB_stPrEPEfficacy";
export const GB_stPrEPInitiations = "GB_stPrEPInitiations";
export const GB_stPrEPit = "GB_stPrEPit";
export const GB_stPrEPitDataTemplateUploaded = "GB_stPrEPitDataTemplateUploaded";
export const GB_stPrEPitForCop25 = "GB_stPrEPitForCop25";
export const GB_stPrEPitPSE = "GB_stPrEPitPSE";
export const GB_stPrEPitPSEToolDescription = "GB_stPrEPitPSEToolDescription";
export const GB_stPrEPitStandsFor = "GB_stPrEPitStandsFor";
export const GB_stPrEPitToolDescription = "GB_stPrEPitToolDescription";
export const GB_stPrEPitWizard = "GB_stPrEPitWizard";
export const GB_stPrEPNEW = "GB_stPrEPNEW";
export const GB_stPrEPNewDescription = "GB_stPrEPNewDescription";
export const GB_stPrEPPersYearCost = "GB_stPrEPPersYearCost";
export const GB_stPrEPRecipients = "GB_stPrEPRecipients";
export const GB_stPrEPRecipientsDescription = "GB_stPrEPRecipientsDescription";
export const GB_stPrev10to14 = "GB_stPrev10to14";
export const GB_stPrev15to24 = "GB_stPrev15to24";
export const GB_stPrevAmongAGYW = "GB_stPrevAmongAGYW";
export const GB_stPrimaryIA = "GB_stPrimaryIA";
export const GB_stPriorityPop = "GB_stPriorityPop";
export const GB_stPriorPops = "GB_stPriorPops";
export const GB_stPriorPopsParenS = "GB_stPriorPopsParenS";
export const GB_stPrivateClinics = "GB_stPrivateClinics";
export const GB_stProgdataMonForecasting = "GB_stProgdataMonForecasting";
export const GB_stProgDataNotUploadedForMethods = "GB_stProgDataNotUploadedForMethods";
export const GB_stProgDataNotUploadedForPopulations = "GB_stProgDataNotUploadedForPopulations";
export const GB_stProgDataPeriod = "GB_stProgDataPeriod";
export const GB_stProgDataTemplateMismatch = "GB_stProgDataTemplateMismatch";
export const GB_stProgramData = "GB_stProgramData";
export const GB_stPropAllVisitsCont = "GB_stPropAllVisitsCont";
export const GB_stPropAllVisitsInit = "GB_stPropAllVisitsInit";
export const GB_stPropMaxImpAchieved = "GB_stPropMaxImpAchieved";
export const GB_stProposedCoverage = "GB_stProposedCoverage";
export const GB_stProvideBiomedPrevAGYWQuestion = "GB_stProvideBiomedPrevAGYWQuestion";
export const GB_stProvinceName = "GB_stProvinceName";
export const GB_stPublicHealthCenters = "GB_stPublicHealthCenters";
export const GB_stPublicHospitals = "GB_stPublicHospitals";
export const GB_stPW = "GB_stPW";
export const GB_stQuestionsAboutVersionsEmail = "GB_stQuestionsAboutVersionsEmail";
export const GB_stQuickGuideCommodities = "GB_stQuickGuideCommodities";
export const GB_stQuickGuides = "GB_stQuickGuides";
export const GB_stQuickGuideSetTargets = "GB_stQuickGuideSetTargets";
export const GB_stQuickGuidesIntro = "GB_stQuickGuidesIntro";
export const GB_stQuickGuideTargCosts = "GB_stQuickGuideTargCosts";
export const GB_stRangeNotInTargSetPeriod = "GB_stRangeNotInTargSetPeriod";
export const GB_stRapidSyphTest = "GB_stRapidSyphTest";
export const GB_stRatioActualMaxImpact = "GB_stRatioActualMaxImpact";
export const GB_stRatioPreInitToInit = "GB_stRatioPreInitToInit";
export const GB_stReEnterAnnualAboveSiteCosts = "GB_stReEnterAnnualAboveSiteCosts";
export const GB_stReference = "GB_stReference";
export const GB_stRegional = "GB_stRegional";
export const GB_stRegName = "GB_stRegName";
export const GB_stRegularPartners = "GB_stRegularPartners";
export const GB_stRemoveContCurveNote = "GB_stRemoveContCurveNote";
export const GB_stRemoveContVisitNote = "GB_stRemoveContVisitNote";
export const GB_stRemoveContVisitSchedNote = "GB_stRemoveContVisitSchedNote";
export const GB_stRemoveLabTestNote = "GB_stRemoveLabTestNote";
export const GB_stRemoveMethodNote = "GB_stRemoveMethodNote";
export const GB_stRemoveMinPatNote = "GB_stRemoveMinPatNote";
export const GB_stRemovePersTypeNote = "GB_stRemovePersTypeNote";
export const GB_stRemovePriorPopNote = "GB_stRemovePriorPopNote";
export const GB_stRemoveScaleUpTrendNote = "GB_stRemoveScaleUpTrendNote";
export const GB_stRemoveServDelivStratNote = "GB_stRemoveServDelivStratNote";
export const GB_stRemoveServDelivUnitNote = "GB_stRemoveServDelivUnitNote";
export const GB_stRemoveStaffTypeNote = "GB_stRemoveStaffTypeNote";
export const GB_stReportingPeriod = "GB_stReportingPeriod";
export const GB_stRequired = "GB_stRequired";
export const GB_stResetToDefault = "GB_stResetToDefault";
export const GB_stResources = "GB_stResources";
export const GB_stRestarts = "GB_stRestarts";
export const GB_stRestoreDefImpFactors = "GB_stRestoreDefImpFactors";
export const GB_stResults = "GB_stResults";
export const GB_stResultsDashboard = "GB_stResultsDashboard";
export const GB_stRing = "GB_stRing";
export const GB_stRingCosts = "GB_stRingCosts";
export const GB_stRingLower = "GB_stRingLower";
export const GB_stRings = "GB_stRings";
export const GB_stRPRTitre = "GB_stRPRTitre";
export const GB_stSalaries = "GB_stSalaries";
export const GB_stSalary = "GB_stSalary";
export const GB_stSaveWork = "GB_stSaveWork";
export const GB_stSaveWorkPara1 = "GB_stSaveWorkPara1";
export const GB_stScaleUp = "GB_stScaleUp";
export const GB_stScaleUpTrend = "GB_stScaleUpTrend";
export const GB_stScaleUpTrends = "GB_stScaleUpTrends";
export const GB_stScaleUpType = "GB_stScaleUpType";
export const GB_stScenarioColon = "GB_stScenarioColon";
export const GB_stSchedule = "GB_stSchedule";
export const GB_stSDC = "GB_stSDC";
export const GB_stSDCAbbr = "GB_stSDCAbbr";
export const GB_stSDCOptions = "GB_stSDCOptions";
export const GB_stSDCs = "GB_stSDCs";
export const GB_stSDCsCC = "GB_stSDCsCC";
export const GB_stSecondaryIA = "GB_stSecondaryIA";
export const GB_stSeeProcGuide = "GB_stSeeProcGuide";
export const GB_stSelDelivStratFrDetCosts = "GB_stSelDelivStratFrDetCosts";
export const GB_stSelectContCurves = "GB_stSelectContCurves";
export const GB_stSelectContVisits = "GB_stSelectContVisits";
export const GB_stSelectContVisitScheds = "GB_stSelectContVisitScheds";
export const GB_stSelectCountry = "GB_stSelectCountry";
export const GB_stSelectDataElements = "GB_stSelectDataElements";
export const GB_stSelectImpactFactorDerived = "GB_stSelectImpactFactorDerived";
export const GB_stSelectLabTestForContVisit = "GB_stSelectLabTestForContVisit";
export const GB_stSelectLabTests = "GB_stSelectLabTests";
export const GB_stSelectMethods = "GB_stSelectMethods";
export const GB_stSelectMethodsDisplay = "GB_stSelectMethodsDisplay";
export const GB_stSelectMinPats = "GB_stSelectMinPats";
export const GB_stSelectOptionsToUse = "GB_stSelectOptionsToUse";
export const GB_stSelectPersTypes = "GB_stSelectPersTypes";
export const GB_stSelectPriorPops = "GB_stSelectPriorPops";
export const GB_stSelectPriorPopsByDistrictPSE = "GB_stSelectPriorPopsByDistrictPSE";
export const GB_stSelectPriorPopsPSE = "GB_stSelectPriorPopsPSE";
export const GB_stSelectScaleUpTrends = "GB_stSelectScaleUpTrends";
export const GB_stSelectServDelivStrats = "GB_stSelectServDelivStrats";
export const GB_stSelectServDelivUnits = "GB_stSelectServDelivUnits";
export const GB_stSelectStaffTypes = "GB_stSelectStaffTypes";
export const GB_stSelectTargSetOption = "GB_stSelectTargSetOption";
export const GB_stSeptAbbr = "GB_stSeptAbbr";
export const GB_stSeptember = "GB_stSeptember";
export const GB_stServDelivStrat = "GB_stServDelivStrat";
export const GB_stServDelivStratExplan = "GB_stServDelivStratExplan";
export const GB_stServDelivStrats = "GB_stServDelivStrats";
export const GB_stServDelivUnit = "GB_stServDelivUnit";
export const GB_stServDelivUnits = "GB_stServDelivUnits";
export const GB_stServiceDeliveryUnit = "GB_stServiceDeliveryUnit";
export const GB_stSessionDownloadDate = "GB_stSessionDownloadDate";
export const GB_stSessionUploaded = "GB_stSessionUploaded";
export const GB_stSetAGYWTargsBasedOn = "GB_stSetAGYWTargsBasedOn";
export const GB_stSetDateRange = "GB_stSetDateRange";
export const GB_stSetTargets = "GB_stSetTargets";
export const GB_stSetTargetsBasedOnCov = "GB_stSetTargetsBasedOnCov";
export const GB_stSetTargsNatLevel = "GB_stSetTargsNatLevel";
export const GB_stSetUp = "GB_stSetUp";
export const GB_stSeveralCurvesPriorStudies = "GB_stSeveralCurvesPriorStudies";
export const GB_stSexSelectionConstraint = "GB_stSexSelectionConstraint";
export const GB_stShowProgDataPeriod = "GB_stShowProgDataPeriod";
export const GB_stSource = "GB_stSource";
export const GB_stSourceAssum = "GB_stSourceAssum";
export const GB_stSourceAssumPop = "GB_stSourceAssumPop";
export const GB_stSourceAssumPrev = "GB_stSourceAssumPrev";
export const GB_stSpecContVisitMonthsAfterInit = "GB_stSpecContVisitMonthsAfterInit";
export const GB_stSpecialVersionOfPrEP = "GB_stSpecialVersionOfPrEP";
export const GB_stSpecifyAboveSiteCostCat = "GB_stSpecifyAboveSiteCostCat";
export const GB_stSpecifyASubnatLevel = "GB_stSpecifyASubnatLevel";
export const GB_stSpecifyASubnatLevelToViewTable = "GB_stSpecifyASubnatLevelToViewTable";
export const GB_stSpecifyCostsAs = "GB_stSpecifyCostsAs";
export const GB_stSpecifySubnatLevelOneToSeeTable = "GB_stSpecifySubnatLevelOneToSeeTable";
export const GB_stSpecifyUnit = "GB_stSpecifyUnit";
export const GB_stSpecifyUnitCostsEachCat = "GB_stSpecifyUnitCostsEachCat";
export const GB_stSpecMembersEligible = "GB_stSpecMembersEligible";
export const GB_stSpecSalary = "GB_stSpecSalary";
export const GB_stSpecSubnatLevels = "GB_stSpecSubnatLevels";
export const GB_stSrcNoteRegAvgDHSNotAvail = "GB_stSrcNoteRegAvgDHSNotAvail";
export const GB_stSShaped = "GB_stSShaped";
export const GB_stStaffAMinPerCont = "GB_stStaffAMinPerCont";
export const GB_stStaffAMinPerInit = "GB_stStaffAMinPerInit";
export const GB_stStaffBMinPerCont = "GB_stStaffBMinPerCont";
export const GB_stStaffBMinPerInit = "GB_stStaffBMinPerInit";
export const GB_stStaffCap = "GB_stStaffCap";
export const GB_stStaffType = "GB_stStaffType";
export const GB_stStaffTypeA = "GB_stStaffTypeA";
export const GB_stStaffTypeB = "GB_stStaffTypeB";
export const GB_stStaffTypes = "GB_stStaffTypes";
export const GB_stStandard = "GB_stStandard";
export const GB_stStart = "GB_stStart";
export const GB_stStartAge = "GB_stStartAge";
export const GB_stStartMonth = "GB_stStartMonth";
export const GB_stStartYear = "GB_stStartYear";
export const GB_stSTIStymptoms = "GB_stSTIStymptoms";
export const GB_stSTISymptomsDef = "GB_stSTISymptomsDef";
export const GB_stStrategy = "GB_stStrategy";
export const GB_stSubNatGeo = "GB_stSubNatGeo";
export const GB_stSubnationalTargets = "GB_stSubnationalTargets";
export const GB_stSubnatNamesDontMatchMsg = "GB_stSubnatNamesDontMatchMsg";
export const GB_stSurplusHoursBottleneck = "GB_stSurplusHoursBottleneck";
export const GB_stSwitchModesDataLoss = "GB_stSwitchModesDataLoss";
export const GB_stTargetCoverage = "GB_stTargetCoverage";
export const GB_stTargetMustStartAfterProgram = "GB_stTargetMustStartAfterProgram";
export const GB_stTargets = "GB_stTargets";
export const GB_stTargetsByMethod = "GB_stTargetsByMethod";
export const GB_stTargetsByPopulation = "GB_stTargetsByPopulation";
export const GB_stTargetSettingPeriod = "GB_stTargetSettingPeriod";
export const GB_stTargetsInfo1 = "GB_stTargetsInfo1";
export const GB_stTargetsInfo2 = "GB_stTargetsInfo2";
export const GB_stTargetsInfo3 = "GB_stTargetsInfo3";
export const GB_stTargetsInfo4 = "GB_stTargetsInfo4";
export const GB_stTargetsNotEnteredMsg = "GB_stTargetsNotEnteredMsg";
export const GB_stTargetStartDateWarning = "GB_stTargetStartDateWarning";
export const GB_stTargetValue = "GB_stTargetValue";
export const GB_stTargInd = "GB_stTargInd";
export const GB_stTargsDisagBy = "GB_stTargsDisagBy";
export const GB_stTargsDisagDistrictPriorPops = "GB_stTargsDisagDistrictPriorPops";
export const GB_stTargSet = "GB_stTargSet";
export const GB_stTargSetScaleUpTrends = "GB_stTargSetScaleUpTrends";
export const GB_stTargSettingOption = "GB_stTargSettingOption";
export const GB_stTemplateSuccessUploaded = "GB_stTemplateSuccessUploaded";
export const GB_stTestLTBIAdultHH = "GB_stTestLTBIAdultHH";
export const GB_stTestLTBIAdultHRGs = "GB_stTestLTBIAdultHRGs";
export const GB_stTestLTBIChildHH = "GB_stTestLTBIChildHH";
export const GB_stTestLTBIChildHRGs = "GB_stTestLTBIChildHRGs";
export const GB_stTestLTBIExistAdultARTPat = "GB_stTestLTBIExistAdultARTPat";
export const GB_stTestLTBIExistChildARTPat = "GB_stTestLTBIExistChildARTPat";
export const GB_stTestNewAdultARTPat = "GB_stTestNewAdultARTPat";
export const GB_stTestNewChildARTPat = "GB_stTestNewChildARTPat";
export const GB_stTimeframeIndicators = "GB_stTimeframeIndicators";
export const GB_stTo = "GB_stTo";
export const GB_stToDownloadHelpManual = "GB_stToDownloadHelpManual";
export const GB_stToDownloadInputTracker = "GB_stToDownloadInputTracker";
export const GB_stTotal = "GB_stTotal";
export const GB_stTotalCost = "GB_stTotalCost";
export const GB_stTotalCostInclPreg = "GB_stTotalCostInclPreg";
export const GB_stTotalCosts = "GB_stTotalCosts";
export const GB_stTotalCostSavings = "GB_stTotalCostSavings";
export const GB_stTotalIA = "GB_stTotalIA";
export const GB_stTotalInfAverted = "GB_stTotalInfAverted";
export const GB_stTotalInitiations = "GB_stTotalInitiations";
export const GB_stTotalNumUnitsTargSetPeriod = "GB_stTotalNumUnitsTargSetPeriod";
export const GB_stTotalPotentialUsers = "GB_stTotalPotentialUsers";
export const GB_stTotalPrEPBelowThreshold = "GB_stTotalPrEPBelowThreshold";
export const GB_stTotalRestarts = "GB_stTotalRestarts";
export const GB_stTotalSavingsAllAreas = "GB_stTotalSavingsAllAreas";
export const GB_stTotalSavingsBelowThreshold = "GB_stTotalSavingsBelowThreshold";
export const GB_stTotalUnitsDisp = "GB_stTotalUnitsDisp";
export const GB_stTotMonthVisitCap = "GB_stTotMonthVisitCap";
export const GB_stTP = "GB_stTP";
export const GB_stTPAbbr = "GB_stTPAbbr";
export const GB_stTrainingAnnLumpSum = "GB_stTrainingAnnLumpSum";
export const GB_stTrainingCat = "GB_stTrainingCat";
export const GB_stTrendA = "GB_stTrendA";
export const GB_stTrendB = "GB_stTrendB";
export const GB_stTrendC = "GB_stTrendC";
export const GB_stTrends = "GB_stTrends";
export const GB_stTSPMustBeGreaterThanCFP = "GB_stTSPMustBeGreaterThanCFP";
export const GB_stTSPMustBeGreaterThanDRI = "GB_stTSPMustBeGreaterThanDRI";
export const GB_stTSPStartAfterPDPStart = "GB_stTSPStartAfterPDPStart";
export const GB_stTW = "GB_stTW";
export const GB_stTwoStep = "GB_stTwoStep";
export const GB_stTypeA = "GB_stTypeA";
export const GB_stTypeB = "GB_stTypeB";
export const GB_stUNITCOMMCosts = "GB_stUNITCOMMCosts";
export const GB_stUnitCost = "GB_stUnitCost";
export const GB_stUnitCosts = "GB_stUnitCosts";
export const GB_stUnitOfCommodity = "GB_stUnitOfCommodity";
export const GB_stUpdatesButtonText = "GB_stUpdatesButtonText";
export const GB_stUploadAreaFile = "GB_stUploadAreaFile";
export const GB_stUploadAreaTemplate = "GB_stUploadAreaTemplate";
export const GB_stUploadAreaTemplateBtnText = "GB_stUploadAreaTemplateBtnText";
export const GB_stUploadDataEntryTemplate = "GB_stUploadDataEntryTemplate";
export const GB_stUploadDataTemplateToUse = "GB_stUploadDataTemplateToUse";
export const GB_stUploadNumClientsQuestion = "GB_stUploadNumClientsQuestion";
export const GB_stUploadSession = "GB_stUploadSession";
export const GB_stUploadTemplate = "GB_stUploadTemplate";
export const GB_stUploadTemplateError = "GB_stUploadTemplateError";
export const GB_stUSD = "GB_stUSD";
export const GB_stUseCostPerPYFromMode = "GB_stUseCostPerPYFromMode";
export const GB_stUseOriginalMode = "GB_stUseOriginalMode";
export const GB_stUsePSEMode = "GB_stUsePSEMode";
export const GB_stUsePSEOrOriginalTitle = "GB_stUsePSEOrOriginalTitle";
export const GB_stUseSubnatGeoPrior = "GB_stUseSubnatGeoPrior";
export const GB_stVaryContCurvesByArea = "GB_stVaryContCurvesByArea";
export const GB_stVaryPrEPCostDetCosts = "GB_stVaryPrEPCostDetCosts";
export const GB_stVersion = "GB_stVersion";
export const GB_stVersionHistory = "GB_stVersionHistory";
export const GB_stVeryHighIncidence = "GB_stVeryHighIncidence";
export const GB_stViewCompleteHelp = "GB_stViewCompleteHelp";
export const GB_stVisits = "GB_stVisits";
export const GB_stVisitSchedules = "GB_stVisitSchedules";
export const GB_stVisitsSuppliesCosts = "GB_stVisitsSuppliesCosts";
export const GB_stWarning = "GB_stWarning";
export const GB_stWelcomePageImage = "GB_stWelcomePageImage";
export const GB_stWelcomePrEPit = "GB_stWelcomePrEPit";
export const GB_stWhatIsPrEPit = "GB_stWhatIsPrEPit";
export const GB_stWhoCanUse = "GB_stWhoCanUse";
export const GB_stWhoCanUsePara1 = "GB_stWhoCanUsePara1";
export const GB_stWillYouSetTargets = "GB_stWillYouSetTargets";
export const GB_stWizard = "GB_stWizard";
export const GB_stYear1 = "GB_stYear1";
export const GB_stYear2 = "GB_stYear2";
export const GB_stYear3 = "GB_stYear3";
export const GB_stYear4 = "GB_stYear4";
export const GB_stYear5 = "GB_stYear5";
export const GB_stYes = "GB_stYes";
export const GB_stYourContCurves = "GB_stYourContCurves";
export const GB_stYourContVisits = "GB_stYourContVisits";
export const GB_stYourContVisitScheds = "GB_stYourContVisitScheds";
export const GB_stYourLabTests = "GB_stYourLabTests";
export const GB_stYourMethods = "GB_stYourMethods";
export const GB_stYourMinPats = "GB_stYourMinPats";
export const GB_stYourPersTypes = "GB_stYourPersTypes";
export const GB_stYourPriorPops = "GB_stYourPriorPops";
export const GB_stYourScaleUpTrends = "GB_stYourScaleUpTrends";
export const GB_stYourServDelivStrats = "GB_stYourServDelivStrats";
export const GB_stYourServDelivUnits = "GB_stYourServDelivUnits";
export const GB_stYourStaffTypes = "GB_stYourStaffTypes";
export const PI_stAccessStdVersion = "PI_stAccessStdVersion";