import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import * as gbtu from "../../GB/GBTableUtil";
import * as gbtc from "../../GB/GBTableConst";
import * as pic from "../../PI/NonComponents/PIConst"
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pitu from "../NonComponents/PITableUtil";
import * as gbu from "../../GB/GBUtil";

import TComboBox from "../../common/TComboBox";
import TButton from "../../common/TButton";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const SpecifyCostsAsValues = ["lump-sum", "percentage"];

function createAboveSiteCostCategory(name) {
  return {
    name,
    costs: [
      {
        period: RS(SC.GB_stYear1),
        value: 0,
      },
      {
        period: RS(SC.GB_stYear2),
        value: 0,
      },
      {
        period: RS(SC.GB_stYear3),
        value: 0,
      },
      {
        period: RS(SC.GB_stYear4),
        value: 0,
      },
      {
        period: RS(SC.GB_stYear5),
        value: 0,
      },
    ],
  };
}

export function defaultAboveSiteCostCategories() {
  const defaultCosts = {}
  defaultCosts.units = SpecifyCostsAsValues[0];
  defaultCosts.categories = [
    RS(SC.GB_stTrainingCat),
    RS(SC.GB_stDemandCreationCat),
    RS(SC.GB_stManagementCat),
  ].map((v) => createAboveSiteCostCategory(v));

  return defaultCosts
}

export default class PICostCatAboveSiteTable extends Component {
  static propTypes = {
    modVarObjList: PropTypes.arrayOf(PropTypes.object),
    onModVarsChange: PropTypes.func,
    categoryCosts: PropTypes.shape({
      "lump-sum": PropTypes.object,
      percentage: PropTypes.object
    }),
    onCategoryCostsChange: PropTypes.func,
    tableKey: PropTypes.string,
  };

  static defaultProps = {
    modVarObjList: [],
    onModVarsChange: PropTypes.func,
    categoryCosts: {
      "lump-sum": null,
      percentage: null,
    },
    onCategoryCostsChange: () => console.log(`PICostCatAboveSiteTable::onCategoryCostsChange`),
    tableKey: "",
  };

  state = {
    rdec: [],
  };

  componentDidMount() {
    this.setState((state, props) => {
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
      
      const annualSiteCostsMV = piasu.getModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts");
      // Already initialised?
      if (annualSiteCostsMV.categories != null) return {};

      piasu.setModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts", defaultAboveSiteCostCategories());
      onModVarsChange(modVarObjArrClone);

      return {};
    });
  }

  onPackTableChange = (newPackTable) => {
    try {
      const onModVarsChange = this.props[pias.onModVarsChange];

      let modVarObjArrClone = structuredClone(this.props[pias.modVarObjList]);

      const annualSiteCostsMV = piasu.getModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts");

      for (let row = 1; row < gbtu.getNumRows(newPackTable); ++row) {
        const categoryName = gbtu.getValue(newPackTable, row, 1);

        const cat = annualSiteCostsMV.categories[row - 1];
        if (cat == null) throw new Error("Table data corrupted");

        cat.name = categoryName;

        const multiplier = annualSiteCostsMV.units === "percentage" ? 100 : 1;
        for (let col = 2; col < gbtu.getNumCols(newPackTable) - 1; ++col) {
          const colVal = gbtu.getValue(newPackTable, row, col) / multiplier;
          cat.costs[col - 2].value = colVal;
        }
      }

      piasu.setModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts", annualSiteCostsMV);

      // Clear costs re-enter notification flag
      if (piasu.getModVarExists(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCostsReEnter"))
        piasu.setModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCostsReEnter", false);

      this.setState(
        {
          rdec: newPackTable.RDec,
        },
        () => {
          onModVarsChange(modVarObjArrClone);
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDeleteClick = (row) => {
    try {
      const onModVarsChange = this.props[pias.onModVarsChange];

      const rdec = structuredClone(this.state.rdec);
      if (rdec !== undefined) rdec.splice(row-1, 1);
      const categoryCosts = structuredClone(this.props.categoryCosts)
      for (let unit in categoryCosts) {
        categoryCosts[unit]?.splice(row-1,1)
      }

      let modVarObjArrClone = structuredClone(this.props[pias.modVarObjList]);

      const annualSiteCostsMV = piasu.getModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts");

      annualSiteCostsMV.categories.splice(row - 1, 1);
      piasu.setModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts", annualSiteCostsMV);

      this.setState({ rdec }, () => {
        this.props.onCategoryCostsChange(categoryCosts, () => {
          onModVarsChange(modVarObjArrClone);
        })
      });
    } catch (err) {
      alert(err.message);
    }
  };

  onAddCategoryClick = () => { 
    try { 
      // Update cache
      const categoryCosts = structuredClone(this.props.categoryCosts)
  
      for (const unit in categoryCosts) {          
        if (categoryCosts[unit] === null)
          continue
          
        categoryCosts[unit].push(createAboveSiteCostCategory(RS(SC.GB_stNewCategory)).costs)
      }

      this.props.onCategoryCostsChange(categoryCosts, () => {
        const modVarObjArrClone = structuredClone(this.props.modVarObjList);
        
        const annualSiteCostsMV = piasu.getModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts");        
        annualSiteCostsMV.categories.push(createAboveSiteCostCategory(RS(SC.GB_stNewCategory)));
        
        piasu.setModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts", annualSiteCostsMV);
        this.props.onModVarsChange(modVarObjArrClone);
      })
    } catch (err) {
      alert(err.message);
    }
  };

  onCostTypeChange = (value) => {  
    // Save current costs to allow switching (whilst remaining on form).
    const annualSiteCostsMV = piasu.getModVarValue(this.props.modVarObjList, "PI_ProgramWideAnnualAboveSiteCosts");      

    const categoryCosts = {
      ...this.props.categoryCosts,
      [annualSiteCostsMV.units]: structuredClone(annualSiteCostsMV.categories.map(v => v.costs)),
    }

    this.props.onCategoryCostsChange(categoryCosts, () => {
        this.setState({rdec: []})

        const modVarObjArrClone = structuredClone(this.props.modVarObjList);
        const annualSiteCostsMV = piasu.getModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts");

        annualSiteCostsMV.units = SpecifyCostsAsValues[value] ?? SpecifyCostsAsValues[0];
        
        if (
          this.props.categoryCosts[annualSiteCostsMV.units] !== null &&
          annualSiteCostsMV.categories.length !== this.props.categoryCosts[annualSiteCostsMV.units].length
        )
          throw new Error("Mismatched lump-sum categories length. Unable to restore");

        // Restore or zero out costs
        for (let i=0; i < annualSiteCostsMV.categories.length; ++i) {
          const category = annualSiteCostsMV.categories[i]
          const prevCosts = this.props.categoryCosts[annualSiteCostsMV.units]?.[i]
          if (prevCosts === undefined)
            category.costs = category.costs.map(v => ({...v, value: 0}))
          else
            category.costs = structuredClone(prevCosts)
        }

        piasu.setModVarValue(modVarObjArrClone, "PI_ProgramWideAnnualAboveSiteCosts", annualSiteCostsMV);

        this.props.onModVarsChange(modVarObjArrClone);
      }
    );
  };

  renderTable(current) {
    const fn = () => {
      const rows = current?.categories ?? [];
      if (rows.length === 0) return null;

      const DeleteButton = ({ id, row }) => (
        <IconButton
          key={id}
          aria-label={"delete"}
          style={{
            marginLeft: 14,
            marginTop: -14, // FIXME: Not sure why this is necessary, but the button is offset out of the row otherwise
          }}
          onClick={() => this.onDeleteClick(row)}
        >
          <DeleteIcon
            style={{
              color: Theme.PI_TertiaryColor,
            }}
          />
        </IconButton>
      );

      const years = 5;

      let packTable = gbtu.getNewPackTable();
      packTable = gbtu.resizePackTable(packTable, rows.length + 1, years + 3);

      // This has to be before any cell locking, otherwise locked cells will have their color overridden
      // pitu.colorizeTable(packTable);

      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, 1, 275);
      gbtu.lockCol(packTable, 7, true, false);

      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);

      // Set column headers
      gbtu.setValue(packTable, 0, 1, RS(SC.GB_stCategoryHeader));
      for (let col = 0; col < rows[0].costs.length; ++col) {
        gbtu.setValue(packTable, 0, col + 2, rows[0].costs[col].period);
      }

      const multiplier = current.units === "percentage" ? 100 : 1;
      for (let row = 1; row <= rows.length; ++row) {
        const catInfo = rows[row - 1];
        const headerName = catInfo.name;

        gbtu.setValue(packTable, row, 1, headerName);
        for (let col = 2; col <= years + 1; ++col) {
          gbtu.setValue(packTable, row, col, catInfo.costs[col - 2].value * multiplier);
        }
        packTable.components[row][7] = () => <DeleteButton id={`delbtn${row}`} row={row} />;
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);

      gbtu.restoreRDecsFromCopy(packTable, this.state.rdec, current.units === "lump-sum" ? 0 : 2)
      
      gbtu.setRowHeight(packTable, 0, 120);
      gbtu.setColWidths(packTable, Theme.dataColWidthLarge);
      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, 7, Theme.dataColWidthSmall);
      gbtu.setColAlignment(packTable, 7, gbtc.hAlign.center);

      gbtu.setID(packTable, "packTable");

      // FIXME: We should use PercRDec and "p" type for percentage
      // ...repeat(current.units === "lump-sum" ? "n" : "p", packTable.GBColCount - packTable.GBFixedCols - 2),
      return (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, [
            ...repeat("s", packTable.GBFixedCols + 1),
            ...repeat("n", packTable.GBColCount - packTable.GBFixedCols - 2),
            "cm",
          ])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.topIndent,
            padding: 0,
          }}
          limitWidthToContainer={true}
          undoDisabled={true}
        />
      );
    };

    return gbu.tryRenderFn(fn, "render PICostCatAboveSiteTable");
  }

  render() {
    const modVarObjList = this.props[pias.modVarObjList];

    const annualSiteCostsMV = piasu.getModVarValue(modVarObjList, "PI_ProgramWideAnnualAboveSiteCosts");

    const specifyAs = annualSiteCostsMV?.units ?? SpecifyCostsAsValues[0];
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TComboBox
            caption={RS(SC.GB_stSpecifyCostsAs)}
            items={[RS(SC.GB_stLumpSumAmount), RS(SC.GB_stPercentageOfTotal)]}
            itemIndex={SpecifyCostsAsValues.indexOf(specifyAs)}
            onChange={this.onCostTypeChange}
            style={{ width: "fit-content" }}
          />
          {this.renderTable(annualSiteCostsMV)}
          <TButton
            caption={RS(SC.GB_stAddCategory)}
            key={"addCatBtn"}
            onClick={this.onAddCategoryClick}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              display: "block",
              marginBottom: 20,
              // marginLeft: Theme.leftIndent,
              marginTop: Theme.topIndent,
              width: 200,
            }}
            disabled={annualSiteCostsMV?.categories?.length >= pic.MaxAboveSiteLumpSumCostCategories}
          />
        </div>
      </React.Fragment>
    );
  }
}
