import deepmerge from "deepmerge";
import * as React from "react";

import { getUserLanguageCode, setUserLanguage } from "../../../data/strings/global";

import { getCountries, getModVar, getVersion } from "../../../api/server_calls";

import GBApp from "../../../app/GBApp";
import * as GBConst from "../../GB/GBConst";
import * as gbu from "../../GB/GBUtil";

import TAPIDialog from "../../common/TAPIDialog";
import TSplashScreen from "../../common/TAppSplash";
import TDialog from "../../common/TDialog";

import * as Theme from "../../../app/Theme";
import * as SC from "../../../data/strings/PIStringConst";
import { RS } from "../../../data/strings/global";
import HelpWindow from "../../common/HelpWindow";
import TWifiAnimation from "../../common/TWifiAnimation";
import * as pias from "../NonComponents/PIAppState";
import { createAppState, styleObj } from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import { getModVarValue } from "../NonComponents/PIAppStateUtil";
import { onCalculate, onCalculateCostDetailedTable, onCalculateCostsLiteTable } from "../NonComponents/PICalc";
import * as pic from "../NonComponents/PIConst";
import * as pih from "../NonComponents/PIHelp";
import * as pisc from "../NonComponents/PIServerConst";
import PIRedirectPage from "../Other/PIRedirectPage";
import MainWrapper from "./MainWrapper";

const initalizeUserLanguage = () => {
  const userLanguageCode = getUserLanguageCode();

  if (userLanguageCode) {
    setUserLanguage(userLanguageCode);
  }
};

const createCountryComboBoxItems = (countryItems) =>
  [
    {
      ISONumeric: -1,
      Country: RS(SC.GB_stSelectCountry),
      ISOAlpha: "",
    },
  ].concat(countryItems);

class PIApp extends GBApp {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  state = {
    ...deepmerge(this.state, createAppState()),
    language: getUserLanguageCode(),
  };

  componentDidMount() {
    initalizeUserLanguage();

    this.onResize();
    window.addEventListener("resize", this.onResize);

    /* To turn PIRedirectPage completely off (for live site, for example), set showRedirectBool to false below.
     *
     *  To turn it on but not allow the user to continue with the beta version, set showRedirectBool to true
     *  below and forNormalBetaSiteBool to false in PIRedirectPage. Set forNormalBetaSiteBool to true to
     *  allow the user to continue with the beta.
     *
     * */

    this.onRunInitialRESTCalls(() => {
      this.setState({
        [pias.pageID]: pic.welcomeForm,
        [pias.loading]: false,
        [pias.showRedirectBool]: false, // markCheckbox
      });
    });
  }

  setCountries = (countryObjArr, successFn) => {
    let countryObjListClone = createCountryComboBoxItems(countryObjArr);

    this.setState(
      {
        [pias.countryObjList]: countryObjListClone,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  setCountryIndependentModVars = (modVarObjArr, successFn) => {
    this.setState(
      {
        [pias.modVarObjList]: modVarObjArr, //newModVarObjArr,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onRunInitialRESTCalls = (successFn) => {
    /* Country is irrelevant for these getModVar calls, since the ModVars are not by country; just don't want */
    let modVarObjArr = [];

    getVersion(
      (response) => {
        this.setState(
          {
            [pias.versionStr]: response,
          },
          () => {
            const requestedModVarTags = [
              pisc.appModeMVTag,
              pisc.easyStartOptionsMVTag,
              pisc.countryISOMVTag,
              pisc.allInputMVsLoadedMVTag,
            ].join(",");

            getModVar(
              "024",
              requestedModVarTags,
              (modVarsResponse) => {
                modVarObjArr = modVarsResponse[pisc.modVars];
                piasu.setModVarValue(modVarObjArr, pisc.countryISOMVTag, pic.noCountrySelected);

                this.setCountryIndependentModVars(modVarObjArr, () => {
                  getCountries(
                    {},
                    (countryObjArrResponse) => {
                      this.setCountries(countryObjArrResponse, () => {
                        gbu.safeCallFn(successFn(modVarObjArr));
                      });
                    },
                    () => {
                      let dialogObj = pias.getDefaultDialogObj();
                      dialogObj[pias.contentStr] = "An error occurred while retrieving the countries.";
                      dialogObj[pias.headerStr] = RS(SC.GB_stError);
                      dialogObj[pias.maxWidthStr] = "sm";
                      dialogObj[pias.showBool] = true;
                      dialogObj[pias.styleObj] = { width: 500 };

                      this.onDialogChange(dialogObj);
                    }
                  );
                });
              },
              () => {
                let dialogObj = pias.getDefaultDialogObj();
                dialogObj[pias.contentStr] = "An error ocurred while initializing data.";
                dialogObj[pias.headerStr] = RS(SC.GB_stError);
                dialogObj[pias.maxWidthStr] = "sm";
                dialogObj[pias.showBool] = true;
                dialogObj[pias.styleObj] = { width: 500 };

                this.onDialogChange(dialogObj);
              }
            );
          }
        );
      },
      () => {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = "An error ocurred while getting the version number.";
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        this.onDialogChange(dialogObj);
      }
    );
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onModVarChange = (value, modVarTag, successFn) => {
    const modVarObjList = this.state[pias.modVarObjList];
    const modVarObjListClone = structuredClone(modVarObjList);

    /*
      A few ModVars are transformed on the client to make them easier to work with.
      If those ModVars are being changed, transform them back to their server form first.
    */
    if (modVarTag === pisc.targetStartDateMVTag || modVarTag === pisc.targetEndDateMVTag) {
      piasu.setTargSettingPeriodObj(modVarObjListClone, value);
    } else if (modVarTag === pisc.programStartDateMVTag || modVarTag === pisc.programRecentDateMVTag) {
      piasu.setProgDataPeriodObj(modVarObjListClone, value);
    } else if (modVarTag === pisc.dataElementsMVTag) {
      piasu.setDataElements(modVarObjListClone, value);
    } else {
      piasu.setModVarValue(modVarObjListClone, modVarTag, value);
    }

    this.setState(
      {
        [pias.modVarObjList]: modVarObjListClone,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onModVarsChange = (modVarObjList, recalcIfNeeded, successFn, errorFn) => {
    try {
      const state = this.state;
      let modVarObjListStateClone = structuredClone(state[pias.modVarObjList]);
      let origModVarObjArr = structuredClone(state[pias.origModVarObjArr]);
      let tableKeyObjClone = structuredClone(state[pias.tableKeyObj]);
      const recalcBool = state[pias.recalcBool];

      let newModVarObjArr;

      const allInputMVsLoaded = piasu.getModVarValue(modVarObjList, pisc.allInputMVsLoadedMVTag);
      const allInputMVsLoadedOrig = piasu.getModVarValue(origModVarObjArr, pisc.allInputMVsLoadedMVTag);

      if (!allInputMVsLoadedOrig && allInputMVsLoaded) {
        /* First clone the object array we're passing in, since it will contain the original values of all possible ModVars */
        origModVarObjArr = structuredClone(modVarObjList);
        /*
          Merge the existing and passed in modVarObjLists, since the existing
          one may will contain the app mode and easy start options modVars and we
          don't want to overwrite user options. We do want to take the country though.
        */
        piasu.addNewModVars(modVarObjListStateClone, modVarObjList);

        newModVarObjArr = modVarObjListStateClone;

        piasu.setModVarValue(
          newModVarObjArr,
          pisc.countryISOMVTag,
          piasu.getModVarValue(modVarObjList, pisc.countryISOMVTag)
        );
        piasu.setModVarValue(newModVarObjArr, pisc.allInputMVsLoadedMVTag, true);
      } else {
        newModVarObjArr = modVarObjList;
      }

      /* Determine if the ModVars have changed. If so and recalc is true, re-calculate.
               The server currently runs all calculations at once after receiving all ModVars. */
      const modVarsChanged = !gbu.equalObj(modVarObjList, modVarObjListStateClone);

      const costingModeMstID = getModVarValue(newModVarObjArr, pisc.costingModuleMVTag); //m

      /* If recalcIfNeeded is true: The input ModVars don't contain calculated fields
               we need, so calculate immediately after receiving them to insert them. */

      piasu.updateTableKeys(tableKeyObjClone);

      this.setState(
        {
          [pias.modVarObjList]: newModVarObjArr, // m
          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.tableKeyObj]: tableKeyObjClone,
        },
        () => {
          if (recalcIfNeeded && (modVarsChanged || recalcBool)) {
            let state = this.state;
            let origModVarObjArr = structuredClone(state[pias.origModVarObjArr]);

            this.onCalculatingChange(true, () => {
              onCalculate(
                newModVarObjArr,
                "",
                this.onDialogChange,
                (response) => {
                  if (costingModeMstID === pisc.detailedCostModeMstID) {
                    onCalculateCostDetailedTable(
                      response,
                      "",
                      this.onDialogChange,
                      (response2) => {
                        this.setState(
                          {
                            [pias.modVarObjList]: response2,
                            [pias.origModVarObjArr]: origModVarObjArr,
                            [pias.recalcBool]: false,
                          },
                          () => gbu.safeCallFn(successFn)
                        );
                      },
                      () => this.onCalculatingChange(false)
                    );
                  } else if (costingModeMstID === pisc.costsLiteModeMstID) {
                    onCalculateCostsLiteTable(
                      response,
                      "",
                      this.onDialogChange,
                      (response2) => {
                        this.setState(
                          {
                            [pias.modVarObjList]: response2,
                            [pias.origModVarObjArr]: origModVarObjArr,
                            [pias.recalcBool]: false,
                            [pias.calculatingBool]: false,
                          },
                          () => gbu.safeCallFn(successFn)
                        );
                      },
                      () => this.onCalculatingChange(false)
                    );
                  }
                },
                () => {
                  this.setState(
                    {
                      [pias.recalcBool]: true,
                      [pias.calculatingBool]: false,
                    },
                    () => gbu.safeCallFn(errorFn)
                  );
                }
              );
            });
          } else {
            gbu.safeCallFn(successFn);
          }
        }
      );
    } catch (exception) {
      this.onCalculatingChange(false, () => gbu.safeCallFn(errorFn));
    }
  };

  onOrigModVarsChange = (origModVarObjArr, successFn, errorFn) => {
    try {
      this.setState(
        {
          [pias.origModVarObjArr]: origModVarObjArr,
        },
        () => gbu.safeCallFn(successFn)
      );
    } catch (exception) {
      this.onCalculatingChange(false, () => gbu.safeCallFn(errorFn));
    }
  };

  onCalcContCurvePercOnPrEPChange = (calcBool, successFn) => {
    const state = this.state;
    let tableKeyObjClone = structuredClone(state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.calcContCurvePercOnPrEPBool]: calcBool,
        [pias.tableKeyObj]: tableKeyObjClone,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onCalculatingChange = (calcBool, successFn) => {
    const state = this.state;
    let tableKeyObjClone = structuredClone(state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.calculatingBool]: calcBool,
        [pias.tableKeyObj]: tableKeyObjClone,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onDialogChange = (dialogObj, successFn) => {
    this.setState(
      {
        [pias.dialogObj]: dialogObj,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onDialogClose = (modalResult) => {
    let state = this.state;
    let dialogObjClone = window.structuredCloneReview(state[pias.dialogObj]);
    const onCloseEvent = state[pias.dialogObj][pias.onCloseEvent];
    dialogObjClone[pias.showBool] = !dialogObjClone[pias.showBool];

    this.setState(
      {
        [pias.dialogObj]: dialogObjClone,
      },
      () => {
        onCloseEvent(modalResult);
      }
    );
  };

  onHelpAreaChange = (helpAreaStr, successFn) => {
    this.setState(
      {
        [pias.helpAreaStr]: helpAreaStr,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onHelp = () => {
    let helpArea = this.state[pias.helpAreaStr];

    /*
      NOTE: Names on the right are the names of the files here:

      - PrEP-it\client\public\PrEPitHelp\HTML
    */

    let helpPages = {
      [pih.general_HP]: "getting-started",
      [pih.welcome_HP]: "choose-mode",

      /* Aggregate */

      [pih.aggregateFM_HP]: "aggregate",

      /* Configuration */

      [pih.configFM_HP]: "configuration",
      [pih.config_PriorPopSD_HP]: "priority-populations",
      [pih.config_ContSD_HP]: "continuation",
      [pih.config_Cont_DefCurvesTB_HP]: "continuation",
      [pih.config_Cont_AssCurvesTB_HP]: "continuation",
      [pih.config_MethodsSD_HP]: "methods",
      [pih.config_ProgDataSD_HP]: "program-data",
      [pih.config_Cont_ContCurvesSD_HP]: "continuation",
      [pih.config_Cont_RefRD_HP]: "continuation",
      [pih.config_VisitSchedules]: "assign-visit-schedules",

      /* Costs Lite */

      [pih.costsLiteFM_HP]: "enter-costs", //"define-visit-schedules",
      [pih.costsLite_AssContVisTB_HP]: "enter-costs",
      [pih.costsLite_FirstTB_HP]: "enter-costs", //"define-visit-schedules",
      [pih.costsLite_AssContVisSchedTB_HP]: "enter-costs",
      [pih.costsLite_CostsTB_HP]: "enter-costs",
      [pih.costsLite_ResTB_HP]: "enter-costs",
      [pih.costsLite_AssContVis_ContVisSchedSD_HP]: "",
      [pih.costsLite_DefCostPatRD_HP]: "",
      [pih.costsLite_AvgUnitCostRD_HP]: "",

      /* Detailed costs */

      [pih.detCostsFM_HP]: "costs",
      [pih.detCosts_SalariesTB_HP]: "",
      [pih.detCosts_LabTestsContVisTB_HP]: "",
      [pih.detCosts_DefContVisSchedTB_HP]: "",
      [pih.detCosts_AssStratTB_HP]: "",
      [pih.detCosts_CostsTB_HP]: "",
      [pih.detCosts_ResTB_HP]: "",
      [pih.detCosts_DefMinPatRD_HP]: "",
      [pih.detCosts_ServDelivStratSD_HP]: "",
      [pih.detCosts_PersTypesSD_HP]: "",
      [pih.detCosts_LabTestsSD_HP]: "",
      [pih.detCosts_ContVisSD_HP]: "",
      [pih.detCosts_ContVisSchedSD_HP]: "",

      /* AGYW */

      [pih.AGYW_FM_HP]: "agyw",
      [pih.AGYW_ParamsTB_HP]: "agyw",
      [pih.AGYW_InputsByAreaTB_HP]: "inputs-by-area",
      [pih.AGYW_ResTB_HP]: "results-agyw",

      /* Impact */

      [pih.impactFM_HP]: "impact",
      [pih.impact_AssImpFactTB_HP]: "assign-impact-factors",
      [pih.impact_FirstTB_HP]: "assign-impact-factors",
      [pih.impact_ImpactTB_HP]: "impact-adjustment",
      [pih.impact_DefImpFactRD_HP]: "impact",

      /* Targets */

      [pih.targFM_HP]: "targets",
      [pih.targ_PopTB_HP]: "populations",
      [pih.targ_FirstTB_HP]: "populations",
      [pih.targ_DefScaleUpTrendsTB_HP]: "define-scale-up-trends",
      [pih.targ_AssTrendsTB_HP]: "assign-trends",
      [pih.targ_OptTB_HP]: "set-targets",
      [pih.targ_ResTB_HP]: "results-targets",
      [pih.targ_ContRD_HP]: "targets",
      [pih.targ_ScaleUpRD_HP]: "targets",
      [pih.targ_ScaleUpTrendsSD_HP]: "targets",

      /* Result Dashboard */
      [pih.resultDashboard_CostTB_HP]: "results-dashboard",
      [pih.resultDashboard_TargTB_HP]: "results-dashboard",

      /* Disaggregate targets */

      [pih.disagTargFM_HP]: "disaggregate-targets",
      [pih.disagTarg_DisagSetupTB_HP]: "disaggregate-targets", //"disaggregation-setup",
      [pih.disagTarg_FirstTB_HP]: "disaggregate-targets", //"disaggregation-setup",
      [pih.disagTarg_DistPopsTB_HP]: "disaggregate-targets", //"subnational-populations",
      [pih.disagTarg_ResTB_HP]: "disaggregate-targets", //"results-disaggregate",
      [pih.disagTarg_HIV_AgeSexRD_HP]: "disaggregate-targets",

      /* Capacity */

      [pih.capacityFM_HP]: "",

      /* Commodities forecasting */

      [pih.commForecFM_HP]: "commodities-forecasting",
    };

    this.setState({
      [pias.showHelp]: true,
      [pias.helpPage]: helpPages[helpArea] || "getting-started",
    });
  };

  onValidationItemChange = (validationItem, successFn) => {
    this.setState(
      {
        [pias.validationItem]: validationItem,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onResize = () => {
    let screenSize = GBConst.lgScreen;

    if (window.innerWidth <= GBConst.smScreen) {
      screenSize = GBConst.smScreen;
    } else if (window.innerWidth <= GBConst.mdScreen) {
      screenSize = GBConst.mdScreen;
    }

    this.setState({
      [pias.screenSize]: screenSize,
    });
  };

  onShowRedirectChange = (showRedirectBool, successFn) => {
    this.setState(
      {
        [pias.showRedirectBool]: showRedirectBool,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onSideBarArrowClick = (sideBarOpenBool, successFn) => {
    let bool;

    if (typeof sideBarOpenBool === "undefined") {
      bool = !this.state[pias.sideBarOpen];
    } else {
      bool = sideBarOpenBool;
    }

    this.setState(
      {
        [pias.sideBarOpen]: bool,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onPageChange = (pageIDByte, successFn) => {
    this.setState(
      {
        [pias.pageID]: pageIDByte,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onDrawerChange = (drawerIDStr, drawerLvInt, successFn, errorFn) => {
    try {
      const state = this.state;
      let drawerID1DStrArray = structuredClone(state[pias.drawerID1DStrArray]);
      let drawerOpen1DBoolArray = structuredClone(state[pias.drawerOpen1DBoolArray]);

      drawerID1DStrArray[drawerLvInt - 1] = drawerIDStr;
      drawerOpen1DBoolArray[drawerLvInt - 1] = !drawerOpen1DBoolArray[drawerLvInt - 1];

      this.setState(
        {
          [pias.drawerID1DStrArray]: drawerID1DStrArray,
          [pias.drawerOpen1DBoolArray]: drawerOpen1DBoolArray,
        },
        successFn
      );
    } catch (exception) {
      this.onCalculatingChange(false, () => gbu.safeCallFn(errorFn));
    }
  };

  onLanguageChange = (newLanguage) => {
    this.setState({
      language: newLanguage,
      [pias.showHelp]: false,
      [pias.countryObjList]: createCountryComboBoxItems(this.state[pias.countryObjList].slice(1)),
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderCalcDialog = () => {
    const fn = () => {
      let dialog = null;

      const state = this.state;
      const calculatingBool = state[pias.calculatingBool];

      if (calculatingBool) {
        const captionDiv = (
          <div
            style={{
              color: Theme.PI_PrimaryColor,
              fontSize: 20,
            }}
          >
            {"Communicating with server..."}
          </div>
        );

        const wifiAnimationIcon = (
          <TWifiAnimation
            style={{
              marginRight: 20,
              float: "left",
            }}
          />
        );

        const contentDiv = (
          <div>
            <div
              style={{
                fontSize: 16,
                color: Theme.gray,
              }}
            >
              {"Calculating..."}
            </div>

            <div
              style={{
                fontSize: 12,
              }}
            >
              <br />
              {"Working on: Running calculations"}
            </div>
          </div>
        );

        dialog = (
          <TDialog
            caption={captionDiv}
            header={""} // needed for dialog to show icon and caption
            icon={wifiAnimationIcon}
            content={contentDiv}
            modal={true}
            visible={true}
            actions={[]}
            style={{
              width: 575,
            }}
          />
        );
      }

      return dialog;
    };

    return gbu.tryRenderFn(fn, "renderCalcDialog");
  };

  render() {
    /* State */
    const state = this.state;
    const dialogObj = state[pias.dialogObj];
    const loading = state[pias.loading];
    const showRedirectBool = state[pias.showRedirectBool];

    const APIServiceComp = null;

    let mainComp = null;
    let APIDialogComp = null;
    let commWithServerDialog = null;
    let helpDialog = null;
    let calcDialog = null;
    let redirectPage = null;
    let dialogComp = null;

    if (!showRedirectBool) {
      mainComp = (
        <MainWrapper
          key={state.language}
          onLanguageChange={this.onLanguageChange}
          {...{
            [pias.onAddTasks]: this[pias.onAddTasks],

            [pias.appState]: state,
            [pias.onStateChange]: this[pias.onStateChange],

            [pias.onCalcContCurvePercOnPrEPChange]: this[pias.onCalcContCurvePercOnPrEPChange],

            [pias.onCalculatingChange]: this[pias.onCalculatingChange],

            [pias.onCountryChange]: this[pias.onCountryChange],

            [pias.onDialogChange]: this[pias.onDialogChange],

            [pias.onDrawerChange]: this[pias.onDrawerChange],

            [pias.onHelpAreaChange]: this[pias.onHelpAreaChange],
            [pias.onHelp]: this[pias.onHelp],

            [pias.onModVarChange]: this[pias.onModVarChange],
            [pias.onModVarsChange]: this[pias.onModVarsChange],

            [pias.onOrigModVarsChange]: this[pias.onOrigModVarsChange],

            [pias.onPageChange]: this[pias.onPageChange],

            [pias.onRunInitialRESTCalls]: this[pias.onRunInitialRESTCalls],

            [pias.onSideBarArrowClick]: this[pias.onSideBarArrowClick],

            [pias.onValidationItemChange]: this[pias.onValidationItemChange],
          }}
          language={state.language}
        />
      );

      APIDialogComp = <TAPIDialog apiTasks={state[pias.apiTasks]} />;

      commWithServerDialog = (
        <TDialog
          caption={"Communicating with server..."}
          modal={true}
          visible={false}
          style={{
            width: "370px",
          }}
        />
      );

      if (dialogObj[pias.showBool]) {
        const header = (
          <p
            key={"dialogHeaderKey"}
            style={{
              ...Theme.labelStyle,
              margin: 0,
              marginTop: Theme.topIndent,
              padding: 0,
            }}
          >
            {dialogObj[pias.headerStr]}
          </p>
        );

        const msg = (
          <div
            key={"dialogMsgKey"}
            style={{
              ...Theme.textFontStyle,
            }}
          >
            {dialogObj[pias.contentStr]}
          </div>
        );

        dialogComp = (
          <TDialog
            actions={dialogObj[pias.actions1DStrArr]}
            caption={dialogObj[pias.captionStr]}
            closeBtnCaption={dialogObj[pias.closeBtnCaptionStr]}
            onClose={this[pias.onDialogClose]}
            content={[msg]}
            header={header}
            key={dialogObj[pias.keyStr]}
            maxWidth={dialogObj[pias.maxWidthStr]}
            modal={dialogObj[pias.modalBool]}
            style={dialogObj[styleObj]}
            visible={dialogObj[pias.visibleBool]}
          />
        );
      }

      helpDialog = (
        <HelpWindow
          open={this.state[pias.showHelp]}
          title={"PrEP-it Help"}
          language={this.state.language}
          page={this.state[pias.helpPage]}
          onClose={() => this.setState({ [pias.showHelp]: false })}
          footer={
            <div>
              <div style={{ float: "right" }}>
                <a href={`PrEPitHelp/${this.state.language}/HTML/index.html`} target="_blank" rel="noopener noreferrer">
                  {RS(SC.GB_stViewCompleteHelp)}
                </a>
              </div>
            </div>
          }
        />
      );

      calcDialog = this.renderCalcDialog();
    } else {
      redirectPage = (
        <PIRedirectPage
          {...{
            [pias.onShowRedirectChange]: this[pias.onShowRedirectChange],
          }}
        />
      );
    }

    if (loading) {
      return (
        <div>
          <TSplashScreen />
          {APIServiceComp}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {mainComp}
          {APIServiceComp}
          {APIDialogComp}
          {commWithServerDialog}
          {helpDialog}
          {calcDialog}
          {dialogComp}
          {redirectPage}
        </React.Fragment>
      );
    }
  }
}

export default PIApp;
