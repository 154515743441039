import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import TComboBox from "../../common/TComboBox";
import TButton from "../../common/TButton";

import * as gbu from "../../GB/GBUtil";
import * as piu from "../NonComponents/PIUtil";
import * as pip from "../NonComponents/PIProps";
import * as gbc from "../../GB/GBConst";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import * as pic from "../NonComponents/PIConst";
import * as piasu from "../NonComponents/PIAppStateUtil";

/* Attributes specific to PITimePeriodDiv. */

export const PITimePeriodDivProps = {
  style: "style",
};

class PITimePeriodDiv extends Component {
  static propTypes = {
    [pip.boundingTimePeriodObj]: PropTypes.object,

    [pip.onSetPeriod]: PropTypes.func,

    [pip.showInstructionsBool]: PropTypes.bool,

    [pip.timePeriodObj]: PropTypes.object,
    [pip.timePeriodObjAppState]: PropTypes.object,

    [pip.onTimePeriodChange]: PropTypes.func,

    [pip.timePeriodType]: PropTypes.number,

    [pip.timePeriodCaption]: PropTypes.string,

    [pip.timePeriodLabStyle]: PropTypes.object,

    [PITimePeriodDivProps.style]: PropTypes.object,
  };

  static defaultProps = {
    [pip.boundingTimePeriodObj]: {},

    [pip.onSetPeriod]: () => console.log(pip.onSetPeriod),

    [pip.showInstructionsBool]: true,

    [pip.timePeriodObj]: {},
    [pip.timePeriodObjAppState]: {},

    [pip.onTimePeriodChange]: () => console.log(pip.onTimePeriodChange),

    [pip.timePeriodType]: pic.targSetPeriod,

    [pip.timePeriodCaption]: "",

    [pip.timePeriodLabStyle]: {},

    [PITimePeriodDivProps.style]: {},
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onStartMonthComboBoxChange = (value, text, info) => {
    this.props[pip.onTimePeriodChange](info, pic.start);
  };

  onStartYearComboBoxChange = (value, text, info) => {
    this.props[pip.onTimePeriodChange](info, pic.start);
  };

  onEndMonthComboBoxChange = (value, text, info) => {
    this.props[pip.onTimePeriodChange](info, pic.end);
  };

  onEndYearComboBoxChange = (value, text, info) => {
    this.props[pip.onTimePeriodChange](info, pic.end);
  };

  onSetPeriodBtnClick = () => {
    this.props[pip.onSetPeriod]();
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  renderTimePeriodDiv = () => {
    const fn = () => {
      const props = this.props;
      const boundingTimePeriodObj = props[pip.boundingTimePeriodObj];
      const timePeriodObj = props[pip.timePeriodObj];
      const timePeriodObjAppState = props[pip.timePeriodObjAppState];
      const timePeriodType = props[pip.timePeriodType];
      const style = props[PITimePeriodDivProps.style];
      const timePeriodCaption = props[pip.timePeriodCaption];
      const timePeriodLabStyle = props[pip.timePeriodLabStyle];
      const showInstructionsBool = props[pip.showInstructionsBool];

      const timePeriodLab = (
        <p
          style={{
            marginBottom: 0,
            marginTop: 0,
            //marginTop    : 20,//markc 10,
            ...Theme.labelStyle,
            ...timePeriodLabStyle,
          }}
        >
          {timePeriodCaption}
        </p>
      );

      let startYearInt;
      let startMonthInt;
      let endYearInt;
      let endMonthInt;

      let startYearIntAppState;
      let startMonthIntAppState;
      let endYearIntAppState;
      let endMonthIntAppState;

      if (timePeriodType === pic.targSetPeriod) {
        startYearInt = piasu.getTargStartYear(timePeriodObj);
        startMonthInt = piasu.getTargStartMonth(timePeriodObj);
        endYearInt = piasu.getTargEndYear(timePeriodObj);
        endMonthInt = piasu.getTargEndMonth(timePeriodObj);

        startYearIntAppState = piasu.getTargStartYear(timePeriodObjAppState);
        startMonthIntAppState = piasu.getTargStartMonth(timePeriodObjAppState);
        endYearIntAppState = piasu.getTargEndYear(timePeriodObjAppState);
        endMonthIntAppState = piasu.getTargEndMonth(timePeriodObjAppState);
      } else if (timePeriodType === pic.progDataPeriod) {
        startYearInt = piasu.getProgDataStartYear(timePeriodObj);
        startMonthInt = piasu.getProgDataStartMonth(timePeriodObj);
        endYearInt = piasu.getProgDataEndYear(timePeriodObj);
        endMonthInt = piasu.getProgDataEndMonth(timePeriodObj);

        startYearIntAppState = piasu.getProgDataStartYear(timePeriodObjAppState);
        startMonthIntAppState = piasu.getProgDataStartMonth(timePeriodObjAppState);
        endYearIntAppState = piasu.getProgDataEndYear(timePeriodObjAppState);
        endMonthIntAppState = piasu.getProgDataEndMonth(timePeriodObjAppState);
      } else if (timePeriodType === pic.indProgDataPeriod) {
        startYearInt = piasu.getIndProgDataStartYear(timePeriodObj);
        startMonthInt = piasu.getIndProgDataStartMonth(timePeriodObj);
        endYearInt = piasu.getIndProgDataEndYear(timePeriodObj);
        endMonthInt = piasu.getIndProgDataEndMonth(timePeriodObj);

        startYearIntAppState = piasu.getIndProgDataStartYear(timePeriodObjAppState);
        startMonthIntAppState = piasu.getIndProgDataStartMonth(timePeriodObjAppState);
        endYearIntAppState = piasu.getIndProgDataEndYear(timePeriodObjAppState);
        endMonthIntAppState = piasu.getIndProgDataEndMonth(timePeriodObjAppState);
      } else if (timePeriodType === pic.dateRangeDisplayPeriod) {
        startYearInt = piasu.getDateRangeDisplayStartYear(timePeriodObj);
        startMonthInt = piasu.getDateRangeDisplayStartMonth(timePeriodObj);
        endYearInt = piasu.getDateRangeDisplayEndYear(timePeriodObj);
        endMonthInt = piasu.getDateRangeDisplayEndMonth(timePeriodObj);

        startYearIntAppState = piasu.getDateRangeDisplayStartYear(timePeriodObjAppState);
        startMonthIntAppState = piasu.getDateRangeDisplayStartMonth(timePeriodObjAppState);
        endYearIntAppState = piasu.getDateRangeDisplayEndYear(timePeriodObjAppState);
        endMonthIntAppState = piasu.getDateRangeDisplayEndMonth(timePeriodObjAppState);
      }

      //            const startDate = (new Date(startYearInt, startMonthInt - 1)).getTime();
      //            const endDate = (new Date(endYearInt, endMonthInt - 1)).getTime();

      //const startDateAppState = (new Date(startYearIntAppState, startMonthIntAppState - 1)).getTime();
      //const endDateAppState = (new Date(endYearIntAppState, endMonthIntAppState - 1)).getTime();

      let setDateRangeBtn = null;
      let setDateRangeNoteLab = null;

      if (
        timePeriodType === pic.targSetPeriod ||
        timePeriodType === pic.dateRangeDisplayPeriod ||
        timePeriodType === pic.progDataPeriod
      ) {
        const startDate = new Date(startYearInt, startMonthInt - 1).getTime();
        const endDate = new Date(endYearInt, endMonthInt - 1).getTime();

        const startDateAppState = new Date(startYearIntAppState, startMonthIntAppState - 1).getTime();
        const endDateAppState = new Date(endYearIntAppState, endMonthIntAppState - 1).getTime();

        if (showInstructionsBool) {
          setDateRangeNoteLab = (
            <p
              style={{
                marginBottom: 0,
              }}
            >
              {RS(SC.GB_stClickSetPeriod)}
            </p>
          );
        }

        setDateRangeBtn = (
          <TButton
            caption={RS(SC.GB_stSetDateRange)}
            disabled={startDate === startDateAppState && endDate === endDateAppState}
            key={"setDateRangeBtn"}
            onClick={this.onSetPeriodBtnClick}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              marginRight: 20,
            }}
          />
        );
      }

      let startYearsArr = [];
      let startMonthsArr = [];
      let endYearsArr = [];
      let endMonthsArr = [];

      /* The Timeframe for indicators must always be a subset of the program
               data setting period. */
      if (timePeriodType === pic.indProgDataPeriod) {
        const progDataStartYearInt = piasu.getProgDataStartYear(boundingTimePeriodObj);
        const progDataStartMonthInt = piasu.getProgDataStartMonth(boundingTimePeriodObj);
        const progDataEndYearInt = piasu.getProgDataEndYear(boundingTimePeriodObj);
        const progDataEndMonthInt = piasu.getProgDataEndMonth(boundingTimePeriodObj);

        for (let i = progDataStartYearInt; i <= progDataEndYearInt; i++) {
          startYearsArr.push(i);
          endYearsArr.push(i);
        }

        for (let i = 1; i <= gbc.monthMstIDs.length; i++) {
          if (
            (startYearInt !== endYearInt && i >= progDataStartMonthInt) ||
            (startYearInt === endYearInt && i >= progDataStartMonthInt && i < progDataEndMonthInt)
          ) {
            startMonthsArr.push(i);
          } else if (
            (startYearInt !== endYearInt && i <= progDataEndMonthInt) ||
            (startYearInt === endYearInt && i <= progDataEndMonthInt && i > progDataStartMonthInt)
          ) {
            endMonthsArr.push(i);
          }
        }
      } else if (timePeriodType === pic.dateRangeDisplayPeriod) {
        /* The Date range for indicators and Commodity forecasting period must always be a
               subset of the Target setting period. */
        const targStartYearInt = piasu.getTargStartYear(boundingTimePeriodObj);
        //const targStartMonthInt = piasu.getTargStartMonth(boundingTimePeriodObj);
        const targEndYearInt = piasu.getTargEndYear(boundingTimePeriodObj);
        //const targEndMonthInt = piasu.getTargEndMonth(boundingTimePeriodObj);

        for (let i = targStartYearInt; i <= targEndYearInt; i++) {
          startYearsArr.push(i);
          endYearsArr.push(i);
        }

        for (let i = 1; i <= gbc.monthMstIDs.length; i++) {
          startMonthsArr.push(i);
          endMonthsArr.push(i);
        }
      } else {
        if (timePeriodType === pic.progDataPeriod) {
          startYearsArr = piu.getDefProgramStartYearsArray();
          endYearsArr = piu.getDefProgramEndYearsArray();
        }

        startMonthsArr = structuredClone(gbc.monthMstIDs);
        endMonthsArr = structuredClone(gbc.monthMstIDs);
      }

      /* If showing the target setting time period, further restrict the months and years
               so that the target setting period is farther in time than the program data setting
               period. */

      if (timePeriodType === pic.targSetPeriod) {
        const endYearPDInt = piasu.getProgDataEndYear(boundingTimePeriodObj);
        const endMonthPDInt = piasu.getProgDataEndMonth(boundingTimePeriodObj);

        /* The first possible TSP year should be the last PDP year (or the last PDP year + 1 if the last
                   PDP month is December). The last one should be ten years after. */
        const firstPossibleTSPMonth = endMonthPDInt === 12 ? endYearPDInt + 1 : endYearPDInt;
        for (let yr = firstPossibleTSPMonth; yr <= endYearPDInt + 10; yr++) {
          startYearsArr.push(yr);
          endYearsArr.push(yr);
        }
      }

      //const monthComboBoxWidth = 150;
      //const yearComboBoxWidth = 100;

      const startMonthComboBox = (
        <TComboBox
          onChange={this.onStartMonthComboBoxChange}
          info={startMonthsArr}
          itemIndex={startMonthInt - startMonthsArr[0]}
          items={piu.getMonthsArray(startMonthsArr)}
          key={"startMonthComboBox"}
          style={{
            fontFamily: Theme.fontFamily,
          }}
          width={"150"}
        />
      );

      const startYearComboBox = (
        <TComboBox
          onChange={this.onStartYearComboBoxChange}
          info={startYearsArr}
          itemIndex={startYearInt - startYearsArr[0]}
          items={startYearsArr.map(String)}
          key={"startYearComboBox"}
          style={{
            fontFamily: Theme.fontFamily,
          }}
          width={"100"}
        />
      );

      const toLab = (
        <p
          key={"toLab"}
          style={{
            color: Theme.PI_SecondaryColor,
            //display  : "inline-block",
            fontSize: 16,
            marginRight: 25,
            ...Theme.labelFontStyle,
          }}
        >
          {RS(SC.GB_stTo)}
        </p>
      );

      const endMonthComboBox = (
        <TComboBox
          onChange={this.onEndMonthComboBoxChange}
          info={endMonthsArr}
          itemIndex={endMonthInt - endMonthsArr[0]}
          items={piu.getMonthsArray(endMonthsArr)}
          key={"endMonthComboBox"}
          style={{
            fontFamily: Theme.fontFamily,
          }}
          width={"150"}
        />
      );

      const endYearComboBox = (
        <TComboBox
          onChange={this.onEndYearComboBoxChange}
          info={endYearsArr}
          itemIndex={endYearInt - endYearsArr[0]}
          items={endYearsArr.map(String)}
          key={"endYearComboBox"}
          style={{
            fontFamily: Theme.fontFamily,
          }}
          width={"100"}
        />
      );

      const timePeriodDiv = (
        <div
          key={"timePeriodDiv"}
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div key={"startDiv"}>
            {startMonthComboBox}
            {startYearComboBox}
          </div>

          {toLab}

          <div key={"endDiv"}>
            {endMonthComboBox}
            {endYearComboBox}
          </div>
        </div>
      );

      return (
        <div
          style={{
            ...style,
          }}
        >
          {timePeriodLab}

          {setDateRangeNoteLab}

          <div
            style={{
              alignItems: "center",
              display: "inline-flex",
              marginTop: 10,
            }}
          >
            {setDateRangeBtn}
            {timePeriodDiv}
          </div>
        </div>
      );
    };

    return gbu.tryRenderFn(fn, "render PITimePeriodDiv");
  };

  render() {
    return <React.Fragment>{this.renderTimePeriodDiv()}</React.Fragment>;
  }
}

export default PITimePeriodDiv;
