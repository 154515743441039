import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
//import * as pic from "../NonComponents/PIConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pip from "../NonComponents/PIProps";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const priorPopCol = 0;
const priorSizeCol = 1;
const percEligAreasCol = 2;
const HIVPrevCol = 3;
const percIndicPrEPCol = 4;

const firstRow = 0;

class PIPopulationsTargTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = structuredClone(props[pias.modVarObjList]);

      let HIVPrev1DFltArray = piasu.getModVarValue(modVarObjListClone, pisc.percentHIVPrevalenceMVTag);
      let percIndicPrEP1DFltArray = piasu.getModVarValue(modVarObjListClone, pisc.percentForPrepMVTag);
      let percPrEPElig1DFltArray = piasu.getModVarValue(modVarObjListClone, pisc.percentPrepEligibleMVTag);
      let popSize1DFltArray = piasu.getModVarValue(modVarObjListClone, pisc.priorityPopSizeMVTag);
      let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);

      const showAGYWToolBool = piasu.showAGYWTool(modVarObjListClone);

      const newPackTableClone = window.structuredCloneReview(newPackTable);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      //const numRows = numPriorPops + 1; // includes one header

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const priorPopMstID = piasu.getPriorPopMstID(priorPopObjList, pp);

        if (!(priorPopMstID === pisc.AGYW_PP_MstID && showAGYWToolBool)) {
          const popSizeFlt = gbtu.getValue(newPackTableClone, pp, priorSizeCol);
          piasu.setPopSize(popSize1DFltArray, pp, popSizeFlt);

          const percPrEPEligFlt = gbtu.getValue(newPackTableClone, pp, percEligAreasCol);
          piasu.setPercPrEPElig(percPrEPElig1DFltArray, pp, percPrEPEligFlt);

          if (priorPopMstID !== pisc.SDC_PP_MstID) {
            const HIVPrevFlt = gbtu.getValue(newPackTableClone, pp, HIVPrevCol);
            piasu.setHIVPrev(HIVPrev1DFltArray, pp, HIVPrevFlt);
          }
        }

        const percIndicPrEPFlt = gbtu.getValue(newPackTableClone, pp, percIndicPrEPCol);
        piasu.setPercIndicPrEP(percIndicPrEP1DFltArray, pp, percIndicPrEPFlt);
      }

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          onModVarsChange(modVarObjListClone, false);
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;

      const modVarObjList = props[pias.modVarObjList];

      const isPSEMode = piasu.isPSEMode(modVarObjList);
      const numPotUsers = isPSEMode ? 1 : 5;
      const numCols = numPotUsers + (isPSEMode ? 4 : 1);

      const state = this.state;
      const rDec = state[pip.rDec];

      const HIVPrev1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
      const percIndicPrEP1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentForPrepMVTag);
      const percPrEPElig1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentPrepEligibleMVTag);
      const popSize1DFltArray = piasu.getModVarValue(modVarObjList, pisc.priorityPopSizeMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const areasBelowThresholdObj = piasu.getModVarValue(modVarObjList, pisc.thresholdsResTableAGYWMVTag);

      const showAGYWToolBool = piasu.showAGYWTool(modVarObjList);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numRows = numPriorPops + 1; // includes one header

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));

      if (!isPSEMode) {
        gbtu.setValue(packTable, firstRow, priorSizeCol, RS(SC.GB_stPopulationSize));
        gbtu.setValue(packTable, firstRow, percEligAreasCol, RS(SC.GB_stPercPrEPEligAreas));
        gbtu.setValue(packTable, firstRow, HIVPrevCol, RS(SC.GB_stHIVPrev) + " (%)");
        gbtu.setValue(packTable, firstRow, percIndicPrEPCol, RS(SC.GB_stPercIndicPrEP));
        gbtu.setValue(packTable, firstRow, numPotUsers, RS(SC.GB_stNumPotentialUsers));
      }

      if (isPSEMode) {
        gbtu.setValue(packTable, firstRow, numPotUsers, RS(SC.GB_stTotalPotentialUsers));
        gbtu.setValue(packTable, firstRow, numPotUsers + 1, RS(SC.GB_stMediumIncidence));
        gbtu.setValue(packTable, firstRow, numPotUsers + 2, RS(SC.GB_stHighIncidence));
        gbtu.setValue(packTable, firstRow, numPotUsers + 3, RS(SC.GB_stVeryHighIncidence));
      }

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const pop = priorPopObjList[pp - 1];
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

        if (!isPSEMode) {
          let popSizeFlt;
          let percPrEPEligFlt;
          let HIVPrevFlt;

          if (pop.mstID === pisc.AGYW_PP_MstID && showAGYWToolBool) {
            popSizeFlt = piasu.getAGYW_ABT(areasBelowThresholdObj);
            gbtu.lockCell(packTable, pp, priorSizeCol, true);

            percPrEPEligFlt = 100;
            gbtu.lockCell(packTable, pp, percEligAreasCol, true);

            HIVPrevFlt = piasu.getPrevalenceAGYW_ABT(areasBelowThresholdObj);
            gbtu.lockCell(packTable, pp, HIVPrevCol, true);
          } else {
            popSizeFlt = piasu.getPopSize(popSize1DFltArray, pp);
            percPrEPEligFlt = piasu.getPercPrEPElig(percPrEPElig1DFltArray, pp);

            if (pop.mstID !== pisc.SDC_PP_MstID) {
              HIVPrevFlt = piasu.getHIVPrev(HIVPrev1DFltArray, pp);
            } else {
              HIVPrevFlt = 0;
              gbtu.lockCell(packTable, pp, HIVPrevCol, true);
            }
          }

          gbtu.setValue(packTable, pp, priorSizeCol, popSizeFlt);

          gbtu.setValue(packTable, pp, percEligAreasCol, percPrEPEligFlt);

          gbtu.setValue(packTable, pp, HIVPrevCol, HIVPrevFlt);

          const percIndicPrEPFlt = piasu.getPercIndicPrEP(percIndicPrEP1DFltArray, pp);
          gbtu.setValue(packTable, pp, percIndicPrEPCol, percIndicPrEPFlt);

          gbtu.setValue(
            packTable,
            pp,
            numPotUsers,
            popSizeFlt * (percPrEPEligFlt / 100) * (1 - HIVPrevFlt / 100) * (percIndicPrEPFlt / 100)
          );
        } else {
          gbtu.setValue(packTable, pp, numPotUsers, pop.potential_users_pse);
          gbtu.setValue(packTable, pp, numPotUsers + 1, pop.pse_users_by_incidence?.medium ?? "unknown");
          gbtu.setValue(packTable, pp, numPotUsers + 2, pop.pse_users_by_incidence?.high ?? "unknown");
          gbtu.setValue(packTable, pp, numPotUsers + 3, pop.pse_users_by_incidence?.veryhigh ?? "unknown");
        }
        gbtu.lockCell(packTable, pp, numPotUsers, true);
        gbtu.lockCell(packTable, pp, numPotUsers + 1, true);
        gbtu.lockCell(packTable, pp, numPotUsers + 2, true);
        gbtu.lockCell(packTable, pp, numPotUsers + 3, true);
      }

      gbtu.setMaxAllowedValByCol(packTable, priorSizeCol, gbtc.maxInt);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
      gbtu.setWordWrappedCol(packTable, priorPopCol, true);

      if (rDec.length === 0) {
        gbtu.setRDecs(packTable, 0);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIPopulationsTargTable");
        console.log("ModVar(s):");
        console.log(pisc.percentHIVPrevalenceMVTag);
        console.log(HIVPrev1DFltArray);
        console.log(pisc.percentForPrepMVTag);
        console.log(percIndicPrEP1DFltArray);
        console.log(pisc.percentPrepEligibleMVTag);
        console.log(percPrEPElig1DFltArray);
        console.log(pisc.priorityPopSizeMVTag);
        console.log(popSize1DFltArray);
        console.log(pisc.priorPopsMVTag);
        console.log(priorPopObjList);
        console.log(pisc.thresholdsResTableAGYWMVTag);
        console.log(areasBelowThresholdObj);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"populationsTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIPopulationsTargTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIPopulationsTargTable;
