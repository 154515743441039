// @ts-check
import React from "react";
import * as PropTypes from "prop-types";
import { useState } from "react";

import { RS, getUserLanguageCode } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbu from "../../../GB/GBUtil";
import * as gbtc from "../../../GB/GBTableConst";

import * as piasu from "../../NonComponents/PIAppStateUtil";

import * as pisc from "../../NonComponents/PIServerConst";
import * as pitu from "../../NonComponents/PITableUtil";
import * as piu from "../../NonComponents/PIUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

import { combinePriorPops, genAndKeyPopsCombinedPops } from "../../NonComponents/PIPriorPopUtil";

/**
 * @typedef {object} Totals
 * @property {number} totalInit
 * @property {number} totalRestart
 * @property {number} totalCosts
 * @property {number} totalCostsTotalForAverted
 * @property {number} costPerInfAvtd
 * @property {number} actualCovAch
 * @property {number} potentialUsersInit
 * @property {number} totalInfAvtdConstantCoverage
 * @property {number} totalInfAvtdPSE
 */

const methodNameRow = 0;
const colHeadingsRow = 1;
const rowOffset = 1; // number of extra column heading rows

const priorPopCol = 0;

const PIImpCostsBasedTargsResTable = (props) => {
  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  const progDataPeriod = piasu.getProgDataPeriodObj(modVarObjList);
  const displayRange = piasu.getDateRangeDisplayObj(modVarObjList);

  const pdp = piu.getDateObjectAsJSDates(progDataPeriod);
  const drd = piu.getDateObjectAsJSDates(displayRange);

  const drdEndIdx = piu.getMonthsBetweenDates(pdp.start, drd.end);

  /** @type {any[]} */
  let priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  let totalCosts2DIntArray = piasu.getModVarValue(modVarObjList, "PI_CostsByPopType");
  let allTotalCosts = piasu.getModVarValue(modVarObjList, "PI_CostsByPopType_ALL");

  const totalInfAvtdConstantCoverage2DIntArray = piasu.getModVarValue(
    modVarObjList,
    "PI_InfAvertedByPopType_CONSTANT_COVERAGE"
  );

  let totalInfAvtdPSE = piasu.getModVarValue(modVarObjList, "PI_InfAvertedByPopType_PSE");

  let totalInits2DIntArray = piasu.getModVarValue(modVarObjList, "PI_InitByPopType");
  let totalRestarts2DIntArray = piasu.getModVarValue(modVarObjList, "PI_RestartsByPopType");

  let priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  let actUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjList, "PI_ActualCoverage");

  let potentialUsers = piasu.getModVarValue(modVarObjList, "PI_PotentialUsers");
  let initiations = piasu.getModVarValue(modVarObjList, "PI_InitiationsNoRestarts");

  /** @type {any[]} */
  const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
  /** @type {boolean[]} */
  const selectedMethods1DBoolArr = piasu.getModVarValue(modVarObjList, pisc.targSelectedMethodsMVTag);

  const showImpactBool = piasu.showImpact(modVarObjList, false, true);
  const showCostBool = piasu.showCostsLite(modVarObjList, false, true);

  const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  // Combined pops in PSE mode
  if (isPSEMode) {
    const combined = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, [
      "PI_CostsByPopType",
      "PI_CostsByPopType_ALL",
      "PI_InfAvertedByPopType_PSE",
      "PI_InitByPopType",
      "PI_RestartsByPopType",
      "PI_Eligibility",
      "PI_PotentialUsers",
      "PI_InitiationsNoRestarts",
      "PI_ActualCoverage",
    ]);

    priorPopObjList = combined.PI_PriorityPop;
    totalCosts2DIntArray = combined["PI_CostsByPopType"];
    allTotalCosts = combined["PI_CostsByPopType_ALL"];
    totalInfAvtdPSE = combined["PI_InfAvertedByPopType_PSE"];
    totalInits2DIntArray = combined["PI_InitByPopType"];
    totalRestarts2DIntArray = combined["PI_RestartsByPopType"];
    priorPopMethodEligObjArr = combined["PI_Eligibility"];
    actUsersToTakePrEPObjArray = combined["PI_ActualCoverage"];
    potentialUsers = combined["PI_PotentialUsers"];
    initiations = combined["PI_InitiationsNoRestarts"];
  }

  const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

  const allMethodsSelectedBool = methodObjArr.length > 1 && selectedMethods1DBoolArr[methodObjArr.length];
  const numEnabledMethods =
    selectedMethods1DBoolArr.reduce((acc, cur) => acc + (cur ? 1 : 0), 0) - (allMethodsSelectedBool ? 1 : 0);

  // State
  const [rDec, setRDecs] = useState(/** @type {number[][]} */ ([]));

  if (allTotalCosts == null) {
    // PI_CostsByPopType_ALL is missing from session, but will be fixed by a calculate. The results page
    // will calculate on show, but will render the table while calculating. Once calculate has completed,
    // the table will be re-rendered with the correct data.
    return null;
  }

  // Functions
  const formatCost = (number) =>
    number?.toLocaleString(getUserLanguageCode(), {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  // Callbacks
  const onPackTableChange = (newPackTable) => {
    // Update RDecs
    setRDecs(structuredClone(newPackTable.RDec));
  };

  // Column Offsets
  let col = 0;
  const totInitColOrder = col++;
  const totRestartColOrder = col++;
  const totalCostsColOrder = showCostBool ? col++ : -1;
  const totalInfAvtdColOrder = showImpactBool ? col++ : -1;
  const costPerInfAvtdColOrder = showCostBool && showImpactBool ? col++ : -1;
  const potentialUsersInitCombinedColOrder = col;
  const numAllMethodsCols = allMethodsSelectedBool ? col + 1 : 0;
  const actualCovAchColOrder = showImpactBool || !aggModeBool ? col++ : -1;
  const potentialUsersInitColOrder = col++;

  const finalColOrder = col - 1;
  const numRepeatedMethodCols = col;

  const totalsRowOffset = numPriorPops + 1 + rowOffset;

  // Table
  const packTable = gbtu.resizePackTable(
    gbtu.getNewPackTable(),
    priorPopObjList.length + 3,
    numEnabledMethods * numRepeatedMethodCols + numAllMethodsCols + 1
  );

  gbtu.setRDecs(packTable, 0);

  // Row & Column Headings
  gbtu.setValue(packTable, methodNameRow, priorPopCol, RS(SC.GB_stPriorityPop));

  for (let pp = 1; pp <= numPriorPops; pp++) {
    /* Set row headings. */
    const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
    gbtu.setValue(packTable, pp + rowOffset, priorPopCol, priorPopName);
    gbtu.setIndent(packTable, pp + rowOffset, true, Theme.leftIndent);
  }

  gbtu.setValue(packTable, totalsRowOffset, priorPopCol, RS(SC.GB_stTotal));
  gbtu.setFontStyle(packTable, totalsRowOffset, priorPopCol, [gbtc.fontStyle.bold]);

  // Methods

  /**
   * Render columns for a method
   * @param {(string|null)} methodID Method ID
   * @param {number} colStart Index of method in the table
   * @param {Totals[]} totalsByPop Row totals by population
   * @param {boolean} combined true if rendering all methods combined
   * @param {boolean[]} [disableBlocks] Disable by PP
   */
  const renderTotals = (methodID, colStart, totalsByPop, combined, disableBlocks) => {
    // Priority Pop rows
    for (let pp = 0; pp < numPriorPops; ++pp) {
      const row = pp + 1 + rowOffset;

      if (
        (methodID && piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodID, pp + 1) !== pisc.yesCVOMstID) ||
        (disableBlocks && disableBlocks[pp])
      ) {
        gbtu.lockCellBlock(packTable, row, row, colStart, colStart + finalColOrder, true, true);
        const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
        gbtu.setCellBlockBGColor(packTable, row, row, colStart, colStart + finalColOrder, gainsboroBase10);
        continue;
      }

      gbtu.setValue(packTable, row, colStart + totInitColOrder, totalsByPop[pp].totalInit);
      gbtu.setValue(packTable, row, colStart + totRestartColOrder, totalsByPop[pp].totalRestart);

      if (showCostBool) {
        gbtu.setValue(packTable, row, colStart + totalCostsColOrder, totalsByPop[pp].totalCosts);
      }

      if (showImpactBool && (totalInfAvtdConstantCoverage2DIntArray || totalInfAvtdPSE)) {
        const constantCovg = totalsByPop[pp].totalInfAvtdConstantCoverage;
        const pse = totalsByPop[pp].totalInfAvtdPSE;

        if (!isPSEMode) {
          // Constant Coverage + 9 Fives
          gbtu.setValue(packTable, row, colStart + totalInfAvtdColOrder, constantCovg);
        } else {
          // PSE
          gbtu.setValue(packTable, row, colStart + totalInfAvtdColOrder, pse);
        }

        if (showCostBool) {
          const totalCost = !combined ? totalsByPop[pp].totalCostsTotalForAverted : totalsByPop[pp].totalCosts;
          if (!isPSEMode) {
            // Constant Coverage + 9 Fives
            const costPerInfAvtdCC = constantCovg > 0 ? totalCost / constantCovg : 0;
            gbtu.setValue(packTable, row, colStart + costPerInfAvtdColOrder, formatCost(costPerInfAvtdCC));
          } else {
            // PSE
            const costPerInfAvtdPSE = pse > 0 ? totalCost / pse : 0;
            gbtu.setValue(packTable, row, colStart + costPerInfAvtdColOrder, formatCost(costPerInfAvtdPSE));
          }
        }
      }

      if (!aggModeBool && !combined) {
        gbtu.setValue(packTable, row, colStart + actualCovAchColOrder, totalsByPop[pp].actualCovAch * 100);
      }

      gbtu.setValue(
        packTable,
        row,
        colStart + (combined ? potentialUsersInitCombinedColOrder : potentialUsersInitColOrder),
        totalsByPop[pp].potentialUsersInit
      );
    }
  };

  /**
   * Calculate columns for a method
   * @param {number} m Index into methods array (zero based)
   * @param {Totals[]} combinedTotals Totals by PP for all methods combined
   * @returns {boolean} true if totals block should be grayed out
   */
  const calcTotals = (m, combinedTotals) => {
    const method = methodObjArr[m];

    // Etc
    /** @type {Totals} */
    const totals = {
      totalInit: 0,
      totalRestart: 0,
      totalCosts: 0,
      totalCostsTotalForAverted: 0,
      costPerInfAvtd: 0,
      actualCovAch: 0,
      potentialUsersInit: 0,
      totalInfAvtdConstantCoverage: 0,
      totalInfAvtdPSE: 0,
    };
    let grayOutTotalBlock = true;

    // Priority Pop rows
    for (let pp = 0; pp < numPriorPops; ++pp) {
      const eligible = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, method.mstID, pp + 1);
      if (eligible !== pisc.yesCVOMstID) continue;

      const totalInits = totalInits2DIntArray[m][pp];
      totals.totalInit += totalInits;
      combinedTotals[pp].totalInit += totalInits;

      const totalRestarts = totalRestarts2DIntArray ? totalRestarts2DIntArray[m][pp] : 0;
      totals.totalRestart += totalRestarts;
      combinedTotals[pp].totalRestart += totalRestarts;

      let totalCostsTotalForAverted = 0;
      if (showCostBool) {
        const totalCosts = totalCosts2DIntArray[m][pp];
        totals.totalCosts += totalCosts;
        combinedTotals[pp].totalCosts += totalCosts;

        totalCostsTotalForAverted = allTotalCosts[m][pp];
        totals.totalCostsTotalForAverted += totalCostsTotalForAverted;
        combinedTotals[pp].totalCostsTotalForAverted += totalCostsTotalForAverted;
      }

      if (showImpactBool && (totalInfAvtdConstantCoverage2DIntArray || totalInfAvtdPSE)) {
        const constantCovg = Math.round(totalInfAvtdConstantCoverage2DIntArray?.[m]?.[pp] ?? 0);
        const pse = Math.round(totalInfAvtdPSE?.[m]?.[pp] ?? 0);
        totals.totalInfAvtdConstantCoverage += constantCovg;
        totals.totalInfAvtdPSE += pse;
        combinedTotals[pp].totalInfAvtdConstantCoverage += constantCovg;
        combinedTotals[pp].totalInfAvtdPSE += pse;
      }

      if (!aggModeBool) {
        // Actual coverage achieved
        const actualCovFlt = piasu.getActUsersToTakePrEP_DRD(method.mstID, actUsersToTakePrEPObjArray, pp + 1);
        totals.actualCovAch += actualCovFlt;
        combinedTotals[pp].actualCovAch += actualCovFlt;
      }

      // Potential users who initiated
      const numPotUsers = potentialUsers[pp];
      const numInits = initiations[m][pp].slice(0, drdEndIdx + 1).reduce((acc, cur) => acc + cur, 0);
      // FIXME: If there's no potential users, did 0% or 100% initiate? Does it even matter?
      const numPotInit = numPotUsers > 0 ? (numInits / numPotUsers) * 100 : 0;
      totals.potentialUsersInit += numPotInit;
      combinedTotals[pp].potentialUsersInit += numPotInit;

      grayOutTotalBlock = false;
    }

    return grayOutTotalBlock;
  };

  /**
   * Render totals row
   * @param {number} colStart
   * @param {Totals[]} totals
   * @param {boolean} grayOutTotalBlock
   */
  const renderTotalsRow = (colStart, totals, grayOutTotalBlock) => {
    if (grayOutTotalBlock) {
      gbtu.lockCellBlock(packTable, totalsRowOffset, totalsRowOffset, colStart, colStart + finalColOrder, true, true);
      const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
      gbtu.setCellBlockBGColor(
        packTable,
        totalsRowOffset,
        totalsRowOffset,
        colStart,
        colStart + finalColOrder,
        gainsboroBase10
      );

      return;
    }

    const total = totals.slice(1).reduce((acc, cur) => {
      return addTotals(acc, cur);
    }, totals[0]);

    gbtu.setValue(packTable, totalsRowOffset, colStart + totInitColOrder, total.totalInit);
    gbtu.setValue(packTable, totalsRowOffset, colStart + totRestartColOrder, total.totalRestart);

    if (showCostBool) {
      gbtu.setValue(packTable, totalsRowOffset, colStart + totalCostsColOrder, total.totalCosts);
    }

    if (showImpactBool) {
      const constantCovg = total.totalInfAvtdConstantCoverage;
      const pse = total.totalInfAvtdPSE;

      if (!isPSEMode) {
        gbtu.setValue(packTable, totalsRowOffset, colStart + totalInfAvtdColOrder, constantCovg);
      } else {
        gbtu.setValue(packTable, totalsRowOffset, colStart + totalInfAvtdColOrder, pse);
      }

      if (showCostBool) {
        const totalCost = total.totalCostsTotalForAverted;
        let v;
        if (!isPSEMode) {
          // Constant Coverage + 9 Fives
          const costPerInfAvtdCC = constantCovg > 0 ? totalCost / constantCovg : 0;
          v = formatCost(costPerInfAvtdCC);
        } else {
          // PSE
          v = pse > 0 ? totalCost / pse : 0;
        }
        gbtu.setValue(packTable, totalsRowOffset, colStart + costPerInfAvtdColOrder, v);
      }
    }
  };

  /**
   * Add a and b
   * @param {Totals} a
   * @param {Totals} b
   * @returns {Totals} a + b
   */
  const addTotals = (a, b) => {
    // @ts-ignore
    return Object.fromEntries(Object.entries(a).map(([k, v]) => [k, v + b[k]]));
  };

  let num = 0;
  /** @type {Totals[][]} */
  const allMethodTotals = [];
  for (let m = 0; m < methodObjArr.length; ++m) {
    const colStart = 1 + numRepeatedMethodCols * num;

    /** @type {Totals[]} */
    const totals = priorPopObjList.map((v) => ({
      totalInit: 0,
      totalRestart: 0,
      totalCosts: 0,
      totalCostsTotalForAverted: 0,
      costPerInfAvtd: 0,
      actualCovAch: 0,
      potentialUsersInit: 0,
      totalInfAvtdConstantCoverage: 0,
      totalInfAvtdPSE: 0,
    }));
    allMethodTotals.push(totals);

    // renderMethod(m, num, totals);
    const grayOutTotalBlock = calcTotals(m, totals);

    if (selectedMethods1DBoolArr[m]) {
      // Headers
      gbtu.mergeCells(packTable, methodNameRow, colStart, 1, numRepeatedMethodCols);

      gbtu.setValue(packTable, methodNameRow, colStart, methodObjArr[m].name);
      gbtu.setValue(packTable, colHeadingsRow, colStart + totInitColOrder, RS(SC.GB_stTotalInitiations));
      gbtu.setValue(packTable, colHeadingsRow, colStart + totRestartColOrder, RS(SC.GB_stTotalRestarts));

      if (showCostBool) {
        gbtu.setValue(packTable, colHeadingsRow, colStart + totalCostsColOrder, RS(SC.GB_stTotalCosts) + " ($)");
      }

      if (showImpactBool) {
        gbtu.setValue(packTable, colHeadingsRow, colStart + totalInfAvtdColOrder, RS(SC.GB_stTotalInfAverted));

        if (showCostBool) {
          gbtu.setValue(
            packTable,
            colHeadingsRow,
            colStart + costPerInfAvtdColOrder,
            RS(SC.GB_stCostPerInfAvtd) + " ($)"
          );
        }
      }

      if (!aggModeBool) {
        gbtu.setValue(
          packTable,
          colHeadingsRow,
          colStart + actualCovAchColOrder,
          RS(SC.GB_stActualCovToBeAchieved) + " (%)"
        );
      }

      gbtu.setValue(
        packTable,
        colHeadingsRow,
        colStart + potentialUsersInitColOrder,
        RS(SC.GB_stPotentialUsersWhoInitiated)
      );

      // Data
      renderTotals(methodObjArr[m].mstID, colStart, totals, false);
      renderTotalsRow(colStart, totals, grayOutTotalBlock);
      ++num;
    }
  }

  const popTotals = allMethodTotals.reduce((acc, cur) => {
    if (acc.length === 0) {
      return structuredClone(cur);
    }

    return acc.map((v, idx) => addTotals(v, cur[idx]));
  }, []);

  // All Combined
  if (methodObjArr.length > 1 && selectedMethods1DBoolArr[methodObjArr.length] && numEnabledMethods >= 1) {
    const colStart = 1 + numRepeatedMethodCols * numEnabledMethods;

    // Headings
    gbtu.mergeCells(packTable, methodNameRow, colStart, 1, numAllMethodsCols);

    gbtu.setValue(packTable, methodNameRow, colStart, RS(SC.GB_stAllMethodsCombined));
    gbtu.setValue(packTable, colHeadingsRow, colStart + totInitColOrder, RS(SC.GB_stTotalInitiations));
    gbtu.setValue(packTable, colHeadingsRow, colStart + totRestartColOrder, RS(SC.GB_stTotalRestarts));

    if (showCostBool) {
      gbtu.setValue(packTable, colHeadingsRow, colStart + totalCostsColOrder, RS(SC.GB_stTotalCosts) + " ($)");
    }

    if (showImpactBool) {
      gbtu.setValue(packTable, colHeadingsRow, colStart + totalInfAvtdColOrder, RS(SC.GB_stTotalInfAverted));
      if (showCostBool) {
        gbtu.setValue(
          packTable,
          colHeadingsRow,
          colStart + costPerInfAvtdColOrder,
          RS(SC.GB_stCostPerInfAvtd) + " ($)"
        );
      }
    }

    gbtu.setValue(
      packTable,
      colHeadingsRow,
      colStart + potentialUsersInitCombinedColOrder,
      RS(SC.GB_stPotentialUsersWhoInitiated)
    );

    const disableBlocks = priorPopObjList.map(
      (pop, idx) =>
        !methodObjArr
          .map(
            (method) =>
              piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, method.mstID, idx + 1) === pisc.yesCVOMstID
          )
          .reduce((acc, cur) => acc || cur, false)
    );

    // Etc
    renderTotals(null, colStart, popTotals, true, disableBlocks);
    renderTotalsRow(colStart, popTotals, false);
  }

  // Table Formatting
  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, methodNameRow, gbtc.hAlign.center);
  gbtu.setColWidths(packTable, Theme.dataColWidthMed);
  gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthExtraWide);
  gbtu.setRowHeight(packTable, methodNameRow, 75);
  gbtu.setRowHeight(packTable, colHeadingsRow, 75);
  gbtu.setWordWrappedCol(packTable, priorPopCol, true);
  gbtu.setFixedRows(packTable, 2);
  gbtu.lockPackTable(packTable, true, false);

  gbtu.restoreRDecsFromCopy(packTable, rDec, 0);

  const types = generateTypes(packTable);
  if (showImpactBool) {
    for (let m = 0; m <= numEnabledMethods; m++) {
      const colStart = 1 + numRepeatedMethodCols * m;
      gbtu.setColAlignment(packTable, colStart + totalInfAvtdColOrder, gbtc.hAlign.center);
      for (let pp = 1; pp <= numPriorPops; pp++) {
        types[pp + rowOffset][colStart + totalInfAvtdColOrder] = "s";
        if (showCostBool) {
          gbtu.setColWidth(packTable, colStart + costPerInfAvtdColOrder, Theme.dataColWidthLarge);
          gbtu.setColAlignment(packTable, colStart + costPerInfAvtdColOrder, gbtc.hAlign.center);
          types[pp + rowOffset][colStart + costPerInfAvtdColOrder] = "s";
        }
      }
    }
  }

  return (
    //@ts-ignore
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      key={"optionsTable"}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={types}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        tableFont: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      width={0}
      limitWidthToContainer={true}
      undo={undefined}
      debug={undefined}
    />
  );
};

PIImpCostsBasedTargsResTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
};

export default PIImpCostsBasedTargsResTable;
