import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";
import * as pip from "../NonComponents/PIProps";
import TDialog from "../../common/TDialog";

class PITargIndResInfoDialog extends Component {
  static propTypes = {
    [pip.dialogHeaderText]: PropTypes.string,

    [pip.onToggleInfoDialog]: PropTypes.func,
  };

  static defaultProps = {
    [pip.dialogHeaderText]: "",

    [pip.onToggleInfoDialog]: () => console.log(pip.onToggleInfoDialog),
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================
  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderInfoDialog = () => {
    const fn = () => {
      const props = this.props;
      const dialogHeaderText = props[pip.dialogHeaderText];
      const onToggleInfoDialog = props[pip.onToggleInfoDialog];

      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {dialogHeaderText}
        </p>
      );

      const liStyle = {
        color: Theme.PI_TertiaryColor,
        fontWeight: 700,
      };

      const blurbText = (
        <React.Fragment>
          <p
            style={{
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stPrEPNEW) + ": "}</span>
            {RS(SC.GB_stPrEPNewDescription)}
          </p>
          <p
            style={{
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stPrEPCT) + ": "}</span>
            {RS(SC.GB_stPrEPCTDescription)}
          </p>
          <p
            style={{
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stPrEPRecipients) + ": "}</span>
            {RS(SC.GB_stPrEPRecipientsDescription)}
          </p>
          <p
            style={{
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stCurrentlyOnPrEP) + ": "}</span>
            {RS(SC.GB_stCurrOnPrEPDescription)}
          </p>
        </React.Fragment>
      );

      const dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[blurbText]}
          header={[dialogHeader]}
          key={"targIndResInfoDialog"}
          onClose={onToggleInfoDialog}
        />
      );

      return dialog;
    };

    return gbu.tryRenderFn(fn, "render PITargIndResInfoDialog");
  };

  render() {
    return <React.Fragment>{this.renderInfoDialog()}</React.Fragment>;
  }
}

export default PITargIndResInfoDialog;
