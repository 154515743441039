import { PropTypes } from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync } from "../NonComponents/PICalc";

import { defineCurvesTab, assignCurvesTab } from "../Forms/PIContinuationForm";
import { shiftContCurves } from "../NonComponents/PIAppStateUtil";

function resetCurvesConfiguration(defModVars, modVarObjList, origModVarObjArr) {
  const modVars = structuredClone(modVarObjList);

  // Rebuild curves with defaults
  const curves = piasu.getModVarValue(modVarObjList, "PI_ContinuationCurves");
  const defCurves = piasu.getModVarValue(defModVars, "PI_ContinuationCurves");

  // Restore names/values for any non custom methods
  const newCurves = curves.map((v) => {
    const ret = defCurves.find((c) => c.mstID === v.mstID);
    if (ret === undefined) return v;

    return {
      ...ret,
      name: piu.getDefContCurveNameFromMstID(v.mstID),
    };
  });

  piasu.setModVarValue(modVars, "PI_ContinuationCurves", newCurves);

  // re-add deleted default curves in original location
  for (const [defCurveIdx, defCurve] of defCurves.entries()) {
    if (defCurve.mstID.includes(pisc.customItemMstID) || newCurves.find((m) => m.mstID === defCurve.mstID)) continue;

    // NOTE: Mutates modVars by ref using newCurves...
    newCurves.splice(defCurveIdx, 0, {
      ...structuredClone(defCurve),
      name: piu.getDefContCurveNameFromMstID(defCurve.mstID),
    });
    shiftContCurves(modVars, origModVarObjArr, defCurveIdx + 1, pic.addItem);
  }

  return modVars;
}

function resetAssignCurves(defModVars, modVarObjList) {
  const modVars = structuredClone(modVarObjList);

  // Reset curve assignment to priority pops (cloning due to inplace replacement that follows)
  const newPriorPop = structuredClone(piasu.getModVarValue(modVars, pisc.priorPopsMVTag));

  const defPriorPop = piasu.getModVarValue(defModVars, pisc.priorPopsMVTag);
  const defContCurves = piasu.getModVarValue(defModVars, "PI_ContinuationCurves");

  // Replace any prior pop contCurve with the corresponding default contCurve
  for (const pp of newPriorPop) {
    const defPP = defPriorPop.find((v) => v.mstID === pp.mstID);

    for (let [ppIdx, ppCurve] of pp.contCurve.entries()) {
      // custom priority pops default all methods to first available curve
      if (defPP === undefined) {
        pp.contCurve[ppIdx] = { curveID: defContCurves[0].mstID, mstID: ppCurve.mstID };
        continue;
      }

      // methods all revert to same curve as first method of the corresponding priority pop
      pp.contCurve[ppIdx] = { curveID: defPP.contCurve[0].curveID, mstID: ppCurve.mstID };
    }
  }

  piasu.setModVarValue(modVars, pisc.priorPopsMVTag, newPriorPop);

  return modVars;
}

const PIResetDefaultContCurvesBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const onResetClick = async () => {
    try {
      props.onCalculatingChange(true);

      let modVarObjList = null;

      // Fetch defaults from server, inc. hoop jumping
      const pseMode = piasu.isPSEMode(props.modVarObjList);
      const countryCode = piasu.getModVarValue(props.modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode, pse: pseMode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      switch (props.activeTabIdx) {
        case defineCurvesTab:
          modVarObjList = resetCurvesConfiguration(defModVars, props.modVarObjList, props.origModVarObjArr);
          break;
        case assignCurvesTab:
          modVarObjList = resetAssignCurves(defModVars, props.modVarObjList);
          break;
        default:
          console.log(`Reset to defaults not supported for tab: ${props.activeTabIdx}`);
      }

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, false);
    } catch (err) {
      console.log(err);
    } finally {
      props.onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"resetCurvesBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetDefaultContCurvesBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  origModVarObjArr: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
  activeTabIdx: PropTypes.number.isRequired,
};

PIResetDefaultContCurvesBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetDefaultContCurvesBtn;
