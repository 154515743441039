import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";
import { onCalculate } from "../NonComponents/PICalc";
import {
  PICustomRowTableProps,
  firstRow,
  hiddenColOffset,
  sharedPropTypes,
  sharedDefaultProps,
  initialState,
  getDeleteBtnCol,
  getNumCols,
  AddButton,
  DeleteButton,
  handleCustomItemCalculate,
} from "../Other/PICustomRowsUtil";

import { generateTypes } from "../../../utilities";
import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";

const itemNameColumn = hiddenColOffset;
const scaleUpTypeCol = itemNameColumn + 1;
const midpointCol = scaleUpTypeCol + 1;
const initSpeedCol = midpointCol + 1;
const lastColumn = initSpeedCol;

export class PIScaleUpTrendsTable extends Component {
  static propTypes = {
    ...sharedPropTypes,
  };
  static defaultProps = {
    ...sharedDefaultProps,

    [pip.tableKey]: this.props?.[pip.tableKey] ?? "",
  };

  state = initialState;

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onScaleUpTypeComboBoxChange = (value, text, info) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjList = structuredClone(props[pias.modVarObjList]);

      let objList = piasu.getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

      const scaleUpTypeCurrID = value + 1;
      const scaleUpTrendCurrID = info;

      const scaleUpTypeMstID = piu.getScaleUpTypeMstID(scaleUpTypeCurrID);

      piasu.setScaleUpTrendScaleUpTypeMstID(objList, scaleUpTrendCurrID, scaleUpTypeMstID);

      piasu.setScaleUpTrendMidpoint(objList, scaleUpTrendCurrID, scaleUpTypeMstID === "S-SHAPED" ? 9 : 0);

      piasu.setScaleUpTrendInitSpeed(objList, scaleUpTrendCurrID, scaleUpTypeMstID === "S-SHAPED" ? 0.3 : 0);

      onCalculatingChange(true, () => {
        onModVarsChange(modVarObjList, false, () => {
          /* Put this here because after the editor values change, the user needs to see
                        the graph under it update. */
          onCalculate(
            modVarObjList,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false);
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];
      const modVarObjList = structuredClone(props[pias.modVarObjList]);

      const objList = piasu.getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
      const numObjects = piasu.getTotalNumScaleUpTrends(objList);

      for (let row = 1; row <= numObjects; row++) {
        /* Users can change the name in the first visible column now. */
        const itemName = gbtu.getValue(newPackTable, row, itemNameColumn);
        piasu.setContCurveName(objList, row, itemName);

        let midpointFlt = gbtu.getValue(newPackTable, row, midpointCol);
        if (midpointFlt === "") {
          midpointFlt = 0.0;
        }
        piasu.setScaleUpTrendMidpoint(objList, row, midpointFlt);

        let initSpeedFlt = gbtu.getValue(newPackTable, row, initSpeedCol);
        if (initSpeedFlt === "") {
          initSpeedFlt = 0.0;
        }
        piasu.setScaleUpTrendInitSpeed(objList, row, initSpeedFlt);
      }

      this.setState({ [pip.rDec]: newPackTable[gbtc.rDec] }, async () =>
        handleCustomItemCalculate(onCalculatingChange, onModVarsChange, modVarObjList, onDialogChange)
      );
    } catch (exception) {
      alert(exception.name + " (PIScaleUpTrendsTable.onPackTableChange) : " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const allowEditsBool = props[PICustomRowTableProps.allowEditsBoolC];
      const tableKey = this.state[pip.tableKey];

      const state = this.state;
      const focusedCell = state[pip.focusedCell];
      const selectedRegions = state[pip.selectedRegions];
      const rDec = state[pip.rDec];

      const objList = piasu.getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

      const numObjects = piasu.getTotalNumScaleUpTrends(objList);

      const deleteBtnCol = getDeleteBtnCol(this.props, lastColumn);
      const numCols = getNumCols(this.props, lastColumn);

      const numRows = numObjects + 1;

      let packTable = gbtu.getNewPackTable();

      let frameworkComponents = allowEditsBool ? {} : null;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, itemNameColumn, RS(SC.GB_stScaleUpTrend));
      gbtu.setValue(packTable, firstRow, scaleUpTypeCol, RS(SC.GB_stScaleUpType));
      gbtu.setValue(packTable, firstRow, midpointCol, RS(SC.GB_stMidpointOfCurve));
      gbtu.setValue(packTable, firstRow, initSpeedCol, RS(SC.GB_stInitSpeedOfScaleUp));
      if (allowEditsBool) {
        gbtu.setValue(packTable, firstRow, deleteBtnCol, RS(SC.GB_stDeleteBtn));
      }

      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      for (let item = 1; item <= numObjects; item++) {
        /* Set row values. */
        const itemName = piasu.getScaleUpTrendName(objList, item);
        gbtu.setValue(packTable, item, itemNameColumn, itemName);

        gbtu.setHasComboBox(packTable, item, scaleUpTypeCol, true);

        comboBoxObj[pitu.style2DObjArray][item][scaleUpTypeCol] = {
          color: Theme.blackColor,
          fontFamily: Theme.fontFamily,
          width: 140,
        };

        let scaleUpTrendsCurrIDArray = [];
        scaleUpTrendsCurrIDArray.length = pic.numScaleUpTypes;
        scaleUpTrendsCurrIDArray.fill(item);
        comboBoxObj[pitu.info3DIntArray][item][scaleUpTypeCol] = scaleUpTrendsCurrIDArray;

        comboBoxObj[pitu.item3DStrArray][item][scaleUpTypeCol] = piu.getScaleUpTypeNames();

        const scaleUpTypeMstID = piasu.getScaleUpTrendScaleUpTypeMstID(objList, item);
        const scaleUpTypeCurrID = piu.getScaleUpTypeCurrID(scaleUpTypeMstID);
        comboBoxObj[pitu.idx2DIntArray][item][scaleUpTypeCol] = scaleUpTypeCurrID - 1;

        comboBoxObj[pitu.onChange2DFuncArray][item][scaleUpTypeCol] = this.onScaleUpTypeComboBoxChange;

        const midPointFlt = piasu.getScaleUpTrendMidpoint(objList, item);
        gbtu.setValue(packTable, item, midpointCol, midPointFlt);

        const initSpeedFlt = piasu.getScaleUpTrendInitSpeed(objList, item);
        gbtu.setValue(packTable, item, initSpeedCol, initSpeedFlt);

        /* If not showing s-shaped, set the font color to white so the user can't see it instead of adding/
                   removing the number to avoid issues with rDec. */
        if (scaleUpTypeMstID !== pisc.sShapedScaleUpMstID) {
          gbtu.lockCell(packTable, item, midpointCol, true, true);
          gbtu.lockCell(packTable, item, initSpeedCol, true, true);

          const whiteColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whiteColor));
          const bandColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.PI_BandColor));
          const color = item % 2 === 1 ? whiteColor : bandColor;
          gbtu.setFontColor(packTable, item, midpointCol, color);
          gbtu.setFontColor(packTable, item, initSpeedCol, color);
        }

        if (allowEditsBool) {
          /* Need to save these into variables before passing or the link component will be sent the wrong row, col. */
          let rowPassed = item;
          let colPassed = deleteBtnCol;

          let btnKey = "btn row" + rowPassed + "_col" + colPassed;
          packTable.components[rowPassed][colPassed] = () => (
            <DeleteButton
              id={btnKey}
              row={rowPassed}
              itemType={pic.scaleUpTrendItems}
              props={this.props}
              state={this.state}
              setState={this.setState.bind(this)}
            />
          );
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setColAlignment(packTable, deleteBtnCol, gbtc.hAlign.center);
      /* Needed when entering text. */
      gbtu.setMinAllowedValByCol(packTable, itemNameColumn, 0);
      gbtu.setMaxAllowedValByCol(packTable, itemNameColumn, 0);
      /* Hide the first column so the user can type in the first visible column. */
      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, itemNameColumn, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, scaleUpTypeCol, 140 + 10);
      gbtu.setColWidth(packTable, midpointCol, Theme.dataColWidthSmall);
      gbtu.setColWidth(packTable, initSpeedCol, Theme.dataColWidthSmall);
      if (allowEditsBool) {
        gbtu.setColWidth(packTable, deleteBtnCol, Theme.dataColWidthSmall);
      }
      gbtu.setRowHeight(packTable, firstRow, 120);
      gbtu.setWordWrappedCol(packTable, 0, true);
      gbtu.setMinAllowedValByCol(packTable, midpointCol, 100);
      gbtu.setMaxAllowedValByCol(packTable, initSpeedCol, 1);

      if (!allowEditsBool) {
        gbtu.lockPackTable(packTable, true);
      }
      gbtu.lockCol(packTable, deleteBtnCol, true, false);

      if (rDec.length === 0) {
        gbtu.setRDecs(packTable, 1);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIScaleUpTrendsTable");
        console.log("ModVar(s):");
        console.log(pisc.scaleUpTrendsMVTag);
        console.log(objList);
        console.log("");
      }

      const stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={focusedCell}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          frameworkComponents={frameworkComponents}
          gridKey={tableKey}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{
            ...packTable,
            GBFixedCols: 0,
            GBRowHeights: packTable.GBRowHeights.map((rowHeight, index) => (index === 0 ? rowHeight : -1)),
          }}
          types={generateTypes(packTable, ["s", "s", "dd", "n", "n", "cm"])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={selectedRegions}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIScaleUpTrendsTable");
  };

  render() {
    return (
      <>
        {this.renderTable()}
        {
          <AddButton
            itemType={pic.scaleUpTrendItems}
            props={this.props}
            state={this.state}
            setState={this.setState.bind(this)}
          />
        }
      </>
    );
  }
}
