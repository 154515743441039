import React, { Component } from "react";
import * as PropTypes from "prop-types";

import Check from "@material-ui/icons/Check";
import Divider from "@material-ui/core/Divider";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

//import TComboBox from "../../common/TComboBox";
//import TRadioGroup from "../../common/TRadioGroup";

import * as gbu from "../../GB/GBUtil";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PIItemsSlideDrawer from "../Drawers/PIItemsSlideDrawer";
import PIContinuationSlideDrawer from "../Drawers/PIContinuationSlideDrawer";
import PIVisitSchedSlideDrawer from "../Drawers/PIVisitSchedSlideDrawer";
import PIProgDataSlideDrawer from "../Drawers/PIProgDataSlideDrawer";
import TButton from "../../common/TButton";
import * as pip from "../NonComponents/PIProps";
import * as php from "../NonComponents/PIHelp";
import { onCalculate } from "../NonComponents/PICalc";

const btnDivHeight = 60;

const showContSlideDrawerBoolC = "showContSlideDrawerBool";
const showMethodSlideDrawerBoolC = "showMethodSlideDrawerBool";
const showPriorPopSlideDrawerBoolC = "showPriorPopSlideDrawerBool";
const showProgDataSlideDrawerBoolC = "showProgDataSlideDrawerBool";
const showVisitSchedSlideDrawerBoolC = "showVisitSchedSlideDrawerBool";

class PIConfigForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.calcContCurvePercOnPrEPBool]: PropTypes.bool,
    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,

    [pias.countryObjList]: PropTypes.array,

    [pias.onDialogChange]: PropTypes.func,

    [pias.onDrawerChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.calcContCurvePercOnPrEPBool]: false,
    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(pias.onCalcContCurvePercOnPrEPChange),

    [pias.countryObjList]: [],

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pias.helpAreaStr]: php.configFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pias.tableKeyObj]: {},
  };

  state = {
    [showContSlideDrawerBoolC]: false,
    [showMethodSlideDrawerBoolC]: false,
    [showPriorPopSlideDrawerBoolC]: false,
    [showProgDataSlideDrawerBoolC]: false,
    [showVisitSchedSlideDrawerBoolC]: false,
  };

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.configFM_HP);
  }

  //==================================================================================================================
  //
  //                                              Utility
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onCostingModeRadioGroupChange = (value) => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    let costingModeMstID;

    // if (value === 0) {
    //
    //     costingModeMstID = pisc.detailedCostModeMstID;
    //
    // }
    //else if (value === 1) {
    if (value === 0) {
      costingModeMstID = pisc.costsLiteModeMstID;
    }
    //else if (value === 2) {
    else if (value === 1) {
      costingModeMstID = pisc.noCostingModeMstID;
    }

    piasu.setModVarValue(modVarObjList, pisc.costingModuleMVTag, costingModeMstID);

    props[pias.onModVarsChange](modVarObjList, false);
  };

  onPriorPopsSlideDrawerChange = (successFn) => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const showPriorPopSlideDrawerBool = !state[showPriorPopSlideDrawerBoolC];

      /* Visiting the priority populations drawer and ensuring that at least one priority population is active
               is enough to "complete" it. Note that you cannot leave the priority population slide drawer
               without selecting at least one priority population, so we don't need to check to make sure if
               a priority population exists here. */

      let areaObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.completedAreasMVTag);
      piasu.areaCompleted(areaObjArr, pisc.priorPopAreaMstID, true);

      // Ensure PI_DistrictPopulations mod var has correct values if pops were added
      // This is also done on the server side as part of the session updater, but that is not always called so
      // this still needs to be here to account for populations being added.
      const districtPops = piasu.getModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag);

      const popIDs = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag).map((v) => v.mstID);
      const newDistrictPops = districtPops.map((v) => {
        const pops = popIDs.map((popID) => {
          return [popID, v?.priorityPopulations?.find((pop) => pop[0] === popID)?.[1] ?? true];
        });
        return {
          ...v,
          priorityPopulations: pops,
        };
      });

      piasu.setModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag, newDistrictPops);

      const fn = () => {
        let helpAreaStr = "";
        if (showPriorPopSlideDrawerBool) {
          helpAreaStr = php.config_PriorPopSD_HP;
        } else {
          helpAreaStr = php.configFM_HP;
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState(
            {
              [showPriorPopSlideDrawerBoolC]: showPriorPopSlideDrawerBool,
            },
            () => {
              gbu.safeCallFn(successFn);
            }
          );
        });
      };

      // This previously only called onModVarseChange if showPriorPopSlideDrawerBool was true,
      // otherwise it would just call fn() directly. Since we may have mutated the mod vars
      // above this now needs to call it all the time.
      onModVarsChange(modVarObjArrClone, false, () => {
        fn();
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onMethodSlideDrawerChange = (successFn) => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      let modVarObjArr = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      let showMethodSlideDrawerBool = !state[showMethodSlideDrawerBoolC];

      /* Visiting the methods drawer and ensuring that at least one method is active
               is enough to "complete" it. Note that you cannot leave the method slide drawer
               without selecting at least one method, so we don't need to check to make sure if
               a method exists here. */
      let areaObjArr = piasu.getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
      piasu.areaCompleted(areaObjArr, pisc.methodsAreaMstID, true);

      // Priority pops and methods are combined now, so we also need to set the prior pops completed flag
      piasu.areaCompleted(areaObjArr, pisc.priorPopAreaMstID, true);

      /* If there is more than one method selected, switch to Costs Lite. */
      //const methodObjArr = piasu.getModVarValue(modVarObjArrClone, methodsMVTag);
      //const numMethods = piasu.getTotalNumMethods(methodObjArr);
      //
      // if ((numMethods > 1) && (costingModeMstIDStr === pisc.detailedCostModeMstID)) {
      //
      //     piasu.setModVarValue(modVarObjArrClone, pisc.costingModuleMVTag, pisc.costsLiteModeMstID);
      //
      // }

      const fn = () => {
        let helpAreaStr = "";

        if (showMethodSlideDrawerBool) {
          helpAreaStr = php.config_MethodsSD_HP;
        } else {
          helpAreaStr = php.configFM_HP;
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState(
            {
              [showMethodSlideDrawerBoolC]: showMethodSlideDrawerBool,
            },
            () => {
              gbu.safeCallFn(successFn);
            }
          );
        });
      };

      if (showMethodSlideDrawerBool) {
        onModVarsChange(modVarObjArr, false, () => {
          fn();
        });
      } else {
        fn();
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onContSlideDrawerChange = (successFn) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      let modVarObjArr = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      let showContSlideDrawerBool = !state[showContSlideDrawerBoolC];

      /* Visiting the continuation curves drawer is enough to "complete" it. */
      let areaObjArr = piasu.getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
      piasu.areaCompleted(areaObjArr, pisc.contCurveAreaMstID, true);

      const fn = () => {
        let helpAreaStr = "";

        if (showContSlideDrawerBool) {
          helpAreaStr = php.config_ContSD_HP;
        } else {
          helpAreaStr = php.configFM_HP;
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState(
            {
              [showContSlideDrawerBoolC]: showContSlideDrawerBool,
            },
            () => {
              gbu.safeCallFn(successFn);
            }
          );
        });
      };

      if (showContSlideDrawerBool) {
        onCalculatingChange(true, () => {
          onCalculate(
            modVarObjArr,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false, () => {
                  fn();
                });
              });
            },
            () => {
              onCalculatingChange(false, () => {
                fn();
              });
            }
          );
        });
      } else {
        fn();
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onContBtnClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onDrawerChange = props[pias.onDrawerChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    let state = this.state;
    let showContSlideDrawerBool = !state[showContSlideDrawerBoolC];

    /* Visiting the continuation curves drawer is enough to "complete" it. */
    let areaObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.completedAreasMVTag);
    piasu.areaCompleted(areaObjArr, pisc.contCurveAreaMstID, true);

    onCalculatingChange(true, () => {
      /* Item objects contain input and result fields. The result fields are only present
               after calculating.  Call calculate to add them or certain graphs and charts that rely
               on result fields may break. The original ModVar object array is also only set after
               the first call to onModVarsChange. We need to make sure it's called first since we'll
               use it to restore defaults and create objects. */
      onCalculate(
        modVarObjArrClone,
        "",
        onDialogChange,
        (response) => {
          onModVarsChange(response, false, () => {
            onDrawerChange(pic.continuationDrawer, pic.drawerLv1, () => {
              onHelpAreaChange(php.config_ContSD_HP, () => {
                onCalculatingChange(false, () => {
                  this.setState({
                    [showContSlideDrawerBoolC]: showContSlideDrawerBool,
                  });
                });
              });
            });
          });
        },
        () => onCalculatingChange(false)
      );
    });
  };

  onVisitSchedSlideDrawerChange = (successFn) => {
    try {
      const props = this.props;
      //const onCalculatingChange = props[pias.onCalculatingChange];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      let modVarObjArr = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      let showVisitSchedSlideDrawerBool = !state[showVisitSchedSlideDrawerBoolC];

      /* Visiting the continuation curves drawer is enough to "complete" it. */
      //let areaObjArr = piasu.getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
      //piasu.areaCompleted(areaObjArr, pisc.contCurveAreaMstID, true);

      let areaObjArr = piasu.getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
      piasu.areaCompleted(areaObjArr, pisc.visitSchedAreaMstID, true);

      const fn = () => {
        let helpAreaStr = "";

        if (showVisitSchedSlideDrawerBool) {
          helpAreaStr = php.config_VisitSchedules;
        } else {
          helpAreaStr = php.configFM_HP;
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState(
            {
              [showVisitSchedSlideDrawerBoolC]: showVisitSchedSlideDrawerBool,
            },
            () => {
              gbu.safeCallFn(successFn);
            }
          );
        });
      };

      if (showVisitSchedSlideDrawerBool) {
        //onCalculatingChange(true, () => {

        //onCalculate(modVarObjArr, "", (response) => {

        onModVarsChange(modVarObjArr, false, () => {
          //onCalculatingChange(false, () => {

          fn();

          //});
        });

        //}, () => onCalculatingChange(false));

        //});
      } else {
        fn();
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onVisitSchedBtnClick = () => {
    const props = this.props;
    let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
    //const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onDrawerChange = props[pias.onDrawerChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    let state = this.state;
    let showVisitSchedSlideDrawerBool = !state[showVisitSchedSlideDrawerBoolC];

    /* Visiting the visit schedules drawer is enough to "complete" it. */
    let areaObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.completedAreasMVTag);
    piasu.areaCompleted(areaObjArr, pisc.visitSchedAreaMstID, true);

    //onCalculatingChange(true, () => {

    /* Item objects contain input and result fields. The result fields are only present
               after calculating.  Call calculate to add them or certain graphs and charts that rely
               on result fields may break. The original ModVar object array is also only set after
               the first call to onModVarsChange. We need to make sure it's called first since we'll
               use it to restore defaults and create objects. */
    //onCalculate(modVarObjArrClone, "", (response) => {

    onModVarsChange(modVarObjArrClone, false, () => {
      onDrawerChange(pic.visitSchedDrawer, pic.drawerLv1, () => {
        onHelpAreaChange(php.config_VisitSchedules, () => {
          //onCalculatingChange(false, () => {

          this.setState({
            [showVisitSchedSlideDrawerBoolC]: showVisitSchedSlideDrawerBool,
          });

          //});
        });
      });
    });

    //}, () => onCalculatingChange(false));

    //});
  };

  onProgDataSlideDrawerChange = (successFn) => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      let modVarObjArr = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      let showProgDataSlideDrawerBool = !state[showProgDataSlideDrawerBoolC];

      /* Visiting the visit schedules drawer is enough to "complete" it. */
      let areaObjArr = piasu.getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
      piasu.areaCompleted(areaObjArr, pisc.progDataAreaMstID, true);

      const fn = () => {
        let helpAreaStr = "";

        if (showProgDataSlideDrawerBool) {
          helpAreaStr = php.config_ProgDataSD_HP;
        } else {
          helpAreaStr = php.configFM_HP;
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState(
            {
              [showProgDataSlideDrawerBoolC]: showProgDataSlideDrawerBool,
            },
            () => {
              gbu.safeCallFn(successFn);
            }
          );
        });
      };

      if (showProgDataSlideDrawerBool) {
        onModVarsChange(modVarObjArr, false, () => {
          fn();
        });
      } else {
        fn();
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      const backPageID = piasu.getPageID(modVarObjArray, pic.configFormOrder, pic.back);
      onPageChangeEvent = () => onPageChange(backPageID);
    } else if (direction === pic.next) {
      const nextPageID = piasu.getPageID(modVarObjArray, pic.configFormOrder, pic.next);
      onPageChangeEvent = () => onPageChange(nextPageID);
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    }
  };

  renderPriorPopsDiv = () => {
    const fn = () => {
      const props = this.props;
      // const onDrawerChange = props[pias.onDrawerChange];
      const modVarObjList = props[pias.modVarObjList];

      //const state = this.state;
      //const calculatingBool = state[pip.calculatingBool];

      const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.completedAreasMVTag);

      const countrySelected = piasu.getCountrySelected(modVarObjList);
      const btnEnabled = countrySelected; //(countrySelected && (!calculatingBool));

      const populationsBtn = (
        <TButton
          caption={RS(SC.GB_stPriorPops)}
          onClick={this.onPriorPopsSlideDrawerChange}
          disabled={!btnEnabled}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            width: 200,
          }}
        />
      );

      let populationsReqLab = null;
      let populationsCheckImg = null;
      if (btnEnabled) {
        const areaCompleted = piasu.areaCompleted(areaObjArr, pisc.priorPopAreaMstID);

        if (areaCompleted) {
          populationsCheckImg = (
            <Check
              key={"populationsCheckImg"}
              style={{
                color: Theme.PI_CheckColor,
                marginLeft: Theme.leftIndent,
              }}
            />
          );
        } else {
          populationsReqLab = (
            <p
              key={"populationsReqLab"}
              style={{
                color: Theme.red,
                marginLeft: Theme.leftIndent,
              }}
            >
              {RS(SC.GB_stRequired)}
            </p>
          );
        }
      }

      const populationsDiv = (
        <div
          key={"populationsDiv"}
          style={{
            alignItems: "center",
            display: "flex",
            height: btnDivHeight,
            marginTop: 10,
          }}
        >
          {populationsBtn}
          {populationsReqLab}
          {populationsCheckImg}
        </div>
      );

      return populationsDiv;
    };

    return gbu.tryRenderFn(fn, "renderPriorPopsDiv");
  };

  renderMethodsDiv = () => {
    const fn = () => {
      const props = this.props;
      // const onDrawerChange = props[pias.onDrawerChange];
      const modVarObjList = props[pias.modVarObjList];

      //   const state = this.state;
      //const calculatingBool = state[pip.calculatingBool];

      const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.completedAreasMVTag);

      const countrySelected = piasu.getCountrySelected(modVarObjList);
      const btnEnabled = countrySelected; //(countrySelected && (!calculatingBool));

      const methodsBtn = (
        <TButton
          caption={RS(SC.GB_stMethods)}
          onClick={this[pip.onMethodSlideDrawerChange]}
          disabled={!btnEnabled}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            width: 200,
          }}
        />
      );

      let methodsReqLab = null;
      let methodsCheckImg = null;
      if (btnEnabled) {
        const areaCompleted = piasu.areaCompleted(areaObjArr, pisc.methodsAreaMstID);

        if (areaCompleted) {
          methodsCheckImg = (
            <Check
              key={"methodsCheckImg"}
              style={{
                color: Theme.PI_CheckColor,
                marginLeft: Theme.leftIndent,
              }}
            />
          );
        } else {
          methodsReqLab = (
            <p
              key={"methodsReqLab"}
              style={{
                color: Theme.red,
                marginLeft: Theme.leftIndent,
              }}
            >
              {RS(SC.GB_stRequired)}
            </p>
          );
        }
      }

      const methodsDiv = (
        <div
          key={"methodsDiv"}
          style={{
            alignItems: "center",
            display: "flex",
            height: btnDivHeight,
          }}
        >
          {methodsBtn}
          {methodsReqLab}
          {methodsCheckImg}
        </div>
      );

      return methodsDiv;
    };

    return gbu.tryRenderFn(fn, "renderMethodsDiv");
  };

  renderContinuationDiv = () => {
    const fn = () => {
      const props = this.props;
      // const onDrawerChange = props[pias.onDrawerChange];
      const modVarObjList = props[pias.modVarObjList];

      //const state = this.state;
      //const calculatingBool = state[pip.calculatingBool];

      const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.completedAreasMVTag);

      const countrySelected = piasu.getCountrySelected(modVarObjList);
      const btnEnabled = countrySelected; //(countrySelected && (!calculatingBool));

      let continuationDiv = null;

      const continuationBtn = (
        <TButton
          caption={RS(SC.GB_stContinuation)}
          onClick={this.onContBtnClick}
          disabled={!btnEnabled}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            width: 200,
          }}
        />
      );

      let continuationReqLab = null;
      let continuationCheckImg = null;
      if (btnEnabled) {
        const areaCompleted = piasu.areaCompleted(areaObjArr, pisc.contCurveAreaMstID);

        if (areaCompleted) {
          continuationCheckImg = (
            <Check
              key={"populationsCheckImg"}
              style={{
                color: Theme.PI_CheckColor,
                marginLeft: Theme.leftIndent,
              }}
            />
          );
        } else {
          continuationReqLab = (
            <p
              key={"continuationReqLab"}
              style={{
                color: Theme.red,
                marginLeft: Theme.leftIndent,
              }}
            >
              {RS(SC.GB_stRequired)}
            </p>
          );
        }
      }

      continuationDiv = (
        <div
          key={"continuationDiv"}
          style={{
            alignItems: "center",
            display: "flex",
            height: btnDivHeight,
          }}
        >
          {continuationBtn}
          {continuationReqLab}
          {continuationCheckImg}
        </div>
      );

      return continuationDiv;
    };

    return gbu.tryRenderFn(fn, "renderContinuationDiv");
  };

  renderVisitSchedDiv = () => {
    const fn = () => {
      const props = this.props;
      //const onDrawerChange = props[pias.onDrawerChange];
      const modVarObjList = props[pias.modVarObjList];

      //const state = this.state;
      //const calculatingBool = state[pip.calculatingBool];

      const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.completedAreasMVTag);

      const countrySelected = piasu.getCountrySelected(modVarObjList);
      const btnEnabled = countrySelected;

      let visitSchedDiv = null;

      if (piasu.showVisitSched(modVarObjList)) {
        const visitSchedBtn = (
          <TButton
            caption={RS(SC.GB_stVisitSchedules)}
            onClick={this.onVisitSchedBtnClick}
            disabled={!btnEnabled}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              width: 200,
            }}
          />
        );

        let visitSchedReqLab = null;
        let visitSchedCheckImg = null;
        if (btnEnabled) {
          const areaCompleted = piasu.areaCompleted(areaObjArr, pisc.visitSchedAreaMstID);

          if (areaCompleted) {
            visitSchedCheckImg = (
              <Check
                key={"visitSchedCheckImg"}
                style={{
                  color: Theme.PI_CheckColor,
                  marginLeft: Theme.leftIndent,
                }}
              />
            );
          } else {
            visitSchedReqLab = (
              <p
                key={"visitSchedReqLab"}
                style={{
                  color: Theme.red,
                  marginLeft: Theme.leftIndent,
                }}
              >
                {RS(SC.GB_stRequired)}
              </p>
            );
          }
        }

        visitSchedDiv = (
          <div
            key={"visitSchedDiv"}
            style={{
              alignItems: "center",
              display: "flex",
              height: btnDivHeight,
            }}
          >
            {visitSchedBtn}
            {visitSchedReqLab}
            {visitSchedCheckImg}
          </div>
        );
      }

      return visitSchedDiv;
    };

    return gbu.tryRenderFn(fn, "renderVisitSchedDiv");
  };

  renderProgDataDiv = () => {
    const fn = () => {
      const props = this.props;
      //const onDrawerChange = props[pias.onDrawerChange];
      const modVarObjList = props[pias.modVarObjList];

      //const state = this.state;
      //const calculatingBool = state[pip.calculatingBool];

      const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.completedAreasMVTag);

      const countrySelected = piasu.getCountrySelected(modVarObjList);
      const btnEnabled = countrySelected; // (countrySelected && (!calculatingBool));

      let progDataDiv = null;

      if (piasu.showProgramData(modVarObjList)) {
        const progDataBtn = (
          <TButton
            caption={RS(SC.GB_stProgramData)}
            onClick={this.onProgDataSlideDrawerChange}
            disabled={!btnEnabled}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              width: 200,
            }}
          />
        );

        let progDataCheckImg = null;
        if (btnEnabled) {
          const areaCompleted = piasu.areaCompleted(areaObjArr, pisc.progDataAreaMstID);

          if (areaCompleted) {
            progDataCheckImg = (
              <Check
                key={"progDataCheckImg"}
                style={{
                  color: Theme.PI_CheckColor,
                  marginLeft: Theme.leftIndent,
                }}
              />
            );
          }
        }

        progDataDiv = (
          <div
            key={"initiationDiv"}
            style={{
              alignItems: "center",
              display: "flex",
              height: btnDivHeight,
            }}
          >
            {progDataBtn}
            {progDataCheckImg}
          </div>
        );
      }

      return progDataDiv;
    };

    return gbu.tryRenderFn(fn, "renderProgDataDiv");
  };

  renderPriorPopSlideDrawer = () => {
    const fn = () => {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];
      const onCalculatingChange = props[pias.onCalculatingChange];

      const onPageChange = props[pias.onPageChange];
      const tableKeyObj = props[pias.tableKeyObj];

      const state = this.state;
      const showPriorPopSlideDrawerBool = state[showPriorPopSlideDrawerBoolC];

      let priorPopSlideDrawer = null;

      if (showPriorPopSlideDrawerBool) {
        priorPopSlideDrawer = (
          <PIItemsSlideDrawer
            {...{
              /* State-related */

              //mark [pip.extraContent]         : priorPopExtraContent, // mark

              [pias.onDialogChange]: onDialogChange,

              [pias.onDrawerChange]: this.onPriorPopsSlideDrawerChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,
              [pias.onCalculatingChange]: onCalculatingChange,

              // [pias.onPageChange]        : onPageChange,

              [pias.tableKeyObj]: tableKeyObj,

              [pip.itemType]: pic.priorPopItems,

              //[pip.onMethodSlideDrawerChange] : this[pip.onMethodSlideDrawerChange],
              [pip.onNextAreaChange]: this[pip.onMethodSlideDrawerChange],
              [pip.onPrevAreaChange]: onPageChange,

              /* For top form */

              [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
              [pip.itemDrawerTitleStr]: RS(SC.GB_stPriorPops),
              [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomPriorPops),
              [pip.selectItemsStr]: RS(SC.GB_stSelectPriorPops),
              [pip.yourItemsStr]: RS(SC.GB_stYourPriorPops),

              /* For custom item form */

              [pip.addItemNoteStr]: RS(SC.GB_stAddPriorPopNote),
              [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomPriorPops),
              [pip.editItemInstructStr]: RS(SC.GB_stEditPriorPopInstruct),
              [pip.itemTypeStr]: RS(SC.GB_stPriorityPop),
              [pip.removeItemNoteStr]: RS(SC.GB_stRemovePriorPopNote),
              [pip.moveItemNoteStr]: RS(SC.GB_stMovePriorPopUpNote),
              [pip.moveItemDownNoteStr]: RS(SC.GB_stMovePriorPopDownNote),
            }}
          />
        );
      }

      return priorPopSlideDrawer;
    };

    return gbu.tryRenderFn(fn, "renderPriorPopSlideDrawer");
  };

  renderMethodSlideDrawer = () => {
    const fn = () => {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];
      const onPageChange = props[pias.onPageChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const tableKeyObj = props[pias.tableKeyObj];

      const state = this.state;
      const showMethodSlideDrawerBool = state[showMethodSlideDrawerBoolC];

      /* Change prev/next areas depending on what's showing. */

      let onNextAreaChange;
      let onPrevAreaChange = this[pip.onPriorPopsSlideDrawerChange];

      onNextAreaChange = this[pip.onContSlideDrawerChange];

      let methodSlideDrawer = null;

      if (showMethodSlideDrawerBool) {
        methodSlideDrawer = (
          <PIItemsSlideDrawer
            {...{
              /* State-related */

              [pias.onDialogChange]: onDialogChange,
              [pias.onDrawerChange]: this[pip.onMethodSlideDrawerChange],

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.onPageChange]: onPageChange,

              [pip.onNextAreaChange]: onNextAreaChange,
              [pip.onPrevAreaChange]: onPrevAreaChange,

              [pias.tableKeyObj]: tableKeyObj,

              [pip.itemType]: pic.methodItems,

              /* For top form */
              [pip.allowCustomItemsBool]: true,
              [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
              [pip.itemDrawerTitleStr]: RS(SC.GB_stMethods),
              [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomMethods),
              [pip.selectItemsStr]: RS(SC.GB_stSelectMethods),
              [pip.yourItemsStr]: RS(SC.GB_stYourMethods),

              /* For custom item form */

              [pip.addItemNoteStr]: RS(SC.GB_stAddMethodNote),
              [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomMethods),
              [pip.editItemInstructStr]: RS(SC.GB_stEditMethodsInstruct),
              [pip.itemTypeStr]: RS(SC.GB_stMethod),
              [pip.removeItemNoteStr]: RS(SC.GB_stRemoveMethodNote),
              [pip.moveItemNoteStr]: RS(SC.GB_stMoveMethodUpNote),
              [pip.moveItemDownNoteStr]: RS(SC.GB_stMoveMethodDownNote),
            }}
          />
        );
      }

      return methodSlideDrawer;
    };

    return gbu.tryRenderFn(fn, "renderMethodSlideDrawer");
  };

  renderContinuationSlideDrawer = () => {
    const fn = () => {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const calcContCurvePercOnPrEPBool = props[pias.calcContCurvePercOnPrEPBool];
      const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];
      //const onPageChange = props[pias.onPageChange];
      const tableKeyObj = props[pias.tableKeyObj];

      const state = this.state;
      const showContSlideDrawerBool = state[showContSlideDrawerBoolC];

      let continuationSlideDrawer = null;

      /* Change prev/next areas depending on what's showing. */

      let onPrevAreaChange = this[pip.onMethodSlideDrawerChange];
      let onNextAreaChange = this[pip.onVisitSchedSlideDrawerChange];

      // if (piasu.showProgramData(modVarObjList)) {
      //
      //     onNextAreaChange = this[pip.onProgDataSlideDrawerChange];
      //
      // }
      // else {
      //
      //     const nextPageID = piasu.getPageID(modVarObjList, pic.configFormOrder, pic.next);
      //     onNextAreaChange = () => onPageChange(nextPageID);
      //
      // }

      if (showContSlideDrawerBool) {
        continuationSlideDrawer = (
          <PIContinuationSlideDrawer
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.calcContCurvePercOnPrEPBool]: calcContCurvePercOnPrEPBool,
              [pias.onCalcContCurvePercOnPrEPChange]: onCalcContCurvePercOnPrEPChange,

              [pias.onDialogChange]: onDialogChange,

              [pias.onDrawerChange]: this[pip.onContSlideDrawerChange],

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              //[pip.onMethodSlideDrawerChange] : this[pip.onMethodSlideDrawerChange],

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              //[pip.onProgDataSlideDrawerChange] : this[pip.onProgDataSlideDrawerChange],

              [pip.onNextAreaChange]: onNextAreaChange,
              [pip.onPrevAreaChange]: onPrevAreaChange,

              [pias.tableKeyObj]: tableKeyObj,
            }}
          />
        );
      }

      return continuationSlideDrawer;
    };

    return gbu.tryRenderFn(fn, "renderContinuationSlideDrawer");
  };

  renderVisitSchedSlideDrawer = () => {
    const fn = () => {
      const props = this.props;
      const onCalculatingChange = props[pias.onCalculatingChange];
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];
      const onPageChange = props[pias.onPageChange];
      const tableKeyObj = props[pias.tableKeyObj];

      const state = this.state;
      const showVisitSchedSlideDrawerBool = state[showVisitSchedSlideDrawerBoolC];

      let visitSchedSlideDrawer = null;

      /* Change prev/next areas depending on what's showing. */

      let onPrevAreaChange;
      let onNextAreaChange;

      onPrevAreaChange = this[pip.onContSlideDrawerChange];

      if (piasu.showProgramData(modVarObjList)) {
        onNextAreaChange = this[pip.onProgDataSlideDrawerChange];
      } else {
        const nextPageID = piasu.getPageID(modVarObjList, pic.configFormOrder, pic.next);
        onNextAreaChange = () => onPageChange(nextPageID);
      }

      if (showVisitSchedSlideDrawerBool) {
        visitSchedSlideDrawer = (
          <PIVisitSchedSlideDrawer
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.onDrawerChange]: this[pip.onVisitSchedSlideDrawerChange],

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.onNextAreaChange]: onNextAreaChange,
              [pip.onPrevAreaChange]: onPrevAreaChange,

              [pias.tableKeyObj]: tableKeyObj,
            }}
          />
        );
      }

      return visitSchedSlideDrawer;
    };

    return gbu.tryRenderFn(fn, "renderVisitSchedSlideDrawer");
  };

  renderProgDataSlideDrawer = () => {
    const fn = () => {
      const props = this.props;
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onDialogChange = props[pias.onDialogChange];
      //const onDrawerChange = props[pias.onDrawerChange];
      const helpAreaStr = props[pias.helpAreaStr];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];
      const onPageChange = props[pias.onPageChange];

      const state = this.state;
      const showProgDataSlideDrawerBool = state[showProgDataSlideDrawerBoolC];

      /* Change prev/next areas depending on what's showing. */

      let onNextAreaChange = onPageChange;
      let onPrevAreaChange = this[pip.onVisitSchedSlideDrawerChange];

      // if (piasu.showContinuation(modVarObjList)) {
      //
      //     onPrevAreaChange = this[pip.onContSlideDrawerChange];
      //
      // }
      // else {
      //
      //     onPrevAreaChange = this[pip.onMethodSlideDrawerChange];
      //
      // }

      let progDataSlideDrawer = null;

      if (showProgDataSlideDrawerBool) {
        progDataSlideDrawer = (
          <PIProgDataSlideDrawer
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.onDialogChange]: onDialogChange,

              [pias.onDrawerChange]: this[pip.onProgDataSlideDrawerChange],

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              //[pias.onPageChange]                   : onPageChange,

              [pip.onNextAreaChange]: onNextAreaChange,
              [pip.onPrevAreaChange]: onPrevAreaChange,
            }}
          />
        );
      }

      return progDataSlideDrawer;
    };

    return gbu.tryRenderFn(fn, "renderProgDataSlideDrawer");
  };

  render() {
    try {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stConfiguration)}
        </p>
      );

      const priorPopsDiv = this.renderPriorPopsDiv();
      const methodsDiv = this.renderMethodsDiv();
      const continuationDiv = this.renderContinuationDiv();
      const visitSchedDiv = this.renderVisitSchedDiv();
      const progDataDiv = this.renderProgDataDiv();

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            // [PINavBtnDivProps.showBackBtn]    : (backPageID !== pic.noPageID),
            // [PINavBtnDivProps.showNextBtn]    : (nextPageID !== pic.noPageID),
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
            [PINavBtnDivProps.backBtnKey]: "configFormBackBtn",
            [PINavBtnDivProps.nextBtnKey]: "configFormNextBtn",
            //[PINavBtnDivProps.enableBackBtn]  : backBtnEnabled,
            [PINavBtnDivProps.enableNextBtn]: piasu.canMovePastConfig(modVarObjList),
          }}
        />
      );

      const priorPopSlideDrawer = this.renderPriorPopSlideDrawer();
      const methodSlideDrawer = this.renderMethodSlideDrawer();
      const contSlideDrawer = this.renderContinuationSlideDrawer();
      const visitSchedSlideDrawer = this.renderVisitSchedSlideDrawer();
      const progDataSlideDrawer = this.renderProgDataSlideDrawer();

      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {priorPopsDiv}
          {methodsDiv}
          {continuationDiv}
          {visitSchedDiv}
          {progDataDiv}

          {navBtnDiv}

          {priorPopSlideDrawer}
          {methodSlideDrawer}
          {contSlideDrawer}
          {visitSchedSlideDrawer}
          {progDataSlideDrawer}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIConfigForm;
