import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import TComboBox from "../../common/TComboBox";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pic from "../NonComponents/PIConst";

/* Attributes specific to PICountryComboBox. */

export const PICountryComboBoxProps = {
  outerComboBoxStyle: "outerComboBoxStyle", // affects combobox only
  outerStyle: "outerStyle", // affects label + combobox
};

class PICountryComboBox extends Component {
  static propTypes = {
    [pias.countryObjList]: PropTypes.array,

    [PICountryComboBoxProps.outerComboBoxStyle]: PropTypes.object,
    [PICountryComboBoxProps.outerStyle]: PropTypes.object,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),

    onChange: PropTypes.func,
  };

  static defaultProps = {
    [pias.countryObjList]: [],

    [PICountryComboBoxProps.outerComboBoxStyle]: {},
    [PICountryComboBoxProps.outerStyle]: {},

    [pias.modVarObjList]: [],

    onChange: () => {},
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const countryObjList = props[pias.countryObjList];
    const modVarObjList = props[pias.modVarObjList];

    const countryMstIDs = piasu.getCountryMstIDs(countryObjList);
    let countryIdx;
    if (piasu.getCountrySelected(modVarObjList)) {
      const countryCodeInt = piasu.getModVarValue(modVarObjList, pisc.countryISOMVTag);
      countryIdx = piasu.getCountryIdx(countryObjList, countryCodeInt);
    } else {
      countryIdx = piasu.getCountryIdx(countryObjList, pic.noCountrySelected);
    }

    const countryNames = piasu.getCountryNames(countryObjList);

    return (
      <TComboBox
        // onChange={this.onCountryComboBoxChange}
        onChange={props.onChange}
        info={countryMstIDs}
        itemIndex={countryIdx}
        items={countryNames}
        outerStyle={{
          display: "block",
          fontFamily: Theme.fontFamily,
          marginBottom: 20,
          marginTop: 20,
        }}
        style={{
          fontFamily: Theme.fontFamily,
        }}
        enabledItems={[false, ...countryNames.slice(1).map((_) => true)]}
      />
    );
  }
}

export default PICountryComboBox;
