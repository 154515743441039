import * as PropTypes from "prop-types";
import * as React from "react";

import Divider from "@material-ui/core/Divider";
import ArrowIcon from "@material-ui/icons/KeyboardArrowRight";

import * as Theme from "../../../app/Theme";
import TSideDrawer from "../../common/TSideDrawer";
import * as pias from "../NonComponents/PIAppState";
import * as pic from "../NonComponents/PIConst";
import * as piu from "../NonComponents/PIUtil";

import { getProjDefaults, calculate } from "../../../api/server_calls";
import * as SC from "../../../data/strings/PIStringConst";
import { RS } from "../../../data/strings/global";
import * as gbu from "../../GB/GBUtil";
import TButton from "../../common/TButton";
import TDialog from "../../common/TDialog";
import TWifiAnimation from "../../common/TWifiAnimation";
import * as piasu from "../NonComponents/PIAppStateUtil";
import { getModVarValue } from "../NonComponents/PIAppStateUtil";
import { onCalculate } from "../NonComponents/PICalc";
import * as php from "../NonComponents/PIHelp";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as piv from "../NonComponents/PIValidate";

const indent1 = 20;
const indent2 = 40;

const menuItemStyle = {
  cursor: "pointer",
  marginBottom: 10,
  marginLeft: indent2,
  marginTop: 10,
};

const uploadingSessionBoolC = "uploadingSessionBool";

class PIAppSideBar extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    [pip.appBarHeight]: PropTypes.number,

    [pias.appState]: PropTypes.object,

    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.onModVarsChange]: PropTypes.func,
    [pias.onOrigModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pias.sideBarOpen]: PropTypes.bool,
    [pias.onSideBarArrowClick]: PropTypes.func,

    [pip.appSideBarWidthOpen]: PropTypes.number,
    [pip.appSideBarWidthClosed]: PropTypes.number,

    [pias.onValidationItemChange]: PropTypes.func,

    notes: PropTypes.object,
    onNotesChange: PropTypes.func,
  };

  static defaultProps = {
    [pip.appBarHeight]: 64,

    [pias.appState]: {},

    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.onOrigModVarsChange]: () => console.log(pias.onOrigModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pias.sideBarOpen]: false,
    [pias.onSideBarArrowClick]: () => console.log(pias.onSideBarArrowClick),

    [pip.appSideBarWidthOpen]: 0,
    [pip.appSideBarWidthClosed]: 0,

    [pias.onValidationItemChange]: () => console.log(pias.onValidationItemChange),
  };

  state = {
    [uploadingSessionBoolC]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility Functions
  //
  //==================================================================================================================

  getArrow = (pageIDStr) => {
    const props = this.props;
    const appState = props[pias.appState];

    const pageID = appState[pias.pageID];

    return pageID === pageIDStr ? (
      <ArrowIcon
        style={{
          display: "inline-block",
          marginLeft: 5, //indent2,
        }}
      />
    ) : undefined;
  };

  getListItemStyle = (listItemEnabledBool, pageIDStr) => {
    const props = this.props;
    const appState = props[pias.appState];

    const pageID = appState[pias.pageID];

    let style;

    if (listItemEnabledBool) {
      if (pageID === pageIDStr) {
        style = Theme.styles.listItemSelectedLab;
      } else {
        style = Theme.styles.listItemLab;
      }
    } else {
      style = Theme.styles.listItemDisabledLab;
    }

    return style;
  };

  getStyledParagraph = (idStr, option1Str, option2Str, selectedOptionByte, marginLeftNum, onClickEvent) => {
    if (typeof marginLeftNum === "undefined") {
      marginLeftNum = 0;
    }

    let option1Item;
    let option2Item;

    if (selectedOptionByte === 1) {
      option1Item = (
        <strong
          style={{
            color: Theme.PI_SecondaryColor,
          }}
        >
          {option1Str}
        </strong>
      );

      option2Item = option2Str;
    } else {
      option1Item = option1Str;

      option2Item = (
        <strong
          style={{
            color: Theme.PI_SecondaryColor,
          }}
        >
          {option2Str}
        </strong>
      );
    }

    return (
      <p id={idStr} onClick={onClickEvent} style={menuItemStyle}>
        {option1Item} | {option2Item}
      </p>
    );
  };

  getMenuItemName = (pageIDStr) => {
    const props = this.props;
    const appState = props[pias.appState];
    const modVarObjList = appState[pias.modVarObjList];

    let costsMenuItemName;
    let disagTargsMenuItemName;
    if (
      piv.getModVarsRetrieved(modVarObjList) &&
      //piasu.getModVarExists(modVarObjList, pisc.countryISOMVTag)) {
      piasu.getModVarValue(modVarObjList, pisc.allInputMVsLoadedMVTag)
    ) {
      const costingModeMstID = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const appModeMstIDStr = getModVarValue(modVarObjList, pisc.appModeMVTag);

      if (costingModeMstID === pisc.costsLiteModeMstID) {
        costsMenuItemName = RS(SC.GB_stCostsLite);
      } else {
        costsMenuItemName = RS(SC.GB_stDetailedCosts);
      }

      if (appModeMstIDStr === pisc.aggregateToolMstID) {
        disagTargsMenuItemName = RS(SC.GB_stSubnationalTargets);
      } else {
        disagTargsMenuItemName = RS(SC.GB_stDisagTargets);
      }
    } else {
      /* Default to first radio group item, which is currently Detailed costs. */
      costsMenuItemName = RS(SC.GB_stCostsLite); //markDetailedCostsTemp RS(SC.GB_stDetailedCosts);
      disagTargsMenuItemName = RS(SC.GB_stDisagTargets);
    }

    let menuItemNameStr;

    switch (pageIDStr) {
      case pic.welcomeForm:
        menuItemNameStr = RS(SC.GB_stChooseMode);
        break;

      // case pic.gettingStartedForm :
      //     menuItemNameStr = RS(SC.GB_stGettingStarted);
      //     break;

      case pic.aggregateForm:
        menuItemNameStr = RS(SC.GB_stAggregate);
        break;

      case pic.gettingStartedForm:
        menuItemNameStr = RS(SC.GB_stGettingStarted);
        break;

      case pic.configForm:
        menuItemNameStr = RS(SC.GB_stConfiguration);
        break;

      case pic.targetsForm:
        menuItemNameStr = RS(SC.GB_stTargets);
        break;

      case pic.disagTargetsForm:
        menuItemNameStr = disagTargsMenuItemName;
        break;

      case pic.detailedCostsForm:
        menuItemNameStr = costsMenuItemName;
        break;

      case pic.costsLiteForm:
        menuItemNameStr = costsMenuItemName;
        break;

      case pic.impactForm:
        menuItemNameStr = RS(SC.GB_stImpact);
        break;

      case pic.AGYW_Form:
        menuItemNameStr = RS(SC.GB_stAdolGirlsYoungWomen);
        break;

      case pic.capacityForm:
        menuItemNameStr = RS(SC.GB_stCapacity);
        break;

      case pic.commoditiesForecastForm:
        menuItemNameStr = RS(SC.GB_stCommoditiesForecasting);
        break;

      case pic.resultsDashboardForm:
        menuItemNameStr = RS(SC.GB_stResultsDashboard);
        break;

      default:
        menuItemNameStr = "";
        break;
    }

    return menuItemNameStr;
  };

  getMenuItemOnClickEvent = (form) => {
    let menuItemOnClickEvent;

    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const appState = props[pias.appState];
    //const onPageChange = props[pias.onPageChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];

    const modVarObjArr = appState[pias.modVarObjList];

    const appModeMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.appModeMVTag);
    const modeChosen = appModeMstIDStr !== pisc.noToolSelectedMstID;

    let priorPopAreaCompleted = false;
    let contCurveAreaCompleted = false;
    let methodsAreaCompleted = false;
    let visitSchedAreaCompleted = false;

    //const countrySelected = piasu.getCountrySelected(modVarObjArr);
    //if (countrySelected) {
    const allInputMVsLoadedBool = piasu.getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);
    if (allInputMVsLoadedBool) {
      const areaObjArr = piasu.getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
      priorPopAreaCompleted = piasu.areaCompleted(areaObjArr, pisc.priorPopAreaMstID);
      contCurveAreaCompleted = piasu.areaCompleted(areaObjArr, pisc.contCurveAreaMstID);
      methodsAreaCompleted = piasu.areaCompleted(areaObjArr, pisc.methodsAreaMstID);
      visitSchedAreaCompleted = piasu.areaCompleted(areaObjArr, pisc.visitSchedAreaMstID);
    }

    let stdOnClickEvent;
    if (
      (form === pic.disagTargetsForm || form === pic.commoditiesForecastForm || form === pic.impactForm) &&
      appModeMstIDStr !== pisc.aggregateToolMstID
    ) {
      stdOnClickEvent = () => {
        onCalculatingChange(true, () => {
          onCalculate(
            modVarObjArr,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onCalculatingChange(false, () => {
                  this.onPageChange(form);
                });
              });
            },
            () => onCalculatingChange(false)
          );
        });
      };
    } else {
      stdOnClickEvent = () => this.onPageChange(form);
    }

    const emptyOnClickEvent = () => {};

    /* Assuming these are showing, they should always have the standard onClick event. */
    if (
      form === pic.welcomeForm ||
      form === pic.configForm ||
      form === pic.gettingStartedForm ||
      form === pic.aggregateForm
    ) {
      menuItemOnClickEvent = stdOnClickEvent;
    } else {
      if (
        modeChosen &&
        allInputMVsLoadedBool && //countrySelected
        (appModeMstIDStr === pisc.aggregateToolMstID ||
          (priorPopAreaCompleted && contCurveAreaCompleted && methodsAreaCompleted && visitSchedAreaCompleted))
      ) {
        menuItemOnClickEvent = stdOnClickEvent;
      } else {
        menuItemOnClickEvent = emptyOnClickEvent;
      }
    }

    return menuItemOnClickEvent;
  };

  getMenuItemDisabledStyle = (form) => {
    let menuItemDisabledStyle;

    /* props */

    const props = this.props;
    const appState = props[pias.appState];
    //const onPageChange = props[pias.onPageChange];

    const modVarObjList = appState[pias.modVarObjList];

    const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);
    const modeChosen = appModeMstIDStr !== pisc.noToolSelectedMstID;

    const stdDisabledStyle = {
      color: Theme.darkGrayColor,
      cursor: "arrow",
    };
    const emptyDisabledStyle = {};

    /* Assuming these are showing, they should never be disabled. */
    if (
      form === pic.welcomeForm ||
      form === pic.configForm ||
      form === pic.gettingStartedForm ||
      form === pic.aggregateForm
    ) {
      menuItemDisabledStyle = emptyDisabledStyle;
    } else {
      /* Other areas should be disabled depending on if items in the Configuration have
           been completed. */
      if (modeChosen && (appModeMstIDStr === pisc.aggregateToolMstID || piasu.canMovePastConfig(modVarObjList))) {
        menuItemDisabledStyle = emptyDisabledStyle;
      } else {
        menuItemDisabledStyle = stdDisabledStyle;
      }
    }

    return menuItemDisabledStyle;
  };

  // getMenuItemDisabledStyle = (form) => {
  //
  //     let menuItemDisabledStyle;
  //
  //     /* props */
  //
  //     const props = this.props;
  //     const appState = props[pias.appState];
  //     const onPageChange = props[pias.onPageChange];
  //
  //     const modVarObjList = appState[pias.modVarObjList];
  //
  //     const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);
  //     const modeChosen = appModeMstIDStr !== pisc.noToolSelectedMstID;
  //
  //     let priorPopAreaCompleted = false;
  //     let contCurveAreaCompleted = false;
  //     let methodsAreaCompleted = false;
  //
  //     //const countrySelected = piasu.getCountrySelected(modVarObjArr);
  //     //if (countrySelected) {
  //     const allInputMVsLoadedBool = piasu.getModVarValue(modVarObjList, pisc.allInputMVsLoadedMVTag);
  //     if (allInputMVsLoadedBool) {
  //
  //         const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.completedAreasMVTag);
  //         priorPopAreaCompleted = piasu.areaCompleted(areaObjArr, pisc.priorPopAreaMstID);
  //         contCurveAreaCompleted = piasu.areaCompleted(areaObjArr, pisc.contCurveAreaMstID);
  //         methodsAreaCompleted = piasu.areaCompleted(areaObjArr, pisc.methodsAreaMstID);
  //
  //     }
  //
  //     const stdDisabledStyle = {
  //         color  : Theme.darkGrayColor,
  //         cursor : "arrow",
  //     };
  //     const emptyDisabledStyle = {};
  //
  //     /* Assuming these are showing, they should never be disabled. */
  //     if ((form === pic.welcomeForm) || (form === pic.configForm) ||
  //         (form === pic.gettingStartedForm) || (form === pic.aggregateForm)) {
  //
  //         menuItemDisabledStyle = emptyDisabledStyle;
  //
  //     }
  //     /* Other areas should be disabled depending on if items in the Configuration have
  //        been completed. */
  //     else {
  //
  //         if (modeChosen && allInputMVsLoadedBool// countrySelected
  //
  //             &&
  //
  //             ((appModeMstIDStr === pisc.aggregateToolMstID) ||
  //                 (priorPopAreaCompleted && contCurveAreaCompleted && methodsAreaCompleted))) {
  //
  //             menuItemDisabledStyle = emptyDisabledStyle;
  //
  //         }
  //         else {
  //
  //             menuItemDisabledStyle = stdDisabledStyle;
  //
  //         }
  //
  //
  //     }
  //
  //     return menuItemDisabledStyle;
  //
  // };
  //
  // showForMode = (pageIDStr, toolModeInt) => {
  //
  //     let showBool = false;
  //
  //     if (toolModeInt === pic.PrEPitStdTool) {
  //
  //         showBool = true;
  //
  //     }
  //     else if ((toolModeInt === pic.noToolSelected) ||
  //         (toolModeInt === pic.PrEPitEasyStartTool) ||
  //         (toolModeInt === pic.aggregateTool)) {
  //
  //             if (pageIDStr === pic.welcomeForm) {
  //
  //                 showBool = true;
  //
  //             }
  //
  //     }
  //
  //     return showBool;
  //
  // };

  getMenuItemDiv = (pageIDStr, firstItemStyle, lastItemStyle) => {
    let menuItemDiv;

    const props = this.props;
    const appState = props[pias.appState];
    const sideBarOpen = props[pias.sideBarOpen];
    //const onPageChange = props[pias.onPageChange];

    const modVarObjArr = appState[pias.modVarObjList];

    const pageID = appState[pias.pageID];

    //const appModeMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.appModeMVTag);
    //const modeChosen = appModeMstIDStr !== pisc.noToolSelectedMstID;

    /* These depend on whether or not the user selected a mode yet. */
    const menuItemDisabledStyle = this.getMenuItemDisabledStyle(pageIDStr);
    const menuItemOnClickEvent = this.getMenuItemOnClickEvent(pageIDStr);

    const showMenuItemBool = piasu.showLeftSideBarAreaForActiveMode(modVarObjArr, pageID); //this.showForMode(pageIDStr, appModeMstIDStr);

    let arrow = null;
    let menuItem = null;

    if (sideBarOpen && showMenuItemBool) {
      let activePageStyle = {};

      if (pageID === pageIDStr) {
        activePageStyle = {
          marginLeft: 10,
        };
      }

      arrow = this.getArrow(pageIDStr);
      menuItem = (
        <p
          disabled={false}
          display={"inline-block"}
          key={pageIDStr + "MenuItem"}
          onClick={menuItemOnClickEvent}
          style={{
            color: Theme.PI_SecondaryColor,
            ...menuItemStyle,
            ...Theme.textFontStyle,
            ...menuItemDisabledStyle,
            ...activePageStyle,
          }}
        >
          {this.getMenuItemName(pageIDStr)}
        </p>
      );
    }

    menuItemDiv = (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          ...firstItemStyle,
          ...lastItemStyle,
        }}
      >
        {arrow}
        {menuItem}
      </div>
    );

    return menuItemDiv;
  };

  //==================================================================================================================
  //
  //                                                Events
  //
  //==================================================================================================================

  onPageChange = (form, successFn) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const appState = props[pias.appState];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onPageChange = props[pias.onPageChange];

    const modVarObjArray = appState[pias.modVarObjList];
    const validationItem = appState[pias.validationItem];

    piv.getPotUsersValid(modVarObjArray, validationItem, onDialogChange, true, (response) => {
      if (response || form === pic.welcomeForm || form === pic.configForm) {
        let helpAreaStr = "";

        switch (form) {
          /* Forms without tabs */

          case pic.gettingStartedForm:
            helpAreaStr = php.general_HP;
            break;

          case pic.welcomeForm:
            helpAreaStr = php.welcome_HP;
            break;

          case pic.aggregateForm:
            helpAreaStr = php.aggregateFM_HP;
            break;

          case pic.configForm:
            helpAreaStr = php.configFM_HP;
            break;

          case pic.capacityForm:
            helpAreaStr = php.capacityFM_HP;
            break;

          case pic.commoditiesForecastForm:
            helpAreaStr = php.commForecFM_HP;
            break;

          /* Forms with tabs */

          case pic.targetsForm:
            helpAreaStr = php.targ_FirstTB_HP;
            break;

          case pic.disagTargetsForm:
            helpAreaStr = php.disagTarg_FirstTB_HP;
            break;

          case pic.detailedCostsForm:
            helpAreaStr = php.detCosts_FirstTB_HP;
            break;

          case pic.costsLiteForm:
            helpAreaStr = php.costsLite_FirstTB_HP;
            break;

          case pic.impactForm:
            helpAreaStr = php.impact_FirstTB_HP;
            break;

          case pic.AGYW_Form:
            helpAreaStr = php.AGYW_FirstTB_HP;
            break;

          case pic.resultsDashboardForm:
            helpAreaStr = php.resultDashboard_FirstTB_HP;
            break;

          default:
            break;
        }

        onHelpAreaChange(helpAreaStr, () => {
          onPageChange(form, successFn);
        });
      }
    });
  };

  onDownloadSessionBtnClick = () => {
    try {
      const props = this.props;
      const appState = props[pias.appState];
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjArrClone = structuredClone(appState[pias.modVarObjList]);

      piasu.setModVarValue(modVarObjArrClone, pisc.sessionFileDownloadDateMVTag, gbu.formatDate(new Date()));

      onModVarsChange(modVarObjArrClone, false, () => {
        let JSONText = JSON.stringify([
          {
            tag: "PI_Notes",
            module: "PI",
            value: props.notes,
          },
          ...modVarObjArrClone,
        ]);
        /* # will break encodeURI */
        JSONText = JSONText.replace(/#/gi, "REPLACENUMBER");
        let content = "data:text/json;charset=utf-8," + JSONText;
        let encodedUri = encodeURI(content);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "PrEPitSession.json");
        document.body.appendChild(link); // Required for FF
        link.click();
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onUploadSessionBtnClick = () => {
    try {
      this.refs.importUploader.click();
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onLoadFileEnd = (fileReaderEvent) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];

    if (fileReaderEvent.target.error === null) {
      const appState = props[pias.appState];
      const onModVarsChange = props[pias.onModVarsChange];

      const origModVarObjArr = appState[pias.origModVarObjArr];

      //let output = [];
      let fileContents = fileReaderEvent.target.result;
      fileContents = fileContents.replace(/REPLACENUMBER/gi, "#");
      //const modVarString = "{\"modvars\":" + fileContents + "}";
      let modVarObjArr;
      try {
        const modvars = JSON.parse(fileContents);

        modVarObjArr = modvars.filter((item) => item.tag !== "PI_Notes");

        const notes = modvars.find((item) => item.tag === "PI_Notes");

        props.onNotesChange(notes?.value ?? {});
      } catch (e) {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = e.toString();
        dialogObj[pias.headerStr] = RS(SC.GB_stNote);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);
      }

      const appModeMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.appModeMVTag);

      if (appModeMstIDStr === pisc.aggregateToolMstID) {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = "Aggregate session upload not supported.";
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);
        return;
      }

      const setUserModVars = (successFn) => {
        /* Before setting the users' ModVars into app state, run an updater to
         * address backwards compatability issues. Do not run it for Aggregate mode.
         * The server will update the ModVars when they are processed for Aggregate
         * mode. Trying it here will break things.
         */

        if (appModeMstIDStr !== pisc.aggregateToolMstID) {
          calculate(modVarObjArr, (response) => {
            if (piu.serverCallSuccessful(response)) {
              onModVarsChange(
                response[pisc.modVars],
                false,
                () => {
                  this.onPageChange(pic.configForm, () => {
                    gbu.safeCallFn(
                      successFn(() => {
                        // Notify user of upgrade issues
                        const modVarObjArr = response[pisc.modVars];
                        const warnSiteCosts = piasu.getModVarValue(
                          modVarObjArr,
                          "PI_ProgramWideAnnualAboveSiteCostsReEnter"
                        );
                        const warnProgramData = piasu.getModVarValue(modVarObjArr, "PI_ProgramDataReUpload");

                        let dialogObj = pias.getDefaultDialogObj();
                        dialogObj[pias.contentStr] = (
                          <div>
                            <p>{RS(SC.GB_stSessionUploaded)}</p>
                            {warnSiteCosts && <p>{RS(SC.GB_stReEnterAnnualAboveSiteCosts)}</p>}
                            {warnProgramData && <p>{RS(SC.GB_stInvalidProgramDataReUpload)}</p>}
                          </div>
                        );
                        dialogObj[pias.headerStr] = RS(SC.GB_stNote);
                        dialogObj[pias.maxWidthStr] = "sm";
                        dialogObj[pias.showBool] = true;
                        dialogObj[pias.styleObj] = { width: 500 };

                        onDialogChange(dialogObj);
                      })
                    );
                  });
                },
                () => {
                  this.setState({
                    [uploadingSessionBoolC]: false,
                  });
                }
              );
            } else {
              piu.notifyUserOfError(response, onDialogChange);
              gbu.safeCallFn(
                successFn(() => {
                  let dialogObj = pias.getDefaultDialogObj();
                  dialogObj[pias.contentStr] = RS(SC.GB_stErrorValidatingSession);
                  dialogObj[pias.headerStr] = RS(SC.GB_stError);
                  dialogObj[pias.maxWidthStr] = "sm";
                  dialogObj[pias.showBool] = true;
                  dialogObj[pias.styleObj] = { width: 500 };

                  onDialogChange(dialogObj);

                  //alert(RS(SC.GB_stErrorValidatingSession))
                })
              );
            }
          });
        } else {
          onModVarsChange(
            modVarObjArr,
            false,
            () => {
              this.onPageChange(pic.aggregateForm, () => {
                gbu.safeCallFn(successFn);
              });
            },
            () => {
              this.setState({
                [uploadingSessionBoolC]: false,
              });
            }
          );
        }
      };

      const uploadSessionFile = (successFn) => {
        /* If the original ModVar object array has not yet been filled (because the user
                   is trying to upload a session immediately and hasn't gone to Configuration yet),
                   then we need to populate it first. */
        if (origModVarObjArr.length === 0) {
          const countryCodeInt = piasu.getModVarValue(modVarObjArr, pisc.countryISOMVTag);
          const isPSE = piasu.isPSEMode(modVarObjArr);

          getProjDefaults(
            {
              [pic.countryCode]: countryCodeInt,
              pse: isPSE,
            },
            (response) => {
              //onCalculatingChange(true, () => {

              /* Run calcs so the structures are in the original ModVars for when we are adding/deleting
                               user-entered items. */
              onCalculate(
                response[pisc.modVars],
                "",
                onDialogChange,
                (response2) => {
                  let modVarsObjArr = response2;
                  let origModVarObjArrFilled = structuredClone(modVarsObjArr);

                  piasu.setUserDefaults(modVarsObjArr, origModVarObjArrFilled, false, () => {
                    piasu.setModVarValue(modVarsObjArr, pisc.allInputMVsLoadedMVTag, true);

                    onModVarsChange(
                      modVarsObjArr,
                      true,
                      () => {
                        setUserModVars(successFn);
                      },
                      () => {
                        this.setState({
                          [uploadingSessionBoolC]: false,
                        });
                      }
                    );
                  });
                },
                () => {
                  this.setState({
                    [uploadingSessionBoolC]: false,
                  });
                }
              );

              //});
            },
            () => {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = RS(SC.GB_steProjDefError);
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);

              //alert(RS(SC.GB_steProjDefError));
            }
          );
        } else {
          setUserModVars(successFn);
        }
      };

      //const state = this.state;
      //const uploadingSessionBool = state[uploadingSessionBoolC];

      this.setState(
        {
          [uploadingSessionBoolC]: true,
        },
        () => {
          uploadSessionFile((alertMsgFn) => {
            this.setState(
              {
                [uploadingSessionBoolC]: false,
              },
              () => gbu.safeCallFn(alertMsgFn)
            );
          });
        }
      );
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_steFileReadError);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert(RS(SC.GB_steFileReadError));
    }
  };

  onReadFile = (event) => {
    try {
      let file = event.target.files[0];

      if (file) {
        let fileReader = new FileReader();
        fileReader.onloadend = (fileReaderEvent) => this.onLoadFileEnd(fileReaderEvent);
        fileReader.readAsText(file);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onResetEventTargetValue = (event) => {
    event.target.value = null;
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderUploadingSessionDialog = () => {
    const fn = () => {
      let dialog = null;

      const state = this.state;
      const uploadingSessionBool = state[uploadingSessionBoolC];

      if (uploadingSessionBool) {
        const captionDiv = (
          <div
            style={{
              color: Theme.PI_PrimaryColor,
              fontSize: 20,
            }}
          >
            {"Communicating with server..."}
          </div>
        );

        const wifiAnimationIcon = (
          <TWifiAnimation
            style={{
              marginRight: 20,
              float: "left",
            }}
          />
        );

        const contentDiv = (
          <div>
            <div
              style={{
                fontSize: 16,
                color: Theme.gray,
              }}
            >
              {"Uploading session..."}
            </div>

            <div
              style={{
                fontSize: 12,
              }}
            >
              <br />
              {"Working on: Uploading session"}
            </div>
          </div>
        );

        dialog = (
          <TDialog
            caption={captionDiv}
            header={""} // needed for dialog to show icon and caption
            icon={wifiAnimationIcon}
            content={contentDiv}
            modal={true}
            visible={true}
            actions={[]}
            style={{
              width: 575,
            }}
          />
        );
      }

      return dialog;
    };

    return gbu.tryRenderFn(fn, "renderUploadingSessionDialog");
  };

  render() {
    /* Props */
    const props = this.props;
    //const appBarHeight = props[pip.appBarHeight];
    const appState = props[pias.appState];
    const sideBarOpen = props[pias.sideBarOpen];
    const sideBarWidthClosed = props[pip.appSideBarWidthClosed];
    const sideBarWidthOpen = props[pip.appSideBarWidthOpen];
    //const onSideBarArrowClick = props[pias.onSideBarArrowClick];

    //const pageID = appState[pias.pageID];

    const modVarObjList = appState[pias.modVarObjList];

    const allInputMVsLoadedBool = piasu.getModVarValue(modVarObjList, pisc.allInputMVsLoadedMVTag);

    const countrySelected = piasu.getCountrySelected(modVarObjList);

    //const enabled = true;

    const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);
    const modeChosen = appModeMstIDStr !== pisc.noToolSelectedMstID;

    let modeLab = null;
    let modeDivider = null;

    if (sideBarOpen) {
      if (modeChosen) {
        modeLab = (
          <p
            style={{
              display: "inline-block",
              fontSize: 18,
              marginBottom: 10,
              marginLeft: indent1,
              marginTop: 0,
            }}
          >
            {piu.getAppModeName(appModeMstIDStr)}
          </p>
        );

        modeDivider = <Divider />;
      }
    }

    const welcomeDiv = this.getMenuItemDiv(pic.welcomeForm, { marginTop: 20 });
    // const gettingStartedDiv = this.getMenuItemDiv(pic.gettingStartedForm);

    let easyStartDiv = null;
    if (piasu.showEasyStart(modVarObjList, true)) {
      easyStartDiv = this.getMenuItemDiv(pic.gettingStartedForm);
    }

    let aggregateDiv = null;
    if (piasu.showAggregate(modVarObjList, true)) {
      aggregateDiv = this.getMenuItemDiv(pic.aggregateForm);
    }

    let configDiv = null;
    if (piasu.showConfig(modVarObjList, true)) {
      configDiv = this.getMenuItemDiv(pic.configForm);
    }

    let costsDiv = null;
    //markDetailedCostsTemp
    // if (piasu.showDetailedCosting(modVarObjList, true)) {
    //
    //     costsDiv = this.getMenuItemDiv(pic.detailedCostsForm);
    //
    // }
    // else
    if (piasu.showCostsLite(modVarObjList, true)) {
      costsDiv = this.getMenuItemDiv(pic.costsLiteForm);
    }

    let impactDiv = null;
    if (piasu.showImpact(modVarObjList, true)) {
      impactDiv = this.getMenuItemDiv(pic.impactForm);
    }

    let targetsDiv = null;
    if (piasu.showTargets(modVarObjList, true)) {
      targetsDiv = this.getMenuItemDiv(pic.targetsForm);
    }

    let resultsDashboardDiv = null;
    if (piasu.showDashboard(modVarObjList, true)) {
      resultsDashboardDiv = this.getMenuItemDiv(pic.resultsDashboardForm);
    }

    let disagTargetsDiv = null;
    if (piasu.showDisagTargets(modVarObjList, true, true)) {
      disagTargetsDiv = this.getMenuItemDiv(pic.disagTargetsForm);
    }

    let AGYW_Div = null;
    if (piasu.showAGYWTool(modVarObjList, true)) {
      AGYW_Div = this.getMenuItemDiv(pic.AGYW_Form);
    }

    let commodForecastingDiv = null;
    if (piasu.showCommoditiesForecasting(modVarObjList, true, true)) {
      commodForecastingDiv = this.getMenuItemDiv(pic.commoditiesForecastForm);
    }

    const separatorDiv = (
      <div
        style={{
          marginBottom: 20,
        }}
      />
    );

    const buttonDivider = <Divider />;

    const downloadSessionBtn = (
      <TButton
        caption={RS(SC.GB_stDownloadSession)}
        disabled={!allInputMVsLoadedBool || !countrySelected}
        key={"downloadSessionBtn"}
        onClick={this.onDownloadSessionBtnClick}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          display: "block",
          marginBottom: 20,
          marginLeft: Theme.leftIndent * 2,
          marginTop: 30,
          width: 200,
        }}
      />
    );

    const inputDialog = (
      <input
        type={"file"}
        accept={".json"}
        ref={"importUploader"}
        style={{
          display: "none",
        }}
        onChange={this.onReadFile}
        onClick={(event) => this.onResetEventTargetValue(event)}
      />
    );

    const uploadSessionBtn = (
      <TButton
        caption={RS(SC.GB_stUploadSession)}
        key={"uploadSessionBtn"}
        onClick={this.onUploadSessionBtnClick}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          display: "block",
          marginBottom: 20,
          marginLeft: Theme.leftIndent * 2,
          width: 200,
        }}
      />
    );

    /* Comment out if not testing. */
    // const testingBtn =
    //     <TButton
    //         caption  = {"Testing"}
    //         key      = {"testingBtn"}
    //         onClick  = {() => this.onPageChange(pic.testForm)}
    //         style    = {{
    //             backgroundColor : "#F708B6",
    //             display         : "block",
    //             marginBottom    : 20,
    //             marginLeft      : Theme.leftIndent * 2,
    //             width           : 200,
    //         }}
    //     />;

    const uploadingSessionDialog = this.renderUploadingSessionDialog();

    return (
      <TSideDrawer
        direction="left"
        expanded={sideBarOpen}
        header={false}
        style={{
          // backgroundColor : Theme.whiteColor,// markThomas
          backgroundColor: Theme.lightGrayColor,
          // mr2
          // mr3 top             : appBarHeight,
          // height          : "100%",//"auto !important",// markThomas
          // bottom          : "auto",        // markThomas
        }}
        widthClosed={sideBarWidthClosed}
        widthOpen={sideBarWidthOpen}
      >
        <div
          style={{
            marginTop: 20,
          }}
        >
          {modeLab}
          {modeDivider}
        </div>

        {welcomeDiv}
        {/*{gettingStartedDiv}*/}
        {easyStartDiv}
        {aggregateDiv}
        {configDiv}
        {costsDiv}
        {AGYW_Div}
        {impactDiv}
        {targetsDiv}
        {resultsDashboardDiv}
        {/*{capacityDiv}*/}
        {disagTargetsDiv}
        {commodForecastingDiv}
        {separatorDiv}
        {buttonDivider}
        {downloadSessionBtn}
        {inputDialog}
        {uploadSessionBtn}
        {uploadingSessionDialog}
        {/*{testingBtn}*/}
      </TSideDrawer>
    );
  }
}

export default PIAppSideBar;
