import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const firstRow = 0;
const totalUnitsDispRow = 1;
const numRows = totalUnitsDispRow + 1;
const firstCol = 0;

class PIConstraintsTargTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,

    activeTable: PropTypes.number,
    onTableChanged: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.tableKey]: "",

    activeTable: 1,
    onTableChanged: () => console.log("onTableChanged"),
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },

    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],

    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = structuredClone(props[pias.modVarObjList]);

      const covConstrUnitsDispObjArr = piasu.getModVarValue(modVarObjListClone, pisc.covConstUnitsDispMVTag);
      const methodsObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);

      const newPackTableClone = window.structuredCloneReview(newPackTable);

      const numMethods = piasu.getTotalNumMethods(methodsObjArr);

      for (let m = 1; m <= numMethods; m++) {
        const methodMstID = piasu.methodMstID(methodsObjArr, m);

        const unitsDispFlt = gbtu.getValue(newPackTableClone, totalUnitsDispRow, m);
        piasu.setCovConstrUnitsDisp(methodMstID, covConstrUnitsDispObjArr, unitsDispFlt);
      }

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          this.props.onTableChanged(1, () => {
            onModVarsChange(modVarObjListClone, false);
          });
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const state = this.state;
      const rDec = state.rDec;

      const covConstrUnitsDispObjArr = piasu.getModVarValue(modVarObjList, pisc.covConstUnitsDispMVTag);

      const methodsObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      let packTable = gbtu.getNewPackTable();

      const numMethods = piasu.getTotalNumMethods(methodsObjArr);
      let numCols = numMethods + 2; // +1 for total column

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      for (let m = 1; m <= numMethods; m++) {
        gbtu.setValue(packTable, firstRow, m, piasu.methodName(methodsObjArr, m));
      }

      gbtu.setValue(packTable, firstRow, numCols - 1, RS(SC.GB_stTotal));

      /* Set row headings. */
      gbtu.setValue(packTable, totalUnitsDispRow, firstCol, RS(SC.GB_stTotalNumUnitsTargSetPeriod));

      let totalUnits = 0;

      for (let m = 1; m <= numMethods; m++) {
        const methodMstID = piasu.methodMstID(methodsObjArr, m);

        const unitsDispFlt = piasu.getCovConstrUnitsDisp(methodMstID, covConstrUnitsDispObjArr);
        gbtu.setValue(packTable, totalUnitsDispRow, m, unitsDispFlt);
        gbtu.setIsNullable(packTable, totalUnitsDispRow, m, 1);

        totalUnits = totalUnits + unitsDispFlt;
      }

      gbtu.setValue(packTable, totalUnitsDispRow, numCols - 1, totalUnits);
      gbtu.lockCell(packTable, totalUnitsDispRow, numCols - 1, true, true);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);
      gbtu.setWordWrappedCol(packTable, firstCol, true);
      gbtu.setRowHeights(packTable, 50);

      gbtu.restoreRDecsFromCopy(packTable, rDec, 0);

      let focusedCell = null;
      let onCellFocusedEvent = () => {};
      if (this.props.activeTable === 1) {
        focusedCell = state[pip.focusedCell];
        onCellFocusedEvent = (focusedCell) => pitu.onCellFocused(this, focusedCell);
      }

      const stdTable = (
        <SuperTableShim
          focusedCell={focusedCell}
          onCellFocused={onCellFocusedEvent}
          font={Theme.tableFont}
          gridKey={tableKey}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIConstraintsTargTable");
  };

  render() {
    return <>{this.renderTable()}</>;
  }
}

export default PIConstraintsTargTable;
