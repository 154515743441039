import * as PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Divider, IconButton } from "@material-ui/core";
import Info from "@material-ui/icons/Info";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TButton from "../../common/TButton";
import TDialog from "../../common/TDialog";
import TTabs2 from "../../common/TTabs2";

import { onCalculateAsync } from "../NonComponents/PICalc";
import { promisify } from "../../../utilities";

import * as php from "../NonComponents/PIHelp";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";

import PIImpactRefSlideDrawer from "../Drawers/Reference/PIImpactRefSlideDrawer";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PIResetImpactFactorsBtn from "../Other/PIResetImpactFactorsBtn";
import PIAssignImpPriorPopsToPriorPopsTable from "../Tables/PIAssignImpPriorPopsToPriorPopsTable";
import PIImpactEffectivenessTable from "../Tables/PIImpactEffectivenessTable";
import PIImpactTable from "../Tables/PIImpactTable";

import { uuidv4 } from "../../../utilities";

// @returns {object} - tab name to index lookup table
function impactTabIndexes(isPSEMode) {
  return {
    assignImpFactorsTab: isPSEMode ? -1 : 0,
    impactAdjustmentTab: isPSEMode ? 0 : 1,
  };
}

export const PIImpactForm = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);
  const onCalculatingChange = promisify(props.onCalculatingChange);
  const onHelpAreaChange = promisify(props.onHelpAreaChange);
  const onPageChange = promisify(props.onPageChange);

  const isPSEMode = piasu.isPSEMode(props.modVarObjList);
  const methods = piasu.getModVarValue(props.modVarObjList, pisc.methodsMVTag);

  // Tabs
  let TabHelpAreas = [php.impact_AssImpFactTB_HP, php.impact_ImpactTB_HP];
  let TabTitles = [RS(SC.GB_stAssignImpactFactors), RS(SC.GB_stImpactAdjustment)];

  const tabIndexes = impactTabIndexes(isPSEMode);

  const firstTab = isPSEMode ? tabIndexes.impactAdjustmentTab : tabIndexes.assignImpFactorsTab;
  const finalTab = tabIndexes.impactAdjustmentTab;

  if (isPSEMode) {
    TabHelpAreas = TabHelpAreas.slice(1);
    TabTitles = TabTitles.slice(1);
  }

  // State
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  const [showImpactRefSlideDrawer, setShowImpactRefSlideDrawer] = useState(false);
  const [showImpactTableDialog, setShowImpactTableDialog] = useState(false);
  const [showEffectivenessDialog, setShowEffectivenessDialog] = useState(false);
  const [showPriorPopDialog, setShowPriorPopDialog] = useState(false);
  const [impactTableKey, setImpactTableKey] = useState(uuidv4());

  useEffect(() => {
    updateHelpArea(getHelpAreaStr(selectedTabIdx));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper Functions

  /**
   * @param {number} tabIdx Tab index
   * @returns {string}
   */
  const getHelpAreaStr = (tabIdx) => {
    return TabHelpAreas[tabIdx];
  };

  /**
   * Updates the help area based on the selected tab index
   * @param {string} helpAreaStr Help area string
   */
  const updateHelpArea = async (helpAreaStr) => {
    console.log(`Update help area: ${helpAreaStr}`);
    await onHelpAreaChange(helpAreaStr);
  };

  /**
   *
   * @param {unknown} newModVars ModVars object
   * @param {string} [helpAreaStr] Help area string
   */
  const doCalculate = async (newModVars, helpAreaStr) => {
    try {
      await onCalculatingChange(true);
      if (helpAreaStr) await updateHelpArea(helpAreaStr);
      //Needed in case the user changes values on the Assign impact factors tab so the Impact tab table updates properly
      const response = await onCalculateAsync(newModVars, "", props.onDialogChange);
      await onModVarsChange(response, false);
    } finally {
      await onCalculatingChange(false);
    }
  };

  // Callbacks
  const onTabIdxChange = async (idx) => {
    await doCalculate(props.modVarObjList, getHelpAreaStr(idx));
    setSelectedTabIdx(idx);
  };

  const onImpactRefSlideDrawerClick = async () => {
    await doCalculate(
      props.modVarObjList,
      !showImpactRefSlideDrawer ? php.impact_DefImpFactRD_HP : getHelpAreaStr(selectedTabIdx)
    );
    setShowImpactRefSlideDrawer(!showImpactRefSlideDrawer);
  };

  const backPageID = piasu.getPageID(props.modVarObjList, pic.impactFormOrder, pic.back);
  const nextPageID = piasu.getPageID(props.modVarObjList, pic.impactFormOrder, pic.next);
  const onNavBtnClick = async (direction) => {
    switch (direction) {
      case pic.back:
        if (selectedTabIdx === firstTab) await onPageChange(backPageID);
        else await onTabIdxChange(selectedTabIdx - 1);
        break;

      case pic.next:
        if (selectedTabIdx === finalTab) await onPageChange(nextPageID);
        else await onTabIdxChange(selectedTabIdx + 1);
        break;

      default:
        throw new Error(`Invalid direction: ${direction}`);
    }
  };

  const renderImpactDialog = () => {
    if (!showImpactTableDialog) return null;

    const dialogHeader = (
      <p
        key={"dialogHeader"}
        style={{
          ...Theme.textFontStyle,
          color: Theme.PI_PrimaryColor,
          padding: 0,
          margin: 0,
        }}
      >
        {RS(SC.GB_stImpact)}
      </p>
    );

    const dialogText = (
      <p
        key={"text"}
        style={{
          ...Theme.textFontStyle,
          padding: 0,
          margin: 0,
        }}
      >
        {RS(SC.GB_stImpactPara3)}
      </p>
    );

    return (
      <TDialog
        actions={["mrClose"]}
        content={[dialogText]}
        header={[dialogHeader]}
        key={"impactDialog"}
        onClose={() => {
          setShowImpactTableDialog(false);
        }}
      />
    );
  };

  const renderEffectivenessDialog = () => {
    if (!showEffectivenessDialog) return null;

    const dialogHeader = (
      <p
        key={"dialogHeader"}
        style={{
          ...Theme.textFontStyle,
          color: Theme.PI_PrimaryColor,
          padding: 0,
          margin: 0,
        }}
      >
        {RS(SC.GB_stEffectiveness)}
      </p>
    );

    const effectivenessBlurbText = (
      <div
        key="effectiveness-blurb-text"
        style={{
          ...Theme.textFontStyle,
        }}
      >
        <p>{RS(SC.GB_stEffectivenessInfoBlurb)}</p>

        <p>{RS(SC.GB_stEffectivenessCitationsIntroduction)}</p>

        <p
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stEffectivenessCitationsTitle)}
        </p>

        <ul>
          <li>
            Koss CA, Havlir DV, Ayieko HJ, et al. (2020) Lower than expected HIV incidence among men and women at
            elevated HIV risk in a population-based PrEP study in rural Kenya and Uganda: Interim results from the
            SEARCH study. AIDS 2020 Abstract. Accessed September 27, 2021 at 
            <a href="http://programme.aids2020.org/Abstract/Abstract/875" target="_blank" rel="noreferrer nofollow">
              http://programme.aids2020.org/Abstract/Abstract/875
            </a>
            .
          </li>
          <li>
            Nel A, Malherbe M, Mans W, et al. (2019) Safety adherence and HIV-1 seroconversion in DREAM – An open-label
            dapivirine vaginal ring trial. 9th South African AIDS Conference. June 2019, Durban, South Africa.
          </li>
          <li>
            Baeten J, Baeten T, Palanee-Phillips N, et al. (2019) High adherence and sustained impact on HIV-1
            incidence: Final result of an open-label extension triap of the dapivirine vaginal ring. 10th IAS Conference
            on HIV Science. July, 2019, Mexico City, Mexico.
          </li>
        </ul>
      </div>
    );

    return (
      <TDialog
        actions={["mrClose"]}
        content={[effectivenessBlurbText]}
        header={[dialogHeader]}
        key={"effectivenessDialog"}
        onClose={() => {
          setShowEffectivenessDialog(false);
        }}
      />
    );
  };

  const renderPriorPopDialog = () => {
    if (!showPriorPopDialog) return null;

    const dialogHeader = (
      <p
        key={"dialogHeader"}
        style={{
          ...Theme.textFontStyle,
          color: Theme.PI_PrimaryColor,
          padding: 0,
          margin: 0,
        }}
      >
        {RS(SC.GB_stImpactFactorsColon).slice(0, -1)}
      </p>
    );

    const priorPopBlurbText = (
      <React.Fragment>
        <p
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stImpactPara1)}
        </p>
        <p
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stImpactInfo2)}
        </p>
      </React.Fragment>
    );

    return (
      <TDialog
        actions={["mrClose"]}
        content={[priorPopBlurbText]}
        header={[dialogHeader]}
        key={"effectivenessDialog"}
        onClose={() => {
          setShowPriorPopDialog(false);
        }}
      />
    );
  };

  const renderAssignFactorsTab = () => {
    const assignImpPriorPopsToPriorPopsTableKey = props.tableKeyObj[pias.assignImpPriorPopsToPriorPopsTableKey];

    return (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          marginTop: Theme.topIndent,
        }}
      >
        <PIAssignImpPriorPopsToPriorPopsTable
          {...{
            [pias.onCalculatingChange]: props.onCalculatingChange,

            [pias.modVarObjList]: props.modVarObjList,
            [pias.onModVarsChange]: props.onModVarsChange,

            [pip.tableKey]: assignImpPriorPopsToPriorPopsTableKey,
          }}
        />

        <IconButton
          onClick={() => setShowPriorPopDialog(true)}
          style={{
            color: Theme.PI_SecondaryColor,
            cursor: "pointer",
            display: "inline-block",
            marginLeft: Theme.leftIndent,
            marginTop: 0,
            padding: 0,
          }}
        >
          <Info
            style={{
              color: Theme.PI_SecondaryColor,
            }}
          />
        </IconButton>
      </div>
    );
  };

  const renderImpactTab = () => {
    return (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          marginTop: 30,
        }}
      >
        <PIImpactTable
          key={impactTableKey}
          modVarObjList={props.modVarObjList}
          onCalculatingChange={props.onCalculatingChange}
          onModVarsChange={props.onModVarsChange}
          onDialogChange={props.onDialogChange}
          showAdjFactor
        />

        <IconButton
          onClick={() => setShowImpactTableDialog(true)}
          style={{
            color: Theme.PI_SecondaryColor,
            cursor: "pointer",
            display: "inline-block",
            marginLeft: 10,
            marginTop: 0,
            paddingLeft: 0,
            paddingTop: 0,
          }}
        >
          <Info
            style={{
              color: Theme.PI_SecondaryColor,
            }}
          />
        </IconButton>
      </div>
    );
  };

  return (
    <div
      style={{
        marginLeft: Theme.contentMarginLeft,
        marginTop: Theme.contentMarginTop,
      }}
    >
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        {/* Header Row */}
        <div>
          <p
            style={{
              display: "inline-block",
              ...Theme.pageHeadingFontStyle,
              marginTop: 10,
            }}
          >
            {RS(SC.GB_stImpact)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <PIResetImpactFactorsBtn
            modVarObjList={props.modVarObjList}
            onModVarsChange={props.onModVarsChange}
            activeTabIdx={selectedTabIdx}
            tabIdxMap={tabIndexes}
            onCalculatingChange={props.onCalculatingChange}
          />

          {!isPSEMode && (
            <TButton
              caption={RS(SC.GB_stDefaultImpFactors)}
              containerStyle={{
                display: "inline-block",
                marginRight: 10,
                marginTop: 0, // same as saveButton
              }}
              key={"refBtn"}
              onClick={onImpactRefSlideDrawerClick}
              style={{
                backgroundColor: Theme.PI_PrimaryColor,
                padding: 0, // same as saveButton
              }}
            />
          )}
        </div>
      </div>

      <Divider
        style={{
          ...Theme.dividerStyle,
        }}
      />

      {/* Tabs */}
      <TTabs2
        onChange={onTabIdxChange}
        selectedTabIdx={selectedTabIdx}
        style={{
          marginTop: 10,
        }}
        tabBackgroundColor={"inherit"}
        tabBarOutline={"none"}
        tabContents={["", ""]}
        tabTitles={TabTitles}
      />

      {methods.length > 1 && (
        <PIMethodComboBox
          {...{
            [pias.modVarObjList]: props.modVarObjList,
            [pias.onModVarsChange]: (...args) => {
              setImpactTableKey(uuidv4());
              props.onModVarsChange?.(...args);
            },

            [PIMethodComboBoxProps.row]: false,
          }}
        />
      )}

      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          marginTop: 30,
        }}
      >
        <PIImpactEffectivenessTable
          {...{
            [pias.onCalculatingChange]: props.onCalculatingChange,

            [pias.modVarObjList]: props.modVarObjList,
            [pias.onModVarsChange]: props.onModVarsChange,
          }}
        />
        <IconButton
          onClick={() => setShowEffectivenessDialog(true)}
          style={{
            color: Theme.PI_SecondaryColor,
            cursor: "pointer",
            display: "inline-block",
            marginLeft: Theme.leftIndent,
            marginTop: 0,
            padding: 0,
          }}
        >
          <Info
            style={{
              color: Theme.PI_SecondaryColor,
            }}
          />
        </IconButton>
      </div>

      {selectedTabIdx === tabIndexes.assignImpFactorsTab && renderAssignFactorsTab()}
      {selectedTabIdx === tabIndexes.impactAdjustmentTab && renderImpactTab()}

      {/* Nav */}
      <PINavBtnDiv
        {...{
          [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
          [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
          [PINavBtnDivProps.onBackBtnClick]: () => onNavBtnClick(pic.back),
          [PINavBtnDivProps.onNextBtnClick]: () => onNavBtnClick(pic.next),
        }}
      />

      {/* Dialogs */}
      {showImpactRefSlideDrawer && (
        <PIImpactRefSlideDrawer
          {...{
            [pias.helpAreaStr]: props.helpAreaStr,
            [pias.onHelp]: props.onHelp,

            [pias.modVarObjList]: props.modVarObjList,
            [pias.onModVarsChange]: props.onModVarsChange,

            [pip.onToggleRefSlideDrawer]: onImpactRefSlideDrawerClick,
          }}
        />
      )}

      {renderImpactDialog()}
      {renderEffectivenessDialog()}
      {renderPriorPopDialog()}
    </div>
  );
};

PIImpactForm.propTypes = {
  onCalculatingChange: PropTypes.func,

  helpAreaStr: PropTypes.string,
  onHelpAreaChange: PropTypes.func,
  onHelp: PropTypes.func,

  modVarObjList: PropTypes.arrayOf(PropTypes.object),
  origModVarObjArr: PropTypes.arrayOf(PropTypes.object),
  onModVarsChange: PropTypes.func,

  onPageChange: PropTypes.func,

  tableKeyObj: PropTypes.object,
};

PIImpactForm.defaultProps = {
  onCalculatingChange: () => console.log(pias.onCalculatingChange),

  helpAreaStr: php.impactFM_HP,
  onHelpAreaChange: () => console.log(pias.onHelpAreaChange),
  onHelp: () => console.log(pias.onHelp),

  modVarObjList: [],
  origModVarObjArr: [],
  onModVarsChange: () => console.log(pias.onModVarsChange),

  onPageChange: () => console.log(pias.onPageChange),

  tableKeyObj: {},
};
