import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";
import { useState } from "react";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as gbtu from "../../GB/GBTableUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import {
  combinePriorPops,
  disaggregatePriorPops,
  genAndKeyPopsCombinedPops,
  combinedPopsByCategory,
} from "../NonComponents/PIPriorPopUtil";
import React from "react";

const PIMethodMixCoveragePSETable = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const modVarObjList = props.modVarObjList;

  /** @type {any[]} */
  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
  /** @type {any[]} */
  const allPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let priorPops = allPops;
  let methodMixCoverage = piasu.getModVarValue(modVarObjList, "PI_MethodMixCoverage");

  /** @type {any[]} */
  let actualCoverage = piasu.getModVarValue(modVarObjList, "PI_ActualCoverage");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  const categories = Object.keys(piu.pseIncidenceCategories);
  const genAndKeyPopsWithCategories = combinedPopsByCategory(genAndKeyPopsCombinedPops, allPops, categories);

  const combined = combinePriorPops(modVarObjList, genAndKeyPopsWithCategories, [
    "PI_MethodMixCoverage",
    "PI_ActualCoverage",
    "PI_Eligibility",
  ]);
  methodMixCoverage = combined["PI_MethodMixCoverage"];
  priorPops = combined["PI_PriorityPop"];
  actualCoverage = combined["PI_ActualCoverage"];
  eligibility = combined["PI_Eligibility"];

  const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
  const badNumberFontColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.red));

  const numPops = Object.keys(genAndKeyPopsCombinedPops).length;

  const numRows = numPops + 2;
  let numCols = categories.length + 1;
  if (props.showActualCoverage) numCols += methods.length + 1;

  const firstRow = 2;
  const firstCategory = 1;
  const firstActualCovMethod = firstCategory + categories.length;

  // State
  const [rDec, setRDecs] = useState([]);

  const isPSEMode = piasu.isPSEMode(modVarObjList);
  if (!isPSEMode) {
    return <div>PIMethodMixCoveragePSETable requires PSE mode</div>;
  }

  // Callbacks
  const onPackTableChange = async (newPackTable) => {
    setRDecs(structuredClone(newPackTable.RDec));

    // Coverage
    let newCoverage = {};

    let pp = 0;
    for (const popID in genAndKeyPopsCombinedPops) {
      for (let cat = 0; cat < categories.length; ++cat) {
        const catName = categories[cat];
        const catPopID = `${popID}_${catName.toUpperCase()}`;
        const pop = priorPops.find((p) => p.mstID === catPopID);
        if (pop == null) continue;

        const value = gbtu.getValue(newPackTable, pp + firstRow, cat + firstCategory);
        newCoverage[catPopID] = typeof value === "number" ? value / 100 : 0.0;
      }

      ++pp;
    }

    const newModVars = structuredClone(modVarObjList);

    const newData = {
      PI_PriorityPop: priorPops,
      PI_MethodMixCoverage: newCoverage,
    };

    const disagg = disaggregatePriorPops(modVarObjList, newData, genAndKeyPopsWithCategories);
    newCoverage = disagg.PI_MethodMixCoverage;

    piasu.setModVarValue(newModVars, "PI_MethodMixCoverage", newCoverage);

    await onModVarsChange(newModVars, false);
  };

  // Table
  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  // Headings
  gbtu.setFixedRows(packTable, 2);

  gbtu.mergeCells(packTable, 0, firstCategory, 1, categories.length);
  gbtu.setValue(packTable, 0, firstCategory, RS(SC.GB_stTargetCoverage));

  if (props.showActualCoverage) {
    gbtu.mergeCells(packTable, 0, firstActualCovMethod, 1, methods.length + 1);
    gbtu.setValue(packTable, 0, firstActualCovMethod, RS(SC.GB_stActualCovToBeAchieved) + " (%)");

    for (let m = 0; m < methods.length; ++m) {
      gbtu.setValue(packTable, 1, m + firstActualCovMethod, methods[m].name);
    }

    gbtu.setValue(packTable, 1, numCols - 1, RS(SC.GB_stTotal));
  }

  gbtu.setValue(packTable, 1, 0, RS(SC.GB_stPriorityPop));
  gbtu.setValue(packTable, 1, 1, RS(SC.GB_stCoverage));
  for (let c = 0; c < categories.length; ++c) {
    gbtu.setValue(packTable, 1, c + firstCategory, piu.pseIncidenceCategories[categories[c]]);
  }

  // Data
  let pp = 0;
  for (const popID in genAndKeyPopsCombinedPops) {
    gbtu.setValue(packTable, pp + firstRow, 0, genAndKeyPopsCombinedPops[popID].newPopName());
    for (let cat = 0; cat < categories.length; ++cat) {
      const catName = categories[cat];
      const catPopID = `${popID}_${catName.toUpperCase()}`;
      const pop = priorPops.find((p) => p.mstID === catPopID);
      const coverage = methodMixCoverage?.[catPopID] ?? 1.0;

      if (pop) {
        gbtu.setValue(packTable, pp + firstRow, cat + firstCategory, coverage * 100);
      } else {
        gbtu.setCellBGColor(packTable, pp + firstRow, cat + firstCategory, gainsboroBase10);
        gbtu.lockCell(packTable, pp + firstRow, cat + firstCategory, true, true);
      }
    }

    if (props.showActualCoverage) {
      // Actual Coverage
      let disableTotal = true;
      let actTotal = 0.0;

      for (let m = 0; m < methods.length; ++m) {
        const method = methods[m];

        if (piasu.getPriorPopMethodElig(eligibility, method.mstID, pp + 1) === pisc.yesMstID) {
          const actualValue = actualCoverage[m].coverage[pp] * 100;
          gbtu.setValue(packTable, pp + firstRow, m + firstActualCovMethod, actualValue);
          actTotal += actualValue;

          disableTotal = false;
        } else {
          gbtu.setCellBGColor(packTable, pp + firstRow, m + firstActualCovMethod, gainsboroBase10);
        }

        gbtu.lockCell(packTable, pp + firstRow, m + firstActualCovMethod, true, true);
      }

      // Total
      if (!disableTotal) {
        gbtu.setValue(packTable, pp + firstRow, numCols - 1, actTotal);
        gbtu.lockCell(packTable, pp + firstRow, numCols - 1, true, actTotal <= 100);
        if (actTotal > 100) gbtu.setFontColor(packTable, pp + firstRow, numCols - 1, badNumberFontColor);
      } else {
        // Grey out Total
        gbtu.lockCell(packTable, pp + firstRow, numCols - 1, true, true);
        gbtu.setCellBGColor(packTable, pp + firstRow, numCols - 1, gainsboroBase10);

        // Grey out Coverage
        gbtu.lockCell(packTable, pp + firstRow, 1, true, true);
        gbtu.setCellBGColor(packTable, pp + firstRow, 1, gainsboroBase10);
      }
    }

    ++pp;
  }

  // Settings
  gbtu.alignNumericCellsRight(packTable);
  // gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, 100);
  gbtu.setColWidths(packTable, Theme.dataColWidthSmall);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.setID(packTable, "optionsPackTable");
  gbtu.setKey(packTable, "optionsPackTable");

  gbtu.setRDecs(packTable, 1);
  if (rDec.length !== 0) {
    for (let r = 0; r < rDec.length; r++) {
      for (let c = 0; c < rDec[r].length; c++) {
        gbtu.setRDec(packTable, r, c, rDec[r][c]);
      }
    }
  }

  return (
    // @ts-ignore
    <SuperTableShim
      // focusedCell={state[pip.focusedCell]}
      // onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
      // selectedRegions={state[pip.selectedRegions]}
      // onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      gridKey={props.tableKey}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PIMethodMixCoveragePSETable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  tableKey: PropTypes.string,
  showActualCoverage: PropTypes.bool,
};

PIMethodMixCoveragePSETable.defaultProps = {
  onModVarsChange: () => {},
  tableKey: "",
  showActualCoverage: false,
};

export default PIMethodMixCoveragePSETable;
