import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";
import { useState } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { onCalculateAsync } from "../../PI/NonComponents/PICalc";
import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";

import { generateHeterogeneousTypes } from "../../../utilities";

import { combinePriorPops, disaggregatePriorPops, contCurveCombinedPops } from "../NonComponents/PIPriorPopUtil";

const priorPopCurrIDC = "priorPopCurrID";
const methodCurrIDC = "methodCurrID";

const PIAssignContCurvesToPriorPopsTable = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);
  const onCalculatingChange = promisify(props.onCalculatingChange);

  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  const contCurves = piasu.getModVarValue(modVarObjList, "PI_ContinuationCurves");
  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");

  let combinedTest;
  let priorPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  if (isPSEMode) {
    combinedTest = combinePriorPops(modVarObjList, contCurveCombinedPops, ["PI_Eligibility"]);
    priorPops = combinedTest["PI_PriorityPop"];
    eligibility = combinedTest["PI_Eligibility"];
  }

  // State
  const [focusedCell, setFocusedCell] = useState({
    row: 0,
    col: 1,
  });

  const [selectedRegions, setSelectedRegions] = useState({
    rowStart: 0,
    rowEnd: 0,
    columnStart: 1,
    columnEnd: 1,
  });

  // Callbacks
  const onContCurveComboBoxChange = async (value, text, info) => {
    const newData = {
      PI_PriorityPop: structuredClone(priorPops),
    };

    const contCurveCurrID = value + 1;
    const priorPopCurrID = info[priorPopCurrIDC];
    const methodCurrID = info[methodCurrIDC];

    const mstID = piasu.getContCurveMstID(contCurves, contCurveCurrID);
    piasu.setPriorPopContCurveMstID(newData.PI_PriorityPop, methodCurrID, priorPopCurrID, mstID);

    const newModVars = structuredClone(modVarObjList);

    if (isPSEMode) {
      const disagg = disaggregatePriorPops(modVarObjList, newData, contCurveCombinedPops);
      piasu.setModVarValue(newModVars, "PI_PriorityPop", disagg.PI_PriorityPop);
    } else {
      piasu.setModVarValue(newModVars, "PI_PriorityPop", newData.PI_PriorityPop);
    }

    await onCalculatingChange(true);
    try {
      await onModVarsChange(newModVars, false);
      const response = await onCalculateAsync(newModVars, "", props.onDialogChange);
      await onModVarsChange(response, false);
    } finally {
      await onCalculatingChange(false);
    }
  };

  // Table
  const numRows = priorPops.length + 1; // includes one header
  const numCols = methods.length + 1;

  const firstRow = 0;
  const priorPopCol = 0;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  /* Set column headings */
  gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));

  for (let m = 1; m <= methods.length; m++) {
    gbtu.setValue(packTable, firstRow, m, piasu.methodName(methods, m));
  }

  let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

  for (let pp = 1; pp <= priorPops.length; pp++) {
    /* Set row headings. */
    const priorPopName = piasu.getPriorPopName(priorPops, pp);
    gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

    for (let m = 1; m <= methods.length; m++) {
      const methodMstID = piasu.methodMstID(methods, m);
      const methodEligMstIDStr = piasu.getPriorPopMethodElig(eligibility, methodMstID, pp);

      if (methodEligMstIDStr !== pisc.yesCVOMstID) {
        gbtu.lockCell(packTable, pp, m, true, true);
        const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
        gbtu.setCellBGColor(packTable, pp, m, gainsboroBase10);

        continue;
      }

      gbtu.setHasComboBox(packTable, pp, m, true);

      comboBoxObj[pitu.style2DObjArray][pp][m] = {
        color: Theme.blackColor,
        fontFamily: Theme.fontFamily,
      };

      let priorPopCurrIDArray = [];
      priorPopCurrIDArray.length = piasu.getTotalNumContCurves(contCurves);
      priorPopCurrIDArray.fill({
        [priorPopCurrIDC]: pp,
        [methodCurrIDC]: m,
      });
      comboBoxObj[pitu.info3DIntArray][pp][m] = priorPopCurrIDArray;

      comboBoxObj[pitu.item3DStrArray][pp][m] = piasu.getContCurveNames(contCurves);

      const contCurveMstID = piasu.getPriorPopContCurveMstID(priorPops, m, pp);
      const contCurveCurrID = piasu.getContCurveCurrID(contCurves, contCurveMstID); // markProblem
      comboBoxObj[pitu.idx2DIntArray][pp][m] = contCurveCurrID - 1;

      comboBoxObj[pitu.onChange2DFuncArray][pp][m] = onContCurveComboBoxChange;
    }
  }

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
  gbtu.setRDecs(packTable, 0);
  gbtu.setColWidths(packTable, Theme.itemNameColWidth);
  gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
  gbtu.setWordWrappedCols(packTable, true);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, 0);
  gbtu.setID(packTable, "assignCurvesPackTable");
  gbtu.setKey(packTable, "assignCurvesPackTable");

  return (
    <GbStdTableWithComboBoxes
      focusedCell={focusedCell}
      onCellFocused={(focusedCell) => setFocusedCell(focusedCell)}
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      gridKey={props.tableKey}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateHeterogeneousTypes(packTable, "dd")}
      onPackTableChanged={() => {}}
      removedMenuNames={pitu.tableHideMenuItems}
      selectedRegions={selectedRegions}
      onSelectionChanged={(selectedRegions) => setSelectedRegions(selectedRegions)}
      style={{
        tableFont: Theme.tableFont,
        marginLeft: Theme.leftIndent,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
      comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
      comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
      comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
      onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
      width={0}
      limitToTableWidth={true}
    />
  );
};

PIAssignContCurvesToPriorPopsTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
  onDialogChange: PropTypes.func,

  tableKey: PropTypes.string,
};

PIAssignContCurvesToPriorPopsTable.defaultProps = {
  onModVarsChange: () => {},
  onCalculatingChange: () => {},
  onDialogChange: () => {},

  tableKey: "",
};

export default PIAssignContCurvesToPriorPopsTable;
