import * as React from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TLabel from "./TLabel";

class TRadioGroup extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    caption: PropTypes.string,
    captionStyle: PropTypes.object,
    handleChange: PropTypes.func,
    style: PropTypes.object,
    itemIndex: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.any),
    disabledItems: PropTypes.arrayOf(PropTypes.any),
    itemStyle: PropTypes.object,
    row: PropTypes.bool,
    usePaper: PropTypes.bool,
    radioButtonColor: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.shape({
      root: PropTypes.string,
      label: PropTypes.string
    })
  };

  static defaultProps = {
    caption: "", //'This is a label',
    captionStyle: {
      color: "#CCCCCC",
    },
    handleChange: () => console.log("onClick"),
    style: {},
    itemIndex: 0,
    items: ["Item1", "Item2", "Item3"],
    disabledItems: undefined,
    itemStyle: {},
    row: false,
    usePaper: false,
    radioButtonColor: "primary", // choices: "primary", "secondary", "default,
    className: "",
    classes: {
    }
  };

  state = {
    itemIndex: 0,
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    //if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
    return true;
    //}
    //else {
    //    return false;
    //}
  }

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  handleChange = (obj, value) => {
    this.props.handleChange(parseInt(value, 10));
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderItems = () => {
    let items = [];

    for (let i = 0; i <= this.props.items.length - 1; i++) {
      items.push(
        <FormControlLabel
          value={i.toString()}
          control={<Radio color={this.props.radioButtonColor} />}
          label={this.props.items[i]}
          key={i}
          style={{ ...this.props.itemStyle, display: "block" }}
          disabled={typeof this.props.disabledItems !== "undefined" ? this.props.disabledItems[i] : false}
        />
      );
    }

    return items;
  };

  render() {
    let styles = {
      paper: {},
    };

    this.props.usePaper
      ? (styles.paper = {
          background: "#FFFFFF",
          margin: "0 0 20px 0",
          padding: 15,
          boxShadow: "1px 1px 4px 0px #bbbbbb",
          ...this.props.style,
        })
      : (styles.paper = {
          padding: "15px 0",
          ...this.props.style,
        });

    return (
      <FormControl component="fieldset" style={styles.paper} classes={{root: this.props.classes.root}} className={this.props.className}>
        {this.props.caption && <TLabel style={{ ...this.props.captionStyle }} className={this.props.classes.label} caption={this.props.caption} />}

        <RadioGroup value={this.props.itemIndex.toString()} onChange={this.handleChange} row={this.props.row}>
          {this.renderItems()}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default TRadioGroup;
