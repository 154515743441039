import en from "../../lang/en.json";
import fr from "../../lang/fr.json";
import * as SC from "./PIStringConst";

// The order of these must match the order expected in the strings.
export const ValidLanguages = {
  en: SC.GB_stEnglish,
  fr: SC.GB_stFrancais,
};

export const getUserLanguage = () => sessionStorage.getItem("userLanguage") ?? localStorage.getItem("userLanguage");

export const getUserLanguageCode = () => {
  let code = "en";

  const language = getUserLanguage();

  if (!language) {
    return code;
  }

  const languageNumber = parseInt(language, 10);

  if (!languageNumber) {
    return code;
  }

  const languageIndex = languageNumber - 1;

  return Object.keys(ValidLanguages)[languageIndex] ?? code;
};

export const setUserLanguage = (languageCode) => {
  const languageIndex = Math.max(Object.keys(ValidLanguages).indexOf(languageCode), 0);

  const value = (languageIndex + 1).toString();

  localStorage.setItem("userLanguage", value);
  sessionStorage.setItem("userLanguage", value);
};

/**
 * Get translated text for string ID
 * @param {string} id Translation ID
 * @returns {string} Translated text string
 */
export function RS(id) {
  // Tolgee treats "" as untranslated, so it's set to "NULL" in Tolgee. Shouldn't really be translated at all.
  if (id === "GB_stNull") {
    return "";
  }

  const translations = {
    en,
    fr,
  };

  const languageCode = getUserLanguageCode();

  const translation = translations[languageCode][id] ?? translations["en"][id];

  if (translation == null) {
    console.error(` ${languageCode} translation for ${id} not found`);
  }

  return translation ?? translations["en"][id] ?? "";
}
