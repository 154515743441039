import { PropTypes } from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync, calcImpactFactorsAsync } from "../NonComponents/PICalc";

// in-place mutate modVarObjList to reset impact factors
function resetImpactFactors(defModVars, modVarObjList) {
  // Reset PI_ImpactPriorPopDefault
  const impPriorPop = piasu.getModVarValue(modVarObjList, "PI_ImpactPriorPopDefault");
  const defImpPriorPop = piasu.getModVarValue(defModVars, "PI_ImpactPriorPopDefault");

  const newImpPriorPop = impPriorPop.map((v) => {
    const def = defImpPriorPop.find((pp) => pp.mstID === v.mstID);
    return {
      ...(def ?? v),
    };
  });

  piasu.setModVarValue(modVarObjList, "PI_ImpactPriorPopDefault", newImpPriorPop);

  // Reset PI_PriorityPop
  const priorPop = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  const defPriorPop = piasu.getModVarValue(defModVars, pisc.priorPopsMVTag);

  const newPriorPop = priorPop.map((v) => {
    const def = defPriorPop.find((pp) => pp.mstID === v.mstID);
    if (def === undefined) return v;

    // impact factors will be re-calculated next so we don't need to do it here
    return {
      ...v,
      impPopMstID: def.impPopMstID,
    };
  });
  piasu.setModVarValue(modVarObjList, pisc.priorPopsMVTag, newPriorPop);
}

// in-place mutate modVarObjist to reset impact adjustments
function resetImpactAdjustement(_defModVars, modVarObjList) {
  // Reset PI_AdjustmentFactor
  const adjFactor = piasu.getModVarValue(modVarObjList, "PI_AdjustmentFactor");
  for (const m of adjFactor) {
    m.factors = m.factors.map((_) => 1);
  }
  piasu.setModVarValue(modVarObjList, "PI_AdjustmentFactor", adjFactor);
}

const PIResetImpactFactorsBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);
  const onCalculatingChange = promisify(props.onCalculatingChange);

  const onResetClick = async () => {
    try {
      await onCalculatingChange(true);

      let modVarObjList = structuredClone(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const pseMode = piasu.isPSEMode(props.modVarObjList);
      const countryCode = piasu.getModVarValue(modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode, pse: pseMode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      piasu.resetImpactEffectiveness(defModVars, modVarObjList);

      switch (props.activeTabIdx) {
        case props.tabIdxMap.assignImpFactorsTab:
          resetImpactFactors(defModVars, modVarObjList);
          break;
        case props.tabIdxMap.impactAdjustmentTab:
          resetImpactAdjustement(defModVars, modVarObjList);
          break;
        default:
          console.error(`Reset to defaults not supported for tab: ${props.activeTabIdx}`);
      }

      // Both tabs require this due to effectiveness reset
      await calcImpactFactorsAsync(modVarObjList);

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, true);
    } catch (err) {
      console.log(err);
    } finally {
      await onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"defaultCostsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetImpactFactorsBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
  activeTabIdx: PropTypes.number.isRequired,
  tabIdxMap: PropTypes.shape({
    assignImpFactorsTab: PropTypes.number,
    impactTab: PropTypes.number,
  }).isRequired,
};

PIResetImpactFactorsBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetImpactFactorsBtn;
