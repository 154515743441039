import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import { onCalculate } from "../NonComponents/PICalc";

import {
  PICustomRowTableProps,
  firstRow,
  hiddenColOffset,
  sharedPropTypes,
  sharedDefaultProps,
  initialState,
  getDeleteBtnCol,
  getNumCols,
  AddButton,
  DeleteButton,
} from "../Other/PICustomRowsUtil";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const itemNameColumn = hiddenColOffset;
const averageMonthsColumn = itemNameColumn + pic.numContCurvePeriods + 1;
const averageMonthInitialColumn = averageMonthsColumn + 1;
const averageMonthContinuationColumn = averageMonthInitialColumn + 1;
const lastColumn = averageMonthContinuationColumn;

export class PIContCurvePercOnPrEPTable extends Component {
  static propTypes = {
    ...sharedPropTypes,

    [pias.calcContCurvePercOnPrEPBool]: PropTypes.bool,
    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,
  };

  static defaultProps = {
    ...sharedDefaultProps,

    [pip.tableKey]: this.props?.[pip.tableKey] ?? "",

    [pias.calcContCurvePercOnPrEPBool]: false,
    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(pias.onCalcContCurvePercOnPrEPChange),
  };

  state = initialState;

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
      const onModVarsChange = props[pias.onModVarsChange];
      const modVarObjListClone = structuredClone(props[pias.modVarObjList]);

      const objList = piasu.getModVarValue(modVarObjListClone, pisc.continuationCurvesMVTag);
      const numObjects = piasu.getTotalNumContCurves(objList);

      const newPackTableClone = window.structuredCloneReview(newPackTable);

      let valuesIncreasing = false;
      let valuesChanged = false;

      let cc = 1;
      /* Values can't increase over time, so stop if we discover they are and give the user an error. */
      while (cc <= numObjects && !valuesIncreasing) {
        /* Users can change the name in the first visible column now. */
        const contCurveName = gbtu.getValue(newPackTable, cc, itemNameColumn);
        piasu.setContCurveName(objList, cc, contCurveName);

        let cp = 1;
        while (cp <= pic.numContCurvePeriods && !valuesIncreasing) {
          const value = gbtu.getValue(newPackTableClone, cc, cp + hiddenColOffset);
          const oldValue = piasu.getContCurvePercOnPrEP(objList, cc, cp);
          piasu.setContCurvePercOnPrEP(objList, cc, cp, value);

          /* Ignore dashes. */
          if (typeof value !== "string" && value !== "-") {
            if (cp > 1) {
              const prevValue = piasu.getContCurvePercOnPrEP(objList, cc, cp - 1);

              if (typeof prevValue !== "string" && prevValue !== "-" && value > prevValue) {
                valuesIncreasing = true;
                piasu.setContCurvePercOnPrEP(objList, cc, cp, oldValue);
              }
            } else {
              /* Make sure next value is <= previous value if we're on the first period. Have to
                           do this one separately, since there's no previous value to compare to. */
              const nextValue = piasu.getContCurvePercOnPrEP(objList, cc, cp + 1);

              if (typeof nextValue !== "string" && nextValue !== "-" && !(value >= nextValue)) {
                valuesIncreasing = true;
                piasu.setContCurvePercOnPrEP(objList, cc, cp, oldValue);
              }
            }

            /* Short-circuiting is important here. */
            if (!valuesIncreasing && !gbu.equal(value, oldValue, 0.01)) {
              valuesChanged = true;
            }
          }

          cp++;
        }

        cc++;
      }

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          if (valuesIncreasing) {
            onModVarsChange(modVarObjListClone, false, () => {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = RS(SC.GB_stContCurvesCantIncrease);
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);
            });
          } else if (!valuesChanged) {
            /* If values didn't change, just change the ModVar in case the names changed. */
            onModVarsChange(modVarObjListClone, false);
          } else {
            onCalculatingChange(true, () => {
              onModVarsChange(modVarObjListClone, false, () => {
                onCalcContCurvePercOnPrEPChange(true, () => {
                  /* Put this here because after the editor values change, the user needs to see
                                    the graph under it update. */
                  onCalculate(
                    modVarObjListClone,
                    "",
                    onDialogChange,
                    (response) => {
                      onModVarsChange(response, false, () => {
                        onCalcContCurvePercOnPrEPChange(false, () => {
                          onCalculatingChange(false);
                        });
                      });
                    },
                    () => onCalculatingChange(false)
                  );
                });
              });
            });
          }
        }
      );
    } catch (exception) {
      alert(exception.name + " (PIContCurvePercOnPrEPTable.onPackTableChange) : " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const allowEditsBool = props[PICustomRowTableProps.allowEditsBoolC];
      const tableKey = this.state[pip.tableKey];

      const state = this.state;
      const focusedCell = state[pip.focusedCell];
      const selectedRegions = state[pip.selectedRegions];
      const rDec = state[pip.rDec];

      const objList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

      const numObjects = piasu.getTotalNumContCurves(objList);

      const deleteBtnCol = getDeleteBtnCol(this.props, lastColumn);
      const numCols = getNumCols(this.props, lastColumn);

      const numRows = numObjects + 1;

      let packTable = gbtu.getNewPackTable();

      let frameworkComponents = allowEditsBool ? {} : null;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, itemNameColumn, RS(SC.GB_stContCurves));
      gbtu.setValue(packTable, firstRow, pic.after1MoCurrID + hiddenColOffset, RS(SC.GB_stAfterOneMonth) + " (%)");
      gbtu.setValue(packTable, firstRow, pic.after3MoCurrID + hiddenColOffset, RS(SC.GB_stAfterThreeMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, pic.after6MoCurrID + hiddenColOffset, RS(SC.GB_stAfterSixMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, pic.after12MoCurrID + hiddenColOffset, RS(SC.GB_stAfter12Months) + " (%)");
      gbtu.setValue(packTable, firstRow, averageMonthsColumn, RS(SC.GB_stAvgMoOnPrEPPerPersonInit));
      gbtu.setValue(packTable, firstRow, averageMonthInitialColumn, RS(SC.GB_stAvgMoProdDistrPerPersonInit));
      gbtu.setValue(
        packTable,
        firstRow,
        averageMonthContinuationColumn,
        RS(SC.GB_stAvgMonthsProdDistPerPersonContMonthOne)
      );
      if (allowEditsBool) {
        gbtu.setValue(packTable, firstRow, deleteBtnCol, RS(SC.GB_stDeleteBtn));
      }

      for (let item = 1; item <= numObjects; item++) {
        /* Set row values. */
        const itemName = piasu.getContCurveName(objList, item);
        gbtu.setValue(packTable, item, itemNameColumn, itemName);

        for (let period = 1; period <= pic.numContCurvePeriods; period++) {
          const percOnPrEPInt = piasu.getContCurvePercOnPrEP(objList, item, period);
          gbtu.setValue(packTable, item, period + hiddenColOffset, percOnPrEPInt);

          if (period === pic.numContCurvePeriods) {
            gbtu.setValue(packTable, item, averageMonthsColumn, piasu.getAvgMonthsOnPrEPInit(objList, item));
            gbtu.setValue(packTable, item, averageMonthInitialColumn, piasu.getAvgMonthsProdDistInit(objList, item));
            gbtu.setValue(
              packTable,
              item,
              averageMonthContinuationColumn,
              piasu.getAvgMonthsProdDistCont(objList, item)
            );
          }

          if (allowEditsBool && period === pic.numContCurvePeriods) {
            /* Need to save these into variables before passing or the link component will be sent the wrong row, col. */
            let rowPassed = item;
            let colPassed = deleteBtnCol;

            let btnKey = "btn row" + rowPassed + "_col" + colPassed;
            packTable.components[rowPassed][colPassed] = () => (
              <DeleteButton
                id={btnKey}
                row={rowPassed}
                itemType={pic.contCurveItems}
                props={this.props}
                state={this.state}
                setState={this.setState.bind(this)}
              />
            );
          }
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setColAlignment(packTable, deleteBtnCol, gbtc.hAlign.center);
      /* Needed when entering text. */
      gbtu.setMinAllowedValByCol(packTable, itemNameColumn, 0);
      gbtu.setMaxAllowedValByCol(packTable, itemNameColumn, 0);
      /* Hide the first column so the user can type in the first visible column. */
      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, itemNameColumn, Theme.itemNameColWidth);
      for (let c = itemNameColumn + 1; c < numCols; c++) {
        gbtu.setColWidth(packTable, c, Theme.dataColWidthSmall);
      }
      if (allowEditsBool) {
        gbtu.setColWidth(packTable, deleteBtnCol, Theme.dataColWidthSmall);
      }
      gbtu.setRowHeight(packTable, firstRow, 120);
      gbtu.setWordWrappedCol(packTable, 0, true);

      if (!allowEditsBool) {
        gbtu.lockPackTable(packTable, true);
      } else {
        gbtu.lockCol(packTable, averageMonthsColumn, true, true);
        gbtu.lockCol(packTable, averageMonthInitialColumn, true, true);
        gbtu.lockCol(packTable, averageMonthContinuationColumn, true, true);
      }
      gbtu.lockCol(packTable, deleteBtnCol, true, false);

      gbtu.restoreRDecsFromCopy(packTable, rDec, 1)

      if (window.DebugMode) {
        console.log("Component: PIContCurvePercOnPrEPTable");
        console.log("ModVar(s):");
        console.log(pisc.continuationCurvesMVTag);
        console.log(objList);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          focusedCell={focusedCell}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          frameworkComponents={frameworkComponents}
          gridKey={tableKey}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{
            ...packTable,
            GBFixedCols: 0,
            GBRowHeights: packTable.GBRowHeights.map((rowHeight, index) => (index === 0 ? rowHeight : -1)),
          }}
          types={generateTypes(packTable, [
            ...repeat("s", packTable.GBFixedCols + 1),
            ...repeat("n", packTable.GBColCount - packTable.GBFixedCols - 2),
            "cm",
          ])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={selectedRegions}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          limitWidthToContainer={true}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIContCurvePercOnPrEPTable");
  };

  render() {
    return (
      <>
        {this.renderTable()}
        {
          <AddButton
            itemType={pic.contCurveItems}
            props={this.props}
            state={this.state}
            setState={this.setState.bind(this)}
          />
        }
      </>
    );
  }
}
