export const hAlign = {
  left: 0,
  right: 1,
  center: 2,
};

export const fontStyle = {
  bold: 0,
  italic: 1,
  underline: 2,
  strikeOut: 3,
};

/* Menu items (MI) */
export const undo_MI = "Undo";
export const redo_MI = "Redo";
export const cut_MI = "Cut";
export const copy_MI = "copy";
export const copyAll_MI = "Copy all";
export const paste_MI = "paste";
export const decrDecimal_MI = "Decrement decimal";
export const incrDecimal_MI = "Increment decimal";
export const dupAcrossRows_MI = "Duplicate across rows";
export const dupDownColumns_MI = "Duplicate down columns";
export const interpolate_MI = "Interpolate";
export const normalize_MI = "Normalize";
export const multiply_MI = "Multiply";
export const dataSource_MI = "Data source";
export const showHideThousSep_MI = "Show/Hide thousands separator";
export const copyToJSON_MI = "Copy to JSON";

export const sector1_MIs = [undo_MI, redo_MI];
export const sector2_MIs = [cut_MI, copy_MI, copyAll_MI, paste_MI];
export const sector3_MIs = [decrDecimal_MI, incrDecimal_MI];
export const sector4_MIs = [dupAcrossRows_MI, dupDownColumns_MI];
export const sector5_MIs = [interpolate_MI, normalize_MI, multiply_MI];
export const sector6_MIs = [dataSource_MI];
export const sector7_MIs = [showHideThousSep_MI];
export const sector8_MIs = [copyToJSON_MI];

export const menuItems = [
  ...sector1_MIs,
  ...sector2_MIs,
  ...sector3_MIs,
  ...sector4_MIs,
  ...sector5_MIs,
  ...sector6_MIs,
  ...sector7_MIs,
  ...sector8_MIs,
];

export const rDec = "RDec";

export const maxInt = 999999999999999;

/* Colors */

export const transparentBase10 = 536870911;
export const whiteBase10 = 16777215;

/* Pack table defaults */

export const packTableDefaults = {
  GBRowCount: 1,
  GBColCount: 1,
  GBFixedRows: 1,
  GBFixedCols: 1,
  tableData: {
    value: [],
  },
  RDec: [],
  PercRDec: [],
  MergedCells: [],
  LockedCells: [],
  FontColors: [],
  GBColWidths: [],
  GBRowHeights: [],
  GBCellComment: [],
  title: "",
  hasData: true,
  hasDeselectedIntervs: false,
  RowIds: [],
  MinAllowedVal: [],
  MaxAllowedVal: [],
  FontStyles: [],
  FontSizes: [],
  IndentRowText: [{ xoffset: 0, Value: false }],
  Alignments: [],
  WordWrappedCols: [],
  components: [],
  id: "packTable",
  key: "packTable",
  HasCheckBox: [],
  HasComboBox: [],
  CheckBoxState: [],
  TotalRow: false,
  TotalCol: false,
  BGColors: [],
  nullable: [],
};
