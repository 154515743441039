import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const firstRow = 0;
const priorPopCol = 0;

class PIManualOptTargTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.tableKey]: "",
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },

    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],

    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = structuredClone(props[pias.modVarObjList]);

      let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      //let targSetOptionMstIDStr = piasu.getModVarValue(modVarObjListClone, pisc.targetSettingMVTag);
      let targClientsInitObjArray = piasu.getModVarValue(modVarObjListClone, pisc.targetsByPriorityPopMVTag);
      const methodsObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      const newPackTableClone = window.structuredCloneReview(newPackTable);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numMethods = piasu.getTotalNumMethods(methodsObjArr);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodsObjArr, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
          if (methodEligMstIDStr === pisc.yesCVOMstID) {
            const methodMstID = piasu.methodMstID(methodsObjArr, m);
            const valueFlt = gbtu.getValue(newPackTableClone, pp, m);

            piasu.setTargClientsInit(methodMstID, targClientsInitObjArray, pp, valueFlt);
          }
        }
      }

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          onModVarsChange(modVarObjListClone, false);
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const state = this.state;
      const rDec = state.rDec;

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const targClientsInitObjArray = piasu.getModVarValue(modVarObjList, pisc.targetsByPriorityPopMVTag);
      const methodsObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numMethods = piasu.getTotalNumMethods(methodsObjArr);
      const numRows = numPriorPops + 1; // add 1 for header
      const numCols = numMethods + 2; // +1 for first column, +1 for total column

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));

      for (let m = 1; m <= numMethods; m++) {
        gbtu.setValue(packTable, firstRow, m, piasu.methodName(methodsObjArr, m));
      }

      gbtu.setValue(packTable, firstRow, numCols - 1, RS(SC.GB_stTotal));

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

        let grayOutTotal = true;
        let total = 0;

        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodsObjArr, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
          if (methodEligMstIDStr === pisc.yesCVOMstID) {
            let valueFlt = piasu.getTargClientsInit(methodMstID, targClientsInitObjArray, pp);
            gbtu.setValue(packTable, pp, m, valueFlt);

            total = total + valueFlt;

            grayOutTotal = false;
          } else {
            gbtu.lockCell(packTable, pp, m, true, true);
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBGColor(packTable, pp, m, gainsboroBase10);
          }
        }

        if (grayOutTotal) {
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setCellBGColor(packTable, pp, numCols - 1, gainsboroBase10);
        } else {
          gbtu.setValue(packTable, pp, numCols - 1, total);
        }

        gbtu.lockCell(packTable, pp, numCols - 1, true, true);
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidth);
      gbtu.setRowHeight(packTable, firstRow, 50);
      gbtu.setWordWrappedCol(packTable, priorPopCol, true);

      gbtu.restoreRDecsFromCopy(packTable, rDec, 0);

      if (window.DebugMode) {
        console.log("Component: PIManualOptTargTable");
        console.log("ModVar(s):");
        console.log(pisc.targetsByPriorityPopMVTag);
        console.log(targClientsInitObjArray);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIManualOptTargTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIManualOptTargTable;
