import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const hiddenCol = 0;
const valueCol = 1;
const numCols = valueCol + 1;

const firstRow = 0;
const valueRow = 1;
const numRows = valueRow + 1;

class PIAGYWCostPerPYARTTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = structuredClone(newPackTable);

      const costPerPYART = gbtu.getValue(newPackTableClone, valueRow, valueCol);
      piasu.setModVarValue(modVarObjListClone, pisc.AGYWCostPerPYARTSingleValueMVTag, costPerPYART);

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          onModVarsChange(modVarObjListClone);
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const rDec = state[pip.rDec];

      const AGYWCostPerPYARTSingleValue = piasu.getModVarValue(modVarObjList, pisc.AGYWCostPerPYARTSingleValueMVTag);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, firstRow, valueCol, RS(SC.GB_stCostPerPersonYrART) + " (" + RS(SC.GB_stUSD) + ")");
      gbtu.setValue(packTable, valueRow, valueCol, AGYWCostPerPYARTSingleValue);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRowHeight(packTable, firstRow, 50);
      gbtu.setColWidth(packTable, hiddenCol, 0);
      gbtu.setColWidth(packTable, valueCol, Theme.dataColWidthMed);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);

      if (rDec.length === 0) {
        gbtu.setRDec(packTable, valueRow, valueCol, 2);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIAGYWCostPerPYARTTable");
        console.log("ModVar(s):");
        console.log(pisc.AGYWCostPerPYARTSingleValueMVTag);
        console.log(AGYWCostPerPYARTSingleValue);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.fontFamily}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.fontFamily,
            marginBottom: 20,
            padding: 0,
          }}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAGYWCostPerPYARTTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAGYWCostPerPYARTTable;
