import { useState } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbtc from "../../../GB/GBTableConst";

import * as piasu from "../../NonComponents/PIAppStateUtil";

import * as piu from "../../NonComponents/PIUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

import { combinePriorPops, genAndKeyPopsCombinedPops } from "../../NonComponents/PIPriorPopUtil";

const PIProgDataInitResByMonthTable = (props) => {
  const [rDec, setRDecs] = useState([]);

  const progDataPeriod = piu.getDateObjectAsJSDates(props.progDataPeriod);

  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  let priorPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");

  /** @type {number[][][]} */
  let initiations = piasu.getModVarValue(modVarObjList, "PI_Initiations");
  /** @type {number[][][]} */
  let restarts = piasu.getModVarValue(modVarObjList, "PI_Restarts");

  if (isPSEMode) {
    const combined = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, ["PI_Initiations", "PI_Restarts"]);

    priorPops = combined.PI_PriorityPop;
    initiations = combined.PI_Initiations;
    restarts = combined.PI_Restarts;
  }

  /** @type {string[]} */
  const popNames = priorPops.map((v) => v.name);

  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
  const methodIdx = methods.findIndex((m) => m.mstID === props.methodID);

  const progDataMonths = piu.getMonthsBetweenDates(progDataPeriod.start, progDataPeriod.end) + 1;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), progDataMonths + 3, popNames.length * 2 + 1);

  // Headers
  for (let col = 1; col <= popNames.length * 2; col += 2) {
    const idx = (col - 1) / 2;
    gbtu.mergeCells(packTable, 0, col, 1, 2);
    gbtu.setValue(packTable, 0, col, popNames[idx]);
    gbtu.setValue(packTable, 1, col, RS(SC.GB_stInits));
    gbtu.setValue(packTable, 1, col + 1, RS(SC.GB_stRestarts));
  }

  // Row Data
  const filteredInits = initiations[methodIdx].map((p) => p.slice(0, progDataMonths));
  const filteredRestarts = restarts[methodIdx].map((p) => p.slice(0, progDataMonths));

  let month = progDataPeriod.start.getMonth() + 1;
  let year = progDataPeriod.start.getFullYear();
  for (let row = 2; row <= progDataMonths + 1; ++row) {
    gbtu.setValue(packTable, row, 0, `${piu.getMonthAbbrName(month)} ${year}`);

    if (month === 12) {
      month = 1;
      ++year;
    } else ++month;

    for (let col = 1; col <= popNames.length * 2; col += 2) {
      const idx = (col - 1) / 2;
      gbtu.setValue(packTable, row, col, filteredInits[idx][row - 2]);
      gbtu.setValue(packTable, row, col + 1, filteredRestarts[idx][row - 2]);
    }
  }

  // Totals row
  const totalsRow = progDataMonths + 2;
  gbtu.setValue(packTable, totalsRow, 0, RS(SC.GB_stTotal));
  gbtu.setFontStyle(packTable, totalsRow, 0, [gbtc.fontStyle.bold]);

  const totalInits = filteredInits.map((p) => {
    return p.reduce((acc, cur) => acc + cur, 0);
  });

  const totalRestarts = filteredRestarts.map((p) => {
    return p.reduce((acc, cur) => acc + cur, 0);
  });

  for (let col = 1; col <= popNames.length * 2; col += 2) {
    const idx = (col - 1) / 2;
    gbtu.setValue(packTable, totalsRow, col, totalInits[idx]);
    gbtu.setValue(packTable, totalsRow, col + 1, totalRestarts[idx]);
  }

  // Table formatting
  gbtu.setRDecs(packTable, 0);

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);

  gbtu.setColWidths(packTable, Theme.dataColWidthMed);
  gbtu.setColWidth(packTable, 0, 140);
  gbtu.setRowHeight(packTable, 0, 75);
  // gbtu.setRowHeight(packTable, colHeadingsRow, 75);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.setFixedRows(packTable, 2);

  gbtu.lockPackTable(packTable, true, false);

  if (rDec.length !== 0) {
    for (let r = 0; r < rDec.length; r++) {
      for (let c = 0; c < rDec[r].length; c++) {
        gbtu.setRDec(packTable, r, c, rDec[r][c]);
      }
    }
  }

  const onPackTableChange = (newPackTable) => {
    setRDecs(structuredClone(newPackTable.RDec));
  };

  return (
    // <div>Temp: Method IDX = {methodIdx}</div>
    <SuperTableShim
      font={Theme.fontFamily}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      key={"progDataByMonth"}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.fontFamily,
        padding: 0,
      }}
    />
  );
};

PIProgDataInitResByMonthTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  progDataPeriod: PropTypes.shape({
    startYearInt: PropTypes.number,
    startMonthInt: PropTypes.number,
    endYearInt: PropTypes.number,
    endMonthInt: PropTypes.number,
  }),
  methodID: PropTypes.string.isRequired,
};

PIProgDataInitResByMonthTable.defaultProps = {
  onModVarsChange: () => {},
};

export default PIProgDataInitResByMonthTable;
