import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";
import { generateTypes } from "../../../utilities";

const initCol = 0;

const firstRow = 0;

const numRows = 26;

//const firstLevelCol               = 0;
const catchCol = 1;
const numInitPrEPCol = 2;
const PrEPCostCol = 3;
const ARTCostAvtdCol = 4;
const totalCostSavingsCol = 5;
const PrEPCostPerInfAvtdCol = 6;
const costEffectiveCol = 7;
const numCols = costEffectiveCol + 1;
const numColsToHideWhenTargsShows = 4;

let maxPage = 2;

class PIAGYWCostTable extends Component {
  static propTypes = {
    [pip.countryISO3Str]: PropTypes.string,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.showInDashboardAreaBool]: PropTypes.bool,
    [pip.showInTargetsAreaBool]: PropTypes.bool,
  };

  static defaultProps = {
    [pip.countryISO3Str]: "",

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},

    [pip.showInDashboardAreaBool]: false,
    [pip.showInTargetsAreaBool]: false,
  };

  state = {
    page: 1,
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================


  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const showInTargetsAreaBool = props[pip.showInTargetsAreaBool];
      const showInDashboardAreaBool = props[pip.showInDashboardAreaBool];

      const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.AGYWByAreaMVTag);
      const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
      const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.AGYWTemplateUploadedMVTag);
      const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
      const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

      const showTargets = piasu.showTargets(modVarObjList);
      const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
      const showAllCols = showInTargetsAreaBool || showInDashboardAreaBool || !showTargets || doNotSetTargsBool;

      let colOffset = showAllCols ? 0 : numColsToHideWhenTargsShows;

      // markAGYW
      if ((level1Name === "" && level2Name === "") || !templateUploaded) {
        return null;
      } else {
        let areas = [];

        const numAreas = piasu.getNumAGYWAreas(areaObjArr);

        for (let i = 1; i <= numAreas; i++) {
          areas.push(piasu.areaNameAGYW(areaObjArr, i));
        }

        let numR = this.state.page < maxPage ? pic.AGYWRegionsPerPage + 1 : 6;
        if (areas.length > 0) {
          numR =
            this.state.page <= areas.length / pic.AGYWRegionsPerPage
              ? pic.AGYWRegionsPerPage
              : (areas.length % pic.AGYWRegionsPerPage) + 1;
          maxPage = Math.ceil(areas.length / pic.AGYWRegionsPerPage);
        }

        //numR = areas.length > 0 ? areas.length - 1 : numRows;
        numR = areas.length > 0 ? areas.length + 1 : numRows; // mark

        let packTable = gbtu.getNewPackTable();

        packTable = gbtu.resizePackTable(packTable, numR, numCols - colOffset);

        const firstColTitle = level2Name !== "" ? level2Name : level1Name;

        gbtu.setValue(packTable, firstRow, initCol, firstColTitle);
        gbtu.setValue(packTable, firstRow, catchCol, RS(SC.GB_stCatchAreas));
        if (showAllCols) {
          gbtu.setValue(packTable, firstRow, numInitPrEPCol, RS(SC.GB_stNumAGYWInitiatingPrEP));
          gbtu.setValue(packTable, firstRow, PrEPCostCol, RS(SC.GB_stPrEPCost) + " (USD)");
          gbtu.setValue(packTable, firstRow, ARTCostAvtdCol, RS(SC.GB_stLifetimeARTCostAvtd) + " (USD)");
          gbtu.setValue(packTable, firstRow, totalCostSavingsCol, RS(SC.GB_stTotalCostSavings) + " (USD)");
        }
        gbtu.setValue(
          packTable,
          firstRow,
          PrEPCostPerInfAvtdCol - colOffset,
          RS(SC.GB_stPrEPCostInfectAvtd) + " (USD)"
        );
        gbtu.setValue(packTable, firstRow, costEffectiveCol - colOffset, RS(SC.GB_stIsPrEPCostEffective));

        for (let i = 1; i < numR; i++) {
          let areaName = piasu.areaNameAGYW(areaObjArr, i);
          let catchAreaName = piasu.catchmentAreaNameAGYW(areaObjArr, i);
          gbtu.setValue(packTable, firstRow + i, initCol, areaName);
          gbtu.setValue(packTable, firstRow + i, initCol + 1, catchAreaName);

          let areaCurrID = i;

          const numInitPrEP = piasu.numInitAGYW(areaObjArr, areaCurrID);
          const PrEPCostAGYW = piasu.PrEPCostAGYW(areaObjArr, areaCurrID);
          const lifetimeARTCostAvtdAGYW = piasu.lifetimeARTCostAvtdAGYW(areaObjArr, areaCurrID);
          const totalCostSavingsAGYW = piasu.totalCostSavingsAGYW(areaObjArr, areaCurrID);
          const PrEPCostPerInfAvtdAGYW = piasu.PrEPCostPerInfAvtdAGYW(areaObjArr, areaCurrID);
          const costEffectiveAGYW = piasu.costEffectiveAGYW(areaObjArr, areaCurrID) === 1 ? RS(SC.GB_stYes) : "";

          if (showAllCols) {
            gbtu.setValue(packTable, firstRow + i, numInitPrEPCol, numInitPrEP);
            gbtu.setValue(packTable, firstRow + i, PrEPCostCol, PrEPCostAGYW);
            gbtu.setValue(packTable, firstRow + i, ARTCostAvtdCol, lifetimeARTCostAvtdAGYW);
            gbtu.setValue(packTable, firstRow + i, totalCostSavingsCol, totalCostSavingsAGYW);
          }
          gbtu.setValue(packTable, firstRow + i, PrEPCostPerInfAvtdCol - colOffset, PrEPCostPerInfAvtdAGYW);
          gbtu.setValue(packTable, firstRow + i, costEffectiveCol - colOffset, costEffectiveAGYW);
        }

        gbtu.alignNumericCellsRight(packTable);
        gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
        gbtu.setRDecs(packTable, 0);
        gbtu.setRowHeight(packTable, firstRow, 100);
        gbtu.setColWidths(packTable, Theme.dataColWidthSmall);
        if (showAllCols) {
          gbtu.setMinAllowedValByCol(packTable, PrEPCostCol, 0);
          gbtu.setMaxAllowedValByCol(packTable, PrEPCostCol, gbtc.maxInt);
        }
        gbtu.lockPackTable(packTable, true, false);

        gbtu.setColWidth(packTable, initCol, 200);
        gbtu.setColWidth(packTable, catchCol, 200);

        if (window.DebugMode) {
          console.log("Component: PIAGYWCostTable");
          console.log("ModVar(s):");
          console.log(pisc.AGYWByAreaMVTag);
          console.log(areaObjArr);
          console.log("");
        }

        const stdTable = (
          <SuperTableShim
            font={Theme.tableFont}
            headerBackgroundColor={Theme.PI_PrimaryColor}
            oddRowBackgroundColor={Theme.PI_BandColor}
            packTable={packTable}
            types={generateTypes(packTable, ["s", "s", "n", "n", "n", "n", "n", "s"])}
            removedMenuNames={pitu.tableHideMenuItems}
            style={{
              fontFamily: Theme.tableFont,
              marginTop: 20,
              padding: 0,
            }}
          />
        );

        return stdTable;
      }
    };

    return gbu.tryRenderFn(fn, "render PIAGYWCostTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAGYWCostTable;
