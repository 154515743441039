import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";
import { useState } from "react";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbtc from "../../GB/GBTableConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import {
  combinePriorPops,
  disaggregatePriorPops,
  genAndKeyPopsCombinedPops,
  combinedPopsByCategory,
} from "../NonComponents/PIPriorPopUtil";
import React from "react";

const PIMethodMixCoveragePSETable = (props) => {
  // State
  const [rDec, setRDecs] = useState([]);

  if (!piasu.isPSEMode(props.modVarObjList)) {
    return <div>PIMethodMixCoveragePSETable requires PSE mode</div>;
  }

  const onModVarsChange = promisify(props.onModVarsChange);

  const modVarObjList = props.modVarObjList;
  /** @type {any[]} */
  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
  /** @type {any[]} */
  const allPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let priorPops = allPops;
  let methodMixCoverage = piasu.getModVarValue(modVarObjList, "PI_MethodMixCoverage");
  let methodMix = piasu.getModVarValue(modVarObjList, "PI_MethodMix");
  /** @type {any[]} */
  let actualCoverage = piasu.getModVarValue(modVarObjList, "PI_ActualCoverage");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  const categories = Object.keys(piu.pseIncidenceCategories);
  const genAndKeyPopsWithCategories = combinedPopsByCategory(genAndKeyPopsCombinedPops, allPops, categories);

  const combined = combinePriorPops(modVarObjList, genAndKeyPopsWithCategories, [
    "PI_MethodMixCoverage",
    "PI_MethodMix",
    "PI_ActualCoverage",
    "PI_Eligibility",
  ]);
  methodMixCoverage = combined["PI_MethodMixCoverage"];
  methodMix = combined["PI_MethodMix"];
  priorPops = combined["PI_PriorityPop"];
  actualCoverage = combined["PI_ActualCoverage"];
  eligibility = combined["PI_Eligibility"];

  const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));

  const numPops = Object.keys(genAndKeyPopsCombinedPops).length;

  const fixedRows = 2;
  const numRows = numPops * (categories.length + 1) + fixedRows;
  const numCols = methods.length * 2 + 4; // +4 = PP col, Coverage col, 2 total cols

  const covColIdx = 1;
  const covTargetStartIdx = 2;
  const covTargetTotalIdx = covTargetStartIdx + methods.length;
  const covActualStartIdx = covTargetTotalIdx + 1;
  const covActualTotalIdx = covActualStartIdx + methods.length;

  // Callbacks
  const onPackTableChange = async (newPackTable) => {
    setRDecs(structuredClone(newPackTable.RDec));

    let newCoverage = {};

    for (let rowIdx = 0; rowIdx < newPackTable.GBRowCount; ++rowIdx) {
      const rowID = gbtu.getRowID(newPackTable, rowIdx);
      if (rowID === 0) continue;

      const value = gbtu.getValue(newPackTable, rowIdx, covColIdx);
      newCoverage[rowID] = typeof value === "number" ? value / 100 : 0.0;
    }

    const newModVars = structuredClone(modVarObjList);

    const newData = {
      PI_PriorityPop: priorPops,
      PI_MethodMixCoverage: newCoverage,
    };

    const disagg = disaggregatePriorPops(modVarObjList, newData, genAndKeyPopsWithCategories);
    newCoverage = disagg.PI_MethodMixCoverage;

    piasu.setModVarValue(newModVars, "PI_MethodMixCoverage", newCoverage);

    await onModVarsChange(newModVars, false);
  };

  // Table
  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  // Headings
  gbtu.setFixedRows(packTable, fixedRows);

  gbtu.mergeCells(packTable, 0, covColIdx, 1, methods.length + 2);
  gbtu.setValue(packTable, 0, covColIdx, RS(SC.GB_stTargetCoverage));

  gbtu.mergeCells(packTable, 0, covActualStartIdx, 1, methods.length + 1);
  gbtu.setValue(packTable, 0, covActualStartIdx, RS(SC.GB_stActualCovToBeAchieved) + " (%)");

  // Sub Headings
  gbtu.setValue(packTable, 1, covColIdx, RS(SC.GB_stCoverage));
  for (let idx = 0; idx < methods.length; ++idx) {
    gbtu.setValue(packTable, 1, idx + covTargetStartIdx, methods[idx].name);
    gbtu.setValue(packTable, 1, idx + covActualStartIdx, methods[idx].name);
  }

  gbtu.setValue(packTable, 1, covTargetStartIdx + methods.length, RS(SC.GB_stTotal));
  gbtu.setValue(packTable, 1, covActualStartIdx + methods.length, RS(SC.GB_stTotal));

  // Row Titles by pops/incidence category
  let rowIdx = packTable.GBFixedRows;
  for (const popID in genAndKeyPopsCombinedPops) {
    gbtu.setValue(packTable, rowIdx, 0, genAndKeyPopsCombinedPops[popID].newPopName());
    gbtu.lockCells(packTable, rowIdx, true, true);

    ++rowIdx;

    // Incidence sub-groups
    for (let cat = 0; cat < categories.length; ++cat) {
      const catName = categories[cat];
      const catPopID = `${popID}_${catName.toUpperCase()}`;
      const pop = priorPops.find((p) => p.mstID === catPopID);

      gbtu.setValue(packTable, rowIdx, 0, piu.pseIncidenceCategories[catName]);
      gbtu.setIndent(packTable, rowIdx, true, Theme.leftIndent);
      gbtu.lockCells(packTable, rowIdx, true, true);

      if (pop === undefined) {
        gbtu.setRowBGColor(packTable, rowIdx, gainsboroBase10);
        gbtu.setCellBGColor(packTable, rowIdx, 0, gbtc.transparentBase10);
      } else {
        gbtu.setRowID(packTable, rowIdx, catPopID);
        gbtu.lockCell(packTable, rowIdx, covColIdx, false);
      }

      ++rowIdx;
    }
  }

  // Data
  for (let rowIdx = 0; rowIdx < packTable.GBRowCount; ++rowIdx) {
    const catPopID = gbtu.getRowID(packTable, rowIdx);
    if (catPopID === 0) continue;

    // Coverage
    const coverage = methodMixCoverage?.[catPopID] ?? 1.0;
    gbtu.setValue(packTable, rowIdx, covColIdx, coverage * 100);

    let totalActCov = 0;
    let totalTargetCov = 0;

    // Coverage types by method
    for (let methodIdx = 0; methodIdx < methods.length; ++methodIdx) {
      const popIdx = priorPops.findIndex((p) => p.mstID === catPopID);
      const eligible = eligibility[methodIdx].value[popIdx].value === pisc.yesMstID;

      if (eligible) {
        // Proposed coverage
        const proposedValue = (methodMix[catPopID][methods[methodIdx].mstID] ?? 0) * coverage * 100;
        gbtu.setValue(packTable, rowIdx, covTargetStartIdx + methodIdx, proposedValue);
        totalTargetCov += proposedValue;

        // Actual coverage
        const actualValue = actualCoverage[methodIdx].coverage[popIdx] * 100;
        gbtu.setValue(packTable, rowIdx, covActualStartIdx + methodIdx, actualValue);
        totalActCov += actualValue;
      } else {
        gbtu.setCellBGColor(packTable, rowIdx, covTargetStartIdx + methodIdx, gainsboroBase10);
        gbtu.setCellBGColor(packTable, rowIdx, covActualStartIdx + methodIdx, gainsboroBase10);
      }
    }

    // Totals
    gbtu.setValue(packTable, rowIdx, covTargetTotalIdx, totalTargetCov);
    gbtu.setValue(packTable, rowIdx, covActualTotalIdx, totalActCov);
  }

  // Settings
  gbtu.alignNumericCellsRight(packTable);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, 100);
  gbtu.setColWidths(packTable, Theme.dataColWidthSmall);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.setID(packTable, "optionsPackTable");
  gbtu.setKey(packTable, "optionsPackTable");

  gbtu.restoreRDecsFromCopy(packTable, rDec, 1);

  return (
    // @ts-ignore
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      gridKey={props.tableKey}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PIMethodMixCoveragePSETable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  tableKey: PropTypes.string,
};

PIMethodMixCoveragePSETable.defaultProps = {
  onModVarsChange: () => {},
  tableKey: "",
};

export default PIMethodMixCoveragePSETable;
