import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbtu from "../../GB/GBTableUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as pitu from "../NonComponents/PITableUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import { generateHeterogeneousTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import { combinePriorPops, disaggregatePriorPops, genAndKeyPopsCombinedPops } from "../NonComponents/PIPriorPopUtil";

const PIPriorPopMethodEligTable = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");

  let combined;
  let priorPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  if (isPSEMode) {
    combined = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, ["PI_Eligibility"]);
    priorPops = combined["PI_PriorityPop"];
    eligibility = combined["PI_Eligibility"];
  }

  // Callbacks
  const onPackTableChange = async (newPackTable) => {
    const newModVars = structuredClone(modVarObjList);

    const newData = {
      PI_PriorityPop: priorPops,
      PI_Eligibility: structuredClone(eligibility),
    };

    for (let pp = 1; pp <= priorPops.length; pp++) {
      for (let m = 1; m <= methods.length; m++) {
        const method = methods[m - 1];

        const checked = gbtu.getCheckBoxState(newPackTable, pp, m) ? "YES" : "NO";
        piasu.setPriorPopMethodElig(newData.PI_Eligibility, method.mstID, pp, checked);
      }
    }

    if (isPSEMode) {
      const disagg = disaggregatePriorPops(modVarObjList, newData, genAndKeyPopsCombinedPops);
      piasu.setModVarValue(newModVars, "PI_Eligibility", disagg.PI_Eligibility);
    } else {
      piasu.setModVarValue(newModVars, "PI_Eligibility", newData.PI_Eligibility);
    }

    await onModVarsChange(newModVars, false);
  };

  // Table
  const numRows = priorPops.length + 1;
  const numCols = methods.length + 1;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  gbtu.setValue(packTable, 0, 0, RS(SC.GB_stPriorityPop));

  for (let pp = 1; pp <= priorPops.length; pp++) {
    gbtu.setValue(packTable, pp, 0, piasu.getPriorPopName(priorPops, pp));

    for (let m = 1; m <= methods.length; m++) {
      const methodMstIDV = piasu.methodMstID(methods, m);

      if (pp === 1) {
        gbtu.setValue(packTable, 0, m, piasu.methodName(methods, m));
      }

      gbtu.setHasCheckBox(packTable, pp, m, true);
      gbtu.setAlignment(packTable, pp, m, gbtc.hAlign.center);
      //gbtu.lockCell(packTable, pp, m, true, false);
      const methodEligMstIDStr = piasu.getPriorPopMethodElig(eligibility, methodMstIDV, pp);
      gbtu.setCheckBoxState(packTable, pp, m, methodEligMstIDStr === pisc.yesCVOMstID ? true : false);
    }
  }

  gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);

  return (
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateHeterogeneousTypes(packTable, "cb")}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        tableFont: Theme.tableFont,
        marginLeft: Theme.leftIndent,
        marginTop: Theme.ctrlSpacing * 2,
        padding: 0,
        width: 1000,
      }}
      undoDisabled={false}
    />
  );
};

PIPriorPopMethodEligTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
};

PIPriorPopMethodEligTable.defaultProps = {
  onModVarsChange: () => {},
};

export default PIPriorPopMethodEligTable;
