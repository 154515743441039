import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as gbu from "../../GB/GBUtil";
import * as pic from "./PIConst";
import * as pisc from "./PIServerConst";
import * as pias from "./PIAppState";
import * as piasu from "./PIAppStateUtil";

export function getModVarsRetrieved(modVarObjArray) {
  /* If the country ISO ModVar is in there, assume the other ModVars are as well. */
  return typeof modVarObjArray !== "undefined" && modVarObjArray !== null && !gbu.isEmpty(modVarObjArray);
}

export function getPotUsersValid(modVarObjArray, validationItem, onDialogChange, displayErrorBool, successFn) {
  //const props = this.props;
  //const onDialogChange = props[pias.onDialogChange];

  //gbu.safeCallFn(successFn(true));
  //return true;
  // if (!getModVarsRetrieved(modVarObjArray)) {
  //
  //     return false;
  //
  // }

  const costingModeMstID = piasu.getModVarValue(modVarObjArray, pisc.costingModuleMVTag);
  if (costingModeMstID === pisc.detailedCostModeMstID) {
    gbu.safeCallFn(successFn(true));
    return true;
  }

  let validBool = true;

  if (getModVarsRetrieved(modVarObjArray)) {
    if (validationItem === pic.potUsersVI) {
      let priorPopObjArray = piasu.getModVarValue(modVarObjArray, pisc.priorPopsMVTag);
      let methodsObjArr = piasu.getModVarValue(modVarObjArray, pisc.methodsMVTag);
      const potUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjArray, pisc.coverageByPriorityPopMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArray);
      const numMethods = piasu.getTotalNumMethods(methodsObjArr);

      let pp = 1;
      while (pp <= numPriorPops && validBool) {
        let total = 0;

        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodsObjArr, m);

          const valueFlt = piasu.getPotUsersToTakePrEP(methodMstID, potUsersToTakePrEPObjArray, pp) * 100;
          total += valueFlt;
        }

        //if (!gbu.equal(total, 100)) {
        if (total > 100) {
          validBool = false;

          if (typeof displayErrorBool !== "undefined") {
            let dialogObj = pias.getDefaultDialogObj();
            dialogObj[pias.contentStr] = RS(SC.GB_stPotUsersLessThan100);
            dialogObj[pias.headerStr] = RS(SC.GB_stError);
            dialogObj[pias.maxWidthStr] = "sm";
            dialogObj[pias.showBool] = true;
            dialogObj[pias.styleObj] = { width: 500 };

            onDialogChange(dialogObj);

            //alert(RS(SC.GB_stPotUsersLessThan100));
          }
        }

        pp++;
      }
    }
  } else {
    validBool = false;
  }

  /* markFix */
  if (typeof successFn !== "undefined") {
    gbu.safeCallFn(() => successFn(validBool));
  }

  //return validBool;
}

export function validateAGYWParameters(modVarObjArr, validationItem, displayErrorBool, successFn) {
  //gbu.safeCallFn(successFn(true));
  //return true;
  // if (!getModVarsRetrieved(modVarObjArray)) {
  //
  //     return false;
  //
  // }

  let validBool = true;

  if (getModVarsRetrieved(modVarObjArr)) {
    if (validationItem === pic.AGYWParamsVI) {
    }
  } else {
    validBool = false;
  }

  /* markFix */
  if (typeof successFn !== "undefined") {
    gbu.safeCallFn(() => successFn(validBool));
  }

  //return validBool;
}
