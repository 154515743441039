import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";
import { useState } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as pitu from "../NonComponents/PITableUtil";

import { generateHeterogeneousTypes } from "../../../utilities";

import { combinePriorPops, disaggregatePriorPops, genAndKeyPopsCombinedPops } from "../NonComponents/PIPriorPopUtil";

const priorPopCurrIDC = "priorPopCurrID";
const methodCurrIDC = "methodCurrID";

const PIAssignContSchedToPriorPopLiteTableNew = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  const contVisits = piasu.getModVarValue(modVarObjList, "PI_Schedules_LiteV2");
  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");

  let combinedTest;
  let priorPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let costCatLite = piasu.getModVarValue(modVarObjList, "PI_CostCategories_Lite");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  if (isPSEMode) {
    combinedTest = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, [
      "PI_Eligibility",
      "PI_CostCategories_Lite",
    ]);
    priorPops = combinedTest["PI_PriorityPop"];
    eligibility = combinedTest["PI_Eligibility"];
    costCatLite = combinedTest["PI_CostCategories_Lite"];
  }

  // State
  const [focusedCell, setFocusedCell] = useState({
    row: 0,
    col: 1,
  });

  const [selectedRegions, setSelectedRegions] = useState({
    rowStart: 0,
    rowEnd: 0,
    columnStart: 1,
    columnEnd: 1,
  });

  // Callbacks
  const onContVisitSchedComboBoxChange = async (value, text, info) => {
    const newModVars = structuredClone(modVarObjList);

    const newData = {
      PI_PriorityPop: priorPops,
      PI_CostCategories_Lite: structuredClone(costCatLite),
    };

    const contVisitSchedCurrID = value + 1;
    const priorPopCurrID = info[priorPopCurrIDC];
    const methodCurrID = info[methodCurrIDC];

    const methodMstIDV = piasu.methodMstID(methods, methodCurrID);
    const contVisitSchedMstID = piasu.getContVisitSchedMstID(contVisits, contVisitSchedCurrID);

    piasu.setPriorPopContVisitSchedMstIDCostsLite(
      methodMstIDV,
      newData.PI_CostCategories_Lite,
      priorPopCurrID,
      contVisitSchedMstID
    );

    if (isPSEMode) {
      const disagg = disaggregatePriorPops(modVarObjList, newData, genAndKeyPopsCombinedPops);
      piasu.setModVarValue(newModVars, "PI_CostCategories_Lite", disagg.PI_CostCategories_Lite);
    } else {
      piasu.setModVarValue(newModVars, "PI_CostCategories_Lite", newData.PI_CostCategories_Lite);
    }

    await onModVarsChange(newModVars, false);
  };

  const numRows = priorPops.length + 1;
  const numCols = methods.length + 1;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  gbtu.setValue(packTable, 0, 0, RS(SC.GB_stPriorityPop));

  const comboBoxWidth = 240;

  let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

  for (let pp = 1; pp <= priorPops.length; pp++) {
    /* Set row headings. */
    const priorPopName = piasu.getPriorPopName(priorPops, pp);
    gbtu.setValue(packTable, pp, 0, priorPopName);

    for (let m = 1; m <= methods.length; m++) {
      const methodMstID = piasu.methodMstID(methods, m);

      /* Set column headings. */
      const methodNameV = piasu.methodName(methods, m);
      gbtu.setValue(packTable, 0, m, methodNameV);

      //markElig
      const methodEligMstIDStr = piasu.getPriorPopMethodElig(eligibility, methodMstID, pp);
      if (methodEligMstIDStr === pisc.yesCVOMstID) {
        gbtu.setHasComboBox(packTable, pp, m, true);

        comboBoxObj[pitu.style2DObjArray][pp][m] = {
          color: Theme.blackColor,
          fontFamily: Theme.fontFamily,
          width: comboBoxWidth,
        };

        let infoObjArr = [];
        infoObjArr.length = contVisits.length;
        infoObjArr.fill({
          [priorPopCurrIDC]: pp,
          [methodCurrIDC]: m,
        });
        comboBoxObj[pitu.info3DIntArray][pp][m] = infoObjArr;

        comboBoxObj[pitu.item3DStrArray][pp][m] = piasu.getContVisitSchedNames(contVisits);

        const contVisitSchedMstID = piasu.getPriorPopContVisitSchedMstIDCostsLite(methodMstID, costCatLite, pp);
        const contVisitSchedCurrID = piasu.getContVisitSchedCurrID(contVisits, contVisitSchedMstID);
        comboBoxObj[pitu.idx2DIntArray][pp][m] = contVisitSchedCurrID - 1;

        comboBoxObj[pitu.onChange2DFuncArray][pp][m] = onContVisitSchedComboBoxChange;
      } else {
        // There are some tables were doing this would show "Method not used" for several cells, even
        // if the user only unchecks one prior pop for one method. Would we rather see grayed out cells
        // or have this phrase repeated, say 4 times in a row?
        // gbtu.setValue(packTable, pp, m, RS(SC.GB_stMethodNotUsed));
        // gbtu.lockCell(packTable, pp, m, true, false);

        gbtu.lockCell(packTable, pp, m, true, true);
        const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
        gbtu.setCellBGColor(packTable, pp, m, gainsboroBase10);
      }
    }
  }

  gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);

  for (let m = 1; m <= methods.length; m++) {
    gbtu.setColWidth(packTable, m, comboBoxWidth + 10);
  }

  return (
    <GbStdTableWithComboBoxes
      focusedCell={focusedCell}
      onCellFocused={(focusedCell) => setFocusedCell(focusedCell)}
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      gridKey={props.tableKey}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateHeterogeneousTypes(packTable, "dd")}
      onPackTableChanged={() => {}}
      removedMenuNames={pitu.tableHideMenuItems}
      selectedRegions={selectedRegions}
      onSelectionChanged={(selectedRegions) => setSelectedRegions(selectedRegions)}
      style={{
        tableFont: Theme.tableFont,
        marginLeft: Theme.leftIndent,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
      comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
      comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
      comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
      onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
      width={0}
    />
  );
};

PIAssignContSchedToPriorPopLiteTableNew.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,

  tableKey: PropTypes.string,
};

PIAssignContSchedToPriorPopLiteTableNew.defaultProps = {
  onModVarsChange: () => {},

  tableKey: "",
};

export default PIAssignContSchedToPriorPopLiteTableNew;
