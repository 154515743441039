import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

//==================================================================================================================
//
//         ModVar tags
//
//==================================================================================================================

/* General */

export const appModeMVTag = "PI_AppMode";
export const allInputMVsLoadedMVTag = "PI_AllInputMVsLoaded";

/* Easy Start */

export const easyStartOptionsMVTag = "PI_EasyStartOptions";

/* Configuration */

export const countryISOMVTag = "PI_CountryISO";
export const priorPopsMVTag = "PI_PriorityPop";
export const priorPopsUploadMVTag = "PI_PriorityPopUpload";
export const continuationCurvesMVTag = "PI_ContinuationCurves";
export const programStartDateMVTag = "PI_ProgramStartDate";
export const programRecentDateMVTag = "PI_ProgramRecentDate";
export const programStartDateUploadMVTag = "PI_ProgramStartDateUpload";
export const programRecentDateUploadMVTag = "PI_ProgramRecentDateUpload";
export const dataElementsMVTag = "PI_DataElements";
export const costingModuleMVTag = "PI_CostingModule";
export const methodsMVTag = "PI_Methods";
export const selectedMethodMVTag = "PI_SelectedMethod";
export const specifyCostsAsMVTag = "PI_SpecifyCostsAs";
export const completedAreasMVTag = "PI_CompletedAreas";
export const AGYWOptionMVTag = "PI_AGYWSelected";
export const adminSubnatLevelsDisagMVTag = "PI_LevelNames";
export const progDataTemplateUploadedMVTag = "PI_MV_UploadedProgramData";
export const priorPopMethodEligMVTag = "PI_Eligibility";
export const initiationUploadMVTag = "PI_ProgramDataUpload";

/* Not explicitly used on client, but fields need to be resized when priority pops change. Contains
   contents of down/uploaded program data template. */
export const initiationMVTag = "PI_ProgramData";
export const sessionFileDownloadDateMVTag = "PI_DownloadDate";

/* Targets */

export const targetStartDateMVTag = "PI_TargetStartDate";
export const targetEndDateMVTag = "PI_TargetEndDate";
export const priorityPopSizeMVTag = "PI_PriorityPopSize";
export const percentPrepEligibleMVTag = "PI_PercentPrepEligible";
export const percentHIVPrevalenceMVTag = "PI_PercentHIVPrevalence";
export const percentForPrepMVTag = "PI_PercentForPrep";
export const scaleUpTrendsMVTag = "PI_ScaleupTrends";
export const continuationTrendMVTag = "PI_ContinuationTrend";
export const targetSettingMVTag = "PI_TargetSetting";
export const coverageByPriorityPopMVTag = "PI_ProposedCoverage"; //"PI_CoverageByPriorityPop";
//export const covConstrByPriorityPopMVTag = "PI_TargetCoverageConstraint";
export const covConstrActualMVTag = "PI_ActualCoverage";
export const targetsByPriorityPopMVTag = "PI_TargetsByPriorityPop";
export const initByPopTypeMVTag = "PI_InitByPopType";
export const restartsByPopTypeMVTag = "PI_RestartsByPopType";
export const costsByPopTypeMVTag = "PI_CostsByPopType";
export const infAvertedByPopTypeConstantCoverageMVTag = "PI_InfAvertedByPopType_CONSTANT_COVERAGE";
export const initByMonthMVTag = "PI_InitByMonth";
export const currPrEPByMonthMVTag = "PI_CurrPrEPByMonth";
export const targSelectedMethodsMVTag = "PI_TargetSelectedMethods";
export const targIndTableMVTag = "PI_TargetIndicators";
export const covConstUnitsDispMVTag = "PI_TargetDispensesConstraint";

/* Disaggregate targets */

export const dateRangeDisplayStartDateMVTag = "PI_ReportStartDate";
export const dateRangeDisplayEndDateMVTag = "PI_ReportEndDate";
export const priorPopAgeSexInclMVTag = "PI_PriorityPopIncluded";
export const districtPopulationsMVTag = "PI_DistrictPopulations";
export const HIVPatternsMVTag = "PI_HIVPattern";
export const selectedPrEPStatusMVTag = "PI_DisaggrTargetSelected";
export const targDisagDistPopPrEP_NEW_MVTag = "PI_TargetsByDistrictPrEP_NEW"; //"PI_TargetsByDistrictInit";
//defunct export const targDisagDistPopOnPrEPMVTag  = "PI_TargetsByDistrictCurrOnPrEP";
export const targDisagDistPopPrEP_CT_MVTag = "PI_TargetsByDistrictPrEP_CT"; //"PI_TargetsByDistrictPrEPCURR";
export const disagTargArea1NameMVTag = "PI_DisaggrTargetArea1Name";
export const disagTargArea2NameMVTag = "PI_DisaggrTargetArea2Name";
export const disagTargSelectedMethodMVTag = "PI_DisaggrTargetSelectedMethod";
export const disagTargTemplateUploadedMVTag = "PI_DisaggrTargetTemplateUploaded";

/* Detailed Costs */

export const contVisitsMVTag = "PI_VisitsV2"; // "PI_Visits"; (was by method)
export const costStayOnPrEPPTMVTag = "PI_CostStayOnByPT"; // both costing modes
export const costPerPersonInitPTMVTag = "PI_CostByPT"; // both costing modes
export const avgCostPrEPByMonthPTMVTag = "PI_CostStayOnByPTByMonth"; // both costing modes

/* Costs Lite */

export const contVisitSchedLiteMVTag = "PI_Schedules_LiteV2"; // "PI_Schedules_Lite"; (was by method)
export const costCategoriesLiteMVTag = "PI_CostCategories_Lite";
export const costPerVisitRatiosMVTag = "PI_CostRatio_Lite";
export const defCostsPerVisitLiteMVTag = "PI_CostTables_Lite";

/* Impact */

export const impactInfAvtdConstantCoverageMVTag = "PI_ImpactBaseDef_CONSTANT_COVERAGE";
export const adjustmentFactorMVTag = "PI_AdjustmentFactor";
export const adjInfAvtdConstantCoverageMVTag = "PI_InfAvertPerPerson_CONSTANT_COVERAGE";
export const persYrsPrEPAvtOneInfectConstantCoverageMVTag = "PI_ToAvert1Infection_CONSTANT_COVERAGE";
export const impactDefPriorPopMVTag = "PI_ImpactPriorPopDefault";
export const impactEffectivenessMVTag = "PI_ImpactEffectiveness";
//export const impactFactorsEffectMVTag     = "PI_ImpactPopEffectiveness";

/* Impact coverage options */

export const constantCovMstID = "CONSTANT_COVERAGE";

/* AGYW */

export const AGYWByAreaMVTag = "PI_AGYWByArea";
export const efficacyMVTag = "PI_Efficacy";
export const effectiveUseMVTag = "PI_EffectiveUse";
export const annualDiscountRateMVTag = "PI_AnnualDiscountRate";
export const thresholdCriterionMVTag = "PI_ThresholdCriterion";
export const costPerIAMVTag = "PI_CostPerIA";
export const costPerPersonMVTag = "PI_CostPerPYOnPrEP";
export const varyCostByAreaMVTag = "PI_VaryCostByArea";
export const varyCurvesAreaMVTag = "PI_VaryCurvesArea";
export const thresholdsResTableAGYWMVTag = "PI_AGYWBelowThresholds";
export const totalCostSavingsAllAreasMVTag = "PI_AGYWTotalCostSavings";
export const addCostSavingsTargMVTag = "PI_AGYWAdditionalSavings";
export const addCostSavingsTargPercMVTag = "PI_AGYWAdditionalPercent";
export const AGYWSelectedAreaTypeMVTag = "PI_AGYWSelectedAreaType";
export const AGYWCostPerPYPrEPSrcMVTag = "PI_CostPerPYPrEPSource";
export const AGYWCostPerPYARTEntryTypeMVTag = "PI_CostPerPYARTEntryType";
export const AGYWCostPerPYARTSingleValueMVTag = "PI_CostPerPYARTSingleValue";
export const AGYWTemplateUploadedMVTag = "PI_AGYWTemplateUploaded";

/* Program data monitoring and forecasting / Commodities forecasting */

export const drugForecastTableMVTag = "PI_DrugForecasting";

/* Aggregate */
export const aggFileNamesMVTag = "PI_AggregateFileNames";

/* Target estimates (TE) (Targets default priority populations reference drawer) - from database. */
export const n_AGYW_15_19TE = "90_AGYW_15_19";
export const n_AGYW_18_19TE = "90_AGYW_18_19";
export const n_AGYW_20_24TE = "90_AGYW_20_24";
export const n_Breastfeeding_womenTE = "90_Breastfeeding_women";
export const n_FSWTE = "90_FSW";
export const n_General_population_menTE = "90_General_population_men";
export const n_General_population_womenTE = "90_General_population_women";
export const n_High_risk_heterosexual_menTE = "90_High_risk_heterosexual_men";
export const n_MSMTE = "90_MSM";
export const n_Medium_risk_menTE = "90_Medium_risk_men";
export const n_Medium_risk_womenTE = "90_Medium_risk_women";
export const n_PWIDTE = "90_PWID";
export const n_Pregnant_womenTE = "90_Pregnant_women";
export const n_TWTE = "90_TW";
export const AGYW_15_19TE = "AGYW_15_19";
export const AGYW_15_19_HIV_sourceTE = "AGYW_15_19_HIV_source";
export const AGYW_15_24TE = "AGYW_15_24";
export const AGYW_15_24_HIV_sourceTE = "AGYW_15_24_HIV_source";
export const AGYW_18_19TE = "AGYW_18_19";
export const AGYW_18_19_HIV_sourceTE = "AGYW_18_19_HIV_source";
export const AGYW_18_24TE = "AGYW_18_24";
export const AGYW_18_24_HIV_sourceTE = "AGYW_18_24_HIV_source";
export const AGYW_20_24TE = "AGYW_20_24";
export const AGYW_20_24_HIV_sourceTE = "AGYW_20_24_HIV_source";
export const BF_sourceTE = "BF_source";
export const BreastfeedingTE = "Breastfeeding";
export const CC_AGYW_15_19TE = "CC_AGYW_15_19";
export const CC_AGYW_18_19TE = "CC_AGYW_18_19";
export const CC_AGYW_20_24TE = "CC_AGYW_20_24";
export const CC_Breastfeeding_womenTE = "CC_Breastfeeding_women";
export const CC_FSWTE = "CC_FSW";
export const CC_General_population_menTE = "CC_General_population_men";
export const CC_General_population_womenTE = "CC_General_population_women";
export const CC_High_risk_heterosexual_menTE = "CC_High_risk_heterosexual_men";
export const CC_MSMTE = "CC_MSM";
export const CC_Medium_risk_menTE = "CC_Medium_risk_men";
export const CC_Medium_risk_womenTE = "CC_Medium_risk_women";
export const CC_PWIDTE = "CC_PWID";
export const CC_Pregnant_womenTE = "CC_Pregnant_women";
export const CC_TWTE = "CC_TW";
export const CountryTE = "Country";
export const HIV_prev_women_15_49TE = "HIV_prev_women_15_49";
export const ISO_AlphaTE = "ISO_Alpha";
export const ISO_NumericTE = "ISO_Numeric";
export const MSMTE = "MSM";
export const MSM_HIV_sourceTE = "MSM_HIV_source";
export const MSM_sourceTE = "MSM_source";
export const Men_15_64TE = "Men_15_64";
export const PWIDTE = "PWID";
export const PWID_HIV_sourceTE = "PWID_HIV_source";
export const PWID_sourceTE = "PWID_source";
export const Population_15_64TE = "Population_15_64";
export const PregnantTE = "Pregnant";
export const Prev15_49_2017_estTE = "Prev15_49_2017_est";
export const RegionTE = "Region";
export const SDCsTE = "SDCs";
export const SWTE = "SW";
export const SW_HIV_sourceTE = "SW_HIV_source";
export const SW_sourceTE = "SW_source";
export const TGTE = "TG";
export const TG_HIV_sourceTE = "TG_HIV_source";
export const TG_sourceTE = "TG_source";
export const Women_15_49TE = "Women_15_49";
export const perc_15_19TE = "perc_15_19";
export const perc_15_24TE = "perc_15_24";
export const perc_15_49TE = "perc_15_49";
export const perc_15_64TE = "perc_15_64";
export const perc_20_24TE = "perc_20_24";
export const perc_20_64TE = "perc_20_64";
export const perc_AGYW_15_19_HIVTE = "perc_AGYW_15_19_HIV";
export const perc_AGYW_15_19_HR2_MPCSTE = "perc_AGYW_15_19_HR2_MPCS";
export const perc_AGYW_15_19_STI_allTE = "perc_AGYW_15_19_STI_all";
export const perc_AGYW_15_19_STI_no_dischargeTE = "perc_AGYW_15_19_STI_no_discharge";
export const perc_AGYW_15_24_HIVTE = "perc_AGYW_15_24_HIV";
export const perc_AGYW_15_24_HR2_MPCSTE = "perc_AGYW_15_24_HR2_MPCS";
export const perc_AGYW_15_24_STI_allTE = "perc_AGYW_15_24_STI_all";
export const perc_AGYW_15_24_STI_no_dischargeTE = "perc_AGYW_15_24_STI_no_discharge";
export const perc_AGYW_18_19_HIVTE = "perc_AGYW_18_19_HIV";
export const perc_AGYW_18_19_HR2_MPCSTE = "perc_AGYW_18_19_HR2_MPCS";
export const perc_AGYW_18_19_STI_allTE = "perc_AGYW_18_19_STI_all";
export const perc_AGYW_18_19_STI_no_dischargeTE = "perc_AGYW_18_19_STI_no_discharge";
export const perc_AGYW_18_24_HIVTE = "perc_AGYW_18_24_HIV";
export const perc_AGYW_18_24_HR2_MPCSTE = "perc_AGYW_18_24_HR2_MPCS";
export const perc_AGYW_18_24_STI_allTE = "perc_AGYW_18_24_STI_all";
export const perc_AGYW_18_24_STI_no_dischargeTE = "perc_AGYW_18_24_STI_no_discharge";
export const perc_AGYW_20_24_HIVTE = "perc_AGYW_20_24_HIV";
export const perc_AGYW_20_24_HR2_MPCSTE = "perc_AGYW_20_24_HR2_MPCS";
export const perc_AGYW_20_24_STI_allTE = "perc_AGYW_20_24_STI_all";
export const perc_AGYW_20_24_STI_no_dischargeTE = "perc_AGYW_20_24_STI_no_discharge";
export const perc_BF_HIVTE = "perc_BF_HIV";
export const perc_BF_MPCSTE = "perc_BF_MPCS";
export const perc_BF_STITE = "perc_BF_STI";
export const perc_BF_STR_no_dischargeTE = "perc_BF_STR_no_discharge";
export const perc_BreastfeedingTE = "perc_Breastfeeding";
export const perc_MSM_HIVTE = "perc_MSM_HIV";
export const perc_PWID_HIVTE = "perc_PWID_HIV";
export const perc_PregnantTE = "perc_Pregnant";
export const perc_Pregnant_HIVTE = "perc_Pregnant_HIV";
export const perc_Pregnant_MPCSTE = "perc_Pregnant_MPCS";
export const perc_Pregnant_STITE = "perc_Pregnant_STI";
export const perc_Pregnant_STI_no_dischargeTE = "perc_Pregnant_STI_no_discharge";
export const perc_SW_HIVTE = "perc_SW_HIV";
export const perc_TG_HIVTE = "perc_TG_HIV";
export const perc_femalesTE = "perc_females";
export const perc_malesTE = "perc_males";
export const perc_married_unionTE = "perc_married_union";
export const pop_2019_estTE = "pop_2019_est";

//==================================================================================================================
//
//         ModVar fields
//
//==================================================================================================================

/* ModVar objects, general */

export const modVars = "modvars";
export const mvTag = "tag";
export const mvModule = "module";
export const mvValue = "value";
/* In Aggregate (AG) mode, we gain the aggregate, list, and sum fields and lose value.
   The sum field only exists if the data can in fact be summed across files. */
export const mvAggregateAG = "aggregate";
export const mvListAG = "list";
export const mvSumAG = "sum";

/* ModVars by method, general */

export const genMethodMstID = "mstID";
export const genItemMstID = "mstID";

/* Easy Start mode. */

export const easyStartModeMstID = "mstID";
export const easyStartModeValue = "value";

/* Priority population object - priorPopsMVTag */

export const priorPopMstID = "mstID";
export const priorPopName = "name";
export const priorPopAbbr = "abrv";
export const priorPopContCurves = "contCurve";
export const priorPopContCurveMstID = "curveID";
export const priorPopContCurveMethodMstID = "mstID";
export const priorPopScaleUpTrends = "scaleUpTrend";
export const priorPopScaleUpTrendMstID = "trendID";
export const priorPopScaleUpTrendMethodMstID = "mstID";
export const priorPopBaseName = "baseName"; // for Impact I guess
export const priorPopImpPopMstID = "impPopMstID";
export const priorPopImpactPSE = "impact_pse";
export const priorPopImpactPSEMethodMstID = "mstID";
export const priorPopImpactConst = "impact_constant";
export const priorPopImpactConstMethodMstID = "mstID";
export const priorPopDefaults = "defaults";

/* Continuation curve object - continuationCurvesMVTag */

export const contCurveMstID = "mstID";
export const contCurveName = "name";
export const contCurvePercOnPrEP = "percOnPrep";
export const percClientsRemainPrEP = "byMonth";
export const avgMonthsOnPrEPInit = "avgMonthsOnPrEPPerPersonInit";
export const avgMonthsProdDistInit = "avgMonthsProdDistPerPersonInit";
export const avgMonthsProdDistCont = "avgMonthsProdDistPerPersonCont";

/* Scale-up trend object */

export const initSpeed = "initSpeed";
export const midpoint = "midpoint";
export const scaleUpTrendName = "name";
export const scaleUpTrendMstID = "trendID";
export const scaleUpTypeMstID = "scaleUp";
export const percClientsInitPrEP = "values";

/* Country objects */

export const countryName = "Country";
export const countryAlphaCode = "ISOAlpha"; // "ISO_Alpha";
export const countryCode = "ISONumeric"; // "ISO_Numeric";

/* "Program start date" */

export const monthPSD = "month";
export const yearPSD = "year";

/* "Program recent date" */

export const monthPRD = "month";
export const yearPRD = "year";

/* "Target start date" */

export const monthTSD = "month";
export const yearTSD = "year";

/* "Target recent date" */

export const monthTRD = "month";
export const yearTRD = "year";

/* "Date range for display start date" */

export const monthDRD_SD = "month";
export const yearDRD_SD = "year";

/* "Date range for display recent date" */

export const monthDRD_RD = "month";
export const yearDRD_RD = "year";

export const noCountryObj = {
  [countryCode]: -1,
  [countryName]: RS(SC.GB_stSelectCountry),
  [countryAlphaCode]: "",
};

/* Errors */

export const exceptionMsg = "msg";
export const exceptionStatus = "status";
export const stackTrace = "trace";

/* Continuation visit objects - contVisitsMVTag */

export const contVisitMethodMstID = "mstID";
export const contVisitObjArr = "visitList";
export const contVisitMstID = "mstID";
export const contVisitName = "name";

/* Continuation visit schedule objects */

export const contVisitSchedMethodMstID = "mstID";
export const contVisitSchedObjArr = "schedules";
export const contVisitSchedMstID = "mstID";
export const contVisitSchedName = "name";
export const contVisitSchedVisits = "visits";
export const contVisitSchedPop = "pop";
export const contVisitSchedMonthsAgo = "months_ago";

/* Priority population percent eligible - priorPopMethodEligMVTag */

export const methodValuePPPE = "value";
export const methodMstIDPPPE = "mstID";
export const priorPopValuePPPE = "value";
export const priorPopMethodMstIDPPE = "mstID";

/* Cost categories (CC) object - costCategoriesMVTag */

export const methodMstIDCCDet = "mstID";
export const costPerPersMonthCC = "monthlyCost";
export const ARVsCC = "ARVs";
export const adhereSuppCC = "adherenceSupport";
export const preInitCC = "preInit";
export const preInitCostsCC = "cost";
export const preInitToInitCC = "initRatio";
export const preInitHIVRapidTestCC = "labTests";
export const preInitPersonnelCostsCC = "personnel";
export const preInitOtherRecurCostsCC = "otherRecurrent";
export const preInitOverheadCostsCC = "overhead";
export const preInitCapCostsCC = "capitalCosts";
export const preInitDefRatios = "initRatio"; //"defaultRatios";
export const initCC = "initiation";
export const initVisitCostsCC = "cost";
export const initLabTestCostsCC = "labTests";
export const initRecPregTestCC = "receivePregTest";
export const initPersonnelCostsCC = "personnel";
export const initOtherRecurCostsCC = "otherRecurrent";
export const initOverheadCostsCC = "overhead";
export const initCapCostsCC = "capitalCosts";
export const initDefRatios = "defaultRatios";
export const contCC = "continuation";
export const contVisitCostsCC = "cost";
export const contLabTestCostsCC = "labTests";
export const contRecPregTestCC = "receivePregTest";
export const contPersonnelCostsCC = "personnel";
export const contOtherRecurCostsCC = "otherRecurrent";
export const contOverheadCostsCC = "overhead";
export const contCapCostsCC = "capitalCosts";
export const contDefRatiosCC = "defaultRatios";
export const annualLumpSumCostsCC = "cost";
export const annualCC = "annual";
export const annAboveSiteCC = "aboveSite";
export const annDemCreatCC = "demandCreation";
export const annOtherCC = "other";
export const annTrainingCC = "training";
export const nameCC = "name"; // not used?

/* Cost categories (CC) (Lite) */

export const methodMstIDCCLite = "mstID";
export const priorPopObjArrCCLite = "categories";
export const ARVsCCLite = "ARVs";
export const adherenceSupportCCLite = "adherenceSupport";
export const annualCCLite = "annual";
export const contCCLite = "continuation";
export const initCCLite = "initiation";
export const capitalCCLite = "capital";
export const personnelCCLite = "personnel";
export const recurrentCCLite = "recurrent";
export const totalCCLite = "total";
export const visitLabsCCLite = "visitLabs";
export const monthlyCostCCLite = "monthlyCost";
export const includedCCLite = "included";
export const priorPopMstIDCCLite = "mstID";
export const scheduleIDCCLite = "scheduleID";

/* Cost per visit (CPV), Costs Lite (Reference table) - defCostsPerVisitLiteMVTag */

export const totalCPVLite = "totalCPV";
export const methodMstIDCPVLite = "methodMstID";
export const AGYWCPVLite = "AGYW";
export const KEYPOPCPVLite = "KeyPop";
export const SDCCPVLite = "SDC";
export const contCPVLite = "continuation";
export const initCPVLite = "initiation";

/* Cost per visit ratios (CPVR), Costs Lite - costPerVisitRatiosMVTag */

export const methodMstIDCPVRLite = "mstID";
export const ratiosCPVRLite = "ratios";

/* Initiation / Program data */

export const initMethodMstID = "mstID";
export const initMethodName = "name";
export const initMonth = "month";
export const initYear = "year";
export const initObjArr = "program";
export const initInitiatedPrEP = "initiatedPrEP";
export const initReinitiatedPrEP = "reinitiatedPrEP";

/* Area completed object */

export const areaCompleted = "completed";
export const areaMstID = "mstID";

/* Capacity - Staff minutes (SM) */

export const contSM = "cont";
export const initSM = "init";

/* Capacity - Proportion of visits (PV) */

export const contPV = "cont";
export const initPV = "init";
export const monthsPV = "months";
export const propVisitsPV = "rate";

/* Methods - methodsMVTag */

export const methodName = "name";
export const methodMstID = "mstID";
export const methodUnitComm = "unitOfCommodity";
export const methodsMonthsCovPerUnit = "months_cov_per_unit";
export const methodUnitCost = "unitCost";

/* Targets, Options - Potential users to take PrEP with constraints(PUwC) */

export const coveragePUwC = "coverage";
export const methodMstIDPUwC = "mstID";

/* Targets, Options - Coverage with constraints, total costs (CwCTC) */

export const constraintsCwCTC = "constraints";
export const methodMstIDCwCTC = "mstID";

/* Targets, Options - Coverage with constraints, units dispensed (CwCUD) */

export const constraintsCwCUD = "constraints";
export const methodMstIDCwCUD = "mstID";

/* Targets, Options - Potential users to take PrEP (PU) */

export const coveragePU = "coverage";
export const methodMstIDPU = "mstID";

/* Targets, Options - Actual users to take PrEP (AU) */

export const coverageTSP_AU = "coverageTSP";
export const coverageDRD_AU = "coverageDRD";
export const methodMstIDAU = "mstID";

/* Targets, Options - Target number of clients (TC) */

export const targetTC = "target";
export const methodMstIDTC = "mstID";

/* Targets -  indicators */

export const PREP_CT_TI = "PrEP_CT";
export const currOnPrEPTI = "currentlyOnPrEP";
export const PrEP_NEW_TI = "PrEP_NEW";

/* Impact, Impact adjustment - Adjustment factors */

export const factorsAF = "factors";
export const methodMstIDAF = "mstID";

/* Costs Lite - Reference drawer - Default cost per visit */

export const ratiosCPV = "ratios";

/* Drug forecasting by month table */

export const numInitVisitsDF = "initiations";
export const numContVisitsDF = "continuations";
export const numMethodReqDF = "productRequired";
export const methodCostsDF = "costs";

/* Areas below threshold AGYW result table object */

export const AGYW_ABT = "popAGYW";
export const prevalenceAGYW_ABT = "prevalence";
export const PrEPCostABT = "PrEPCost";
export const savingsABT = "TotalCost";

/* Drug forecasting - Number of [method] distributed per initiation visit. (MDI) */

export const methodMstIDMDI = "mstID";
export const countMDI = "count";

/* Drug forecasting - Number of [method] distributed per continuation visit. (MDC) */

export const methodMstIDMDC = "mstID";
export const countMDC = "count";

/* Drug forecasting - Cost per method (CPM) */

export const methodMstIDCPM = "mstID";
export const costCPM = "cost";

/* AGYW */

export const areaNameAGYW = "areaName";
export const catchmentAreaNameAGYW = "catchmentArea";
export const PrEPCostAGYW = "PrEPCost";
export const lifetimeARTCostAvtdAGYW = "ARTCostAverted";
export const totalCostSavingsAGYW = "TotalCostSavings";
export const PrEPCostPerInfAvtdAGYW = "CostInfectionAverted";
export const prev15t24CostAGYW = "prevalenceAmong15_24";
export const prev10t14AGYW = "prevalenceAmong10_14";
export const estInc15t24AGYW = "incidenceEstimate15_24";
export const numInitAGYW = "numberInitiating";
export const pop15t24AGYW = "populationAGYW15_24";
export const costPerPersonYrPrEPAGYW = "PrEPCostPerPerson";
export const costPerPersonYrARTAGYW = "CostPerPYOnART"; //"PrEPCost";
export const contCurveMstIDAGYW = "curveMstID";
export const costEffectiveAGYW = "SiteSelection";

/* Default impact priority population (DIPP) object */

export const ninetyX3DIPP = "90_90_90";
export const constantDIPP = "constant";
export const impPriorPopMstIDDIPP = "mstID";
export const impPriorPopNameDIPP = "name";

/* Impact factors by effectiveness - impactFactorsEffectMVTag */

export const impFactEffect90x3 = "impact_90_90_90";
export const impFactEffectConst = "impact_constant";

/* Disaggregate targets - district populations (DP) */

export const districtNameDP = "district";
export const percentDP = "percent";
export const pop15PlusDP = "pop15plus";
export const provinceDP = "province";

/* Disaggregate targets - Priority populations included by age and sex */

export const includedPPIASObjArr = "included";
export const methodMstIDPPIAS = "mstID";
export const endAgePPIAS = "endAge";
export const startAgePPIAS = "startAge";
export const includePPIAS = "include";
export const priorPopMstIDsPPIAS = "mstIDs";

/* Salaries - salariesMVTag */

export const salariesMethodMstID = "mstID";
export const salariesObjArr = "salaries";

//==================================================================================================================
//
//         Master IDs
//
//==================================================================================================================

export const noToolSelectedMstID = "TOOL_NOT_SELECTED";
export const PrEPitStdToolMstID = "PREP_IT_STANDARD_TOOL";
export const PrEPitEasyStartToolMstID = "PREP_IT_WIZARD_TOOL";
export const aggregateToolMstID = "AGGREGATE_TOOL";
export const costsLiteWizardMstID = "COST_LITE_WIZARD_TOOL"; // Defunct
export const AGYWWizardToolMstID = "AGYW_WIZARD_TOOL"; // Defunct

/* For any of the object lists/arrays of objects */
export const customItemMstID = "CUSTOM";

/* Default priority populations (PP) */

export const SDC_PP_MstID = "SDC"; // Sero discordent couples
export const AGYW_PP_MstID = "AGYW_15_24"; // Adolescent girls/young women 15-24
export const MSM_PP_MstID = "MSM"; // Men who have sex with men
export const FSW_PP_MstID = "FSW"; // Female sex workers
export const PWID_PP_MstID = "PWIDU"; // People who inject drugs
export const PBW_PP_MstID = "PBW"; // Pregnant and breastfeeding women
export const TP_PP_MstID = "TP"; // Transgender people

/* Months - silly and unnecessary, but w/e */

export const JanMstID = "JANUARY";
export const FebMstID = "FEBRUARY";
export const MarMstID = "MARCH";
export const AprMstID = "APRIL";
export const MayMstID = "MAY";
export const JunMstID = "JUNE";
export const JulMstID = "JULY";
export const AugMstID = "AUGUST";
export const SeptMstID = "SEPTEMBER";
export const OctMstID = "OCTOBER";
export const NovMstID = "NOVEMBER";
export const DecMstID = "DECEMBER";

/* Initiation data elements (DE) */

export const includeDEMstID = "YES";
export const excludeDEMstID = "NO";

/* Continuation curves (CC) */

export const SDCsCCMstID = "CURVE_SDC";
export const AGYW15t24CCMstID = "CURVE_AGYW";
export const MSMCCMstID = "CURVE_MSM";
export const FSWCCMstID = "CURVE_FSW";
export const PBWCCMstID = "CURVE_PBW";

/* PrEP screened option (SO) */

export const noDataSOMstID = "NO_STEP";
export const oneStepSOMstID = "ONE_STEP";
export const twoStepSOMstID = "TWO_STEP";

/* Scale-up types */

export const noScaleUpMstID = "NO-SCALEUP";
export const constantScaleUpMstID = "CONSTANT";
export const linearScaleUpMstID = "LINEAR";
export const sShapedScaleUpMstID = "S-SHAPED";

/* Scale-up trends */

export const scaleUpTrendAMstID = "TREND_A";
export const scaleUpTrendBMstID = "TREND_B";
export const scaleUpTrendCMstID = "TREND_C";

/* Target-setting option */

export const coverageTargSetMstID = "TARGET_COVERAGE_OPTION";
export const constraintsTargSetMstID = "TARGET_CONSTRAINTS_OPTION";
// defunct export const capacityTargSetMstID = "TARGET_CAPACITY_OPTION";
export const manualTargSetMstID = "TARGET_MANUAL_OPTION";

/* Education codes */

export const eduCode1MstID = "Educational_Code1";
export const eduCode2MstID = "Educational_Code2";
export const eduCode3MstID = "Educational_Code3";
export const eduCode4MstID = "Educational_Code4";
export const eduCode5MstID = "Educational_Code5";

/* Minute patterns (MP) */

export const SDCsMPMstID = "MP_SDC";
export const keyPopsMPMstID = "MP_KEYPOP";
export const AGYWMPMstID = "MP_AGYW";
export const genPopMPMstID = "MP_GENERAL_POPULATION";
export const dropInCenterMPMstID = "MP_DROP_IN_CETRES";
export const pubHlthCenterMPMstID = "MP_PUBLIC_HEALTH_CENTRES";
export const pubHospMPMstID = "MP_PUBLIC_HOSPITALS";
export const privClinicMPMstID = "MP_PRIVATE_CLINICS";

/* Laboratory tests */

export const HIVRapidTestMstID = "HIV_RAPID_TEST";
export const ELISATestMstID = "ELISA_TEST";
export const HBsAgTestMstID = "HBSAG_TEST";
export const ALTTestMstID = "ALT_TEST";
export const pregTestUrineMstID = "PREGNANCY_TEST";
export const rapidSyphTestMstID = "RAPID_SYPHILIS_TEST";
export const RPRTitreTestMstID = "RPR_TITRE_TEST";
export const creatinineTestMstID = "CREATININE_TEST";
export const PrEPDrugLevelTestMstID = "PREP_DRUG_LVL_TEST";

/* Continuation visits */

export const initVisitCVMstID = "INIT_VISIT";
export const dashCVMstID = "-";

/* Continuation visit schedules */

export const contVisitSched1MstID = "SCHEDULE_1";
export const contVisitSched2MstID = "SCHEDULE_2";
export const contVisitSched3MstID = "SCHEDULE_3";
export const contVisitSched4MstID = "SCHEDULE_4";

/* Continuation visit options (CVO) */

export const yesCVOMstID = "YES";
export const noCVOMstID = "NO";
export const dashCVOMstID = "-";

/* Costing modes */

export const detailedCostModeMstID = "DETAILED_COSTS";
export const costsLiteModeMstID = "COSTS_LITE";
export const noCostingModeMstID = "PI_NO_COSTING";

/* Areas to Complete */

export const priorPopAreaMstID = "POPULATION_AREA";
export const contCurveAreaMstID = "CONTINUATION_AREA";
export const initAreaMstID = "INITIATION_AREA";
export const capacityAreaMstID = "CAPACITY_AREA";
export const detCostsAreaMstID = "DETAILED_COSTS_AREA";
export const costsAreaMstID = "COSTS_LITE_AREA";
export const targetsAreaMstID = "TARGETS_AREA";
export const AGYWAreaMstID = "AGYW_AREA";
export const disaggTargsAreaMstID = "DISAGGREGATED_TARGETS_AREA";
export const methodsAreaMstID = "METHODS_AREA";
export const progDataAreaMstID = "PROGRAM_DATA_AREA";
export const visitSchedAreaMstID = "VISIT_SCHEDULES_AREA";

/* Methods */

export const pillMethodMstID = "ARVS";
export const ringsMethodMstID = "RING";
export const CAB_LAMethodMstID = "CAB-LA";
export const allMethodsCombined = "ALL_METHODS_COMBINED";

/* Impact - default import priority populations (DIPP) */

export const SDC_DIPPMstID = "IMP_SDC"; //serodiscordent couples
export const FSW_DIPPMstID = "IMP_FSW"; //female sex workers
export const MSM_DIPPMstID = "IMP_MSM"; //men sex with men
export const AGYW_15_19_DIPPMstID = "IMP_AGYW_15_19"; //women 15 to 19
export const AGYW_18_19_DIPPMstID = "IMP_AGYW_18_19"; //women 18 to 19
export const AGYW_20_24_DIPPMstID = "IMP_AGYW_20_24"; //women 20 to 24
export const AGYW_15_24_DIPPMstID = "IMP_AGYW_15_24"; //women 15 to 24
export const TW_DIPPMstID = "IMP_TW"; //Trans women
export const PWID_DIPPMstID = "IMP_PWID"; //people who inject drugs
export const PW_DIPPMstID = "IMP_PW"; //pregnant women
export const BFW_DIPPMstID = "IMP_BFW"; //breast feeding women
export const GPW_DIPPMstID = "IMP_GPW"; //general population women
export const MRW_DIPPMstID = "IMP_MRW"; //Medium risk women
export const GPM_DIPPMstID = "IMP_GPM"; //General population men
export const MRM_DIPPMstID = "IMP_MRM"; //Medium risk men
export const HRM_DIPPMstID = "IMP_HRM"; //High risk men

/* AGYW - Threshold criterion */

export const totalCostSavingsAGYWMstID = "TOTAL_COST_SAVINGS";
export const PrEPCostInfAvtdAGYWMstID = "COST_PER_INF_AVERTED";

/* General master IDs for several ModVars */

export const yesMstID = "YES";
export const noMstID = "NO";

/* AGYW option */

export const setTargsNatAGYWMstID = "SET_TARGS_NATIONAL";
export const useSubnatGeoPriorAGYWMstID = "USE_SUBNAT_GEO_PRIOR";

/* Disaggregate targets - PrEP status */

export const PrEP_NEW_StatusMstID = "INITIATION_TARGET";
export const PrEP_CT_StatusMstID = "CURR_ON_PREP_TARGET";

/* Disaggregate targets - Priority populations included by age/sex age groups */

export const age15t19PPIASMstID = "15-19";
export const age20t24PPIASMstID = "20-24";
export const age25t29PPIASMstID = "25-29";
export const age30t34PPIASMstID = "30-34";
export const age35t39PPIASMstID = "35-39";
export const age40t44PPIASMstID = "40-44";
export const age45t49PPIASMstID = "45-49";
export const age50t54PPIASMstID = "50-54";
export const age55t59PPIASMstID = "55-59";
export const age60t64PPIASMstID = "60-64";
export const age50PlusPPIASMstID = "50 plus"; // Defunct. Used to stop at 45-49, then 50 plus.
export const age65PlusPPIASMstID = "65 plus";

/* AGYW selected area */

export const AGYW_NationalAreaMstID = "NATIONAL";
export const AGYW_RegionalAreaMstID = "REGIONAL";
export const AGYW_DistrictAreaMstID = "DISTRICT";

/* AGYW - Source for cost per person-year on PrEP */

export const AGYW_PerPYPrEPFromCostingSrcMstID = "AGYW_PER_PY_PREP_FROM_COSTING";
export const AGYW_EnterPerPYPrEPSrcMstID = "AGYW_ENTER_PER_PY";

/* AGYW - Cost per person year on ART entry type */

export const AGYW_SingleCostPerPerPYARTMstID = "AGYW_SINGLE_COST_PER_PY_ART";
export const AGYW_CostPerPerPYByAreaARTMstID = "AGYW_COST_PER_PY_BY_AREA";

/* Easy Start options */

export const setTargsBasedCovESMstID = "SET_TARGS_BASED_COV_ES"; // Q2a
export const enterExistTargsManESMstID = "ENTER_EXIST_TARGS_MAN_ES"; // Q2b
export const doNotSetTargsESMstID = "DO_NOT_SET_TARGS_ES"; // Q2c
export const uploadClientsInitESMstID = "UPLOAD_CLIENTS_INIT_ES"; // Q2sub
export const AGYWEverywhereESMstID = "AGYW_EVERYWHERE_ES"; // Q3
export const AGYWHotspotsESMstID = "AGYW_HOTSPOTS_ES"; // Q3
export const AGYWNotIncludedESMstID = "AGYW_NOT_INCL_ES"; // Q3
export const estCostImpactsESMstID = "EST_COST_IMPACTS_ES"; // Q4
export const estImpactTargsESMstID = "EST_IMPACT_TARGS_ES"; // Q4
//export const estCostEffTargsESMstID       = "EST_COST_EFF_TARGS_ES";        // Q4
export const disaggTargsSubnatESMstID = "DISAGG_TARGS_SUBNAT_ES"; // Q4
export const forecastPrevProdNeedsESMstID = "FORECAST_PREV_PROD_NEEDS_ES"; // Q4
export const analyzePerPersCostsESMstID = "ANALYZE_PER_PERS_COSTS_ES"; // Q3aAlt

/*********************************************************************
 *
 *    Warnings
 *
 *********************************************************************/

export const targetStartDateWarning = "targetStartDateWarning";

export const responseSuccessful = "Success";
