import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";

import PIAnnualUnitCostsLiteResTable from "../Tables/Results/PIAnnualUnitCostsLiteResTable";

import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";

class PICostsLiteResultContent extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pip.showMethodComboBox]: PropTypes.bool,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pip.showMethodComboBox]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  isSettingTargets() {
    const easyStartOptions = piasu.getModVarValue(this.props.modVarObjList, pisc.easyStartOptionsMVTag);
    return !piasu.easyStartModeOptionOn(easyStartOptions, pisc.doNotSetTargsESMstID);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderContent = () => {
    const props = this.props;
    const modVarObjList = props.modVarObjList;
    const onModVarsChange = props[pias.onModVarsChange];
    const showMethodComboBox = props[pip.showMethodComboBox];

    const numMethods = piasu.getTotalNumMethods(modVarObjList);

    return (
      <>
        {showMethodComboBox && numMethods > 1 && (
          <PIMethodComboBox
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        )}

        <p
          style={{
            marginTop: 30,
            marginRight: 20,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAnnUnitCostsExcDeliveryEtc)}
        </p>
        <PIAnnualUnitCostsLiteResTable modVarObjList={modVarObjList} />

        {/* TODO: Temporarily hidden this table for prod release until the following card is resolved
            https://trello.com/c/gXtL0dHi/400-how-to-calculate-unit-costs-including-lump-sum-and-above-site-costs
          */}
        {false && this.isSettingTargets() && (
          <>
            <p
              style={{
                marginTop: 30,
                marginRight: 20,
                ...Theme.labelStyle,
              }}
            >
              {RS("GB_stAnnUnitCostsInclDeliveryEtc")}
            </p>

            <PIAnnualUnitCostsLiteResTable modVarObjList={modVarObjList} includeAboveSite />
          </>
        )}
      </>
    );
  };

  render() {
    try {
      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PICostsLiteResultContent;
