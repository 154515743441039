import * as PropTypes from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as piu from "../NonComponents/PIUtil";
import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync, calcImpactFactorsAsync } from "../NonComponents/PICalc";
import { shiftMethods } from "../NonComponents/PIModVars/PIMethods";

import { methodConfigTab, methodEligTab } from "../Forms/PIItemsForm";

// Reset PI_Methods values for each method that exists in defModVars
async function resetMethodConfiguration(defModVars, modVarObjList, origModVarObjArr) {
  const modVars = structuredClone(modVarObjList);

  const methods = piasu.getModVarValue(modVars, "PI_Methods");
  const defMethods = piasu.getModVarValue(defModVars, "PI_Methods");

  piasu.resetImpactEffectiveness(defModVars, modVars);
  await calcImpactFactorsAsync(modVars);

  // Restore names/values for any non custom methods
  const newMethods = methods.map((v) => {
    const ret = defMethods.find((m) => m.mstID === v.mstID);
    if (ret === undefined) return v;

    return {
      ...ret,
      name: piu.getDefMethodNameFromMstID(v.mstID),
      unitOfCommodity: piu.getDefMethodUnitOfCommodityFromMstID(v.mstID),
    };
  });

  piasu.setModVarValue(modVars, "PI_Methods", newMethods);

  // re-add deleted default methods in original location
  for (const [defMethodIdx, defMethod] of defMethods.entries()) {
    if (defMethod.mstID.includes(pisc.customItemMstID) || newMethods.find((m) => m.mstID === defMethod.mstID)) continue;

    // NOTE: Mutates modVars by ref using newMethods...
    newMethods.splice(defMethodIdx, 0, {
      ...structuredClone(defMethod),
      name: piu.getDefMethodNameFromMstID(defMethod.mstID),
      unitOfCommodity: piu.getDefMethodUnitOfCommodityFromMstID(defMethod.mstID),
    });
    shiftMethods(modVars, origModVarObjArr, defMethodIdx + 1, pic.addItem);
  }

  return modVars;
}

// Reset PI_Eligibility for each method/pop that still exists in defModVars
function resetAssignMethods(defModVars, modVarObjList) {
  const modVars = structuredClone(modVarObjList);

  const defEligibility = piasu.getModVarValue(defModVars, "PI_Eligibility");
  const eligibility = structuredClone(piasu.getModVarValue(modVarObjList, "PI_Eligibility"));

  for (const method of eligibility) {
    if (method.mstID.includes(pisc.customItemMstID)) continue;

    for (const pop of method.value) {
      const defPop = defEligibility.find((m) => m.mstID === method.mstID)?.value.find((p) => p.mstID === pop.mstID);
      if (defPop === undefined) {
        console.error(`Unable to reset to defaults. Missing method/population ${method.mstID}/${pop.mstID}.`);
        return modVarObjList;
      }

      pop.value = defPop.value;
    }
  }

  piasu.setModVarValue(modVars, "PI_Eligibility", eligibility);

  return modVars;
}

const PIResetDefaultMethodCostsBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const onResetClick = async () => {
    try {
      props.onCalculatingChange(true);

      let modVarObjList = null;

      const isPSEMode = piasu.isPSEMode(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const countryCode = piasu.getModVarValue(props.modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode, pse: isPSEMode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      switch (props.activeTabIdx) {
        case methodConfigTab:
          modVarObjList = await resetMethodConfiguration(defModVars, props.modVarObjList, props.origModVarObjArr);
          break;
        case methodEligTab:
          modVarObjList = resetAssignMethods(defModVars, props.modVarObjList);
          break;
        default:
          console.error(`Reset to defaults not supported for tab: ${props.activeTabIdx}`);
      }

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, false);
    } catch (err) {
      console.log(err);
    } finally {
      props.onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"resetMethodsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetDefaultMethodCostsBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  origModVarObjArr: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
  activeTabIdx: PropTypes.number,
};

PIResetDefaultMethodCostsBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetDefaultMethodCostsBtn;
