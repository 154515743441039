import React from "react";
import * as PropTypes from "prop-types";
import { AvenirDatePropType } from "../NonComponents/PIUtil";

import * as Theme from "../../../app/Theme";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as pias from "../NonComponents/PIAppState";
import * as piu from "../NonComponents/PIUtil";

import TComboBox from "../../common/TComboBox";

const monthNames = [
  SC.GB_stJanuary,
  SC.GB_stFebruary,
  SC.GB_stMarch,
  SC.GB_stApril,
  SC.GB_stMay,
  SC.GB_stJune,
  SC.GB_stJuly,
  SC.GB_stAugust,
  SC.GB_stSeptember,
  SC.GB_stOctober,
  SC.GB_stNovember,
  SC.GB_stDecember,
];

/**
 * @typedef {import('../NonComponents/PIUtil').AvenirDateRangeObject} AvenirDateRangeObject
 *
 * @typedef {object} SelectedDate
 * @property {number} month
 * @property {number} year
 *
 * @typedef {object} PISelectDateProps
 * @property {SelectedDate} selectedDate
 * @property {AvenirDateRangeObject} dateRange
 * @property {() => (date: SelectedDate, info: any)} onDateChange
 * @property {any} info
 * @property {(dialogObj: any) => void} onDialogChange
 * @property {string} outOfRangeMessage
 */

/**
 * @param {PISelectDateProps} props
 * @returns
 */
const PISelectDate = ({ selectedDate, dateRange, onDateChange, info, onDialogChange, outOfRangeMessage }) => {
  const months = monthNames.map((m) => RS(m));
  const years = new Array(dateRange.endYearInt - dateRange.startYearInt + 1)
    .fill(dateRange.startYearInt)
    .map((y, i) => String(y + i));

  /**
   *
   * @param {string} message
   */
  function showErrorDialog(message) {
    if (onDialogChange === undefined) return;

    let dialogObj = pias.getDefaultDialogObj();
    dialogObj[pias.contentStr] = message;
    dialogObj[pias.headerStr] = RS(SC.GB_stError);
    dialogObj[pias.maxWidthStr] = "sm";
    dialogObj[pias.showBool] = true;
    dialogObj[pias.styleObj] = { width: 500 };

    onDialogChange(dialogObj);
  }

  /**
   *
   * @param {SelectedDate} date
   */
  const onChange = (date) => {
    // Validate date first
    const { start, end } = piu.getDateObjectAsJSDates(dateRange);
    const d = new Date(date.year, date.month - 1);
    if (outOfRangeMessage && (d < start || d > end)) {
      showErrorDialog(outOfRangeMessage);
      return;
    }

    // Call onDateChange
    if (onDateChange) onDateChange(date, info);
  };

  const onMonthChange = (month) => {
    onChange({ month: month + 1, year: selectedDate.year }, info);
  };
  const onYearChange = (year) => {
    onChange({ month: selectedDate.month, year: parseInt(years[year]) }, info);
  };

  const yearIdx =
    Math.max(dateRange.startYearInt, Math.min(selectedDate.year, dateRange.endYearInt)) - dateRange.startYearInt;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <TComboBox
        onChange={onMonthChange}
        items={months}
        itemIndex={Math.max(0, Math.min(selectedDate.month - 1, months.length - 1))}
        style={{
          fontFamily: Theme.fontFamily,
        }}
        outerStyle={{ flex: "2 1 auto" }}
        width={"100%"}
      />
      <TComboBox
        onChange={onYearChange}
        items={years}
        itemIndex={yearIdx}
        style={{
          fontFamily: Theme.fontFamily,
        }}
        outerStyle={{ flex: "1 0 auto" }}
        width={"100%"}
      />
    </div>
  );
};

PISelectDate.propTypes = {
  selectedDate: PropTypes.shape({
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  }).isRequired,
  dateRange: AvenirDatePropType,
  outOfRangeMessage: PropTypes.string,
  info: PropTypes.any,
  onDateChange: PropTypes.func,
  onDialogChange: PropTypes.func,
};

export default PISelectDate;
