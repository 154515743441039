import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as pic from "./PIConst";
import * as pip from "./PIProps";
import * as pisc from "./PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import * as piv from "./PIValidate";

import { v4 as uuidv4 } from "uuid";

import { getModVarValue, setModVarValue } from "./PIModVars/PICommon";
import {
  setDefMethodNames,
  getDefMethodActive,
  setDefMethodActive,
  methodName,
  methodMstID,
  getMethodCustom,
  getTotalNumMethods,
  getMethodCurrID,
  getNumCustomMethods,
  getCustomMethodsCurrIDArray,
  addCustomMethod,
  deleteCustomMethods,
  moveCustomMethods,
  clearMethods,
} from "./PIModVars/PIMethods";

// REVIEW: Re-exported in order to maintain AppStateUtil public interface until
// refactor complete and all client code is switched over to using imports directly.
export * from "./PIModVars/PICommon";
export * from "./PIModVars/PIMethods";

/*********************
 *
 * Automated testing would be nice
 *
 * What would a good automated testing suite do?
 *
 * 1. Uncheck all default items, then recheck the second one (or first one if there is no second).
 * 2. Add three items.  Reverse their order, delete the second one.
 * 3. Do this for all methods, then all user-editable items, then reverse this. After each list is completely changed,
 *    you should calculate in between.
 *
 * Each time one of the above is done, you should print out a pass/fail status.  If fail, stop, since failures can
 * compound.
 *
 * It would be ever nicer if the server handled all add/delete transactions.
 *
 ***************************/

export function updateTableKeys(tableKeyObj) {
  /* Unfortunately, we need to update the table keys at the exact same time as the
       ModVars and any state the tables need or the tables will not work. The only reason we have separate table keys for
       the various tables is in case we're showing more than one table at a time that requires a
       tracked table key (such as a table with comboboxes or one that changes cell selection or
       cell focus. */

  tableKeyObj[pias.contCurvePercOnPrEPTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignContCurvesToPriorPopsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.persMinDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignMinPatToStratDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignSchedToStratDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignVisitToSchedDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignTrendsPopsDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.scaleUpTrendsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignContSchedToPriorPopLiteTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignContVisitsToSchedLiteTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.AGYWGeoPriorityTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.customItemTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.percReachedDetTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.covConstraintsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.optionsTargTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.assignImpPriorPopsToPriorPopsTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.disagPriorPopTableKey] = gbu.createUniqueKey();
  tableKeyObj[pias.PrEPEfficacyAGYWTableKey] = gbu.createUniqueKey();
}

/* Use this to initialize things in ModVars such as default strings after getting the
   the user's data (ModVars) from the server. Strings are all kept on the client,
   so the server can't initialize them. */
export function setUserDefaults(modVarObjList, origModVarObjArr, forceMethodsOff, successFn) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  setDefPriorPopNames(priorPopObjList);
  setModVarValue(modVarObjList, pisc.priorPopsMVTag, priorPopObjList);

  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
  setDefContCurveNames(contCurveObjList);
  setModVarValue(modVarObjList, pisc.continuationCurvesMVTag, contCurveObjList);

  let scaleUpTrendsObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
  setDefScaleUpTrendNames(scaleUpTrendsObjList);
  setModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag, scaleUpTrendsObjList);

  /* Do this before continuation visits so they can use the method object array. */
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);
  setDefMethodNames(methodObjList);
  setModVarValue(modVarObjList, pisc.methodsMVTag, methodObjList);

  let contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
  //setDefContVisitSchedNames(methodObjList, contVisitSchedLiteObjList);
  setDefContVisitSchedNames(contVisitSchedLiteObjList);
  setModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag, contVisitSchedLiteObjList);

  gbu.safeCallFn(successFn);
}

export function getDefItemActive(itemTypeByte, itemObjList, mstID, mstID2) {
  let activeBool;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      activeBool = getDefPriorPopActive(itemObjList, mstID);
      break;

    case pic.contVisitSchedItems:
      //            activeBool = getDefContVisitSchedActive(mstID2, itemObjList, mstID);
      activeBool = getDefContVisitSchedActive(itemObjList, mstID);
      break;

    case pic.contCurveItems:
      activeBool = getDefContCurveActive(itemObjList, mstID);
      break;

    case pic.scaleUpTrendItems:
      activeBool = getDefScaleUpTrendActive(itemObjList, mstID);
      break;

    case pic.methodItems:
      activeBool = getDefMethodActive(itemObjList, mstID);
      break;

    default:
      activeBool = 0;
      break;
  }

  return activeBool;
}

export function setDefItemActive(itemTypeByte, modVarObjList, origModVarObjArr, mstID, activeBool, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      setDefPriorPopActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.contVisitSchedItems:
      //setDefContVisitSchedActive(modVarObjList, origModVarObjArr, mstID2, mstID, activeBool);
      setDefContVisitSchedActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.contCurveItems:
      setDefContCurveActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.scaleUpTrendItems:
      setDefScaleUpTrendActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    case pic.methodItems:
      setDefMethodActive(modVarObjList, origModVarObjArr, mstID, activeBool);
      break;

    default:
      break;
  }
}

export function clearItems(itemTypeByte, itemObjList, origModVarObjArr, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      clearPriorPops(itemObjList, origModVarObjArr);
      break;

    case pic.contVisitSchedItems:
      // clearContVisitSchedules(itemObjList, origModVarObjArr, mstID2);
      clearContVisitSchedules(itemObjList, origModVarObjArr);
      break;

    case pic.contCurveItems:
      clearContCurves(itemObjList, origModVarObjArr);
      break;

    case pic.scaleUpTrendItems:
      clearScaleUpTrends(itemObjList, origModVarObjArr);
      break;

    case pic.methodItems:
      clearMethods(itemObjList, origModVarObjArr);
      break;

    default:
      break;
  }
}

export function getItemModVarValue(itemTypeByte, modVarObjList) {
  let value;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      value = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      break;

    case pic.contVisitSchedItems:
      const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);
      value = getModVarValue(modVarObjList, modVarTag);
      break;

    case pic.contCurveItems:
      value = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
      break;

    case pic.scaleUpTrendItems:
      value = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
      break;

    case pic.methodItems:
      value = getModVarValue(modVarObjList, pisc.methodsMVTag);
      break;

    default:
      break;
  }

  return value;
}

export function setItemModVarValue(itemTypeByte, modVarObjList, value) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      setModVarValue(modVarObjList, pisc.priorPopsMVTag, value);
      break;

    case pic.contVisitSchedItems:
      const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);
      setModVarValue(modVarObjList, modVarTag, value);
      break;

    case pic.contCurveItems:
      setModVarValue(modVarObjList, pisc.continuationCurvesMVTag, value);
      break;

    case pic.scaleUpTrendItems:
      setModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag, value);
      break;

    case pic.methodItems:
      setModVarValue(modVarObjList, pisc.methodsMVTag, value);
      break;

    default:
      break;
  }
}

export function getTotalNumItems(itemTypeByte, itemObjList, mstID2) {
  let numItemsByte;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      numItemsByte = getTotalNumPriorPops(itemObjList);
      break;

    case pic.contVisitSchedItems:
      //numItemsByte = getTotalNumContVisitSchedules(mstID2, itemObjList);
      numItemsByte = getTotalNumContVisitSchedules(itemObjList);
      break;

    case pic.contCurveItems:
      numItemsByte = getTotalNumContCurves(itemObjList);
      break;

    case pic.scaleUpTrendItems:
      numItemsByte = getTotalNumScaleUpTrends(itemObjList);
      break;

    case pic.methodItems:
      numItemsByte = getTotalNumMethods(itemObjList);
      break;

    default:
      numItemsByte = 0;
      break;
  }

  return numItemsByte;
}

export function getItemName(itemTypeByte, itemObjList, currID, mstID2) {
  let itemNameStr;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemNameStr = getPriorPopName(itemObjList, currID);
      break;

    case pic.contVisitSchedItems:
      //itemNameStr = getContVisitSchedName(mstID2, itemObjList, currID);
      itemNameStr = getContVisitSchedName(itemObjList, currID);
      break;

    case pic.contCurveItems:
      itemNameStr = getContCurveName(itemObjList, currID);
      break;

    case pic.scaleUpTrendItems:
      itemNameStr = getScaleUpTrendName(itemObjList, currID);
      break;

    case pic.methodItems:
      itemNameStr = methodName(itemObjList, currID);
      break;

    default:
      itemNameStr = "";
      break;
  }

  return itemNameStr;
}

export function setItemName(itemTypeByte, itemObjList, currID, nameStr, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      setPriorPopName(itemObjList, currID, nameStr);
      break;

    case pic.contVisitSchedItems:
      // setContVisitSchedName(mstID2, itemObjList, currID, nameStr);
      setContVisitSchedName(itemObjList, currID, nameStr);
      break;

    case pic.contCurveItems:
      setContCurveName(itemObjList, currID, nameStr);
      break;

    case pic.scaleUpTrendItems:
      setScaleUpTrendName(itemObjList, currID, nameStr);
      break;

    case pic.methodItems:
      methodName(itemObjList, currID, nameStr);
      break;

    default:
      break;
  }
}

export function getItemCustom(itemTypeByte, itemObjList, currID, mstID2) {
  let itemCustomBool;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemCustomBool = getPriorPopCustom(itemObjList, currID);
      break;

    case pic.contVisitSchedItems:
      //itemCustomBool = getContVisitSchedCustom(mstID2, itemObjList, currID);
      itemCustomBool = getContVisitSchedCustom(itemObjList, currID);
      break;

    case pic.contCurveItems:
      itemCustomBool = getContCurveCustom(itemObjList, currID);
      break;

    case pic.scaleUpTrendItems:
      itemCustomBool = getScaleUpTrendCustom(itemObjList, currID);
      break;

    case pic.methodItems:
      itemCustomBool = getMethodCustom(itemObjList, currID);
      break;

    default:
      itemCustomBool = false;
      break;
  }

  return itemCustomBool;
}

export function getCustomItemCurrIDArray(itemTypeByte, itemObjList, mstID2) {
  let itemCurrIDArray;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemCurrIDArray = getCustomPriorPopsCurrIDArray(itemObjList);
      break;

    case pic.contVisitSchedItems:
      //itemCurrIDArray = getCustomContVisitSchedsCurrIDArray(mstID2, itemObjList);
      itemCurrIDArray = getCustomContVisitSchedsCurrIDArray(itemObjList);
      break;

    case pic.contCurveItems:
      itemCurrIDArray = getCustomContCurvesCurrIDArray(itemObjList);
      break;

    case pic.scaleUpTrendItems:
      itemCurrIDArray = getCustomScaleUpTrendsCurrIDArray(itemObjList);
      break;

    case pic.methodItems:
      itemCurrIDArray = getCustomMethodsCurrIDArray(itemObjList);
      break;

    default:
      itemCurrIDArray = [];
      break;
  }

  return itemCurrIDArray;
}

export function getNumCustomItems(itemTypeByte, itemObjList, mstID2) {
  let numItemsByte;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      numItemsByte = getNumCustomPriorPops(itemObjList);
      break;

    case pic.contVisitSchedItems:
      numItemsByte = getNumCustomContVisitScheds(itemObjList);
      break;

    case pic.contCurveItems:
      numItemsByte = getNumCustomContCurves(itemObjList);
      break;

    case pic.scaleUpTrendItems:
      numItemsByte = getNumCustomScaleUpTrends(itemObjList);
      break;
    case pic.methodItems:
      numItemsByte = getNumCustomMethods(itemObjList);
      break;

    default:
      numItemsByte = 0;
      break;
  }

  return numItemsByte;
}

export function addCustomItem(itemTypeByte, modVarObjList, origModVarObjArr, itemToAddAfterCurrID, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      addCustomPriorPop(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.contVisitSchedItems:
      //addCustomContVisitSched(modVarObjList, origModVarObjArr, mstID2, itemToAddAfterCurrID);
      addCustomContVisitSched(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.contCurveItems:
      addCustomContCurve(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.scaleUpTrendItems:
      addCustomScaleUpTrend(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    case pic.methodItems:
      addCustomMethod(modVarObjList, origModVarObjArr, itemToAddAfterCurrID);
      break;

    default:
      break;
  }
}

export function deleteCustomItems(itemTypeByte, modVarObjList, origModVarObjArr, itemCurrID1DIntArray, mstID2) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      deleteCustomPriorPops(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.contVisitSchedItems:
      //deleteCustomContVisitScheds(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray);
      deleteCustomContVisitScheds(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.contCurveItems:
      deleteCustomContCurves(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.scaleUpTrendItems:
      deleteCustomScaleUpTrends(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    case pic.methodItems:
      deleteCustomMethods(modVarObjList, origModVarObjArr, itemCurrID1DIntArray);
      break;

    default:
      break;
  }
}

export function moveCustomItems(
  itemTypeByte,
  modVarObjList,
  origModVarObjArr,
  itemCurrID1DIntArray,
  direction,
  mstID2
) {
  switch (itemTypeByte) {
    case pic.priorPopItems:
      moveCustomPriorPops(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.contVisitSchedItems:
      //moveCustomContVisitSchedules(modVarObjList, origModVarObjArr, mstID2, itemCurrID1DIntArray, direction);
      moveCustomContVisitSchedules(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.contCurveItems:
      moveCustomContCurves(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.scaleUpTrendItems:
      moveCustomScaleUpTrends(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    case pic.methodItems:
      moveCustomMethods(modVarObjList, origModVarObjArr, itemCurrID1DIntArray, direction);
      break;

    default:
      break;
  }
}

/* Generic methods for editable item lists */

export function getItemObj(modVarsObj, itemTypeByte, mstIDStr, mstIDStr2) {
  let itemObj = null;

  let modVarValue;
  /* Entire "modvars" array from server, assumed to have a single ModVar inside. */
  if (typeof modVarsObj[pisc.modVars] !== "undefined") {
    const modVarObj = modVarsObj[pisc.modVars][0];
    modVarValue = modVarObj[pisc.mvValue];
  } else {
    /* "value" field from a single ModVar */
    modVarValue = modVarsObj;
  }

  let i = 0;
  let stop = false;

  const mstIDField = piu.getItemMstIDField(itemTypeByte);

  if (piu.itemVariesByMethod(itemTypeByte)) {
    while (i < modVarValue.length && !stop) {
      const methodObj = modVarValue[i];

      if (methodObj[piu.getItemObjMethodMstIDField(itemTypeByte)] === mstIDStr2) {
        const itemObjArr = methodObj[piu.getItemObjArrField(itemTypeByte)];

        let j = 0;

        while (j < itemObjArr.length && !stop) {
          if (itemObjArr[j][piu.getItemObjMstIDField(itemTypeByte)] === mstIDStr) {
            itemObj = itemObjArr[j];
            stop = true;
          }

          j++;
        }
      }

      i++;
    }
  } else {
    while (i < modVarValue.length && !stop) {
      if (modVarValue[i][mstIDField] === mstIDStr) {
        itemObj = modVarValue[i];
        stop = true;
      }

      i++;
    }
  }

  return itemObj;
}

export function getItemMethodObj(methodMstIDStr, itemTypeByte, itemObjArr) {
  let methodObj = {};

  const methodMstIDField = piu.getItemMethodMstIDField(itemTypeByte);

  let i = 0;
  while (i < itemObjArr.length && gbu.isEmpty(methodObj)) {
    if (itemObjArr[i][methodMstIDField] === methodMstIDStr) {
      methodObj = itemObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getNumActiveDefItemsByMethod(methodMstIDStr, itemTypeByte, itemObjArr) {
  let numActiveDefItems = 0;

  const methodObj = getItemMethodObj(methodMstIDStr, itemTypeByte, itemObjArr);

  const itemObjArrField = piu.getItemObjArrField(itemTypeByte);
  const itemMstIDField = piu.getItemMstIDField(itemTypeByte);

  for (let i = 0; i < methodObj[itemObjArrField].length; i++) {
    let obj = methodObj[itemObjArrField][i];

    if (!obj[itemMstIDField].includes(pisc.customItemMstID)) {
      numActiveDefItems++;
    }
  }

  return numActiveDefItems;
}

/***********************************   Areas   **********************************/

export function getNumAreas(areaObjArr) {
  return areaObjArr.length;
}

export function getAreaMstID(areaObjArr, areaCurrID) {
  return areaObjArr[areaCurrID - 1][pisc.areaMstID];
}

export function setAreaMstID(areaObjArr, areaCurrID, valueStr) {
  areaObjArr[areaCurrID - 1][pisc.areaMstID] = valueStr;
}

export function areaCompleted(areaObjArr, areaMstID, valueBool) {
  let areaCompletedBool;

  const numAreas = getNumAreas(areaObjArr);

  let areaCurrID = 1;
  while (areaCurrID <= numAreas) {
    const areaMstIDL = getAreaMstID(areaObjArr, areaCurrID);

    if (areaMstID === areaMstIDL) {
      if (typeof valueBool !== "undefined") {
        areaObjArr[areaCurrID - 1][pisc.areaCompleted] = valueBool;
      } else {
        areaCompletedBool = areaObjArr[areaCurrID - 1][pisc.areaCompleted];
      }
    }

    areaCurrID++;
  }

  return areaCompletedBool;
}

/***********************************************************************************/
/**********************************   Easy Start   *********************************/
/***********************************************************************************/

export function getNumEasyStartModeOptions(easyStartModeOptionObjArr) {
  return easyStartModeOptionObjArr.length;
}

export function getEasyStartModeOptionMstID(easyStartModeOptionObjArr, easyStartModeOptionCurrID) {
  return easyStartModeOptionObjArr[easyStartModeOptionCurrID - 1][pisc.easyStartModeMstID];
}

export function setEasyStartModeOptionMstID(easyStartModeOptionObjArr, easyStartModeOptionCurrID, valueStr) {
  easyStartModeOptionObjArr[easyStartModeOptionCurrID - 1][pisc.easyStartModeMstID] = valueStr;
}

export function easyStartModeOptionOn(easyStartModeOptionObjArr, easyStartModeOptionMstID, valueBool) {
  let onBool;

  const numOptions = getNumEasyStartModeOptions(easyStartModeOptionObjArr);

  let optionCurrID = 1;
  while (optionCurrID <= numOptions) {
    const optionMstID = getEasyStartModeOptionMstID(easyStartModeOptionObjArr, optionCurrID);

    if (easyStartModeOptionMstID === optionMstID) {
      if (typeof valueBool !== "undefined") {
        easyStartModeOptionObjArr[optionCurrID - 1][pisc.easyStartModeValue] = valueBool;
      } else {
        onBool = easyStartModeOptionObjArr[optionCurrID - 1][pisc.easyStartModeValue];
      }
    }

    optionCurrID++;
  }

  return onBool;
}

/***********************************************************************************/
/*******************************   Configuration   *********************************/
/***********************************************************************************/

/********************************   Countries   ************************************/

export function getCountryIdx(countryObjList, countryCode) {
  let i = 0;
  let foundCountry = false;

  /* Make sure the countryCode is an integer - the server may send it
       as a string. */
  const countryCodeInt = parseInt(countryCode);

  while (i < countryObjList.length && !foundCountry) {
    let countryCodeL = countryObjList[i][pisc.countryCode];

    if (countryCodeInt === countryCodeL) {
      foundCountry = true;
    } else {
      i++;
    }
  }

  if (foundCountry) {
    return i;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getCountryName(countryObjList, countryCode) {
  let name = "";
  let i = 0;
  let stop = false;

  /* Make sure the countryCode is an integer - the server may send it
       as a string. */
  const countryCodeInt = parseInt(countryCode);

  while (i < countryObjList.length && !stop) {
    let countryCodeL = countryObjList[i][pisc.countryCode];

    if (countryCodeInt === countryCodeL) {
      name = countryObjList[i][pisc.countryName];
      stop = true;
    } else {
      i++;
    }
  }

  return name;
}

export function getCountryISO3(countryObjList, countryCode) {
  let name = "";
  let i = 0;
  let stop = false;

  /* Make sure the countryCode is an integer - the server may send it
       as a string. */
  const countryCodeInt = parseInt(countryCode);

  while (i < countryObjList.length && !stop) {
    let countryCodeL = countryObjList[i][pisc.countryCode];

    if (countryCodeInt === countryCodeL) {
      name = countryObjList[i][pisc.countryAlphaCode];
      stop = true;
    } else {
      i++;
    }
  }

  return name;
}

export function getCountryNames(countryObjList) {
  let names1DStrArray = [];

  for (let i = 0; i < countryObjList.length; i++) {
    let countryName = countryObjList[i][pisc.countryName];
    names1DStrArray.push(countryName);
  }

  return names1DStrArray;
}

export function getCountryMstIDs(countryObjList) {
  let mstIDs1DStrArray = [];

  for (let i = 0; i < countryObjList.length; i++) {
    let countryCode = countryObjList[i][pisc.countryCode];
    mstIDs1DStrArray.push(countryCode);
  }

  return mstIDs1DStrArray;
}

export function getCountrySelected(modVarObjList) {
  let countrySelectedBool = false;

  if (piv.getModVarsRetrieved(modVarObjList)) {
    let countryCodeInt = getModVarValue(modVarObjList, pisc.countryISOMVTag);

    if (typeof countryCodeInt !== "undefined") {
      /* Make sure the countryCode is an integer - the server may send it
               as a string. */
      countryCodeInt = parseInt(countryCodeInt);

      if (countryCodeInt !== pic.noCountrySelected) {
        countrySelectedBool = true;
      }
    }
  }

  return countrySelectedBool;
}

export function adminSubnatLevelName(level1DStrArr, levelCurrID, valueStr) {
  if (!level1DStrArr) {
    return undefined;
  }

  let value;

  if (typeof valueStr !== "undefined") {
    level1DStrArr[levelCurrID - 1] = valueStr;
  } else {
    value = level1DStrArr[levelCurrID - 1];
  }

  return value;
}

/****************   Program data - Program data period   ********************/

/**
 * @typedef {import('./PIUtil').AvenirDateRangeObject} AvenirDateRangeObject
 */

/** Constructs a single object representing a setting period info using
 * the appropriate modVars.
 * @param modVarObjList - Array of modvars
 * @param {string} startDateMVTag - ModVar TAG for period start date
 * @param {string} endDateMVTag - ModVar TAG for period end date
 * @returns {AvenirDateRangeObject} dateObj
 */
export function getDatesAsPeriodObj(modVarObjList, startDateMVTag, endDateMVTag) {
  const startDate = getModVarValue(modVarObjList, startDateMVTag);
  const endDate = getModVarValue(modVarObjList, endDateMVTag);

  return {
    startYearInt: startDate.year,
    startMonthInt: piu.getMonthFromMstID(startDate.month),
    endYearInt: endDate.year,
    endMonthInt: piu.getMonthFromMstID(endDate.month),
  };
}

/** Deconstructs a period date object into individual start/end ModVar date objects.
 * @param modVarObjList - Array of modvars
 * @param {AvenirDateRangeObject} periodObj
 * @param {string} startDateMVTag - Destination  ModVar TAG for period start date
 * @param {string} endDateMVTag - Destination ModVar TAG for period end date
 */
export function setModVarsFromPeriodObj(modVarObjList, periodObj, startDateMVTag, endDateMVTag) {
  setModVarValue(modVarObjList, startDateMVTag, {
    year: periodObj.startYearInt,
    month: piu.getMonthMstID(periodObj.startMonthInt),
  });

  setModVarValue(modVarObjList, endDateMVTag, {
    year: periodObj.endYearInt,
    month: piu.getMonthMstID(periodObj.endMonthInt),
  });
}

/* Constructs a single object containing all the program data setting period info using
   the appropriate modVars. */
export function getProgDataPeriodObj(modVarObjList) {
  return getDatesAsPeriodObj(modVarObjList, pisc.programStartDateMVTag, pisc.programRecentDateMVTag);
}

/* Deconstructs the program data setting period object and saves it back to the modVar. */
export function setProgDataPeriodObj(modVarObjList, progDataPeriodObj) {
  setModVarsFromPeriodObj(modVarObjList, progDataPeriodObj, pisc.programStartDateMVTag, pisc.programRecentDateMVTag);
}

export function getProgDataStartYear(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.startYearInt];
}

export function setProgDataStartYear(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.startYearInt] = valueInt;
}

export function getProgDataStartMonth(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.startMonthInt];
}

export function setProgDataStartMonth(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.startMonthInt] = valueInt;
}

export function getProgDataEndYear(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.endYearInt];
}

export function setProgDataEndYear(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.endYearInt] = valueInt;
}

export function getProgDataEndMonth(progDataSettingPeriodObj) {
  return progDataSettingPeriodObj[pip.endMonthInt];
}

export function setProgDataEndMonth(progDataSettingPeriodObj, valueInt) {
  progDataSettingPeriodObj[pip.endMonthInt] = valueInt;
}

/****************   Program data - Timeframe for indicators   ********************/

/* Constructs a single object containing all the timeframe for indicators period info using
   the appropriate modVars. */
export function getIndProgDataSettingPeriodObj(startYear, startMonth, endYear, endMonth) {
  /* Currently does not exist on server. */
  //const progStartDateObj = getModVarValue(modVarObjList, pisc.programStartDateMVTag);
  //const progEndDateObj = getModVarValue(modVarObjList, pisc.programRecentDateMVTag);

  let indProgDataSettingPeriodObj = {
    [pip.startYearInt]: startYear, // progStartDateObj[pisc.yearPSD],
    [pip.startMonthInt]: startMonth, // piu.getMonthFromMstID(progStartDateObj[pisc.monthPSD]),
    [pip.endYearInt]: endYear, // progEndDateObj[pisc.yearPRD],
    [pip.endMonthInt]: endMonth, // piu.getMonthFromMstID(progEndDateObj[pisc.monthPRD]),
  };

  return indProgDataSettingPeriodObj;
}

// /* Deconstructs the timeframe for indicators period object and saves it back to the modVar. */
// export function setIndProgDataSettingPeriodObj(modVarObjList, indProgDataSettingPeriodObj) {
//
//     let progStartDateObj = {
//         [pisc.yearPSD]  : indProgDataSettingPeriodObj[pip.startYearInt],
//         [pisc.monthPSD] : piu.getMonthMstID(indProgDataSettingPeriodObj[pip.startMonthInt]),
//     };
//
//     let progEndDateObj = {
//         [pisc.yearPRD]  : indProgDataSettingPeriodObj[pip.endYearInt],
//         [pisc.monthPRD] : piu.getMonthMstID(indProgDataSettingPeriodObj[pip.endMonthInt]),
//     };
//
//     setModVarValue(modVarObjList, pisc.programStartDateMVTag, progStartDateObj);
//     setModVarValue(modVarObjList, pisc.programRecentDateMVTag, progEndDateObj);
//
// }

export function getIndProgDataStartYear(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.startYearInt];
}

export function setIndProgDataStartYear(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.startYearInt] = valueInt;
}

export function getIndProgDataStartMonth(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.startMonthInt];
}

export function setIndProgDataStartMonth(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.startMonthInt] = valueInt;
}

export function getIndProgDataEndYear(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.endYearInt];
}

export function setIndProgDataEndYear(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.endYearInt] = valueInt;
}

export function getIndProgDataEndMonth(indProgDataSettingPeriodObj) {
  return indProgDataSettingPeriodObj[pip.endMonthInt];
}

export function setIndProgDataEndMonth(indProgDataSettingPeriodObj, valueInt) {
  indProgDataSettingPeriodObj[pip.endMonthInt] = valueInt;
}

/**********************   Targets - Target setting period   *************************/

/* Constructs a single object containing all the target setting period info using
   the appropriate modVars. */
export function getTargSettingPeriodObj(modVarObjList) {
  return getDatesAsPeriodObj(modVarObjList, pisc.targetStartDateMVTag, pisc.targetEndDateMVTag);
}

/* Deconstructs the target setting period object and saves it back to the modVar. */
export function setTargSettingPeriodObj(modVarObjList, targSettingPeriodObj) {
  setModVarsFromPeriodObj(modVarObjList, targSettingPeriodObj, pisc.targetStartDateMVTag, pisc.targetEndDateMVTag);
}

export function getTargStartYear(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.startYearInt];
}

export function setTargStartYear(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.startYearInt] = valueInt;
}

export function getTargStartMonth(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.startMonthInt];
}

export function setTargStartMonth(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.startMonthInt] = valueInt;
}

export function getTargEndYear(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.endYearInt];
}

export function setTargEndYear(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.endYearInt] = valueInt;
}

export function getTargEndMonth(targSettingPeriodObj) {
  return targSettingPeriodObj[pip.endMonthInt];
}

export function setTargEndMonth(targSettingPeriodObj, valueInt) {
  targSettingPeriodObj[pip.endMonthInt] = valueInt;
}

/*****************   Disaggregate targets - Reporting period   **********************/

/* Constructs a single object containing all the reporting period info using
   the appropriate modVars. */
export function getDateRangeDisplayObj(modVarObjList) {
  return getDatesAsPeriodObj(modVarObjList, pisc.dateRangeDisplayStartDateMVTag, pisc.dateRangeDisplayEndDateMVTag);
}

/* Deconstructs the date range for display object and saves it back to the modVar. */
export function setDateRangeDisplayObj(modVarObjList, dateRangeDisplayObj) {
  setModVarsFromPeriodObj(
    modVarObjList,
    dateRangeDisplayObj,
    pisc.dateRangeDisplayStartDateMVTag,
    pisc.dateRangeDisplayEndDateMVTag
  );
}

export function getDateRangeDisplayStartYear(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.startYearInt];
}

export function setDateRangeDisplayStartYear(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.startYearInt] = valueInt;
}

export function getDateRangeDisplayStartMonth(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.startMonthInt];
}

export function setDateRangeDisplayStartMonth(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.startMonthInt] = valueInt;
}

export function getDateRangeDisplayEndYear(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.endYearInt];
}

export function setDateRangeDisplayEndYear(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.endYearInt] = valueInt;
}

export function getDateRangeDisplayEndMonth(dateRangeDisplayObj) {
  return dateRangeDisplayObj[pip.endMonthInt];
}

export function setDateRangeDisplayEndMonth(dateRangeDisplayObj, valueInt) {
  dateRangeDisplayObj[pip.endMonthInt] = valueInt;
}

/* Assumes the timeframe objects all have the same fields. */
export function setTimeframeData(timeframeObj, monthOrYearInt, startOrEndInt) {
  /* Year change */
  if (monthOrYearInt > 12) {
    /* Start year */
    if (startOrEndInt === pic.start) {
      timeframeObj[pip.startYearInt] = monthOrYearInt;
    } else {
      /* End year */
      timeframeObj[pip.endYearInt] = monthOrYearInt;
    }
  } else {
    /* Month change */
    if (startOrEndInt === pic.start) {
      timeframeObj[pip.startMonthInt] = monthOrYearInt;
    } else {
      timeframeObj[pip.endMonthInt] = monthOrYearInt;
    }
  }
}

export function setTimePeriods(modVarObjList, periodByte, newMonthOrYearInt, startOrEndInt) {
  let progDataSettingPeriodObj = getProgDataPeriodObj(modVarObjList);
  let startYearPDInt = getProgDataStartYear(progDataSettingPeriodObj);
  let startMonthPDInt = getProgDataStartMonth(progDataSettingPeriodObj);
  let endYearPDInt = getProgDataEndYear(progDataSettingPeriodObj);
  let endMonthPDInt = getProgDataEndMonth(progDataSettingPeriodObj);

  let targSettingPeriodObj = getTargSettingPeriodObj(modVarObjList);
  let startYearTSInt = getTargStartYear(targSettingPeriodObj);
  let startMonthTSInt = getTargStartMonth(targSettingPeriodObj);
  let endYearTSInt = getTargEndYear(targSettingPeriodObj);
  let endMonthTSInt = getTargEndMonth(targSettingPeriodObj);

  let dateRangeDisplayObj = getDateRangeDisplayObj(modVarObjList);
  let startYearDRDInt = getDateRangeDisplayStartYear(dateRangeDisplayObj);
  let startMonthDRDInt = getDateRangeDisplayStartMonth(dateRangeDisplayObj);
  let endYearDRDInt = getDateRangeDisplayEndYear(dateRangeDisplayObj);
  let endMonthDRDInt = getDateRangeDisplayEndMonth(dateRangeDisplayObj);

  let targetChanged = false;
  let programChanged = false;
  let dateRangeDisplayChanged = false;

  /* Rules

           1. Both the target setting and program data periods should span from 2018 to
              the current year for their start year and 2018 to 2030 for their end year. The
              server will default the start year to 2018 and the final year to the current year + 1
              for both years.

           2. The end month + year must be at least one month farther into
              the future than the start month + start year.

              Since the start year comes before the end year, if it's changed, the end year may be
              changed to satisfy this condition. The first year cannot be changed in such a way to violate
              this rule.

           3. The target setting start period month + year must be at least one month
              farther into the future than the program data end month + year.

              Since the program data period comes first in the tool, if it's changed, the target setting
              period may be changed to satisfy this condition. The target setting period cannot
              be changed in such a way as to violate this rule.

    */

  /* Update the field that changed. */

  if (periodByte === pic.progDataPeriod) {
    /* If > 12, we're setting a year. Otherwise, we're setting a month. */
    if (newMonthOrYearInt > 12) {
      if (startOrEndInt === pic.start) {
        startYearPDInt = newMonthOrYearInt;
      } else {
        endYearPDInt = newMonthOrYearInt;
      }
    } else {
      if (startOrEndInt === pic.start) {
        startMonthPDInt = newMonthOrYearInt;
      } else {
        endMonthPDInt = newMonthOrYearInt;
      }
    }

    programChanged = true;
  } else if (periodByte === pic.targSetPeriod) {
    /* If > 12, we're setting a year. Otherwise, we're setting a month. */
    if (newMonthOrYearInt > 12) {
      if (startOrEndInt === pic.start) {
        startYearTSInt = newMonthOrYearInt;
      } else {
        endYearTSInt = newMonthOrYearInt;
      }
    } else {
      if (startOrEndInt === pic.start) {
        startMonthTSInt = newMonthOrYearInt;
      } else {
        endMonthTSInt = newMonthOrYearInt;
      }
    }

    targetChanged = true;
  } else if (periodByte === pic.dateRangeDisplayPeriod) {
    /* If > 12, we're setting a year. Otherwise, we're setting a month. */
    if (newMonthOrYearInt > 12) {
      if (startOrEndInt === pic.start) {
        startYearDRDInt = newMonthOrYearInt;
      } else {
        endYearDRDInt = newMonthOrYearInt;
      }
    } else {
      if (startOrEndInt === pic.start) {
        startMonthDRDInt = newMonthOrYearInt;
      } else {
        endMonthDRDInt = newMonthOrYearInt;
      }
    }

    dateRangeDisplayChanged = true;
  }

  /* Validate all periods on their own. */

  const newStartTimePD = new Date(startYearPDInt, startMonthPDInt - 1).getTime();
  const newEndTimePD = new Date(endYearPDInt, endMonthPDInt - 1).getTime();

  /* If the new end month + year comes before the new start month + year,
       change the new end month + year so that it is comes one month after it. */
  if (newEndTimePD < newStartTimePD) {
    if (startMonthPDInt === 12) {
      endMonthPDInt = 1;
      endYearPDInt = startYearPDInt + 1;
    } else {
      endMonthPDInt = startMonthPDInt + 1;
      endYearPDInt = startYearPDInt;
    }

    programChanged = true;
  }

  const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
  const newEndTimeTS = new Date(endYearTSInt, endMonthTSInt - 1).getTime();

  /* If the new end month + year comes before the new start month + year,
       change the new end month + year so that it is comes one month after it. */
  if (newEndTimeTS < newStartTimeTS) {
    if (startMonthTSInt === 12) {
      endMonthTSInt = 1;
      endYearTSInt = startYearTSInt + 1;
    } else {
      endMonthTSInt = startMonthTSInt + 1;
      endYearTSInt = startYearTSInt;
    }

    targetChanged = true;
  }

  const newStartTimeDRI = new Date(startYearDRDInt, startMonthDRDInt - 1).getTime();
  const newEndTimeDRI = new Date(endYearDRDInt, endMonthDRDInt - 1).getTime();

  /* If the new end month + year comes before the new start month + year,
       change the new end month + year so that it is the same. */
  if (newEndTimeDRI < newStartTimeDRI) {
    if (startMonthDRDInt === 12) {
      endMonthDRDInt = 1;
      endYearDRDInt = startYearDRDInt + 1;
    } else {
      endMonthDRDInt = startMonthDRDInt;
      endYearDRDInt = startYearDRDInt;
    }

    dateRangeDisplayChanged = true;
  }

  /* 8/24: Periods must now be validated with respect to each other. Rules:
   *
   *     1. If Program period changes, make sure
   *
   *       a) Target start > Program end.
   *       b) Target start <= Program end + 4.
   *
   *     2. If Target period changes, set Reporting period to Target period.
   *
   *  Also, when showing dropdowns:
   *
   *     1. Target start dropdown choices must be greater than or equal to Program end dropdown choices.
   *
   *     2. Reporting dropdown choices must be a subset of the Target dropdown choices.
   *
   * */

  /* If Program period changes, adjust Target period according to rules above. */

  if (programChanged) {
    const startTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
    const newEndTimePD = new Date(endYearPDInt, endMonthPDInt - 1).getTime();

    if (startTimeTS <= newEndTimePD) {
      if (endMonthPDInt === 12) {
        startMonthTSInt = 1;
        startYearTSInt = endYearPDInt + 1;
      } else {
        startMonthTSInt = endMonthPDInt + 1;
        startYearTSInt = endYearPDInt;
      }

      targetChanged = true;
    }

    if (targetChanged) {
      /* If the Target start is now greater than Target end, set the Target end after the Target start. */
      const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
      const endTimeTS = new Date(endYearTSInt, endMonthTSInt - 1).getTime();

      if (endTimeTS <= newStartTimeTS) {
        if (startMonthTSInt === 12) {
          endMonthTSInt = 1;
          endYearTSInt = startYearTSInt + 1;
        } else {
          endMonthTSInt = startMonthTSInt + 1;
          endYearTSInt = startYearTSInt;
        }
      }
    }

    let endMonthPDPlus5 = endMonthPDInt;
    let endYearPDIntPlus5 = endYearPDInt;

    if (endMonthPDInt <= 7) {
      endMonthPDPlus5 += 5;
    } else {
      endMonthPDPlus5 -= 7;
      endYearPDIntPlus5++;
    }

    const endTimePDPlus5 = new Date(endYearPDIntPlus5, endMonthPDPlus5 - 1).getTime();

    if (startTimeTS > endTimePDPlus5) {
      if (endMonthPDPlus5 === 1) {
        startMonthTSInt = 12;
        startYearTSInt = endYearPDIntPlus5 - 1;
      } else {
        startMonthTSInt = endMonthPDPlus5 - 1;
        startYearTSInt = endYearPDIntPlus5;
      }

      targetChanged = true;
    }
  }

  if (targetChanged) {
    startYearDRDInt = startYearTSInt;
    startMonthDRDInt = startMonthTSInt;
    endYearDRDInt = endYearTSInt;
    endMonthDRDInt = endMonthTSInt;

    /* If the Target start is less than or equal to the Program end, change the Target start
           to be greater than the Program end. */
    const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
    const newEndTimePD = new Date(endYearPDInt, endMonthPDInt - 1).getTime();

    if (newStartTimeTS <= newEndTimePD) {
      if (endMonthPDInt === 12) {
        startMonthTSInt = 1;
        startYearTSInt = endYearPDInt + 1;
      } else {
        startMonthTSInt = endMonthPDInt + 1;
        startYearTSInt = endYearPDInt;
      }
    }
  }

  /* If the reporting period was changed so that it falls outside of the target period, change
       the reporting period to be inside the target period. */
  if (dateRangeDisplayChanged) {
    const newStartTimeTS = new Date(startYearTSInt, startMonthTSInt - 1).getTime();
    const newEndTimeTS = new Date(endYearTSInt, endMonthTSInt - 1).getTime();

    const newStartTimeDRI = new Date(startYearDRDInt, startMonthDRDInt - 1).getTime();
    const newEndTimeDRI = new Date(endYearDRDInt, endMonthDRDInt - 1).getTime();

    if (newStartTimeDRI < newStartTimeTS) {
      startMonthDRDInt = startMonthTSInt;
      startYearDRDInt = startYearTSInt;
    }

    if (newStartTimeDRI > newEndTimeTS) {
      startMonthDRDInt = endMonthTSInt;
      startYearDRDInt = endYearTSInt;
    }

    if (newEndTimeDRI > newEndTimeTS) {
      endMonthDRDInt = endMonthTSInt;
      endYearDRDInt = endYearTSInt;
    }
  }

  setProgDataStartYear(progDataSettingPeriodObj, startYearPDInt);
  setProgDataStartMonth(progDataSettingPeriodObj, startMonthPDInt);
  setProgDataEndYear(progDataSettingPeriodObj, endYearPDInt);
  setProgDataEndMonth(progDataSettingPeriodObj, endMonthPDInt);
  setProgDataPeriodObj(modVarObjList, progDataSettingPeriodObj);

  setTargStartYear(targSettingPeriodObj, startYearTSInt);
  setTargStartMonth(targSettingPeriodObj, startMonthTSInt);
  setTargEndYear(targSettingPeriodObj, endYearTSInt);
  setTargEndMonth(targSettingPeriodObj, endMonthTSInt);
  setTargSettingPeriodObj(modVarObjList, targSettingPeriodObj);

  setDateRangeDisplayStartYear(dateRangeDisplayObj, startYearDRDInt);
  setDateRangeDisplayStartMonth(dateRangeDisplayObj, startMonthDRDInt);
  setDateRangeDisplayEndYear(dateRangeDisplayObj, endYearDRDInt);
  setDateRangeDisplayEndMonth(dateRangeDisplayObj, endMonthDRDInt);
  setDateRangeDisplayObj(modVarObjList, dateRangeDisplayObj);
}

/**
 * In place update PI_ProgramData where methods/years are available within
 * PI_ProgramDataUpload. Resyncs initiatedPrEP and reinitiatedPrEP.
 */
function _syncProgramDataFromUpload(modVarObjArr) {
  const templateUploaded = getModVarValue(modVarObjArr, pisc.progDataTemplateUploadedMVTag);

  const pdDateRange = piu.getDateObjectAsJSDates(
    getDatesAsPeriodObj(modVarObjArr, pisc.programStartDateMVTag, pisc.programRecentDateMVTag)
  );
  const pdUploadDateRange = piu.getDateObjectAsJSDates(
    getDatesAsPeriodObj(modVarObjArr, pisc.programStartDateUploadMVTag, pisc.programRecentDateUploadMVTag)
  );

  if (!templateUploaded || pdDateRange.start < pdUploadDateRange.start || pdDateRange.end > pdUploadDateRange.end) {
    // PDP outside upload range requires a new template upload
    setModVarValue(modVarObjArr, pisc.progDataTemplateUploadedMVTag, false);
    return;
  }

  // PDP within range of previously uploaded template date can be sync'd from upload modvars
  const sourcePD = getModVarValue(modVarObjArr, pisc.initiationUploadMVTag);
  const targetPD = getModVarValue(modVarObjArr, pisc.initiationMVTag);
  const sourcePriorPop = getModVarValue(modVarObjArr, pisc.priorPopsUploadMVTag);
  const targetPriorPop = getModVarValue(modVarObjArr, pisc.priorPopsMVTag);

  // Can only sync over data for methods/populations that exist in the original template upload (sourcePD)
  for (let methodIdx = 0; methodIdx < sourcePD.length; methodIdx++) {
    const sourceMethod = sourcePD[methodIdx];
    const targetMethod = targetPD.find((v) => v.mstID === sourcePD[methodIdx].mstID);

    if (targetMethod === undefined) continue;

    const sourceStartIDX = sourceMethod.program.findIndex(
      (v) => v.month === targetMethod.program[0].month && v.year === targetMethod.program[0].year
    );

    // Cannot sync data back if data ranges for methods do not match
    if (sourceStartIDX === -1) continue;

    for (let monthIdx = 0; monthIdx < targetMethod.program.length; ++monthIdx) {
      const sourceMonthIdx = sourceStartIDX + monthIdx;

      const sourceMonthData = sourceMethod.program[sourceMonthIdx];
      const targetMonthData = targetMethod.program[monthIdx];

      for (let sourcePriorPopIdx = 0; sourcePriorPopIdx < sourcePriorPop.length; ++sourcePriorPopIdx) {
        const targetPopIdx = targetPriorPop.findIndex((v) => v.mstID === sourcePriorPop[sourcePriorPopIdx].mstID);

        if (targetPopIdx === -1) continue;

        targetMonthData.initiatedPrEP[targetPopIdx] = sourceMonthData.initiatedPrEP[sourcePriorPopIdx];
        targetMonthData.reinitiatedPrEP[targetPopIdx] = sourceMonthData.reinitiatedPrEP[sourcePriorPopIdx];
      }
    }
  }
}

/**
 * In place update PI_ProgramData to hold correct number of year/month array items
 * based on current program data period range.
 * Newly created items will have data restored from PI_ProgramDataUpload where available.
 */
export function shiftProgDataYears(modVarObjArr) {
  const programDataMV = getModVarValue(modVarObjArr, pisc.initiationMVTag);

  const pdDateRange = piu.getDateObjectAsJSDates(
    getDatesAsPeriodObj(modVarObjArr, pisc.programStartDateMVTag, pisc.programRecentDateMVTag)
  );

  const numMonths = piu.getMonthsBetweenDates(pdDateRange.start, pdDateRange.end) + 1;

  const programYears = [];

  for (let i = 0; i < numMonths; ++i) {
    const currentDate = new Date(pdDateRange.start);
    currentDate.setMonth(currentDate.getMonth() + i);

    const month = piu.getMonthName(currentDate.getMonth() + 1).toUpperCase();
    const year = currentDate.getFullYear();

    const programYearsObj = structuredClone(programDataMV[0].program[0]);
    programYearsObj.year = year;
    programYearsObj.month = month;
    programYears.push(programYearsObj);
  }

  // No need to preserve current PI_ProgramData as all new items have 0 values and
  // previous items will be restored via _sync.
  const programData = [];

  for (let methodIdx = 0; methodIdx < programDataMV.length; methodIdx++) {
    programData.push({
      mstID: programDataMV[methodIdx].mstID,
      name: programDataMV[methodIdx].name,
      program: structuredClone(programYears),
    });
  }
  setModVarValue(modVarObjArr, pisc.initiationMVTag, programData);

  _syncProgramDataFromUpload(modVarObjArr);
}

/********************   Populations - Priority populations   **********************/

export function setDefPriorPopNames(priorPopObjList) {
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const priorPopMstID = getPriorPopMstID(priorPopObjList, pp);
    const priorPopName = getPriorPopName(priorPopObjList, pp);

    if (priorPopName === "") {
      setPriorPopName(priorPopObjList, pp, piu.getDefPriorPopNameFromMstID(priorPopMstID));
      setPriorPopAbbr(priorPopObjList, pp, piu.getDefPriorPopAbbrFromMstID(priorPopMstID));
    }
  }
}

export function setDefPSEKeyPopNames(pseKeyPops) {
  for (let p = 0; p < pseKeyPops.length; ++p) {
    pseKeyPops[p].name = piu.getDefPriorPopNameFromMstID(pseKeyPops[p].mstID);
  }
}

/* Determines if the user turned on (checked off) any priority populations. */
export function priorPopSelected(priorPopObjList) {
  return priorPopObjList.length > 0;
}

/* Default priority populations are active if they exist in the priority population object
   list. */
export function getDefPriorPopActive(priorPopObjList, priorPopMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < priorPopObjList.length && !activeBool) {
    let obj = priorPopObjList[i];

    if (obj[pisc.priorPopMstID] === priorPopMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

export function setDefPriorPopActive(modVarObjList, origModVarObjArr, priorPopMstIDStr, valueBool) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  const alreadyActive = getDefPriorPopActive(priorPopObjList, priorPopMstIDStr);
  const totalNumPriorPops = getTotalNumPriorPops(priorPopObjList);

  /* If we are activating the priority pop, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom priority pops should always be added after all default ones. */
    if (!alreadyActive) {
      const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);
      /* The original ModVar object array is cloned when the user clicks Add, but we need to
               clone again here because we'll be passing it along again and we want to pass the
               unadulterated version. */
      let itemObj = structuredClone(getItemObj(origPriorPopObjArr, pic.priorPopItems, priorPopMstIDStr, ""));
      itemObj[pisc.priorPopMstID] = priorPopMstIDStr;
      itemObj[pisc.priorPopName] = piu.getDefPriorPopNameFromMstID(priorPopMstIDStr);

      const numActiveDefPriorPops = getNumActiveDefPriorPops(priorPopObjList);

      /* If there are no priority pops, just push the default one onto the array. */
      if (totalNumPriorPops === 0) {
        priorPopObjList.push(itemObj);
        /* Add 1 since we just changed the number of priority pop objects. */
        shiftPriorPops(modVarObjList, origModVarObjArr, totalNumPriorPops + 1, pic.addItem);
      } else if (numActiveDefPriorPops === 0) {
        /* Otherwise, if there are no active default priority pops, add the
               default one to the front of the array. */
        priorPopObjList.splice(0, 0, itemObj);
        shiftPriorPops(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default priority pop. Place the default pop we are
               activating just before the first default priority population we encounter with a
               higher current ID. */
        const defPriorPopCurrID = piu.getDefPriorPopCurrID(priorPopMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumPriorPops && !stop) {
          const priorPopMstIDLoop = getPriorPopMstID(priorPopObjList, i + 1);

          if (!priorPopMstIDLoop.includes(pisc.customItemMstID)) {
            const defPriorPopCurrIDLoop = piu.getDefPriorPopCurrID(priorPopMstIDLoop);

            if (defPriorPopCurrID < defPriorPopCurrIDLoop) {
              priorPopObjList.splice(i, 0, itemObj);
              shiftPriorPops(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom priority population. If we're activating the last
                       default priority population, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom priority population we encounter. */
            priorPopObjList.splice(i, 0, itemObj);
            shiftPriorPops(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default priority pop yet, we must be adding the last one and there
                   must not be any custom priority populations.*/
        if (!stop) {
          priorPopObjList.push(itemObj);
          /* Add 1 since we just changed the number of priority pop objects. */
          shiftPriorPops(modVarObjList, origModVarObjArr, totalNumPriorPops + 1, pic.addItem);
        }
      }

      /* These modify the itemObj */
      adjustPriorPopContCurves(modVarObjList, origModVarObjArr, itemObj);
      adjustPriorPopScaleUpTrends(modVarObjList, itemObj);
      /* These use the itemObj but don't modfiy it. Must be called after the itemObj
               is added to the priority pop object array, since it is used within this method. */
      adjustPriorPopContVisitSchedules(modVarObjList, itemObj);
      adjustPriorPopImpFactors(modVarObjList, origModVarObjArr, itemObj);

      /* Adjust the continuation curves in the priority pop objects in case the user is re-checking a priority pop.*/
      let priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const methodsArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
      const numMethods = getTotalNumMethods(methodsArr);
      //const defPriorPopCurrID = piu.getDefPriorPopCurrID(priorPopMstIDStr);
      const priorPopCurrID = getPriorPopCurrID(priorPopObjList, priorPopMstIDStr);
      let contCurveObjArr = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

      for (let m = 1; m <= numMethods; m++) {
        const contCurveMstID = getPriorPopContCurveMstID(priorPopObjArr, m, priorPopCurrID);
        const contCurveCurrID = getContCurveCurrID(contCurveObjArr, contCurveMstID);

        if (contCurveCurrID === pic.itemDoesNotExist) {
          const newContCurveMstIDStr = getContCurveMstID(contCurveObjArr, 1);
          setPriorPopContCurveMstID(priorPopObjArr, m, priorPopCurrID, newContCurveMstIDStr);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumPriorPops && !stop) {
      let obj = priorPopObjList[i];

      if (obj[pisc.priorPopMstID] === priorPopMstIDStr) {
        priorPopObjList.splice(i, 1);
        shiftPriorPops(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts priority populations for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by priority population
   should be in the same order as the priority pop objects themselves. */
export function shiftPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID, instructionInt, priorPopToMoveCurrID) {
  /* ModVars to modify. */

  /****************************************************************************
   *
   *            Inputs
   *
   ****************************************************************************/

  /* Configuration - Methods, Eligibility */

  let priorPopMethodEligObjArr = getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

  /* Costs Lite - Costs tab */

  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);

  /* Targets - Population tab */

  let HIVPrev1DFltArray = getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
  let percIndicPrEP1DFltArray = getModVarValue(modVarObjList, pisc.percentForPrepMVTag);
  let percPrEPElig1DFltArray = getModVarValue(modVarObjList, pisc.percentPrepEligibleMVTag);
  let popSize1DFltArray = getModVarValue(modVarObjList, pisc.priorityPopSizeMVTag);

  /* Targets - Options tab */

  let potUsersToTakePrEP1DObjArray = getModVarValue(modVarObjList, pisc.coverageByPriorityPopMVTag);
  /* Sort of an input and result. */
  let actUsersToTakePrEP1DObjArray = getModVarValue(modVarObjList, pisc.covConstrActualMVTag);
  let targClientsInit1DObjArray = getModVarValue(modVarObjList, pisc.targetsByPriorityPopMVTag);

  /* Targets - Results tab */

  let targIndObj = getModVarValue(modVarObjList, pisc.targIndTableMVTag);

  /* Costs Lite - Continuation visit schedules tab inputs */

  /* Disaggregate targets - Disaggregation setup tab */

  let priorPopInclObj = getModVarValue(modVarObjList, pisc.priorPopAgeSexInclMVTag);

  /* Impact - Impact tab  */
  let adjFactor2DFltArr = getModVarValue(modVarObjList, pisc.adjustmentFactorMVTag);

  /* Method Mix */
  let methodMixObj = getModVarValue(modVarObjList, "PI_MethodMix");
  let methodMixCoverageObj = getModVarValue(modVarObjList, "PI_MethodMixCoverage");

  /****************************************************************************
   *
   *            Results
   *
   ****************************************************************************/

  /* Impact - Impact tab  */

  let impactInfAvtdConstantCoverage1DFltArr = getModVarValue(modVarObjList, pisc.impactInfAvtdConstantCoverageMVTag);
  let adjInfAvtdConstantCoverage1DFltArr = getModVarValue(modVarObjList, pisc.adjInfAvtdConstantCoverageMVTag);
  let persYrsPrEPAvtOneInfectConstantCoverage1DFltArr = getModVarValue(
    modVarObjList,
    pisc.persYrsPrEPAvtOneInfectConstantCoverageMVTag
  );

  /* Disaggregate targets - Results tab  */

  let initPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_NEW_MVTag);
  let onPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_CT_MVTag);

  /* Targets - Results tab */

  let totalCosts2DIntArray = getModVarValue(modVarObjList, pisc.costsByPopTypeMVTag);
  let totalInfAvtdConstantCoverage2DIntArray = getModVarValue(
    modVarObjList,
    pisc.infAvertedByPopTypeConstantCoverageMVTag
  );

  let totalInits1DIntArray = getModVarValue(modVarObjList, pisc.initByPopTypeMVTag);

  /* Costs Lite / Detailed costs - Reference drawer */

  let avgCostPrEPByMonthPP3DFltArr = getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthPTMVTag);

  /* Costs Lite / Detailed costs - Results tab */

  let costStayOnPrEPPP1DFltArr = getModVarValue(modVarObjList, pisc.costStayOnPrEPPTMVTag);
  let costPerPersonInitPP1DFltArr = getModVarValue(modVarObjList, pisc.costPerPersonInitPTMVTag);

  /* Server-only */

  let initiationObj = getModVarValue(modVarObjList, pisc.initiationMVTag);

  /* Not modifying these; just using them to help us below. */

  /* Current ModVars. */

  const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const districtPop1DObjArr = getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);

  /* Info we'll need from current ModVars. */

  /* Note: Unlike many other user-editable object arrays, priority populations do not vary
       by method. If one is added/deleted/moved, we need to loop over all methods. */
  const numMethods = getTotalNumMethods(methodObjArr);
  const numDistrictPops = getNumDistrictsDP(districtPop1DObjArr);

  const progDataSettingPeriodObj = getProgDataPeriodObj(modVarObjList);

  const numProgDataMonths = piu.getMonthsBetween(
    getProgDataStartMonth(progDataSettingPeriodObj),
    getProgDataStartYear(progDataSettingPeriodObj),
    getProgDataEndMonth(progDataSettingPeriodObj),
    getProgDataEndYear(progDataSettingPeriodObj)
  );

  /* Many ModVars are not placed in the modVarObjArr until calculations are run.
       Wrapping things in safeModify assures we only try to modify them if they are defined (i.e.,
       the calculations are run and the calculated ModVars are placed in modVarObjArr). */

  /***************************************************************************************
   *
   *    Add
   *
   ***************************************************************************************/

  if (instructionInt === pic.addItem) {
    /* ModVars we need */

    const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

    /* Stuff we need from ModVars */

    const priorPopMstID = getPriorPopMstID(priorPopObjArr, priorPopCurrID);

    /* Original ModVars we need */

    const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

    /* Stuff we need from original ModVars */

    let origPriorPopCurrID = getPriorPopCurrID(origPriorPopObjArr, priorPopMstID);

    /* Put this into a function because it varies by the method current ID we loop over
           below. */
    const getOrigMethodMstID = (methodCurrID) => {
      const methodMstIDStr = methodMstID(methodObjArr, methodCurrID);

      const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
      let origMethodCurrID = getMethodCurrID(origMethodObjArr, methodMstIDStr);

      /* If the original method could not be found, then we are adding a custom method.
               In this case, we'll copy the first method and change the method master ID to the
               custom one where applicable. */
      //let newCustomItem = false;
      let origMethodMstID = methodMstIDStr;
      if (origMethodCurrID === pic.itemDoesNotExist) {
        //newCustomItem = true;
        origMethodCurrID = 1;
        origMethodMstID = methodMstID(origMethodObjArr, origMethodCurrID);
      }

      return origMethodMstID;
    };

    /* If the original priority population cannot be found, then we are adding a custom
           priority population. In this case, we'll copy the first priority population
           and change the method master ID to the custom one where applicable. */
    let newCustomItem = false;
    //let origPriorPopMstID = priorPopMstID;
    if (origPriorPopCurrID === pic.itemDoesNotExist) {
      newCustomItem = true;
      origPriorPopCurrID = 1;
      //origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, origPriorPopCurrID);
    }

    /* General approach below:
     *
     *  1. Grab ModVar that needs to be modified as well as its original version.
     *  2. If modified and original ModVars both exist, clone original one and set
     *     it to modified one.
     *  3. If adding a custom method and ModVar has a method master ID field, then
     *     ensure that the field has the custom method's master ID instead of the
     *     default ModVar we cloned.
     *
     * */

    // markElig
    /* Configuration - Methods, Eligibility. */

    gbu.safeModify(priorPopMethodEligObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopMethodEligMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origEligMethodObj = getPriorPopMethodEligMethodObj(origMethodMstID, origVal);

          const newPriorPop = structuredClone(origEligMethodObj[pisc.methodValuePPPE][origPriorPopCurrID - 1]);
          newPriorPop["mstID"] = priorPopMstID;

          priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(priorPopCurrID - 1, 0, newPriorPop);
        }
      });
    });

    gbu.safeModify(HIVPrev1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.percentHIVPrevalenceMVTag);

      gbu.safeModify(origVal, () => {
        HIVPrev1DFltArray.splice(priorPopCurrID - 1, 0, structuredClone(origVal[origPriorPopCurrID - 1]));
      });
    });

    gbu.safeModify(adjFactor2DFltArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.adjustmentFactorMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origAdjFactorMethodObj = getAdjFactorMethodObj(origMethodMstID, origVal);

          adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(origAdjFactorMethodObj[pisc.factorsAF][origPriorPopCurrID - 1])
          );
        }
      });
    });

    gbu.safeModify(percIndicPrEP1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.percentForPrepMVTag);

      gbu.safeModify(origVal, () => {
        percIndicPrEP1DFltArray.splice(priorPopCurrID - 1, 0, structuredClone(origVal[origPriorPopCurrID - 1]));
      });
    });

    gbu.safeModify(percPrEPElig1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.percentPrepEligibleMVTag);

      gbu.safeModify(origVal, () => {
        percPrEPElig1DFltArray.splice(priorPopCurrID - 1, 0, structuredClone(origVal[origPriorPopCurrID - 1]));
      });
    });

    gbu.safeModify(popSize1DFltArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorityPopSizeMVTag);

      gbu.safeModify(origVal, () => {
        popSize1DFltArray.splice(priorPopCurrID - 1, 0, structuredClone(origVal[origPriorPopCurrID - 1]));
      });
    });

    /* Costs Lite - Continuation visit schedules tab inputs */

    gbu.safeModify(methodObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        const methodMstIDStr = methodMstID(methodObjArr, m);
        const origMethodMstID = getOrigMethodMstID(m);

        gbu.safeModify(costCatLiteObjList, () => {
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

          gbu.safeModify(costCatMethodObj, () => {
            const origVal = getModVarValue(origModVarObjArr, pisc.costCategoriesLiteMVTag);

            gbu.safeModify(origVal, () => {
              const origCostCatMethodObj = getCostCatLiteMethodObj(origMethodMstID, origVal);

              let categoriesArr = costCatMethodObj[pisc.priorPopObjArrCCLite];

              categoriesArr.splice(
                priorPopCurrID - 1,
                0,
                structuredClone(origCostCatMethodObj[pisc.priorPopObjArrCCLite][origPriorPopCurrID - 1])
              );

              if (newCustomItem) {
                let categoriesPriorPopObj = categoriesArr[priorPopCurrID - 1];

                /* All new priority populations should start off with zeros. The exception is the first
                                   line, which pulls the method costs per month from the PI_Methods ModVar.*/
                categoriesPriorPopObj[pisc.adherenceSupportCCLite] = 0;
                categoriesPriorPopObj[pisc.annualCCLite] = 0;
                categoriesPriorPopObj[pisc.monthlyCostCCLite] = 0;
                let contObj = categoriesPriorPopObj[pisc.contCCLite];
                contObj[pisc.capitalCCLite] = 0;
                contObj[pisc.personnelCCLite] = 0;
                contObj[pisc.recurrentCCLite] = 0;
                contObj[pisc.totalCCLite] = 0;
                contObj[pisc.visitLabsCCLite] = 0;
                let initObj = categoriesPriorPopObj[pisc.initCCLite];
                initObj[pisc.capitalCCLite] = 0;
                initObj[pisc.personnelCCLite] = 0;
                initObj[pisc.recurrentCCLite] = 0;
                initObj[pisc.totalCCLite] = 0;
                initObj[pisc.visitLabsCCLite] = 0;
                categoriesPriorPopObj[pisc.priorPopMstIDCCLite] = getPriorPopMstID(priorPopObjArr, priorPopCurrID);
              }
            });
          });
        });
      }
    });

    /* Targets - Options */

    gbu.safeModify(potUsersToTakePrEP1DObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.coverageByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origPotUsersMethodObj = getPotUsersMethodObj(origMethodMstID, origVal);

          potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(origPotUsersMethodObj[pisc.coveragePU][origPriorPopCurrID - 1])
          );
        }
      });
    });

    gbu.safeModify(actUsersToTakePrEP1DObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.covConstrActualMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origActUsersMethodObj = getActUsersMethodObj(origMethodMstID, origVal);

          actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(origActUsersMethodObj[pisc.coverageTSP_AU][origPriorPopCurrID - 1])
          );
          actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(origActUsersMethodObj[pisc.coverageDRD_AU][origPriorPopCurrID - 1])
          );
        }
      });
    });

    gbu.safeModify(targClientsInit1DObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.targetsByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);

          const origTargClientsMethodObj = getTargClientsMethodObj(origMethodMstID, origVal);

          targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(origTargClientsMethodObj[pisc.targetTC][origPriorPopCurrID - 1])
          );
        }
      });
    });

    // /* Potential users must be 100% for any given priority population across all
    //    methods, so if a priority population is added, make the
    //    potential users 100% for the method. */
    // const methodMstIDStr = methodMstID(methodObjArr, 1);
    // total = getPotUsersToTakePrEPTotal(potUsersToTakePrEP1DObjArray, methodObjArr, methodMstIDStr);
    // if (!gbu.equal(total, 100)) {
    //
    //     setPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEP1DObjArray, priorPopCurrID, 1.0);
    //
    // }

    /* Disaggregate targets - Disaggregation setup tab */

    gbu.safeModify(priorPopInclObj, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopAgeSexInclMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);
          const origPriorPopInclMethodObj = getPriorPopInclMethodObj(origMethodMstID, origVal);

          priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(
              origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.startAgePPIAS][origPriorPopCurrID - 1]
            )
          );

          priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(
              origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.endAgePPIAS][origPriorPopCurrID - 1]
            )
          );

          priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(
            priorPopCurrID - 1,
            0,
            structuredClone(
              origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.includePPIAS][origPriorPopCurrID - 1]
            )
          );

          if (newCustomItem) {
            priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
              priorPopCurrID - 1,
              0,
              getPriorPopMstID(priorPopObjArr, priorPopCurrID)
            );
          } else {
            priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
              priorPopCurrID - 1,
              0,
              structuredClone(
                origPriorPopInclMethodObj[pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS][origPriorPopCurrID - 1]
              )
            );
          }
        }
      });
    });

    gbu.safeModify(targIndObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
        targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
        targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(impactInfAvtdConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        impactInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(adjInfAvtdConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        adjInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(persYrsPrEPAvtOneInfectConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        persYrsPrEPAvtOneInfectConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    /* Disaggregate targets  - Targets disaggregated by district, by priority population */

    gbu.safeModify(initPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, 0.0);
        }
      }
    });

    gbu.safeModify(onPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, 0.0);
        }
      }
    });

    gbu.safeModify(totalCosts2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalCosts2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, 0);
      }
    });

    gbu.safeModify(totalInfAvtdConstantCoverage2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInfAvtdConstantCoverage2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, 0);
      }
    });

    gbu.safeModify(totalInits1DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInits1DIntArray[m - 1].splice(priorPopCurrID - 1, 0, 0);
      }
    });

    gbu.safeModify(costPerPersonInitPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costPerPersonInitPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(costStayOnPrEPPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    gbu.safeModify(initiationObj, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.initiationMVTag);

      gbu.safeModify(origVal, () => {
        for (let m = 1; m <= numMethods; m++) {
          const origMethodMstID = getOrigMethodMstID(m);
          const origMethodObj = getInitiationMethodObj(origMethodMstID, origVal);
          const origNumProgDataMonths = origMethodObj[pisc.initObjArr].length;

          for (let t = 1; t <= numProgDataMonths; t++) {
            let tOrig;
            if (t <= origNumProgDataMonths) {
              tOrig = t;
            } else {
              tOrig = 1;
            }

            let initByMonth = initiationObj[m - 1][pisc.initObjArr][t - 1];
            const origInitByMonth = origMethodObj[pisc.initObjArr][tOrig - 1];

            initByMonth[pisc.initInitiatedPrEP].splice(
              priorPopCurrID - 1,
              0,
              structuredClone(origInitByMonth[pisc.initInitiatedPrEP][origPriorPopCurrID - 1])
            );

            initByMonth[pisc.initReinitiatedPrEP].splice(
              priorPopCurrID - 1,
              0,
              structuredClone(origInitByMonth[pisc.initReinitiatedPrEP][origPriorPopCurrID - 1])
            );
          }
        }
      });
    });

    gbu.safeModify(avgCostPrEPByMonthPP3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopCurrID - 1, 0, 0.0);
      }
    });

    /* Method Mix */
    if (methodMixObj !== undefined) {
      const origPopMstID = origPriorPopObjArr[0].mstID;
      const origMethodMixObj = getModVarValue(origModVarObjArr, "PI_MethodMix");

      methodMixObj[priorPopMstID] = structuredClone(origMethodMixObj[origPopMstID]);
    }

    if (methodMixCoverageObj !== undefined) {
      const origPopMstID = origPriorPopObjArr[0].mstID;
      const origMethodMixCoverageObj = getModVarValue(origModVarObjArr, "PI_MethodMixCoverage");

      methodMixCoverageObj[priorPopMstID] = structuredClone(origMethodMixCoverageObj[origPopMstID]);
    }
  } else if (instructionInt === pic.deleteItem) {
    /***************************************************************************************
     *
     *    Delete
     *
     ***************************************************************************************/
    //markElig
    gbu.safeModify(priorPopMethodEligObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(HIVPrev1DFltArray, () => HIVPrev1DFltArray.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(percIndicPrEP1DFltArray, () => percIndicPrEP1DFltArray.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(percPrEPElig1DFltArray, () => percPrEPElig1DFltArray.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(popSize1DFltArray, () => popSize1DFltArray.splice(priorPopCurrID - 1, 1));

    gbu.safeModify(adjFactor2DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(priorPopCurrID - 1, 1);
      }
    });

    /* Targets - Options */

    gbu.safeModify(potUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(actUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(priorPopCurrID - 1, 1);
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(targClientsInit1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(methodObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        const methodMstIDStr = methodMstID(methodObjArr, m);

        gbu.safeModify(costCatLiteObjList, () => {
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

          gbu.safeModify(costCatLiteObjList, () => {
            costCatMethodObj[pisc.priorPopObjArrCCLite].splice(priorPopCurrID - 1, 1);
          });
        });
      }
    });

    /* Disaggregate targets  - Priority populations included by age/sex*/

    gbu.safeModify(priorPopInclObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(priorPopCurrID - 1, 1);

        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(priorPopCurrID - 1, 1);

        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(priorPopCurrID - 1, 1);

        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(priorPopCurrID - 1, 1);
      }
    });

    /* Disaggregate targets  - Target indicators */

    gbu.safeModify(targIndObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopCurrID - 1, 1);
        targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopCurrID - 1, 1);
        targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(impactInfAvtdConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        impactInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(adjInfAvtdConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        adjInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(persYrsPrEPAvtOneInfectConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        persYrsPrEPAvtOneInfectConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    /* Disaggregate targets  - Targets disaggregated by district, by priority population */

    gbu.safeModify(initPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 1);
        }
      }
    });

    gbu.safeModify(onPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 1);
        }
      }
    });

    gbu.safeModify(totalCosts2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalCosts2DIntArray[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(totalInfAvtdConstantCoverage2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInfAvtdConstantCoverage2DIntArray[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(totalInits1DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        totalInits1DIntArray[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(avgCostPrEPByMonthPP3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(costStayOnPrEPPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(costPerPersonInitPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        costPerPersonInitPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 1);
      }
    });

    gbu.safeModify(initiationObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let t = 1; t <= numProgDataMonths; t++) {
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(priorPopCurrID - 1, 1);
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initReinitiatedPrEP].splice(priorPopCurrID - 1, 1);
        }
      }
    });

    /* Method Mix */
    // TODO: This should remove the deleted pp from MM and MMC, however we only have
    //       the priorPop ID not the mstID at this point and the ID cannot be used
    //       to get mstID from priorPopObjArr as it's already been removed. Will need
    //       to pass in mstID as an extra shiftPriorPops arg.
    //       Once fixed this will also want a server upgrade entry adding to remove
    //       any orphaned pop entries in either of these two modvars.
    // if (methodMixObj !== undefined) {
    //   delete methodMixObj[priorPopMstID];
    // }

    // if (methodMixCoverageObj !== undefined) {
    //   delete methodMixCoverageObj[priorPopMstID];
    // }
  } else if (instructionInt === pic.moveItem) {
    /***************************************************************************************
     *
     *    Move
     *
     ***************************************************************************************/
    let singleValueArray;

    /* Configuration - Continuation, Eligibility */

    gbu.safeModify(priorPopMethodEligObjArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(priorPopToMoveCurrID - 1, 1);
        priorPopMethodEligObjArr[m - 1][pisc.methodValuePPPE].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(HIVPrev1DFltArray, () => {
      singleValueArray = HIVPrev1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      HIVPrev1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    gbu.safeModify(adjFactor2DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(priorPopToMoveCurrID - 1, 1);
        adjFactor2DFltArr[m - 1][pisc.factorsAF].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(percIndicPrEP1DFltArray, () => {
      singleValueArray = percIndicPrEP1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      percIndicPrEP1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    gbu.safeModify(percPrEPElig1DFltArray, () => {
      singleValueArray = percPrEPElig1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      percPrEPElig1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    gbu.safeModify(popSize1DFltArray, () => {
      singleValueArray = popSize1DFltArray.splice(priorPopToMoveCurrID - 1, 1);
      popSize1DFltArray.splice(priorPopCurrID - 1, 0, singleValueArray[0]);
    });

    /* Targets - Options */

    gbu.safeModify(potUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(priorPopToMoveCurrID - 1, 1);
        potUsersToTakePrEP1DObjArray[m - 1][pisc.coveragePU].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(actUsersToTakePrEP1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(priorPopToMoveCurrID - 1, 1);
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageTSP_AU].splice(priorPopCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(priorPopToMoveCurrID - 1, 1);
        actUsersToTakePrEP1DObjArray[m - 1][pisc.coverageDRD_AU].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(targClientsInit1DObjArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(priorPopToMoveCurrID - 1, 1);
        targClientsInit1DObjArray[m - 1][pisc.targetTC].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(methodObjArr, () => {
      const movePriorPop = (methodCurrID) => {
        const methodMstIDStr = methodMstID(methodObjArr, methodCurrID);

        gbu.safeModify(costCatLiteObjList, () => {
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

          gbu.safeModify(costCatLiteObjList, () => {
            singleValueArray = costCatMethodObj[pisc.priorPopObjArrCCLite].splice(priorPopToMoveCurrID - 1, 1);
            costCatMethodObj[pisc.priorPopObjArrCCLite].splice(priorPopToMoveCurrID - 1, 0, singleValueArray[0]);
          });
        });
      };

      for (let m = 1; m <= numMethods; m++) {
        movePriorPop(m);
      }
    });

    /* Disaggregate targets  - Priority populations included by age/sex */

    gbu.safeModify(priorPopInclObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );

        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );

        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.includePPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );

        singleValueArray = priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
          priorPopToMoveCurrID - 1,
          1
        );
        priorPopInclObj[m - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS].splice(
          priorPopCurrID - 1,
          0,
          singleValueArray[0]
        );
      }
    });

    /* Disaggregate targets  - Target indicators */

    gbu.safeModify(targIndObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopToMoveCurrID - 1, 1);
        targIndObj[pisc.PrEP_NEW_TI][m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopToMoveCurrID - 1, 1);
        targIndObj[pisc.currOnPrEPTI][m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopToMoveCurrID - 1, 1);
        targIndObj[pisc.PREP_CT_TI][m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(impactInfAvtdConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = impactInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        impactInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(adjInfAvtdConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = adjInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        adjInfAvtdConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(persYrsPrEPAvtOneInfectConstantCoverage1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = persYrsPrEPAvtOneInfectConstantCoverage1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        persYrsPrEPAvtOneInfectConstantCoverage1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    /* Disaggregate targets  - Targets disaggregated by district, by priority population */

    gbu.safeModify(initPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          singleValueArray = initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopToMoveCurrID - 1, 1);
          initPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
        }
      }
    });

    gbu.safeModify(onPrEPTargDisag3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let dp = 1; dp <= numDistrictPops; dp++) {
          singleValueArray = onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopToMoveCurrID - 1, 1);
          onPrEPTargDisag3DFltArr[m - 1][dp - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
        }
      }
    });

    gbu.safeModify(totalCosts2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = totalCosts2DIntArray[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        totalCosts2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(totalInfAvtdConstantCoverage2DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = totalInfAvtdConstantCoverage2DIntArray[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        totalInfAvtdConstantCoverage2DIntArray[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(totalInits1DIntArray, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = totalInits1DIntArray[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        totalInits1DIntArray[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(avgCostPrEPByMonthPP3DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        avgCostPrEPByMonthPP3DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(costStayOnPrEPPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        costStayOnPrEPPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    gbu.safeModify(costPerPersonInitPP1DFltArr, () => {
      for (let m = 1; m <= numMethods; m++) {
        singleValueArray = costPerPersonInitPP1DFltArr[m - 1].splice(priorPopToMoveCurrID - 1, 1);
        costPerPersonInitPP1DFltArr[m - 1].splice(priorPopCurrID - 1, 0, singleValueArray[0]);
      }
    });

    /* Method Mix and Method Mix Coverage are dict based, move does not apply */

    /* Server-only */

    gbu.safeModify(initiationObj, () => {
      for (let m = 1; m <= numMethods; m++) {
        for (let t = 1; t <= numProgDataMonths; t++) {
          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initInitiatedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );

          singleValueArray = initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initReinitiatedPrEP].splice(
            priorPopToMoveCurrID - 1,
            1
          );
          initiationObj[m - 1][pisc.initObjArr][t - 1][pisc.initReinitiatedPrEP].splice(
            priorPopCurrID - 1,
            0,
            singleValueArray[0]
          );
        }
      }
    });
  }
}

/* Continuation curves are assigned to each priority population. If the user adds a default
   priority population back in or creates a custom priority population, we load defaults
   for it from the original ModVar object array. That array uses the original continuation
   curves.  If the user changed the  continuation curves before adding the priority population, they will no longer match the
   original list of continuation curves. Call this function to ensure that new priority
   populations are using valid continuation curves. */
export function adjustPriorPopContCurves(modVarObjList, origModVarObjArr, itemObj) {
  /* First use the master ID of the priority population we just added to get the
       original priority population object. */

  let origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

  const priorPopMstID = itemObj[pisc.priorPopMstID];
  let origPriorPopCurrID = getPriorPopCurrID(origPriorPopObjArr, priorPopMstID);

  if (origPriorPopCurrID === pic.itemDoesNotExist) {
    origPriorPopCurrID = 1;
  }

  let origPriorPopObj = structuredClone(origPriorPopObjArr[origPriorPopCurrID - 1]);

  /* Now modify the impact factor data structures in the original object to match the
       current method structure. */

  let methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  let origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* Remove methods the user removed. */

  for (let m = origPriorPopObj[pisc.priorPopContCurves].length - 1; m >= 0; m--) {
    const origMethodMstID = methodMstID(origMethodObjArr, m + 1);
    const methodCurrID = getMethodCurrID(methodObjArr, origMethodMstID);

    if (methodCurrID === pic.itemDoesNotExist) {
      origPriorPopObj[pisc.priorPopContCurves].splice(m, 1);
    }
  }

  /* Add methods the user added.*/

  const numMethods = getTotalNumMethods(methodObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const customBool = getMethodCustom(methodObjArr, m);

    if (customBool) {
      origPriorPopObj[pisc.priorPopContCurves].push(structuredClone(origPriorPopObj[pisc.priorPopContCurves][0]));

      origPriorPopObj[pisc.priorPopContCurves][m - 1][pisc.priorPopContCurveMethodMstID] = methodMstID(methodObjArr, m);
    }
  }

  /* Finally, copy over the data structures. */

  itemObj[pisc.priorPopContCurves] = structuredClone(origPriorPopObj[pisc.priorPopContCurves]);
}

/* Scale-up trends are assigned to each priority population. If the user adds a default
   priority population back in or creates a custom priority population, we load defaults
   for it from the original ModVar object array. That array uses the original trends.
   If the user changed the trends before adding the priority population, they will no longer
   match the original list of trends. Call this function to ensure that new priority
   populations are using valid trends. */
export function adjustPriorPopScaleUpTrends(modVarObjList, itemObj) {
  const scaleUpTrendsObjArr = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  const scaleUpTrendMstID = itemObj[pisc.priorPopScaleUpTrendMstID];
  const scaleUpTrendCurrID = getScaleUpTrendCurrID(scaleUpTrendsObjArr, scaleUpTrendMstID);

  if (scaleUpTrendCurrID === pic.itemDoesNotExist) {
    itemObj[pisc.priorPopScaleUpTrendMstID] = getScaleUpTrendMstID(scaleUpTrendsObjArr, 1);
  }
}

/* Continuation visit schedules are assigned to each priority population. If the user adds a
   default priority population back in or creates a custom priority population, we load
   defaults for it from the original ModVar object array. That array uses the original schedules.
   If the user changed the schedules before adding the priority population, they will no longer
   match the original list of schedules. Call this function to ensure that new priority
   populations are using valid schedules. */
export function adjustPriorPopContVisitSchedules(modVarObjList, itemObj) {
  const selectedMethodMstID = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  const contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);

  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);

  const priorPopMstID = itemObj[pisc.priorPopMstID];
  const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, priorPopMstID);

  const contVisitSchedMstID = getPriorPopContVisitSchedMstIDCostsLite(
    selectedMethodMstID,
    costCatLiteObjList,
    priorPopCurrID
  );
  //    const contVisitSchedCurrID = getContVisitSchedCurrID(selectedMethodMstID, contVisitSchedLiteObjList, contVisitSchedMstID);
  const contVisitSchedCurrID = getContVisitSchedCurrID(contVisitSchedLiteObjList, contVisitSchedMstID);

  if (contVisitSchedCurrID === pic.itemDoesNotExist) {
    setPriorPopContVisitSchedMstIDCostsLite(
      selectedMethodMstID,
      costCatLiteObjList,
      priorPopCurrID,
      //getContVisitSchedMstID(selectedMethodMstID, contVisitSchedLiteObjList, 1));
      getContVisitSchedMstID(contVisitSchedLiteObjList, 1)
    );
  }
}

export function adjustPriorPopImpFactors(modVarObjList, origModVarObjArr, itemObj) {
  /* First use the master ID of the priority population we just added to get the
       original priority population object. */

  //const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  let origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

  const priorPopMstID = itemObj[pisc.priorPopMstID];
  let origPriorPopCurrID = getPriorPopCurrID(origPriorPopObjArr, priorPopMstID);

  if (origPriorPopCurrID === pic.itemDoesNotExist) {
    origPriorPopCurrID = 1;
  }

  let origPriorPopObj = structuredClone(origPriorPopObjArr[origPriorPopCurrID - 1]);

  /* Now modify the impact factor data structures in the original object to match the
       current method structure. */

  let methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
  let origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);

  /* Remove methods the user removed. */

  for (let m = origPriorPopObj[pisc.priorPopImpactConst].length - 1; m >= 0; m--) {
    const origMethodMstID = methodMstID(origMethodObjArr, m + 1);
    const methodCurrID = getMethodCurrID(methodObjArr, origMethodMstID);

    if (methodCurrID === pic.itemDoesNotExist) {
      origPriorPopObj[pisc.priorPopImpactConst]?.splice(m, 1);
      origPriorPopObj[pisc.priorPopImpactPSE]?.splice(m, 1);
    }
  }

  /* Add methods the user added.*/

  const numMethods = getTotalNumMethods(methodObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const customBool = getMethodCustom(methodObjArr, m);

    if (customBool) {
      if (origPriorPopObj[pisc.priorPopImpactConst]) {
        origPriorPopObj[pisc.priorPopImpactConst].push(structuredClone(origPriorPopObj[pisc.priorPopImpactConst][0]));

        origPriorPopObj[pisc.priorPopImpactConst][m - 1][pisc.priorPopImpactConstMethodMstID] = methodMstID(
          methodObjArr,
          m
        );
      }

      if (origPriorPopObj[pisc.priorPopImpactPSE]) {
        origPriorPopObj[pisc.priorPopImpactPSE].push(structuredClone(origPriorPopObj[pisc.priorPopImpactPSE][0]));

        origPriorPopObj[pisc.priorPopImpactPSE][m - 1][pisc.priorPopImpactPSEMethodMstID] = methodMstID(
          methodObjArr,
          m
        );
      }
    }
  }

  /* Finally, copy over the data structures. */

  if (origPriorPopObj[pisc.priorPopImpactConst])
    itemObj[pisc.priorPopImpactConst] = structuredClone(origPriorPopObj[pisc.priorPopImpactConst]);
  if (origPriorPopObj[pisc.priorPopImpactPSE])
    itemObj[pisc.priorPopImpactPSE] = structuredClone(origPriorPopObj[pisc.priorPopImpactPSE]);
}

export function getPriorPopObj(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1];
}

/* Default and custom priority population objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */

export function getPriorPopName(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopName];
}

export function setPriorPopName(priorPopObjList, priorPopCurrID, value) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopName] = value;
}

export function getPriorPopNames(priorPopObjList) {
  let names = [];

  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  for (let pp = 1; pp <= numPriorPops; pp++) {
    names.push(getPriorPopName(priorPopObjList, pp));
  }

  return names;
}

export function getPriorPopAbbr(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopAbbr];
}

export function setPriorPopAbbr(priorPopObjList, priorPopCurrID, value) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopAbbr] = value;
}

export function getPriorPopMstID(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopMstID];
}

export function getPriorPopCustom(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopMstID].includes(pisc.customItemMstID);
}

export function getTotalNumPriorPops(priorPopObjList) {
  return priorPopObjList.length;
}

export function getPriorPopCurrID(priorPopObjList, priorPopMstID) {
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numPriorPops && !stop) {
    const mstID = getPriorPopMstID(priorPopObjList, currIDInt);

    if (mstID === priorPopMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getPriorPopCurrIDArray(priorPopObjList) {
  let currID1DIntArray = [];

  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  for (let pp = 1; pp <= numPriorPops; pp++) {
    currID1DIntArray.push(pp);
  }

  return currID1DIntArray;
}

export function getNumActiveDefPriorPops(priorPopObjList) {
  let numActiveDefPriorPops = 0;

  for (let i = 0; i < priorPopObjList.length; i++) {
    let obj = priorPopObjList[i];

    if (!obj[pisc.priorPopMstID].includes(pisc.customItemMstID)) {
      numActiveDefPriorPops++;
    }
  }

  return numActiveDefPriorPops;
}

export function getNumCustomPriorPops(priorPopObjList) {
  let numCustomPriorPops = 0;

  for (let i = 0; i < priorPopObjList.length; i++) {
    let obj = priorPopObjList[i];

    if (obj[pisc.priorPopMstID].includes(pisc.customItemMstID)) {
      numCustomPriorPops++;
    }
  }

  return numCustomPriorPops;
}

export function getCustomPriorPopsCurrIDArray(priorPopObjList) {
  /* Add the current IDs of all custom priority pops to an array. */
  let customPriorPopCurrID1DIntArray = [];

  const totalNumPriorPops = getTotalNumPriorPops(priorPopObjList);

  for (let pp = 1; pp <= totalNumPriorPops; pp++) {
    const customBool = getPriorPopCustom(priorPopObjList, pp);

    if (customBool) {
      customPriorPopCurrID1DIntArray.push(pp);
    }
  }

  return customPriorPopCurrID1DIntArray;
}

export function addCustomPriorPop(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  let HIVPrev1DFltArray = getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
  let percIndicPrEP1DFltArray = getModVarValue(modVarObjList, pisc.percentForPrepMVTag);
  let percPrEPElig1DFltArray = getModVarValue(modVarObjList, pisc.percentPrepEligibleMVTag);
  let popSize1DFltArray = getModVarValue(modVarObjList, pisc.priorityPopSizeMVTag);

  const priorPopNum = (getNumCustomPriorPops(priorPopObjList) + 1).toString();

  const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = structuredClone(getItemObj(origPriorPopObjArr, pic.priorPopItems, pisc.SDC_PP_MstID, ""));
  itemObj[pisc.priorPopMstID] = `${pisc.customItemMstID}-${uuidv4()}`;
  itemObj[pisc.priorPopName] = RS(SC.GB_stCustomPriorPop) + " " + priorPopNum;

  let priorPopCurrID;
  if (typeof itemToAddAfterCurrID !== "undefined") {
    priorPopObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    priorPopCurrID = itemToAddAfterCurrID + 1;
    shiftPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID, pic.addItem);
  } else {
    priorPopObjList.push(itemObj);
    const totalNumPriorPops = getTotalNumPriorPops(priorPopObjList);
    priorPopCurrID = totalNumPriorPops;
    shiftPriorPops(modVarObjList, origModVarObjArr, totalNumPriorPops, pic.addItem);
  }

  /* These modify the itemObj */
  adjustPriorPopContCurves(modVarObjList, origModVarObjArr, itemObj);
  adjustPriorPopScaleUpTrends(modVarObjList, itemObj);
  /* These use the itemObj but don't modfiy it. Must be called after the itemObj
       is added to the priority pop object array, since it is used within this method. */
  adjustPriorPopContVisitSchedules(modVarObjList, itemObj);
  adjustPriorPopImpFactors(modVarObjList, origModVarObjArr, itemObj);

  setPopSize(popSize1DFltArray, priorPopCurrID, 0);
  setPercPrEPElig(percPrEPElig1DFltArray, priorPopCurrID, 100);
  setHIVPrev(HIVPrev1DFltArray, priorPopCurrID, 0);
  setPercIndicPrEP(percIndicPrEP1DFltArray, priorPopCurrID, 100);
}

export function deleteCustomPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID1DIntArray) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  for (let i = priorPopCurrID1DIntArray.length; i >= 1; i--) {
    priorPopObjList.splice(priorPopCurrID1DIntArray[i - 1] - 1, 1);
    shiftPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID1DIntArray[i - 1], pic.deleteItem);
  }
}

export function moveCustomPriorPops(modVarObjList, origModVarObjArr, priorPopCurrID1DIntArray, direction) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  //const numPriorPops = getTotalNumPriorPops(priorPopObjList);

  /* Instead of moving the custom priority pop(s) we want to move, move the one above/below them.
       If moving the priority pop block down, put the priority pop immediately after the block
       before the first priority pop in block. If moving the priority pop block up, put the
       priority pop immediately below the block after the last priority pop in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default priority pops always come before custom ones. */

  const customPriorPopsCurrIDArray = getCustomPriorPopsCurrIDArray(priorPopObjList);

  const firstPriorPopInBlockCurrID = priorPopCurrID1DIntArray[0];
  const lastPriorPopInBlockCurrID = priorPopCurrID1DIntArray[priorPopCurrID1DIntArray.length - 1];

  /* If moving the priority pop(s) down in the list and there's another custom priority
       pop after it, move that custom priority pop before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastPriorPopInBlockCurrID !== customPriorPopsCurrIDArray[customPriorPopsCurrIDArray.length - 1]
  ) {
    const priorPopObjArray = priorPopObjList.splice(lastPriorPopInBlockCurrID + 1 - 1, 1);
    priorPopObjList.splice(firstPriorPopInBlockCurrID - 1, 0, priorPopObjArray[0]);
    shiftPriorPops(
      modVarObjList,
      origModVarObjArr,
      firstPriorPopInBlockCurrID,
      pic.moveItem,
      lastPriorPopInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstPriorPopInBlockCurrID !== customPriorPopsCurrIDArray[0]) {
    const priorPopObjArray = priorPopObjList.splice(firstPriorPopInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    priorPopObjList.splice(lastPriorPopInBlockCurrID - 1 - 1 + 1, 0, priorPopObjArray[0]);
    shiftPriorPops(
      modVarObjList,
      origModVarObjArr,
      firstPriorPopInBlockCurrID,
      pic.moveItem,
      firstPriorPopInBlockCurrID - 1
    );
  }
}

export function clearPriorPops(modVarObjList, origModVarObjArr) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  for (let i = priorPopObjList.length - 1; i >= 0; i--) {
    shiftPriorPops(modVarObjList, origModVarObjArr, priorPopObjList.length, pic.deleteItem);
    priorPopObjList.pop();
  }
}

export function getPriorPopContCurveMstID(priorPopObjList, methodCurrID, priorPopCurrID) {
  const curveMstID =
    priorPopObjList[priorPopCurrID - 1][pisc.priorPopContCurves][methodCurrID - 1][pisc.priorPopContCurveMstID];
  return curveMstID;
}

export function setPriorPopContCurveMstID(priorPopObjList, methodCurrID, priorPopCurrID, valueStr) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopContCurves][methodCurrID - 1][pisc.priorPopContCurveMstID] =
    valueStr;
}

export function resetPriorPopContCurveMstIDs(methodObjList, priorPopObjList, contCurveObjList, deletedContCurveCurrID) {
  const numMethods = getTotalNumMethods(methodObjList);
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  const numContCurves = getTotalNumContCurves(contCurveObjList);

  /* If a priority population was using the continuation curve, give it the continuation curve
       master ID for the first continuation curve. */
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const contCurveMstID = getContCurveMstID(contCurveObjList, deletedContCurveCurrID);

    for (let m = 1; m <= numMethods; m++) {
      const contCurveMstIDPP = getPriorPopContCurveMstID(priorPopObjList, m, pp);

      if (contCurveMstIDPP === contCurveMstID) {
        let newContCurveMstID;
        /* Since we're deleting continuation curves, make sure there is more than one,
                   or we'll have to assign NONE temporarily as a master ID. */
        if (numContCurves > 1) {
          if (deletedContCurveCurrID !== 1) {
            newContCurveMstID = getContCurveMstID(contCurveObjList, 1);
          } else {
            newContCurveMstID = getContCurveMstID(contCurveObjList, 2);
          }
        } else {
          /* Temporarily assign a master ID of NONE if there will be no more
                   continuation curves and assign a proper one at the right time. */
          newContCurveMstID = pic.noMstID;
        }

        setPriorPopContCurveMstID(priorPopObjList, m, pp, newContCurveMstID);
      }
    }
  }
}

export function resetPriorPopScaleUpTrendMstIDs(
  methodObjList,
  priorPopObjList,
  scaleUpTrendObjList,
  deletedScaleUpTrendCurrID
) {
  const numMethods = getTotalNumMethods(methodObjList);
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  const numScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  /* If a priority population was using the scale-up trend, give it the scale-up
               trend master ID for the first scale-up trend. */
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const scaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, deletedScaleUpTrendCurrID);

    for (let m = 1; m <= numMethods; m++) {
      const scaleUpTrendMstIDPP = getPriorPopScaleUpTrendMstID(priorPopObjList, m, pp);

      if (scaleUpTrendMstIDPP === scaleUpTrendMstID) {
        let newScaleUpTrendMstID;
        /* Since we're deleting scale-up trends, make sure there is more than one,
                or we'll have to assign NONE temporarily as a master ID. */
        if (numScaleUpTrends > 1) {
          if (deletedScaleUpTrendCurrID !== 1) {
            newScaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, 1);
          } else {
            newScaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, 2);
          }
        } else {
          /* Temporarily assign a master ID of NONE if there will be no more
                scale-up trends and assign a proper one at the right time. */
          newScaleUpTrendMstID = pic.noMstID;
        }

        setPriorPopScaleUpTrendMstID(priorPopObjList, m, pp, newScaleUpTrendMstID);
      }
    }
  }
}

/* Call this to properly update the service delivery schedule master IDs in the cost category lite structures when
   schedules are added/deleted/moved. */
export function resetPriorPopContVisitSchedMstIDs(
  priorPopObjList,
  costCatLiteObjList,
  //methodMstIDStr, contVisitSchedObjList, deletedContVisitSchedCurrID) {
  methodObjArr,
  contVisitSchedObjList,
  contVisitSchedCurrID,
  addOrDeleteByte
) {
  const numPriorPops = getTotalNumPriorPops(priorPopObjList);
  // const numContVisitScheds = getTotalNumContVisitSchedules(methodMstIDStr, contVisitSchedObjList);
  const numContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);
  const numMethods = getTotalNumMethods(methodObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const methodMstIDStr = methodMstID(methodObjArr, m);

    /* If a priority population was using the schedule, give it the schedule master ID
           for the first available schedule or NONE if there are no schedules. */
    for (let pp = 1; pp <= numPriorPops; pp++) {
      const contVisitSchedMstIDPP = getPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, pp);
      //const contVisitSchedMstID = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, deletedContVisitSchedCurrID);
      const contVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, contVisitSchedCurrID);

      if (addOrDeleteByte === pic.deleteItem) {
        if (contVisitSchedMstIDPP === contVisitSchedMstID) {
          let newContVisitSchedMstID;
          /* Since we're deleting continuation visit schedules, make sure there is more than one,
                       or we'll have to assign NONE temporarily as a master ID. */
          if (numContVisitScheds > 1) {
            if (contVisitSchedCurrID !== 1) {
              //newContVisitSchedMstID = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, 1);
              newContVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, 1);
            } else {
              //newContVisitSchedMstID = getContVisitSchedMstID(methodMstIDStr, contVisitSchedObjList, 2);
              newContVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, 2);
            }
          } else {
            /* Temporarily assign a master ID of NONE if there will be no more continuation
                       visit objects and assign a proper one at the right time. */
            newContVisitSchedMstID = pic.noMstID;
          }

          setPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, pp, newContVisitSchedMstID);
        }
      } else if (addOrDeleteByte === pic.addItem && contVisitSchedMstIDPP === pic.noMstID) {
        setPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, pp, contVisitSchedMstID);
      }
    }
  }
}

export function getPriorPopScaleUpTrendMstID(priorPopObjList, methodCurrID, priorPopCurrID) {
  const trendMstID =
    priorPopObjList[priorPopCurrID - 1][pisc.priorPopScaleUpTrends][methodCurrID - 1][pisc.priorPopScaleUpTrendMstID];
  return trendMstID;
}

export function setPriorPopScaleUpTrendMstID(priorPopObjList, methodCurrID, priorPopCurrID, valueInt) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopScaleUpTrends][methodCurrID - 1][pisc.priorPopScaleUpTrendMstID] =
    valueInt;
}

export function getPriorPopImpPriorPopMstID(priorPopObjList, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpPopMstID];
}

export function setPriorPopImpPriorPopMstID(priorPopObjList, priorPopCurrID, valueStr) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpPopMstID] = valueStr;
}

export function getConstantPP(priorPopObjList, methodCurrID, priorPopCurrID) {
  return priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpactConst][methodCurrID - 1][pisc.priorPopDefaults];
}

export function setConstantPP(priorPopObjList, methodCurrID, priorPopCurrID, valueFlt) {
  priorPopObjList[priorPopCurrID - 1][pisc.priorPopImpactConst][methodCurrID - 1][pisc.priorPopDefaults] = valueFlt;
}

/*********************   Continuation - Continuation curves   ************************/

// export function createContCurveObj(mstIDStr, nameStr, percOnPrep1DIntArray,
//                                    percClientsRemainPrEP1DIntArray) {
//
//     return ({
//         [pisc.defContCurveMstID]     : mstIDStr,
//         [pisc.contCurveName]         : nameStr,
//         [pisc.contCurvePercOnPrEP]   : percOnPrep1DIntArray,
//         [pisc.percClientsRemainPrEP] : percClientsRemainPrEP1DIntArray,
//     });
//
// }

// export function setDefContCurveNames(contCurveObjList) {
//
//     //const numContCurves = piasu.getNumContCurves(contCurveObjList);
//     /* Currently, we always show a fixed number of rows for continuation curves. */
//     for (let cc = 1; cc <= pic.numDefContCurves; cc++) {
//
//         const contCurveName = getContCurveName(contCurveObjList, cc);
//
//         if (contCurveName === "") {
//
//             setContCurveName(contCurveObjList, cc, piu.getDefContCurveName(cc));
//
//         }
//
//     }
//
// }

export function setDefContCurveNames(contCurveObjList) {
  const numContCurves = getTotalNumContCurves(contCurveObjList);
  for (let i = 1; i <= numContCurves; i++) {
    const contCurveMstID = getContCurveMstID(contCurveObjList, i);
    const contCurveName = getContCurveName(contCurveObjList, i);

    if (contCurveName === "") {
      setContCurveName(contCurveObjList, i, piu.getDefContCurveNameFromMstID(contCurveMstID));
    }
  }
}
/* Determines if the user turned on (checked off) any continuation curves. */
export function contCurveSelected(contCurveObjList) {
  return contCurveObjList.length > 0;
}

/* Default continuation curves are active if they exist in the continuation curve object
   list. */
export function getDefContCurveActive(contCurveObjList, contCurveMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < contCurveObjList.length && !activeBool) {
    let obj = contCurveObjList[i];

    if (obj[pisc.contCurveMstID] === contCurveMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

export function setDefContCurveActive(modVarObjList, origModVarObjArr, contCurvesMstIDStr, valueBool) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  const alreadyActive = getDefContCurveActive(contCurveObjList, contCurvesMstIDStr);
  const totalNumContCurves = getTotalNumContCurves(contCurveObjList);

  /* If we are activating the continuation curve, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom continuation curves should always be added after all default ones. */
    if (!alreadyActive) {
      const origContCurveObjList = getModVarValue(origModVarObjArr, pisc.continuationCurvesMVTag);
      /* The original ModVar object array is cloned when the user clicks Add, but we need to
               clone again here because we'll be passing it along again and we want to pass the
               unadulterated version. */
      let itemObj = structuredClone(getItemObj(origContCurveObjList, pic.contCurveItems, contCurvesMstIDStr, ""));
      itemObj[pisc.contCurveMstID] = contCurvesMstIDStr;
      itemObj[pisc.contCurveName] = piu.getDefContCurveNameFromMstID(contCurvesMstIDStr);

      // const numCustomContCurves = getNumCustomContCurves(contCurveObjList);
      const numActiveDefContCurves = getNumActiveDefContCurves(contCurveObjList);

      /* If there are no continuation curves, just push the default one onto the array. */
      if (totalNumContCurves === 0) {
        contCurveObjList.push(itemObj);
        /* Add 1 since we just changed the number of continuation curve objects. */
        shiftContCurves(modVarObjList, origModVarObjArr, totalNumContCurves + 1, pic.addItem);
      } else if (numActiveDefContCurves === 0) {
        /* Otherwise, if there are no active default continuation curves, add the
               default one to the front of the array. */
        contCurveObjList.splice(0, 0, itemObj);
        shiftContCurves(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default continuation curve. Place the default pop we are
               activating just before the first default continuation curve we encounter with a
               higher current ID. */
        const defContCurveCurrID = piu.getDefContCurveCurrID(contCurvesMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumContCurves && !stop) {
          const contCurveMstIDLoop = getContCurveMstID(contCurveObjList, i + 1);

          if (!contCurveMstIDLoop.includes(pisc.customItemMstID)) {
            const defContCurveCurrIDLoop = piu.getDefContCurveCurrID(contCurveMstIDLoop);

            if (defContCurveCurrID < defContCurveCurrIDLoop) {
              contCurveObjList.splice(i, 0, itemObj);
              shiftContCurves(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom continuation curve. If we're activating the last
                       default continuation curve, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom continuation curve we encounter. */
            contCurveObjList.splice(i, 0, itemObj);
            shiftContCurves(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default continuation curve yet, we must be adding the last one and there
                   must not be any custom continuation curves.*/
        if (!stop) {
          contCurveObjList.push(itemObj);
          /* Add 1 since we just changed the number of continuation curve objects. */
          shiftContCurves(modVarObjList, origModVarObjArr, totalNumContCurves + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumContCurves && !stop) {
      let obj = contCurveObjList[i];

      if (obj[pisc.contCurveMstID] === contCurvesMstIDStr) {
        /* Shift curves in other data structures first so they can access the continuation curve
                   object list.*/
        shiftContCurves(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        contCurveObjList.splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts continuation curves for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by continuation curve
   should be in the same order as the continuation curve objects themselves. */
export function shiftContCurves(
  modVarObjList,
  origModVarObjArr,
  contCurveCurrID,
  instructionInt,
  contCurveToMoveCurrID
) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  if (instructionInt === pic.addItem) {
  } else if (instructionInt === pic.deleteItem) {
    resetPriorPopContCurveMstIDs(methodObjList, priorPopObjList, contCurveObjList, contCurveCurrID);
  } else if (instructionInt === pic.moveItem) {
  }
}

export function getContCurveName(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurveName];
}

export function setContCurveName(contCurveObjList, contCurveCurrID, valueStr) {
  contCurveObjList[contCurveCurrID - 1][pisc.contCurveName] = valueStr;
}

export function getContCurveNames(contCurveObjList) {
  let names = [];

  const numContCurves = getTotalNumContCurves(contCurveObjList);
  for (let cc = 1; cc <= numContCurves; cc++) {
    names.push(getContCurveName(contCurveObjList, cc));
  }

  return names;
}

export function getContCurveMstID(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurveMstID];
}

export function setContCurveMstID(contCurveObjList, contCurveCurrID, value) {
  contCurveObjList[contCurveCurrID - 1][pisc.contCurveMstID] = value;
}

// markProblem
export function getContCurveCurrID(contCurveObjList, contCurveMstID) {
  const numContCurves = getTotalNumContCurves(contCurveObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numContCurves && !stop) {
    const mstID = getContCurveMstID(contCurveObjList, currIDInt);

    if (mstID === contCurveMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getTotalNumContCurves(contCurveObjList) {
  return contCurveObjList.length;
}

export function getContCurveCustom(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurveMstID].includes(pisc.customItemMstID);
}

export function getNumActiveDefContCurves(persPopObjList) {
  let numActiveDefContCurves = 0;

  for (let i = 0; i < persPopObjList.length; i++) {
    let obj = persPopObjList[i];

    if (!obj[pisc.contCurveMstID].includes(pisc.customItemMstID)) {
      numActiveDefContCurves++;
    }
  }

  return numActiveDefContCurves;
}

export function getNumCustomContCurves(contCurveObjList) {
  let numCustomContCurves = 0;

  for (let i = 0; i < contCurveObjList.length; i++) {
    let obj = contCurveObjList[i];

    if (obj[pisc.contCurveMstID].includes(pisc.customItemMstID)) {
      numCustomContCurves++;
    }
  }

  return numCustomContCurves;
}

export function getCustomContCurvesCurrIDArray(contCurveObjList) {
  /* Add the current IDs of all custom continuation curves to an array. */
  let customContCurveCurrID1DIntArray = [];

  const totalNumContCurves = getTotalNumContCurves(contCurveObjList);

  for (let i = 1; i <= totalNumContCurves; i++) {
    const customBool = getContCurveCustom(contCurveObjList, i);

    if (customBool) {
      customContCurveCurrID1DIntArray.push(i);
    }
  }

  return customContCurveCurrID1DIntArray;
}

export function getNextCustomCurveID(contCurveObjList) {
  const lastID = contCurveObjList.reduce((acc, cur) => {
    const id = cur.mstID;
    if (!id.startsWith(pisc.customItemMstID)) return acc;

    const num = parseInt(id.slice(6));
    if (num > acc) return num;

    return acc;
  }, 0);

  return lastID + 1;
}

export function addCustomContCurve(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  const customCurveNum = getNextCustomCurveID(contCurveObjList);
  const curveNumAsStr = customCurveNum.toString();

  const origContCurveObjList = getModVarValue(origModVarObjArr, pisc.continuationCurvesMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = structuredClone(getItemObj(origContCurveObjList, pic.contCurveItems, pisc.SDCsCCMstID, ""));
  itemObj[pisc.contCurveMstID] = `${pisc.customItemMstID}-${uuidv4()}`;
  itemObj[pisc.contCurveName] = RS(SC.GB_stCustomContCurve) + " " + curveNumAsStr;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    contCurveObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    shiftContCurves(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    contCurveObjList.push(itemObj);
    const totalNumContCurves = getTotalNumContCurves(contCurveObjList);
    shiftContCurves(modVarObjList, origModVarObjArr, totalNumContCurves, pic.addItem);
  }

  /* Set all values to be blank */

  for (let cp = 1; cp <= pic.numContCurvePeriods; cp++) {
    setContCurvePercOnPrEP(contCurveObjList, getTotalNumContCurves(contCurveObjList), cp, "-");
  }
}

export function deleteCustomContCurves(modVarObjList, origModVarObjArr, contCurveCurrID1DIntArray) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  for (let i = contCurveCurrID1DIntArray.length; i >= 1; i--) {
    // markShift
    /* Shift curves in other data structures first so they can access the continuation curve
           object list.*/
    shiftContCurves(modVarObjList, origModVarObjArr, contCurveCurrID1DIntArray[i - 1], pic.deleteItem);
    contCurveObjList.splice(contCurveCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomContCurves(modVarObjList, origModVarObjArr, contCurveCurrID1DIntArray, direction) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  /* Instead of moving the custom continuation curve(s) we want to move, move the one above/below them.
       If moving the continuation curve block down, put the continuation curve immediately after the block
       before the first continuation curve in block. If moving the continuation curve block up, put the
       continuation curve immediately below the block after the last continuation curve in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default continuation curves always come before custom ones. */

  const customContCurvesCurrIDArray = getCustomContCurvesCurrIDArray(contCurveObjList);

  const firstContCurveInBlockCurrID = contCurveCurrID1DIntArray[0];
  const lastContCurveInBlockCurrID = contCurveCurrID1DIntArray[contCurveCurrID1DIntArray.length - 1];

  /* If moving the continuation curve(s) down in the list and there's another custom priority
       pop after it, move that custom continuation curve before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastContCurveInBlockCurrID !== customContCurvesCurrIDArray[customContCurvesCurrIDArray.length - 1]
  ) {
    const contCurveObjArray = contCurveObjList.splice(lastContCurveInBlockCurrID + 1 - 1, 1);
    contCurveObjList.splice(firstContCurveInBlockCurrID - 1, 0, contCurveObjArray[0]);
    shiftContCurves(
      modVarObjList,
      origModVarObjArr,
      firstContCurveInBlockCurrID,
      pic.moveItem,
      lastContCurveInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstContCurveInBlockCurrID !== customContCurvesCurrIDArray[0]) {
    const contCurveObjArray = contCurveObjList.splice(firstContCurveInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    contCurveObjList.splice(lastContCurveInBlockCurrID - 1 - 1 + 1, 0, contCurveObjArray[0]);
    shiftContCurves(
      modVarObjList,
      origModVarObjArr,
      firstContCurveInBlockCurrID,
      pic.moveItem,
      firstContCurveInBlockCurrID - 1
    );
  }
}

export function clearContCurves(modVarObjList, origModVarObjArr) {
  let contCurveObjList = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

  for (let i = contCurveObjList.length - 1; i >= 0; i--) {
    shiftContCurves(modVarObjList, origModVarObjArr, contCurveObjList.length, pic.deleteItem);
    contCurveObjList.pop();
  }
}

export function getContCurvePercOnPrEP(contCurveObjList, contCurveCurrID, contCurvePeriodCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.contCurvePercOnPrEP][contCurvePeriodCurrID - 1];
}

export function setContCurvePercOnPrEP(contCurveObjList, contCurveCurrID, contCurvePeriodCurrID, valueInt) {
  contCurveObjList[contCurveCurrID - 1][pisc.contCurvePercOnPrEP][contCurvePeriodCurrID - 1] = valueInt;
}

export function getPercClientsRemainPrEP(contCurveObjList, contCurveCurrID, monthByte) {
  /* month 0 is actually used, so no -1 is used. */
  return contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP][monthByte];
}

export function setPercClientsRemainPrEP(contCurveObjList, contCurveCurrID, monthByte, value) {
  /* month 0 is actually used, so no -1 is used. */
  contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP][monthByte] = value;
}

export function getPercClientsRemainPrEPArray(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP];
}

export function setPercClientsRemainPrEPArray(contCurveObjList, contCurveCurrID, value) {
  contCurveObjList[contCurveCurrID - 1][pisc.percClientsRemainPrEP] = value;
}

export function getAvgMonthsOnPrEPInit(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.avgMonthsOnPrEPInit];
}

export function getAvgMonthsProdDistInit(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.avgMonthsProdDistInit];
}

export function getAvgMonthsProdDistCont(contCurveObjList, contCurveCurrID) {
  return contCurveObjList[contCurveCurrID - 1][pisc.avgMonthsProdDistCont];
}

/* input - priorPopMethodEligMVTag */

export function getPriorPopMethodEligMethodObj(methodMstIDStr, priorPopMethodEligObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < priorPopMethodEligObjArr.length && gbu.isEmpty(methodObj)) {
    if (priorPopMethodEligObjArr[i][pisc.methodMstIDPPPE] === methodMstIDStr) {
      methodObj = priorPopMethodEligObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstIDStr, priorPopCurrID) {
  const priorPopMethodEligMethodObj = getPriorPopMethodEligMethodObj(methodMstIDStr, priorPopMethodEligObjArr);
  return priorPopMethodEligMethodObj[pisc.methodValuePPPE][priorPopCurrID - 1][pisc.priorPopValuePPPE];
}

export function setPriorPopMethodElig(priorPopMethodEligObjArr, methodMstIDStr, priorPopCurrID, valueStr) {
  const priorPopMethodEligMethodObj = getPriorPopMethodEligMethodObj(methodMstIDStr, priorPopMethodEligObjArr);
  priorPopMethodEligMethodObj[pisc.methodValuePPPE][priorPopCurrID - 1][pisc.priorPopValuePPPE] = valueStr;
}

/***************************   Initiation / Program data - Data elements   *************************/

export function getDataElements(modVarObjList) {
  let dataElement1DBoolArray = [];
  const dataElement1DStrArray = getModVarValue(modVarObjList, pisc.dataElementsMVTag);

  for (let de = 1; de <= dataElement1DStrArray.length; de++) {
    dataElement1DBoolArray.push(dataElement1DStrArray[de - 1] === pisc.includeDEMstID);
  }

  return dataElement1DBoolArray;
}

/* Deconstructs the program data setting period object and saves it back to the modVar. */
export function setDataElements(modVarObjList, dataElement1DBoolArray) {
  let dataElement1DStrArray = [];

  for (let de = 1; de <= dataElement1DBoolArray.length; de++) {
    dataElement1DStrArray.push(dataElement1DBoolArray[de - 1] ? pisc.includeDEMstID : pisc.excludeDEMstID);
  }

  setModVarValue(modVarObjList, pisc.dataElementsMVTag, dataElement1DStrArray);
}

export function getDataElementSelected(dataElement1DBoolArray, dataElementCurrID) {
  return dataElement1DBoolArray[dataElementCurrID - 1];
}

export function setDataElementSelected(dataElement1DBoolArray, dataElementCurrID, valueBool) {
  dataElement1DBoolArray[dataElementCurrID - 1] = valueBool;
}

export function getInitiationMethodObj(methodMstIDStr, initiationObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < initiationObjArr.length && gbu.isEmpty(methodObj)) {
    if (initiationObjArr[i][pisc.initMethodMstID] === methodMstIDStr) {
      methodObj = initiationObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getDataElementNum(methodMstIDStr, initiationObjArr, t, dataElementFieldStr, priorPopCurrID) {
  let initiationMethodObj = getInitiationMethodObj(methodMstIDStr, initiationObjArr);
  return initiationMethodObj[pisc.initObjArr][t][dataElementFieldStr][priorPopCurrID - 1];
}

export function moreThanInitsChecked(modVarObjArr) {
  const dataElements1DBoolArray = getDataElements(modVarObjArr);
  return dataElements1DBoolArray[pic.numReinitPrEPCurrID - 1];
}

/***********************************************************************************/
/***************************************   Targets   *******************************/
/***********************************************************************************/

/************************   Populations - Population size   ************************/

export function getPopSize(popSize1DFltArray, priorPopCurrID) {
  return popSize1DFltArray[priorPopCurrID - 1];
}

export function setPopSize(popSize1DFltArray, priorPopCurrID, value) {
  popSize1DFltArray[priorPopCurrID - 1] = value;
}

/*****************   Populations - % in PrEP-eligible geographic areas   **********/

export function getPercPrEPElig(percPrEPElig1DFltArray, priorPopCurrID) {
  return percPrEPElig1DFltArray[priorPopCurrID - 1];
}

export function setPercPrEPElig(percPrEPElig1DFltArray, priorPopCurrID, value) {
  percPrEPElig1DFltArray[priorPopCurrID - 1] = value;
}

/*********************   Populations - HIV prevalence   ***************************/

export function getHIVPrev(HIVPrev1DFltArray, priorPopCurrID) {
  return HIVPrev1DFltArray[priorPopCurrID - 1];
}

export function setHIVPrev(HIVPrev1DFltArray, priorPopCurrID, value) {
  HIVPrev1DFltArray[priorPopCurrID - 1] = value;
}

/****************************   Populations - % indicated for PrEp   **************/

export function getPercIndicPrEP(percIndicPrEP1DFltArray, priorPopCurrID) {
  return percIndicPrEP1DFltArray[priorPopCurrID - 1];
}

export function setPercIndicPrEP(percIndicPrEP1DFltArray, priorPopCurrID, value) {
  percIndicPrEP1DFltArray[priorPopCurrID - 1] = value;
}

/**************************   Trends - Scale-up trends   **************************/

// export function createScaleUpTrendObj(mstIDStr, nameStr, scaleUpTypeMstIDStr, initSpeedFlt, midpointFlt,
//                                       percClientsInitPrEP1DIntArray) {
//
//     return ({
//         [pisc.scaleUpTrendMstID]    : mstIDStr,
//         [pisc.scaleUpTypeMstID]     : scaleUpTypeMstIDStr,
//         [pisc.scaleUpTrendName]     : nameStr,
//         [pisc.initSpeed]            : initSpeedFlt,
//         [pisc.midpoint]             : midpointFlt,
//         [pisc.percClientsInitPrEP]  : percClientsInitPrEP1DIntArray,
//     });
//
// }

// export function setDefScaleUpTrendNames(scaleUpTrendsObjList) {
//
//     /* Currently, we always show a fixed number of rows for scale-up trends. */
//     for (let st = 1; st <= pic.numDefScaleUpTrends; st++) {
//
//         const scaleUpTrendName = getScaleUpTrendName(scaleUpTrendsObjList, st);
//
//         if (scaleUpTrendName === "") {
//
//             setScaleUpTrendName(scaleUpTrendsObjList, st, piu.getDefScaleUpTrendName(st));
//
//         }
//
//     }
//
// }

export function setDefScaleUpTrendNames(scaleUpTrendObjList) {
  const numScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);
  for (let i = 1; i <= numScaleUpTrends; i++) {
    const scaleUpTrendMstID = getScaleUpTrendMstID(scaleUpTrendObjList, i);
    const scaleUpTrendName = getScaleUpTrendName(scaleUpTrendObjList, i);

    if (scaleUpTrendName === "") {
      setScaleUpTrendName(scaleUpTrendObjList, i, piu.getDefScaleUpTrendNameFromMstID(scaleUpTrendMstID));
    }
  }
}

/* Determines if the user turned on (checked off) any scale-up trends. */
export function scaleUpTrendSelected(scaleUpTrendObjList) {
  return scaleUpTrendObjList.length > 0;
}

/* Default scale-up trends are active if they exist in the scale-up trend object
   list. */
export function getDefScaleUpTrendActive(scaleUpTrendObjList, scaleUpTrendMstIDStr) {
  let activeBool = false;

  let i = 0;
  while (i < scaleUpTrendObjList.length && !activeBool) {
    let obj = scaleUpTrendObjList[i];

    if (obj[pisc.scaleUpTrendMstID] === scaleUpTrendMstIDStr) {
      activeBool = true;
    }

    i++;
  }

  return activeBool;
}

export function setDefScaleUpTrendActive(modVarObjList, origModVarObjArr, scaleUpTrendsMstIDStr, valueBool) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  const alreadyActive = getDefScaleUpTrendActive(scaleUpTrendObjList, scaleUpTrendsMstIDStr);
  const totalNumScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  /* If we are activating the scale-up trend, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom scale-up trends should always be added after all default ones. */
    if (!alreadyActive) {
      const origScaleUpTrendObjList = getModVarValue(origModVarObjArr, pisc.scaleUpTrendsMVTag);
      /* The original ModVar object array is cloned when the user clicks Add, but we need to
               clone again here because we'll be passing it along again and we want to pass the
               unadulterated version. */
      let itemObj = structuredClone(
        getItemObj(origScaleUpTrendObjList, pic.scaleUpTrendItems, scaleUpTrendsMstIDStr, "")
      );
      itemObj[pisc.scaleUpTrendMstID] = scaleUpTrendsMstIDStr;
      itemObj[pisc.scaleUpTrendName] = piu.getDefScaleUpTrendNameFromMstID(scaleUpTrendsMstIDStr);

      //const numCustomScaleUpTrends = getNumCustomScaleUpTrends(scaleUpTrendObjList);
      const numActiveDefScaleUpTrends = getNumActiveDefScaleUpTrends(scaleUpTrendObjList);

      /* If there are no scale-up trends, just push the default one onto the array. */
      if (totalNumScaleUpTrends === 0) {
        scaleUpTrendObjList.push(itemObj);
        /* Add 1 since we just changed the number of scale-up trend objects. */
        shiftScaleUpTrends(modVarObjList, origModVarObjArr, totalNumScaleUpTrends + 1, pic.addItem);
      } else if (numActiveDefScaleUpTrends === 0) {
        /* Otherwise, if there are no active default scale-up trends, add the
               default one to the front of the array. */
        scaleUpTrendObjList.splice(0, 0, itemObj);
        shiftScaleUpTrends(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default scale-up trend. Place the default pop we are
               activating just before the first default scale-up trend we encounter with a
               higher current ID. */
        const defScaleUpTrendCurrID = piu.getDefScaleUpTrendCurrID(scaleUpTrendsMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumScaleUpTrends && !stop) {
          const scaleUpTrendMstIDLoop = getScaleUpTrendMstID(scaleUpTrendObjList, i + 1);

          if (!scaleUpTrendMstIDLoop.includes(pisc.customItemMstID)) {
            const defScaleUpTrendCurrIDLoop = piu.getDefScaleUpTrendCurrID(scaleUpTrendMstIDLoop);

            if (defScaleUpTrendCurrID < defScaleUpTrendCurrIDLoop) {
              scaleUpTrendObjList.splice(i, 0, itemObj);
              shiftScaleUpTrends(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom scale-up trend. If we're activating the last
                       default scale-up trend, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom scale-up trend we encounter. */
            scaleUpTrendObjList.splice(i, 0, itemObj);
            shiftScaleUpTrends(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default scale-up trend yet, we must be adding the last one and there
                   must not be any custom scale-up trends.*/
        if (!stop) {
          scaleUpTrendObjList.push(itemObj);
          /* Add 1 since we just changed the number of scale-up trend objects. */
          shiftScaleUpTrends(modVarObjList, origModVarObjArr, totalNumScaleUpTrends + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumScaleUpTrends && !stop) {
      let obj = scaleUpTrendObjList[i];

      if (obj[pisc.scaleUpTrendMstID] === scaleUpTrendsMstIDStr) {
        /* Remove the scale-up trend after shifting things that depend on it in case we need
                   the master ID. */
        shiftScaleUpTrends(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        scaleUpTrendObjList.splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts scale-up trends for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by scale-up trend
   should be in the same order as the scale-up trend objects themselves. */
export function shiftScaleUpTrends(
  modVarObjList,
  origModVarObjArr,
  scaleUpTrendCurrID,
  instructionInt,
  scaleUpTrendToMoveCurrID
) {
  //let HIVPrev1DFltArray = getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);
  const scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  if (instructionInt === pic.addItem) {
  } else if (instructionInt === pic.deleteItem) {
    resetPriorPopScaleUpTrendMstIDs(methodObjList, priorPopObjList, scaleUpTrendObjList, scaleUpTrendCurrID);
  } else if (instructionInt === pic.moveItem) {
  }
}

export function getTotalNumScaleUpTrends(scaleUpTrendObjList) {
  return scaleUpTrendObjList.length;
}

export function getScaleUpTrendCurrID(scaleUpTrendObjList, scaleUpTrendMstID) {
  const numScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numScaleUpTrends && !stop) {
    const mstID = getScaleUpTrendMstID(scaleUpTrendObjList, currIDInt);

    if (mstID === scaleUpTrendMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getScaleUpTrendName(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendName];
}

export function setScaleUpTrendName(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendName] = value;
}

export function getScaleUpTrendNames(scaleUpTrendObjList) {
  let names = [];

  const numScaleUpTypes = getTotalNumScaleUpTrends(scaleUpTrendObjList);
  for (let st = 1; st <= numScaleUpTypes; st++) {
    names.push(getScaleUpTrendName(scaleUpTrendObjList, st));
  }

  return names;
}

export function getScaleUpTrendMstID(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendMstID];
}

export function setScaleUpTrendMstID(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendMstID] = value;
}

export function getScaleUpTrendScaleUpTypeMstID(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTypeMstID];
}

export function setScaleUpTrendScaleUpTypeMstID(scaleUpTrendObjList, scaleUpTrendCurrID, valueStr) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTypeMstID] = valueStr;
}

export function getScaleUpTrendCustom(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.scaleUpTrendMstID].includes(pisc.customItemMstID);
}

export function getNumActiveDefScaleUpTrends(scaleUpTrendObjList) {
  let numActiveDefScaleUpTrends = 0;

  for (let i = 0; i < scaleUpTrendObjList.length; i++) {
    let obj = scaleUpTrendObjList[i];

    if (!obj[pisc.scaleUpTrendMstID].includes(pisc.customItemMstID)) {
      numActiveDefScaleUpTrends++;
    }
  }

  return numActiveDefScaleUpTrends;
}

export function getNumCustomScaleUpTrends(scaleUpTrendObjList) {
  let numCustomScaleUpTrends = 0;

  for (let i = 0; i < scaleUpTrendObjList.length; i++) {
    let obj = scaleUpTrendObjList[i];

    if (obj[pisc.scaleUpTrendMstID].includes(pisc.customItemMstID)) {
      numCustomScaleUpTrends++;
    }
  }

  return numCustomScaleUpTrends;
}

export function getCustomScaleUpTrendsCurrIDArray(scaleUpTrendObjList) {
  /* Add the current IDs of all custom scale-up trends to an array. */
  let customScaleUpTrendCurrID1DIntArray = [];

  const totalNumScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);

  for (let i = 1; i <= totalNumScaleUpTrends; i++) {
    const customBool = getScaleUpTrendCustom(scaleUpTrendObjList, i);

    if (customBool) {
      customScaleUpTrendCurrID1DIntArray.push(i);
    }
  }

  return customScaleUpTrendCurrID1DIntArray;
}

export function addCustomScaleUpTrend(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  const scaleUpTrendNum = (getNumCustomScaleUpTrends(scaleUpTrendObjList) + 1).toString();

  const origScaleUpTrendObjList = getModVarValue(origModVarObjArr, pisc.scaleUpTrendsMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = structuredClone(
    getItemObj(origScaleUpTrendObjList, pic.scaleUpTrendItems, pisc.scaleUpTrendAMstID, "")
  );
  itemObj[pisc.scaleUpTrendMstID] = `${pisc.customItemMstID}-${uuidv4()}`;
  itemObj[pisc.scaleUpTrendName] = RS(SC.GB_stCustomScaleUpTrend) + " " + scaleUpTrendNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    scaleUpTrendObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    scaleUpTrendObjList.push(itemObj);
    const totalNumScaleUpTrends = getTotalNumScaleUpTrends(scaleUpTrendObjList);
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, totalNumScaleUpTrends, pic.addItem);
  }
}

export function deleteCustomScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendCurrID1DIntArray) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  for (let i = scaleUpTrendCurrID1DIntArray.length; i >= 1; i--) {
    /* Remove the scale-up trend after shifting things that depend on it in case we need
          the master ID. */
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendCurrID1DIntArray[i - 1], pic.deleteItem);
    scaleUpTrendObjList.splice(scaleUpTrendCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendCurrID1DIntArray, direction) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  /* Instead of moving the custom scale-up trend(s) we want to move, move the one above/below them.
       If moving the scale-up trend block down, put the scale-up trend immediately after the block
       before the first scale-up trend in block. If moving the scale-up trend block up, put the
       scale-up trend immediately below the block after the last scale-up trend in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default scale-up trends always come before custom ones. */

  const customScaleUpTrendsCurrIDArray = getCustomScaleUpTrendsCurrIDArray(scaleUpTrendObjList);

  const firstScaleUpTrendInBlockCurrID = scaleUpTrendCurrID1DIntArray[0];
  const lastScaleUpTrendInBlockCurrID = scaleUpTrendCurrID1DIntArray[scaleUpTrendCurrID1DIntArray.length - 1];

  /* If moving the scale-up trend(s) down in the list and there's another custom priority
       pop after it, move that custom scale-up trend before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastScaleUpTrendInBlockCurrID !== customScaleUpTrendsCurrIDArray[customScaleUpTrendsCurrIDArray.length - 1]
  ) {
    const scaleUpTrendObjArray = scaleUpTrendObjList.splice(lastScaleUpTrendInBlockCurrID + 1 - 1, 1);
    scaleUpTrendObjList.splice(firstScaleUpTrendInBlockCurrID - 1, 0, scaleUpTrendObjArray[0]);
    shiftScaleUpTrends(
      modVarObjList,
      origModVarObjArr,
      firstScaleUpTrendInBlockCurrID,
      pic.moveItem,
      lastScaleUpTrendInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstScaleUpTrendInBlockCurrID !== customScaleUpTrendsCurrIDArray[0]) {
    const scaleUpTrendObjArray = scaleUpTrendObjList.splice(firstScaleUpTrendInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    scaleUpTrendObjList.splice(lastScaleUpTrendInBlockCurrID - 1 - 1 + 1, 0, scaleUpTrendObjArray[0]);
    shiftScaleUpTrends(
      modVarObjList,
      origModVarObjArr,
      firstScaleUpTrendInBlockCurrID,
      pic.moveItem,
      firstScaleUpTrendInBlockCurrID - 1
    );
  }
}

export function clearScaleUpTrends(modVarObjList, origModVarObjArr) {
  let scaleUpTrendObjList = getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);

  for (let i = scaleUpTrendObjList.length - 1; i >= 0; i--) {
    shiftScaleUpTrends(modVarObjList, origModVarObjArr, scaleUpTrendObjList.length, pic.deleteItem);
    scaleUpTrendObjList.pop();
  }
}

export function getScaleUpTrendInitSpeed(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.initSpeed];
}

export function setScaleUpTrendInitSpeed(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.initSpeed] = value;
}

export function getScaleUpTrendMidpoint(scaleUpTrendObjList, scaleUpTrendCurrID) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.midpoint];
}

export function setScaleUpTrendMidpoint(scaleUpTrendObjList, scaleUpTrendCurrID, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.midpoint] = value;
}

export function getScaleUpTrendPercClientsInitPrEP(scaleUpTrendObjList, scaleUpTrendCurrID, monthYrIdx) {
  return scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.percClientsInitPrEP][monthYrIdx - 1];
}

export function setScaleUpTrendPercClientsInitPrEP(scaleUpTrendObjList, scaleUpTrendCurrID, monthYrIdx, value) {
  scaleUpTrendObjList[scaleUpTrendCurrID - 1][pisc.percClientsInitPrEP][monthYrIdx - 1] = value;
}

/* result - initByMonthMVTag */

export function getInitByMonth(initByMonth2DIntArr, methodCurrID, monthYrIdx) {
  return initByMonth2DIntArr[methodCurrID - 1][monthYrIdx - 1];
}

/* result - currPrEPByMonthMVTag */

export function getCurrOnPrEPByMonth(currOnPrEPByMonth2DIntArr, methodCurrID, monthYrIdx) {
  return currOnPrEPByMonth2DIntArr[methodCurrID - 1][monthYrIdx - 1];
}

/* input - targSelectedMethodsMVTag */

export function targSelectedMethods(selectedMethods1DBoolArr, methodCurrID, valueBool) {
  let value;

  if (typeof valueBool !== "undefined") {
    selectedMethods1DBoolArr[methodCurrID - 1] = valueBool;
  } else {
    value = selectedMethods1DBoolArr[methodCurrID - 1];
  }

  return value;
}

export function getNumSelectedMethods(selectedMethods1DBoolArr) {
  let numSelectedMethods = 0;

  for (let i = 0; i < selectedMethods1DBoolArr.length; i++) {
    if (selectedMethods1DBoolArr[i]) {
      numSelectedMethods++;
    }
  }

  return numSelectedMethods;
}

/**********************************   Options   ************************************/

/* input - coverageByPriorityPopMVTag */

export function getPotUsersMethodObj(methodMstIDStr, potUsersToTakePrEPObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < potUsersToTakePrEPObjArray.length && gbu.isEmpty(methodObj)) {
    if (potUsersToTakePrEPObjArray[i][pisc.methodMstIDPU] === methodMstIDStr) {
      methodObj = potUsersToTakePrEPObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getPotUsersToTakePrEPTotal(potUsersToTakePrEPObjArray, methodObjArr, methodMstIDStr) {
  let totalPotUsersInt = 0;

  const methodCurrID = getMethodCurrID(methodObjArr, methodMstIDStr);

  for (let pp = 1; pp <= potUsersToTakePrEPObjArray[methodCurrID - 1].length; pp++) {
    const potUsersInt = getPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEPObjArray, pp);
    totalPotUsersInt += potUsersInt;
  }

  return totalPotUsersInt;
}

export function getPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEPObjArray, priorPopCurrID) {
  const potUsersMethodObj = getPotUsersMethodObj(methodMstIDStr, potUsersToTakePrEPObjArray);
  return potUsersMethodObj[pisc.coveragePU][priorPopCurrID - 1];
}

export function setPotUsersToTakePrEP(methodMstIDStr, potUsersToTakePrEPObjArray, priorPopCurrID, valueFlt) {
  let potUsersMethodObj = getPotUsersMethodObj(methodMstIDStr, potUsersToTakePrEPObjArray);
  potUsersMethodObj[pisc.coveragePU][priorPopCurrID - 1] = valueFlt;
}

/* output (shows on input table though) - coverageByPriorityPopMVTag */

export function getActUsersMethodObj(methodMstIDStr, actUsersToTakePrEPObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < actUsersToTakePrEPObjArray.length && gbu.isEmpty(methodObj)) {
    if (actUsersToTakePrEPObjArray[i][pisc.methodMstIDAU] === methodMstIDStr) {
      methodObj = actUsersToTakePrEPObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getActUsersToTakePrEP_TSP(methodMstIDStr, actUsersToTakePrEPObjArray, priorPopCurrID) {
  const actUsersMethodObj = getActUsersMethodObj(methodMstIDStr, actUsersToTakePrEPObjArray);
  return actUsersMethodObj["coverage"][priorPopCurrID - 1];
}

export function getActUsersToTakePrEP_DRD(methodMstIDStr, actUsersToTakePrEPObjArray, priorPopCurrID) {
  const actUsersMethodObj = getActUsersMethodObj(methodMstIDStr, actUsersToTakePrEPObjArray);
  return actUsersMethodObj[pisc.coverageDRD_AU][priorPopCurrID - 1];
}

/* input - covConstUnitsDispMVTag */

export function getCovConstrUnitsDispMethodObj(methodMstIDStr, covConstrUnitsDispObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < covConstrUnitsDispObjArray.length && gbu.isEmpty(methodObj)) {
    if (covConstrUnitsDispObjArray[i][pisc.methodMstIDCwCUD] === methodMstIDStr) {
      methodObj = covConstrUnitsDispObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getCovConstrUnitsDisp(methodMstIDStr, covConstrUnitsDispObjArray) {
  const covConstrUnitsDispMethodObj = getCovConstrUnitsDispMethodObj(methodMstIDStr, covConstrUnitsDispObjArray);
  return covConstrUnitsDispMethodObj[pisc.constraintsCwCUD];
}

export function setCovConstrUnitsDisp(methodMstIDStr, covConstrUnitsDispObjArray, valueFlt) {
  let covConstrUnitsDispMethodObj = getCovConstrUnitsDispMethodObj(methodMstIDStr, covConstrUnitsDispObjArray);
  covConstrUnitsDispMethodObj[pisc.constraintsCwCUD] = valueFlt;
}

// /* input - covConstrByPriorityPopMVTag */
//
// export function getPotUsersConstrMethodObj(methodMstIDStr, potUsersToTakePrEPConstrObjArr) {
//
//     let methodObj = {};
//
//     let i = 0;
//     while ((i < potUsersToTakePrEPConstrObjArr.length) && gbu.isEmpty(methodObj)) {
//
//         if (potUsersToTakePrEPConstrObjArr[i][pisc.methodMstIDPUwC] === methodMstIDStr) {
//
//             methodObj = potUsersToTakePrEPConstrObjArr[i];
//
//         }
//
//         i++;
//
//     }
//
//     return methodObj;
//
// }
//
// export function getPotUsersToTakePrEPConstr(methodMstIDStr, potUsersToTakePrEPConstrObjArr, priorPopCurrID) {
//
//     const potUsersConstrMethodObj = getPotUsersConstrMethodObj(methodMstIDStr, potUsersToTakePrEPConstrObjArr);
//     return potUsersConstrMethodObj[pisc.coveragePUwC][priorPopCurrID - 1];
//
// }
//
// export function setPotUsersToTakePrEPConstr(methodMstIDStr, potUsersToTakePrEPConstrObjArr, priorPopCurrID, valueFlt) {
//
//     let potUsersConstrMethodObj = getPotUsersConstrMethodObj(methodMstIDStr, potUsersToTakePrEPConstrObjArr);
//     potUsersConstrMethodObj[pisc.coveragePUwC][priorPopCurrID - 1] = valueFlt;
//
// }

/* input - targetsByPriorityPopMVTag */

export function getTargClientsMethodObj(methodMstIDStr, targClientsInitObjArray) {
  let methodObj = {};

  let i = 0;
  while (i < targClientsInitObjArray.length && gbu.isEmpty(methodObj)) {
    if (targClientsInitObjArray[i][pisc.methodMstIDTC] === methodMstIDStr) {
      methodObj = targClientsInitObjArray[i];
    }

    i++;
  }

  return methodObj;
}

export function getTargClientsInit(methodMstIDStr, targClientsInitObjArray, priorPopCurrID) {
  const targClientsMethodObj = getTargClientsMethodObj(methodMstIDStr, targClientsInitObjArray);
  return targClientsMethodObj[pisc.targetTC][priorPopCurrID - 1];
}

export function setTargClientsInit(methodMstIDStr, targClientsInitObjArray, priorPopCurrID, valueFlt) {
  let targClientsMethodObj = getTargClientsMethodObj(methodMstIDStr, targClientsInitObjArray);
  targClientsMethodObj[pisc.targetTC][priorPopCurrID - 1] = valueFlt;
}

/*******************************   Results   ************************************/

/* result - targIndTableMVTag */

export function PrEP_CT_TI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    targIndObj[pisc.PREP_CT_TI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = targIndObj[pisc.PREP_CT_TI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

export function currOnPrEP_TI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    targIndObj[pisc.currOnPrEPTI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = targIndObj[pisc.currOnPrEPTI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

export function PrEP_NEW_TI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    targIndObj[pisc.PrEP_NEW_TI][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = targIndObj[pisc.PrEP_NEW_TI][methodCurrID - 1][priorPopCurrID - 1];
  }

  return value;
}

export function PrEP_recipients_TI(targIndObj, methodCurrID, priorPopCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    targIndObj["recipients"][methodCurrID - 1][priorPopCurrID - 1] = valueFlt;
  } else {
    value = targIndObj.recipients?.[methodCurrID - 1]?.[priorPopCurrID - 1];
  }

  return value;
}

/* result - costsByPopTypeMVTag */

export function getTotalCosts(totalCosts2DIntArray, methodCurrID, priorPopCurrID) {
  return totalCosts2DIntArray[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - infAvertedByPopTypeMVTag */

export function getTotalInfAvtd(totalInfAvtd2DIntArray, methodCurrID, priorPopCurrID) {
  return totalInfAvtd2DIntArray[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - initByPopTypeMVTag */

export function getTotalInits(totalInits2DIntArray, methodCurrID, priorPopCurrID) {
  return totalInits2DIntArray?.[methodCurrID - 1]?.[priorPopCurrID - 1] ?? 0;
}

/***********************************************************************************/
/*******************************   Costs   *****************************************/
/***********************************************************************************/

//==================================================================================================================
//
//  Continuation visit shedules
//
//  NOTE: There is an object array for Detailed Costs and one for Costs Lite, but
//  but the object structure is the same for both, so both ModVars can utilize the same set of
//  methods.
//
//==================================================================================================================

export function getContVisitObj(contVisitObjArr, currID) {
  let contVisitObj = {};

  contVisitObj = contVisitObjArr[currID - 1];

  return contVisitObj;
}

export function getContVisitMstID(contVisitObjList, contVisitCurrID) {
  const contVisitObj = getContVisitObj(contVisitObjList, contVisitCurrID);
  return contVisitObj[pisc.contVisitMstID];
}

export function setDefContVisitSchedNames(contVisitSchedObjList) {
  const numContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  for (let i = 1; i <= numContVisitScheds; i++) {
    const contVisitSchedMstID = getContVisitSchedMstID(contVisitSchedObjList, i);
    const contVisitSchedName = getContVisitSchedName(contVisitSchedObjList, i);

    if (contVisitSchedName === "") {
      setContVisitSchedName(contVisitSchedObjList, i, piu.getDefContVisitSchedNameFromMstID(contVisitSchedMstID));
    }
  }
}

export function getContVisitSchedObjByMstID(contVisitSchedObjArr, mstIDStr) {
  let contVisitSchedObj = {};

  let i = 0;
  while (i < contVisitSchedObjArr.length) {
    if (contVisitSchedObjArr[i][pisc.contVisitSchedMstID] === mstIDStr) {
      contVisitSchedObj = contVisitSchedObjArr[i];
    }

    i++;
  }

  return contVisitSchedObj;
}

export function getContVisitSchedObj(contVisitSchedObjArr, currID) {
  let contVisitSchedObj = {};

  contVisitSchedObj = contVisitSchedObjArr[currID - 1];

  return contVisitSchedObj;
}

/* Determines if the user turned on (checked off) any continuation visit schedules. */
export function contVisitSchedSelected(contVisitSchedObjList) {
  return contVisitSchedObjList.length > 0;
}

export function getNumActiveDefContVisitSchedules(contVisitSchedObjArr) {
  let numActiveDefContVisitSchedules = 0;

  for (let i = 0; i < contVisitSchedObjArr.length; i++) {
    let obj = contVisitSchedObjArr[i];

    if (!obj[pisc.contVisitSchedMstID].includes(pisc.customItemMstID)) {
      numActiveDefContVisitSchedules++;
    }
  }

  return numActiveDefContVisitSchedules;
}

/* Default continuation visit schedules are active if they exist in the continuation visit schedule object
   list. */
export function getDefContVisitSchedActive(contVisitSchedObjList, mstIDStr) {
  const contVisitSchedObj = getContVisitSchedObjByMstID(contVisitSchedObjList, mstIDStr);
  return !gbu.isEmpty(contVisitSchedObj);
}

export function setDefContVisitSchedActive(modVarObjList, origModVarObjArr, mstIDStr, valueBool) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);

  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);

  const alreadyActive = getDefContVisitSchedActive(contVisitSchedObjList, mstIDStr);
  const totalNumContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  /* If we are activating the continuation visit schedule, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom continuation visit schedules should always be added after all default ones. */
    if (!alreadyActive) {
      const origContVisitSchedObjList = getModVarValue(origModVarObjArr, modVarTag);

      let itemObj = structuredClone(getItemObj(origContVisitSchedObjList, pic.contVisitSchedItems, mstIDStr, ""));
      itemObj[pisc.contVisitSchedMstID] = mstIDStr;
      itemObj[pisc.contVisitSchedName] = piu.getDefContVisitSchedNameFromMstID(mstIDStr);

      const numActiveDefContVisitScheds = getNumActiveDefContVisitSchedules(contVisitSchedObjList);

      /* If there are no continuation visit schedules, just push the default one onto the array. */
      if (totalNumContVisitScheds === 0) {
        contVisitSchedObjList.push(itemObj);
        /* Add 1 since we just changed the number of continuation visit schedule objects. */
        shiftContVisitScheds(modVarObjList, origModVarObjArr, totalNumContVisitScheds + 1, pic.addItem);
      } else if (numActiveDefContVisitScheds === 0) {
        /* Otherwise, if there are no active default continuation visit schedules, add the
               default one to the front of the array. */
        contVisitSchedObjList.splice(0, 0, itemObj);
        shiftContVisitScheds(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default continuation visit schedule. Place the default pop we are
               activating just before the first default continuation visit schedule we encounter with a
               higher current ID. */
        const defContVisitSchedCurrID = piu.getDefContVisitSchedCurrID(mstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumContVisitScheds && !stop) {
          const contVisitSchedMstIDLoop = getContVisitSchedMstID(contVisitSchedObjList, i + 1);

          if (!contVisitSchedMstIDLoop.includes(pisc.customItemMstID)) {
            const defContVisitSchedCurrIDLoop = piu.getDefContVisitSchedCurrID(contVisitSchedMstIDLoop);

            if (defContVisitSchedCurrID < defContVisitSchedCurrIDLoop) {
              contVisitSchedObjList.splice(i, 0, itemObj);
              shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom continuation visit schedule. If we're activating the last
                       default continuation visit schedule, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom continuation visit schedule we encounter. */
            contVisitSchedObjList.splice(i, 0, itemObj);
            shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default continuation visit schedule yet, we must be adding the last one and there
                   must not be any custom continuation visit schedules.*/
        if (!stop) {
          contVisitSchedObjList.push(itemObj);
          /* Add 1 since we just changed the number of continuation visit schedule objects. */
          shiftContVisitScheds(modVarObjList, origModVarObjArr, totalNumContVisitScheds + 1, pic.addItem);
        }
      }
    }
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumContVisitScheds && !stop) {
      let contVisitSchedObj = contVisitSchedObjList[i];

      if (contVisitSchedObj[pisc.contVisitSchedMstID] === mstIDStr) {
        /* Remove the scale-up trend after shifting things that depend on it in case we need
                   the master ID. */
        shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        contVisitSchedObjList.splice(i, 1);
        stop = true;
      }

      i++;
    }
  }
}

/* Shifts continuation visit schedules for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by continuation visit schedule
   should be in the same order as the continuation visit schedule objects themselves. */
export function shiftContVisitScheds(
  modVarObjList,
  origModVarObjArr,
  contVisitSchedCurrID,
  instructionInt,
  contVisitSchedToMoveCurrID
) {
  let priorPopObjList = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  let contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);

  const usingCostsLiteBool = piu.usingCostsLite(costingModeMstID);

  /* Note: Since continuation visit schedules vary by method, if one is added/deleted/moved,
       only do it for the selected method. */

  if (instructionInt === pic.addItem) {
    if (usingCostsLiteBool) {
      resetPriorPopContVisitSchedMstIDs(
        priorPopObjList,
        costCatLiteObjList,
        methodObjArr,
        contVisitSchedLiteObjList,
        contVisitSchedCurrID,
        pic.addItem
      );
    }
  } else if (instructionInt === pic.deleteItem) {
    if (usingCostsLiteBool) {
      resetPriorPopContVisitSchedMstIDs(
        priorPopObjList,
        costCatLiteObjList,
        methodObjArr,
        contVisitSchedLiteObjList,
        contVisitSchedCurrID,
        pic.deleteItem
      );
    }
  } else if (instructionInt === pic.moveItem) {
  }
}

export function resetContVisitSchedContVisitMstIDs(contVisitSchedObjList, contVisitObjList, contVisitCurrID) {
  const numContVisitSchedules = getTotalNumContVisitSchedules(contVisitSchedObjList);

  /* If a priority population was using the continuation curve, give it the continuation curve
       master ID for the first continuation curve. */
  for (let cvs = 1; cvs <= numContVisitSchedules; cvs++) {
    for (let m = 1; m <= 13; m++) {
      const contVisitMstIDCVS = getContVisitSchedContVisitMstID(contVisitSchedObjList, cvs, m);
      const contVisitMstID = getContVisitMstID(contVisitObjList, contVisitCurrID);

      if (contVisitMstIDCVS === contVisitMstID) {
        let newContVisitMstID;
        if (contVisitCurrID !== 1) {
          newContVisitMstID = getContVisitMstID(contVisitObjList, 1);
        } else {
          newContVisitMstID = getContVisitMstID(contVisitObjList, 2);
        }

        setContVisitSchedContVisitMstID(contVisitSchedObjList, cvs, m, newContVisitMstID);
      }
    }
  }
}

export function getContVisitSchedName(contVisitSchedObjList, contVisitSchedCurrID) {
  const contVisitSchedObj = getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedName];
}

export function setContVisitSchedName(contVisitSchedObjList, contVisitSchedCurrID, value) {
  let contVisitSchedObj = getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  contVisitSchedObj[pisc.contVisitSchedName] = value;
}

export function getContVisitSchedNames(contVisitSchedObjList) {
  let names = [];

  const numContVisitSchedules = getTotalNumContVisitSchedules(contVisitSchedObjList);
  for (let cvs = 1; cvs <= numContVisitSchedules; cvs++) {
    names.push(getContVisitSchedName(contVisitSchedObjList, cvs));
  }

  return names;
}

export function getContVisitSchedMstID(contVisitSchedObjList, contVisitSchedCurrID) {
  const contVisitSchedObj = getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedMstID];
}

export function setContVisitSchedMstID(contVisitSchedObjList, contVisitSchedCurrID, value) {
  let contVisitSchedObj = getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  contVisitSchedObj[pisc.contVisitSchedMstID] = value;
}

export function getContVisitSchedContVisitMstID(contVisitSchedObjList, contVisitSchedCurrID, monthsAfterInit) {
  const contVisitSchedObj = getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedVisits][monthsAfterInit - 1];
}

export function setContVisitSchedContVisitMstID(
  contVisitSchedObjList,
  contVisitSchedCurrID,
  monthsAfterInit,
  valueStr
) {
  let contVisitSchedObj = getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  contVisitSchedObj[pisc.contVisitSchedVisits][monthsAfterInit - 1] = valueStr;
}

export function getTotalNumContVisitSchedules(contVisitSchedObjList) {
  return contVisitSchedObjList.length;
}

export function getContVisitSchedCurrID(contVisitSchedObjList, contVisitSchedMstID) {
  const numContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numContVisitScheds && !stop) {
    const mstID = getContVisitSchedMstID(contVisitSchedObjList, currIDInt);

    if (mstID === contVisitSchedMstID) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getContVisitSchedCustom(contVisitSchedObjList, contVisitSchedCurrID) {
  const contVisitSchedObj = getContVisitSchedObj(contVisitSchedObjList, contVisitSchedCurrID);
  return contVisitSchedObj[pisc.contVisitSchedMstID].includes(pisc.customItemMstID);
}

export function getNumCustomContVisitScheds(contVisitSchedObjArr) {
  let numCustomContVisitScheds = 0;

  for (let i = 0; i < contVisitSchedObjArr.length; i++) {
    let contVisitSchedObj = contVisitSchedObjArr[i];

    if (contVisitSchedObj[pisc.contVisitSchedMstID].includes(pisc.customItemMstID)) {
      numCustomContVisitScheds++;
    }
  }

  return numCustomContVisitScheds;
}

export function getCustomContVisitSchedsCurrIDArray(contVisitSchedObjList) {
  /* Add the current IDs of all custom continuation visit schedules to an array. */
  let customContVisitSchedCurrID1DIntArray = [];

  const totalNumContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);

  for (let i = 1; i <= totalNumContVisitScheds; i++) {
    const customBool = getContVisitSchedCustom(contVisitSchedObjList, i);

    if (customBool) {
      customContVisitSchedCurrID1DIntArray.push(i);
    }
  }

  return customContVisitSchedCurrID1DIntArray;
}

export function addCustomContVisitSched(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);

  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);

  const contVisitSchedNum = (getNumCustomContVisitScheds(contVisitSchedObjList) + 1).toString();

  const origContVisitSchedObjList = getModVarValue(origModVarObjArr, modVarTag);

  let itemObj = structuredClone(
    getItemObj(origContVisitSchedObjList, pic.contVisitSchedItems, pisc.contVisitSched1MstID, "")
  );
  itemObj[pisc.contVisitSchedMstID] = `${pisc.customItemMstID}-${uuidv4()}`;
  itemObj[pisc.contVisitSchedName] = RS(SC.GB_stCustomContVisitSched) + " " + contVisitSchedNum;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    contVisitSchedObjList.splice(itemToAddAfterCurrID, 0, itemObj);

    shiftContVisitScheds(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    contVisitSchedObjList.push(itemObj);

    const totalNumContVisitScheds = getTotalNumContVisitSchedules(contVisitSchedObjList);
    shiftContVisitScheds(modVarObjList, origModVarObjArr, totalNumContVisitScheds, pic.addItem);
  }
}

export function deleteCustomContVisitScheds(modVarObjList, origModVarObjArr, contVisitSchedCurrID1DIntArray) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);

  for (let i = contVisitSchedCurrID1DIntArray.length; i >= 1; i--) {
    /* Remove the scale-up trend after shifting things that depend on it in case we need
           the master ID. */
    shiftContVisitScheds(modVarObjList, origModVarObjArr, contVisitSchedCurrID1DIntArray[i - 1], pic.deleteItem);
    contVisitSchedObjList.splice(contVisitSchedCurrID1DIntArray[i - 1] - 1, 1);
  }
}

export function moveCustomContVisitSchedules(
  modVarObjList,
  origModVarObjArr,
  contVisitSchedCurrID1DIntArray,
  direction
) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);

  /* Instead of moving the custom continuation visit schedule(s) we want to move, move the one above/below them.
       If moving the continuation visit schedule block down, put the continuation visit schedule immediately after the block
       before the first continuation visit schedule in block. If moving the continuation visit schedule block up, put the
       continuation visit schedule immediately below the block after the last continuation visit schedule in the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default continuation visit schedules always come before custom ones. */

  const customContVisitSchedsCurrIDArray = getCustomContVisitSchedsCurrIDArray(contVisitSchedObjList);

  const firstContVisitSchedInBlockCurrID = contVisitSchedCurrID1DIntArray[0];
  const lastContVisitSchedInBlockCurrID = contVisitSchedCurrID1DIntArray[contVisitSchedCurrID1DIntArray.length - 1];

  /* If moving the continuation visit schedule(s) down in the list and there's another custom priority
       pop after it, move that custom continuation visit schedule before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastContVisitSchedInBlockCurrID !== customContVisitSchedsCurrIDArray[customContVisitSchedsCurrIDArray.length - 1]
  ) {
    const contVisitSchedObjArray = contVisitSchedObjList.splice(lastContVisitSchedInBlockCurrID + 1 - 1, 1);
    contVisitSchedObjList.splice(firstContVisitSchedInBlockCurrID - 1, 0, contVisitSchedObjArray[0]);
    shiftContVisitScheds(
      modVarObjList,
      origModVarObjArr,
      firstContVisitSchedInBlockCurrID,
      pic.moveItem,
      lastContVisitSchedInBlockCurrID + 1
    );
  } else if (direction === pic.moveUp && firstContVisitSchedInBlockCurrID !== customContVisitSchedsCurrIDArray[0]) {
    const contVisitSchedObjArray = contVisitSchedObjList.splice(firstContVisitSchedInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    contVisitSchedObjList.splice(lastContVisitSchedInBlockCurrID - 1 - 1 + 1, 0, contVisitSchedObjArray[0]);
    shiftContVisitScheds(
      modVarObjList,
      origModVarObjArr,
      firstContVisitSchedInBlockCurrID,
      pic.moveItem,
      firstContVisitSchedInBlockCurrID - 1
    );
  }
}

export function clearContVisitSchedules(modVarObjList, origModVarObjArr) {
  const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);
  let modVarTag = piu.getCostingModeSchedMVTag(costingModeMstID);

  let contVisitSchedObjList = getModVarValue(modVarObjList, modVarTag);

  for (let i = contVisitSchedObjList.length - 1; i >= 0; i--) {
    shiftContVisitScheds(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
    contVisitSchedObjList.pop();
  }
}

/************************   Cost categories (CC)   ********************************/

/* result - costStayOnPrEPPTMVTag */

export function getCostStayOnPrEPPP(costStayOnPrEPPP2DFltArr, methodCurrID, priorPopCurrID) {
  return costStayOnPrEPPP2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - costPerPersonInitPTMVTag */

export function getCostPerPersonInitPP(costPerPersonInitPP2DFltArr, methodCurrID, priorPopCurrID) {
  return costPerPersonInitPP2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - avgCostPrEPByMonthPTMVTag */

export function getAvgCostPrEPByMonthPP(avgCostPrEPByMonthPP3DFltArr, methodCurrID, priorPopCurrID, monthCurrID) {
  /* Note months actually start at 0 */
  return avgCostPrEPByMonthPP3DFltArr[methodCurrID - 1][priorPopCurrID - 1][monthCurrID];
}

/**************************   Costs Lite   **********************************/

/* costCategoriesLiteMVTag */

// Mapping of default prior pop ID to minute pattern cost data
const MinPatPPMap = {
  [pisc.SDC_PP_MstID]: pisc.SDCsMPMstID,
  [pisc.AGYW_PP_MstID]: pisc.AGYWMPMstID,
  [pisc.PBW_PP_MstID]: pisc.AGYWMPMstID,
  [pisc.MSM_PP_MstID]: pisc.keyPopsMPMstID,
  [pisc.FSW_PP_MstID]: pisc.keyPopsMPMstID,
  [pisc.PWID_PP_MstID]: pisc.keyPopsMPMstID,
  [pisc.TP_PP_MstID]: pisc.keyPopsMPMstID,
};

// Mapping of default prior pop ID to CAB-LA cost data
const CabLAPPMap = {
  [pisc.SDC_PP_MstID]: pisc.AGYW_PP_MstID,
  [pisc.AGYW_PP_MstID]: pisc.AGYW_PP_MstID,
  [pisc.MSM_PP_MstID]: pisc.MSM_PP_MstID,
  [pisc.FSW_PP_MstID]: pisc.FSW_PP_MstID,
  [pisc.PWID_PP_MstID]: pisc.MSM_PP_MstID,
  [pisc.PBW_PP_MstID]: pisc.AGYW_PP_MstID,
  [pisc.TP_PP_MstID]: pisc.MSM_PP_MstID,
};

// Mapping of PSE pop prefix to minute pattern cost data
const MinPatPSEPrefixMap = {
  REG_M: pisc.SDCsMPMstID,
  REG_F: pisc.AGYWMPMstID,
  NREG_M: pisc.SDCsMPMstID,
  NREG_F: pisc.AGYWMPMstID,
  FSW: pisc.keyPopsMPMstID,
  MSM: pisc.keyPopsMPMstID,
  PWID: pisc.keyPopsMPMstID,
};

/**
 *
 * @param {string} popID
 * @returns {string|undefined}
 */
const minPatIDForPSEPop = (popID) => {
  for (const [prefix, minPatID] of Object.entries(MinPatPSEPrefixMap)) {
    if (popID.startsWith(`POP_${prefix}`)) return minPatID;
  }

  return undefined;
};

// Mapping of PSE pop prefix to CAB-LA cost data
const CabLAPSEPrefixMap = {
  REG_M: pisc.AGYW_PP_MstID,
  REG_F: pisc.AGYW_PP_MstID,
  NREG_M: pisc.AGYW_PP_MstID,
  NREG_F: pisc.AGYW_PP_MstID,
  FSW: pisc.FSW_PP_MstID,
  MSM: pisc.MSM_PP_MstID,
  PWID: pisc.MSM_PP_MstID,
};

/**
 *
 * @param {string} popID
 * @returns {string|undefined}
 */
const cabLAForPSEPop = (popID) => {
  for (const [prefix, cabLAPPID] of Object.entries(CabLAPSEPrefixMap)) {
    if (popID.startsWith(`POP_${prefix}`)) return cabLAPPID;
  }

  return undefined;
};

export function setCostCategoriesLiteValues(modVarObjArr, methodMstIDSet) {
  /* Seed costCategoriesLiteMVTag with defCostsPerVisitLiteMVTag values. */
  const methodObjArr = getModVarValue(modVarObjArr, pisc.methodsMVTag);
  const costPerVisitObjArr = getModVarValue(modVarObjArr, pisc.defCostsPerVisitLiteMVTag);
  const priorPopObjArr = getModVarValue(modVarObjArr, pisc.priorPopsMVTag);
  let costCatObjList = getModVarValue(modVarObjArr, pisc.costCategoriesLiteMVTag);
  const costRatios = getModVarValue(modVarObjArr, pisc.costPerVisitRatiosMVTag);
  const isPSE = isPSEMode(modVarObjArr);

  const numMethods = getTotalNumMethods(methodObjArr);
  const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

  for (let m = 1; m <= numMethods; m++) {
    const methodMstIDV = methodMstID(methodObjArr, m);
    if (!methodMstIDSet.has(methodMstIDV)) continue;

    if (methodMstIDV !== pisc.CAB_LAMethodMstID) {
      // Not CAB-LA; set cost categories with minute patterns
      for (let mp = 1; mp <= pic.numMinPatCPV; mp++) {
        const minPatMstID = piu.getDefMinPatMstID(mp);

        for (let vt = 1; vt <= pic.numVisitTypes; vt++) {
          for (let cc = 1; cc <= pic.numCostCatCPV; cc++) {
            /* Total row comes first but is last in the data structure. */
            const value = getCostPerVisit(costPerVisitObjArr, m, mp, vt, cc);

            for (let pp = 1; pp <= numPriorPops; pp++) {
              const priorPopMstID = getPriorPopMstID(priorPopObjArr, pp);

              // This is the original if kept for reference, in case the refactor
              // turns out to be broken for some reason.
              //
              // if (
              //   (priorPopMstID === pisc.SDC_PP_MstID && minPatMstID === pisc.SDCsMPMstID) ||
              //   ((priorPopMstID === pisc.AGYW_PP_MstID || priorPopMstID === pisc.PBW_PP_MstID) &&
              //     minPatMstID === pisc.AGYWMPMstID) ||
              //   ((priorPopMstID === pisc.MSM_PP_MstID ||
              //     priorPopMstID === pisc.FSW_PP_MstID ||
              //     priorPopMstID === pisc.PWID_PP_MstID ||
              //     priorPopMstID === pisc.TP_PP_MstID) &&
              //     minPatMstID === pisc.keyPopsMPMstID)
              // )

              const minPatID = isPSE ? minPatIDForPSEPop(priorPopMstID) : MinPatPPMap[priorPopMstID];
              if (minPatID === minPatMstID) {
                if (vt === pic.initVisitCurrID) {
                  if (cc === pic.totalCostCatCPVCurrID) {
                    setInitVisitCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.personnelCostCatCPVCurrID) {
                    setInitPersonnelCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.labCostCatCPVCurrID) {
                    setInitLabCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.otherRecurCostCatCPVCurrID) {
                    setInitOtherRecurCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.capCostCatCPVCurrID) {
                    setInitCapCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }
                }

                if (vt === pic.contVisitCurrID) {
                  if (cc === pic.totalCostCatCPVCurrID) {
                    setContVisitCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.personnelCostCatCPVCurrID) {
                    setContPersonnelCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.labCostCatCPVCurrID) {
                    setContLabTestCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.otherRecurCostCatCPVCurrID) {
                    setContOtherRecurCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }

                  if (cc === pic.capCostCatCPVCurrID) {
                    setContCapCostsLite(methodMstIDV, costCatObjList, pp, value);
                  }
                }
              }
            }
          }
        }
      }
    } else {
      // CAB-LA specific
      const cablaCosts = costPerVisitObjArr[m - 1];
      const ratios = (costRatios.find((v) => v.mstID === methodMstIDV)?.ratios ?? [0, 0]).map((v) => v / 100);

      for (let pp = 1; pp <= numPriorPops; ++pp) {
        const priorPopMstID = getPriorPopMstID(priorPopObjArr, pp);
        const costID = (isPSE ? cabLAForPSEPop(priorPopMstID) : CabLAPPMap[priorPopMstID]) ?? pisc.AGYW_PP_MstID;
        const costs = cablaCosts.costs[costID];

        const initPersonnel = costs.initiation.personnel * cablaCosts.multiplier;
        setInitPersonnelCostsLite(methodMstIDV, costCatObjList, pp, initPersonnel);
        setInitLabCostsLite(methodMstIDV, costCatObjList, pp, costs.initiation.labs);
        setInitOtherRecurCostsLite(
          methodMstIDV,
          costCatObjList,
          pp,
          (initPersonnel + costs.initiation.labs) * ratios[0]
        );
        setInitCapCostsLite(methodMstIDV, costCatObjList, pp, (initPersonnel + costs.initiation.labs) * ratios[1]);

        const contPersonnel = costs.continuation.personnel * cablaCosts.multiplier;
        setContPersonnelCostsLite(methodMstIDV, costCatObjList, pp, contPersonnel);
        setContLabTestCostsLite(methodMstIDV, costCatObjList, pp, costs.continuation.labs);
        setContOtherRecurCostsLite(
          methodMstIDV,
          costCatObjList,
          pp,
          (contPersonnel + costs.continuation.labs) * ratios[0]
        );
        setContCapCostsLite(methodMstIDV, costCatObjList, pp, (contPersonnel + costs.continuation.labs) * ratios[1]);
      }
    }
  }

  setModVarValue(modVarObjArr, pisc.costCategoriesLiteMVTag, costCatObjList);
}

export function getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList) {
  let methodObj = {};

  let i = 0;
  while (i < costCatLiteObjList.length && gbu.isEmpty(methodObj)) {
    if (costCatLiteObjList[i][pisc.methodMstIDCCLite] === methodMstIDStr) {
      methodObj = costCatLiteObjList[i];
    }

    i++;
  }

  return methodObj;
}

export function getCostCatLiteObjByMstID(methodMstIDStr, costCatLiteObjList, priorPopMstIDStr) {
  let costCatLiteObj = {};

  let methodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

  if (!gbu.isEmpty(methodObj)) {
    let i = 0;
    while (i < methodObj[pisc.priorPopObjArrCCLite].length) {
      if (methodObj[pisc.priorPopObjArrCCLite][i][pisc.priorPopMstIDCCLite] === priorPopMstIDStr) {
        costCatLiteObj = methodObj[pisc.priorPopObjArrCCLite][i];
      }

      i++;
    }
  }

  return costCatLiteObj;
}

export function getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  let costCatLiteObj = {};

  let methodObj = getCostCatLiteMethodObj(methodMstIDStr, costCatLiteObjList);

  if (!gbu.isEmpty(methodObj)) {
    costCatLiteObj = methodObj[pisc.priorPopObjArrCCLite][priorPopCurrID - 1];
  }

  return costCatLiteObj;
}

export function getCostsPriorPopMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.priorPopMstIDCCLite];
}

export function setCostsPriorPopMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, priorPopMstIDStr) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.priorPopMstIDCCLite] = priorPopMstIDStr;
}

export function getCostPerPersMonthCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.monthlyCostCCLite];
}

export function setCostPerPersMonthCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.monthlyCostCCLite] = valueFlt;
}

const _defaultIncludedObjList = {
  [pisc.monthlyCostCCLite]: true,
  [pisc.ARVsCCLite]: true,
  [pisc.adherenceSupportCCLite]: true,
  [pisc.totalCPVLite]: true,
  [pisc.initCCLite]: {
    [pisc.personnelCCLite]: true,
    [pisc.visitLabsCCLite]: true,
    [pisc.recurrentCCLite]: true,
    [pisc.capitalCCLite]: true,
    [pisc.totalCCLite]: true,
  },
  [pisc.contCCLite]: {
    [pisc.personnelCCLite]: true,
    [pisc.visitLabsCCLite]: true,
    [pisc.recurrentCCLite]: true,
    [pisc.capitalCCLite]: true,
    [pisc.totalCCLite]: true,
  },
  [pisc.annualCCLite]: true,
};

export function setCostIncludedCostsLite(CCLiteIDStr, methodMstIDStr, costCatLiteObjList, valueFlt) {
  let includedObjList = {};
  let i = 0;
  while (i < costCatLiteObjList.length && gbu.isEmpty(includedObjList)) {
    if (costCatLiteObjList[i][pisc.methodMstIDCCLite] === methodMstIDStr) {
      if (costCatLiteObjList[i][pisc.includedCCLite] === undefined) {
        costCatLiteObjList[i][pisc.includedCCLite] = structuredClone(_defaultIncludedObjList);
      }
      includedObjList = costCatLiteObjList[i][pisc.includedCCLite];
    }
    i++;
  }

  if (!gbu.isEmpty(includedObjList)) {
    if (typeof CCLiteIDStr === "string" || CCLiteIDStr instanceof String) {
      includedObjList[CCLiteIDStr] = valueFlt;
    } else if (CCLiteIDStr instanceof Array && CCLiteIDStr.length === 2) {
      includedObjList[CCLiteIDStr[0]][CCLiteIDStr[1]] = valueFlt;
    }
  }
}

export function getCostIncludedCostsLite(CCLiteIDStr, methodMstIDStr, costCatLiteObjList) {
  let includedObjList = {};
  let i = 0;
  while (i < costCatLiteObjList.length && gbu.isEmpty(includedObjList)) {
    if (costCatLiteObjList[i][pisc.methodMstIDCCLite] === methodMstIDStr) {
      if (costCatLiteObjList[i][pisc.includedCCLite] !== undefined) {
        includedObjList = costCatLiteObjList[i][pisc.includedCCLite];
      }
    }
    i++;
  }
  if (gbu.isEmpty(includedObjList)) {
    includedObjList = structuredClone(_defaultIncludedObjList);
  }

  if (typeof CCLiteIDStr === "string" || CCLiteIDStr instanceof String) {
    return includedObjList[CCLiteIDStr];
  } else if (CCLiteIDStr instanceof Array && CCLiteIDStr.length === 2) {
    return includedObjList[CCLiteIDStr[0]][CCLiteIDStr[1]];
  } else {
    return true;
  }
}

export function getARVCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.ARVsCCLite];
}

export function setARVCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.ARVsCCLite] = valueFlt;
}

export function getAdhereSupportCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.adherenceSupportCCLite];
}

export function setAdhereSupportCostLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.adherenceSupportCCLite] = valueFlt;
}

export function getInitVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.totalCCLite];
}

export function setInitVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.totalCCLite] = valueFlt;
}

export function getInitLabCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.visitLabsCCLite];
}

export function setInitLabCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.visitLabsCCLite] = valueFlt;
}

export function getInitPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.personnelCCLite];
}

export function setInitPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.personnelCCLite] = valueFlt;
}

export function getInitOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.recurrentCCLite];
}

export function setInitOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.recurrentCCLite] = valueFlt;
}

export function getInitCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.initCCLite][pisc.capitalCCLite];
}

export function setInitCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.initCCLite][pisc.capitalCCLite] = valueFlt;
}

export function getContVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.totalCCLite];
}

export function setContVisitCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.totalCCLite] = valueFlt;
}

export function getContLabTestCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.visitLabsCCLite];
}

export function setContLabTestCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.visitLabsCCLite] = valueFlt;
}

export function getContPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.personnelCCLite];
}

export function setContPersonnelCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.personnelCCLite] = valueFlt;
}

export function getContOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.recurrentCCLite];
}

export function setContOtherRecurCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.recurrentCCLite] = valueFlt;
}

export function getContCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.contCCLite][pisc.capitalCCLite];
}

export function setContCapCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.contCCLite][pisc.capitalCCLite] = valueFlt;
}

export function getAnnLumpSumCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.annualCCLite];
}

export function setAnnLumpSumCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueFlt) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.annualCCLite] = valueFlt;
}

export function getPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID) {
  const costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  return costCatLiteObj[pisc.scheduleIDCCLite];
}

export function setPriorPopContVisitSchedMstIDCostsLite(methodMstIDStr, costCatLiteObjList, priorPopCurrID, valueStr) {
  let costCatLiteObj = getCostCatLiteObj(methodMstIDStr, costCatLiteObjList, priorPopCurrID);
  costCatLiteObj[pisc.scheduleIDCCLite] = valueStr;
}

/* Default Cost per visit (CPV) Lite (Reference table) */

/* defCostsPerVisitLiteMVTag */

export function getCostPerVisit(costPerVisitObjArr, methodCurrID, minPatCurrID, visitTypeCurrID, costCatCurrID) {
  let minPatObj = undefined;
  let visitTypeObj = undefined;
  let value = 0.0;

  switch (minPatCurrID) {
    case pic.SDCMinPatCPVCurrID:
      minPatObj = costPerVisitObjArr[methodCurrID - 1][pisc.SDCCPVLite];
      break;

    case pic.keyPopsMinPatCPVCurrID:
      minPatObj = costPerVisitObjArr[methodCurrID - 1][pisc.KEYPOPCPVLite];
      break;

    case pic.AGYWMinPatCPVCurrID:
      minPatObj = costPerVisitObjArr[methodCurrID - 1][pisc.AGYWCPVLite];
      break;

    default:
      break;
  }

  if (typeof minPatObj !== "undefined") {
    switch (visitTypeCurrID) {
      case pic.initVisitCurrID:
        visitTypeObj = minPatObj[pisc.initCPVLite];
        break;

      case pic.contVisitCurrID:
        visitTypeObj = minPatObj[pisc.contCPVLite];
        break;

      default:
        break;
    }
  }

  if (typeof visitTypeObj !== "undefined") {
    value = visitTypeObj[costCatCurrID - 1];
    // switch (costCatCurrID) {
    //
    //     case pic.personnelCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.personnelCPVLite];
    //         break;
    //
    //     case pic.labCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.labsCPVLite];
    //         break;
    //
    //     case pic.otherRecurCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.recurrentCPVLite];
    //         break;
    //
    //     case pic.capCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.capitalCPVLite];
    //         break;
    //
    //     case pic.totalCostCatCPVCurrID :
    //         value = visitTypeObj[pisc.totalCPVLite];
    //         break;
    //
    //     default :
    //         break;
    //
    // }
  }

  return value;
}

/* costPerVisitRatiosMVTag */

export function costPerVisitRatios(costPerVisitRatioObjArr, methodCurrID, idx, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    costPerVisitRatioObjArr[methodCurrID - 1][pisc.ratiosCPV][idx] = valueFlt;
  } else {
    value = costPerVisitRatioObjArr[methodCurrID - 1][pisc.ratiosCPV][idx];
  }

  return value;
}

//==================================================================================================================
//
//         Impact
//
//==================================================================================================================

/* result - impactInfAvtdMVTag */

export function getImpactInfAvtd(infAvtd1DFltArr, methodCurrID, priorPopCurrID) {
  return infAvtd1DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* input - adjustmentFactorMVTag */

export function getAdjFactorMethodObj(methodMstIDStr, adjFactor2DFltArr) {
  let methodObj = {};

  let i = 0;
  while (i < adjFactor2DFltArr.length && gbu.isEmpty(methodObj)) {
    if (adjFactor2DFltArr[i][pisc.methodMstIDAF] === methodMstIDStr) {
      methodObj = adjFactor2DFltArr[i];
    }

    i++;
  }

  return methodObj;
}

export function getImpactAdjFactor(methodMstIDStr, adjFactor2DFltArr, priorPopCurrID) {
  const adjFactorMethodObj = getAdjFactorMethodObj(methodMstIDStr, adjFactor2DFltArr);
  return adjFactorMethodObj[pisc.factorsAF][priorPopCurrID - 1];
}

export function setImpactAdjFactor(methodMstIDStr, adjFactor2DFltArr, priorPopCurrID, valueFlt) {
  let adjFactorMethodObj = getAdjFactorMethodObj(methodMstIDStr, adjFactor2DFltArr);
  adjFactorMethodObj[pisc.factorsAF][priorPopCurrID - 1] = valueFlt;
}

/* result - adjInfAvtdMVTag */

export function getAdjInfAvtd(infAvtdPerPersonYrPrEP1DFltArr, methodCurrID, priorPopCurrID) {
  return infAvtdPerPersonYrPrEP1DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* result - persYrsPrEPAvtOneInfectMVTag */

export function getPersYrsPrEPAvtOneInfect(persYrsPrEPAvtOneInfect1DFltArr, methodCurrID, priorPopCurrID) {
  return persYrsPrEPAvtOneInfect1DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* impactEffectivenessMVTag */

export function getImpactEffectiveness(impactEffectiveness1DIntArr, methodCurrID) {
  return impactEffectiveness1DIntArr[methodCurrID - 1];
}

// NOTE: Run calcImpactFactors before calculate if effectiveness changes.
export function setImpactEffectiveness(impactEffectiveness1DIntArr, methodCurrID, valueInt) {
  impactEffectiveness1DIntArr[methodCurrID - 1] = valueInt;
}

/**
 * in-place mutate modVarObjList to reset effectiveness
 * NOTE: Run calcImpactFactors before calculate if effectiveness changes.
 * @param {object} defModVars - Default modvars representing a fresh session for the selected country
 * @param {object} modVarObjList - in/out modvars to be reset
 */
export function resetImpactEffectiveness(defModVars, modVarObjList) {
  const impEff = getModVarValue(modVarObjList, "PI_ImpactEffectiveness");
  const methods = getModVarValue(modVarObjList, "PI_Methods");
  const defImpEff = getModVarValue(defModVars, "PI_ImpactEffectiveness");
  const defMethods = getModVarValue(defModVars, "PI_Methods");

  const newImpEff = impEff.map((v, idx) => {
    const m = methods[idx];
    if (m === undefined) return defImpEff[idx];

    const defIdx = defMethods.findIndex((v) => v.mstID === m.mstID);
    return defImpEff[defIdx > -1 ? defIdx : 0];
  });

  setModVarValue(modVarObjList, "PI_ImpactEffectiveness", newImpEff);
}

/* Default impact priority population (DIPP) object */

export function getNumDefImpPriorPops(defImpPriorPopObjArr) {
  return defImpPriorPopObjArr.length;
}

export function defImpPriorPopMstID(defImpPriorPopObjArr, defImpPriorPopCurrID, mstIDStr) {
  let value;

  if (typeof mstIDStr !== "undefined") {
    defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopMstIDDIPP] = mstIDStr;
  } else {
    value = defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopMstIDDIPP];
  }

  return value;
}

export function getDefImpPriorPopCurrID(defImpPriorPopObjArr, defImpPriorPopMstIDStr) {
  const numDefImpPriorPops = getNumDefImpPriorPops(defImpPriorPopObjArr);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numDefImpPriorPops && !stop) {
    const mstID = defImpPriorPopMstID(defImpPriorPopObjArr, currIDInt);

    if (mstID === defImpPriorPopMstIDStr) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function defImpPriorPopName(defImpPriorPopObjArr, defImpPriorPopCurrID, nameStr) {
  let value;

  if (typeof nameStr !== "undefined") {
    defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopNameDIPP] = nameStr;
  } else {
    value = defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.impPriorPopNameDIPP];
  }

  return value;
}

export function setDefImpPriorPopNames(defImpPriorPopObjArr) {
  const numDefImpPriorPops = getNumDefImpPriorPops(defImpPriorPopObjArr);
  for (let ipp = 1; ipp <= numDefImpPriorPops; ipp++) {
    const defImpPriorPopMstIDV = defImpPriorPopMstID(defImpPriorPopObjArr, ipp);
    const defImpPriorPopNameV = defImpPriorPopName(defImpPriorPopObjArr, ipp);

    if (defImpPriorPopNameV === "") {
      defImpPriorPopName(defImpPriorPopObjArr, ipp, piu.getDefImpPriorPopNameFromMstID(defImpPriorPopMstIDV));
    }
  }
}

export function getDefImpPriorPopNames(defImpPriorPopObjArr) {
  let names = [];

  const numDefImpPriorPops = getNumDefImpPriorPops(defImpPriorPopObjArr);
  for (let ipp = 1; ipp <= numDefImpPriorPops; ipp++) {
    /* Assumes setDefImpPriorPopNames has already been called and the names
           have been set properly. Otherwise, we should use getDefImpPriorPopNameFromMstID. */
    names.push(defImpPriorPopName(defImpPriorPopObjArr, ipp));
  }

  return names;
}

export function getConstantDIPP(defImpPriorPopObjArr, defImpPriorPopCurrID) {
  return defImpPriorPopObjArr[defImpPriorPopCurrID - 1][pisc.constantDIPP];
}

export function getCostForm(modVarObjList) {
  let form = pic.detailedCostsForm;

  if (piv.getModVarsRetrieved(modVarObjList)) {
    const costingModeMstID = getModVarValue(modVarObjList, pisc.costingModuleMVTag);

    if (costingModeMstID === pisc.costsLiteModeMstID) {
      form = pic.costsLiteForm;
    }
  }

  return form;
}

/***********************************************************************************/
/***************************************   Capacity   ********************************/
/***********************************************************************************/

export function staffMinutes(staffMinObjArr, staffTypeCurrID, visitTypeCurrID, valueInt) {
  let staffMinutesInt;

  let staffObj = staffMinObjArr[staffTypeCurrID - 1];

  if (visitTypeCurrID === pic.initVisitCurrID) {
    if (typeof valueInt !== "undefined") {
      staffObj[pisc.initSM] = valueInt;
    } else {
      staffMinutesInt = staffObj[pisc.initSM];
    }
  } else {
    if (typeof valueInt !== "undefined") {
      staffObj[pisc.contSM] = valueInt;
    } else {
      staffMinutesInt = staffObj[pisc.contSM];
    }
  }

  return staffMinutesInt;
}

export function proportionVisits(monthlyCapObj, visitTypeCurrID, valueInt) {
  let proportionInt;

  if (visitTypeCurrID === pic.initVisitCurrID) {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.initPV][pisc.propVisitsPV] = valueInt / 100;
    } else {
      proportionInt = monthlyCapObj[pisc.initPV][pisc.propVisitsPV] * 100;
    }
  } else {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.contPV][pisc.propVisitsPV] = valueInt / 100;
    } else {
      proportionInt = monthlyCapObj[pisc.contPV][pisc.propVisitsPV] * 100;
    }
  }

  return proportionInt;
}

export function monthsPrEPDist(monthlyCapObj, visitTypeCurrID, valueInt) {
  let monthsInt;

  if (visitTypeCurrID === pic.initVisitCurrID) {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.initPV][pisc.monthsPV] = valueInt;
    } else {
      monthsInt = monthlyCapObj[pisc.initPV][pisc.monthsPV];
    }
  } else {
    if (typeof valueInt !== "undefined") {
      monthlyCapObj[pisc.contPV][pisc.monthsPV] = valueInt;
    } else {
      monthsInt = monthlyCapObj[pisc.contPV][pisc.monthsPV];
    }
  }

  return monthsInt;
}

/****************************   AGYW   ***************************************/

export function areaNameAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.areaNameAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.areaNameAGYW];
  }

  return value;
}

export function catchmentAreaNameAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.catchmentAreaNameAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.catchmentAreaNameAGYW];
  }

  return value;
}

export function PrEPCostAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.PrEPCostAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.PrEPCostAGYW];
  }

  return value;
}

export function lifetimeARTCostAvtdAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.lifetimeARTCostAvtdAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.lifetimeARTCostAvtdAGYW];
  }

  return value;
}

export function totalCostSavingsAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.totalCostSavingsAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.totalCostSavingsAGYW];
  }

  return value;
}

export function PrEPCostPerInfAvtdAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.PrEPCostPerInfAvtdAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.PrEPCostPerInfAvtdAGYW];
  }

  return value;
}

export function prev15t24CostAGYW(areaObjArr, areaCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.prev15t24CostAGYW] = valueFlt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.prev15t24CostAGYW];
  }

  return value;
}

export function prev10t14AGYW(areaObjArr, areaCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.prev10t14AGYW] = valueFlt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.prev10t14AGYW];
  }

  return value;
}

export function estInc15t24AGYW(areaObjArr, areaCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.estInc15t24AGYW] = valueFlt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.estInc15t24AGYW];
  }

  return value;
}

export function numInitAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.numInitAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.numInitAGYW];
  }

  return value;
}

export function pop15t24AGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.pop15t24AGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.pop15t24AGYW];
  }

  return value;
}

export function costPerPersonYrPrEPAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.costPerPersonYrPrEPAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.costPerPersonYrPrEPAGYW];
  }

  return value;
}

export function costPerPersonYrARTAGYW(areaObjArr, areaCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.costPerPersonYrARTAGYW] = valueInt;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.costPerPersonYrARTAGYW];
  }

  return value;
}

export function contCurveMstIDAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.contCurveMstIDAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.contCurveMstIDAGYW];
  }

  return value;
}

export function costEffectiveAGYW(areaObjArr, areaCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    areaObjArr[areaCurrID - 1][pisc.costEffectiveAGYW] = valueStr;
  } else {
    value = areaObjArr[areaCurrID - 1][pisc.costEffectiveAGYW];
  }

  return value;
}

export function getNumAGYWAreas(areaObjArr) {
  return areaObjArr.length;
}

/***************************   AGYW - Areas below threshold (ABT)   ***************************/

export function getAGYW_ABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.AGYW_ABT];
}

export function getPrevalenceAGYW_ABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.prevalenceAGYW_ABT];
}

export function getPrEPCostABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.PrEPCostABT];
}

export function getSavingsABT(areasBelowThresholdObj) {
  return areasBelowThresholdObj[pisc.savingsABT];
}

/**************************   Drug forecasting   **********************************/

export function numMethodDistInit(methodObjArr, methodCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.countMDI] = valueInt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.countMDI];
  }

  return value;
}

export function numMethodDistCont(methodObjArr, methodCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.countMDC] = valueInt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.countMDC];
  }

  return value;
}

export function costPerMethod(methodObjArr, methodCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.costCPM] = valueFlt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.costCPM];
  }

  return value;
}

/* drugForecastTableMVTag */

export function numInitVisitsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numInitVisitsDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numInitVisitsDF];
  }

  return value;
}

export function numRestartVisitsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID]["restarts"] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID]["restarts"];
  }

  return value;
}

export function numContVisitsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numContVisitsDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numContVisitsDF];
  }

  return value;
}

export function numMethodReqDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numMethodReqDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.numMethodReqDF];
  }

  return value;
}

export function methodCostsDrugFore(drugFore2DObjArr, methodCurrID, monthCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.methodCostsDF] = valueFlt;
  } else {
    value = drugFore2DObjArr[methodCurrID - 1][monthCurrID][pisc.methodCostsDF];
  }

  return value;
}

/*****************   Disaggregate targets - District populations    **********************/

export function getNumDistrictsDP(districtPop1DObjArr) {
  return districtPop1DObjArr.length;
}

export function clearDistrictsDP(districtPop1DObjArr) {
  districtPop1DObjArr.length = 0;
}

export function createDistrictPopObj(provinceName, districtName, percentDP = 0, pop15PlusDP = 0) {
  return {
    [pisc.provinceDP]: provinceName,
    [pisc.districtNameDP]: districtName,
    [pisc.percentDP]: percentDP,
    [pisc.pop15PlusDP]: pop15PlusDP,
  };
}

export function addDistrictPopObj(districtPop1DObjArr, districtPopObj) {
  districtPop1DObjArr.push(districtPopObj);
}

export function provinceNameDP(districtPop1DObjArr, districtCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.provinceDP] = valueStr;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.provinceDP];
  }

  return value;
}

export function districtNameDP(districtPop1DObjArr, districtCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.districtNameDP] = valueStr;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.districtNameDP];
  }

  return value;
}

export function percentDP(districtPop1DObjArr, districtCurrID, valueFlt) {
  let value;

  if (typeof valueFlt !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.percentDP] = valueFlt;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.percentDP];
  }

  return value;
}

export function pop15PlusDP(districtPop1DObjArr, districtCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    districtPop1DObjArr[districtCurrID - 1][pisc.pop15PlusDP] = valueInt;
  } else {
    value = districtPop1DObjArr[districtCurrID - 1][pisc.pop15PlusDP];
  }

  return value;
}

/*****************   Disaggregate targets - Target indicators    **********************/

/* Disaggregate targets - Priority populations included by age and sex */

/* priorPopAgeSexInclMVTag */

export function getPriorPopInclMethodObj(methodMstIDStr, priorPopInclObjArr) {
  let methodObj = {};

  let i = 0;
  while (i < priorPopInclObjArr.length && gbu.isEmpty(methodObj)) {
    if (priorPopInclObjArr[i][pisc.methodMstIDPPIAS] === methodMstIDStr) {
      methodObj = priorPopInclObjArr[i];
    }

    i++;
  }

  return methodObj;
}

export function startAgePPIAS(priorPopInclObjArr, methodCurrID, priorPopCurrID, sexCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS][priorPopCurrID - 1][
      sexCurrID - 1
    ] = valueStr;
  } else {
    value =
      priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS][priorPopCurrID - 1][
        sexCurrID - 1
      ];
  }

  return value;
}

export function endAgePPIAS(priorPopInclObjArr, methodCurrID, priorPopCurrID, sexCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS][priorPopCurrID - 1][
      sexCurrID - 1
    ] = valueStr;
  } else {
    value =
      priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.endAgePPIAS][priorPopCurrID - 1][
        sexCurrID - 1
      ];
  }

  return value;
}

export function includePPIAS(priorPopInclObjArr, methodCurrID, priorPopCurrID, sexCurrID, valueBool) {
  let value;

  if (typeof valueBool !== "undefined") {
    priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.includePPIAS][priorPopCurrID - 1][
      sexCurrID - 1
    ] = valueBool;
  } else {
    value =
      priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.includePPIAS][priorPopCurrID - 1][
        sexCurrID - 1
      ];
  }

  return value;
}

/* results - targDisagDistPopInitMVTag */

/* Disaggregate targets - Targets disaggregated by district by priority population */

export function getPrEP_NEW_TargDisag(PrEP_NEW_TargDisag3DFltArr, methodCurrID, districtCurrID, priorPopCurrID) {
  return PrEP_NEW_TargDisag3DFltArr[methodCurrID - 1][districtCurrID - 1][priorPopCurrID - 1];
}

export function getPrEP_CTTargDisag(PrEP_CT_TargDisag3DFltArr, methodCurrID, districtCurrID, priorPopCurrID) {
  return PrEP_CT_TargDisag3DFltArr[methodCurrID - 1][districtCurrID - 1][priorPopCurrID - 1];
}

/* Disaggregate targets - Targets disaggregated by priority population

   Note: This is the only ModVar whose structure actually changes in
   Aggregate mode. We drop the district completely.
*/

export function getPrEP_NEW_TargDisagAggMode(PrEP_NEW_TargDisag2DFltArr, methodCurrID, priorPopCurrID) {
  return PrEP_NEW_TargDisag2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

export function getPrEP_CT_TargDisagAggMode(PrEP_CT_TargDisag2DFltArr, methodCurrID, priorPopCurrID) {
  return PrEP_CT_TargDisag2DFltArr[methodCurrID - 1][priorPopCurrID - 1];
}

/* Other methods - change these to work with aggregate too */

export function showLeftSideBarAreaForActiveMode(modVarObjArr, pageIDStr) {
  let showBool = true;

  switch (pageIDStr) {
    case pic.welcomeForm:
      showBool = showWelcome();
      break;

    case pic.gettingStartedForm:
      showBool = showEasyStart(modVarObjArr);
      break;

    case pic.aggregateForm:
      showBool = showAggregate(modVarObjArr);
      break;

    case pic.configForm:
      showBool = showConfig(modVarObjArr);
      break;

    case pic.targetsForm:
      showBool = showTargets(modVarObjArr);
      break;

    case pic.disagTargetsForm:
      showBool = showDisagTargets(modVarObjArr);
      break;

    case pic.detailedCostsForm:
      showBool = showDetailedCosting(modVarObjArr);
      break;

    case pic.costsLiteForm:
      showBool = showCostsLite(modVarObjArr);
      break;

    case pic.impactForm:
      showBool = showImpact(modVarObjArr);
      break;

    case pic.AGYW_Form:
      showBool = showAGYWTool(modVarObjArr);
      break;

    // case pic.capacityForm :
    //     showBool = true;
    //     break;

    case pic.commoditiesForecastForm:
      showBool = showCommoditiesForecasting(modVarObjArr);
      break;

    // case pic.customPriorPopsFormLv2 :
    //     showBool = true;
    //     break;

    case pic.resultsDashboardForm:
      showBool = showDashboard(modVarObjArr);
      break;

    default:
      break;
  }

  return showBool;
}

export function showWelcome() {
  return true;
}

export function showEasyStart(modVarObjArr, ignoreCountrySelected = false) {
  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

  return appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
}

export function showAggregate(modVarObjArr, ignoreCountrySelected = false) {
  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

  const aggregateModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  return aggregateModeBool;
}

export function showConfig(modVarObjArr, ignoreCountrySelected = false) {
  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

  const showBool = appModeMstIDStr === pisc.PrEPitStdToolMstID || appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;

  return showBool;
}

export function showVisitSched(modVarObjArr) {
  return true; // until told otherwise
}

export function showProgramData(modVarObjArr) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);

  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;

  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
  const uploadClientsInitBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.uploadClientsInitESMstID);

  if (easyStartModeBool) {
    showBool = !(!uploadClientsInitBool || doNotSetTargsBool);
  } else {
    showBool = true;
  }

  return showBool;
}

export function showDetailedCosting(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const costingModeMstID = getModVarValue(modVarObjArr, pisc.costingModuleMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const costingModeSelected = costingModeMstID === pisc.detailedCostModeMstID;
  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  if (allInputMVsLoadedBool && countrySelected) {
    const estCostImpactsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.estCostImpactsESMstID);
    const analyzePerPersCostsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzePerPersCostsESMstID);
    const showProgData = showProgramData(modVarObjArr);

    if (easyStartModeBool) {
      showBool =
        costingModeSelected && ((!showProgData && analyzePerPersCostsBool) || (showProgData && estCostImpactsBool));
    } else if (standardModeBool) {
      showBool = costingModeSelected;
    } else if (aggModeBool && forDashboardBool) {
      showBool = costingModeSelected;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showCostsLite(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const costingModeMstID = getModVarValue(modVarObjArr, pisc.costingModuleMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const costingModeSelected = costingModeMstID === pisc.costsLiteModeMstID;
  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  if (allInputMVsLoadedBool && countrySelected) {
    const estCostImpactsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.estCostImpactsESMstID);
    const analyzePerPersCostsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzePerPersCostsESMstID);

    const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
    const uploadClientsInitBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.uploadClientsInitESMstID);

    const showProgData = showProgramData(modVarObjArr);

    if (easyStartModeBool) {
      showBool =
        costingModeSelected &&
        ((!showProgData && analyzePerPersCostsBool) || (showProgData && estCostImpactsBool)) &&
        !(!doNotSetTargsBool && !uploadClientsInitBool && !estCostImpactsBool);

      showBool = showBool || (doNotSetTargsBool && !estCostImpactsBool && analyzePerPersCostsBool);
    } else if (standardModeBool) {
      showBool = costingModeSelected;
    } else if (aggModeBool && forDashboardBool) {
      showBool = costingModeSelected;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showAGYWTool(modVarObjArr, ignoreCountrySelected = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const priorPopObjArr = getModVarValue(modVarObjArr, pisc.priorPopsMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const AGYWOptionMstIDStr = getModVarValue(modVarObjArr, pisc.AGYWOptionMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;

  if (allInputMVsLoadedBool && countrySelected) {
    const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
    const AGYWHotspotsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWHotspotsESMstID);

    /* Pretend this is checked on if the country is not selected and we're ignoring the
           selection of the country. Short-circuiting is important, since we can't access
           most ModVars unless the country has been selected. */
    const AGYWCheckedBool = getDefPriorPopActive(priorPopObjArr, pisc.AGYW_PP_MstID);

    if (AGYWCheckedBool) {
      if (easyStartModeBool) {
        showBool = !doNotSetTargsBool && AGYWHotspotsBool;
      } else if (standardModeBool) {
        showBool = AGYWOptionMstIDStr === pisc.useSubnatGeoPriorAGYWMstID;
      }
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showImpact(modVarObjArr, ignoreCountrySelected = false, showForAggMode = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const estImpactTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.estImpactTargsESMstID);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  if (allInputMVsLoadedBool && countrySelected) {
    if (easyStartModeBool || (aggModeBool && showForAggMode)) {
      showBool = estImpactTargsBool && !doNotSetTargsBool;
    } else if (standardModeBool) {
      showBool = true;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showTargets(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  if (countrySelected && allInputMVsLoadedBool) {
    showBool = (easyStartModeBool && !doNotSetTargsBool) || standardModeBool || (aggModeBool && forDashboardBool);
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showDisagTargets(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const disaggTargsSubnatBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.disaggTargsSubnatESMstID);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  if ((isPSEMode(modVarObjArr) || aggModeBool) && forDashboardBool) return false;

  if (allInputMVsLoadedBool && countrySelected) {
    return easyStartModeBool ? disaggTargsSubnatBool && !doNotSetTargsBool : true;
  } else if (standardModeBool && ignoreCountrySelected) {
    return true;
  }

  return false;
}

export function showCommoditiesForecasting(modVarObjArr, ignoreCountrySelected = false, forDashboardBool = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);
  const easyStartModeBool = appModeMstIDStr === pisc.PrEPitEasyStartToolMstID;
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
  const forecastPrevProdNeedsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.forecastPrevProdNeedsESMstID);

  const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

  if (aggModeBool && forDashboardBool) return false;

  if (allInputMVsLoadedBool && countrySelected) {
    if (easyStartModeBool) {
      showBool = doNotSetTargsBool || forecastPrevProdNeedsBool;
    } else {
      showBool = true;
    }
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

export function showDashboard(modVarObjArr, ignoreCountrySelected = false) {
  let showBool = false;

  const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
  const standardModeBool = appModeMstIDStr === pisc.PrEPitStdToolMstID;
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const countrySelected = getCountrySelected(modVarObjArr);

  if (allInputMVsLoadedBool && countrySelected) {
    return appModeMstIDStr !== pisc.noToolSelectedMstID;
  } else if (standardModeBool && ignoreCountrySelected) {
    showBool = true;
  }

  return showBool;
}

/* Gets either the previous or next page, if it exists, given the current page. */
export function getPageID(modVarObjArr, currPageOrder, direction) {
  const findPage = (pageOrder) => {
    const pageID = piu.getPageIDFromOrder(pageOrder);

    if (pageOrder < pic.firstForm || pageOrder > pic.finalForm) {
      return pic.noPageID;
    } else if (showLeftSideBarAreaForActiveMode(modVarObjArr, pageID)) {
      return pageID;
    } else {
      if (direction === pic.back) {
        return findPage(pageOrder - 1);
      } else {
        return findPage(pageOrder + 1);
      }
    }
  };

  let pgOrder;
  if (direction === pic.back) {
    pgOrder = currPageOrder - 1;
  } else {
    pgOrder = currPageOrder + 1;
  }

  const pageID = findPage(pgOrder);

  return pageID;
}

export function canMovePastConfig(modVarObjArr) {
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);
  const countrySelected = getCountrySelected(modVarObjArr);

  let priorPopAreaCompleted = false;
  let contCurveAreaCompleted = false;
  let methodsAreaCompleted = false;
  let visitSchedAreaCompleted = false;

  if (allInputMVsLoadedBool && countrySelected) {
    const areaObjArr = getModVarValue(modVarObjArr, pisc.completedAreasMVTag);
    priorPopAreaCompleted = areaCompleted(areaObjArr, pisc.priorPopAreaMstID);
    contCurveAreaCompleted = areaCompleted(areaObjArr, pisc.contCurveAreaMstID);
    methodsAreaCompleted = areaCompleted(areaObjArr, pisc.methodsAreaMstID);
    visitSchedAreaCompleted = areaCompleted(areaObjArr, pisc.visitSchedAreaMstID);
  }

  const goForward =
    allInputMVsLoadedBool &&
    countrySelected &&
    priorPopAreaCompleted &&
    contCurveAreaCompleted &&
    methodsAreaCompleted &&
    visitSchedAreaCompleted;

  return goForward;
}

export function checkAGYW_PriorPop(modVarObjArr) {
  let easyStartOptionObjArr = getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);
  const allInputMVsLoadedBool = getModVarValue(modVarObjArr, pisc.allInputMVsLoadedMVTag);

  const AGYWNotIncludedBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWNotIncludedESMstID);
  const doNotSetTargsBool = easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  return allInputMVsLoadedBool && ((!doNotSetTargsBool && !AGYWNotIncludedBool) || doNotSetTargsBool);
}

/**
 * Returns true if this is a PSE session, false otherwise
 * @param {any} modVarObjArr
 * @returns {boolean}
 */
export function isPSEMode(modVarObjArr) {
  return getModVarValue(modVarObjArr, "PI_EnablePSEMode") ?? false;
}

/**
 * Enable or disable PSE mode
 * @param {any} modVarOjArr
 * @param {boolean} enabled
 */
export function setPSEMode(modVarOjArr, enabled) {
  setModVarValue(modVarOjArr, "PI_EnablePSEMode", enabled);
}
