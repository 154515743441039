import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TTabs2 from "../../common/TTabs2";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";

import { PIPriorPopsTable } from "../Tables/PIPriorPopsTable";
import { PIMethodUnitsTable } from "../Tables/PIMethodUnitsTable";
import PIPriorPopMethodEligTable from "../Tables/PIPriorPopMethodEligTable";

import PIDistrictPopTable from "../Tables/PIDistrictPopTable";
// import PIPriorPopPSETable from "../Tables/PIPriorPopPSETable";

import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";

// Export as reset to defaults logic depends on the active tab
export const methodConfigTab = 0;
export const methodEligTab = 1;

const firstTab = methodConfigTab;
const finalTab = methodEligTab;

class PIItemsForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.itemType]: PropTypes.number,

    [pip.onSlideDrawerSaveBtnClick]: PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,

    activeTabIdx: PropTypes.number,
    onActiveTabIdxChange: PropTypes.func,

    deferDistrictPopCellChangeCalculate: PropTypes.bool,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(`PIItemsForm: ${pias.onCalculatingChange}`),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(`PIItemsForm: ${pias.onModVarsChange}`),

    [pias.tableKeyObj]: {},

    [pip.itemType]: pic.priorPopItems,
    activeTabIdx: 0,
    onActiveTabIdxChange: () => console.log("PIItemsForm: onActiveTabIdxChange"),

    deferDistrictPopCellChangeCalculate: false,

    [pip.onSlideDrawerSaveBtnClick]: () => console.log(`PIItemsForm: ${pip.onSlideDrawerSaveBtnClick}`),

    [pip.onNextAreaChange]: () => console.log(`PIItemsForm: ${pip.onNextAreaChange}`),
    [pip.onPrevAreaChange]: () => console.log(`PIItemsForm: ${pip.onPrevAreaChange}`),
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    this.props.onActiveTabIdxChange(tabIdx);
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const onSlideDrawerSaveBtnClick = props[pip.onSlideDrawerSaveBtnClick];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    let selectedTabIdx = props.activeTabIdx;

    let onDrawerChangeEvent = undefined;

    if (direction === pic.back) {
      if (props.itemType === pic.methodItems && selectedTabIdx === firstTab) {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onPrevAreaChange(pic.gettingStartedForm);
          });
        };
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      console.log(selectedTabIdx, firstTab, finalTab);
      if (props.itemType === pic.priorPopItems || (props.itemType === pic.methodItems && selectedTabIdx === finalTab)) {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onNextAreaChange();
          });
        };
      } else {
        selectedTabIdx++;
      }
    }

    if (typeof onDrawerChangeEvent !== "undefined") {
      onDrawerChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderPriorPopsComponents = () => {
    const props = this.props;

    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];

    const origModVarObjArr = props[pias.origModVarObjArr];
    const modVarObjList = props[pias.modVarObjList]; // should this be state?
    const onModVarsChange = props[pias.onModVarsChange]; // should this be "this."

    const isPSEMode = piasu.isPSEMode(modVarObjList);

    const tableKeyObj = props[pias.tableKeyObj];
    const priorPopsTableKey = tableKeyObj[pias.priorPopsTableKey];

    return (
      <>
        {!isPSEMode && (
          <>
            <p
              style={{
                marginLeft: Theme.leftIndent,
                marginTop: 30,
                ...Theme.labelStyle,
              }}
            >
              {RS(isPSEMode ? SC.GB_stSelectPriorPopsPSE : SC.GB_stSelectPriorPops)}
            </p>

            <PIPriorPopsTable
              {...{
                [pias.onCalculatingChange]: onCalculatingChange,
                [pias.onDialogChange]: onDialogChange,

                [pias.origModVarObjArr]: origModVarObjArr,
                [pias.modVarObjList]: modVarObjList,
                [pias.onModVarsChange]: onModVarsChange,

                [pip.tableKey]: priorPopsTableKey,
              }}
            />
          </>
        )}
        {/* {isPSEMode && <PIPriorPopPSETable modVarObjList={modVarObjList} onModVarsChange={onModVarsChange} />} */}

        <p
          style={{
            marginLeft: Theme.leftIndent,
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stSelectPriorPopsByDistrictPSE)}
        </p>
        <PIDistrictPopTable
          modVarObjList={modVarObjList}
          onModVarsChange={onModVarsChange}
          onCalculatingChange={onCalculatingChange}
          deferCellChangeCalculate={props.deferDistrictPopCellChangeCalculate}
        />
      </>
    );
  };

  renderPriorPopsMethodEligTabContents = () => {
    const props = this.props;

    const modVarObjList = props[pias.modVarObjList]; // should this be state?
    const onModVarsChange = props[pias.onModVarsChange]; // should this be "this."

    return (
      <>
        <p
          style={{
            marginLeft: Theme.leftIndent,
            marginTop: 30,
            marginRight: 20,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stSpecMembersEligible)}
        </p>

        <PIPriorPopMethodEligTable modVarObjList={modVarObjList} onModVarsChange={onModVarsChange} />
      </>
    );
  };

  renderMethodsUnitsTabContents = () => {
    const props = this.props;

    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];

    const origModVarObjArr = props[pias.origModVarObjArr];
    const modVarObjList = props[pias.modVarObjList]; // should this be state?
    const onModVarsChange = props[pias.onModVarsChange]; // should this be "this."

    const tableKeyObj = props[pias.tableKeyObj];

    const methodsUnitsLabel = (
      <p
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          marginRight: 20,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stSelectMethods)}
      </p>
    );

    const methodsUnitCostInfo = (
      <p
        style={{
          marginBottom: 0,
        }}
      >
        {RS(SC.GB_stMethodsUnitCostMessage)}
      </p>
    );

    const methodUnitsTableKey = tableKeyObj[pias.methodUnitsTableKey];

    const methodsUnitsTable = (
      <PIMethodUnitsTable
        {...{
          [pias.onCalculatingChange]: onCalculatingChange,
          [pias.onDialogChange]: onDialogChange,

          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.tableKey]: methodUnitsTableKey,
        }}
      />
    );

    return (
      <>
        {methodsUnitsLabel}
        {methodsUnitCostInfo}
        {methodsUnitsTable}
      </>
    );
  };

  renderSelectedTabContent = () => {
    const selectedTabIdx = this.props.activeTabIdx;

    let methodsUnitsTabContents = null;
    let priorPopsMethodEligTabContents = null;

    if (selectedTabIdx === methodConfigTab) {
      methodsUnitsTabContents = this.renderMethodsUnitsTabContents();
    } else if (selectedTabIdx === methodEligTab) {
      priorPopsMethodEligTabContents = this.renderPriorPopsMethodEligTabContents();
    }

    return (
      <>
        {methodsUnitsTabContents}
        {priorPopsMethodEligTabContents}
      </>
    );
  };

  render = () => {
    const priorPopsComponents = this.renderPriorPopsComponents();

    const tabs = (
      <TTabs2
        onChange={this.onTabIdxChange}
        selectedTabIdx={this.props.activeTabIdx}
        tabBackgroundColor={"inherit"}
        tabBarOutline={"none"}
        tabContents={["", ""]}
        tabTitles={[RS(SC.GB_stConfiguration), RS(SC.GB_stEligibility)]}
      />
    );

    const selectedTabContent = this.renderSelectedTabContent();

    const navBtnDiv = (
      <PINavBtnDiv
        {...{
          [PINavBtnDivProps.divStyle]: { display: "block" },
          [PINavBtnDivProps.showBackBtn]: true,
          [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
          [PINavBtnDivProps.backBtnStyle]: { marginLeft: Theme.leftIndent },
          [PINavBtnDivProps.showNextBtn]: true,
          [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
        }}
      />
    );

    const methodsComponents = (
      <>
        {tabs}
        {selectedTabContent}
      </>
    );

    return (
      <>
        {this.props.itemType === pic.priorPopItems && priorPopsComponents}
        {this.props.itemType === pic.methodItems && methodsComponents}
        {navBtnDiv}
      </>
    );
  };
}

export default PIItemsForm;
