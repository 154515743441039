export { v4 as uuidv4 } from 'uuid'

export const generateHeterogeneousTypes = (packTable, dataType) =>
  generateTypes(packTable, [
    ...repeat("s", packTable.GBFixedCols),
    ...repeat(dataType, packTable.GBColCount - packTable.GBFixedCols),
  ]);

export const generateTypes = (packTable, dataRowTypes) => {
  if (!dataRowTypes) {
    dataRowTypes = [
      ...repeat("n", packTable.GBFixedCols),
      ...repeat("n", packTable.GBColCount - packTable.GBFixedCols),
    ];
  }

  return [
    ...repeat(repeat("s", packTable.GBColCount), packTable.GBFixedRows),
    ...repeat(dataRowTypes, packTable.GBRowCount - packTable.GBFixedRows),
  ];
};

export const repeat = (value, times) => Array.from({ length: times }, () => value);

// This is for promisifying Avenir callbacks. The success callback and error call back will be passed in the last two arguments.
export const promisify = (fn) => {
  return (...args) => {
    return new Promise((resolve, reject) => {
      try {
        fn(...args, resolve, reject);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
};
