import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";
import { useState } from "react";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbtc from "../../GB/GBTableConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import {
  combinePriorPops,
  disaggregatePriorPops,
  genAndKeyPopsCombinedPops,
  combinedPopsByCategory,
} from "../NonComponents/PIPriorPopUtil";
import React from "react";

const PIManualOptTargPSETable = (props) => {
  // State
  const [rDec, setRDecs] = useState([]);

  if (!piasu.isPSEMode(props.modVarObjList)) {
    return <div>PIManualOptTargPSETable requires PSE mode</div>;
  }

  const onModVarsChange = promisify(props.onModVarsChange);

  const modVarObjList = props.modVarObjList;

  /** @type {any[]} */
  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");

  /** @type {any[]} */
  const allPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let priorPops = allPops;

  /** @type {any[]} */
  let targClientsInit = piasu.getModVarValue(modVarObjList, "PI_TargetsByPriorityPop");

  /** @type {any[]} */
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  const categories = Object.keys(piu.pseIncidenceCategories);
  const genAndKeyPopsWithCategories = combinedPopsByCategory(genAndKeyPopsCombinedPops, allPops, categories);

  const combined = combinePriorPops(modVarObjList, genAndKeyPopsWithCategories, [
    "PI_TargetsByPriorityPop",
    "PI_Eligibility",
  ]);
  priorPops = combined["PI_PriorityPop"];
  targClientsInit = combined["PI_TargetsByPriorityPop"];
  eligibility = combined["PI_Eligibility"];

  const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));

  const numPops = Object.keys(genAndKeyPopsCombinedPops).length;

  const fixedRows = 1;
  const numRows = numPops * (categories.length + 1) + fixedRows;
  const numCols = methods.length + 2; // +2 = Pop + total cols

  const priorityPopCol = 0;
  const methodStartCol = 1;
  const methodTotalCol = methodStartCol + methods.length;

  // Callbacks
  const onPackTableChange = async (newPackTable) => {
    setRDecs(structuredClone(newPackTable.RDec));

    // Update "combined" target init modvar
    const newTargClientInits = structuredClone(targClientsInit);

    for (let methodIdx = 0; methodIdx < methods.length; ++methodIdx) {
      for (let rowIdx = 0; rowIdx < newPackTable.GBRowCount; ++rowIdx) {
        const catPopID = gbtu.getRowID(newPackTable, rowIdx);
        if (catPopID === 0) continue;

        const popIdx = priorPops.findIndex((p) => p.mstID === catPopID);

        const value = gbtu.getValue(newPackTable, rowIdx, methodStartCol + methodIdx);
        newTargClientInits[methodIdx].target[popIdx] = typeof value === "number" ? value : 0;
      }
    }

    // Disagg

    // FIXME: This should be handled as part of disagg and returned as part of the disagg result as it is on the server.
    // This is here just to get it across the line.

    /** @type {Object.<string, string>} */
    const popMap = Object.fromEntries(allPops.map((pop) => [pop.mstID, pop]));
    /** @type {Object.<string, number>} */
    const popSizes = Object.fromEntries(priorPops.map((pop) => [pop.mstID, pop.potential_users_pse]));
    /** @type {Object.<string, number>} */
    const ratios = Object.fromEntries(
      Object.entries(genAndKeyPopsWithCategories).flatMap(([combinedPopID, combinedPop]) =>
        combinedPop.originalPopIDs.map((popID) => [
          popID,
          popMap[popID]?.potential_users_pse / popSizes[combinedPopID] ?? 0,
        ])
      )
    );

    const newModVars = structuredClone(modVarObjList);

    const newData = {
      PI_PriorityPop: priorPops,
      PI_Eligibility: eligibility,
      PI_TargetsByPriorityPop: newTargClientInits,
    };

    const disagg = disaggregatePriorPops(modVarObjList, newData, genAndKeyPopsWithCategories);

    // FIXME: Little bit cheesey; disagg will have set the same value to each pop so we'll just multiply each pop's value by the correct ratio and be done with it.
    // FIXME: Handle this in disagg instead.
    for (let methodIdx = 0; methodIdx < methods.length; ++methodIdx) {
      for (let popIdx = 0; popIdx < allPops.length; ++popIdx) {
        const popID = allPops[popIdx].mstID;
        disagg.PI_TargetsByPriorityPop[methodIdx].target[popIdx] *= ratios[popID] ?? 0;
      }
    }

    piasu.setModVarValue(newModVars, "PI_TargetsByPriorityPop", disagg.PI_TargetsByPriorityPop);

    await onModVarsChange(newModVars, false);
  };

  // Table
  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  // Headings
  gbtu.setFixedRows(packTable, fixedRows);

  gbtu.setValue(packTable, 0, priorityPopCol, RS(SC.GB_stPriorityPop));

  for (let idx = 0; idx < methods.length; ++idx) {
    gbtu.setValue(packTable, 0, idx + methodStartCol, methods[idx].name);
  }

  gbtu.setValue(packTable, 0, methodStartCol + methods.length, RS(SC.GB_stTotal));

  // Row Titles by pops/incidence category
  let rowIdx = packTable.GBFixedRows;
  for (const popID in genAndKeyPopsCombinedPops) {
    gbtu.setValue(packTable, rowIdx, 0, genAndKeyPopsCombinedPops[popID].newPopName());
    gbtu.lockCells(packTable, rowIdx, true, true);

    ++rowIdx;

    // Incidence sub-groups
    for (let cat = 0; cat < categories.length; ++cat) {
      const catName = categories[cat];
      const catPopID = `${popID}_${catName.toUpperCase()}`;
      const pop = priorPops.find((p) => p.mstID === catPopID);

      gbtu.setValue(packTable, rowIdx, 0, piu.pseIncidenceCategories[catName]);
      gbtu.setIndent(packTable, rowIdx, true, Theme.leftIndent);
      gbtu.lockCells(packTable, rowIdx, true, true);

      if (pop === undefined) {
        gbtu.setRowBGColor(packTable, rowIdx, gainsboroBase10);
        gbtu.setCellBGColor(packTable, rowIdx, 0, gbtc.transparentBase10);
      } else {
        gbtu.setRowID(packTable, rowIdx, catPopID);
      }

      ++rowIdx;
    }
  }

  // Data by pop/incidence category
  for (let rowIdx = 0; rowIdx < packTable.GBRowCount; ++rowIdx) {
    const catPopID = gbtu.getRowID(packTable, rowIdx);
    if (catPopID === 0) continue;

    let totalInits = 0;

    // Target inits by method
    for (let methodIdx = 0; methodIdx < methods.length; ++methodIdx) {
      const methodCol = methodStartCol + methodIdx;

      const popIdx = priorPops.findIndex((p) => p.mstID === catPopID);
      const eligible = eligibility[methodIdx].value[popIdx].value === pisc.yesMstID;

      if (eligible) {
        const clientInits = piasu.getTargClientsInit(methods[methodIdx].mstID, targClientsInit, popIdx + 1);
        gbtu.setValue(packTable, rowIdx, methodCol, clientInits);
        totalInits += clientInits;

        gbtu.lockCell(packTable, rowIdx, methodCol, false);
      } else {
        gbtu.setCellBGColor(packTable, rowIdx, methodCol, gainsboroBase10);
        gbtu.setCellBGColor(packTable, rowIdx, methodCol, gainsboroBase10);
      }
    }

    // Totals
    gbtu.setValue(packTable, rowIdx, methodTotalCol, totalInits);
  }

  // Settings
  gbtu.alignNumericCellsRight(packTable);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
  gbtu.setColWidths(packTable, Theme.dataColWidthSmall);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidth);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.setID(packTable, "optionsPackTable");
  gbtu.setKey(packTable, "optionsPackTable");

  gbtu.restoreRDecsFromCopy(packTable, rDec, 1);

  return (
    // @ts-ignore
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PIManualOptTargPSETable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  tableKey: PropTypes.string,
};

PIManualOptTargPSETable.defaultProps = {
  onModVarsChange: () => {},
  tableKey: "",
};

export default PIManualOptTargPSETable;
