import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import { generateTypes, repeat } from "../../../utilities";
import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";

const firstRow = 0;

const numRows = 26;

class PIAGYWGeoPriorityTable extends Component {
  static propTypes = {
    [pip.countryISO3Str]: PropTypes.string,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pip.countryISO3Str]: "",

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},

    [pip.tableKey]: "",
  };

  state = {
    page: 1,
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 1,
        [pip.rowEnd]: 1,
        [pip.columnStart]: 2,
        [pip.columnEnd]: 2,
      },
    ],
    [pip.focusedCell]: {
      [pip.rowFocus]: 1,
      [pip.colFocus]: 2,
    },
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Utility Functions
  //
  //==================================================================================================================

  getShowInitsBool = () => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const showTargetsBool = piasu.showTargets(modVarObjArr);
    const threshCritMstID = piasu.getModVarValue(modVarObjArr, pisc.thresholdCriterionMVTag);

    return threshCritMstID !== pisc.totalCostSavingsAGYWMstID && showTargetsBool;
  };

  getAreaTypeCol = () => {
    return 0;
  };

  getCatchmentCol = () => {
    return 1;
  };

  getPrev15to24Col = () => {
    return 2;
  };

  getPrev10to14Col = () => {
    return 3;
  };

  getInc15to24Col = () => {
    return 4;
  };

  getNumInitCol = () => {
    return this.getShowInitsBool() ? 5 : -1;
  };

  getPopAGYW15to24Col = () => {
    return this.getShowInitsBool() ? 6 : 5;
  };

  getCostPerPYPrEPCol = () => {
    return this.getShowInitsBool() ? 7 : 6;
  };

  getCostPerPYARTCol = () => {
    return this.getShowInitsBool() ? 8 : 7;
  };

  getNumCols = () => {
    return this.getCostPerPYARTCol() + 1;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onContCurveComboBoxChange = (val, r) => {
    try {
      let modVarObjListClone = structuredClone(this.props[pias.modVarObjList]);
      const contCurveObjList = piasu.getModVarValue(modVarObjListClone, pisc.continuationCurvesMVTag);
      let areaObjArr = piasu.getModVarValue(modVarObjListClone, pisc.AGYWByAreaMVTag);

      piasu.contCurveMstIDAGYW(areaObjArr, r, contCurveObjList[val].mstID);
      piasu.setModVarValue(modVarObjListClone, pisc.AGYWByAreaMVTag, areaObjArr);

      this.props.onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onPackTableChange = (newPackTable) => {
    try {
      this.setState({
        [pip.rDec]: newPackTable[gbtc.rDec],
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const state = this.state;
      const rDec = state[pip.rDec];

      const areaObjArr = piasu.getModVarValue(modVarObjList, pisc.AGYWByAreaMVTag);
      const costPerPYPrEPSrcMstIDStr = piasu.getModVarValue(modVarObjList, pisc.AGYWCostPerPYPrEPSrcMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const costStayOnPrEPPT1DIntArr = piasu.getModVarValue(modVarObjList, pisc.costStayOnPrEPPTMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const costPerPYARTEntryTypeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.AGYWCostPerPYARTEntryTypeMVTag);
      const AGYWCostPerPYARTSingleValue = piasu.getModVarValue(modVarObjList, pisc.AGYWCostPerPYARTSingleValueMVTag);
      const costingModeMstID = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
      const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.AGYWTemplateUploadedMVTag);
      const varyCurvesAreaMstID = piasu.getModVarValue(modVarObjList, pisc.varyCurvesAreaMVTag);

      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
      const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

      const showInitsBool = this.getShowInitsBool();

      const areaTypeCol = this.getAreaTypeCol();
      const catchmentCol = this.getCatchmentCol();
      const prev15to24Col = this.getPrev15to24Col();
      const prev10to14Col = this.getPrev10to14Col();
      const inc15to24Col = this.getInc15to24Col();
      const numInitCol = this.getNumInitCol();
      const popAGYW15to24Col = this.getPopAGYW15to24Col();
      const costPerPYPrEPCol = this.getCostPerPYPrEPCol();
      const costPerPYARTCol = this.getCostPerPYARTCol();
      const numCols = this.getNumCols();

      // markAGYW
      if ((level1Name === "" && level2Name === "") || !templateUploaded) {
        return null;
      } else {
        let areas = [];

        const numAreas = piasu.getNumAGYWAreas(areaObjArr);

        for (let i = 1; i <= numAreas; i++) {
          areas.push(piasu.areaNameAGYW(areaObjArr, i));
        }

        const numR = areas.length > 0 ? areas.length + 1 : numRows;

        let packTable = gbtu.getNewPackTable();

        let numC = numCols;

        let contCurve = varyCurvesAreaMstID === pisc.yesMstID;

        let contCurveCol = -1;

        if (contCurve) {
          numC++;
          contCurveCol = numC;
        }

        packTable = gbtu.resizePackTable(packTable, numR, numC);

        const comboBoxWidth = 240;
        let comboBoxObj = pitu.getComboBoxObj(numR, numC);

        let firstColTitle = "";
        if (level2Name !== "") {
          firstColTitle = level2Name;
        } else {
          firstColTitle = level1Name;
        }

        gbtu.setValue(packTable, firstRow, areaTypeCol, firstColTitle);
        gbtu.setValue(packTable, firstRow, catchmentCol, RS(SC.GB_stCatchAreas));
        gbtu.setValue(packTable, firstRow, prev15to24Col, RS(SC.GB_stPrev15to24) + " (%)");
        gbtu.setValue(packTable, firstRow, prev10to14Col, RS(SC.GB_stPrev10to14) + " (%) " + RS(SC.GB_stOptParen));
        gbtu.setValue(packTable, firstRow, inc15to24Col, RS(SC.GB_stEstInc15to24) + " (%)");
        if (showInitsBool) {
          gbtu.setValue(packTable, firstRow, numInitCol, RS(SC.GB_stNumInit) + " " + RS(SC.GB_stOptParen));
        }
        gbtu.setValue(packTable, firstRow, popAGYW15to24Col, RS(SC.GB_stPopAGYW15to24) + " " + RS(SC.GB_stOptParen));
        gbtu.setValue(
          packTable,
          firstRow,
          costPerPYPrEPCol,
          RS(SC.GB_stCostPerPersonYrPrEP) + " (" + RS(SC.GB_stUSD) + ")"
        );
        gbtu.setValue(
          packTable,
          firstRow,
          costPerPYARTCol,
          RS(SC.GB_stCostPerPersonYrART) + " (" + RS(SC.GB_stUSD) + ")"
        );

        gbtu.setColWidths(packTable, Theme.dataColWidthSmall + 10);

        if (contCurve) {
          gbtu.setValue(packTable, firstRow, contCurveCol, RS(SC.GB_stContCurve));
          gbtu.setColWidth(packTable, contCurveCol, comboBoxWidth + 10);

          const contCurveObjList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
          const contCurveList = contCurveObjList.map((x) => x.name);
          for (let r = 1; r < numR; r++) {
            gbtu.setHasComboBox(packTable, r, contCurveCol, true);

            comboBoxObj[pitu.style2DObjArray][r][contCurveCol] = {
              color: Theme.blackColor,
              fontFamily: Theme.fontFamily,
              width: comboBoxWidth,
            };

            let info1DIntArray = [];
            info1DIntArray.length = numR;
            info1DIntArray.fill(r);
            comboBoxObj[pitu.info3DIntArray][r][contCurveCol] = info1DIntArray;
            comboBoxObj[pitu.item3DStrArray][r][contCurveCol] = contCurveList;

            comboBoxObj[pitu.idx2DIntArray][r][contCurveCol] = contCurveObjList
              .map((x) => x.mstID)
              .indexOf(piasu.contCurveMstIDAGYW(areaObjArr, r));

            comboBoxObj[pitu.onChange2DFuncArray][r][contCurveCol] = (val) => this.onContCurveComboBoxChange(val, r);
          }
        }

        for (let i = 1; i < numR; i++) {
          let areaName = piasu.areaNameAGYW(areaObjArr, i);
          let catchAreaName = piasu.catchmentAreaNameAGYW(areaObjArr, i);
          gbtu.setValue(packTable, firstRow + i, areaTypeCol, areaName);
          gbtu.setValue(packTable, firstRow + i, catchmentCol, catchAreaName);

          let areaCurrID = i;
          let prev15t24CostAGYW = piasu.prev15t24CostAGYW(areaObjArr, areaCurrID) * 100;
          let prev10t14AGYW = piasu.prev10t14AGYW(areaObjArr, areaCurrID);
          let estInc15t24AGYW = piasu.estInc15t24AGYW(areaObjArr, areaCurrID);
          let numInitAGYW = piasu.numInitAGYW(areaObjArr, areaCurrID);
          let pop15t24AGYW = piasu.pop15t24AGYW(areaObjArr, areaCurrID);

          let costPerPersonYrPrEPAGYW = 0;
          if (
            costingModeMstID !== pisc.noCostingModeMstID &&
            costPerPYPrEPSrcMstIDStr === pisc.AGYW_PerPYPrEPFromCostingSrcMstID
          ) {
            /* If pill is active, use that. Otherwise, use the first method. */
            let methodCurrID = 1;
            if (piasu.getDefMethodActive(methodObjArr, pisc.pillMethodMstID)) {
              methodCurrID = piasu.getMethodCurrID(methodObjArr, pisc.pillMethodMstID);
            }

            costPerPersonYrPrEPAGYW += piasu.getCostStayOnPrEPPP(
              costStayOnPrEPPT1DIntArr,
              methodCurrID,
              piasu.getPriorPopCurrID(priorPopObjList, pisc.AGYW_PP_MstID)
            );
          } else {
            costPerPersonYrPrEPAGYW = piasu.costPerPersonYrPrEPAGYW(areaObjArr, areaCurrID);
          }

          let costPerPersonYrARTAGYW = 0;

          if (costPerPYARTEntryTypeMstIDStr === pisc.AGYW_SingleCostPerPerPYARTMstID) {
            costPerPersonYrARTAGYW = AGYWCostPerPYARTSingleValue;
          } else {
            costPerPersonYrARTAGYW = piasu.costPerPersonYrARTAGYW(areaObjArr, areaCurrID);
          }

          gbtu.setValue(packTable, firstRow + i, prev15to24Col, prev15t24CostAGYW);
          gbtu.setValue(packTable, firstRow + i, prev10to14Col, prev10t14AGYW);
          gbtu.setValue(packTable, firstRow + i, inc15to24Col, estInc15t24AGYW * 100);
          if (showInitsBool) {
            gbtu.setValue(packTable, firstRow + i, numInitCol, numInitAGYW);
          }
          gbtu.setValue(packTable, firstRow + i, popAGYW15to24Col, pop15t24AGYW);
          gbtu.setValue(packTable, firstRow + i, costPerPYPrEPCol, costPerPersonYrPrEPAGYW);
          gbtu.setValue(packTable, firstRow + i, costPerPYARTCol, costPerPersonYrARTAGYW);
        }

        gbtu.alignNumericCellsRight(packTable);
        gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);

        gbtu.setRowHeight(packTable, firstRow, 100);

        if (rDec.length === 0) {
          gbtu.setRDecs(packTable, 0);
          gbtu.setRDecByCol(packTable, prev10to14Col, 1);
          gbtu.setRDecByCol(packTable, prev15to24Col, 1);
          gbtu.setRDecByCol(packTable, inc15to24Col, 1);
          gbtu.setRDecByCol(packTable, costPerPYPrEPCol, 2);
          gbtu.setRDecByCol(packTable, costPerPYARTCol, 2);
        } else {
          for (let r = 0; r < rDec.length; r++) {
            for (let c = 0; c < rDec[r].length; c++) {
              gbtu.setRDec(packTable, r, c, rDec[r][c]);
            }
          }
        }

        gbtu.lockPackTable(packTable, true, false);
        gbtu.setMaxAllowedValByCol(packTable, catchmentCol, 0);

        gbtu.setMinAllowedValByCol(packTable, prev15to24Col, 0);
        gbtu.setMaxAllowedValByCol(packTable, prev15to24Col, 100);
        gbtu.setMaxAllowedValByCol(packTable, popAGYW15to24Col, gbtc.maxInt);
        gbtu.setMaxAllowedValByCol(packTable, costPerPYARTCol, gbtc.maxInt);

        gbtu.setColWidth(packTable, areaTypeCol, 200);
        gbtu.setColWidth(packTable, catchmentCol, 200);

        if (window.DebugMode) {
          console.log("Component: PIAGYWGeoPriorityTable");
          console.log("ModVar(s):");
          console.log(pisc.AGYWByAreaMVTag);
          console.log(areaObjArr);
          console.log("");
        }

        const stdTable = (
          <GbStdTableWithComboBoxes
            focusedCell={this.state[pip.focusedCell]}
            onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
            font={Theme.tableFont}
            headerBackgroundColor={Theme.PI_PrimaryColor}
            gridKey={tableKey}
            oddRowBackgroundColor={Theme.PI_BandColor}
            packTable={packTable}
            types={generateTypes(packTable, ["s", "s", ...repeat("n", packTable.GBColCount - 2)])}
            onPackTableChanged={this.onPackTableChange}
            removedMenuNames={pitu.tableHideMenuItems}
            selectedRegions={this.state[pip.selectedRegions]}
            onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
            style={{
              fontFamily: Theme.tableFont,
              marginTop: 20,
              marginRight: 20,
              padding: 0,
            }}
            comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
            comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
            comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
            comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
            onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
            width={0}
          />
        );

        return stdTable;
      }
    };

    return gbu.tryRenderFn(fn, "render PIAGYWGeoPriorityTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAGYWGeoPriorityTable;
