// @ts-check
import React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pic from "../../NonComponents/PIConst";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes, repeat } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

import { combinePriorPops, contCurveCombinedPops } from "../../NonComponents/PIPriorPopUtil";

const numRows = 3;

function PIAnnualUnitCostsLiteResTable(props) {
  const modVarObjList = props.modVarObjList;

  let populations = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  // IMPORTANT: As we are merging PI_CostStayOnByPT, we need to use the same combined pops as in continuation curves otherwise the data will not be correct.

  let costStayOnByPTMVName = "PI_CostStayOnByPT";
  let costByPTMVName = "PI_CostByPT";
  if (props.includeAboveSite) {
    costStayOnByPTMVName += "_IncAS";
    costByPTMVName += "_IncAS";
  }

  let costStayOnPrEPPT1DIntArr = piasu.getModVarValue(modVarObjList, costStayOnByPTMVName);
  let costPerPersonInitPT1DIntArr = piasu.getModVarValue(modVarObjList, costByPTMVName);
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  if (piasu.isPSEMode(modVarObjList)) {
    const combined = combinePriorPops(props.modVarObjList, contCurveCombinedPops, [
      costStayOnByPTMVName,
      costByPTMVName,
      "PI_Eligibility",
    ]);

    populations = combined.PI_PriorityPop;
    costStayOnPrEPPT1DIntArr = combined[costStayOnByPTMVName];
    costPerPersonInitPT1DIntArr = combined[costByPTMVName];
    eligibility = combined["PI_Eligibility"];
  }

  const methods = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
  const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const selectedMethodCurrID = piasu.getMethodCurrID(methods, selectedMethodMstID);

  const numPriorPops = piasu.getTotalNumPriorPops(populations);

  /* Number of service delivery strategies + edu code column + personnel type column. */
  const numCols = numPriorPops + 1;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  /* Column headings */

  const methodName = piasu.methodName(methods, selectedMethodCurrID);
  gbtu.setValue(packTable, 1, 0, RS(SC.GB_stPersYearCostMETHOD).replace(pic.methodStr, methodName));
  gbtu.setValue(packTable, 2, 0, RS(SC.GB_stCostPerPersInit));

  for (let pp = 1; pp <= numPriorPops; pp++) {
    const pop = populations[pp - 1];
    const priorPopName = pop.name;

    gbtu.setValue(packTable, 0, pp, priorPopName);

    //markElig
    const methodEligMstIDStr = piasu.getPriorPopMethodElig(eligibility, selectedMethodMstID, pp);
    if (methodEligMstIDStr === pisc.yesCVOMstID) {
      let value = piasu.getCostStayOnPrEPPP(costStayOnPrEPPT1DIntArr, selectedMethodCurrID, pp);
      gbtu.setValue(packTable, 1, pp, value);

      value = piasu.getCostPerPersonInitPP(costPerPersonInitPT1DIntArr, selectedMethodCurrID, pp);
      gbtu.setValue(packTable, 2, pp, value);
    } else {
      gbtu.lockCol(packTable, pp, true, true);
      const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
      gbtu.setColBGColor(packTable, pp, gainsboroBase10);
    }

    gbtu.setColWidth(packTable, pp, Theme.dataColWidthMed);
  }

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setRDecs(packTable, 2);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.lockPackTable(packTable, true, false);

  gbtu.setID(packTable, "packTable");
  gbtu.setKey(packTable, "packTable");

  return (
    // @ts-ignore
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      key={"table"}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={{
        ...packTable,
        GBRowHeights: repeat(50, packTable.GBRowCount),
      }}
      types={generateTypes(packTable)}
      onPackTableChanged={() => {}}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        tableFont: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
    />
  );
}

PIAnnualUnitCostsLiteResTable.propTypes = {
  modVarObjList: PropTypes.arrayOf(PropTypes.object).isRequired,
  includeAboveSite: PropTypes.bool,
};

export default PIAnnualUnitCostsLiteResTable;
