import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";
import { useState } from "react";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as gbtu from "../../GB/GBTableUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as pitu from "../NonComponents/PITableUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import { combinePriorPops, disaggregatePriorPops, genAndKeyPopsCombinedPops } from "../NonComponents/PIPriorPopUtil";

const PIMethodMixCoverageByMethodTable = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
  const allPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let priorPops = allPops;
  let methodMix = piasu.getModVarValue(modVarObjList, "PI_MethodMix");
  let methodMixCoverage = piasu.getModVarValue(modVarObjList, "PI_MethodMixCoverage");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  if (isPSEMode) {
    const combined = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, [
      "PI_MethodMix",
      "PI_MethodMixCoverage",
      "PI_Eligibility",
    ]);
    methodMix = combined["PI_MethodMix"];
    methodMixCoverage = combined["PI_MethodMixCoverage"];
    priorPops = combined["PI_PriorityPop"];
    eligibility = combined["PI_Eligibility"];
  }

  const badNumberFontColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.red));
  const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));

  const numRows = priorPops.length + 2;
  const numCols = methods.length + 3;

  const firstRow = 2;
  const firstMethod = 2;

  // State
  const [rDec, setRDecs] = useState([]);

  // Callbacks
  const onPackTableChange = async (newPackTable) => {
    setRDecs(structuredClone(newPackTable.RDec));

    // Coverage
    let newCoverage = {};
    for (let pp = 0; pp < priorPops.length; ++pp) {
      const pop = priorPops[pp];
      const value = gbtu.getValue(newPackTable, pp + firstRow, 1);
      newCoverage[pop.mstID] = typeof value === "number" ? value / 100 : 0.0;
    }

    const newModVars = structuredClone(modVarObjList);
    if (isPSEMode) {
      const newData = {
        PI_PriorityPop: priorPops,
        PI_MethodMixCoverage: newCoverage,
      };

      const disagg = disaggregatePriorPops(modVarObjList, newData, genAndKeyPopsCombinedPops);
      newCoverage = disagg.PI_MethodMixCoverage;
    }

    piasu.setModVarValue(newModVars, "PI_MethodMixCoverage", newCoverage);

    await onModVarsChange(newModVars, false);
  };

  // Table
  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  // Headings
  gbtu.setFixedRows(packTable, 2);

  gbtu.mergeCells(packTable, 0, firstMethod, 1, methods.length + 1);
  gbtu.setValue(packTable, 0, firstMethod, RS(SC.GB_stTargetCoverage));

  gbtu.setValue(packTable, 1, 0, RS(SC.GB_stPriorityPop));
  gbtu.setValue(packTable, 1, 1, RS(SC.GB_stCoverage));
  for (let m = 0; m < methods.length; ++m) {
    gbtu.setValue(packTable, 1, m + firstMethod, methods[m].name);
  }
  gbtu.setValue(packTable, 1, numCols - 1, RS(SC.GB_stTotal));

  // Data
  for (let pp = 0; pp < priorPops.length; ++pp) {
    const pop = priorPops[pp];
    gbtu.setValue(packTable, pp + firstRow, 0, pop.name);

    let disableTotal = true;
    let total = 0.0;

    // Coverage
    const coverage = methodMixCoverage?.[pop.mstID] ?? 1.0;

    // Methods
    for (let m = 0; m < methods.length; ++m) {
      const method = methods[m];

      if (piasu.getPriorPopMethodElig(eligibility, method.mstID, pp + 1) === pisc.yesMstID) {
        const value = (methodMix?.[pop.mstID]?.[method.mstID] ?? 0) * coverage * 100;
        gbtu.setValue(packTable, pp + firstRow, m + firstMethod, value);

        total += value;
        disableTotal = false;
      } else {
        gbtu.setCellBGColor(packTable, pp + firstRow, m + firstMethod, gainsboroBase10);
      }

      gbtu.lockCell(packTable, pp + firstRow, m + firstMethod, true, true);
    }

    // Total
    if (!disableTotal) {
      gbtu.setValue(packTable, pp + firstRow, 1, coverage * 100);
      gbtu.setValue(packTable, pp + firstRow, numCols - 1, total);

      const gray = gbu.equal(total, 100) || total < 100;
      gbtu.lockCell(packTable, pp + firstRow, numCols - 1, true, gray);
      if (!gray) gbtu.setFontColor(packTable, pp + firstRow, numCols - 1, badNumberFontColor);
    } else {
      // Grey out Total
      gbtu.lockCell(packTable, pp + firstRow, numCols - 1, true, true);
      gbtu.setCellBGColor(packTable, pp + firstRow, numCols - 1, gainsboroBase10);

      // Grey out Coverage
      gbtu.lockCell(packTable, pp + firstRow, 1, true, true);
      gbtu.setCellBGColor(packTable, pp + firstRow, 1, gainsboroBase10);
    }
  }

  // Settings
  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, 100);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
  gbtu.setColWidth(packTable, 1, Theme.dataColWidthSmall);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.setID(packTable, "optionsPackTable");
  gbtu.setKey(packTable, "optionsPackTable");

  gbtu.setRDecs(packTable, 1);
  if (rDec.length !== 0) {
    for (let r = 0; r < rDec.length; r++) {
      for (let c = 0; c < rDec[r].length; c++) {
        gbtu.setRDec(packTable, r, c, rDec[r][c]);
      }
    }
  }

  return (
    <SuperTableShim
      // focusedCell={state[pip.focusedCell]}
      // onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
      // selectedRegions={state[pip.selectedRegions]}
      // onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      gridKey={props.tableKey}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PIMethodMixCoverageByMethodTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  tableKey: PropTypes.string,
};

PIMethodMixCoverageByMethodTable.defaultProps = {
  onModVarsChange: () => {},
  tableKey: "",
};

export default PIMethodMixCoverageByMethodTable;
