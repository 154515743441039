import * as PropTypes from "prop-types";
import { useState } from "react";

import { promisify } from "../../../utilities";

import * as gbtu from "../../GB/GBTableUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as pitu from "../NonComponents/PITableUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import TAlertDialog from "../../common/TAlertDialog";
import TButton from "../../common/TButton";

import { onCalculateAsync } from "../NonComponents/PICalc";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import { combinePriorPops, disaggregatePriorPops, genAndKeyPopsCombinedPops } from "../NonComponents/PIPriorPopUtil";

// Delete Button Component
const DeleteButton = ({ id, onClick }) => (
  <IconButton
    key={id}
    aria-label={"delete"}
    style={{
      marginLeft: 14,
      marginTop: -14, // FIXME: Not sure why this is necessary, but the button is offset out of the row otherwise
    }}
    onClick={onClick}
  >
    <DeleteIcon
      style={{
        color: Theme.PI_TertiaryColor,
      }}
    />
  </IconButton>
);

DeleteButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

DeleteButton.defaultProps = {
  onClick: () => {},
};

// PIDistrictPopTable Component
const PIDistrictPopTable = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);
  const onCalculatingChange = promisify(props.onCalculatingChange);

  const modVarObjList = props.modVarObjList;

  const isPSEMode = piasu.isPSEMode(modVarObjList);

  // const pseKeyPops = piasu.getModVarValue(modVarObjList, "PI_PSEKeyPops");

  let districtPops = piasu.getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);

  let combined;
  let priorPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  // let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");

  if (isPSEMode) {
    combined = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, ["PI_Eligibility", "PI_DistrictPopulations"]);
    priorPops = combined["PI_PriorityPop"];
    // eligibility = combined["PI_Eligibility"];
    districtPops = combined["PI_DistrictPopulations"];
  }

  // const priorityPopulations = districtPop1DObjArr[0].priorityPopulations ?? [];
  const priorityPopulations = priorPops.map((population) => [population.name]);

  const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
  const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.disagTargTemplateUploadedMVTag);

  const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
  const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

  // Helper Methods
  const getCellCoordinates = (params) => [
    params.rowIndex,
    params.columnIndex ?? parseInt(params.columnId.replace("field", ""), 10) - 1,
  ];

  const mapPriorityPopulationSelections = () => {
    return (
      districtPops.map((district) =>
        district.priorityPopulations?.map((priorityPopulation) => priorityPopulation[1])
      ) ?? []
    );
  };

  const getSelectionCoordinates = (params) => {
    const offset = (level2Name ?? "") === "" ? 3 : 4;

    const cellCoordinates = getCellCoordinates(params);
    const rowIndex = cellCoordinates[0] - 1;
    const columnIndex = cellCoordinates[1] - offset;

    return {
      rowIndex,
      columnIndex,
    };
  };

  const validateSelectedDistrictPopulations = (selectedPriorityPopulations, params) => {
    const { rowIndex, columnIndex } = getSelectionCoordinates(params);

    const selections = selectedPriorityPopulations.map((row) => [...row]);
    selections[rowIndex][columnIndex] = !selections[rowIndex][columnIndex];

    for (let columnIndex = 0; columnIndex < selections[0].length; columnIndex++) {
      let regionSelectedForPopulation = false;

      for (const row of selections) {
        if (row[columnIndex]) {
          regionSelectedForPopulation = true;

          break;
        }
      }

      if (!regionSelectedForPopulation) {
        return false;
      }
    }

    return true;
  };

  const updateSelectedPopulations = (modvars, selectedPopulations) => {
    const newDistrictPops = structuredClone(districtPops);
    const newData = {
      PI_PriorityPop: priorPops,
      PI_DistrictPopulations: newDistrictPops.map((district, districtIndex) => ({
        ...district,
        priorityPopulations: district.priorityPopulations?.map((priorityPopulation, priorityPopulationIndex) => [
          priorityPopulation[0],
          selectedPopulations[districtIndex][priorityPopulationIndex],
        ]),
      })),
    };

    let districtPopulations = newData.PI_DistrictPopulations;
    if (isPSEMode) {
      const disagg = disaggregatePriorPops(modvars, newData, genAndKeyPopsCombinedPops);
      districtPopulations = disagg.PI_DistrictPopulations;
    }

    piasu.setModVarValue(modvars, pisc.districtPopulationsMVTag, districtPopulations);
  };

  // State
  const selectedPriorityPops = mapPriorityPopulationSelections();

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  // FIXME: Verify this
  if (level1Name === "" || (!isPSEMode && !templateUploaded)) {
    return (
      <p
        style={{
          display: "block",
          marginTop: 20,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stSpecifySubnatLevelOneToSeeTable)}
      </p>
    );
  }

  // Callbacks
  const handleCellValueChanged = async (params) => {
    const newModVars = structuredClone(modVarObjList);

    const newDistrictPops = piasu.getModVarValue(newModVars, pisc.districtPopulationsMVTag);
    switch (params.columnIndex) {
      case 0:
        piasu.provinceNameDP(newDistrictPops, params.rowIndex, params.updatedValue);
        break;

      case 1:
        piasu.districtNameDP(newDistrictPops, params.rowIndex, params.updatedValue);
        break;

      case pop15PlusCol:
        piasu.pop15PlusDP(newDistrictPops, params.rowIndex, params.updatedValue);
        break;

      default:
    }

    if (params.type === "cb") {
      const { rowIndex, columnIndex } = getSelectionCoordinates(params);

      if (!validateSelectedDistrictPopulations(selectedPriorityPops, params)) {
        setAlertDialogOpen(true);
        return;
      }

      selectedPriorityPops[rowIndex][columnIndex] = params.updatedValue;
      updateSelectedPopulations(newModVars, selectedPriorityPops);
    }

    try {
      if (!props.deferCellChangeCalculate) await onCalculatingChange(true);

      await onModVarsChange(newModVars, false);

      if (!props.deferCellChangeCalculate) {
        const response = await onCalculateAsync(newModVars, "", props.onDialogChange);
        await onModVarsChange(response, false);
      }
    } catch (err) {
      alert(err.message);
    } finally {
      await onCalculatingChange(false);
    }
  };

  const onAddDistrictClick = async () => {
    try {
      const newModVars = structuredClone(modVarObjList);

      const districts = piasu.getModVarValue(newModVars, pisc.districtPopulationsMVTag);
      const newDistrict = piasu.createDistrictPopObj(RS(SC.GB_stLevel1), RS(SC.GB_stLevel2));
      newDistrict.priorityPopulations = piasu
        .getModVarValue(newModVars, pisc.priorPopsMVTag)
        .map((population) => [population.mstID, true]);

      districts.push(newDistrict);

      piasu.setModVarValue(newModVars, pisc.districtPopulationsMVTag, districts);

      await onCalculatingChange(true);
      await onModVarsChange(newModVars, false);
      const response = await onCalculateAsync(newModVars, "", props.onDialogChange);
      await onModVarsChange(response, false);
    } catch (err) {
      alert(err.message);
    } finally {
      await onCalculatingChange(false);
    }
  };

  const onDeleteDistrictClick = async (row) => {
    try {
      const newModVars = structuredClone(modVarObjList);

      const districts = piasu.getModVarValue(newModVars, pisc.districtPopulationsMVTag);
      districts.splice(row - 1, 1);

      piasu.setModVarValue(newModVars, pisc.districtPopulationsMVTag, districts);

      await onCalculatingChange(true);
      await onModVarsChange(newModVars, false);
      const response = await onCalculateAsync(newModVars, "", props.onDialogChange);
      await onModVarsChange(response, false);
    } catch (err) {
      alert(err.message);
    } finally {
      await onCalculatingChange(false);
    }
  };

  // Table
  const firstRow = 0;
  const level1NameCol = 0;
  const level2NameCol = 1;
  let pop15PlusCol = 2;
  let percCol = 3;
  let numCols = 4 + priorityPopulations.length + 1;
  let deleteCol = numCols - 1;

  // Areas are fixed in PSE mode, so don't allow deleting them
  if (isPSEMode) --numCols;

  // markAGYW
  if (level2Name === "") {
    pop15PlusCol--;
    percCol--;
    numCols--;
    deleteCol--;
  }
  const levelCount = levelNames1DStrArr.filter((name) => Boolean(name)).length;

  const numDistrictsDP = piasu.getNumDistrictsDP(districtPops);
  const numRows = numDistrictsDP + 1;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);
  gbtu.setFixedCols(packTable, 0);

  if (!isPSEMode) gbtu.lockCol(packTable, deleteCol, true, false);

  // Titles
  gbtu.setValue(packTable, firstRow, level1NameCol, level1Name);
  if (level2Name !== "") {
    gbtu.setValue(packTable, firstRow, level2NameCol, level2Name);
  }
  gbtu.setValue(packTable, firstRow, pop15PlusCol, RS(SC.GB_stPop15Plus));
  gbtu.setValue(packTable, firstRow, percCol, RS(SC.GB_stPercent));

  for (const [i, priorityPopulation] of priorityPopulations.entries()) {
    gbtu.setValue(packTable, firstRow, percCol + 1 + i, priorityPopulation[0]);
  }

  // Data
  for (let dp = 1; dp <= numDistrictsDP; dp++) {
    const area1Name = piasu.provinceNameDP(districtPops, dp);
    gbtu.setValue(packTable, dp, level1NameCol, area1Name);

    if (level2Name !== "") {
      const area2Name = piasu.districtNameDP(districtPops, dp);
      gbtu.setValue(packTable, dp, level2NameCol, area2Name);
    }

    const pop15Plus = piasu.pop15PlusDP(districtPops, dp);
    gbtu.setValue(packTable, dp, pop15PlusCol, pop15Plus);

    const percent = piasu.percentDP(districtPops, dp);
    gbtu.setValue(packTable, dp, percCol, percent * 100);

    for (let i = 0; i < priorityPopulations.length; i++) {
      const selected = selectedPriorityPops?.[dp - 1]?.[i] ?? true;

      gbtu.setHasCheckBox(packTable, dp, percCol + 1 + i, true);
      gbtu.setCheckBoxState(packTable, dp, percCol + 1 + i, selected);
      gbtu.setAlignment(packTable, dp, percCol + 1 + i, gbtc.hAlign.center);
      gbtu.lockCell(packTable, dp, percCol + 1 + i, true, false);
    }

    if (!isPSEMode)
      packTable.components[dp][deleteCol] = () => (
        <DeleteButton
          id={`delbtn${dp}`}
          onClick={() => {
            onDeleteDistrictClick(dp);
          }}
        />
      );
  }

  // Table Formatting
  if (level2Name !== "") {
    gbtu.lockCol(packTable, level2NameCol, false, false);
  }
  if (isPSEMode) {
    // In PSE mode, this data should not be editable
    gbtu.lockCol(packTable, level1NameCol, true);
    gbtu.lockCol(packTable, level2NameCol, true);
    gbtu.lockCol(packTable, pop15PlusCol, true);
  }

  gbtu.lockCol(packTable, percCol, true);
  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
  gbtu.setMaxAllowedValByCol(packTable, pop15PlusCol, gbtc.maxInt);
  gbtu.setRDecByCol(packTable, pop15PlusCol, 0);
  gbtu.setRDecByCol(packTable, percCol, 1);
  gbtu.setColWidth(packTable, level1NameCol, Theme.itemNameColWidth);

  if (level2Name !== "") {
    // In PSE mode, level 2 name is area ID so does not need such a wide column
    gbtu.setColWidth(packTable, level2NameCol, !isPSEMode ? Theme.itemNameColWidth : Theme.dataColWidthMed);
  }

  gbtu.setColWidth(packTable, pop15PlusCol, Theme.dataColWidthMed);
  gbtu.setColWidth(packTable, percCol, Theme.dataColWidthMed);

  for (let i = 0; i < priorityPopulations.length; i++) {
    gbtu.setColWidth(packTable, percCol + 1 + i, Theme.dataColWidthMed);
  }

  if (!isPSEMode) {
    gbtu.setColWidth(packTable, deleteCol, Theme.dataColWidthSmall);
    gbtu.setColAlignment(packTable, deleteCol, gbtc.hAlign.center);
  }

  gbtu.setRowHeight(packTable, firstRow, 70);

  return (
    <>
      <SuperTableShim
        gridKey={"district-pop-table"}
        font={Theme.fontFamily}
        headerBackgroundColor={Theme.PI_PrimaryColor}
        oddRowBackgroundColor={Theme.PI_BandColor}
        packTable={packTable}
        types={generateTypes(packTable, [
          ...repeat("s", levelCount),
          "n",
          "n",
          ...repeat("cb", packTable.GBColCount - levelCount - (isPSEMode ? 2 : 3)),
          "cm",
        ])}
        removedMenuNames={pitu.tableHideMenuItems}
        style={{
          fontFamily: Theme.fontFamily,
          marginTop: 30,
          padding: 0,
        }}
        onCellValueChanged={handleCellValueChanged}
        undoDisabled={false}
      />
      {!isPSEMode && (
        <TButton
          caption={RS(SC.GB_stAddDistrictPop)}
          key={"addDistrictBtn"}
          onClick={onAddDistrictClick}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            display: "block",
            marginBottom: 20,
            // marginLeft: Theme.leftIndent,
            marginTop: Theme.topIndent,
            width: 200,
          }}
        />
      )}
      <TAlertDialog
        open={alertDialogOpen}
        title={RS(SC.GB_stError)}
        content={RS(SC.GB_stAreaSelectionConstraint)}
        onClose={() => {
          setAlertDialogOpen(false);
        }}
      />
    </>
  );
};

PIDistrictPopTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,

  onCalculatingChange: PropTypes.func,
  onDialogChange: PropTypes.func,

  // When true, disables running calculate during pack table cell changes. Parent
  // becomes responsible for running "calculate" before dependant data is used.
  deferCellChangeCalculate: PropTypes.bool,
};

PIDistrictPopTable.defaultProps = {
  onModVarsChange: () => {},
  onCalculatingChange: () => {},
  onDialogChange: () => {},

  deferCellChangeCalculate: false,
};

export default PIDistrictPopTable;
