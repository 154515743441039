import * as PropTypes from "prop-types";

import { promisify } from "../../../utilities";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as pic from "../NonComponents/PIConst";
import * as gbtc from "../../GB/GBTableConst";

import { repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import { InformationTooltip } from "../Other/PICustomRowsUtil";

import { combinePriorPops, disaggregatePriorPops, genAndKeyPopsCombinedPops } from "../NonComponents/PIPriorPopUtil";

/* Input rows */
const costPerPersMonthRow = 1; // calced
const methodCostRow = 2;
const adhereSupportCostRow = 3;
const costPerVisitHeaderRow = 4; // no data
const initVisitCostsRow = 5; // calced
const initPersCostsRow = 6;
const initVisitLabCostsRow = 7;
const initOtherRecurCostsRow = 8;
const initCapCostsRow = 9;
const contVisitCostsRow = 10; // calced
const contPersCostsRow = 11;
const contVisitLabCostsRow = 12;
const contOtherRecurCostsRow = 13;
const contCapCostsRow = 14;
const annAboveSiteCostsRow = 15;
const numRows = annAboveSiteCostsRow + 1;

// markHardcoded

export const includeCostList = [
  "skip", // per person costs total row
  pisc.ARVsCCLite, // method cost
  pisc.adherenceSupportCCLite,
  "skip", // cost per visit header
  "skip", // init visit costs total row
  [pisc.initCCLite, pisc.personnelCCLite],
  [pisc.initCCLite, pisc.visitLabsCCLite],
  [pisc.initCCLite, pisc.recurrentCCLite],
  [pisc.initCCLite, pisc.capitalCCLite],
  "skip", // cont visit costs total row
  [pisc.contCCLite, pisc.personnelCCLite],
  [pisc.contCCLite, pisc.visitLabsCCLite],
  [pisc.contCCLite, pisc.recurrentCCLite],
  [pisc.contCCLite, pisc.capitalCCLite],
  pisc.annualCCLite,
];

const PICostCatLiteTable = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  const methods = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
  const selectedMethodID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const methodName = methods.find((m) => m.mstID === selectedMethodID)?.name;

  let combined;
  let priorPops = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  let eligibility = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
  let costCatLite = piasu.getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);

  if (isPSEMode) {
    combined = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, ["PI_Eligibility", "PI_CostCategories_Lite"]);
    priorPops = combined["PI_PriorityPop"];
    eligibility = combined["PI_Eligibility"];
    costCatLite = combined["PI_CostCategories_Lite"];
  }

  // Callbacks

  /**
   * Returns cost category title for given row number
   *
   * @param {number} row Row number
   * @returns {string}
   */
  const getRowText = (row) => {
    switch (row) {
      case methodCostRow:
        return methodName;

      case initVisitCostsRow:
        return RS(SC.GB_stInitVisitExclMETHOD).replace(pic.methodStr, methodName);

      case contVisitCostsRow:
        return RS(SC.GB_stContVisitExclMETHOD).replace(pic.methodStr, methodName);

      default:
        return "";
    }
  };

  const onPackTableChange = async (newPackTable) => {
    const newModVars = structuredClone(modVarObjList);

    const newData = {
      PI_PriorityPop: priorPops,
      PI_CostCategories_Lite: structuredClone(costCatLite),
    };

    // Costs
    for (let pp = 1; pp <= newData.PI_PriorityPop.length; ++pp) {
      if (piasu.getPriorPopMethodElig(eligibility, selectedMethodID, pp) !== pisc.yesCVOMstID) continue;

      const methodCost = gbtu.getValue(newPackTable, methodCostRow, pp);

      const adhereSupportCost = gbtu.getValue(newPackTable, adhereSupportCostRow, pp);
      piasu.setAdhereSupportCostLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, adhereSupportCost);

      let costPerPersMonth = 0
      if (gbtu.getCheckBoxState(newPackTable, adhereSupportCostRow, priorPops.length + 1))
        costPerPersMonth += adhereSupportCost;
      if (gbtu.getCheckBoxState(newPackTable, methodCostRow, priorPops.length + 1))
        costPerPersMonth += methodCost;

      piasu.setCostPerPersMonthCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, costPerPersMonth);

      const initPersonnelCosts = gbtu.getValue(newPackTable, initPersCostsRow, pp);
      piasu.setInitPersonnelCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, initPersonnelCosts);

      const initLabCosts = gbtu.getValue(newPackTable, initVisitLabCostsRow, pp);
      piasu.setInitLabCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, initLabCosts);

      const initOtherRecurCosts = gbtu.getValue(newPackTable, initOtherRecurCostsRow, pp);
      piasu.setInitOtherRecurCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, initOtherRecurCosts);

      const initCapCosts = gbtu.getValue(newPackTable, initCapCostsRow, pp);
      piasu.setInitCapCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, initCapCosts);

      let initVisitCosts = 0;
      if (gbtu.getCheckBoxState(newPackTable, initPersCostsRow, priorPops.length + 1)) {
        initVisitCosts += initPersonnelCosts;
      }
      if (gbtu.getCheckBoxState(newPackTable, initVisitLabCostsRow, priorPops.length + 1)) {
        initVisitCosts += initLabCosts;
      }
      if (gbtu.getCheckBoxState(newPackTable, initOtherRecurCostsRow, priorPops.length + 1)) {
        initVisitCosts += initOtherRecurCosts;
      }
      if (gbtu.getCheckBoxState(newPackTable, initCapCostsRow, priorPops.length + 1)) {
        initVisitCosts += initCapCosts;
      }
      piasu.setInitVisitCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, initVisitCosts);

      const contPersonnelCosts = gbtu.getValue(newPackTable, contPersCostsRow, pp);
      piasu.setContPersonnelCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, contPersonnelCosts);

      const contLabCosts = gbtu.getValue(newPackTable, contVisitLabCostsRow, pp);
      piasu.setContLabTestCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, contLabCosts);

      const contOtherRecurCosts = gbtu.getValue(newPackTable, contOtherRecurCostsRow, pp);
      piasu.setContOtherRecurCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, contOtherRecurCosts);

      const contCapCosts = gbtu.getValue(newPackTable, contCapCostsRow, pp);
      piasu.setContCapCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, contCapCosts);

      let contVisitCosts = 0;
      if (gbtu.getCheckBoxState(newPackTable, contPersCostsRow, priorPops.length + 1)) {
        contVisitCosts += contPersonnelCosts;
      }
      if (gbtu.getCheckBoxState(newPackTable, contVisitLabCostsRow, priorPops.length + 1)) {
        contVisitCosts += contLabCosts;
      }
      if (gbtu.getCheckBoxState(newPackTable, contOtherRecurCostsRow, priorPops.length + 1)) {
        contVisitCosts += contOtherRecurCosts;
      }
      if (gbtu.getCheckBoxState(newPackTable, contCapCostsRow, priorPops.length + 1)) {
        contVisitCosts += contCapCosts;
      }
      piasu.setContVisitCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, contVisitCosts);

      const annLumpSumCost = gbtu.getValue(newPackTable, annAboveSiteCostsRow, pp);
      piasu.setAnnLumpSumCostsLite(selectedMethodID, newData.PI_CostCategories_Lite, pp, annLumpSumCost);
    }

    // Include/Exclude
    for (let costRow = 1; costRow < numRows; costRow++) {
      if (includeCostList[costRow - 1] === "skip") {
        continue;
      }
      const isIncluded = gbtu.getCheckBoxState(newPackTable, costRow, priorPops.length + 1);
      piasu.setCostIncludedCostsLite(
        includeCostList[costRow - 1],
        selectedMethodID,
        newData.PI_CostCategories_Lite,
        isIncluded
      );
    }

    if (isPSEMode) {
      const disagg = disaggregatePriorPops(modVarObjList, newData, genAndKeyPopsCombinedPops);
      piasu.setModVarValue(newModVars, pisc.costCategoriesLiteMVTag, disagg.PI_CostCategories_Lite);
    } else {
      piasu.setModVarValue(newModVars, pisc.costCategoriesLiteMVTag, newData.PI_CostCategories_Lite);
    }

    await onModVarsChange(newModVars, false);
  };

  // Table
  const numCols = priorPops.length + 2; // 1 for category label, one for include / exclude checkbox

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  gbtu.setValue(packTable, 0, 0, RS(SC.GB_stCostCategory));

  gbtu.setValue(packTable, costPerPersMonthRow, 0, RS(SC.GB_stCostsPerPersMonth));
  gbtu.setFontStyle(packTable, costPerPersMonthRow, 0, [gbtc.fontStyle.bold]);

  gbtu.setValue(packTable, methodCostRow, 0, getRowText(methodCostRow));
  gbtu.setIndent(packTable, methodCostRow, true, Theme.leftIndent);

  gbtu.setValue(packTable, adhereSupportCostRow, 0, RS(SC.GB_stAdhereSupportOtherCosts));
  gbtu.setIndent(packTable, adhereSupportCostRow, true, Theme.leftIndent);

  gbtu.setValue(packTable, costPerVisitHeaderRow, 0, RS(SC.GB_stCostsPerVisit));
  gbtu.setFontStyle(packTable, costPerVisitHeaderRow, 0, [gbtc.fontStyle.bold]);
  gbtu.mergeCells(packTable, costPerVisitHeaderRow, 0, 1, packTable.GBColCount);
  gbtu.lockCells(packTable, costPerVisitHeaderRow, true, true);

  gbtu.setValue(packTable, initVisitCostsRow, 0, getRowText(initVisitCostsRow));
  gbtu.setIndent(packTable, initVisitCostsRow, true, Theme.leftIndent);
  gbtu.setFontStyle(packTable, initVisitCostsRow, 0, [gbtc.fontStyle.bold]);

  gbtu.setValue(packTable, initPersCostsRow, 0, RS(SC.GB_stPersonnel));
  gbtu.setIndent(packTable, initPersCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, initVisitLabCostsRow, 0, RS(SC.GB_stInitVisitLabs));
  gbtu.setIndent(packTable, initVisitLabCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, initOtherRecurCostsRow, 0, RS(SC.GB_stOtherRecurSiteTrainingEtc));
  gbtu.setIndent(packTable, initOtherRecurCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, initCapCostsRow, 0, RS(SC.GB_stCapCosts));
  gbtu.setIndent(packTable, initCapCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, contVisitCostsRow, 0, getRowText(contVisitCostsRow));
  gbtu.setIndent(packTable, contVisitCostsRow, true, Theme.leftIndent);
  gbtu.setFontStyle(packTable, contVisitCostsRow, 0, [gbtc.fontStyle.bold]);

  gbtu.setValue(packTable, contPersCostsRow, 0, RS(SC.GB_stPersonnel));
  gbtu.setIndent(packTable, contPersCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, contVisitLabCostsRow, 0, RS(SC.GB_stContVisitLabs));
  gbtu.setIndent(packTable, contVisitLabCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, contOtherRecurCostsRow, 0, RS(SC.GB_stOtherRecurSiteTrainingEtc));
  gbtu.setIndent(packTable, contOtherRecurCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, contCapCostsRow, 0, RS(SC.GB_stCapCosts));
  gbtu.setIndent(packTable, contCapCostsRow, true, Theme.leftIndent * 2);

  gbtu.setValue(packTable, annAboveSiteCostsRow, 0, RS(SC.GB_stAnnAboveSiteLumpSumCosts));
  gbtu.setFontStyle(packTable, annAboveSiteCostsRow, 0, [gbtc.fontStyle.bold]);

  /* set include / exclude packtable column */
  // gbtu.setValue(packTable, 0, numCols - 1, RS(SC.GB_stIncludeCost));
  let easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);
  const doNotSetTargets = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

  packTable.components[0][numCols - 1] = () => (
    <span>
      {RS(SC.GB_stIncludeCost)}
      <InformationTooltip
        id="costinfotooltip"
        message={RS(doNotSetTargets ? SC.GB_stCostsInfoBoxNoTargets : SC.GB_stCostsInfoBox)}
        color={Theme.whiteColor}
      />
    </span>
  );
  for (let costRow = 1; costRow < numRows; costRow++) {
    if (includeCostList[costRow - 1] === "skip") {
      gbtu.lockCell(packTable, costRow, numCols - 1, true, true);
    } else {
      const isIncluded = piasu.getCostIncludedCostsLite(includeCostList[costRow - 1], selectedMethodID, costCatLite);
      gbtu.setHasCheckBox(packTable, costRow, numCols - 1, true);
      gbtu.setCheckBoxState(packTable, costRow, numCols - 1, isIncluded);
    }
  }

  for (let pp = 1; pp <= priorPops.length; pp++) {
    /* Set col headings. */
    const priorPopName = piasu.getPriorPopName(priorPops, pp);
    gbtu.setValue(packTable, 0, pp, priorPopName);

    //markElig
    const methodEligMstIDStr = piasu.getPriorPopMethodElig(eligibility, selectedMethodID, pp);
    if (methodEligMstIDStr === pisc.yesCVOMstID) {
      const costPerPersMonth = piasu.getCostPerPersMonthCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, costPerPersMonthRow, pp, costPerPersMonth);
      gbtu.lockCell(packTable, costPerPersMonthRow, pp, true, true);

      const ARVCost = piasu.getARVCostLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, methodCostRow, pp, ARVCost);
      gbtu.lockCell(packTable, methodCostRow, pp, true, true);

      const adhereSupportCost = piasu.getAdhereSupportCostLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, adhereSupportCostRow, pp, adhereSupportCost);

      const initVisitCosts = piasu.getInitVisitCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, initVisitCostsRow, pp, initVisitCosts);
      gbtu.lockCell(packTable, initVisitCostsRow, pp, true, true);

      const initPersonnelCosts = piasu.getInitPersonnelCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, initPersCostsRow, pp, initPersonnelCosts);

      const initLabCosts = piasu.getInitLabCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, initVisitLabCostsRow, pp, initLabCosts);

      const initOtherRecurCosts = piasu.getInitOtherRecurCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, initOtherRecurCostsRow, pp, initOtherRecurCosts);

      const initCapCosts = piasu.getInitCapCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, initCapCostsRow, pp, initCapCosts);

      const contVisitCosts = piasu.getContVisitCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, contVisitCostsRow, pp, contVisitCosts);
      gbtu.lockCell(packTable, contVisitCostsRow, pp, true, true);

      const contPersonnelCosts = piasu.getContPersonnelCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, contPersCostsRow, pp, contPersonnelCosts);

      const contLabCosts = piasu.getContLabTestCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, contVisitLabCostsRow, pp, contLabCosts);

      const contOtherRecurCosts = piasu.getContOtherRecurCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, contOtherRecurCostsRow, pp, contOtherRecurCosts);

      const contCapCosts = piasu.getContCapCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, contCapCostsRow, pp, contCapCosts);

      const annLumpSumCost = piasu.getAnnLumpSumCostsLite(selectedMethodID, costCatLite, pp);
      gbtu.setValue(packTable, annAboveSiteCostsRow, pp, annLumpSumCost);
    } else {
      gbtu.lockCol(packTable, pp, true, true);
    }
  }

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setRDecs(packTable, 2);
  gbtu.setColWidth(packTable, 0, 500);
  for (let pp = 1; pp <= priorPops.length; pp++) {
    gbtu.setColWidth(packTable, pp, Theme.dataColWidthMed);
  }
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
  pitu.colorizeTable(packTable);

  gbtu.setID(packTable, "packTable");
  gbtu.setKey(packTable, "packTable");

  // Try to re-create fixed row header styles because we had to disable fixed rows in order to use a component.
  // FIXME: Vert align is wrong
  for (let col = 0; col < packTable.GBColCount; ++col) {
    gbtu.setCellBGColor(packTable, 0, col, Theme.PI_PrimaryColor);
    gbtu.setFontColor(packTable, 0, col, Theme.whiteColor);
    gbtu.setAlignment(packTable, 0, col, gbtc.hAlign.center);
  }

  return (
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      key={"table"}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={{
        ...packTable,
        GBFixedRows: 0,
        GBRowHeights: repeat(25, packTable.GBRowCount).map((rowHeight, rowIndex) =>
          [8, 13, 15].includes(rowIndex) ? 40 : rowHeight
        ),
      }}
      types={[
        ...repeat([...repeat("s", packTable.GBColCount - 1), "cm"], packTable.GBFixedRows),
        ...repeat(["s", ...repeat("n", packTable.GBColCount - 2), "cb"], packTable.GBRowCount - packTable.GBFixedRows),
      ]}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        tableFont: Theme.tableFont,

        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PICostCatLiteTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  tableKey: PropTypes.string,
};

PICostCatLiteTable.defaultProps = {
  onModVarsChange: () => {},
  tableKey: "",
};

export default PICostCatLiteTable;
