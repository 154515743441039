import React, { useState } from "react";
import * as PropTypes from "prop-types";

import { downloadTargetsOutputPEPFARXLSX } from "../../../api/server_calls";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TComboBox from "../../common/TComboBox";
import TButton from "../../common/TButton";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import * as pip from "../NonComponents/PIProps";
import * as pic from "../NonComponents/PIConst";
import * as pieh from "../NonComponents/PIEventHandlers";

import PITargDisagDistrictPriorPopsAggResTable from "../Tables/Results/PITargDisagDistrictPriorPopsAggResTable";
import PITargDisagDistrictPriorPopsResTable from "../Tables/Results/PITargDisagDistrictPriorPopsResTable";

import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PITimePeriodDiv, { PITimePeriodDivProps } from "../Other/PITimePeriodDiv";
import PITimePeriodLab, { PITimePeriodLabProps } from "../Other/PITimePeriodLab";

/**
 * @typedef {import("../NonComponents/PIUtil").AvenirDateObject} AvenirDateObject
 */

const PIDisagTargetsResultContent = (props) => {
  const modVarObjList = props.modVarObjList;
  const onModVarsChange = props.onModVarsChange;

  const PrEPStatus = piasu.getModVarValue(modVarObjList, pisc.selectedPrEPStatusMVTag);
  const methods = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
  const area2Name = piasu.getModVarValue(modVarObjList, pisc.disagTargArea2NameMVTag);
  const isAggMode = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag) === pisc.aggregateToolMstID;
  const [level1Name] = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);

  const targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);
  const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjList);
  const [drdState, setDRDState] = useState(dateRangeDisplayObj);

  const currentFinancialYear = piu.getCurrentFinancialYear();
  const includesFinancialYear = piu.includesFinalYear(drdState, currentFinancialYear);

  const onPrEPStatusComboBoxChange = (value, text, info) => {
    const modVarObjListClone = structuredClone(modVarObjList);
    piasu.setModVarValue(modVarObjListClone, pisc.selectedPrEPStatusMVTag, info);
    onModVarsChange(modVarObjListClone, false);
  };

  const onDateRangeDisplayChange = (monthOrYearInt, startOrEndInt, successFn) => {
    const newDRD = structuredClone(drdState);
    piasu.setTimeframeData(newDRD, monthOrYearInt, startOrEndInt);
    setDRDState(newDRD);
  };

  const onSetPeriod = () => {
    const modVarObjListClone = structuredClone(modVarObjList);

    const timePeriodObjs = {
      [pip.dateRangeDisplayObj]: structuredClone(drdState),
    };

    pieh.onSetTimePeriod(
      timePeriodObjs,
      undefined,
      false,
      modVarObjListClone,
      props.origModVarObjArr,
      onModVarsChange,
      props.onCalculatingChange,
      props.onDialogChange
    );
  };

  return (
    <React.Fragment>
      {!isAggMode && (
        // download button
        <div
          style={{
            marginTop: 30,
            marginBottom: 5,
          }}
        >
          <div
            style={{
              marginBottom: 10,
            }}
          >
            {RS(SC.GB_stDownloadPepfarTargetsInstructions)
              .replace("FY24", `FY${currentFinancialYear % 100}`)
              .replaceAll("2024", currentFinancialYear.toString())
              .replace("2023", (currentFinancialYear - 1).toString())}
          </div>

          <TButton
            caption={RS(SC.GB_stDownloadPepfarTargets)
              .replace("FY24", `FY${currentFinancialYear % 100}`)
              .replace("2024", currentFinancialYear.toString())}
            containerStyle={{
              display: "block",
            }}
            key={"downloadTargOutPEPFARBtn"}
            onClick={() => {
              downloadTargetsOutputPEPFARXLSX(modVarObjList);
            }}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              width: 400,
            }}
            disabled={!includesFinancialYear}
          />
        </div>
      )}

      {/* Dates */}
      <PITimePeriodLab
        {...{
          [pip.timePeriodObj]: targSettingPeriodObj,
          [pip.timePeriodType]: pic.targSetPeriod,
          [PITimePeriodLabProps]: { display: "block" },
        }}
      />

      {isAggMode && (
        <div>
          <PITimePeriodLab
            {...{
              [pip.timePeriodObj]: dateRangeDisplayObj,
              [pip.timePeriodType]: pic.dateRangeDisplayPeriod,
              [PITimePeriodLabProps]: { display: "block" },
            }}
          />
        </div>
      )}

      {!isAggMode && (
        <PITimePeriodDiv
          {...{
            [pip.boundingTimePeriodObj]: piasu.getTargSettingPeriodObj(modVarObjList),

            [pip.onSetPeriod]: onSetPeriod,

            [PITimePeriodDivProps.style]: {
              marginTop: Theme.topIndent,
            },

            [pip.onTimePeriodChange]: onDateRangeDisplayChange,

            [pip.timePeriodCaption]: RS(SC.GB_stDateRangeForDisplay),

            [pip.timePeriodObj]: drdState,
            [pip.timePeriodObjAppState]: dateRangeDisplayObj,

            [pip.timePeriodType]: pic.dateRangeDisplayPeriod,
          }}
        />
      )}

      {/* Method Selection */}
      {methods.length > 1 && (
        <PIMethodComboBox
          {...{
            outerStyle: {
              marginTop: Theme.ctrlSpacing,
            },
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [PIMethodComboBoxProps.row]: false,
          }}
        />
      )}

      {/* PrEP Status Selection */}
      <div
        style={{
          marginTop: 20,
        }}
      >
        <p
          style={{
            ...Theme.labelStyle,
            margin: 0,
            marginRight: Theme.leftIndent,
          }}
        >
          {RS(SC.GB_stDisplay)}
        </p>

        <TComboBox
          onChange={onPrEPStatusComboBoxChange}
          info={[pisc.PrEP_NEW_StatusMstID, pisc.PrEP_CT_StatusMstID]}
          itemIndex={PrEPStatus === pisc.PrEP_NEW_StatusMstID ? 0 : 1}
          items={[RS(SC.GB_stPrEPNEW), RS(SC.GB_stPrEPCT)]}
          outerStyle={{
            marginTop: 10,
            fontFamily: Theme.fontFamily,
          }}
          style={{
            fontFamily: Theme.fontFamily,
          }}
        />
      </div>

      {/* Data Table */}
      <p
        key={"targDisagDistrictPriorPopsLab"}
        style={{
          marginTop: 30,
          padding: 0,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stTargsDisagBy) + " " + area2Name.toLowerCase().trim() + ", " + RS(SC.GB_stByPriorPop)}
      </p>

      {level1Name === "" && (
        <p
          style={{
            display: "block",
            marginTop: 20,
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stSpecifySubnatLevelOneToSeeTable)}
        </p>
      )}

      {!isAggMode && <PITargDisagDistrictPriorPopsResTable modVarObjList={modVarObjList} />}
      {isAggMode && <PITargDisagDistrictPriorPopsAggResTable modVarObjList={modVarObjList} />}
    </React.Fragment>
  );
};

PIDisagTargetsResultContent.propTypes = {
  modVarObjList: PropTypes.arrayOf(PropTypes.object).isRequired,
  origModVarObjArr: PropTypes.arrayOf(PropTypes.object),
  onModVarsChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
  onDialogChange: PropTypes.func,
};

PIDisagTargetsResultContent.defaultProps = {
  origModVarObjArr: [],
  onModVarsChange: () => {},
  onCalculatingChange: () => {},
  onDialogChange: () => {},
};

export default PIDisagTargetsResultContent;
