import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TTabs2 from "../../common/TTabs2";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as php from "../NonComponents/PIHelp"; // TODO work out why this is needed here but not in PIITemsForm
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";

import PIItemsSlideDrawer from "../Drawers/PIItemsSlideDrawer";
import { onCalculate } from "../NonComponents/PICalc";

import { PIContVisitScheduleTable } from "../Tables/PIContVisitScheduleTable";
import PIAssignContVisitsToSchedLiteTable from "../Tables/PIAssignContVisitsToSchedLiteTable";
import PIAssignContSchedToPriorPopLiteTable from "../Tables/PIAssignContSchedToPriorPopLiteTable";

import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";

const defVisitSchedTab = 0;
const firstTab = defVisitSchedTab;
const assignContVisitSchedTab = 1;
const finalTab = assignContVisitSchedTab;

const showContVisitSchedSlideDrawerBoolC = "showContVisitSchedSlideDrawerBool";

class PIVisitSchedForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onSlideDrawerSaveBtnClick]: PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(`PIVisitSchedForm: ${pias.onCalculatingChange}`),

    [pias.helpAreaStr]: php.config_Cont_DefCurvesTB_HP,
    [pias.onHelpAreaChange]: () => console.log(`PIVisitSchedForm: ${pias.onHelpAreaChange}`),
    [pias.onHelp]: () => console.log(`PIVisitSchedForm: ${pias.onHelp}`),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(`PIVisitSchedForm: ${pias.onModVarsChange}`),

    [pias.tableKeyObj]: {},

    [pip.onSlideDrawerSaveBtnClick]: () => console.log(`PIVisitSchedForm: ${pip.onSlideDrawerSaveBtnClick}`),

    [pip.onNextAreaChange]: () => console.log(`PIVisitSchedForm: ${pip.onNextAreaChange}`),
    [pip.onPrevAreaChange]: () => console.log(`PIVisitSchedForm: ${pip.onPrevAreaChange}`),
  };

  state = {
    [pip.selectedTabIdx]: defVisitSchedTab,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case defVisitSchedTab:
        helpAreaStr = php.config_VisitSchedules;
        break;

      case assignContVisitSchedTab:
        helpAreaStr = php.config_VisitSchedules;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    const helpAreaStr = this.getHelpAreaStr(tabIdx);

    onHelpAreaChange(helpAreaStr, () => {
      this.setState({
        [pip.selectedTabIdx]: tabIdx,
      });
    });
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const onSlideDrawerSaveBtnClick = props[pip.onSlideDrawerSaveBtnClick];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    const state = this.state;
    let selectedTabIdx = state[pip.selectedTabIdx];

    let onDrawerChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === firstTab) {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onPrevAreaChange();
          });
        };
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === finalTab) {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onNextAreaChange();
          });
        };
      } else {
        selectedTabIdx++;
      }
    }

    if (typeof onDrawerChangeEvent !== "undefined") {
      onDrawerChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  onContVisitSchedSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const showContVisitSchedSlideDrawerBool = state[showContVisitSchedSlideDrawerBoolC];
      const selectedTabIdx = state[pip.selectedTabIdx];

      const selectedMethodMstID = piasu.getModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag);
      const costCatObjList = piasu.getModVarValue(modVarObjArrClone, pisc.costCategoriesLiteMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);
      const contVisitSchedLiteObjList = piasu.getModVarValue(modVarObjArrClone, pisc.contVisitSchedLiteMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      const fn = (successFn) => {
        let helpAreaStr = "";
        if (!showContVisitSchedSlideDrawerBool) {
          helpAreaStr = php.costsLite_AssContVis_ContVisSchedSD_HP;
        } else {
          helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
        }

        onHelpAreaChange(helpAreaStr, () => {
          this.setState(
            {
              [showContVisitSchedSlideDrawerBoolC]: !showContVisitSchedSlideDrawerBool,
            },
            () => gbu.safeCallFn(successFn)
          );
        });
      };

      /* Before doing anything else, in case all the continuation visit schedules were
               cleared and one was selected, make sure all priority populations are
               assigned something other than NONE for a continuation visit schedule master ID.
               Just assign the master ID of the first continuation visit schedule. */
      const firstContVisitSchedMstID = piasu.getContVisitSchedMstID(contVisitSchedLiteObjList, 1);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const currContVisitSchedMstID = piasu.getPriorPopContVisitSchedMstIDCostsLite(
          selectedMethodMstID,
          costCatObjList,
          pp
        );

        if (currContVisitSchedMstID === pic.noMstID) {
          piasu.setPriorPopContVisitSchedMstIDCostsLite(
            selectedMethodMstID,
            costCatObjList,
            pp,
            firstContVisitSchedMstID
          );
        }
      }

      if (showContVisitSchedSlideDrawerBool) {
        onCalculatingChange(true, () => {
          /* Item objects contain input and result fields. The result fields are only present
                       after calculating.  Call calculate to add them or certain graphs and charts that rely
                       on result fields may break. */
          onCalculate(
            modVarObjArrClone,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                fn(() => {
                  onCalculatingChange(false);
                });
              });
            },
            () => onCalculatingChange(false)
          );
        });
      } else {
        fn();
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderAssignContVisitsTabContents = () => {
    const props = this.props;

    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];

    const helpAreaStr = props[pias.helpAreaStr];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];

    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const tableKeyObj = props[pias.tableKeyObj];

    const state = this.state;
    const showContVisitSchedSlideDrawerBool = state[showContVisitSchedSlideDrawerBoolC];

    const estContVisitSchedLabel = (
      <p
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stEstContVisitSchedules)}
      </p>
    );

    const contVisitSchedTableKey = tableKeyObj[pias.contVisitSchedTableKey];

    const contVisitSchedTable = (
      <PIContVisitScheduleTable
        {...{
          [pias.onCalculatingChange]: onCalculatingChange,
          [pias.onDialogChange]: onDialogChange,

          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.tableKey]: contVisitSchedTableKey,
        }}
      />
    );

    let contVisitSchedSlideDrawer = null;

    if (showContVisitSchedSlideDrawerBool) {
      contVisitSchedSlideDrawer = (
        <PIItemsSlideDrawer
          {...{
            /* State-related */
            [pias.onDialogChange]: onDialogChange,
            [pias.onDrawerChange]: this.onContVisitSchedSlideDrawerClick,

            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelpAreaChange]: onHelpAreaChange,
            [pias.onHelp]: onHelp,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.itemType]: pic.contVisitSchedItems,

            [pias.tableKeyObj]: tableKeyObj,

            /* For top form */

            [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
            [pip.itemDrawerTitleStr]: RS(SC.GB_stContVisitSchedules),
            [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomContVisitScheds),
            [pip.selectItemsStr]: RS(SC.GB_stSelectContVisitScheds),
            [pip.yourItemsStr]: RS(SC.GB_stYourContVisitScheds),

            /* For custom item form */

            [pip.addItemNoteStr]: RS(SC.GB_stAddContVisitSchedNote),
            [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomContVisitScheds),
            [pip.editItemInstructStr]: RS(SC.GB_stEditContVisitSchedInstruct),
            [pip.itemTypeStr]: RS(SC.GB_stContVisitSchedules),
            [pip.removeItemNoteStr]: RS(SC.GB_stRemoveContVisitSchedNote),
            [pip.moveItemNoteStr]: RS(SC.GB_stMoveContVisitSchedUpNote),
            [pip.moveItemDownNoteStr]: RS(SC.GB_stMoveContVisitSchedDownNote),
          }}
        />
      );
    }

    const assignContVisitsLabel = (
      <p
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          marginRight: 20,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stSpecContVisitMonthsAfterInit)}
      </p>
    );

    const assignContVisitsTable = (
      <PIAssignContVisitsToSchedLiteTable
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    return (
      <>
        {estContVisitSchedLabel}
        {contVisitSchedTable}
        {contVisitSchedSlideDrawer}
        {assignContVisitsLabel}
        {assignContVisitsTable}
      </>
    );
  };

  renderAssignContVisitSchedTabContents = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const tableKeyObj = props[pias.tableKeyObj];

    const assignContSchedLabel = (
      <p
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          marginRight: 20,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stAssignContSchedToPriorPop)}
      </p>
    );

    const assignContSchedTable = (
      <>
        <PIAssignContSchedToPriorPopLiteTable
          modVarObjList={modVarObjList}
          onModVarsChange={onModVarsChange}
          tableKey={tableKeyObj[pias.assignContSchedToPriorPopLiteTableKey]}
        />
      </>
    );

    return (
      <>
        {assignContSchedLabel}
        {assignContSchedTable}
      </>
    );
  };

  renderSelectedTabContent = () => {
    const state = this.state;
    const selectedTabIdx = state[pip.selectedTabIdx];

    let assignContVisitsTabContents = null;
    let assignContVisitSchedTabContents = null;

    if (selectedTabIdx === defVisitSchedTab) {
      assignContVisitsTabContents = this.renderAssignContVisitsTabContents();
    } else if (selectedTabIdx === assignContVisitSchedTab) {
      assignContVisitSchedTabContents = this.renderAssignContVisitSchedTabContents();
    }

    return (
      <>
        {assignContVisitsTabContents}
        {assignContVisitSchedTabContents}
      </>
    );
  };

  render = () => {
    const state = this.state;
    const selectedTabIdx = state[pip.selectedTabIdx];

    const tabs = (
      <TTabs2
        onChange={this.onTabIdxChange}
        selectedTabIdx={selectedTabIdx}
        tabBackgroundColor={"inherit"}
        tabBarOutline={"none"}
        tabContents={["", ""]}
        tabTitles={[RS(SC.GB_stDefineVisitSchedules), RS(SC.GB_stAssignContVisitSchedules)]}
      />
    );

    const selectedTabContent = this.renderSelectedTabContent();

    const navBtnDiv = (
      <PINavBtnDiv
        {...{
          [PINavBtnDivProps.divStyle]: { display: "block" },
          [PINavBtnDivProps.showBackBtn]: true,
          [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
          [PINavBtnDivProps.backBtnStyle]: { marginLeft: Theme.leftIndent },
          [PINavBtnDivProps.showNextBtn]: true,
          [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          [PINavBtnDivProps.backBtnKey]: "visitSchedFormBackBtn",
          [PINavBtnDivProps.nextBtnKey]: "visitSchedFormNextBtn",
        }}
      />
    );

    return (
      <>
        {tabs}
        {selectedTabContent}
        {navBtnDiv}
      </>
    );
  };
}

export default PIVisitSchedForm;
