import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import Info from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";

import * as Theme from "../../../app/Theme";

import TDialog from "../../common/TDialog";
import TTabs2 from "../../common/TTabs2";

import * as gbu from "../../GB/GBUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";

import { PIContCurvePercOnPrEPTable } from "../Tables/PIContCurvePercOnPrEPTable";
import { PICustomRowTableProps } from "../Other/PICustomRowsUtil";
import PIAssignContCurvesToPriorPopsTable from "../Tables/PIAssignContCurvesToPriorPopsTable";

import PIPercClientsOnPrEPGraph from "../Graphs/PIPercClientsOnPrEPGraph";
import PIContinuationRefSlideDrawer from "../Drawers/Reference/PIContinuationRefSlideDrawer";
import PIContinuationCalcSlideDrawer from "../Drawers/Reference/PIContinuationCalcSlideDrawer";
import PIItemsSlideDrawer from "../Drawers/PIItemsSlideDrawer";
import * as pisc from "../NonComponents/PIServerConst";
import * as php from "../NonComponents/PIHelp";
import { onCalculate } from "../NonComponents/PICalc";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";

export const defineCurvesTab = 0;
export const assignCurvesTab = 1;
const firstTab = defineCurvesTab;
const finalTab = assignCurvesTab;

const showContCurveDialogC = "showContCurveDialog";
const showContCurveSlideDrawerBoolC = "showContCurveSlideDrawerBool";

class PIContinuationForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.calcContCurvePercOnPrEPBool]: PropTypes.bool,
    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),

    [pias.onModVarsChange]: PropTypes.func,

    [pip.showRefSlideDrawer]: PropTypes.bool,
    [pip.onToggleRefSlideDrawer]: PropTypes.func,

    [pip.showCalcSlideDrawer]: PropTypes.bool,
    [pip.onToggleCalcSlideDrawer]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onSlideDrawerSaveBtnClick]: PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,

    activeTabIdx: PropTypes.number,
    onActiveTabIdxChange: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.calcContCurvePercOnPrEPBool]: false,
    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(pias.onCalcContCurvePercOnPrEPChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.config_Cont_DefCurvesTB_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],

    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.showRefSlideDrawer]: false,
    [pip.onToggleRefSlideDrawer]: () => console.log(pip.onToggleRefSlideDrawer),

    [pip.showCalcSlideDrawer]: false,
    [pip.onToggleCalcSlideDrawer]: () => console.log(pip.onToggleCalcSlideDrawer),

    [pias.tableKeyObj]: {},

    [pip.onSlideDrawerSaveBtnClick]: () => console.log(pip.onSlideDrawerSaveBtnClick),

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),

    activeTabIdx: 0,
    onActiveTabIdxChange: () => console.log("PIContinuationForm: onActiveTabIdxChange")
  };

  state = {
    [showContCurveDialogC]: false,
    [showContCurveSlideDrawerBoolC]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case defineCurvesTab:
        helpAreaStr = php.config_Cont_DefCurvesTB_HP;
        break;

      case assignCurvesTab:
        helpAreaStr = php.config_Cont_AssCurvesTB_HP;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    const helpAreaStr = this.getHelpAreaStr(tabIdx);

    onHelpAreaChange(helpAreaStr, () => {
      this.props.onActiveTabIdxChange(tabIdx)      
    });
  };

  onContCurveInfoClick = () => {
    this.setState({
      [showContCurveDialogC]: true,
    });
  };

  onContCurveDialogClose = () => {
    this.setState({
      [showContCurveDialogC]: false,
    });
  };

  onContCurveSlideDrawerClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const state = this.state;
    const showContCurveSlideDrawerBool = state[showContCurveSlideDrawerBoolC];

    const fn = () => {
      let helpAreaStr = "";
      if (showContCurveSlideDrawerBool) {
        helpAreaStr = php.config_Cont_ContCurvesSD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(props.activeTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [showContCurveSlideDrawerBoolC]: !showContCurveSlideDrawerBool,
        });
      });
    };

    /* Before doing anything else, in case all the continuation visit schedules were
           cleared and one was selected, make sure all priority populations are
           assigned something other than NONE for a continuation curve master ID.
           Just assign the master ID of the first continuation curve. */
    const methodObjList = piasu.getModVarValue(modVarObjArrClone, pisc.methodsMVTag);
    const numMethods = piasu.getTotalNumMethods(methodObjList);

    const priorPopObjList = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);
    const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

    const contCurveObjList = piasu.getModVarValue(modVarObjArrClone, pisc.continuationCurvesMVTag);
    const firstContCurveMstID = piasu.getContCurveMstID(contCurveObjList, 1);

    for (let pp = 1; pp <= numPriorPops; pp++) {
      for (let m = 1; m <= numMethods; m++) {
        const currContCurveMstID = piasu.getPriorPopContCurveMstID(priorPopObjList, m, pp);

        if (currContCurveMstID === pic.noMstID) {
          piasu.setPriorPopContCurveMstID(priorPopObjList, m, pp, firstContCurveMstID);
        }
      }
    }

    if (showContCurveSlideDrawerBool) {
      onCalculatingChange(true, () => {
        /* Item objects contain input and result fields. The result fields are only present
                   after calculating.  Call calculate to add them or certain graphs and charts that rely
                   on result fields may break. */
        onCalculate(
          modVarObjArrClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false, () => {
                fn();
              });
            });
          },
          () => onCalculatingChange(false)
        );
      });
    } else {
      fn();
    }
  };

  onRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const showRefSlideDrawer = props[pip.showRefSlideDrawer];
      const onToggleRefSlideDrawer = props[pip.onToggleRefSlideDrawer];

      let helpAreaStr = "";
      if (showRefSlideDrawer) {
        helpAreaStr = php.config_Cont_RefRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(props.activeTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        onToggleRefSlideDrawer();
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onCalcSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const showCalcSlideDrawer = props[pip.showCalcSlideDrawer];
      const onToggleCalcSlideDrawer = props[pip.onToggleCalcSlideDrawer];

      let helpAreaStr = "";
      if (showCalcSlideDrawer) {
        helpAreaStr = php.config_Cont_CalcRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(props.activeTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        onToggleCalcSlideDrawer();
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const onSlideDrawerSaveBtnClick = props[pip.onSlideDrawerSaveBtnClick];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    let selectedTabIdx = props.activeTabIdx

    let onDrawerChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === finalTab) {
        selectedTabIdx--;
      } else {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onPrevAreaChange();
          });
        };
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === firstTab) {
        selectedTabIdx++;
      } else {
        onDrawerChangeEvent = () => {
          onSlideDrawerSaveBtnClick(() => {
            onNextAreaChange();
          });
        };
      }
    }

    if (typeof onDrawerChangeEvent !== "undefined") {
      onDrawerChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderEditorComponents = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const calcContCurvePercOnPrEPBool = props[pias.calcContCurvePercOnPrEPBool];
    const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
    const helpAreaStr = props[pias.helpAreaStr];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    const modVarObjList = props[pias.modVarObjList];
    const origModVarObjArr = props[pias.origModVarObjArr];
    const onModVarsChange = props[pias.onModVarsChange];
    const showRefSlideDrawer = props[pip.showRefSlideDrawer];
    const showCalcSlideDrawer = props[pip.showCalcSlideDrawer];
    const contCurvePercOnPrEPTableKey = props[pias.tableKeyObj][pias.contCurvePercOnPrEPTableKey];
    const assignContCurvesToPriorPopsTableKey = props[pias.tableKeyObj][pias.assignContCurvesToPriorPopsTableKey];

    const state = this.state;
    const selectedTabIdx = props.activeTabIdx
    const showContCurveSlideDrawerBool = state[showContCurveSlideDrawerBoolC];

    const contCurveObjList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

    let estContCurvesLab = null;
    let contCurveBtn = null;
    let contCurveSlideDrawer = null;
    let contCurvTableLabInfoDiv = null;
    let defineCurvesTable = null;

    let assignCurveLab = null;
    let assignCurvesTable = null;

    if (selectedTabIdx === defineCurvesTab) {
      if (showContCurveSlideDrawerBool) {
        contCurveSlideDrawer = (
          <PIItemsSlideDrawer
            {...{
              /* State-related */

              [pias.onDialogChange]: onDialogChange,

              [pias.onDrawerChange]: this.onContCurveSlideDrawerClick,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.itemType]: pic.contCurveItems,

              [pias.tableKeyObj]: props[pias.tableKeyObj],

              /* For top form */

              [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
              [pip.itemDrawerTitleStr]: RS(SC.GB_stContCurves),
              [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomContCurves),
              [pip.selectItemsStr]: RS(SC.GB_stSelectContCurves),
              [pip.yourItemsStr]: RS(SC.GB_stYourContCurves),

              /* For custom item form */

              [pip.addItemNoteStr]: RS(SC.GB_stAddContCurveNote),
              [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomContCurves),
              [pip.editItemInstructStr]: RS(SC.GB_stEditContCurveInstruct),
              [pip.itemTypeStr]: RS(SC.GB_stContCurves),
              [pip.removeItemNoteStr]: RS(SC.GB_stRemoveContCurveNote),
              [pip.moveItemNoteStr]: RS(SC.GB_stMoveContCurveUpNote),
              [pip.moveItemDownNoteStr]: RS(SC.GB_stMoveContCurveDownNote),
            }}
          />
        );
      }

      const contCurveTableLab = (
        <p
          key={"contCurvTableLab"}
          style={{
            display: "inline-block",
            marginBottom: 0,
            marginLeft: Theme.leftIndent,
            marginTop: 0,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stContCurveExplan)}
        </p>
      );

      const contCurveInfoBtn = (
        <IconButton
          onClick={this.onContCurveInfoClick}
          style={{
            color: Theme.PI_SecondaryColor,
            cursor: "pointer",
            display: "inline-block",
            marginLeft: 0,
            marginRight: 20,
            padding: 0,
          }}
        >
          <Info
            style={{
              color: Theme.PI_SecondaryColor,
            }}
          />
        </IconButton>
      );

      contCurvTableLabInfoDiv = (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: Theme.topIndent,
          }}
        >
          {contCurveTableLab}

          <div
            style={{
              marginLeft: 10,
            }}
          >
            {contCurveInfoBtn}
          </div>
        </div>
      );

      defineCurvesTable = (
        <PIContCurvePercOnPrEPTable
          {...{
            [PICustomRowTableProps.allowEditsBoolC]: true,

            [pias.onCalculatingChange]: onCalculatingChange,

            [pias.onDialogChange]: onDialogChange,

            [pias.calcContCurvePercOnPrEPBool]: calcContCurvePercOnPrEPBool,
            [pias.onCalcContCurvePercOnPrEPChange]: onCalcContCurvePercOnPrEPChange,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,
            [pias.origModVarObjArr]: origModVarObjArr,

            [pip.tableKey]: contCurvePercOnPrEPTableKey,
          }}
        />
      );
    } else if (selectedTabIdx === assignCurvesTab) {
      assignCurveLab = (
        <p
          key={"assignCurveLab"}
          style={{
            marginLeft: Theme.leftIndent,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAssignContCurves)}
        </p>
      );

      assignCurvesTable = (
        <PIAssignContCurvesToPriorPopsTable
          modVarObjList={modVarObjList}
          onModVarsChange={onModVarsChange}
          onCalculatingChange={onCalculatingChange}
          onDialogChange={onDialogChange}
          tableKey={assignContCurvesToPriorPopsTableKey}
        />
      );
    }

    const tabs = (
      <TTabs2
        onChange={this.onTabIdxChange}
        selectedTabIdx={selectedTabIdx}
        tabBackgroundColor={"inherit"}
        tabBarOutline={"none"}
        tabContents={["", ""]}
        tabTitles={[RS(SC.GB_stDefineCurves), RS(SC.GB_stAssignCurves)]}
      />
    );

    const percClientsPrEPChart = (
      <PIPercClientsOnPrEPGraph
        {...{
          [pip.contCurveObjList]: contCurveObjList,
        }}
      />
    );

    const navBtnDiv = (
      <PINavBtnDiv
        {...{
          [PINavBtnDivProps.showBackBtn]: true,
          [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
          [PINavBtnDivProps.backBtnKey]: "contCurveFormBackBtn",
          [PINavBtnDivProps.nextBtnKey]: "contCurveFormNextBtn",
          [PINavBtnDivProps.backBtnStyle]: { marginLeft: Theme.leftIndent },
          [PINavBtnDivProps.showNextBtn]: true,
          [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
        }}
      />
    );

    let continuationRefSlideDrawerComp = null;

    if (showRefSlideDrawer) {
      continuationRefSlideDrawerComp = (
        <PIContinuationRefSlideDrawer
          {...{
            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelp]: onHelp,

            [pip.onToggleRefSlideDrawer]: this.onRefSlideDrawerClick,
          }}
        />
      );
    }

    let continuationCalcSlideDrawerComp = null;

    if (showCalcSlideDrawer) {
      continuationCalcSlideDrawerComp = (
        <PIContinuationCalcSlideDrawer
          {...{
            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelp]: onHelp,

            [pip.onToggleCalcSlideDrawer]: this.onCalcSlideDrawerClick,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {tabs}
        {estContCurvesLab}
        {contCurveBtn}
        {contCurveSlideDrawer}
        {contCurvTableLabInfoDiv}
        {defineCurvesTable}
        {assignCurveLab}
        {assignCurvesTable}
        {navBtnDiv}
        {percClientsPrEPChart}
        {continuationRefSlideDrawerComp}
        {continuationCalcSlideDrawerComp}
      </React.Fragment>
    );
  };

  renderContCurveDialog = () => {
    let contCurveDialog = null;

    /* State */

    const state = this.state;
    const showContCurveDialog = state[showContCurveDialogC];

    if (showContCurveDialog) {
      const contCurveHeader = (
        <p
          key={"contCurveHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stContCurves)}
        </p>
      );

      const contCurveText = (
        <p
          key={"contCurveText"}
          style={{
            ...Theme.textFontStyle,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stContCurveExplanation1)}
          <br></br>
          <br></br>
          {RS(SC.GB_stContCurveExplanation2)}
        </p>
      );

      contCurveDialog = (
        <TDialog
          actions={["mrClose"]}
          content={[contCurveText]}
          header={[contCurveHeader]}
          key={"contCurveDialog"}
          onClose={this.onContCurveDialogClose}
        />
      );
    }

    return contCurveDialog;
  };

  renderDialogComponents = () => {
    const contCurveDialog = this.renderContCurveDialog();

    return <React.Fragment>{contCurveDialog}</React.Fragment>;
  };

  render() {
    const editorComponents = this.renderEditorComponents();
    const dialogComponents = this.renderDialogComponents();

    return (
      <React.Fragment>
        {editorComponents}
        {dialogComponents}
      </React.Fragment>
    );
  }
}

export default PIContinuationForm;
