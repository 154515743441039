import preval from "preval.macro";
import { v4 as uuidv4 } from "uuid";

const configure = () => {
  window.DebugMode = Boolean(process.env.REACT_APP_DEBUG_MODE);

  window.API_URL_BASE = process.env.REACT_APP_API_URL_BASE?.replace(/\/$/, "");
  window.API_URL = window.API_URL_BASE + "/JSON";
  window.DISABLE_PSE_MODE =
    process.env.REACT_APP_DISABLE_PSE_MODE === "true" || parseInt(process.env.REACT_APP_DISABLE_PSE_MODE) === 1;

  window.HIDE_PREP_RECIPIENTS =
    process.env.REACT_APP_HIDE_PREP_RECIPIENTS === "true" || parseInt(process.env.REACT_APP_HIDE_PREP_RECIPIENTS) === 1;

  // Enable gzip Content-Type encoding for select calls to python FastAPI backend
  window.ENABLE_REQUEST_GZIP =
    process.env.REACT_APP_ENABLE_REQUEST_GZIP === "true" || parseInt(process.env.REACT_APP_ENABLE_REQUEST_GZIP) === 1;
  /* For REST calls, we always want a new GUID every time the site is visited. */
  window.CLIENT_ID = "{" + uuidv4() + "}";

  window.USE_OLD_CALCULATE =
    process.env.REACT_APP_USE_OLD_CALCULATE === "true" || parseInt(process.env.REACT_APP_USE_OLD_CALCULATE) === 1;

  const build_timestamp = preval`module.exports = new Date().toUTCString();`;
  console.log(`Build Timestamp: ${build_timestamp}`);
};

export default configure;
