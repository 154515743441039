import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbu from "../../GB/GBUtil";
import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as pitu from "../NonComponents/PITableUtil";
import * as pip from "../NonComponents/PIProps";

const PrEPEffRow = 1;
const effectiveUseRow = 2;
const annDiscRateRow = 3;
const numRows = annDiscRateRow + 1;

class PIPrEPEfficacyAGYWTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
    [pip.tableKey]: "",
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = window.structuredCloneReview(newPackTable);

      piasu.setModVarValue(modVarObjListClone, pisc.efficacyMVTag, gbtu.getValue(newPackTableClone, 1, 1) / 100);
      piasu.setModVarValue(modVarObjListClone, pisc.effectiveUseMVTag, gbtu.getValue(newPackTableClone, 2, 1) / 100);
      // piasu.setModVarValue(modVarObjListClone, pisc.annualDiscountRateMVTag,
      //    gbtu.getValue(newPackTableClone, 3, 1) / 100);

      onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onComboBoxChange = (val) => {
    const props = this.props;
    let modVarObjListClone = structuredClone(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    piasu.setModVarValue(modVarObjListClone, pisc.annualDiscountRateMVTag, val / 100);

    onModVarsChange(modVarObjListClone, false);
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      //const salary1DFltArray = piasu.getModVarValue(modVarObjList, pisc.salariesMVTag);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, 2);

      gbtu.setValue(packTable, PrEPEffRow, 0, RS(SC.GB_stPrEPEfficacy) + " (%)");
      gbtu.setValue(packTable, effectiveUseRow, 0, RS(SC.GB_stEffectiveUse) + " (%)");
      gbtu.setValue(packTable, annDiscRateRow, 0, RS(SC.GB_stAnnualDiscountRate) + " (%)");

      const efficacyValue = piasu.getModVarValue(modVarObjList, pisc.efficacyMVTag) * 100;
      gbtu.setValue(packTable, PrEPEffRow, 1, efficacyValue);
      const effectiveUseValue = piasu.getModVarValue(modVarObjList, pisc.effectiveUseMVTag) * 100;
      gbtu.setValue(packTable, effectiveUseRow, 1, effectiveUseValue);
      //gbtu.setValue(packTable, annDiscRateRow, 1, piasu.getModVarValue(modVarObjList, pisc.annualDiscountRateMVTag) * 100);

      gbtu.setHasComboBox(packTable, annDiscRateRow, 1, true);

      let comboBoxObj = pitu.getComboBoxObj(numRows, 2);

      comboBoxObj[pitu.style2DObjArray][annDiscRateRow][1] = {
        color: Theme.blackColor,
        fontFamily: Theme.fontFamily,
        width: Theme.dataColWidthSmall - 10,
        textAlign: "right",
      };

      let info1DIntArray = [];
      info1DIntArray.length = 1;
      info1DIntArray.fill(1);

      comboBoxObj[pitu.info3DIntArray][annDiscRateRow][1] = info1DIntArray;

      comboBoxObj[pitu.item3DStrArray][annDiscRateRow][1] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

      comboBoxObj[pitu.idx2DIntArray][annDiscRateRow][1] = Math.floor(
        piasu.getModVarValue(modVarObjList, pisc.annualDiscountRateMVTag) * 100
      );

      comboBoxObj[pitu.onChange2DFuncArray][annDiscRateRow][1] = (val) => this.onComboBoxChange(val);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRDecs(packTable, 0);
      gbtu.setColWidth(packTable, 0, 200);
      gbtu.setColWidth(packTable, 1, Theme.dataColWidthSmall);

      gbtu.setMinAllowedValByCol(packTable, 1, 0);
      gbtu.setMaxAllowedValByCol(packTable, 1, 100);
      gbtu.setID(packTable, "packTable");
      gbtu.setKey(packTable, "packTable");

      if (window.DebugMode) {
        console.log("Component: PIPrEPEfficacyAGYWTable");
        console.log("ModVar(s):");
        console.log(pisc.efficacyMVTag);
        console.log(efficacyValue);
        console.log(pisc.effectiveUseMVTag);
        console.log(effectiveUseValue);
        console.log("");
      }

      const stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={this.state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"packTable"}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={[
            ["s", "s"],
            ["s", "n"],
            ["s", "n"],
            ["s", "dd"],
          ]}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIPrEPEfficacyAGYWTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIPrEPEfficacyAGYWTable;
