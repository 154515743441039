//@ts-check
import * as piasu from "../NonComponents/PIAppStateUtil";

import { pseIncidenceCategories, pseBasePops } from "./PIUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

// WARNING: This currently only works with PSE data

/**
 * @typedef {object} CombinedPop
 * @property {function(): string} newPopName
 * @property {string[]} originalPopIDs
 */

/**
 * @typedef {Object.<string, CombinedPop>} PopCombineInfo
 */

/**
 * @typedef {{(oldModVar: any, newModVar: any, oldIndex: number, newIndex: number, pop: Object, combineInfo: CombinedPop): void }} CombineModVarFn
 * @typedef {{(newModVar: any, newIndex: number, pop: Object, combineInfo: CombinedPop): void }} PostProcessModVarFn
 *
 * @typedef {object} CombineModVar
 * @property {CombineModVarFn} combineFn Combine function
 * @property {function(): any} defaultValue Function to return default value
 * @property {PostProcessModVarFn} [postProcessFn] Optional post processing function
 *
 * @typedef {Object.<string, CombineModVar>} CombineModVars
 */

/**
 * @typedef {object} CombinedPopResult
 * @property {Object[]} PI_PriorityPop New priority pops
 * @property {number[]} indexMap Mapping of new pop index to old pop index
 * @property {PopCombineInfo} combineInfo Combine info used to combine the pops
 */

///////////////////////////////////////////////////////////////////////////////
// Mod Var Combine Data
///////////////////////////////////////////////////////////////////////////////

/** @type {CombineModVars} */
const combineModVars = {
  PI_DistrictPopulations: {
    combineFn: combineDistrictPops,
    defaultValue: () => [],
  },
  PI_MethodMix: {
    combineFn: combineMethodMix,
    defaultValue: () => ({}),
  },
  PI_MethodMixCoverage: {
    combineFn: combineMethodMix,
    defaultValue: () => ({}),
  },
  PI_AdjustmentFactor: {
    combineFn: (oldModVar, newModVar, oldIndex, newIndex, pop, combineInfo) =>
      combineMethodPriorPopArray(oldModVar, newModVar, oldIndex, newIndex, pop, combineInfo, false),
    defaultValue: () => [],
  },
  PI_PotentialUsers: {
    combineFn: combinePriorPopArray,
    defaultValue: () => [],
  },
  PI_ProposedCoverage: {
    combineFn: combineActualCoverage,
    postProcessFn: postProcessActualCoverage,
    defaultValue: () => [],
  },
  PI_ActualCoverage: {
    combineFn: combineActualCoverage,
    postProcessFn: postProcessActualCoverage,
    defaultValue: () => [],
  },
  PI_Coverage: {
    combineFn: combineMethodPriorPopArray,
    postProcessFn: postProcessMethodPopDateArray,
    defaultValue: () => [],
  },
  PI_TargetIndicators: {
    combineFn: combineTargetIndicators,
    defaultValue: () => ({}),
  },
  PI_TargetsByDistrictPrEP_NEW: {
    combineFn: combineMethodDistrictPriorPopArray,
    defaultValue: () => [],
  },
  PI_TargetsByDistrictPrEP_CT: {
    combineFn: combineMethodDistrictPriorPopArray,
    defaultValue: () => [],
  },
  // IMPORTANT: When merging PI_CostStayOnByPT, the continuation curve PopCombineInfo must be used otherwise the results will be wrong
  PI_CostStayOnByPT: {
    combineFn: combineMethodPriorPopArray,
    postProcessFn: postProcessCostStayOnByPTMethodPopArray,
    defaultValue: () => [],
  },
  default: {
    combineFn: combineMethodPriorPopArray,
    defaultValue: () => [],
  },
};

// Sort ordering:
//   Females before males
//   Younger ages before older ages
//   Gen pop before key pops

/**
 * Sort function for pop IDs that should follow the following order:
 *    Females before males
 *    Younger ages before older ages
 *    Gen pop before key pops
 *
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
export function sortPopFn(a, b) {
  const aParts = a.split("_").slice(1);
  const bParts = b.split("_").slice(1);

  // Conveniently, with the current pop IDs, this is all that is needed to
  // meet the sort ordering. This may need to be updated if the pop IDs change.

  if (aParts[1] !== bParts[1]) return aParts[1]?.localeCompare(bParts[1]) ?? 1;

  return aParts[0].localeCompare(bParts[0]);
}

///////////////////////////////////////////////////////////////////////////////
// New Combine Pops
///////////////////////////////////////////////////////////////////////////////

/**
 * Combine priority pops into groups
 *
 * @param {any} modVars Original modvars
 * @param {PopCombineInfo} popsToCombine Mapping of new pop ID to list of original pop IDs
 * @param {string[]} modVarsToCombine
 * @returns {CombinedPopResult} New mod vars with combined pops
 */
export function combinePriorPops(modVars, popsToCombine, modVarsToCombine = []) {
  const combineRevMap = Object.entries(popsToCombine).reduce((acc, [newPopID, info]) => {
    for (const id of info.originalPopIDs) {
      acc[id] = newPopID;
    }
    return acc;
  }, {});

  /** @type {CombinedPopResult} */
  const ret = {
    PI_PriorityPop: [],
    indexMap: [],
    combineInfo: popsToCombine,
  };

  /** @type {Object[]} */
  const priorPops = piasu.getModVarValue(modVars, "PI_PriorityPop");
  const additionalModVars = Object.fromEntries(modVarsToCombine.map((mv) => [mv, piasu.getModVarValue(modVars, mv)]));
  const haveAnyImpactModVar = ImpactModVars.reduce((acc, mv) => acc || mv in additionalModVars, false);

  // Reserve sorted pop list
  // const sortedPops = Object.keys(popsToCombine).sort(sortPopFn);

  // This ensures that any combined pops that have no original pops are excluded
  const sortedPops = Object.entries(popsToCombine)
    .filter(([newPopID, info]) => priorPops.filter((p) => info.originalPopIDs.includes(p.mstID)).length > 0)
    .map(([newPopID, info]) => newPopID)
    .sort(sortPopFn);

  /** @type {Map<string, number>} */
  const processed = new Map();

  const newPops = sortedPops.map((newPopID, popIdx) => {
    const info = popsToCombine[newPopID];
    const originalPops = priorPops.filter((p) => info.originalPopIDs.includes(p.mstID));
    // if (originalPops.length === 0) return undefined;

    const newPop = {
      ...structuredClone(originalPops[0]),
      mstID: newPopID,
      name: info.newPopName(),
      potential_users_pse: originalPops.reduce((acc, p) => acc + p.potential_users_pse, 0),
      originalPops: originalPops.map((p) => p.mstID),
    };

    processed.set(newPopID, popIdx);

    return newPop;
  });

  ret.PI_PriorityPop = newPops;

  // For each additional modvar, combine all its pops
  for (const mv in additionalModVars) {
    const combineInfo = combineModVars[mv] ?? combineModVars.default;
    if (!(mv in ret)) ret[mv] = combineInfo.defaultValue();

    for (let pp = 0; pp < priorPops.length; ++pp) {
      const oldPop = priorPops[pp];
      const newPopID = combineRevMap[oldPop.mstID] ?? oldPop.mstID;
      const newPopIdx = processed.get(newPopID);
      if (newPopIdx === undefined) continue;

      combineInfo.combineFn(
        additionalModVars[mv],
        ret[mv],
        pp,
        newPopIdx,
        ret.PI_PriorityPop[newPopIdx],
        popsToCombine[newPopID]
      );
    }

    // Post process after all pops have been combined
    if (combineInfo.postProcessFn && mv in ret) {
      for (let pp = 0; pp < ret.PI_PriorityPop.length; ++pp) {
        combineInfo.postProcessFn(ret[mv], pp, ret.PI_PriorityPop[pp], popsToCombine[ret.PI_PriorityPop[pp].mstID]);
      }
    }
  }

  if (haveAnyImpactModVar) {
    // Re-calculate impact mod vars if needed
    const impactMVs = Object.fromEntries(
      [...ImpactModVars, "PI_ImpactPSE", "PI_ImpactEffectiveness"].map((mv) => [mv, piasu.getModVarValue(modVars, mv)])
    );
    impactMVs["PI_AdjustmentFactor"] = ret["PI_AdjustmentFactor"];

    Object.assign(ret, recalculateImpactModVars(ret.PI_PriorityPop, impactMVs, popsToCombine));
  }

  return ret;
}

///////////////////////////////////////////////////////////////////////////////
// Combine Pops
///////////////////////////////////////////////////////////////////////////////

/**
 * Combine an array of methods containing arrays of pops
 *
 * @param {any[]} oldData Old modvar data
 * @param {any[]} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 * @param {boolean} [addValues=true] Add numeric values if true
 */
function combineMethodPriorPopArray(oldData, newData, oldIdx, newIdx, pop, combineInfo, addValues = true) {
  for (let m = 0; m < oldData.length; ++m) {
    const method = oldData[m];
    if (Array.isArray(method)) {
      // Plain array
      if (newData[m] == null) newData[m] = [];
      combinePriorPopArray(method, newData[m], oldIdx, newIdx, pop, combineInfo, addValues);
    } else if (typeof method === "object") {
      // Object with pop array in a field
      const key = ["value", "categories", "factors", "target"].find((k) => k in method);
      if (key == null) throw new Error(`Could not determine value key when trying to combine pops`);

      if (newData[m] == null) {
        newData[m] = structuredClone(oldData[m]);
        newData[m][key] = [];
      }

      combinePriorPopArray(method[key], newData[m][key], oldIdx, newIdx, pop, combineInfo, addValues);
    } else throw new Error(`Unable to determine population data type when trying to combine pops`);
  }
}

/**
 * Combine an array of methods containing arrays districts containing arrays of pops
 *
 * @param {any[]} oldData Old modvar data
 * @param {any[]} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 * @param {boolean} [addValues=true] Add numeric values if true
 */
function combineMethodDistrictPriorPopArray(oldData, newData, oldIdx, newIdx, pop, combineInfo, addValues = true) {
  for (let m = 0; m < oldData.length; ++m) {
    const method = oldData[m];
    if (!Array.isArray(method)) throw new Error(`Expected array of districts`);
    if (method.length > 0 && !Array.isArray(method[0])) throw new Error(`Expected array of pops`);

    if (newData[m] == null) newData[m] = [];

    // This will be another array iteration for "methods" so we can just call it for the rest of the merge
    combineMethodPriorPopArray(method, newData[m], oldIdx, newIdx, pop, combineInfo, addValues);
  }
}

/**
 * Combine an array of pops
 *
 * @param {any[]} oldData Old modvar data
 * @param {any[]} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 * @param {boolean} [addValues=true] Add numeric values if true
 */
function combinePriorPopArray(oldData, newData, oldIdx, newIdx, pop, combineInfo, addValues = true) {
  let newPop = structuredClone(oldData[oldIdx]);

  if (Array.isArray(newPop)) {
    if (typeof newPop[0] !== "number") throw new Error(`Expected array of numbers`);

    // Array of numbers probably by date
    if (newData[newIdx]) {
      newPop = newPop.map((v, idx) => v + (newData[newIdx][idx] ?? 0));
    }
  }
  if (typeof newPop === "object") {
    // Update ID if there is one
    if (newPop.mstID) newPop.mstID = pop.mstID;

    // FIXME: Deal with adding up values, mod var dependant
  } else if (typeof newPop === "number") {
    // Sum all pops
    if (addValues) newPop += newData[newIdx] ?? 0;
  }

  newData[newIdx] = newPop;
}

/**
 * Combine PI_DistrictPopulations modvar
 *
 * @param {any[]} oldData Old modvar data
 * @param {any[]} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function combineDistrictPops(oldData, newData, oldIdx, newIdx, pop, combineInfo) {
  for (let dp = 0; dp < oldData.length; ++dp) {
    if (newData[dp] == null) {
      newData[dp] = structuredClone(oldData[dp]);
      newData[dp].priorityPopulations = [];
    }

    const old = oldData[dp].priorityPopulations[oldIdx];
    newData[dp].priorityPopulations[newIdx] = [pop.mstID, old[1]];
  }
}

/**
 * Combine PI_MethodMix modvar
 *
 * @param {any[]} oldData Old modvar data
 * @param {any[]} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function combineMethodMix(oldData, newData, oldIdx, newIdx, pop, combineInfo) {
  if (pop.mstID in newData) return;
  newData[pop.mstID] = structuredClone(oldData[combineInfo?.originalPopIDs[0] ?? pop.mstID]);
}

/**
 * Combine PI_ProposedCoverage and PI_ActualCoverage modvars
 *
 * @param {any[]} oldData Old modvar data
 * @param {any[]} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function combineActualCoverage(oldData, newData, oldIdx, newIdx, pop, combineInfo) {
  if (oldData.length === 0) return;

  const haveDRD = "coverageDRD" in oldData[0];
  const haveTSP = "coverageTSP" in oldData[0];

  for (let m = 0; m < oldData.length; ++m) {
    const method = oldData[m];

    if (newData[m] == null) {
      newData[m] = structuredClone(oldData[m]);
      newData[m].coverage = [];
      if (haveDRD) newData[m].coverageDRD = [];
      if (haveTSP) newData[m].coverageTSP = [];
    }

    combinePriorPopArray(method.coverage, newData[m].coverage, oldIdx, newIdx, pop, combineInfo, true);
    if (haveDRD)
      combinePriorPopArray(method.coverageDRD, newData[m].coverageDRD, oldIdx, newIdx, pop, combineInfo, true);
    if (haveTSP)
      combinePriorPopArray(method.coverageTSP, newData[m].coverageTSP, oldIdx, newIdx, pop, combineInfo, true);
  }
}

/**
 * Post-process PI_ProposedCoverage and PI_ActualCoverage modvars
 *
 * This may not be particularly obvious. As the coverage values are percentages, adding them
 * does not work.
 *
 * The previous step of combining will sum all the coverage values for the original pops.
 * This step divides by the number of original pops to get the percentage coverage for the combined pop.
 *
 * @param {any} newModVar New modvar data
 * @param {number} newIndex New pop index
 * @param {Object} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function postProcessActualCoverage(newModVar, newIndex, pop, combineInfo) {
  /**
   * @param {any} newModVar New modvar data
   * @param {number} newIndex New pop index
   * @param {Object} pop Priority pop (new combined one)
   * @param {CombinedPop} combineInfo Priority pop data
   */
  function postProcessPriorPopArray(newModVar, newIndex, pop, combineInfo) {
    newModVar[newIndex] = newModVar[newIndex] / pop.originalPops.length;
  }

  const haveDRD = "coverageDRD" in newModVar[0];
  const haveTSP = "coverageTSP" in newModVar[0];

  for (let m = 0; m < newModVar.length; ++m) {
    const method = newModVar[m];

    postProcessPriorPopArray(method.coverage, newIndex, pop, combineInfo);
    if (haveDRD) postProcessPriorPopArray(method.coverageDRD, newIndex, pop, combineInfo);
    if (haveTSP) postProcessPriorPopArray(method.coverageTSP, newIndex, pop, combineInfo);
  }
}

/**
 * Post-process for PI_Coverage modvars
 *
 * This may not be particularly obvious. As the coverage values are percentages, adding them
 * does not work.
 *
 * The previous step of combining will sum all the coverage values for the original pops.
 * This step divides by the number of original pops to get the percentage coverage for the combined pop.
 *
 * @param {number[][][]} newModVar New modvar data
 * @param {number} newIndex New pop index
 * @param {Object} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function postProcessMethodPopDateArray(newModVar, newIndex, pop, combineInfo) {
  /**
   * @param {number[][]} newModVar New modvar data
   * @param {number} newIndex New pop index
   * @param {Object} pop Priority pop (new combined one)
   * @param {CombinedPop} combineInfo Priority pop data
   */
  function postProcessPriorPopArray(newModVar, newIndex, pop, combineInfo) {
    newModVar[newIndex] = newModVar[newIndex].map((v) => v / pop.originalPops.length);
  }

  for (let m = 0; m < newModVar.length; ++m) {
    const method = newModVar[m];

    postProcessPriorPopArray(method, newIndex, pop, combineInfo);
  }
}

/**
 * Post-process for PI_CostStayOnByPT modvar
 *
 * This requires that the same combined pops are used as the continuation curves otherwise
 * the results will be incorrect.
 *
 * @param {number[][]} newModVar New modvar data
 * @param {number} newIndex New pop index
 * @param {Object} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function postProcessCostStayOnByPTMethodPopArray(newModVar, newIndex, pop, combineInfo) {
  for (const method of newModVar) {
    method[newIndex] /= pop.originalPops?.length ?? 1;
  }
}

/**
 * Combine a modvar that is an object with various fields containing arrays of pops
 *
 * @param {string[]} fields Fields to combine
 * @param {any[]} oldData Old modvar data
 * @param {any[]} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function combineObjectWithPopArrays(fields, oldData, newData, oldIdx, newIdx, pop, combineInfo) {
  for (const field of fields) {
    if (newData[field] == null) {
      newData[field] = [];
    }

    combineMethodPriorPopArray(oldData[field], newData[field], oldIdx, newIdx, pop, combineInfo);
  }
}

/**
 * Combine PI_TargetIndicators modvar
 *
 * @param {any} oldData Old modvar data
 * @param {any} newData New modvar data
 * @param {number} oldIdx Old pop index
 * @param {number} newIdx New pop index
 * @param {any} pop Priority pop (new combined one)
 * @param {CombinedPop} combineInfo Priority pop data
 */
function combineTargetIndicators(oldData, newData, oldIdx, newIdx, pop, combineInfo) {
  if (newData.methods == null) {
    newData.methods = structuredClone(oldData.methods);
  }

  combineObjectWithPopArrays(
    Object.keys(oldData).filter((v) => v !== "methods"),
    oldData,
    newData,
    oldIdx,
    newIdx,
    pop,
    combineInfo
  );
}

///////////////////////////////////////////////////////////////////////////////
// Combine Impact Mod Vars
///////////////////////////////////////////////////////////////////////////////

const ImpactModVars = ["PI_ImpactBaseDef_PSE", "PI_InfAvertPerPerson_PSE", "PI_ToAvert1Infection_PSE"];

/**
 * Recalculate impact mod vars
 *
 * @param {any[]} newPops
 * @param {Object.<string, any>} impactMVs
 * @param {PopCombineInfo} popCombineInfo
 */
function recalculateImpactModVars(newPops, impactMVs, popCombineInfo) {
  /** @type {Object.<string, number>} */
  const impactPSE = impactMVs["PI_ImpactPSE"];

  /** @type {number[][]} */
  const impactBaseDef = impactMVs["PI_ImpactBaseDef_PSE"];

  /** @type {number[]} */
  const impactEff = impactMVs["PI_ImpactEffectiveness"];

  /**
   * This will be the adjustment factors for the combined pops, not the original pops.
   * @type {any[]}
   */
  const adjFactors = impactMVs["PI_AdjustmentFactor"];

  const numMethods = impactBaseDef.length;

  const ret = {
    /** @type {number[][]} */
    PI_ImpactBaseDef_PSE: [],
    /** @type {number[][]} */
    PI_InfAvertPerPerson_PSE: [],
    /** @type {number[][]} */
    PI_ToAvert1Infection_PSE: [],
  };

  // Calculate new impact factors for the combined pops
  const newImpactFactors = newPops.map((pop, idx) => {
    const newPopID = pop["mstID"];
    const combineInfo = popCombineInfo[newPopID];

    let totalSusc = 0;
    let totalInc = 0;
    for (const origPopID of combineInfo.originalPopIDs) {
      const suscID = "SUSC" + origPopID.slice(3);
      const susc = impactPSE[suscID] ?? 0;
      totalInc += (impactPSE[origPopID] ?? 0) * susc;
      totalSusc += susc;
    }

    return totalSusc > 0 ? totalInc / totalSusc : 0;
  });

  // Update impact defaults for the combined pops
  ret.PI_PriorityPop = newPops.map((pop, pp) => ({
    ...pop,
    impact_pse: pop.impact_pse.map((v, m) => ({
      ...v,
      defaults: newImpactFactors[pp] * (impactEff[m] / 100),
    })),
  }));

  // Recalculate impact base def for the combined pops
  for (let m = 0; m < numMethods; ++m) {
    ret.PI_ImpactBaseDef_PSE.push(ret.PI_PriorityPop.map((pop, idx) => pop.impact_pse[m].defaults ?? 0));

    ret.PI_InfAvertPerPerson_PSE.push(
      ret.PI_ImpactBaseDef_PSE[m].map((v, idx) => v * (adjFactors[m].factors[idx] ?? 1))
    );
    ret.PI_ToAvert1Infection_PSE.push(ret.PI_InfAvertPerPerson_PSE[m].map((v) => (v > 0 ? 1 / v : 0)));
  }

  return ret;
}

///////////////////////////////////////////////////////////////////////////////
// Decombine Pops
///////////////////////////////////////////////////////////////////////////////

/**
 * Reverse the actions of {@link combinePriorPops}
 *
 * @param {any[]} origModVars Original mod vars
 * @param {Object} newData Object containing new modvar data to decombine
 * @param {PopCombineInfo} combineInfo Combine info used to combine the pops initially
 * @returns {Object} Object of decombined mod vars
 */
export function disaggregatePriorPops(origModVars, newData, combineInfo) {
  const origPriorPops = piasu.getModVarValue(origModVars, "PI_PriorityPop");
  const methods = piasu.getModVarValue(origModVars, "PI_Methods");
  const priorPops = newData["PI_PriorityPop"];

  // const ret = {
  //   PI_PriorityPop: structuredClone(origPriorPops),
  // };

  const ret = Object.fromEntries(
    Object.entries(newData).map(([k, v]) => [k, structuredClone(piasu.getModVarValue(origModVars, k))])
  );

  for (let pp = 0; pp < priorPops.length; ++pp) {
    const pop = priorPops[pp];
    const originalPopIDs = combineInfo[pop.mstID]?.originalPopIDs;

    const popIDs = originalPopIDs ?? [pop.mstID];
    const popIndexes = popIDs.map((id) => origPriorPops.findIndex((p) => p.mstID === id));

    for (const idx of popIndexes) {
      if (idx === -1) continue;

      // These are arrays of methods so can be set directly
      ret.PI_PriorityPop[idx].contCurve = structuredClone(pop.contCurve);
      ret.PI_PriorityPop[idx].scaleUpTrend = structuredClone(pop.scaleUpTrend);

      // Disaggregate method[pop[]] modvars
      for (let m = 0; m < methods.length; ++m) {
        // Eligibility
        if (ret.PI_Eligibility) {
          ret.PI_Eligibility[m].value[idx] = disaggObjValue(
            newData.PI_Eligibility[m].value[pp],
            ret.PI_Eligibility[m].value[idx]
          );
        }

        // Targets By Priority Pop
        if (ret.PI_TargetsByPriorityPop) {
          ret.PI_TargetsByPriorityPop[m].target[idx] = disaggObjValue(
            newData.PI_TargetsByPriorityPop[m].target[pp],
            ret.PI_TargetsByPriorityPop[m].target[idx]
          );
        }

        // Cost Categories
        if (ret.PI_CostCategories_Lite) {
          ret.PI_CostCategories_Lite[m].categories[idx] = disaggObjValue(
            newData.PI_CostCategories_Lite[m].categories[pp],
            ret.PI_CostCategories_Lite[m].categories[idx]
          );
          ret.PI_CostCategories_Lite[m].included = structuredClone(newData.PI_CostCategories_Lite[m].included);
        }

        // Adjustment Factor
        if (ret.PI_AdjustmentFactor) {
          ret.PI_AdjustmentFactor[m].factors[idx] = disaggObjValue(
            newData.PI_AdjustmentFactor[m].factors[pp],
            ret.PI_AdjustmentFactor[m].factors[idx]
          );
        }
      }

      // District Pops
      if (ret.PI_DistrictPopulations)
        disaggDistrictPops(ret.PI_DistrictPopulations, newData.PI_DistrictPopulations, idx, pp);

      // Method Mix
      if (ret.PI_MethodMix) disaggMethodMix(ret.PI_MethodMix, newData.PI_MethodMix, origPriorPops[idx], pop);
      if (ret.PI_MethodMixCoverage)
        disaggMethodMix(ret.PI_MethodMixCoverage, newData.PI_MethodMixCoverage, origPriorPops[idx], pop);
    }
  }

  return ret;
}

function disaggObjValue(newValue, oldValue) {
  const ret = structuredClone(newValue);

  if (ret.mstID) ret.mstID = oldValue.mstID;

  return ret;
}

function disaggDistrictPops(retData, newData, retPopIdx, newPopIdx) {
  for (let dp = 0; dp < retData.length; ++dp) {
    retData[dp].priorityPopulations[retPopIdx] = [
      retData[dp].priorityPopulations[retPopIdx][0],
      newData[dp].priorityPopulations[newPopIdx][1],
    ];
  }
}

function disaggMethodMix(retData, newData, retPop, newPop) {
  retData[retPop.mstID] = newData[newPop.mstID];
}

///////////////////////////////////////////////////////////////////////////////
// Categories
///////////////////////////////////////////////////////////////////////////////

const AllCategories = Object.keys(pseIncidenceCategories).map((v) => v.toUpperCase());

/**
 * Get list of pop IDs with categories given a list of base IDs
 * @param {(string|string[])} popBaseID Base pop ID
 * @param {string[]} [categories=AllCategories] Categories to append
 * @returns {string[]} List of new pop IDs
 */
export function popsWithCategories(popBaseID, categories = AllCategories) {
  if (typeof popBaseID === "string") popBaseID = [popBaseID];

  return popBaseID.flatMap((pop) => categories.map((cat) => `${pop}_${cat}`));
}

///////////////////////////////////////////////////////////////////////////////
// Ages
///////////////////////////////////////////////////////////////////////////////

const AllAgeRanges = [
  ...new Set(
    Object.keys(pseBasePops).map((v) => {
      const ages = v.split("_").slice(2);
      return `${ages[0].slice(1)}_${ages[1]}`;
    })
  ),
];

const MethodMixAgeBands = [
  ["15_19", "20_24"],
  ["25_29", "30_34"],
  ["35_39", "40_44", "45_49"],
];

const ContCurveAgeBands = [
  ["15_19", "20_24"],
  ["25_29", "30_34", "35_39", "40_44", "45_49"],
];

/**
 * Returns an array of population IDs with age groups.
 *
 * @param {string|string[]} popBaseID - The base population ID(s).
 * @param {string[]} [ageGroups=AllAgeRanges] - The age groups.
 * @returns {string[]} - The array of population IDs with age groups.
 */
export function popsWithAgeGroups(popBaseID, ageGroups = AllAgeRanges) {
  if (typeof popBaseID === "string") popBaseID = [popBaseID];

  return popBaseID.flatMap((pop) => ageGroups.map((age) => `${pop}${age}`));
}

///////////////////////////////////////////////////////////////////////////////
// Combined Pops by Category
///////////////////////////////////////////////////////////////////////////////

/**
 * Create a PopCombineInfo that includes categories based on what is available
 * in the priority pops.
 *
 * See PIMethodMixCoveragePSETable for example usage.
 *
 * @param {PopCombineInfo} baseCombineInfo Base combine info
 * @param {any[]} priorPops Priority pops modvar
 * @param {string[]} categories Categories to combine (lower case; used as keys)
 * @returns {PopCombineInfo}
 */
export function combinedPopsByCategory(baseCombineInfo, priorPops, categories) {
  /** @type {Object.<string, string[]>} */
  const popsByCategory = priorPops.reduce((acc, pop) => {
    if (pop.potential_users_pse === 0) return acc;

    const cat = pop.pse_incidence_category;
    const a = acc[cat] ?? [];
    a.push(pop.mstID);
    acc[cat] = a;

    return acc;
  }, {});

  /**
   *
   * @param {string[]} popIDs population IDs
   * @param {string} category category name
   */
  const popIDsForCategory = (popIDs, category) => {
    return popsByCategory[category]?.filter((popID) => popIDs.includes(popID)) ?? [];
  };

  /**
   *
   * @param {string} popID population ID
   * @param {CombinedPop} combineInfo pop combine info
   * @returns {Array<[string, CombinedPop]>}
   */
  const categoryPopsFromCombineInfo = (popID, combineInfo) => {
    return categories.map((category) => [
      `${popID}_${category.toUpperCase()}`,
      {
        newPopName: () => `${combineInfo.newPopName} ${category}`,
        originalPopIDs: popIDsForCategory(combineInfo.originalPopIDs, category),
      },
    ]);
  };

  return Object.fromEntries(
    Object.entries(baseCombineInfo).flatMap(([popID, combineInfo]) => categoryPopsFromCombineInfo(popID, combineInfo))
  );
}

///////////////////////////////////////////////////////////////////////////////
// Combined Pops Settings
///////////////////////////////////////////////////////////////////////////////

/** @type {PopCombineInfo} */
const genPopCombinedPops = {
  POP_GENPOP_F: {
    newPopName: () => `${RS(SC.GB_stFemales)}: ${RS(SC.GB_stGeneralPopulation)}`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_F", "POP_REG_F", "POP_NREG_F", "POP_REG_F"])),
  },
  POP_GENPOP_M: {
    newPopName: () => `${RS(SC.GB_stMales)}: ${RS(SC.GB_stGeneralPopulation)}`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_M", "POP_REG_M", "POP_NREG_M", "POP_REG_M"])),
  },
};

/** @type {PopCombineInfo} */
const methodMixGenPopCombinedPops = {
  POP_GENPOP_F15_24: {
    newPopName: () => `${RS(SC.GB_stFemales)}: ${RS(SC.GB_stGeneralPopulation)} 15 - 24`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_F", "POP_REG_F"], MethodMixAgeBands[0])),
  },
  POP_GENPOP_F25_34: {
    newPopName: () => `${RS(SC.GB_stFemales)}: ${RS(SC.GB_stGeneralPopulation)} 25 - 34`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_F", "POP_REG_F"], MethodMixAgeBands[1])),
  },
  POP_GENPOP_F35_49: {
    newPopName: () => `${RS(SC.GB_stFemales)}: ${RS(SC.GB_stGeneralPopulation)} 35 - 49`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_F", "POP_REG_F"], MethodMixAgeBands[2])),
  },
  POP_GENPOP_M15_24: {
    newPopName: () => `${RS(SC.GB_stMales)}: ${RS(SC.GB_stGeneralPopulation)} 15 - 24`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_M", "POP_REG_M"], MethodMixAgeBands[0])),
  },
  POP_GENPOP_M25_34: {
    newPopName: () => `${RS(SC.GB_stMales)}: ${RS(SC.GB_stGeneralPopulation)} 25 - 34`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_M", "POP_REG_M"], MethodMixAgeBands[1])),
  },
  POP_GENPOP_M35_49: {
    newPopName: () => `${RS(SC.GB_stMales)}: ${RS(SC.GB_stGeneralPopulation)} 35 - 49`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_M", "POP_REG_M"], MethodMixAgeBands[2])),
  },
};

/** @type {PopCombineInfo} */
const contCurveGenPopCombinedPops = {
  POP_GENPOP_F15_24: {
    newPopName: () => `${RS(SC.GB_stFemales)}: ${RS(SC.GB_stGeneralPopulation)} 15 - 24`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_F", "POP_REG_F"], ContCurveAgeBands[0])),
  },
  POP_GENPOP_F25_49: {
    newPopName: () => `${RS(SC.GB_stFemales)}: ${RS(SC.GB_stGeneralPopulation)} 25 - 49`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_F", "POP_REG_F"], ContCurveAgeBands[1])),
  },
  POP_GENPOP_M15_24: {
    newPopName: () => `${RS(SC.GB_stMales)}: ${RS(SC.GB_stGeneralPopulation)} 15 - 24`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_M", "POP_REG_M"], ContCurveAgeBands[0])),
  },
  POP_GENPOP_M25_49: {
    newPopName: () => `${RS(SC.GB_stMales)}: ${RS(SC.GB_stGeneralPopulation)} 25 - 49`,
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_NREG_M", "POP_REG_M"], ContCurveAgeBands[1])),
  },
};

/** @type {PopCombineInfo} */
const keyPopCombinedPops = {
  POP_FSW: {
    newPopName: () => RS(SC.GB_stFSW),
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_FSW_F"])),
  },
  POP_MSM: {
    newPopName: () => RS(SC.GB_stMSM),
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_MSM_M"])),
  },
  POP_PWID: {
    newPopName: () => RS(SC.GB_stIDU),
    originalPopIDs: popsWithCategories(popsWithAgeGroups(["POP_PWID_M"])),
  },
};

/** @type {PopCombineInfo} */
export const genAndKeyPopsCombinedPops = {
  ...genPopCombinedPops,
  ...keyPopCombinedPops,
};

/** @type {PopCombineInfo} */
export const methodMixCombinedPops = {
  ...keyPopCombinedPops,
  ...methodMixGenPopCombinedPops,
};

/** @type {PopCombineInfo} */
export const contCurveCombinedPops = {
  ...keyPopCombinedPops,
  ...contCurveGenPopCombinedPops,
};
