import React, { Component } from "react";
import * as PropTypes from "prop-types";

//import DetailsIcon from "@material-ui/icons/Details";
//import AddIcon from "@material-ui/icons/Add";
//import AssistantIcon from "@material-ui/icons/Assistant";

import PillIcon from "../../../img/Pill_Whole_SherpaBlue80.png"; // markPretty60
import AggregateIcon from "../../../img/Aggregate_SherpaBlue80.png"; // markPretty60

import TButton from "../../common/TButton";
import TDialog from "../../common/TDialog";

import * as pic from "../NonComponents/PIConst";

import * as Theme from "../../../app/Theme";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import * as pih from "../NonComponents/PIHelp";

const showModeChangeDialogBool = "showModeChangeDialogBool";
const proposedModeMstIDStr = "proposedModeMstIDStr";
const resetModVarsBool = "resetModVarsBool";

class PIToolsDiv extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,
    [pias.onRunInitialRESTCalls]: PropTypes.func,
    [pias.onSideBarArrowClick]: PropTypes.func,
    [pias.onHelpAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log([pias.onModVarsChange]),

    [pias.onPageChange]: () => console.log([pias.onPageChange]),
    [pias.onRunInitialRESTCalls]: () => console.log(pias.onRunInitialRESTCalls),
    [pias.onSideBarArrowClick]: () => console.log([pias.onSideBarArrowClick]),
    [pias.onHelpAreaChange]: () => console.log([pias.onHelpAreaChange]),
  };

  state = {
    [proposedModeMstIDStr]: "",
    [showModeChangeDialogBool]: false,
    [resetModVarsBool]: false,
  };

  _isMounted = false;

  //==================================================================================================================
  //
  //                                              Utility Functions
  //
  //==================================================================================================================

  onChangeMode = () => {
    const resetState = () => {
      if (this._isMounted) {
        this.setState({
          [proposedModeMstIDStr]: "",
          [showModeChangeDialogBool]: false,
          [resetModVarsBool]: false,
        });
      }
    };

    try {
      const props = this.props;
      const onSideBarArrowClick = props[pias.onSideBarArrowClick];
      const onPageChange = props[pias.onPageChange];
      let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];
      const onRunInitialRESTCalls = props[pias.onRunInitialRESTCalls];
      const onHelpAreaChange = props[pias.onHelpAreaChange];

      const state = this.state;
      const proposedModeMstIDStrC = state[proposedModeMstIDStr];
      const resetModVarsBoolC = state[resetModVarsBool];

      /* Get the current mode. */
      //const appModeMstIDStrCurr = piasu.getModVarValue(modVarObjArrClone, pisc.appModeMVTag);

      const changeMode = (modVarObjArr) => {
        piasu.setModVarValue(modVarObjArr, pisc.appModeMVTag, proposedModeMstIDStrC);

        onModVarsChange(modVarObjArr, false, () => {
          onSideBarArrowClick(true, () => {
            let pageID;
            let helpFile;

            /* Next page will depend on mode selected. */
            if (proposedModeMstIDStrC === pisc.PrEPitStdToolMstID) {
              pageID = pic.configForm;
              helpFile = pih.configFM_HP;
            } else if (proposedModeMstIDStrC === pisc.PrEPitEasyStartToolMstID) {
              pageID = pic.gettingStartedForm;
              helpFile = pih.general_HP;
            } else if (proposedModeMstIDStrC === pisc.aggregateToolMstID) {
              pageID = pic.aggregateForm;
              helpFile = pih.aggregateFM_HP;
            }

            onHelpAreaChange(helpFile, () => {
              onPageChange(pageID, () => resetState());
            });
          });
        });
      };

      if (resetModVarsBoolC) {
        onRunInitialRESTCalls((initModVarObjArr) => {
          changeMode(initModVarObjArr);
        });
      } else {
        changeMode(modVarObjArrClone);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
      resetState();
    }
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onToolModeBtnClick = (appModeMstIDStr) => {
    try {
      const props = this.props;
      let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);

      // This originally only reset modvars when changing to/from aggregate mode. Now it needs to happen every time.

      const appModeMstIDStrCurr = piasu.getModVarValue(modVarObjArrClone, pisc.appModeMVTag);

      if (appModeMstIDStrCurr !== pisc.noToolSelectedMstID && appModeMstIDStrCurr !== appModeMstIDStr) {
        this.setState({
          [proposedModeMstIDStr]: appModeMstIDStr,
          [showModeChangeDialogBool]: true,
          [resetModVarsBool]: true,
        });
      } else {
        this.setState(
          {
            [proposedModeMstIDStr]: appModeMstIDStr,
          },
          () => this.onChangeMode()
        );
      }

      // if (
      // (appModeMstIDStrCurr === pisc.aggregateToolMstID &&
      //     (appModeMstIDStr === pisc.PrEPitStdToolMstID || appModeMstIDStr === pisc.PrEPitEasyStartToolMstID)) ||
      //   ((appModeMstIDStrCurr === pisc.PrEPitStdToolMstID || appModeMstIDStrCurr === pisc.PrEPitEasyStartToolMstID) &&
      //     appModeMstIDStr === pisc.aggregateToolMstID)
      // ) {
      //   this.setState({
      //     [proposedModeMstIDStr]: appModeMstIDStr,
      //     [showModeChangeDialogBool]: true,
      //     [resetModVarsBool]: true,
      //   });
      // } else {
      //   this.setState(
      //     {
      //       [proposedModeMstIDStr]: appModeMstIDStr,
      //     },
      //     () => this.onChangeMode()
      //   );
      // }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onModeChangeDialogClose = (value) => {
    if (value === "mrYes") {
      this.onChangeMode();
    } else {
      this.setState({
        [proposedModeMstIDStr]: "",
        [showModeChangeDialogBool]: false,
        [resetModVarsBool]: false,
      });
    }
  };

  //==================================================================================================================
  //
  //                                               Lifecycle
  //
  //==================================================================================================================

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderModeDiv = (img, labelText, descriptionText, btn1Text, btn1OnClick, btn2Text, btn2OnClick, divStyle) => {
    const marginLeft = 10;

    const btnStyle = {
      fontSize: 16,
      width: 100,
      margin: marginLeft,
    };

    const modeImg = (
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {img}
      </div>
    );

    const modeLab = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          fontSize: 24,
          marginLeft: marginLeft,
          marginTop: 0,
          ...Theme.labelFontStyle,
        }}
      >
        {labelText}
      </p>
    );

    /* Fixed the height of the description to push the button under it towards the
           bottom of the div. */
    const modeDescription = (
      <div
        style={{
          height: 110,
          marginLeft: marginLeft,
        }}
      >
        <p>{descriptionText}</p>
      </div>
    );

    let btn1 = null;

    if (btn1Text !== "") {
      btn1 = <TButton caption={btn1Text} onClick={btn1OnClick} style={btnStyle} />;
    }

    let btn2 = null;

    if (btn2Text !== "") {
      btn2 = (
        <TButton
          caption={btn2Text}
          onClick={btn2OnClick}
          style={{
            ...btnStyle,
            backgroundColor: Theme.PI_TertiaryColor,
            marginLeft: Theme.leftIndent,
          }}
        />
      );
    }

    const modeDiv = (
      <div
        style={{
          backgroundColor: Theme.whiteColor,
          borderRadius: Theme.PI_BtnBorderRadius, // markPretty Theme.PI_DivBorderRadius,//20,
          borderStyle: "solid",
          borderWidth: 0, //markPretty 1,
          opacity: 1,
          padding: 5, // was 20, so add 30 to width
          height: 300, //markPretty 280, //330
          width: 250, //300,
          ...divStyle,
        }}
      >
        {modeImg}
        {modeLab}
        {modeDescription}
        {btn1}
        {btn2}
      </div>
    );

    return modeDiv;
  };

  renderModeChangeDialog = () => {
    const fn = () => {
      const state = this.state;
      const showModeChangeDialogBoolC = state[showModeChangeDialogBool];

      let dialog = null;

      if (showModeChangeDialogBoolC) {
        const dialogContent = (
          <p
            key={"dialogContent"}
            style={{
              ...Theme.textFontStyle,
              padding: 0,
              margin: 0,
            }}
          >
            {RS(SC.GB_stSwitchModesDataLoss)}
          </p>
        );

        dialog = (
          <TDialog
            actions={["mrYes", "mrNo"]}
            okBtnCaption={RS(SC.GB_stYes)}
            cancelBtnCaption={RS(SC.GB_stNo)}
            onClose={(userResponse) => this.onModeChangeDialogClose(userResponse)}
            content={dialogContent}
            key={"aboutDialog"}
            maxWidth={"md"}
            style={{
              width: 700,
            }}
          />
        );
      }

      return dialog;
    };

    return gbu.tryRenderFn(fn, "renderModeChangeDialog");
  };

  render() {
    const PrEPitDiv = this.renderModeDiv(
      <img alt={""} color={Theme.PI_SecondaryColor} src={PillIcon} />,
      RS(SC.GB_stPrEPitForCop25),
      RS(SC.GB_stSpecialVersionOfPrEP), // RS(SC.GB_stPrEPitToolDescription)
      RS(SC.GB_stStart), //RS(SC.GB_stStandard),
      () => this.onToolModeBtnClick(pisc.PrEPitEasyStartToolMstID), // Easy Start is now Standard mode
      "",
      () => {},
      {}
    );

    const aggregateDiv = this.renderModeDiv(
      <img alt={""} color={Theme.PI_SecondaryColor} src={AggregateIcon} />,
      RS(SC.GB_stAggregate),
      RS(SC.GB_stAggToolDescription),
      RS(SC.GB_stBegin),
      () => this.onToolModeBtnClick(pisc.aggregateToolMstID),
      "",
      () => {},
      {}
    );

    const modeChangeDialog = this.renderModeChangeDialog();

    const modesDiv = (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          gap: Theme.ctrlSpacing,
        }}
      >
        {PrEPitDiv}
        {aggregateDiv}
        {modeChangeDialog}
      </div>
    );

    return (
      <div
        style={{
          padding: 20,
          paddingLeft: 0,
          width: "100%",
        }}
      >
        {modesDiv}

        <div
          style={{
            display: "flex",
            flexShrink: 1,
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: Theme.whiteColor,
            borderRadius: Theme.PI_BtnBorderRadius,
            borderStyle: "solid",
            borderWidth: 0,
            margin: Theme.ctrlSpacing,
            padding: 5,
          }}
        >
          {RS(SC.GB_stQuestionsAboutVersionsEmail)}
        </div>
      </div>
    );
  }
}

export default PIToolsDiv;
