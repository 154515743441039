import React, { useState } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import { promisify } from "../../../utilities";

const PIMethodMixCovConstraintsOptTargTable = (props) => {
  const modVarObjList = props.modVarObjList;
  const onModVarsChange = promisify(props.onModVarsChange);

  const [selectedRegions, setSelectedRegions] = useState([{ rowStart: 0, rowEnd: 0, columnStart: 1, columnEnd: 1 }]);
  const [focusedCell, setFocusedCell] = useState({ rowFocus: 0, colFocus: 1 });
  const [rDec, setRDecs] = useState([]);

  // Table
  /** @type {any[]} */
  const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
  /** @type {any[]} */
  const allPops = piasu.getModVarValue(modVarObjList, "PI_PriorityPop");
  let priorPops = allPops;
  let methodMix = piasu.getModVarValue(modVarObjList, "PI_MethodMix");
  let methodMixCoverage = piasu.getModVarValue(modVarObjList, "PI_MethodMixCoverage");
  let eligibility = piasu.getModVarValue(modVarObjList, "PI_Eligibility");
  /** @type {any[]} */
  const actualCoverage = piasu.getModVarValue(modVarObjList, "PI_ActualCoverage");

  const badNumberFontColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.red));
  const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));

  const numRows = priorPops.length + 2;
  const numCols = methods.length * 2 + 4;

  const firstRow = 2;
  const firstMethod = 2;
  const firstActualCovMethod = firstMethod + methods.length + 1;

  // Callbacks
  const onPackTableChange = async (newPackTable) => {
    setRDecs(structuredClone(newPackTable.RDec));

    // Coverage
    let newCoverage = {};
    for (let pp = 0; pp < priorPops.length; ++pp) {
      const pop = priorPops[pp];
      const value = gbtu.getValue(newPackTable, pp + firstRow, 1);
      newCoverage[pop.mstID] = typeof value === "number" ? value / 100 : 0.0;
    }

    const newModVars = structuredClone(modVarObjList);
    piasu.setModVarValue(newModVars, "PI_MethodMixCoverage", newCoverage);

    await onModVarsChange(newModVars, false);
  };

  // Table
  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  // Headings
  gbtu.setFixedRows(packTable, 2);

  gbtu.setValue(packTable, 0, firstMethod - 1, RS(SC.GB_stProposedCoverage) + " (%)");
  gbtu.mergeCells(packTable, 0, firstMethod - 1, 1, methods.length + 2);

  gbtu.setValue(packTable, 1, 0, RS(SC.GB_stPriorityPop));
  gbtu.setValue(packTable, 1, 1, RS(SC.GB_stCoverage));

  gbtu.setValue(packTable, 0, firstActualCovMethod, RS(SC.GB_stActualCovToBeAchieved) + " (%)");
  gbtu.mergeCells(packTable, 0, firstActualCovMethod, 1, methods.length + 1);

  for (let m = 0; m < methods.length; ++m) {
    gbtu.setValue(packTable, 1, m + firstMethod, methods[m].name);
    gbtu.setValue(packTable, 1, m + firstActualCovMethod, methods[m].name);
  }
  gbtu.setValue(packTable, 1, firstActualCovMethod - 1, RS(SC.GB_stTotal));
  gbtu.setValue(packTable, 1, numCols - 1, RS(SC.GB_stTotal));

  // Data
  for (let pp = 0; pp < priorPops.length; ++pp) {
    const pop = priorPops[pp];
    gbtu.setValue(packTable, pp + firstRow, 0, pop.name);

    let disableTotal = true;
    let total = 0.0;
    let actTotal = 0.0;

    // Coverage
    const coverage = methodMixCoverage?.[pop.mstID] ?? 1.0;

    for (let m = 0; m < methods.length; ++m) {
      const method = methods[m];

      if (piasu.getPriorPopMethodElig(eligibility, method.mstID, pp + 1) === pisc.yesMstID) {
        // Proposed Coverage
        const proposedValue = (methodMix?.[pop.mstID]?.[method.mstID] ?? 0) * coverage * 100;
        gbtu.setValue(packTable, pp + firstRow, m + firstMethod, proposedValue);
        total += proposedValue;

        // Actual Coverage
        const actualValue = actualCoverage[m].coverage[pp] * 100;
        gbtu.setValue(packTable, pp + firstRow, m + firstActualCovMethod, actualValue);
        actTotal += actualValue;

        disableTotal = false;
      } else {
        gbtu.setCellBGColor(packTable, pp + firstRow, m + firstMethod, gainsboroBase10);
        gbtu.setCellBGColor(packTable, pp + firstRow, m + firstActualCovMethod, gainsboroBase10);
      }

      gbtu.lockCell(packTable, pp + firstRow, m + firstMethod, true, true);
      gbtu.lockCell(packTable, pp + firstRow, m + firstActualCovMethod, true, true);
    }

    // Total
    if (!disableTotal) {
      gbtu.setValue(packTable, pp + firstRow, 1, coverage * 100);
      gbtu.setValue(packTable, pp + firstRow, firstActualCovMethod - 1, total);
      gbtu.setValue(packTable, pp + firstRow, numCols - 1, actTotal);

      gbtu.lockCell(packTable, pp + firstRow, firstActualCovMethod - 1, true, total <= 100);
      gbtu.lockCell(packTable, pp + firstRow, numCols - 1, true, actTotal <= 100);
      if (total > 100) gbtu.setFontColor(packTable, pp + firstRow, firstActualCovMethod - 1, badNumberFontColor);
      if (actTotal > 100) gbtu.setFontColor(packTable, pp + firstRow, numCols - 1, badNumberFontColor);
    } else {
      // Grey out Total
      gbtu.lockCell(packTable, pp + firstRow, firstActualCovMethod - 1, true, true);
      gbtu.setCellBGColor(packTable, pp + firstRow, firstActualCovMethod - 1, gainsboroBase10);

      gbtu.lockCell(packTable, pp + firstRow, numCols - 1, true, true);
      gbtu.setCellBGColor(packTable, pp + firstRow, numCols - 1, gainsboroBase10);

      // Grey out Coverage
      gbtu.lockCell(packTable, pp + firstRow, 1, true, true);
      gbtu.setCellBGColor(packTable, pp + firstRow, 1, gainsboroBase10);
    }
  }

  // Settings
  gbtu.alignNumericCellsRight(packTable);
  // gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
  gbtu.setMinAllowedValForTable(packTable, 0);
  gbtu.setMaxAllowedValForTable(packTable, 100);
  gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
  gbtu.setColWidth(packTable, 1, Theme.dataColWidthSmall);
  gbtu.setRowHeight(packTable, 0, 50);
  gbtu.setWordWrappedCol(packTable, 0, true);
  gbtu.setID(packTable, "optionsPackTable");
  gbtu.setKey(packTable, "optionsPackTable");

  gbtu.restoreRDecsFromCopy(packTable, rDec, 1);

  return (
    <SuperTableShim
      focusedCell={props.activeTable === 2 ? focusedCell : undefined}
      onCellFocused={(focusedCell) => props.activeTable === 2 && setFocusedCell(focusedCell)}
      selectedRegions={selectedRegions}
      onSelectionChanged={(selectedRegions) => setSelectedRegions(selectedRegions)}
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      gridKey={props.tableKey}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PIMethodMixCovConstraintsOptTargTable.propTypes = {
  modVarObjList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onModVarsChange: PropTypes.func,

  tableKey: PropTypes.string,

  activeTable: PropTypes.number,
  onTableChanged: PropTypes.func,
};

PIMethodMixCovConstraintsOptTargTable.defaultProps = {
  onModVarsChange: () => {},

  tableKey: "",

  activeTable: 2,
  onTableChanged: () => {},
};

export default PIMethodMixCovConstraintsOptTargTable;
