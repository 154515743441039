import React from "react";
import * as PropTypes from "prop-types";
import { useState } from "react";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbu from "../../../GB/GBUtil";
import * as gbtc from "../../../GB/GBTableConst";

import * as piasu from "../../NonComponents/PIAppStateUtil";

import * as pisc from "../../NonComponents/PIServerConst";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

import { combinePriorPops, genAndKeyPopsCombinedPops } from "../../NonComponents/PIPriorPopUtil";

const firstCol = 0;
const totalCol = 1;

const firstRow = 0;
const PrEP_NewRow = 1;
const PrEP_CTRow = 2;
const recipientsRow = window.HIDE_PREP_RECIPIENTS ? 2 : 3;
const currOnPrEPRow = recipientsRow + 1;
const numRows = currOnPrEPRow + 1;

const PITargIndResTable = (props) => {
  const modVarObjList = props.modVarObjList;
  const isPSEMode = piasu.isPSEMode(modVarObjList);

  // Mod vars
  const easyStartOptions = piasu.getModVarValue(modVarObjList, "PI_EasyStartOptions");

  /** @type {any[]} */
  let priorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
  let targIndObj = piasu.getModVarValue(modVarObjList, pisc.targIndTableMVTag);
  let priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
  const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);
  const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

  /** @type {any[]} */
  let districts = piasu.getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);

  if (isPSEMode) {
    const combined = combinePriorPops(modVarObjList, genAndKeyPopsCombinedPops, [
      "PI_TargetIndicators",
      "PI_Eligibility",
      "PI_DistrictPopulations",
    ]);

    priorPopObjArr = combined["PI_PriorityPop"];
    targIndObj = combined["PI_TargetIndicators"];
    priorPopMethodEligObjArr = combined["PI_Eligibility"];
    districts = combined["PI_DistrictPopulations"];
  }

  /** @type {Set<string>} */
  const enabledPops = districts.reduce((acc, cur) => {
    for (const pop of cur.priorityPopulations || []) {
      if (!pop[1]) continue;

      acc.add(pop[0]);
    }

    return acc;
  }, new Set());

  const hasGeographicallyDisaggregatedTargets =
    easyStartOptions?.find(({ mstID }) => mstID === "DISAGG_TARGS_SUBNAT_ES")?.value ?? true;

  // Helper functions
  const onPackTableChanged = (newPackTable) => {
    setRDec(newPackTable.RDec);
  };

  // State
  const [rDec, setRDec] = useState(/** @type {number[][]} */ ([]));

  // Table
  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, priorPopObjArr.length + 2);

  gbtu.setValue(packTable, firstRow, firstCol, RS(SC.GB_stTargInd));
  gbtu.setValue(packTable, firstRow, firstCol + 1, RS(SC.GB_stTotal));

  gbtu.setValue(packTable, PrEP_NewRow, firstCol, RS(SC.GB_stPrEPNEW)); //GB_stInits));
  gbtu.setValue(packTable, PrEP_CTRow, firstCol, RS(SC.GB_stPrEPCT)); //GB_stPrEPCurr));
  if (!window.HIDE_PREP_RECIPIENTS) gbtu.setValue(packTable, recipientsRow, firstCol, RS(SC.GB_stPrEPRecipients));
  gbtu.setValue(packTable, currOnPrEPRow, firstCol, RS(SC.GB_stCurrentlyOnPrEP));

  // Table Data
  let selectedMethodCurrID;
  let m1;
  let m2;
  if (selectedMethodMstID === pisc.allMethodsCombined) {
    selectedMethodCurrID = piasu.getTotalNumMethods(methodObjArr) + 1;
    m1 = 1;
    m2 = piasu.getTotalNumMethods(methodObjArr);
  } else {
    selectedMethodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);
    m1 = selectedMethodCurrID;
    m2 = selectedMethodCurrID;
  }

  let initTotal = 0;
  let prepCurrTotal = 0;
  let currOnPrEPTotal = 0;
  let recipientsTotal = 0;
  let grayOutTotalColBool = hasGeographicallyDisaggregatedTargets;

  let col = 2;
  for (let pp = 1; pp <= priorPopObjArr.length; pp++) {
    const propName = piasu.getPriorPopName(priorPopObjArr, pp);
    gbtu.setValue(packTable, firstRow, col, propName);

    let initTIAcrossMethods = 0;
    let PrEP_CurrTIAcrossMethods = 0;
    let currOnPrEPTIAcrossMethods = 0;
    let recipientsAcrossMethods = 0;
    let grayOutPriorPopCol = hasGeographicallyDisaggregatedTargets;

    //let value = 0;

    for (let m = m1; m <= m2; m++) {
      //markElig
      const methodMstID = piasu.methodMstID(methodObjArr, m);
      const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);

      if (methodEligMstIDStr === pisc.yesCVOMstID && enabledPops.has(priorPopObjArr[pp - 1].mstID)) {
        const initTICurrMethod = piasu.PrEP_NEW_TI(targIndObj, m, pp);
        initTIAcrossMethods += initTICurrMethod;
        initTotal += initTICurrMethod;

        const PrEP_CurrTICurrMethod = piasu.PrEP_CT_TI(targIndObj, m, pp);
        PrEP_CurrTIAcrossMethods += PrEP_CurrTICurrMethod;
        prepCurrTotal += PrEP_CurrTICurrMethod;

        const recipients = piasu.PrEP_recipients_TI(targIndObj, m, pp) ?? 0;
        recipientsAcrossMethods += recipients;
        recipientsTotal += recipients;

        const currOnPrEPTICurrMethod = piasu.currOnPrEP_TI(targIndObj, m, pp);
        currOnPrEPTIAcrossMethods += currOnPrEPTICurrMethod;
        currOnPrEPTotal += currOnPrEPTICurrMethod;

        /* If even one column will be showing values, do not gray out the total column. */
        grayOutTotalColBool = false;

        /* Do not gray out the column for this priority pop if showing the selected method and the
                           priority pop is eligible or all methods combined and the priority pop is eligible for any
                           of them.  */
        grayOutPriorPopCol = false;
      }
    }

    /* Either a single method or all methods combined may be selected. If it's single and the
                   priority pop is not eligible OR the priority pop in not eligible for any method, then gray out the
                   column. Otherwise, show the value. */
    if (!grayOutPriorPopCol) {
      gbtu.setValue(packTable, PrEP_NewRow, col, initTIAcrossMethods);
      gbtu.setValue(packTable, PrEP_CTRow, col, PrEP_CurrTIAcrossMethods);
      if (!window.HIDE_PREP_RECIPIENTS) gbtu.setValue(packTable, recipientsRow, col, recipientsAcrossMethods);
      gbtu.setValue(packTable, currOnPrEPRow, col, currOnPrEPTIAcrossMethods);
    } else {
      gbtu.lockCol(packTable, col, true, true);
      const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
      gbtu.setColBGColor(packTable, col, gainsboroBase10);
    }

    col++;
  }

  /* This is unlikely, but if none of the priority pops are eligible for the selected method (or all methods
               combined, if the user selected that), then gray out the total column. Otherwise, show it. */
  if (!grayOutTotalColBool) {
    gbtu.setValue(packTable, PrEP_NewRow, totalCol, initTotal);
    gbtu.setValue(packTable, PrEP_CTRow, totalCol, prepCurrTotal);
    if (!window.HIDE_PREP_RECIPIENTS) gbtu.setValue(packTable, recipientsRow, totalCol, recipientsTotal);
    gbtu.setValue(packTable, currOnPrEPRow, totalCol, currOnPrEPTotal);
  } else {
    gbtu.lockCol(packTable, totalCol, true, true);
    const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
    gbtu.setColBGColor(packTable, totalCol, gainsboroBase10);
  }

  // Table Formatting
  gbtu.setColWidths(packTable, Theme.dataColWidthMed);
  gbtu.setColWidth(packTable, firstCol, Theme.dataColWidthLarge + 30);

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);

  gbtu.setRowHeight(packTable, 0, 100);
  gbtu.lockPackTable(packTable, true, false);

  gbtu.restoreRDecsFromCopy(packTable, rDec, 0);

  // Render
  const InfoButton = () => (
    <IconButton
      onClick={props.onInfoBtnClick}
      style={{
        color: Theme.PI_SecondaryColor,
        cursor: "pointer",
        display: "inline-block",
        marginTop: 0,
        padding: 0,
      }}
    >
      <Info
        style={{
          color: Theme.PI_SecondaryColor,
        }}
      />
    </IconButton>
  );

  return (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        marginTop: 20,
      }}
    >
      <SuperTableShim
        font={Theme.tableFont}
        headerBackgroundColor={Theme.PI_PrimaryColor}
        oddRowBackgroundColor={Theme.PI_BandColor}
        packTable={packTable}
        types={generateTypes(packTable)}
        onPackTableChanged={onPackTableChanged}
        removedMenuNames={pitu.tableHideMenuItems}
        style={{
          tableFont: Theme.tableFont,
          marginRight: 20,
          padding: 0,
        }}
        width={0}
      />
      <InfoButton />
    </div>
  );
};

PITargIndResTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onInfoBtnClick: PropTypes.func,
};

PITargIndResTable.defaultProps = {
  onInfoBtnClick: () => {},
};

export default PITargIndResTable;
