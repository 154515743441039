import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TSlideDrawer from "../../common/TSlideDrawer";
import THelpButton from "../../common/THelpButton";

import * as gbu from "../../GB/GBUtil";

import * as php from "../NonComponents/PIHelp";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pip from "../NonComponents/PIProps";

import PIVisitSchedForm from "../Forms/PIVisitSchedForm";

const applyBtnEnabledC = "applyBtnEnabled";

class PIVisitSchedSlideDrawer extends React.Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,
    [pias.onDrawerChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(`PIVisitSchedSlideDrawer: ${pias.onCalculatingChange}`),
    [pias.onDrawerChange]: () => console.log(`PIVisitSchedSlideDrawer: ${pias.onDrawerChange}`),

    [pias.helpAreaStr]: php.config_ContSD_HP,
    [pias.onHelpAreaChange]: () => console.log(`PIVisitSchedSlideDrawer: ${pias.onHelpAreaChange}`),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(`PIVisitSchedSlideDrawer: ${pias.onModVarsChange}`),

    [pias.tableKeyObj]: {},

    [pip.onNextAreaChange]: () => console.log(`PIVisitSchedSlideDrawer: ${pip.onNextAreaChange}`),
    [pip.onPrevAreaChange]: () => console.log(`PIVisitSchedSlideDrawer: ${pip.onPrevAreaChange}`),
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    this.state = {
      /* Passed down to further components and passed back up later via callbacks. */
      [pias.modVarObjList]: structuredClone(props[pias.modVarObjList]),
      [pias.tableKeyObj]: structuredClone(props[pias.tableKeyObj]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true,
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  onUpdateHelpArea = (successFn) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    onHelpAreaChange(php.configFM_HP, successFn);
  };

  onSlideDrawerSaveBtnClick = (successFn) => {
    /* state */
    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    /* props */
    const props = this.props;
    const onModVarsChange = props[pias.onModVarsChange];
    const onDrawerChange = props[pias.onDrawerChange];

    const editorValid = true;

    if (!editorValid) {
      return false;
    } else {
      onModVarsChange(
        modVarObjList,
        false,
        () => {
          this.setState(
            {
              [applyBtnEnabledC]: false,
            },
            () => {
              this.onUpdateHelpArea(() => {
                onDrawerChange(() => {
                  gbu.safeCallFn(successFn);
                  return true;
                });
              });
            }
          );
        },
        () => {
          /* In case recalc fails, close the drawer so the user is not stuck. */

          this.onUpdateHelpArea(() => {
            onDrawerChange(() => {
              gbu.safeCallFn(successFn);
              return true;
            });
          });
        }
      );
    }
  };

  onSlideDrawerCloseBtnClick = () => {
    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];

    this.onUpdateHelpArea(() => {
      onDrawerChange();
    });
  };

  /* Match onModVarsChange method signature passed into this form so ItemsSlideDrawer
    can stay the same whether it appears inside another drawer or not. calculateBool will not
    be used. */
  onModVarsChange = (modVarObjList, calculateBool, successFn) => {
    let tableKeyObjClone = structuredClone(this.state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [pias.tableKeyObj]: tableKeyObjClone,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    /* props */
    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];

    const helpAreaStr = props[pias.helpAreaStr];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];

    const onModVarsChange = this[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    /* state */
    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];
    const tableKeyObj = state[pias.tableKeyObj];

    const PIVisitSchedFormComp = (
      <PIVisitSchedForm
        {...{
          [pias.onCalculatingChange]: onCalculatingChange,

          [pias.helpAreaStr]: helpAreaStr,
          [pias.onHelpAreaChange]: onHelpAreaChange,
          [pias.onHelp]: onHelp,

          [pias.modVarObjList]: modVarObjList,
          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,

          [pias.tableKeyObj]: tableKeyObj,

          [pip.onSlideDrawerSaveBtnClick]: this[pip.onSlideDrawerSaveBtnClick],

          [pip.onNextAreaChange]: onNextAreaChange,
          [pip.onPrevAreaChange]: onPrevAreaChange,
        }}
      />
    );

    const helpBtn = <THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />;

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick}
        content={PIVisitSchedFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        title={RS(SC.GB_stVisitSchedules)}
        helpBtn={helpBtn}
      />
    );

    return slideDrawer;
  }
}

export default PIVisitSchedSlideDrawer;
