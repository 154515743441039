// @ts-check
import React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes, repeat } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

import { combinePriorPops, contCurveCombinedPops } from "../../NonComponents/PIPriorPopUtil";

const firstRow = 0;

const PITargDisagDistrictPriorPopsResTable = (props) => {
  const modVarObjList = props.modVarObjList;

  let priorPops = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  let prepNew = piasu.getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_NEW_MVTag);
  let prepCT = piasu.getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_CT_MVTag);
  let districtPops = piasu.getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);

  const PrEPStatus = piasu.getModVarValue(modVarObjList, pisc.selectedPrEPStatusMVTag);
  const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const methods = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

  const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.disagTargTemplateUploadedMVTag);
  const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

  const isPSEMode = piasu.isPSEMode(modVarObjList);

  if (isPSEMode) {
    const combined = combinePriorPops(modVarObjList, contCurveCombinedPops, [
      pisc.targDisagDistPopPrEP_NEW_MVTag,
      pisc.targDisagDistPopPrEP_CT_MVTag,
      pisc.districtPopulationsMVTag,
    ]);

    priorPops = combined["PI_PriorityPop"];
    prepNew = combined[pisc.targDisagDistPopPrEP_NEW_MVTag];
    prepCT = combined[pisc.targDisagDistPopPrEP_CT_MVTag];
    districtPops = combined[pisc.districtPopulationsMVTag];
  }

  // markAGYW
  const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
  const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
  const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

  const selectedMethodCurrID = piasu.getMethodCurrID(methods, selectedMethodMstID);

  const numDistrictsDP = piasu.getNumDistrictsDP(districtPops);
  const numRows = numDistrictsDP + 1;

  const selectedPriorityPopulations =
    districtPops.map((district) => district.priorityPopulations?.map((priorityPopulation) => priorityPopulation[1])) ??
    [];

  /* The user can specify up to two levels but needs to specify at least one. If
               they don't, don't show the table. */
  if (level1Name === "" || (!isPSEMode && !templateUploaded)) {
    return null;
  }

  let level1NameCol = 0;
  let level2NameCol = 1;
  let totalCol = 2;
  let numNonPriorPopCols = totalCol + 1;
  let priorPopStartCol = 3;

  // markAGYW
  if (level2Name === "") {
    totalCol--;
    numNonPriorPopCols--;
    priorPopStartCol--;
  }

  const numPriorPops = piasu.getTotalNumPriorPops(priorPops);
  const numCols = numPriorPops + numNonPriorPopCols;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  /* Set column headings */
  // markAGYW start
  gbtu.setValue(packTable, firstRow, level1NameCol, level1Name);
  if (level2Name !== "") {
    gbtu.setValue(packTable, firstRow, level2NameCol, level2Name);
  }
  // markAGYW end
  gbtu.setValue(packTable, firstRow, totalCol, RS(SC.GB_stTotal));
  let c = priorPopStartCol;
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const priorPopName = piasu.getPriorPopName(priorPops, pp);
    gbtu.setValue(packTable, firstRow, c, priorPopName);

    c++;
  }

  const levelCount = levelNames1DStrArr.filter((name) => Boolean(name)).length;

  const populationPotentialTotals = Array(numPriorPops).fill(0);

  for (let dp = 1; dp <= numDistrictsDP; dp++) {
    /* Set row headings. */
    const provinceName = piasu.provinceNameDP(districtPops, dp);
    gbtu.setValue(packTable, dp, level1NameCol, provinceName);

    // markAGYW
    if (level2Name !== "") {
      const districtName = piasu.districtNameDP(districtPops, dp);
      gbtu.setValue(packTable, dp, level2NameCol, districtName);
    }

    let c = priorPopStartCol;
    let total = 0;
    let grayOutTotalCol = true;

    for (let pp = 1; pp <= numPriorPops; pp++) {
      const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
      if (methodEligMstIDStr === pisc.yesCVOMstID) {
        let target = 0;

        if (PrEPStatus === pisc.PrEP_NEW_StatusMstID) {
          const PrEP_NEW_Val = piasu.getPrEP_NEW_TargDisag(prepNew, selectedMethodCurrID, dp, pp);
          target = PrEP_NEW_Val;
        } else {
          const PrEP_CT_Val = piasu.getPrEP_CTTargDisag(prepCT, selectedMethodCurrID, dp, pp);
          target = PrEP_CT_Val;
        }

        populationPotentialTotals[pp - 1] += target;

        if (!(selectedPriorityPopulations?.[dp - 1]?.[c - levelCount - 1] ?? true)) {
          target = 0;
        }

        total += target;

        gbtu.setValue(packTable, dp, c, target);

        grayOutTotalCol = false;
      } else {
        gbtu.lockCol(packTable, c, true, true);
        const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
        gbtu.setColBGColor(packTable, c, gainsboroBase10);
      }

      c++;
    }

    if (grayOutTotalCol) {
      gbtu.lockCell(packTable, dp, totalCol, true, true);
      const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
      gbtu.setCellBGColor(packTable, dp, totalCol, gainsboroBase10);
    } else {
      gbtu.setValue(packTable, dp, totalCol, total);
    }
  }

  // Normalize population targets

  for (let populationIndex = 0; populationIndex < numPriorPops; populationIndex++) {
    let populationTotal = 0;

    for (let districtIndex = 0; districtIndex < numDistrictsDP; districtIndex++) {
      const value = gbtu.getValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex);
      if (typeof value !== "number") continue;

      populationTotal += value;
    }

    if (populationTotal > 0) {
      for (let districtIndex = 0; districtIndex < numDistrictsDP; districtIndex++) {
        const target = gbtu.getValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex);
        if (typeof target !== "number") continue;

        const normalizedTarget = target * (populationPotentialTotals[populationIndex] / populationTotal);

        gbtu.setValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex, normalizedTarget);
      }
    }
  }

  for (let districtIndex = 0; districtIndex < numDistrictsDP; districtIndex++) {
    let districtTotal = 0;

    for (let populationIndex = 0; populationIndex < numPriorPops; populationIndex++) {
      const value = gbtu.getValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex);
      if (typeof value !== "number") continue;

      districtTotal += value;
    }

    gbtu.setValue(packTable, districtIndex + 1, priorPopStartCol - 1, districtTotal);
  }

  gbtu.lockPackTable(packTable, true, false);
  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
  gbtu.setRDecs(packTable, 0);
  gbtu.setColWidths(packTable, Theme.dataColWidthMed);
  gbtu.setColWidth(packTable, level1NameCol, Theme.itemNameColWidth);
  // markAGYW
  if (level2Name !== "") {
    if (!isPSEMode) gbtu.setColWidth(packTable, level2NameCol, Theme.itemNameColWidth);
    else gbtu.setColWidth(packTable, level2NameCol, Theme.dataColWidthSmall);
  }
  gbtu.setRowHeight(packTable, firstRow, 50);

  return (
    // @ts-ignore
    <SuperTableShim
      font={Theme.fontFamily}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable, [...repeat("s", levelCount), ...repeat("n", packTable.GBColCount - levelCount)])}
      onPackTableChanged={() => {}}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        fontFamily: Theme.fontFamily,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
    />
  );
};

PITargDisagDistrictPriorPopsResTable.propTypes = {
  modVarObjList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PITargDisagDistrictPriorPopsResTable;
