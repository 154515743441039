import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import Divider from "@material-ui/core/Divider";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import * as pip from "../NonComponents/PIProps";

import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";

import TButton from "../../common/TButton";

import * as php from "../NonComponents/PIHelp";
import { aggregateSessionFiles } from "../../../api/server_calls";

class PIAggregateForm extends Component {
  static propTypes = {
    [pip.countryISO3Str]: PropTypes.string,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,
  };

  static defaultProps = {
    [pip.countryISO3Str]: "",

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.AGYW_FM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      const backPageID = piasu.getPageID(modVarObjArray, pic.aggregateFormOrder, pic.back);
      onPageChangeEvent = () => onPageChange(backPageID);
    } else if (direction === pic.next) {
      const nextPageID = piasu.getPageID(modVarObjArray, pic.aggregateFormOrder, pic.next);
      onPageChangeEvent = () => onPageChange(nextPageID);
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    }
  };

  onResetEventTargetValueAgg = (event) => {
    event.target.value = null;
  };

  onAggFilesBtnClick = () => {
    try {
      this.refs.aggFilesImportUploader.click();
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onReadFilesAgg = (event) => {
    try {
      let files = event.target.files;

      if (files) {
        let fileContentAllFiles = [];
        let fileNames = [];

        let fileReader = new FileReader();

        const readFile = (index) => {
          if (index >= files.length) {
            this.processFilesAgg(fileContentAllFiles, fileNames);
            return;
          }

          let file = files[index];
          fileReader.onload = (fileReaderEvent) => {
            let fileContent = fileReaderEvent.target.result;

            fileContentAllFiles.push(fileContent);
            fileNames.push(file.name);

            readFile(index + 1);
          };

          fileReader.readAsText(file);
        };

        readFile(0);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  processFilesAgg = (fileContentAllFiles, fileNames) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const onPageChange = props[pias.onPageChange];

    /* Make sure we're in aggregate mode first. */
    piasu.setModVarValue(modVarObjArrClone, pisc.appModeMVTag, pisc.aggregateToolMstID);

    onModVarsChange(modVarObjArrClone, false, () => {
      let modVarObjArrAllFiles = [];

      for (let i = 0; i < fileContentAllFiles.length; i++) {
        const fileContents = fileContentAllFiles[i].replace(/REPLACENUMBER/gi, "#");

        let modVarObjArr;
        try {
          modVarObjArr = JSON.parse(fileContents);
          modVarObjArrAllFiles.push(modVarObjArr);
        } catch (e) {
          let dialogObj = pias.getDefaultDialogObj();
          dialogObj[pias.contentStr] = e.toString();
          dialogObj[pias.headerStr] = RS(SC.GB_stError);
          dialogObj[pias.maxWidthStr] = "sm";
          dialogObj[pias.showBool] = true;
          dialogObj[pias.styleObj] = { width: 500 };

          onDialogChange(dialogObj);

          //alert(e.toString());
        }
      }

      aggregateSessionFiles(modVarObjArrAllFiles, fileNames, (response) => {
        if (piu.serverCallSuccessful(response)) {
          /* Special case */

          onModVarsChange(response, false, () => {
            onPageChange(pic.resultsDashboardForm);
          });
        } else {
          piu.notifyUserOfError(response, onDialogChange);
          gbu.safeCallFn(alert(RS(SC.GB_stErrAggFiles)));
        }
      });
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    try {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      //const onModVarsChange = props[pias.onModVarsChange];
      //const onPageChange = props[pias.onPageChange];

      let formHeightStyle = {};

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stAggregate)}
        </p>
      );

      const aggFilesInputDialog = (
        <input
          type={"file"}
          multiple={true}
          accept={".json"}
          ref={"aggFilesImportUploader"}
          style={{
            display: "none",
          }}
          onChange={this.onReadFilesAgg}
          onClick={(event) => this.onResetEventTargetValueAgg(event)}
        />
      );

      const instructionsLab = (
        <p
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAggregateInstructions)}
        </p>
      );

      const aggFilesBtn = (
        <TButton
          caption={RS(SC.GB_stAggregateFiles)}
          key={"aggFilesBtn"}
          onClick={this.onAggFilesBtnClick}
          style={{
            backgroundColor: Theme.PI_TertiaryColor,
            display: "block",
            marginBottom: 20,
            marginLeft: 0,
            width: 200,
          }}
        />
      );

      const backPageID = piasu.getPageID(modVarObjList, pic.aggregateFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.aggregateFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      /* formHeightStyle: Some tables unmount and remount (such as those implementing comboboxes and
               those that change font color). This changes the height of the table, causing the
               AppPage scrollbar to change position. To prevent this, if the table is shown on the screen,
               set the height of the form large enough to cover the entire vertical span of all
               controls on the form. */
      return (
        <div
          style={{
            ...formHeightStyle,
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {aggFilesInputDialog}
          {instructionsLab}
          {aggFilesBtn}

          {navBtnDiv}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIAggregateForm;
