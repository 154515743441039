/* Help (HP) area constants
 *
 *  Key:
 *      FM = form
 *      SD = slide drawer
 *      RD = reference drawer
 *      TB = tab
 *
 * */

export const general_HP = "general_HP";
export const welcome_HP = "welcome_HP";

/*Aggregate */

export const aggregateFM_HP = "aggregateFM_HP";

/* Configuration */

export const configFM_HP = "configFM_HP";
export const config_PriorPopSD_HP = "config_PriorPopSD_HP";
export const config_ContSD_HP = "config_ContSD_HP";
export const config_Cont_DefCurvesTB_HP = "config_Cont_DefCurvesTB_HP";
export const config_Cont_AssCurvesTB_HP = "config_Cont_AssCurvesTB_HP";
export const config_MethodsSD_HP = "config_MethodsSD_HP";
export const config_ProgDataSD_HP = "config_ProgDataSD_HP";
export const config_Cont_ContCurvesSD_HP = "config_Cont_ContCurvesSD_HP";
export const config_Cont_RefRD_HP = "config_Cont_RefRD_HP";
export const config_Cont_CalcRD_HP = "config_Cont_CalcRD_HP";
export const config_VisitSchedules = "config_VisitSchedules";

/* Costs Lite */

export const costsLiteFM_HP = "costsLiteFM_HP";
export const costsLite_AssContVisTB_HP = "costsLite_AssContVisTB_HP";
export const costsLite_FirstTB_HP = costsLite_AssContVisTB_HP;
export const costsLite_AssContVisSchedTB_HP = "costsLite_AssContVisSchedTB_HP";
export const costsLite_CostsTB_HP = "costsLite_CostsTB_HP";
export const costsLite_ResTB_HP = "costsLite_ResTB_HP";
export const costsLite_AssContVis_ContVisSchedSD_HP = "costsLite_AssContVis_ContVisSchedSD_HP";
export const costsLite_DefCostPatRD_HP = "costsLite_DefCostPatRD_HP";
export const costsLite_AvgUnitCostRD_HP = "costsLite_AvgUnitCostRD_HP";

/* Detailed costs */

export const detCostsFM_HP = "detCostsFM_HP";
export const detCosts_SalariesTB_HP = "detCosts_SalariesTB_HP";
export const detCosts_FirstTB_HP = detCosts_SalariesTB_HP;
export const detCosts_LabTestsContVisTB_HP = "detCosts_LabTestsContVisTB_HP";
export const detCosts_DefContVisSchedTB_HP = "detCosts_DefContVisSchedTB_HP";
export const detCosts_AssStratTB_HP = "detCosts_AssStratTB_HP";
export const detCosts_CostsTB_HP = "detCosts_CostsTB_HP";
export const detCosts_ResTB_HP = "detCosts_ResTB_HP";
export const detCosts_DefMinPatRD_HP = "detCosts_DefMinPatRD_HP";
export const detCosts_ServDelivStratSD_HP = "detCosts_ServDelivStratSD_HP";
export const detCosts_PersTypesSD_HP = "detCosts_PersTypesSD_HP";
export const detCosts_LabTestsSD_HP = "detCosts_LabTestsSD_HP";
export const detCosts_ContVisSD_HP = "detCosts_ContVisSD_HP";
export const detCosts_ContVisSchedSD_HP = "detCosts_ContVisSchedSD_HP";
/* AGYW */

export const AGYW_FM_HP = "AGYW_FM_HP";
export const AGYW_ParamsTB_HP = "AGYW_ParamsTB_HP";
export const AGYW_FirstTB_HP = AGYW_ParamsTB_HP;
export const AGYW_InputsByAreaTB_HP = "AGYW_InputsByAreaTB_HP";
export const AGYW_ResTB_HP = "AGYW_ResTB_HP";

/* Impact */

export const impactFM_HP = "impactFM_HP";
export const impact_AssImpFactTB_HP = "impact_AssImpFactTB_HP";
export const impact_FirstTB_HP = impact_AssImpFactTB_HP;
export const impact_ImpactTB_HP = "impact_ImpactTB_HP";
export const impact_DefImpFactRD_HP = "impact_DefImpFactRD_HP";

/* Targets */

export const targFM_HP = "targFM_HP";
export const targ_PopTB_HP = "targ_PopTB_HP";
export const targ_FirstTB_HP = targ_PopTB_HP;
export const targ_DefScaleUpTrendsTB_HP = "targ_DefScaleUpTrendsTB_HP";
export const targ_AssTrendsTB_HP = "targ_AssTrendsTB_HP";
export const targ_OptTB_HP = "targ_OptTB_HP";
export const targ_ResTB_HP = "targ_ResTB_HP";
export const targ_ContRD_HP = "targ_ContRD_HP";
export const targ_ScaleUpRD_HP = "targ_ScaleUpRD_HP";
export const targ_ScaleUpTrendsSD_HP = "targ_ScaleUpTrendsSD_HP";

/* Result Dashboard */
export const resultDashboard_CostTB_HP = "resultDashboard_CostTB_HP";
export const resultDashboard_TargTB_HP = "resultDashboard_TargTB_HP";
export const resultDashboard_FirstTB_HP = resultDashboard_CostTB_HP;

/* Disaggregate targets */

export const disagTargFM_HP = "disagTargFM_HP";
export const disagTarg_DisagSetupTB_HP = "disagTarg_DisagSetupTB_HP";
export const disagTarg_FirstTB_HP = disagTarg_DisagSetupTB_HP;
export const disagTarg_DistPopsTB_HP = "disagTarg_DistPopsTB_HP";
export const disagTarg_ResTB_HP = "disagTarg_ResTB_HP";
export const disagTarg_HIV_AgeSexRD_HP = "disagTarg_HIV_AgeSexRD_HP";

/* Capacity */

export const capacityFM_HP = "capacityFM_HP";

/* Commodities forecasting */

export const commForecFM_HP = "commForecFM_HP";
