import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TAlertDialog from "../../common/TAlertDialog";
import * as gbc from "../../GB/GBConst";
import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as piu from "../NonComponents/PIUtil";

import { generateTypes } from "../../../utilities";

const initCol = 0;
const startAgeColF = 2;
const endAgeColF = 3;
const startAgeColM = 5;
const endAgeColM = 6;
const numCols = 7;

const firstRow = 0;

const colWidth = Theme.dataColWidthSmall;

const comboBoxWidth = colWidth - 10;

const validateSelectedSexes = (data, skipName) => {
  for (const row of data) {
    if (skipName && row[0] === skipName) continue;

    if (!row[1] && !row[4]) {
      return false;
    }
  }

  return true;
};

class PIDisagTargPriorityPopTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},

    [pip.tableKey]: "",
  };

  state = {
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 1,
        [pip.rowEnd]: 1,
        [pip.columnStart]: 2,
        [pip.columnEnd]: 2,
      },
    ],
    [pip.focusedCell]: {
      [pip.rowFocus]: 1,
      [pip.colFocus]: 2,
    },
    alertDialogOpen: false,
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onAgeComboBoxChange = (val, method, priorPopCurrID, c, sex) => {
    try {
      let modVarObjListClone = structuredClone(this.props[pias.modVarObjList]);
      const onModVarsChange = this.props[pias.onModVarsChange];
      const priorPopInclObj = piasu.getModVarValue(modVarObjListClone, pisc.priorPopAgeSexInclMVTag);

      let ageArr = piu.getAgeGroupPPIASArray();
      if (c === startAgeColF || c === startAgeColM) {
        piasu.startAgePPIAS(priorPopInclObj, method, priorPopCurrID, sex, ageArr[val]);

        if (val > ageArr.indexOf(piasu.endAgePPIAS(priorPopInclObj, method, priorPopCurrID, sex))) {
          piasu.endAgePPIAS(priorPopInclObj, method, priorPopCurrID, sex, ageArr[val]);
        }
      } else if (c === endAgeColF || c === endAgeColM) {
        const startAge = piasu.startAgePPIAS(priorPopInclObj, method, priorPopCurrID, sex);
        const ageArrStartAgeIdx = ageArr.indexOf(startAge);
        /* Remove all ages after the start age. */
        const endAgeArr = structuredClone(ageArr).splice(ageArrStartAgeIdx);
        const endAge = endAgeArr[val];
        piasu.endAgePPIAS(priorPopInclObj, method, priorPopCurrID, sex, endAge);
      }

      piasu.setModVarValue(modVarObjListClone, pisc.priorPopAgeSexInclMVTag, priorPopInclObj);
      onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onTableChange = (newPackTable) => {
    try {
      const newPackTableClone = window.structuredCloneReview(newPackTable);

      let modVarObjListClone = structuredClone(this.props[pias.modVarObjList]);

      const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.disagTargSelectedMethodMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const priorPopObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);
      const usingAGYW = piasu.showAGYWTool(modVarObjListClone);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const priorPopInclObj = piasu.getModVarValue(modVarObjListClone, pisc.priorPopAgeSexInclMVTag);

      const agywName = priorPopObjArr.find((v) => v.mstID === pisc.AGYW_PP_MstID)?.name;
      if (!validateSelectedSexes(newPackTable.tableData.value, usingAGYW ? agywName : undefined)) {
        this.setState({ alertDialogOpen: true });

        return;
      }

      let row = 2;
      for (let pp = 1; pp <= numPriorPops; pp++) {
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const priorPopMstID = piasu.getPriorPopMstID(priorPopObjArr, pp);

          if (!usingAGYW || priorPopMstID !== pisc.AGYW_PP_MstID) {
            piasu.includePPIAS(
              priorPopInclObj,
              selectedMethodCurrID,
              pp,
              gbc.sex.female,
              gbtu.getCheckBoxState(newPackTableClone, row, initCol + 1)
            );
            piasu.includePPIAS(
              priorPopInclObj,
              selectedMethodCurrID,
              pp,
              gbc.sex.male,
              gbtu.getCheckBoxState(newPackTableClone, row, initCol + 4)
            );
          }
        }

        row++;
      }

      piasu.setModVarValue(modVarObjListClone, pisc.priorPopAgeSexInclMVTag, priorPopInclObj);

      this.props.onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderAgeComboBox = (packTable, priorPopInclObj, comboBoxObj, r, c, sex, priorPopCurrID) => {
    const modVarObjList = this.props[pias.modVarObjList];
    const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);
    const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

    const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

    if (piasu.includePPIAS(priorPopInclObj, selectedMethodCurrID, priorPopCurrID, sex)) {
      gbtu.setHasComboBox(packTable, r, c + 1, true);
      gbtu.setHasComboBox(packTable, r, c + 2, true);

      comboBoxObj[pitu.style2DObjArray][r][c + 1] = {
        color: Theme.blackColor,
        fontFamily: Theme.fontFamily,
        fontSize: 14,
        width: comboBoxWidth,
      };

      comboBoxObj[pitu.style2DObjArray][r][c + 2] = {
        color: Theme.blackColor,
        fontFamily: Theme.fontFamily,
        fontSize: 14,
        width: comboBoxWidth,
      };

      let ageArr = piu.getAgeGroupPPIASArray();

      let startAgeVal =
        piu.getAgeGroupPPIASCurrID(piasu.startAgePPIAS(priorPopInclObj, selectedMethodCurrID, priorPopCurrID, sex)) - 1;

      let endAges = structuredClone(ageArr).splice(startAgeVal);

      let info1DIntArray = [];
      info1DIntArray.length = pic.numAgeGroupsPPIAS;
      info1DIntArray.fill(pic.numAgeGroupsPPIAS);

      comboBoxObj[pitu.info3DIntArray][r][c + 1] = info1DIntArray;
      comboBoxObj[pitu.info3DIntArray][r][c + 2] = info1DIntArray;

      comboBoxObj[pitu.item3DStrArray][r][c + 1] = ageArr.map((x) => piu.getAgeGroupStartPPIAS(x));
      comboBoxObj[pitu.item3DStrArray][r][c + 2] = endAges.map((x) => piu.getAgeGroupEndPPIAS(x));

      comboBoxObj[pitu.idx2DIntArray][r][c + 1] = startAgeVal;
      comboBoxObj[pitu.idx2DIntArray][r][c + 2] = endAges.indexOf(
        piasu.endAgePPIAS(priorPopInclObj, selectedMethodCurrID, priorPopCurrID, sex)
      );

      comboBoxObj[pitu.onChange2DFuncArray][r][c + 1] = (val) =>
        this.onAgeComboBoxChange(val, selectedMethodCurrID, priorPopCurrID, c + 1, sex);
      comboBoxObj[pitu.onChange2DFuncArray][r][c + 2] = (val) =>
        this.onAgeComboBoxChange(val, selectedMethodCurrID, priorPopCurrID, c + 2, sex);
    }
  };

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      let packTable = gbtu.getNewPackTable();

      const priorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      /* We are going to always show the AGYW priority pop, but if we are showing the AGYW area in the tool, we
               will not allow the user to change the default. */
      const usingAGYWBool = piasu.showAGYWTool(modVarObjList);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);

      const numRows = numPriorPops + 2;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      gbtu.setValue(packTable, firstRow, initCol, RS(SC.GB_stPriorityPop));
      gbtu.setValue(packTable, firstRow, initCol + 1, RS(SC.GB_stFemales));
      gbtu.setValue(packTable, firstRow, initCol + 2, "");
      gbtu.setValue(packTable, firstRow, initCol + 3, "");
      gbtu.setValue(packTable, firstRow, initCol + 4, RS(SC.GB_stMales));
      gbtu.setValue(packTable, firstRow, initCol + 5, "");
      gbtu.setValue(packTable, firstRow, initCol + 6, "");

      gbtu.setValue(packTable, firstRow + 1, initCol, "");
      gbtu.setValue(packTable, firstRow + 1, initCol + 1, RS(SC.GB_stInclQ));
      gbtu.setValue(packTable, firstRow + 1, initCol + 2, RS(SC.GB_stStartAge));
      gbtu.setValue(packTable, firstRow + 1, initCol + 3, RS(SC.GB_stEndAge));
      gbtu.setValue(packTable, firstRow + 1, initCol + 4, RS(SC.GB_stInclQ));
      gbtu.setValue(packTable, firstRow + 1, initCol + 5, RS(SC.GB_stStartAge));
      gbtu.setValue(packTable, firstRow + 1, initCol + 6, RS(SC.GB_stEndAge));

      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      let selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const priorPopInclObj = piasu.getModVarValue(modVarObjList, pisc.priorPopAgeSexInclMVTag);

      let fOnly = [pisc.AGYW_PP_MstID, pisc.FSW_PP_MstID];
      let mOnly = [pisc.MSM_PP_MstID];

      if (selectedMethodMstID === pisc.ringsMethodMstID) {
        fOnly.push(pisc.SDC_PP_MstID);
      }

      let row = 2;
      for (let pp = 1; pp <= numPriorPops; pp++) {
        const priorPopMstID = piasu.getPriorPopMstID(priorPopObjArr, pp);

        const priorPopName = piasu.getPriorPopName(priorPopObjArr, pp);
        gbtu.setValue(packTable, row, initCol, priorPopName);

        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          if (!mOnly.includes(priorPopMstID)) {
            if (!usingAGYWBool || priorPopMstID !== pisc.AGYW_PP_MstID) {
              this.renderAgeComboBox(packTable, priorPopInclObj, comboBoxObj, row, 1, gbc.sex.female, pp);
              gbtu.setHasCheckBox(packTable, row, initCol + 1, true);
              gbtu.setCheckBoxState(
                packTable,
                row,
                initCol + 1,
                piasu.includePPIAS(priorPopInclObj, selectedMethodCurrID, pp, gbc.sex.female)
              );
            } else {
              gbtu.setValue(packTable, row, 2, piu.getAgeGroupStartPPIAS(pisc.age15t19PPIASMstID)); //piasu.startAgePPIAS(priorPopInclObj, selectedMethodCurrID, pp, gbc.sex.female)));
              gbtu.setValue(packTable, row, 3, piu.getAgeGroupEndPPIAS(pisc.age20t24PPIASMstID)); //piasu.endAgePPIAS(priorPopInclObj, selectedMethodCurrID, pp, gbc.sex.female)));
              gbtu.setValue(packTable, row, initCol + 1, "yes");
            }
            gbtu.setAlignment(packTable, row, initCol + 1, gbtc.hAlign.center);
          }

          if (!fOnly.includes(priorPopMstID)) {
            if (!usingAGYWBool || priorPopMstID !== pisc.AGYW_PP_MstID) {
              this.renderAgeComboBox(packTable, priorPopInclObj, comboBoxObj, row, 4, gbc.sex.male, pp);
              gbtu.setHasCheckBox(packTable, row, initCol + 4, true);
              gbtu.setCheckBoxState(
                packTable,
                row,
                initCol + 4,
                piasu.includePPIAS(priorPopInclObj, selectedMethodCurrID, pp, gbc.sex.male)
              );
            } else {
              gbtu.setValue(packTable, row, 5, piu.getAgeGroupStartPPIAS(pisc.age15t19PPIASMstID)); //piasu.startAgePPIAS(priorPopInclObj, selectedMethodCurrID, pp, gbc.sex.male)));
              gbtu.setValue(packTable, row, 6, piu.getAgeGroupEndPPIAS(pisc.age20t24PPIASMstID)); //piasu.endAgePPIAS(priorPopInclObj, selectedMethodCurrID, pp, gbc.sex.male)));
              gbtu.setValue(packTable, row, initCol + 4, "yes");
            }
            gbtu.setAlignment(packTable, row, initCol + 4, gbtc.hAlign.center);
          }
        } else {
          gbtu.lockCells(packTable, row, true, true);
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setRowBGColor(packTable, row, gainsboroBase10);
        }

        row++;
      }

      gbtu.lockPackTable(packTable, true, false);

      gbtu.setColWidths(packTable, Theme.dataColWidthSmall + 10);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setRDecByCol(packTable, initCol + 2, 1);
      gbtu.setRDecByCol(packTable, initCol + 4, 1);
      gbtu.setRowHeight(packTable, firstRow, 100);
      gbtu.setFixedRows(packTable, 2);

      gbtu.mergeCells(packTable, 0, 1, 1, 3);
      gbtu.mergeCells(packTable, 0, 4, 1, 3);

      gbtu.setMaxAllowedValByCol(packTable, initCol + 1, 0);

      gbtu.setMinAllowedValByCol(packTable, initCol + 2, 0);
      gbtu.setMaxAllowedValByCol(packTable, initCol + 2, 100);
      gbtu.setMaxAllowedValByCol(packTable, initCol + 6, gbtc.maxInt);
      gbtu.setMaxAllowedValByCol(packTable, initCol + 8, gbtc.maxInt);

      gbtu.setColWidth(packTable, initCol, Theme.itemNameColWidthWide);
      gbtu.setColWidth(packTable, initCol + 1, colWidth);
      gbtu.setColWidth(packTable, initCol + 2, comboBoxWidth + 10);
      gbtu.setColWidth(packTable, initCol + 3, comboBoxWidth + 10);
      gbtu.setColWidth(packTable, initCol + 5, comboBoxWidth + 10);
      gbtu.setColWidth(packTable, initCol + 6, comboBoxWidth + 10);

      if (window.DebugMode) {
        console.log("Component: PIDisagTargPriorityPopTable");
        console.log("ModVar(s):");
        console.log(pisc.priorPopAgeSexInclMVTag + ": ");
        console.log(priorPopInclObj);
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      const stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={this.state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, ["s", "cb", "dd", "dd", "cb", "dd", "dd"])}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={this.state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 30,
            marginRight: 20,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIDisagTargPriorityPopTable");
  };

  render() {
    return (
      <>
        {this.renderTable()}
        <TAlertDialog
          open={this.state.alertDialogOpen}
          title={RS(SC.GB_stError)}
          content={RS(SC.GB_stSexSelectionConstraint)}
          onClose={() => {
            this.setState({ alertDialogOpen: false });
          }}
        />
      </>
    );
  }
}

export default PIDisagTargPriorityPopTable;
