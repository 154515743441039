import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

const TTabs2 = ({
  tabBackgroundColor = "default",
  tabTitles = ["Tab 1", "Tab 2", "Tab 3"],
  tabContents = ["Content 1", "Content 2", "Content 3"],
  classes = {},
  selectedTabIdx = 0,
  onChange = () => console.log("onChange"),
  tabBarOutline,
  style = {},
}) => {
  const appBarStyle = {
    marginBottom: 20,
  };

  if (typeof tabBarOutline !== "undefined") {
    appBarStyle.boxShadow = tabBarOutline;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color={tabBackgroundColor} style={{ ...appBarStyle, ...style }}>
        <Tabs
          value={selectedTabIdx}
          onChange={(event, value) => {
            onChange(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabTitles.map((title, i) => (
            <Tab style={{ textTransform: "none" }} label={title} key={i} />
          ))}
        </Tabs>
      </AppBar>
      {tabContents[selectedTabIdx]}
    </div>
  );
};

TTabs2.propTypes = {
  tabBackgroundColor: PropTypes.string,
  tabTitles: PropTypes.arrayOf(PropTypes.string),
  tabContents: PropTypes.arrayOf(PropTypes.any),
  classes: PropTypes.object.isRequired,
  selectedTabIdx: PropTypes.number,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

export default withStyles(styles)(TTabs2);
