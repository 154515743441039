/**
 * Method ModVar related helpers.
 */
import { v4 as uuidv4 } from "uuid";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as piu from "../PIUtil";
import * as pisc from "../PIServerConst";
import * as pic from "../PIConst";
import * as gbu from "../../../GB/GBUtil";

import { getModVarValue, setModVarValue } from "./PICommon";

// REVIEW: Dependancy on ASU, relocate to more appropriate modules.
import {
  getItemObj,
  getContCurveCurrID,
  getContCurveMstID,
  getTotalNumPriorPops,
  getProgDataPeriodObj,
  getProgDataStartMonth,
  getProgDataStartYear,
  getProgDataEndMonth,
  getProgDataEndYear,
  getPriorPopMstID,
  getPriorPopCurrID,
  getPriorPopCustom,
  getPriorPopContCurveMstID,
  getInitiationMethodObj,
  getCostCatLiteMethodObj,
  setCostCategoriesLiteValues,
  setPriorPopContCurveMstID,
} from "../PIAppStateUtil";

/* Also sets unit of commodity field, since it's in the same object. */
export function setDefMethodNames(methodObjList) {
  const numMethods = getTotalNumMethods(methodObjList);
  for (let i = 1; i <= numMethods; i++) {
    const methodMstIDV = methodMstID(methodObjList, i);
    const methodNameV = methodName(methodObjList, i);

    if (methodNameV === "") {
      methodName(methodObjList, i, piu.getDefMethodNameFromMstID(methodMstIDV));
      //methodUnitComm(methodObjList, i, piu.getDefMethodUnitOfCommodityFromMstID(methodMstIDV));
    }

    const unitCommV = methodUnitComm(methodObjList, i);

    if (unitCommV === "") {
      methodUnitComm(methodObjList, i, piu.getDefMethodUnitOfCommodityFromMstID(methodMstIDV));
    }
  }
}

export function setMethodName(methodObjList, methodCurrID, value) {
  methodObjList[methodCurrID - 1][pisc.methodName] = value;
}

export function getDefMethodActive(methodObjList, methodMstIDStr) {
  // Default methods are active if they exist in the method object list.
  return methodObjList.some((m) => m.mstID === methodMstIDStr);
}

export function setDefMethodActive(modVarObjList, origModVarObjArr, methodMstIDStr, valueBool) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  const alreadyActive = getDefMethodActive(methodObjList, methodMstIDStr);
  const totalNumMethods = getTotalNumMethods(methodObjList);

  /* If we are activating the method, add an object to the object list (as long
       as the object does not already exist there). */
  if (valueBool) {
    /* Note: Custom methods should always be added after all default ones. */
    if (!alreadyActive) {
      const origMethodsObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
      let itemObj = structuredClone(getItemObj(origMethodsObjArr, pic.methodItems, methodMstIDStr, ""));
      itemObj[pisc.methodMstID] = methodMstIDStr;
      itemObj[pisc.methodName] = piu.getDefMethodNameFromMstID(methodMstIDStr);
      itemObj[pisc.methodUnitComm] = piu.getDefMethodUnitOfCommodityFromMstID(methodMstIDStr);

      //const numCustomMethods = getNumCustomMethods(methodObjList);
      const numActiveDefMethods = getNumActiveDefMethods(methodObjList);

      /* If there are no methods, just push the default one onto the array. */
      if (totalNumMethods === 0) {
        methodObjList.push(itemObj);
        /* Add 1 since we just changed the number of method objects. */
        shiftMethods(modVarObjList, origModVarObjArr, totalNumMethods + 1, pic.addItem);
      } else if (numActiveDefMethods === 0) {
        /* Otherwise, if there are no active default methods, add the
               default one to the front of the array. */
        methodObjList.splice(0, 0, itemObj);
        shiftMethods(modVarObjList, origModVarObjArr, 1, pic.addItem);
      } else {
        /* Otherwise, there's at least one default method. Place the default pop we are
               activating just before the first default method we encounter with a
               higher current ID. */
        const defMethodCurrID = piu.getDefMethodCurrID(methodMstIDStr);

        let stop = false;
        let i = 0;

        while (i < totalNumMethods && !stop) {
          const methodMstIDLoop = methodMstID(methodObjList, i + 1);

          if (!methodMstIDLoop.includes(pisc.customItemMstID)) {
            const defMethodCurrIDLoop = piu.getDefMethodCurrID(methodMstIDLoop);

            if (defMethodCurrID < defMethodCurrIDLoop) {
              methodObjList.splice(i, 0, itemObj);
              shiftMethods(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
              stop = true;
            }
          } else {
            /* Otherwise, we hit a custom method. If we're activating the last
                       default method, we won't find another default one with a higher
                       current ID. In this case, place it before the first custom method we encounter. */
            methodObjList.splice(i, 0, itemObj);
            shiftMethods(modVarObjList, origModVarObjArr, i + 1, pic.addItem);
            stop = true;
          }

          i++;
        }

        /* If we didn't add the default method yet, we must be adding the last one and there
                   must not be any custom methods.*/
        if (!stop) {
          methodObjList.push(itemObj);
          /* Add 1 since we just changed the number of method objects. */
          shiftMethods(modVarObjList, origModVarObjArr, totalNumMethods + 1, pic.addItem);
        }
      }
    }

    /* Adjust the continuation curves in the priority pop objects in case the user is re-checking a method.*/
    let priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);
    const numPriorPops = getTotalNumPriorPops(priorPopObjArr);
    //const defMethodCurrID = piu.getDefMethodCurrID(methodMstIDStr);
    const methodCurrID = getMethodCurrID(methodObjList, methodMstIDStr);
    let contCurveObjArr = getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);

    for (let pp = 1; pp <= numPriorPops; pp++) {
      const contCurveMstID = getPriorPopContCurveMstID(priorPopObjArr, methodCurrID, pp);
      const contCurveCurrID = getContCurveCurrID(contCurveObjArr, contCurveMstID);

      if (contCurveCurrID === pic.itemDoesNotExist) {
        const newContCurveMstIDStr = getContCurveMstID(contCurveObjArr, 1);
        setPriorPopContCurveMstID(priorPopObjArr, methodCurrID, pp, newContCurveMstIDStr);
      }
    }

    /* In case the user deleted all methods (either by unchecking them or using the Clear button)
           and is adding one again, we need to set the selected method master ID to something valid again. */
    if (getModVarValue(modVarObjList, pisc.selectedMethodMVTag) === pic.noMstID) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.selectedMethodMVTag, newSelectedMethodMstIDStr);
    }

    if (getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag) === pic.noMstID) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, newSelectedMethodMstIDStr);
    }

    setCostCategoriesLiteValues(modVarObjList, new Set([methodMstIDStr]));
  } else {
    /* Otherwise, we are deactivating it. If the object exists, remove it. */
    let i = 0;
    let stop = false;
    while (i < totalNumMethods && !stop) {
      let obj = methodObjList[i];

      if (obj[pisc.methodMstID] === methodMstIDStr) {
        methodObjList.splice(i, 1);
        shiftMethods(modVarObjList, origModVarObjArr, i + 1, pic.deleteItem);
        stop = true;
      }

      i++;
    }

    const selectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

    /* If the selected method is the one we deactivated and there are any other
           methods left, set the selected method to the first method. */
    if (methodMstIDStr === selectedMethodMstIDStr && methodObjList.length > 0) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.selectedMethodMVTag, newSelectedMethodMstIDStr);
    } else if (methodObjList.length === 0) {
      /* If there are no methods left, set the master ID to no master ID. We'll set it
           appropriately when we force the user to select at least one method on Add. */
      setModVarValue(modVarObjList, pisc.selectedMethodMVTag, pic.noMstID);
    }

    const disagTargSelectedMethodMstIDStr = getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag);

    if (methodMstIDStr === disagTargSelectedMethodMstIDStr && methodObjList.length > 0) {
      const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
      setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, newSelectedMethodMstIDStr);
    } else if (methodObjList.length === 0) {
      setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, pic.noMstID);
    }
  }
}

/* Default and custom method objects are stored in the same list. Custom ones
   will be marked with the "CUSTOM" master ID. */
export function methodName(methodObjArr, methodCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodName] = valueStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodName];
  }

  return value;
}

export function methodMstID(methodObjArr, methodCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodMstID] = valueStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodMstID];
  }

  return value;
}

export function methodUnitComm(methodObjArr, methodCurrID, valueStr) {
  let value;

  if (typeof valueStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodUnitComm] = valueStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodUnitComm];
  }

  return value;
}

export function monthsCovPerUnit(methodObjArr, methodCurrID, valueInt) {
  let value;

  if (typeof valueInt !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodsMonthsCovPerUnit] = valueInt;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodsMonthsCovPerUnit];
  }

  return value;
}

export function methodUnitCost(methodObjArr, methodCurrID, mstIDStr) {
  let value;

  if (typeof mstIDStr !== "undefined") {
    methodObjArr[methodCurrID - 1][pisc.methodUnitCost] = mstIDStr;
  } else {
    value = methodObjArr[methodCurrID - 1][pisc.methodUnitCost];
  }

  return value;
}

export function getMethodNames(methodObjList) {
  let names = [];

  const numMethods = getTotalNumMethods(methodObjList);
  for (let pp = 1; pp <= numMethods; pp++) {
    names.push(methodName(methodObjList, pp));
  }

  return names;
}

export function getMethodCustom(methodObjList, currID) {
  return methodObjList[currID - 1][pisc.methodMstID].includes(pisc.customItemMstID);
}

export function getTotalNumMethods(methodObjList) {
  return methodObjList.length;
}

export function getMethodCurrID(methodObjList, methodMstIDStr) {
  const numMethods = getTotalNumMethods(methodObjList);

  let currIDInt = 1;
  let stop = false;
  while (currIDInt <= numMethods && !stop) {
    const mstID = methodMstID(methodObjList, currIDInt);

    if (mstID === methodMstIDStr) {
      stop = true;
    } else {
      currIDInt++;
    }
  }

  if (stop) {
    return currIDInt;
  } else {
    return pic.itemDoesNotExist;
  }
}

export function getMethodCurrIDArray(methodObjList) {
  let currID1DIntArray = [];

  const numMethods = getTotalNumMethods(methodObjList);
  for (let pp = 1; pp <= numMethods; pp++) {
    currID1DIntArray.push(pp);
  }

  return currID1DIntArray;
}

export function getNumActiveDefMethods(methodObjList) {
  let numActiveDefMethods = 0;

  for (let i = 0; i < methodObjList.length; i++) {
    let obj = methodObjList[i];

    if (!obj[pisc.methodMstID].includes(pisc.customItemMstID)) {
      numActiveDefMethods++;
    }
  }

  return numActiveDefMethods;
}

export function getNumCustomMethods(methodObjList) {
  let numCustomMethods = 0;

  for (let i = 0; i < methodObjList.length; i++) {
    let obj = methodObjList[i];

    if (obj[pisc.methodMstID].includes(pisc.customItemMstID)) {
      numCustomMethods++;
    }
  }

  return numCustomMethods;
}

export function getCustomMethodsCurrIDArray(methodObjList) {
  let customMethodCurrID1DIntArray = [];

  const totalNumMethods = getTotalNumMethods(methodObjList);

  for (let i = 1; i <= totalNumMethods; i++) {
    const customBool = getMethodCustom(methodObjList, i);

    if (customBool) {
      customMethodCurrID1DIntArray.push(i);
    }
  }

  return customMethodCurrID1DIntArray;
}

export function addCustomMethod(modVarObjList, origModVarObjArr, itemToAddAfterCurrID) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  const methodNum = (getNumCustomMethods(methodObjList) + 1).toString();

  const origMethodObjList = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
  /* The original ModVar object array is cloned when the user clicks Add, but we need to
       clone again here because we'll be passing it along again and we want to pass the
       unadulterated version. */
  let itemObj = structuredClone(getItemObj(origMethodObjList, pic.methodItems, pisc.pillMethodMstID, ""));
  itemObj[pisc.methodMstID] = `${pisc.customItemMstID}-${uuidv4()}`;
  itemObj[pisc.methodName] = RS(SC.GB_stCustomMethod) + " " + methodNum;
  itemObj[pisc.methodUnitComm] = RS(SC.GB_stSpecifyUnit);
  itemObj[pisc.methodUnitCost] = 0.0;

  if (typeof itemToAddAfterCurrID !== "undefined") {
    methodObjList.splice(itemToAddAfterCurrID, 0, itemObj);
    shiftMethods(modVarObjList, origModVarObjArr, itemToAddAfterCurrID + 1, pic.addItem);
  } else {
    methodObjList.push(itemObj);
    const totalNumMethods = getTotalNumMethods(methodObjList);
    shiftMethods(modVarObjList, origModVarObjArr, totalNumMethods, pic.addItem);
  }

  /* In case the user deleted all methods and is adding one again, we need to set
       the selected method master ID to something valid again. */
  if (getModVarValue(modVarObjList, pisc.selectedMethodMVTag) === pic.noMstID) {
    const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
    setModVarValue(modVarObjList, pisc.selectedMethodMVTag, newSelectedMethodMstIDStr);
  }

  if (getModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag) === pic.noMstID) {
    const newSelectedMethodMstIDStr = methodMstID(methodObjList, 1);
    setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, newSelectedMethodMstIDStr);
  }
}

export function deleteCustomMethods(modVarObjList, origModVarObjArr, methodCurrID1DIntArray) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  for (let i = methodCurrID1DIntArray.length; i >= 1; i--) {
    methodObjList.splice(methodCurrID1DIntArray[i - 1] - 1, 1);
    shiftMethods(modVarObjList, origModVarObjArr, methodCurrID1DIntArray[i - 1], pic.deleteItem);
  }

  if (methodObjList.length === 0) {
    setModVarValue(modVarObjList, pisc.selectedMethodMVTag, pic.noMstID);
    setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, pic.noMstID);
  }
}

export function moveCustomMethods(modVarObjList, origModVarObjArr, methodCurrID1DIntArray, direction) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  /* Instead of moving the method(s) we want to move, move the one above/below them.
       If moving the method block down, put the method immediately after the block
       before the first method in block. If moving the method block up, put the
       method immediately below the block after the last Methodin the block.

       Down in this case means towards the bottom of the screen and up means towards the top.

       Assume that default method always come before custom ones. */

  const customMethodsCurrIDArray = getCustomMethodsCurrIDArray(methodObjList);

  const firstMethodInBlockCurrID = methodCurrID1DIntArray[0];
  const lastMethodInBlockCurrID = methodCurrID1DIntArray[methodCurrID1DIntArray.length - 1];

  /* If moving the method(s) down in the list and there's another custom priority
       pop after it, move that custom method before it/them.
     */
  if (
    direction === pic.moveDown &&
    lastMethodInBlockCurrID !== customMethodsCurrIDArray[customMethodsCurrIDArray.length - 1]
  ) {
    const methodObjArray = methodObjList.splice(lastMethodInBlockCurrID + 1 - 1, 1);
    methodObjList.splice(firstMethodInBlockCurrID - 1, 0, methodObjArray[0]);
    shiftMethods(modVarObjList, origModVarObjArr, firstMethodInBlockCurrID, pic.moveItem, lastMethodInBlockCurrID + 1);
  } else if (direction === pic.moveUp && firstMethodInBlockCurrID !== customMethodsCurrIDArray[0]) {
    const methodObjArray = methodObjList.splice(firstMethodInBlockCurrID - 1 - 1, 1);
    /* Careful; since we removed the indiactor below the block, the current IDs of all
           indicators in the block will be decreased by one now. So, it's - 1 because the
           indicators' current IDs shifted down 1, - 1 because the current ID is one more than
           the index of the object list, and +1 to put the removed indicator after the block. */
    methodObjList.splice(lastMethodInBlockCurrID - 1 - 1 + 1, 0, methodObjArray[0]);
    shiftMethods(modVarObjList, origModVarObjArr, firstMethodInBlockCurrID, pic.moveItem, firstMethodInBlockCurrID - 1);
  }
}

export function clearMethods(modVarObjList, origModVarObjArr) {
  let methodObjList = getModVarValue(modVarObjList, pisc.methodsMVTag);

  for (let i = methodObjList.length; i >= 1; i--) {
    const methodMstIDStr = methodMstID(methodObjList, i);
    const enabledBool = piu.getDefMethodEnabled(methodMstIDStr);

    /* Don't remove items that cannot be unchecked. */
    if (enabledBool) {
      shiftMethods(modVarObjList, origModVarObjArr, i, pic.deleteItem);
      methodObjList.splice(i - 1, 1);
    }
  }

  setModVarValue(modVarObjList, pisc.selectedMethodMVTag, pic.noMstID);
  setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, pic.noMstID);
}

/* Shifts methods for all applicable data structures when they are (de)activated,
   added, or deleted. After calling this, all data that varies by method
   should be in the same order as the method objects themselves. */
export function shiftMethods(modVarObjList, origModVarObjArr, methodCurrID, instructionInt, methodToMoveCurrID) {
  let modVarValues = [];
  /* Used to clone the first item on Add for results in a loop. */
  let origModVarResultValues = [];

  /* Results are separated from inputs because input values need to get reloaded if
          possible whereas results are calculated.  Inputs require more work on the
          client because we need to search (and possibly modify) the original ModVars to
          reload the default values. Results can just be recalculated.

          In general, add the input/result to modVarValues if the first dimension is an array
          by method. It will be added/deleted/moved in a loop with most of the other ModVars. Input
          ModVars have a more complicated add, however.
      */

  /* Inputs */

  let selectedMethods1DBoolArr = getModVarValue(modVarObjList, pisc.targSelectedMethodsMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(selectedMethods1DBoolArr);
  }

  let potUsersToTakePrEPObjArray = getModVarValue(modVarObjList, pisc.coverageByPriorityPopMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(potUsersToTakePrEPObjArray);
  }

  /* Sort of a result / input combo. */
  let actUsersToTakePrEPObjArray = getModVarValue(modVarObjList, pisc.covConstrActualMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(actUsersToTakePrEPObjArray);
  }

  let covConstUnitsDispObjArr = getModVarValue(modVarObjList, pisc.covConstUnitsDispMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(covConstUnitsDispObjArr);
  }

  // let potUsersToTakePrEPConstrObjArray = getModVarValue(modVarObjList, pisc.covConstrByPriorityPopMVTag);
  // if (instructionInt !== pic.addItem) {
  //     modVarValues.push(potUsersToTakePrEPConstrObjArray);
  // }

  let targClientsInitObjArray = getModVarValue(modVarObjList, pisc.targetsByPriorityPopMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(targClientsInitObjArray);
  }

  // markA
  let adjFactor2DFltArr = getModVarValue(modVarObjList, pisc.adjustmentFactorMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(adjFactor2DFltArr);
  }

  let costCatLiteObjList = getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costCatLiteObjList);
  }

  let costPerVisitObjArr = getModVarValue(modVarObjList, pisc.defCostsPerVisitLiteMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costPerVisitObjArr);
  }

  let costPerVisitRatioObjArr = getModVarValue(modVarObjList, pisc.costPerVisitRatiosMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(costPerVisitRatioObjArr);
  }

  let impactEffectiveness1DIntArr = getModVarValue(modVarObjList, pisc.impactEffectivenessMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(impactEffectiveness1DIntArr);
  }

  let drugFore2DObjArr = getModVarValue(modVarObjList, pisc.drugForecastTableMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(drugFore2DObjArr);
  }

  let priorPopInclObjArr = getModVarValue(modVarObjList, pisc.priorPopAgeSexInclMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(priorPopInclObjArr);
  }

  let priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

  // markElig
  let priorPopMethodEligObjArr = getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);
  if (instructionInt !== pic.addItem) {
    modVarValues.push(priorPopMethodEligObjArr);
  }

  /* Results */

  let initByMonth2DIntArr = getModVarValue(modVarObjList, pisc.initByMonthMVTag);
  modVarValues.push(initByMonth2DIntArr);
  let origInitByMonth2DIntArr = getModVarValue(origModVarObjArr, pisc.initByMonthMVTag);
  origModVarResultValues.push(origInitByMonth2DIntArr);

  let currOnPrEPByMonth2DIntArr = getModVarValue(modVarObjList, pisc.currPrEPByMonthMVTag);
  modVarValues.push(currOnPrEPByMonth2DIntArr);
  let origCurrOnPrEPByMonth2DIntArr = getModVarValue(origModVarObjArr, pisc.currPrEPByMonthMVTag);
  origModVarResultValues.push(origCurrOnPrEPByMonth2DIntArr);

  let totalCosts2DIntArray = getModVarValue(modVarObjList, pisc.costsByPopTypeMVTag);
  modVarValues.push(totalCosts2DIntArray);
  let origTotalCosts2DIntArray = getModVarValue(origModVarObjArr, pisc.costsByPopTypeMVTag);
  origModVarResultValues.push(origTotalCosts2DIntArray);

  let totalInfAvtdConstantCoverage2DIntArray = getModVarValue(
    modVarObjList,
    pisc.infAvertedByPopTypeConstantCoverageMVTag
  );
  modVarValues.push(totalInfAvtdConstantCoverage2DIntArray);
  let origTotalInfAvtdConstantCoverage2DIntArray = getModVarValue(
    origModVarObjArr,
    pisc.infAvertedByPopTypeConstantCoverageMVTag
  );
  origModVarResultValues.push(origTotalInfAvtdConstantCoverage2DIntArray);

  let totalInits2DIntArray = getModVarValue(modVarObjList, pisc.initByPopTypeMVTag);
  modVarValues.push(totalInits2DIntArray);
  let origTotalInits2DIntArray = getModVarValue(origModVarObjArr, pisc.initByPopTypeMVTag);
  origModVarResultValues.push(origTotalInits2DIntArray);

  let costStayOnPrEPPP2DFltArr = getModVarValue(modVarObjList, pisc.costStayOnPrEPPTMVTag);
  modVarValues.push(costStayOnPrEPPP2DFltArr);
  let origCostStayOnPrEPPP2DFltArr = getModVarValue(origModVarObjArr, pisc.costStayOnPrEPPTMVTag);
  origModVarResultValues.push(origCostStayOnPrEPPP2DFltArr);

  let avgCostPrEPByMonthPP3DFltArr = getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthPTMVTag);
  modVarValues.push(avgCostPrEPByMonthPP3DFltArr);
  let origAvgCostPrEPByMonthPP3DFltArr = getModVarValue(origModVarObjArr, pisc.avgCostPrEPByMonthPTMVTag);
  origModVarResultValues.push(origAvgCostPrEPByMonthPP3DFltArr);

  let infAvtdConstantCoverage1DFltArr = getModVarValue(modVarObjList, pisc.impactInfAvtdConstantCoverageMVTag);
  modVarValues.push(infAvtdConstantCoverage1DFltArr);
  let origInfAvtdConstantCoverage1DFltArr = getModVarValue(origModVarObjArr, pisc.impactInfAvtdConstantCoverageMVTag);
  origModVarResultValues.push(origInfAvtdConstantCoverage1DFltArr);

  let infAvtdPerPersonYrPrEPConstantCoverage1DFltArr = getModVarValue(
    modVarObjList,
    pisc.adjInfAvtdConstantCoverageMVTag
  );
  modVarValues.push(infAvtdPerPersonYrPrEPConstantCoverage1DFltArr);
  let origInfAvtdPerPersonYrPrEPConstantCoverage1DFltArr = getModVarValue(
    origModVarObjArr,
    pisc.adjInfAvtdConstantCoverageMVTag
  );
  origModVarResultValues.push(origInfAvtdPerPersonYrPrEPConstantCoverage1DFltArr);

  let persYrsPrEPAvtOneInfectConstantCoverage1DFltArr = getModVarValue(
    modVarObjList,
    pisc.persYrsPrEPAvtOneInfectConstantCoverageMVTag
  );
  modVarValues.push(persYrsPrEPAvtOneInfectConstantCoverage1DFltArr);
  let origPersYrsPrEPAvtOneInfectConstantCoverage1DFltArr = getModVarValue(
    origModVarObjArr,
    pisc.persYrsPrEPAvtOneInfectConstantCoverageMVTag
  );
  origModVarResultValues.push(origPersYrsPrEPAvtOneInfectConstantCoverage1DFltArr);

  let targIndObj = getModVarValue(modVarObjList, pisc.targIndTableMVTag);

  let initPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_NEW_MVTag);
  modVarValues.push(initPrEPTargDisag3DFltArr);
  let origInitPrEPTargDisag3DFltArr = getModVarValue(origModVarObjArr, pisc.targDisagDistPopPrEP_NEW_MVTag);
  origModVarResultValues.push(origInitPrEPTargDisag3DFltArr);

  let onPrEPTargDisag3DFltArr = getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_CT_MVTag);
  modVarValues.push(onPrEPTargDisag3DFltArr);
  let origOnPrEPTargDisag3DFltArr = getModVarValue(origModVarObjArr, pisc.targDisagDistPopPrEP_CT_MVTag);
  origModVarResultValues.push(origOnPrEPTargDisag3DFltArr);

  let costPerPersonInitPP2DFltArr = getModVarValue(modVarObjList, pisc.costPerPersonInitPTMVTag);
  modVarValues.push(costPerPersonInitPP2DFltArr);
  let origCostPerPersonInitPP2DFltArr = getModVarValue(origModVarObjArr, pisc.costPerPersonInitPTMVTag);
  origModVarResultValues.push(origCostPerPersonInitPP2DFltArr);

  // Special case
  let initiationObj = getModVarValue(modVarObjList, pisc.initiationMVTag);
  // modVarValues.push(initiationObj);
  // let origInitiationObj = getModVarValue(origModVarObjArr, pisc.initiationMVTag);
  // origModVarResultValues.push(origInitiationObj);

  /***************************************************************************************
   *
   *    Add
   *
   ***************************************************************************************/

  if (instructionInt === pic.addItem) {
    /* ModVars we need */

    const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
    const priorPopObjArr = getModVarValue(modVarObjList, pisc.priorPopsMVTag);

    /* Stuff we need from ModVars */

    const methodMstIDV = methodMstID(methodObjArr, methodCurrID);

    /* Original ModVars we need. */

    const origMethodObjArr = getModVarValue(origModVarObjArr, pisc.methodsMVTag);
    const origPriorPopObjArr = getModVarValue(origModVarObjArr, pisc.priorPopsMVTag);

    /* Stuff we need from original ModVars. */

    let origMethodCurrID = getMethodCurrID(origMethodObjArr, methodMstIDV);

    /* If the original method cannot be found, then we are adding a custom method.
              In this case, we'll copy the first method and change the method master ID to the
              custom one where applicable. */
    let newCustomItem = false;
    let origMethodMstID = methodMstIDV;
    if (origMethodCurrID === pic.itemDoesNotExist) {
      newCustomItem = true;
      origMethodCurrID = 1;
      origMethodMstID = methodMstID(origMethodObjArr, origMethodCurrID);
    }

    const progDataSettingPeriodObj = getProgDataPeriodObj(modVarObjList);

    const numProgDataMonths = piu.getMonthsBetween(
      getProgDataStartMonth(progDataSettingPeriodObj),
      getProgDataStartYear(progDataSettingPeriodObj),
      getProgDataEndMonth(progDataSettingPeriodObj),
      getProgDataEndYear(progDataSettingPeriodObj)
    );

    /* General approach below:
     *
     *  1. Grab ModVar that needs to be modified as well as its original version.
     *  2. If modified and original ModVars both exist, clone original one and set
     *     it to modified one.
     *  3. If adding a custom method and ModVar has a method master ID field, then
     *     ensure that the field has the custom method's master ID instead of the
     *     default ModVar we cloned.
     *
     * */

    /* Inputs */

    gbu.safeModify(selectedMethods1DBoolArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.targSelectedMethodsMVTag);

      gbu.safeModify(origVal, () => {
        selectedMethods1DBoolArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));
      });
    });

    gbu.safeModify(potUsersToTakePrEPObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.coverageByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = structuredClone(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customVal = structuredClone(methodObj[pisc.coveragePU][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.coveragePU].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.coveragePU].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.coveragePU].push(customVal);
          }
        }

        potUsersToTakePrEPObjArray.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          potUsersToTakePrEPObjArray[methodCurrID - 1][pisc.methodMstIDPU] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(actUsersToTakePrEPObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.covConstrActualMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = structuredClone(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customVal = structuredClone(methodObj[pisc.coverageTSP_AU][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.coverageTSP_AU].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.coverageTSP_AU].splice(pp, 1);
            methodObj[pisc.coverageDRD_AU].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.coverageTSP_AU].push(customVal);
            methodObj[pisc.coverageDRD_AU].push(customVal);
          }
        }

        actUsersToTakePrEPObjArray.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          actUsersToTakePrEPObjArray[methodCurrID - 1][pisc.methodMstIDAU] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(covConstUnitsDispObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.covConstUnitsDispMVTag);

      gbu.safeModify(origVal, () => {
        covConstUnitsDispObjArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          covConstUnitsDispObjArr[methodCurrID - 1][pisc.methodMstIDCwCUD] = methodMstIDV;
        }
      });
    });

    // gbu.safeModify(potUsersToTakePrEPConstrObjArray, () => {
    //
    //     const origVal = getModVarValue(origModVarObjArr, pisc.covConstrByPriorityPopMVTag);
    //
    //     gbu.safeModify(origVal, () => {
    //
    //         let methodObj = structuredClone(origVal[origMethodCurrID - 1]);
    //
    //         /* First remove priority populations the user removed. */
    //         for (let pp = methodObj[pisc.coveragePUwC].length - 1; pp >= 0; pp--) {
    //
    //             const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
    //             const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);
    //
    //             if (priorPopCurrID === pic.itemDoesNotExist) {
    //
    //                 methodObj[pisc.coveragePUwC].splice(pp, 1);
    //
    //             }
    //
    //         }
    //
    //         /* Now add custom priority populations the user added. */
    //         const numPriorPops = getTotalNumPriorPops(priorPopObjArr);
    //
    //         for (let pp = 1; pp <= numPriorPops; pp++) {
    //
    //             const customBool = getPriorPopCustom(priorPopObjArr, pp);
    //             if (customBool) {
    //
    //                 methodObj[pisc.coveragePUwC].push(
    //                     methodObj[pisc.coveragePUwC][0]
    //                 );
    //
    //             }
    //
    //         }
    //
    //         potUsersToTakePrEPConstrObjArray.splice(methodCurrID - 1, 0, methodObj);
    //
    //         if (newCustomItem) {
    //
    //             potUsersToTakePrEPConstrObjArray[methodCurrID - 1][pisc.methodMstIDPUwC] = methodMstIDV;
    //
    //         }
    //
    //     });
    //
    // });

    gbu.safeModify(targClientsInitObjArray, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.targetsByPriorityPopMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = structuredClone(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customVal = structuredClone(methodObj[pisc.targetTC][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.targetTC].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.targetTC].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.targetTC].push(customVal);
          }
        }

        targClientsInitObjArray.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          targClientsInitObjArray[methodCurrID - 1][pisc.methodMstIDTC] = methodMstIDV;
        }
      });
    });

    // markA
    gbu.safeModify(adjFactor2DFltArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.adjustmentFactorMVTag);

      gbu.safeModify(origVal, () => {
        let methodObj = structuredClone(origVal[origMethodCurrID - 1]);

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customVal = structuredClone(methodObj[pisc.factorsAF][0]);

        /* First remove priority populations the user removed. */
        for (let pp = methodObj[pisc.factorsAF].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            methodObj[pisc.factorsAF].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            methodObj[pisc.factorsAF].push(customVal);
          }
        }

        adjFactor2DFltArr.splice(methodCurrID - 1, 0, methodObj);

        if (newCustomItem) {
          adjFactor2DFltArr[methodCurrID - 1][pisc.methodMstIDAF] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(costCatLiteObjList, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.costCategoriesLiteMVTag);

      gbu.safeModify(origVal, () => {
        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customVal = structuredClone(origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite][0]);

        /* First remove priority populations the user removed. */
        for (let pp = origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[origMethodCurrID - 1][pisc.priorPopObjArrCCLite].push(customVal);
          }
        }

        costCatLiteObjList.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costCatLiteObjList[methodCurrID - 1][pisc.methodMstIDCCLite] = methodMstIDV;

          //const origCostCatMethodObj = getCostCatLiteMethodObj(origMethodMstID, origVal);
          let costCatMethodObj = getCostCatLiteMethodObj(methodMstIDV, costCatLiteObjList);
          let categoriesArr = costCatMethodObj[pisc.priorPopObjArrCCLite];

          const contVisitSchedLiteObjList = getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
          const firstAvailableScheduleID = contVisitSchedLiteObjList[0].mstID;

          /* If the priority pop is custom, zero it out. */
          for (let pp = 1; pp <= numPriorPops; pp++) {
            let categoriesPriorPopObj = categoriesArr[pp - 1];

            categoriesPriorPopObj.scheduleID = firstAvailableScheduleID;

            /* All new priority populations should start off with zeros. The exception is the first
                              line, which pulls the method costs per month from the PI_Methods ModVar.*/
            categoriesPriorPopObj[pisc.ARVsCCLite] = 0;
            categoriesPriorPopObj[pisc.adherenceSupportCCLite] = 0;
            categoriesPriorPopObj[pisc.annualCCLite] = 0;
            categoriesPriorPopObj[pisc.monthlyCostCCLite] = 0;
            let contObj = categoriesPriorPopObj[pisc.contCCLite];
            contObj[pisc.capitalCCLite] = 0;
            contObj[pisc.personnelCCLite] = 0;
            contObj[pisc.recurrentCCLite] = 0;
            contObj[pisc.totalCCLite] = 0;
            contObj[pisc.visitLabsCCLite] = 0;
            let initObj = categoriesPriorPopObj[pisc.initCCLite];
            initObj[pisc.capitalCCLite] = 0;
            initObj[pisc.personnelCCLite] = 0;
            initObj[pisc.recurrentCCLite] = 0;
            initObj[pisc.totalCCLite] = 0;
            initObj[pisc.visitLabsCCLite] = 0;
            categoriesPriorPopObj[pisc.priorPopMstIDCCLite] = getPriorPopMstID(priorPopObjArr, pp);
          }
        }
      });
    });

    gbu.safeModify(costPerVisitObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.defCostsPerVisitLiteMVTag);

      gbu.safeModify(origVal, () => {
        costPerVisitObjArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costPerVisitObjArr[methodCurrID - 1][pisc.methodMstIDCPVLite] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(costPerVisitRatioObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.costPerVisitRatiosMVTag);

      gbu.safeModify(origVal, () => {
        costPerVisitRatioObjArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          costPerVisitRatioObjArr[methodCurrID - 1][pisc.methodMstIDCPVRLite] = methodMstIDV;
        }
      });
    });

    gbu.safeModify(impactEffectiveness1DIntArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.impactEffectivenessMVTag);

      gbu.safeModify(origVal, () => {
        impactEffectiveness1DIntArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));
      });
    });

    gbu.safeModify(drugFore2DObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.drugForecastTableMVTag);

      gbu.safeModify(origVal, () => {
        drugFore2DObjArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));
      });
    });

    gbu.safeModify(priorPopInclObjArr, () => {
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopAgeSexInclMVTag);

      gbu.safeModify(origVal, () => {
        let includedObjArr = origVal[origMethodCurrID - 1][pisc.includedPPIASObjArr];

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customVal = structuredClone(origVal[origMethodCurrID - 1][pisc.includedPPIASObjArr]);

        /* First remove priority populations the user removed. */
        for (
          let pp = origVal[origMethodCurrID - 1][pisc.includedPPIASObjArr][pisc.startAgePPIAS].length - 1;
          pp >= 0;
          pp--
        ) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            includedObjArr[pisc.startAgePPIAS].splice(pp, 1);
            includedObjArr[pisc.endAgePPIAS].splice(pp, 1);
            includedObjArr[pisc.includePPIAS].splice(pp, 1);
            includedObjArr[pisc.priorPopMstIDsPPIAS].splice(pp, 1);
          }
        }

        /* Now add custom priority populations the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            includedObjArr[pisc.startAgePPIAS].push(customVal[pisc.startAgePPIAS][0]);
            includedObjArr[pisc.endAgePPIAS].push(customVal[pisc.endAgePPIAS][0]);
            includedObjArr[pisc.includePPIAS].push(customVal[pisc.includePPIAS][0]);

            includedObjArr[pisc.priorPopMstIDsPPIAS].push(getPriorPopMstID(priorPopObjArr, pp));
          }
        }

        priorPopInclObjArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          priorPopInclObjArr[methodCurrID - 1][pisc.methodMstIDPPIAS] = methodMstIDV;

          for (let pp = 1; pp <= numPriorPops; pp++) {
            priorPopInclObjArr[methodCurrID - 1][pisc.includedPPIASObjArr][pisc.priorPopMstIDsPPIAS][pp - 1] =
              getPriorPopMstID(priorPopObjArr, pp);
          }
        }
      });
    });

    // Add a new method entry for each PriorPop method array
    if (priorPopObjArr !== undefined) {
      if (priorPopObjArr.length === 0 || methodObjArr.length === 0)
        throw new RangeError("PriorPops required to have at least 1 pop and method.");

      for (let pp = 0; pp < priorPopObjArr.length; ++pp) {
        const ppClone = structuredClone(priorPopObjArr[pp]);

        priorPopObjArr[pp]?.impact_constant?.splice(methodCurrID - 1, 0, {
          ...ppClone.impact_constant[0],
          mstID: methodMstIDV,
        });
        priorPopObjArr[pp]?.impact_pse?.splice(methodCurrID - 1, 0, { ...ppClone.impact_pse[0], mstID: methodMstIDV });
        priorPopObjArr[pp].contCurve.splice(methodCurrID - 1, 0, { ...ppClone.contCurve[0], mstID: methodMstIDV });
        priorPopObjArr[pp].scaleUpTrend.splice(methodCurrID - 1, 0, {
          ...ppClone.scaleUpTrend[0],
          mstID: methodMstIDV,
        });
      }
    }

    // markElig
    gbu.safeModify(priorPopMethodEligObjArr, () => {
      /* Get the original version of the ModVar. We will have to modify it in case the user changed various
                  user-editable lists. */
      const origVal = getModVarValue(origModVarObjArr, pisc.priorPopMethodEligMVTag);

      gbu.safeModify(origVal, () => {
        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customVal = structuredClone(origVal[origMethodCurrID - 1][pisc.methodValuePPPE][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[origMethodCurrID - 1][pisc.methodValuePPPE].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[origMethodCurrID - 1][pisc.methodValuePPPE].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[origMethodCurrID - 1][pisc.methodValuePPPE].push(customVal);
          }
        }

        priorPopMethodEligObjArr.splice(methodCurrID - 1, 0, structuredClone(origVal[origMethodCurrID - 1]));

        if (newCustomItem) {
          priorPopMethodEligObjArr[methodCurrID - 1][pisc.methodMstIDPPPE] = methodMstIDV;

          for (let pp = 1; pp <= numPriorPops; pp++) {
            priorPopMethodEligObjArr[methodCurrID - 1][pisc.methodValuePPPE][pp - 1][pisc.priorPopMethodMstIDPPE] =
              getPriorPopMstID(priorPopObjArr, pp);
          }
        }
      });
    });

    // MethodMix
    const methodMixMVV = getModVarValue(modVarObjList, "PI_MethodMix");
    for (const ppKey of Object.keys(methodMixMVV)) {
      methodMixMVV[ppKey][methodMstIDV] = 0;
    }

    /* Results */

    /* Special cases :-( */

    gbu.safeModify(targIndObj, () => {
      /* Get the original version of the ModVar. We will have to modify it in case the user changed various
                  user-editable lists. */
      const origVal = getModVarValue(origModVarObjArr, pisc.targIndTableMVTag);

      gbu.safeModify(origVal, () => {
        /**************   initTI   *******************/

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customValNEW_TI = structuredClone(origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */
        const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1].push(customValNEW_TI);
          }
        }

        targIndObj[pisc.PrEP_NEW_TI].splice(
          methodCurrID - 1,
          0,
          structuredClone(origVal[pisc.PrEP_NEW_TI][origMethodCurrID - 1])
        );

        /**************   currOnPrEPTI   *******************/

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customValCurrOnPrEP_TI = structuredClone(origVal[pisc.currOnPrEPTI][origMethodCurrID - 1][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[pisc.currOnPrEPTI][origMethodCurrID - 1].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[pisc.currOnPrEPTI][origMethodCurrID - 1].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[pisc.currOnPrEPTI][origMethodCurrID - 1].push(customValCurrOnPrEP_TI);
          }
        }

        targIndObj[pisc.currOnPrEPTI].splice(
          methodCurrID - 1,
          0,
          structuredClone(origVal[pisc.currOnPrEPTI][origMethodCurrID - 1])
        );

        /**************   PREP_CurrTI   *******************/

        /* Copy the value in the original array to use for the custom item in case we end up deleting all
                      values (which will happen if the user deselected all default items). */
        const customValPREP_CT_TI = structuredClone(origVal[pisc.PREP_CT_TI][origMethodCurrID - 1][0]);

        /* Remove priority populations from the original ModVar that the user removed. */
        for (let pp = origVal[pisc.PREP_CT_TI][origMethodCurrID - 1].length - 1; pp >= 0; pp--) {
          const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
          const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

          if (priorPopCurrID === pic.itemDoesNotExist) {
            origVal[pisc.PREP_CT_TI][origMethodCurrID - 1].splice(pp, 1);
          }
        }

        /* Now add custom priority populations from the original ModVar that the user added. */

        for (let pp = 1; pp <= numPriorPops; pp++) {
          const customBool = getPriorPopCustom(priorPopObjArr, pp);
          if (customBool) {
            origVal[pisc.PREP_CT_TI][origMethodCurrID - 1].push(customValPREP_CT_TI);
          }
        }

        targIndObj[pisc.PREP_CT_TI].splice(
          methodCurrID - 1,
          0,
          structuredClone(origVal[pisc.PREP_CT_TI][origMethodCurrID - 1])
        );
      });
    });

    gbu.safeModify(initiationObj, () => {
      /* Get the original version of the ModVar. We will have to modify it in case the user changed various
                  user-editable lists. */
      const origVal = getModVarValue(origModVarObjArr, pisc.initiationMVTag);

      gbu.safeModify(origVal, () => {
        let origMethodObj = structuredClone(getInitiationMethodObj(origMethodMstID, origVal));
        const origNumProgDataMonths = origMethodObj[pisc.initObjArr].length;

        /* First modify the priority pops in the original structure to match the current priority pops. */

        for (let t = 1; t <= origNumProgDataMonths; t++) {
          let origInitByMonth = origMethodObj[pisc.initObjArr][t - 1];

          /* Copy the value in the original array to use for the custom item in case we end up deleting all
                          values (which will happen if the user deselected all default items). */
          const customVal = structuredClone(origInitByMonth);

          /* Remove priority populations from the original ModVar that the user removed. */
          for (let pp = origInitByMonth[pisc.initInitiatedPrEP].length - 1; pp >= 0; pp--) {
            const origPriorPopMstID = getPriorPopMstID(origPriorPopObjArr, pp + 1);
            const priorPopCurrID = getPriorPopCurrID(priorPopObjArr, origPriorPopMstID);

            if (priorPopCurrID === pic.itemDoesNotExist) {
              origInitByMonth[pisc.initInitiatedPrEP].splice(pp, 1);
              origInitByMonth[pisc.initReinitiatedPrEP].splice(pp, 1);
            }
          }

          /* Now add custom priority populations from the original ModVar that the user added. */
          const numPriorPops = getTotalNumPriorPops(priorPopObjArr);

          for (let pp = 1; pp <= numPriorPops; pp++) {
            const customBool = getPriorPopCustom(priorPopObjArr, pp);
            if (customBool) {
              origInitByMonth[pisc.initInitiatedPrEP].push(customVal[pisc.initInitiatedPrEP][0]);
              origInitByMonth[pisc.initReinitiatedPrEP].push(customVal[pisc.initReinitiatedPrEP][0]);
            }
          }
        }

        /* Now modify the months in the original structure to match the current number of months.  */

        let methodObj = initiationObj[0];
        if (methodObj) {
          const firstInitMonthObj = methodObj[pisc.initObjArr][0];
          const firstYear = firstInitMonthObj[pisc.initYear];
          const firstMonth = piu.getMonthNum(firstInitMonthObj[pisc.initMonth]);

          const finalInitMonthObj = methodObj[pisc.initObjArr][numProgDataMonths - 1];
          const finalYear = finalInitMonthObj[pisc.initYear];
          const finalMonth = piu.getMonthNum(finalInitMonthObj[pisc.initMonth]);

          const origFirstInitMonthObj = origMethodObj[pisc.initObjArr][0];
          const origFirstYear = origFirstInitMonthObj[pisc.initYear];
          const origFirstMonth = piu.getMonthNum(origFirstInitMonthObj[pisc.initMonth]);

          const origFinalInitMonthObj = origMethodObj[pisc.initObjArr][origNumProgDataMonths - 1];
          const origFinalYear = origFinalInitMonthObj[pisc.initYear];
          const origFinalMonth = piu.getMonthNum(origFinalInitMonthObj[pisc.initMonth]);

          /* If the user's start date starts before the original start date, add months before the
                      original start date to match the user's start date. */
          if (firstYear < origFirstYear || (firstYear === origFirstYear && firstMonth < origFirstMonth)) {
            const numMonths = piu.getMonthsBetween(firstMonth, firstYear, origFirstMonth, origFirstYear) - 1;

            let yr = origFirstYear;
            let mo = origFirstMonth;
            for (let t = 1; t <= numMonths; t++) {
              if (mo === 1) {
                yr--;
                mo = 12;
              } else {
                mo--;
              }

              // clone object, change month and year, and add to to front of original array
              const clonedMonthObj = structuredClone(origFirstInitMonthObj);
              clonedMonthObj[pisc.initYear] = yr;
              clonedMonthObj[pisc.initMonth] = piu.getMonthName(mo).toUpperCase();

              origMethodObj[pisc.initObjArr].unshift(clonedMonthObj);
            }
          }

          /* If the user's start date starts after the original start date, remove months after the original
                      start date to match the user's start date. */

          if (firstYear > origFirstYear || (firstYear === origFirstYear && firstMonth > origFirstMonth)) {
            const numMonths = piu.getMonthsBetween(origFirstMonth, origFirstYear, firstMonth, firstYear) - 1;

            for (let t = 1; t <= numMonths; t++) {
              origMethodObj[pisc.initObjArr].shift();
            }
          }

          /* If the user's end date ends before the original end date, remove months before the original end date. */

          if (finalYear < origFinalYear || (finalYear === origFinalYear && finalMonth < origFinalMonth)) {
            const numMonths = piu.getMonthsBetween(finalMonth, finalYear, origFinalMonth, origFinalYear) - 1;

            for (let t = 1; t <= numMonths; t++) {
              origMethodObj[pisc.initObjArr].pop();
            }
          }

          /* If the user's end date ends after the original end date, add months after the original end date. */

          if (finalYear > origFinalYear || (finalYear === origFinalYear && finalMonth > origFinalMonth)) {
            const numMonths = piu.getMonthsBetween(origFinalMonth, origFinalYear, finalMonth, finalYear) - 1;

            let yr = origFinalYear;
            let mo = origFinalMonth;
            for (let t = 1; t <= numMonths; t++) {
              if (mo === 12) {
                yr++;
                mo = 1;
              } else {
                mo++;
              }

              // clone object, change month and year, and add to to front of original array
              const clonedMonthObj = structuredClone(origFinalInitMonthObj);
              clonedMonthObj[pisc.initYear] = yr;
              clonedMonthObj[pisc.initMonth] = piu.getMonthName(mo).toUpperCase();

              origMethodObj[pisc.initObjArr].push(clonedMonthObj);
            }
          }

          initiationObj.splice(methodCurrID - 1, 0, structuredClone(origMethodObj));

          if (newCustomItem) {
            initiationObj[methodCurrID - 1][pisc.initMethodMstID] = methodMstIDV;
          }
        }
      });
    });

    /* General add functionality for everything else. */

    for (let i = 0; i < modVarValues.length; i++) {
      gbu.safeModify(modVarValues[i], () => {
        /* Copy the first original array value and use that until calcs can get rerun. Otherwise,
                      for example, if the user goes back and forth between adding priority pops and
                      methods and does not calculate in between, shifting will break. This really should be done like
                      we do the inputs above, but we are banking on the fact that the calcs need to get rerun before
                      any results are shown to take a shortcut here. */

        const value = structuredClone(origModVarResultValues[i][0]);

        modVarValues[i].splice(methodCurrID - 1, 0, value);
      });
    }
  } else if (instructionInt === pic.deleteItem) {
    /***************************************************************************************
     *
     *    Delete
     *
     ***************************************************************************************/
    for (let i = 0; i < modVarValues.length; i++) {
      gbu.safeModify(modVarValues[i], () => modVarValues[i].splice(methodCurrID - 1, 1));
    }

    /* Inputs */

    gbu.safeModify(priorPopObjArr, () => {
      for (let pp = 0; pp < priorPopObjArr.length; pp++) {
        priorPopObjArr[pp][pisc.priorPopImpactConst]?.splice(methodCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopImpactPSE]?.splice(methodCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopContCurves].splice(methodCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopScaleUpTrends].splice(methodCurrID - 1, 1);
      }
    });

    // Update selected method modvars if selection has been removed
    const methodObjArr = getModVarValue(modVarObjList, pisc.methodsMVTag);
    const selectedMethodMstID = getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

    // disagTarg tracks selectedMethodMV changes but also needs changing from
    // "All combined" when there's only a single method remaining
    if (methodObjArr.length <= 1 || !methodObjArr.map((v) => v.mstID).includes(selectedMethodMstID)) {
      const firstMethodMstId = methodObjArr?.[0].mstID ?? pic.noMstID;
      setModVarValue(modVarObjList, pisc.selectedMethodMVTag, firstMethodMstId);
      setModVarValue(modVarObjList, pisc.disagTargSelectedMethodMVTag, firstMethodMstId);
    }

    // MethodMix
    const methodsMVV = getModVarValue(modVarObjList, pisc.methodsMVTag);
    const methodMixMVV = getModVarValue(modVarObjList, "PI_MethodMix");
    for (const ppKey of Object.keys(methodMixMVV)) {
      for (const methodKey of Object.keys(methodMixMVV[ppKey])) {
        // Method still exists?
        if (methodsMVV.some((m) => m.mstID === methodKey)) continue;

        delete methodMixMVV[ppKey][methodKey];
      }
    }

    /* Results */

    gbu.safeModify(targIndObj, () => {
      targIndObj[pisc.PREP_CT_TI].splice(methodCurrID - 1, 1);

      targIndObj[pisc.currOnPrEPTI].splice(methodCurrID - 1, 1);

      targIndObj[pisc.PrEP_NEW_TI].splice(methodCurrID - 1, 1);
    });

    gbu.safeModify(initiationObj, () => {
      initiationObj.splice(methodCurrID - 1, 1);
    });
  } else if (instructionInt === pic.moveItem) {
    /***************************************************************************************
     *
     *    Move
     *
     ***************************************************************************************/
    let singleValueArray;

    const moveMethodInModVar = (modVarValue) => {
      gbu.safeModify(modVarValue, () => {
        singleValueArray = modVarValue.splice(methodToMoveCurrID - 1, 1);
        modVarValue.splice(methodCurrID - 1, 0, singleValueArray[0]);
      });
    };

    for (let i = 0; i < modVarValues.length; i++) {
      moveMethodInModVar(modVarValues[i]);
    }

    /* Inputs */

    gbu.safeModify(priorPopObjArr, () => {
      for (let pp = 0; pp < priorPopObjArr.length; pp++) {
        singleValueArray = priorPopObjArr[pp][pisc.priorPopImpactConst]?.splice(methodToMoveCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopImpactConst]?.splice(methodCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = priorPopObjArr[pp][pisc.priorPopImpactPSE]?.splice(methodToMoveCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopImpactPSE]?.splice(methodCurrID - 1, 0, singleValueArray[0]);

        singleValueArray = priorPopObjArr[pp][pisc.priorPopContCurves].splice(methodToMoveCurrID - 1, 1);
        priorPopObjArr[pp][pisc.priorPopContCurves].splice(methodCurrID - 1, 0, singleValueArray[0]);
      }
    });

    /* Results */

    gbu.safeModify(targIndObj, () => {
      singleValueArray = targIndObj[pisc.PrEP_NEW_TI].splice(methodToMoveCurrID - 1, 1);
      targIndObj[pisc.PrEP_NEW_TI].splice(methodCurrID - 1, 0, singleValueArray[0]);

      singleValueArray = targIndObj[pisc.currOnPrEPTI].splice(methodToMoveCurrID - 1, 1);
      targIndObj[pisc.currOnPrEPTI].splice(methodCurrID - 1, 0, singleValueArray[0]);

      singleValueArray = targIndObj[pisc.PREP_CT_TI].splice(methodToMoveCurrID - 1, 1);
      targIndObj[pisc.PREP_CT_TI].splice(methodCurrID - 1, 0, singleValueArray[0]);
    });
  }
}
