import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

export const PIDisagTargetsHIVByAgeSexRefTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const initCol = 0;

const firstRow = 0;

const numRows = 18;
const numCols = 3;

class PIDisagTargetsHIVByAgeSexRefTable extends Component {
  static propTypes = {
    [PIDisagTargetsHIVByAgeSexRefTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [PIDisagTargetsHIVByAgeSexRefTableProps.allowEditsBoolC]: true,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================


  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, firstRow, initCol, RS(SC.GB_stAge));
      gbtu.setValue(packTable, firstRow, initCol + 1, RS(SC.GB_stFemale));
      gbtu.setValue(packTable, firstRow, initCol + 2, RS(SC.GB_stMale));

      gbtu.setValue(packTable, firstRow + 1, initCol, "0 - 4");
      gbtu.setValue(packTable, firstRow + 2, initCol, "5 - 9");
      gbtu.setValue(packTable, firstRow + 3, initCol, "10 - 14");
      gbtu.setValue(packTable, firstRow + 4, initCol, "15 - 19");
      gbtu.setValue(packTable, firstRow + 5, initCol, "20 -24");
      gbtu.setValue(packTable, firstRow + 6, initCol, "25 - 29");
      gbtu.setValue(packTable, firstRow + 7, initCol, "30 - 34");
      gbtu.setValue(packTable, firstRow + 8, initCol, "35 - 39");
      gbtu.setValue(packTable, firstRow + 9, initCol, "40 - 44");
      gbtu.setValue(packTable, firstRow + 10, initCol, "45 - 49");
      gbtu.setValue(packTable, firstRow + 11, initCol, "50 - 54");
      gbtu.setValue(packTable, firstRow + 12, initCol, "55 - 59");
      gbtu.setValue(packTable, firstRow + 13, initCol, "60 - 64");
      gbtu.setValue(packTable, firstRow + 14, initCol, "65 - 69");
      gbtu.setValue(packTable, firstRow + 15, initCol, "70 - 74");
      gbtu.setValue(packTable, firstRow + 16, initCol, "75 - 79");
      gbtu.setValue(packTable, firstRow + 17, initCol, "80+");

      gbtu.setValue(packTable, firstRow + 4, initCol + 1, 0.43);
      gbtu.setValue(packTable, firstRow + 5, initCol + 1, 0.98);
      gbtu.setValue(packTable, firstRow + 6, initCol + 1, 1.0);
      gbtu.setValue(packTable, firstRow + 7, initCol + 1, 0.85);
      gbtu.setValue(packTable, firstRow + 8, initCol + 1, 0.68);
      gbtu.setValue(packTable, firstRow + 9, initCol + 1, 0.55);
      gbtu.setValue(packTable, firstRow + 10, initCol + 1, 0.44);
      gbtu.setValue(packTable, firstRow + 11, initCol + 1, 0.34);
      gbtu.setValue(packTable, firstRow + 12, initCol + 1, 0.24);
      gbtu.setValue(packTable, firstRow + 13, initCol + 1, 0.17);
      gbtu.setValue(packTable, firstRow + 14, initCol + 1, 0.15);
      gbtu.setValue(packTable, firstRow + 15, initCol + 1, 0.17);

      gbtu.setValue(packTable, firstRow + 4, initCol + 2, 0.24);
      gbtu.setValue(packTable, firstRow + 5, initCol + 2, 0.79);
      gbtu.setValue(packTable, firstRow + 6, initCol + 2, 1.0);
      gbtu.setValue(packTable, firstRow + 7, initCol + 2, 0.99);
      gbtu.setValue(packTable, firstRow + 8, initCol + 2, 0.85);
      gbtu.setValue(packTable, firstRow + 9, initCol + 2, 0.67);
      gbtu.setValue(packTable, firstRow + 10, initCol + 2, 0.49);
      gbtu.setValue(packTable, firstRow + 11, initCol + 2, 0.36);
      gbtu.setValue(packTable, firstRow + 12, initCol + 2, 0.28);
      gbtu.setValue(packTable, firstRow + 13, initCol + 2, 0.26);
      gbtu.setValue(packTable, firstRow + 14, initCol + 2, 0.26);
      gbtu.setValue(packTable, firstRow + 15, initCol + 2, 0.25);
      gbtu.setValue(packTable, firstRow + 16, initCol + 2, 0.16);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setRowHeight(packTable, 0, 100);
      gbtu.setColWidth(packTable, initCol, Theme.dataColWidthSmall);
      gbtu.lockPackTable(packTable, true, true);

      if (window.DebugMode) {
        console.log("Component: PIDisagTargetsHIVByAgeSexRefTable");
        console.log("ModVar(s): hardcoded");
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.fontFamily}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"DisagTargetsHIVByAgeSexRefTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.fontFamily,
            marginTop: 20,
            marginRight: 20,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIDisagTargetsHIVByAgeSexRefTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIDisagTargetsHIVByAgeSexRefTable;
