import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TCheckBox from "../../common/TCheckBox";
import TRadioGroup from "../../common/TRadioGroup";
import TEdit from "../../common/TEdit";
import TDialog from "../../common/TDialog";
import TWifiAnimation from "../../common/TWifiAnimation";

import * as gbu from "../../GB/GBUtil";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import TButton from "../../common/TButton";

import {
  getProjDefaultsAsync,
  downloadPrEPitDataTemplateXLSX,
  uploadPrEPitDataTemplateXLSX,
} from "../../../api/server_calls";
import { onCalculateAsync } from "../NonComponents/PICalc";

import { promisify } from "../../../utilities";

import PICountryComboBox from "../Other/PICountryComboBox";

import { setDefPriorPopActive } from "../NonComponents/PIAppStateUtil";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import { easyStartModeOptionOn } from "../NonComponents/PIAppStateUtil";

const showDoUploadDialogBoolC = "showDoUploadDialogBool";
const showSetTargDialogBoolC = "showSetTargDialogBool";
const showEnterExistingTargDialogBoolC = "showEnterExistingDialogBool";
const showSubNatLevlDialogBoolC = "showSubNatLevlDialogBool";

const styles = {
  autonumberheaders: {
    counterReset: "h1 h2",
  },
  autonumberh1: {
    counterIncrement: "h1",
    "&::before": {
      content: 'counter(h1)". "',
    },
  },
  autonumberh2: {
    counterIncrement: "h2",
    "&::before": {
      content: 'counter(h1)"."counter(h2)". "',
    },
  },
};

class PIGettingStartedForm extends Component {
  static propTypes = {
    classes: PropTypes.object,
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.countryObjList]: PropTypes.array,

    [pias.onDialogChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onOrigModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    language: PropTypes.string,
  };

  static defaultProps = {
    classes: {},
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.countryObjList]: [],

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.onOrigModVarsChange]: () => console.log(pias.onOrigModVarsChange),
  };

  state = {
    [showDoUploadDialogBoolC]: false,
    [showSetTargDialogBoolC]: false,
    [showEnterExistingTargDialogBoolC]: false,
    [showSubNatLevlDialogBoolC]: false,
    retrievingData: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  hideSubnatComponents = () => {
    let hideComponentsBool;

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjArr, pisc.easyStartOptionsMVTag);

    const pseMode = piasu.isPSEMode(modVarObjArr);

    const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);
    const disaggTargsSubnatBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.disaggTargsSubnatESMstID);
    const AGYWHotspotsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.AGYWHotspotsESMstID);

    hideComponentsBool = doNotSetTargsBool || pseMode || (!disaggTargsSubnatBool && !AGYWHotspotsBool);

    return hideComponentsBool;
  };

  canProcessFile = () => {
    let processFileBool;

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const levelNames1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);

    const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);

    processFileBool = this.hideSubnatComponents() || level1Name !== "";

    return processFileBool;
  };

  /* Pulled this out because it complained about creating this function inside the other function. */
  convertPriorPopSetToStr = (priorPopSet) => {
    let noCostsProvidedStr = "";

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const priorPopObjArr = piasu.getModVarValue(modVarObjArr, pisc.priorPopsMVTag);

    priorPopSet.forEach((priorPop) => {
      noCostsProvidedStr += piasu.getPriorPopName(priorPopObjArr, priorPop) + ", ";
    });

    /* Remove last comma and space. */
    noCostsProvidedStr = noCostsProvidedStr.slice(0, noCostsProvidedStr.length - 2);

    return noCostsProvidedStr;
  };

  uploadDataTemplate = (file) => {
    const props = this.props;
    const modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const onDialogChange = props[pias.onDialogChange];
    const origModVarObjArr = structuredClone(props[pias.origModVarObjArr]);

    const countryCodeMstIDStr = piasu.getModVarValue(modVarObjArrClone, pisc.countryISOMVTag);

    uploadPrEPitDataTemplateXLSX(
      countryCodeMstIDStr,
      file,
      (uploadPrEPitDataTemplateResponse) => {
        const newModVarObjArr = uploadPrEPitDataTemplateResponse[pisc.modVars];

        if (typeof newModVarObjArr !== "undefined") {
          /* Retain the values of certain ModVars. The only ones we need to maintain are the ones that
                       give us the app mode. */
          const appMode = piasu.getModVarValue(modVarObjArrClone, pisc.appModeMVTag);
          const easyStartOptions = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);
          const levels = piasu.getModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag);
          const country = piasu.getModVarValue(modVarObjArrClone, pisc.countryISOMVTag);
          const allInputMVsLoadedBool = piasu.getModVarValue(modVarObjArrClone, pisc.allInputMVsLoadedMVTag);

          piasu.addNewModVars(modVarObjArrClone, newModVarObjArr, true);

          piasu.setModVarValue(modVarObjArrClone, pisc.appModeMVTag, appMode);
          piasu.setModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag, easyStartOptions);
          piasu.setModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag, levels);
          piasu.setModVarValue(modVarObjArrClone, pisc.countryISOMVTag, country);
          piasu.setModVarValue(modVarObjArrClone, pisc.allInputMVsLoadedMVTag, allInputMVsLoadedBool);

          piasu.setModVarValue(modVarObjArrClone, pisc.disagTargTemplateUploadedMVTag, true);

          const AGYWNotIncludedBool = easyStartModeOptionOn(easyStartOptions, pisc.AGYWNotIncludedESMstID);

          if (AGYWNotIncludedBool) {
            piasu.setDefPriorPopActive(modVarObjArrClone, origModVarObjArr, pisc.AGYW_PP_MstID, false);
          }

          onModVarsChange(modVarObjArrClone, false, () => {
            //onModVarsChange(newModVarObjArr, false, () => {

            piasu.setUserDefaults(modVarObjArrClone, origModVarObjArr, false, () => {
              this.setState(
                {
                  [showDoUploadDialogBoolC]: false,
                },
                () => {
                  const showCostingBool = piasu.showCostsLite(modVarObjArrClone);

                  let dialogObj = pias.getDefaultDialogObj();
                  dialogObj[pias.contentStr] = RS(SC.GB_stPrEPitDataTemplateUploaded);
                  dialogObj[pias.headerStr] = RS(SC.GB_stNote);
                  dialogObj[pias.maxWidthStr] = "sm";
                  dialogObj[pias.showBool] = true;
                  dialogObj[pias.styleObj] = { width: 500 };

                  onDialogChange(dialogObj);

                  const priorPopObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.priorPopsMVTag);
                  const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);

                  if (showCostingBool) {
                    //alert(RS(SC.GB_stPrEPitDataTemplateUploaded));

                    /* Inform the user if all costs are 0 for a particular priority population for a particular method if
                                       that priority population is eligible for that method.*/
                    let problemPriorPops = [];
                    const methodCurrID = "methodCurrID";
                    const priorPopSet = "priorPopSet";

                    const costCatObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.costCategoriesLiteMVTag);
                    const methodObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.methodsMVTag);
                    const priorPopMethodEligObjArr = piasu.getModVarValue(
                      modVarObjArrClone,
                      pisc.priorPopMethodEligMVTag
                    );

                    const numMethods = piasu.getTotalNumMethods(methodObjArr);

                    for (let m = 1; m <= numMethods; m++) {
                      const methodMstID = piasu.methodMstID(methodObjArr, m);

                      let methodPriorPopSet = new Set();

                      let hasAllZeroPriorPopBool = false;

                      for (let pp = 1; pp <= numPriorPops; pp++) {
                        const priorPopEligStrMstID = piasu.getPriorPopMethodElig(
                          priorPopMethodEligObjArr,
                          methodMstID,
                          pp
                        );

                        if (priorPopEligStrMstID === pisc.yesMstID) {
                          let totalForPriorPop = 0;

                          /* Ignore calculated values */
                          //const costPerPersMonth = piasu.getCostPerPersMonthCostsLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += costPerPersMonth;
                          /* Ignore this one, since the template always fills it in. */
                          //const ARVCost = piasu.getARVCostLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += ARVCost;
                          const adhereSupportCost = piasu.getAdhereSupportCostLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += adhereSupportCost;
                          /* Ignore calculated values */
                          //const initVisitCosts = piasu.getInitVisitCostsLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += initVisitCosts;
                          const initPersonnelCosts = piasu.getInitPersonnelCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initPersonnelCosts;
                          const initLabCosts = piasu.getInitLabCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initLabCosts;
                          const initOtherRecurCosts = piasu.getInitOtherRecurCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initOtherRecurCosts;
                          const initCapCosts = piasu.getInitCapCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += initCapCosts;
                          /* Ignore calculated values */
                          //const contVisitCosts = piasu.getContVisitCostsLite(methodMstID, costCatObjArr, pp);
                          //totalForPriorPop += contVisitCosts;
                          const contPersonnelCosts = piasu.getContPersonnelCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contPersonnelCosts;
                          const contLabCosts = piasu.getContLabTestCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contLabCosts;
                          const contOtherRecurCosts = piasu.getContOtherRecurCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contOtherRecurCosts;
                          const contCapCosts = piasu.getContCapCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += contCapCosts;
                          const annLumpSumCost = piasu.getAnnLumpSumCostsLite(methodMstID, costCatObjArr, pp);
                          totalForPriorPop += annLumpSumCost;

                          if (gbu.equal(totalForPriorPop, 0)) {
                            methodPriorPopSet.add(pp);
                            hasAllZeroPriorPopBool = true;
                          }
                        }
                      }

                      if (hasAllZeroPriorPopBool) {
                        problemPriorPops.push({
                          [methodCurrID]: m,
                          [priorPopSet]: methodPriorPopSet,
                        });
                      }
                    }

                    if (problemPriorPops.length > 0) {
                      let noCostsProvidedStrArr = [];
                      noCostsProvidedStrArr.push(RS(SC.GB_stCostsNotProvided));

                      for (let i = 0; i < problemPriorPops.length; i++) {
                        noCostsProvidedStrArr.push(<br></br>);
                        noCostsProvidedStrArr.push(<br></br>);
                        const methodNameStr =
                          RS(SC.GB_stMethod) + ": " + piasu.methodName(methodObjArr, problemPriorPops[i][methodCurrID]);
                        noCostsProvidedStrArr.push(methodNameStr);
                        noCostsProvidedStrArr.push(<br></br>);
                        const priorPopNamesStr =
                          RS(SC.GB_stPriorPopsParenS) +
                          ": " +
                          this.convertPriorPopSetToStr(problemPriorPops[i][priorPopSet]);
                        noCostsProvidedStrArr.push(priorPopNamesStr);
                      }

                      let dialogObj = pias.getDefaultDialogObj();
                      dialogObj[pias.contentStr] = <p>{noCostsProvidedStrArr}</p>;
                      dialogObj[pias.headerStr] = RS(SC.GB_stWarning);
                      dialogObj[pias.maxWidthStr] = "sm";
                      dialogObj[pias.showBool] = true;
                      dialogObj[pias.styleObj] = { width: 500 };

                      onDialogChange(dialogObj);
                    }
                  }

                  /* If the user didn't enter anything for Targets, give another message. */
                  // GB_stTargetsNotEnteredMsg
                  const popSize1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.priorityPopSizeMVTag);
                  const percPrEPElig1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.percentPrepEligibleMVTag);
                  const HIVPrev1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.percentHIVPrevalenceMVTag);
                  const percIndicPrEP1DFltArray = piasu.getModVarValue(modVarObjArrClone, pisc.percentForPrepMVTag);

                  let allZerosBool = true;
                  let pp = 1;
                  while (pp <= numPriorPops && allZerosBool) {
                    const a = piasu.getPopSize(popSize1DFltArray, pp);
                    const b = piasu.getPercPrEPElig(percPrEPElig1DFltArray, pp);
                    const c = piasu.getHIVPrev(HIVPrev1DFltArray, pp);
                    const d = piasu.getPercIndicPrEP(percIndicPrEP1DFltArray, pp);

                    if (!gbu.equal(a, 0) || !gbu.equal(b, 0) || !gbu.equal(c, 0) || !gbu.equal(d, 0)) {
                      allZerosBool = false;
                    }

                    pp++;
                  }

                  if (allZerosBool) {
                    alert(RS(SC.GB_stTargetsNotEnteredMsg));
                  }
                }
              );
            });
          });
        } else {
          this.setState(
            {
              [showDoUploadDialogBoolC]: false,
            },
            () => {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = RS(SC.GB_stErrOccProcTemplate);
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);

              //alert(RS(SC.GB_stErrOccProcTemplate));
            }
          );
        }
      },
      () => {
        this.setState(
          {
            [showDoUploadDialogBoolC]: false,
          },
          () => {
            let dialogObj = pias.getDefaultDialogObj();
            dialogObj[pias.contentStr] = RS(SC.GB_stUploadTemplateError);
            dialogObj[pias.headerStr] = RS(SC.GB_stError);
            dialogObj[pias.maxWidthStr] = "sm";
            dialogObj[pias.showBool] = true;
            dialogObj[pias.styleObj] = { width: 500 };

            onDialogChange(dialogObj);
          }
        );
      }
    );
  };

  /**
   * Initialise a new PrEP-it session
   * @param {string} countryISOCode Country ISO code
   * @param {boolean} pseMode true to initialise a PSE session
   */
  createNewSession = (countryISOCode, pseMode) => {
    const props = this.props;

    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarChange = promisify(props[pias.onModVarChange]);
    const onModVarsChange = promisify(props[pias.onModVarsChange]);
    const onOrigModVarsChange = promisify(props[pias.onOrigModVarsChange]);

    /* Preserve the mode and easy start options. */
    const appModeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.appModeMVTag);
    // Note: Easy start options are no longer preserved to ensure the correct defaults are used
    // when changing countries.
    // const easyStartOptions = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const hideDialog = () => {
      onCalculatingChange(false, () => {
        this.setState({
          retrievingData: false,
        });
      });
    };

    /**
     * Initialise a new PrEP-it session
     * @param {string} countryISOCode Country ISO code
     * @param {boolean} pseMode true to initialise a PSE session
     */
    const newSession = async (countryISOCode, pseMode) => {
      try {
        await onModVarChange(countryISOCode, pisc.countryISOMVTag);

        const response = await getProjDefaultsAsync({
          countryCode: countryISOCode,
          pse: pseMode,
        });

        try {
          const newModVars = await onCalculateAsync(response[pisc.modVars], "", onDialogChange);

          piasu.setModVarValue(newModVars, pisc.appModeMVTag, appModeMstIDStr);
          // Note: Easy start options are no longer preserved to ensure the correct defaults are used
          // when changing countries.
          // piasu.setModVarValue(newModVars, pisc.easyStartOptionsMVTag, easyStartOptions);
          piasu.setModVarValue(newModVars, pisc.allInputMVsLoadedMVTag, true);

          const methods = piasu.getModVarValue(newModVars, "PI_Methods");

          piasu.setCostCategoriesLiteValues(newModVars, new Set(methods.map((v) => v.mstID)));

          let origModVarObjArr = structuredClone(newModVars);
          await onOrigModVarsChange(origModVarObjArr);

          // setUserDefaults does not seem to actually be async so using the callback is pointless
          piasu.setUserDefaults(newModVars, origModVarObjArr, true);
          await onModVarsChange(newModVars, true, hideDialog, hideDialog);
        } catch (err) {
          hideDialog();

          // Re-throw so that an error dialog pops up
          console.error(err);
          throw err;
        }
      } catch (err) {
        const dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_steProjDefError);
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);
      }
    };

    this.setState(
      {
        retrievingData: true,
      },
      () => newSession(countryISOCode, pseMode)
    );
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onCountryComboBoxChange = (value, text, info) => {
    const props = this.props;

    const modVarObjList = props[pias.modVarObjList];
    const countryObjArr = props[pias.countryObjList];

    const allLoaded = piasu.getModVarValue(modVarObjList, pisc.allInputMVsLoadedMVTag);
    const countryCode = info.toString();

    const pseAvailable =
      !window.DISABLE_PSE_MODE &&
      (countryObjArr.find((v) => v.ISONumeric.toString() === countryCode.toString())?.PSEAvailable ?? false);

    const pse = (!allLoaded && pseAvailable) || piasu.isPSEMode(modVarObjList);

    this.createNewSession(countryCode, pse && pseAvailable);
  };

  onPSERadioGroupChange = (value) => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];
    // let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
    // const onModVarsChange = props[pias.onModVarsChange];

    // piasu.setPSEMode(modVarObjArrClone, value === 0);
    // onModVarsChange(modVarObjArrClone, false);

    // FIXME: Dialog due to full reset of session

    const countryCode = piasu.getModVarValue(modVarObjList, pisc.countryISOMVTag);

    this.createNewSession(countryCode, value === 0);
  };

  onLevelNameChange = (level, name) => {
    const props = this.props;
    let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    let levels1DStrArr = piasu.getModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag);
    piasu.adminSubnatLevelName(levels1DStrArr, level, name);
    piasu.setModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag, levels1DStrArr);

    onModVarsChange(modVarObjArrClone, false);
  };

  handleCheckBoxClick = (optionMstID, checked) => {
    try {
      const props = this.props;
      let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);

      piasu.easyStartModeOptionOn(easyStartOptionObjArr, optionMstID, checked);

      if (!piasu.isPSEMode(modVarObjArrClone)) {
        setDefPriorPopActive(
          modVarObjArrClone,
          origModVarObjArr,
          pisc.AGYW_PP_MstID,
          piasu.checkAGYW_PriorPop(modVarObjArrClone)
        );
      }

      onModVarsChange(modVarObjArrClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  handleRadioGroupClick = (optionMstID, value, successFn) => {
    try {
      const props = this.props;
      let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);

      piasu.easyStartModeOptionOn(easyStartOptionObjArr, optionMstID, value === 0);

      onModVarsChange(modVarObjArrClone, false, () => {
        gbu.safeCallFn(successFn);
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onEnterExistTargsManCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.enterExistTargsManESMstID, checked);
  };

  onTargetsRadioGroupChange = (value) => {
    try {
      const props = this.props;
      let modVarObjArrClone = structuredClone(props[pias.modVarObjList]);
      const origModVarObjArr = props[pias.origModVarObjArr];
      const onModVarsChange = props[pias.onModVarsChange];

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.easyStartOptionsMVTag);

      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID, value === 0);
      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID, value === 1);
      piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID, value === 2);

      if (!piasu.isPSEMode(modVarObjArrClone)) {
        // PSE mode does not have AGYW pop
        setDefPriorPopActive(
          modVarObjArrClone,
          origModVarObjArr,
          pisc.AGYW_PP_MstID,
          piasu.checkAGYW_PriorPop(modVarObjArrClone)
        );
      }

      onModVarsChange(modVarObjArrClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onUploadNumClientsRadioGroupChange = (value) => {
    this.handleRadioGroupClick(pisc.uploadClientsInitESMstID, value);
  };

  onEstCostsImpactCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.estCostImpactsESMstID, checked);
  };

  onEstImpactTargetsCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.estImpactTargsESMstID, checked);
  };

  onDisagTargsSubnatCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.disaggTargsSubnatESMstID, checked);
  };

  onForecastPrevNeedsCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.forecastPrevProdNeedsESMstID, checked);
  };

  onAnalyzePerPersCostsCheckBoxClick = (checked, event, name, caption, info) => {
    this.handleCheckBoxClick(pisc.analyzePerPersCostsESMstID, checked);
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      const backPageID = piasu.getPageID(modVarObjArray, pic.gettingStartedFormOrder, pic.back);
      onPageChangeEvent = () => onPageChange(backPageID);
    } else if (direction === pic.next) {
      const nextPageID = piasu.getPageID(modVarObjArray, pic.gettingStartedFormOrder, pic.next);
      onPageChangeEvent = () => onPageChange(nextPageID);
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    }
  };

  onLevel1EditChange = (value) => {
    this.onLevelNameChange(1, value);
  };

  onLevel2EditChange = (value) => {
    this.onLevelNameChange(2, value);
  };

  onDownloadDataEntryTemplateBtnClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArr = props[pias.modVarObjList];

    if (this.canProcessFile()) {
      downloadPrEPitDataTemplateXLSX(
        {
          language: props.language,
          modVars: modVarObjArr,
        },
        (response) => {
          if (!piu.serverCallSuccessful(response)) {
            alert(RS(SC.GB_stGenTemplateError));
          }
        }
      );
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_stEnterSubnatLevel1);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //original code: alert(RS(SC.GB_stEnterSubnatLevel1));
    }
  };

  onUploadDataEntryTemplateBtnClick = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    try {
      if (this.canProcessFile()) {
        this.refs.importUploader.click();
      } else {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stEnterSubnatLevel1);
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);

        //alert(RS(SC.GB_stEnterSubnatLevel1));
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onReadFile = (event) => {
    try {
      let file = event.target.files[0];

      if (file) {
        console.log(`reading file ${file.name}`);

        this.uploadDataTemplate(file);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onResetEventTargetValue = (event) => {
    event.target.value = null;
  };

  onToggleSetTargsDialog = () => {
    this.setState({
      [showSetTargDialogBoolC]: !this.state[showSetTargDialogBoolC],
    });
  };

  onToggleEnterExistingTargsDialog = () => {
    this.setState({
      [showEnterExistingTargDialogBoolC]: !this.state[showEnterExistingTargDialogBoolC],
    });
  };

  onToggleSubNatLevlDialog = () => {
    this.setState({
      [showSubNatLevlDialogBoolC]: !this.state[showSubNatLevlDialogBoolC],
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderPSESelection = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    return (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
        }}
      >
        <TRadioGroup
          classes={{ label: this.props.classes.autonumberh1 }}
          caption={RS(SC.GB_stUsePSEOrOriginalTitle)}
          captionStyle={{
            ...Theme.labelStyle,
            //fontColor : "black",
          }}
          handleChange={this.onPSERadioGroupChange}
          itemIndex={piasu.isPSEMode(modVarObjList) ? 0 : 1}
          items={[RS(SC.GB_stUsePSEMode), RS(SC.GB_stUseOriginalMode)]}
          itemStyle={{
            display: "block",
            marginTop: 10,
          }}
          row={false}
          style={{
            display: "block",
            marginTop: Theme.topIndent,
            padding: 0,
          }}
        />
      </div>
    );
  };

  renderTargetsRadioGroup = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const setTargsBasedCovBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
    const enterExistTargsManBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);

    let itemIdx = 2;
    if (setTargsBasedCovBool) {
      itemIdx = 0;
    } else if (enterExistTargsManBool) {
      itemIdx = 1;
    }

    let setTargsInfoBtn = (
      <IconButton
        onClick={this.onToggleSetTargsDialog}
        style={{
          color: Theme.PI_SecondaryColor,
          cursor: "pointer",
          display: "inline-block",
          marginLeft: 10,
          marginTop: 16,
          // paddingLeft : 0,
          // paddingTop  : 0,
          padding: 0,
        }}
      >
        <Info
          style={{
            color: Theme.PI_SecondaryColor,
          }}
        />
      </IconButton>
    );

    const items = [RS(SC.GB_stSetTargetsBasedOnCov), RS(SC.GB_stEnterExistTargsMan)];
    if (!piasu.isPSEMode(modVarObjList)) items.push(RS(SC.GB_stDoNotSetTargets));

    let radioGroup = (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
        }}
      >
        <TRadioGroup
          classes={{ label: this.props.classes.autonumberh1 }}
          caption={RS(SC.GB_stHowSetTargs)}
          captionStyle={{
            ...Theme.labelStyle,
            //fontColor : "black",
          }}
          handleChange={this.onTargetsRadioGroupChange}
          itemIndex={itemIdx}
          items={items}
          itemStyle={{
            display: "block",
            marginTop: 10,
          }}
          row={false}
          style={{
            display: "block",
            marginTop: Theme.topIndent,
            padding: 0,
          }}
        />
        {setTargsInfoBtn}
      </div>
    );

    return radioGroup;
  };

  renderUploadNumClientsRadioGroup = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const setTargsBasedCovBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.setTargsBasedCovESMstID);
    const enterExistTargsManBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.enterExistTargsManESMstID);
    const uploadClientsInitBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.uploadClientsInitESMstID);

    let radioGroup = null;

    if (setTargsBasedCovBool || enterExistTargsManBool) {
      radioGroup = (
        <TRadioGroup
          classes={{ label: this.props.classes.autonumberh2 }}
          caption={RS(SC.GB_stUploadNumClientsQuestion)}
          captionStyle={{
            ...Theme.labelStyle,
            //fontColor : "black",
          }}
          handleChange={this.onUploadNumClientsRadioGroupChange}
          itemIndex={uploadClientsInitBool ? 0 : 1}
          items={[RS(SC.GB_stYes), RS(SC.GB_stNo)]}
          itemStyle={{
            marginTop: 10,
          }}
          row={true}
          style={{
            display: "block",
            marginLeft: Theme.leftIndent * 2,
            marginTop: Theme.topIndent,
            padding: 0,
          }}
        />
      );
    }

    return radioGroup;
  };

  renderEstCostsImpactCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const estCostImpactsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.estCostImpactsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onEstCostsImpactCheckBoxClick}
        enabled={true}
        key={"estCostsImpactCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={estCostImpactsBool}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stEstCostsTargets1)}
        &nbsp;
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stEstCostsTargets2)}
        </span>
        &nbsp;
        {RS(SC.GB_stEstCostsTargets3)}
      </p>
    );

    /* Since this is the first checkbox in section 4, put some space between it
           and the question. */
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderEstImpactTargetsCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);
    const countryISO = parseInt(piasu.getModVarValue(modVarObjList, pisc.countryISOMVTag));

    const estImpactTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.estImpactTargsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onEstImpactTargetsCheckBoxClick}
        enabled={countryISO !== 0}
        key={"estImpactTargetsCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={estImpactTargsBool}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stEstImpactTargets1)}
        &nbsp;
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stEstImpactTargets2)}
        </span>
        &nbsp;
        {RS(SC.GB_stEstImpactTargets3)}
      </p>
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderDisagTargsSubnatCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const disaggTargsSubnatBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.disaggTargsSubnatESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onDisagTargsSubnatCheckBoxClick}
        enabled={true}
        key={"disagTargsSubnatCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={disaggTargsSubnatBool}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stDisagTargetsSubnat1)}
        </span>
        &nbsp;
        {RS(SC.GB_stDisagTargetsSubnat2)}
      </p>
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderForecastPrevNeedsCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const forecastPrevPropNeedsBool = piasu.easyStartModeOptionOn(
      easyStartOptionObjArr,
      pisc.forecastPrevProdNeedsESMstID
    );

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onForecastPrevNeedsCheckBoxClick}
        enabled={true}
        key={"forecastPrevNeedsCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={forecastPrevPropNeedsBool}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stForecastPrevProdNeeds1)}
        </span>
        &nbsp;
        {RS(SC.GB_stForecastPrevProdNeeds2)}
      </p>
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderAnalyzePerPersCostsCheckBox = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

    const analyzePerPersCostsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.analyzePerPersCostsESMstID);

    const checkBox = (
      <TCheckBox
        caption={""}
        onClick={this.onAnalyzePerPersCostsCheckBoxClick}
        enabled={true}
        key={"analyzePerPersCostsCheckBox"}
        style={{
          color: Theme.PI_SecondaryColor,
          display: "block",
          height: "auto",
        }}
        value={analyzePerPersCostsBool}
      />
    );

    const label = (
      <p
        style={{
          color: Theme.PI_SecondaryColor,
          marginLeft: -15,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stAnalyzePerPersCosts)}
      </p>
    );

    /* Since this is the first checkbox in alternative section 3, put some space between it
           and the question. */
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {checkBox}
        {label}
      </div>
    );
  };

  renderSubnatControls = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjArr = props[pias.modVarObjList];

      const levels1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);

      let subnatDiv = null;

      if (!this.hideSubnatComponents()) {
        const specSubnatLevelsLab = (
          <p
            key={"specSubnatLevelsLab"}
            className={this.props.classes.autonumberh1}
            style={{
              ...Theme.labelStyle,
              marginBottom: 0,
              marginTop: 10,
              padding: 0,
            }}
          >
            {RS(SC.GB_stSpecSubnatLevels)}
          </p>
        );

        const specSubnatLevlsInfoBtn = (
          <IconButton
            onClick={this.onToggleSubNatLevlDialog}
            style={{
              color: Theme.PI_SecondaryColor,
              cursor: "pointer",
              display: "inline-block",
              marginLeft: 10,
              marginTop: 5,
              padding: 0,
            }}
          >
            <Info
              style={{
                color: Theme.PI_SecondaryColor,
              }}
            />
          </IconButton>
        );

        const level1Edit = (
          <TEdit
            caption={RS(SC.GB_stLevel1)}
            onChange={this.onLevel1EditChange}
            style={{
              marginBottom: 0,
              marginTop: 10,
              width: 150,
            }}
            type={"string"}
            value={piasu.adminSubnatLevelName(levels1DStrArr, 1) ?? ""}
          />
        );

        const level2Edit = (
          <TEdit
            caption={RS(SC.GB_stLevel2)}
            onChange={this.onLevel2EditChange}
            style={{
              marginBottom: 0,
              marginTop: 10,
              width: 150,
            }}
            type={"string"}
            value={piasu.adminSubnatLevelName(levels1DStrArr, 2) ?? ""}
          />
        );

        subnatDiv = (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              {specSubnatLevelsLab}
              {specSubnatLevlsInfoBtn}
            </div>
            {level1Edit}
            {level2Edit}
          </div>
        );
      }

      return subnatDiv;
    };

    // return gbu.tryRenderFn(fn, "renderSubnatControls");
    return fn();
  };

  renderDataTemplateCtrls = () => {
    if (piasu.isPSEMode(this.props.modVarObjList)) return null;

    const props = this.props;
    const modVarObjList = props.modVarObjList;

    const countrySelected = piasu.getCountrySelected(modVarObjList);

    const genDataEntryTemplateBtn = (
      <TButton
        caption={RS(SC.GB_stGenDataEntryTemplate)}
        containerStyle={{
          display: "block",
        }}
        disabled={!countrySelected}
        key={"genDataEntryTemplateBtn"}
        onClick={this.onDownloadDataEntryTemplateBtnClick}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          marginTop: 10,
          width: 250,
        }}
      />
    );

    const inputDialog = (
      <input
        type={"file"}
        accept={".xlsx"}
        ref={"importUploader"}
        style={{
          display: "none",
        }}
        onChange={this.onReadFile}
        onClick={(event) => this.onResetEventTargetValue(event)}
      />
    );

    const uploadDataEntryTemplateBtn = (
      <TButton
        caption={RS(SC.GB_stUploadDataEntryTemplate)}
        containerStyle={{
          display: "block",
          marginTop: 20,
        }}
        disabled={!countrySelected}
        key={"uploadDataEntryTemplateBtn"}
        onClick={this.onUploadDataEntryTemplateBtnClick}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          //marginLeft      : Theme.leftIndent,
          width: 250,
        }}
      />
    );

    const dataTemplateDiv = (
      <div
        style={{
          marginBottom: 10,
        }}
      >
        {genDataEntryTemplateBtn}
        {inputDialog}
        {uploadDataEntryTemplateBtn}
      </div>
    );

    return dataTemplateDiv;
  };

  renderDoUploadDialog = () => {
    const fn = () => {
      let dialog = null;

      const state = this.state;
      const showDoUploadDialogBool = state[showDoUploadDialogBoolC];

      if (showDoUploadDialogBool) {
        const captionDiv = (
          <div
            style={{
              color: Theme.PI_PrimaryColor,
              fontSize: 20,
            }}
          >
            {"Communicating with server..."}
          </div>
        );

        const contentDiv = (
          <div>
            <div
              style={{
                fontSize: 16,
                color: Theme.gray,
              }}
            >
              {"Uploading data template..."}
            </div>

            <div
              style={{
                fontSize: 12,
              }}
            >
              <br />
              {"Working on: Uploading data template"}
            </div>
          </div>
        );

        dialog = (
          <TDialog
            caption={captionDiv}
            header={""} // needed for dialog to show icon and caption
            //icon    = {wifiAnimationIcon}
            content={contentDiv}
            modal={true}
            visible={true}
            actions={[]}
            style={{
              width: 575,
            }}
          />
        );
      }

      return dialog;
    };

    return gbu.tryRenderFn(fn, "renderDoUploadDialog");
  };

  renderSetTargDialog = () => {
    let dialog = null;

    const state = this.state;
    const showSetTargDialogBool = state[showSetTargDialogBoolC];

    if (showSetTargDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      const liStyle = {
        color: Theme.PI_TertiaryColor,
        fontWeight: 700,
      };

      const setTargBlurbText = (
        <React.Fragment>
          <p
            style={{
              //marginLeft : Theme.leftIndent,
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stSetTargetsBasedOnCov).replace(".", "") + ": "}</span>
            {RS(SC.GB_stGettingStartedInfo2)}
          </p>
          <p
            style={{
              //marginLeft : Theme.leftIndent,
              ...Theme.textFontStyle,
            }}
          >
            <span style={liStyle}>{RS(SC.GB_stEnterExistTargsMan).replace(".", "") + ": "}</span>
            {RS(SC.GB_stGettingStartedInfo3)}
          </p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[setTargBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleSetTargsDialog}
        />
      );
    }

    return dialog;
  };

  renderEnterExistingTargDialog = () => {
    let dialog = null;

    const state = this.state;
    const showEnterExistingTargDialogBool = state[showEnterExistingTargDialogBoolC];

    if (showEnterExistingTargDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      // const liStyle = {
      //     color      : Theme.PI_TertiaryColor,
      //     fontWeight : 700,
      // };

      const enterExistingTargBlurbText = (
        <React.Fragment>
          <p
            style={{
              //marginLeft : Theme.leftIndent,
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stGettingStartedInfo3)}
          </p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[enterExistingTargBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleEnterExistingTargsDialog}
        />
      );
    }

    return dialog;
  };

  renderSubNatLevlDialog = () => {
    let dialog = null;

    const state = this.state;
    const showSubNatLevlDialogBool = state[showSubNatLevlDialogBoolC];

    if (showSubNatLevlDialogBool) {
      const dialogHeader = (
        <p
          key={"dialogHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      const subNatLevlBlurbText = (
        <React.Fragment>
          <p style={Theme.textFontStyle}>{RS(SC.GB_stGettingStartedInfo5)}</p>
        </React.Fragment>
      );

      dialog = (
        <TDialog
          actions={["mrClose"]}
          content={[subNatLevlBlurbText]}
          header={[dialogHeader]}
          key={"effectivenessDialog"}
          onClose={this.onToggleSubNatLevlDialog}
        />
      );
    }

    return dialog;
  };

  renderRetrievingDataDialog = () => {
    const retrievingData = this.state.retrievingData;
    if (!retrievingData) return null;

    const captionDiv = (
      <div
        style={{
          color: Theme.PI_PrimaryColor,
          fontSize: 20,
        }}
      >
        {"Communicating with server..."}
      </div>
    );

    const wifiAnimationIcon = (
      <TWifiAnimation
        style={{
          marginRight: 20,
          float: "left",
        }}
      />
    );

    const contentDiv = (
      <div>
        <div
          style={{
            fontSize: 16,
            color: Theme.gray,
          }}
        >
          {"Retrieving data..."}
        </div>
      </div>
    );

    return (
      <TDialog
        caption={captionDiv}
        header={""}
        icon={wifiAnimationIcon}
        content={contentDiv}
        modal={true}
        visible={true}
        actions={[]}
        style={{
          width: 500,
        }}
      />
    );
  };

  render() {
    try {
      const props = this.props;
      const countryObjArr = props[pias.countryObjList];
      const modVarObjList = props[pias.modVarObjList];

      const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);
      const pseMode = piasu.isPSEMode(modVarObjList);

      const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

      const countrySelected = piasu.getCountrySelected(modVarObjList);
      const countryCode = piasu.getModVarValue(modVarObjList, pisc.countryISOMVTag);
      const pseAvailable =
        !window.DISABLE_PSE_MODE &&
        (countryObjArr.find((v) => v.ISONumeric.toString() === countryCode.toString())?.PSEAvailable ?? false);

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      let countryComboBoxText = <p>{RS(SC.GB_stGettingStartedInfo1) + "."}</p>;

      let countryComboBox = (
        <PICountryComboBox
          {...{
            [pias.countryObjList]: countryObjArr,
            [pias.modVarObjList]: modVarObjList,
          }}
          onChange={this.onCountryComboBoxChange}
        />
      );

      let fourTargOptionsLab = null;
      let estCostsImpactCheckBox = null;
      let estImpactTargetsCheckBox = null;
      let disagTargsSubnatCheckBox = null;
      let forecastPrevNeedsCheckBox = null;

      let altQuestThreeSelectOptionsLab = null;
      let analyzePerPersCostsCheckBox = null;

      let targetsRadioGroup = null;
      let uploadNumClientsRadioGroup = null;
      let subnatControls = null;
      let dataTemplateControls = null;

      if (countrySelected) {
        targetsRadioGroup = this.renderTargetsRadioGroup();
        uploadNumClientsRadioGroup = this.renderUploadNumClientsRadioGroup();

        if (doNotSetTargsBool) {
          altQuestThreeSelectOptionsLab = (
            <p
              className={this.props.classes.autonumberh1}
              style={{
                marginBottom: 0,
                marginTop: Theme.topIndent,
                ...Theme.labelStyle,
              }}
            >
              {RS(SC.GB_stSelectOptionsToUse)}
            </p>
          );
          analyzePerPersCostsCheckBox = this.renderAnalyzePerPersCostsCheckBox();
        } else {
          fourTargOptionsLab = (
            <p
              className={this.props.classes.autonumberh1}
              style={{
                marginBottom: 0,
                marginTop: Theme.topIndent,
                ...Theme.labelStyle,
              }}
            >
              {RS(SC.GB_stSelectOptionsToUse)}
            </p>
          );
          estCostsImpactCheckBox = this.renderEstCostsImpactCheckBox();
          estImpactTargetsCheckBox = this.renderEstImpactTargetsCheckBox();
          disagTargsSubnatCheckBox = this.renderDisagTargsSubnatCheckBox();
          forecastPrevNeedsCheckBox = this.renderForecastPrevNeedsCheckBox();
        }

        subnatControls = this.renderSubnatControls();
        dataTemplateControls = this.renderDataTemplateCtrls();
      }

      const backPageID = piasu.getPageID(modVarObjList, pic.gettingStartedFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.gettingStartedFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.enableNextBtn]: countrySelected,
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noNextOrPrevForm,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noNextOrPrevForm,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      const doUploadDialog = this.renderDoUploadDialog();
      const setTargDialog = this.renderSetTargDialog();
      const enterExistingTargDialog = this.renderEnterExistingTargDialog();
      const subNatLevlDialog = this.renderSubNatLevlDialog();

      // Workaround for chrome bug where autonumbering fails to happen if you
      // make a non-pse session, then change to a PSE session. Item 3 ends up
      // labelled "4".
      const formKey = `getting-started${pseMode ? "-pse" : ""}`;

      return (
        <div
          className={this.props.classes.autonumberheaders}
          key={formKey}
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {countryComboBoxText}
          {countryComboBox}

          {countrySelected && pseAvailable && this.renderPSESelection()}

          {targetsRadioGroup}
          {uploadNumClientsRadioGroup}

          {fourTargOptionsLab}
          {estCostsImpactCheckBox}
          {estImpactTargetsCheckBox}
          {disagTargsSubnatCheckBox}
          {forecastPrevNeedsCheckBox}

          {altQuestThreeSelectOptionsLab}
          {analyzePerPersCostsCheckBox}

          {subnatControls}
          {dataTemplateControls}

          {navBtnDiv}

          {doUploadDialog}
          {setTargDialog}
          {enterExistingTargDialog}
          {subNatLevlDialog}
          {this.renderRetrievingDataDialog()}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default withStyles(styles)(PIGettingStartedForm);
