import axios from "axios";
import { v4 as uuidv4 } from 'uuid'

//==================================================================================================================
//
//                     ##     ## ######## #### ##       #### ######## #### ########  ######
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##       ##    ##
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##       ##
//                     ##     ##    ##     ##  ##        ##     ##     ##  ######    ######
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##             ##
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##       ##    ##
//                      #######     ##    #### ######## ####    ##    #### ########  ######
//
//==================================================================================================================

// ...

//==================================================================================================================
//
//                ##     ##    ###    ########  ####    ###    ########  ##       ########  ######
//                ##     ##   ## ##   ##     ##  ##    ## ##   ##     ## ##       ##       ##    ##
//                ##     ##  ##   ##  ##     ##  ##   ##   ##  ##     ## ##       ##       ##
//                ##     ## ##     ## ########   ##  ##     ## ########  ##       ######    ######
//                 ##   ##  ######### ##   ##    ##  ######### ##     ## ##       ##             ##
//                  ## ##   ##     ## ##    ##   ##  ##     ## ##     ## ##       ##       ##    ##
//                   ###    ##     ## ##     ## #### ##     ## ########  ######## ########  ######
//
//==================================================================================================================

const CONTAINER_BASE_URL = window.API_URL_BASE; // Controller url
const CONTAINER_LOGIN_URL = CONTAINER_BASE_URL + "/api/v1/session/"; // Talk to controller to ask for a container; get a token back
const CONTAINER_STATUS_URL = CONTAINER_BASE_URL + "/api/v1/server/"; // Talk to controller to check 'token' until container is ready
const CONTAINER_SESSION_URL = CONTAINER_BASE_URL + "/r/"; // Talk to RemObjects server on the container
const CONTAINER_TAG = window.containerVersion; // Ability to declare a custom container to get.  Blank is latest.
const CONTAINER_NEW = "instance="; // Adding a new instance value guarentees a new container every time

const CONTAINER_USER = "PrEPit_Client"; //'guest';                     // User for the controller
const CONTAINER_PASS = "guest123"; //'';                             // Password for the user

const CONSOLE_LOGGING = true; // Show console.log messages for each step (debugging purposes)

//==================================================================================================================
//
//                  ######   #######  ##    ## ########    ###    #### ##    ## ######## ########
//                 ##    ## ##     ## ###   ##    ##      ## ##    ##  ###   ## ##       ##     ##
//                 ##       ##     ## ####  ##    ##     ##   ##   ##  ####  ## ##       ##     ##
//                 ##       ##     ## ## ## ##    ##    ##     ##  ##  ## ## ## ######   ########
//                 ##       ##     ## ##  ####    ##    #########  ##  ##  #### ##       ##   ##
//                 ##    ## ##     ## ##   ###    ##    ##     ##  ##  ##   ### ##       ##    ##
//                  ######   #######  ##    ##    ##    ##     ## #### ##    ## ######## ##     ##
//
//==================================================================================================================

const RequestToken = (params, successFn, errorFn) => {
  const url = CONTAINER_LOGIN_URL + params.userName + "?password=" + params.password;

  if (CONSOLE_LOGGING) {
    console.log("Requesting token... " + url);
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    })
    .post(url)
    .then((response) => {
      if (CONSOLE_LOGGING) {
        console.log("Got Token: " + response.data.token);
      }
      successFn(response.data.token);
    })
    .catch((err) => {
      errorFn(err.message);
    });
};

const RequestLinkToken = (params, successFn, errorFn) => {
  const token = params.token;
  const url = CONTAINER_STATUS_URL + token + "?" + CONTAINER_TAG + "&" + CONTAINER_NEW + params.instance;

  if (CONSOLE_LOGGING) {
    console.log("Requesting linkToken... " + url);
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "SpecW-Auth": "token " + token,
      },
    })
    .post(url)
    .then((response) => {
      if (response.data.atCapacity) {
        errorFn("Error: Containers are at full capacity");
      } else if (response.data.ready) {
        if (CONSOLE_LOGGING) {
          console.log("Got linkToken: " + response.data.linkToken);
        }
        successFn(response.data.linkToken);
      } else {
        setTimeout(() => {
          RequestLinkToken(params, successFn, errorFn);
        }, 1000);
      }
    })
    .catch((err) => {
      errorFn(err.message);
    });
};

const GetContainerAccess = (successFn, errorFn) => {
  if (typeof errorFn === "undefined") {
    errorFn = () => {
      alert("errorFn was not passed in.");
    };
  }

  RequestToken(
    { userName: CONTAINER_USER, password: CONTAINER_PASS },
    (token) => {
      RequestLinkToken(
        { token: token, instance: uuidv4() },
        (linkToken) => {
          successFn({
            url: CONTAINER_SESSION_URL + linkToken + "/JSON",
          });
        },
        errorFn
      );
    },
    errorFn
  );
};

export default GetContainerAccess;
