import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";

import {
  PICustomRowTableProps,
  firstRow,
  hiddenColOffset,
  sharedPropTypes,
  sharedDefaultProps,
  initialState,
  getDeleteBtnCol,
  getNumCols,
  AddButton,
  DeleteButton,
  handleCustomItemCalculate,
} from "../Other/PICustomRowsUtil";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const itemNameColumn = hiddenColOffset;
const lastColumn = itemNameColumn;

export class PIContVisitScheduleTable extends Component {
  static propTypes = {
    ...sharedPropTypes,
  };

  static defaultProps = {
    ...sharedDefaultProps,

    [pip.tableKey]: this.props?.[pip.tableKey] ?? "",
  };

  state = initialState;

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];
      const modVarObjListClone = structuredClone(props[pias.modVarObjList]);

      const objList = piasu.getModVarValue(modVarObjListClone, pisc.contVisitSchedLiteMVTag);
      const numObjects = piasu.getTotalNumContVisitSchedules(objList);

      for (let row = 1; row <= numObjects; row++) {
        /* Users can change the name in the first visible column now. */
        const itemName = gbtu.getValue(newPackTable, row, itemNameColumn);
        piasu.setContVisitSchedName(objList, row, itemName);
      }

      this.setState({ [pip.rDec]: newPackTable[gbtc.rDec] }, async () =>
        handleCustomItemCalculate(onCalculatingChange, onModVarsChange, modVarObjListClone, onDialogChange)
      );
    } catch (exception) {
      alert(exception.name + " (PIContVisitScheduleTable.onPackTableChange) : " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const allowEditsBool = props[PICustomRowTableProps.allowEditsBoolC];
      const tableKey = this.state[pip.tableKey];

      const state = this.state;
      const focusedCell = state[pip.focusedCell];
      const selectedRegions = state[pip.selectedRegions];
      const rDec = state[pip.rDec];

      let objList = piasu.getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);

      const numObjects = piasu.getTotalNumContVisitSchedules(objList);

      const deleteBtnCol = getDeleteBtnCol(this.props, lastColumn);
      const numCols = getNumCols(this.props, lastColumn);

      const numRows = numObjects + 1;

      let packTable = gbtu.getNewPackTable();

      let frameworkComponents = allowEditsBool ? {} : null;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, itemNameColumn, RS(SC.GB_stContVisitSchedules));
      if (allowEditsBool) {
        gbtu.setValue(packTable, firstRow, deleteBtnCol, RS(SC.GB_stDeleteBtn));
      }

      for (let item = 1; item <= numObjects; item++) {
        /* Set row values. */
        const itemName = piasu.getContVisitSchedName(objList, item);
        gbtu.setValue(packTable, item, itemNameColumn, itemName);

        if (allowEditsBool) {
          /* Need to save these into variables before passing or the link component will be sent the wrong row, col. */
          let rowPassed = item;
          let colPassed = deleteBtnCol;

          let btnKey = "btn row" + rowPassed + "_col" + colPassed;
          packTable.components[rowPassed][colPassed] = () => (
            <DeleteButton
              id={btnKey}
              row={rowPassed}
              itemType={pic.contVisitSchedItems}
              props={this.props}
              state={this.state}
              setState={this.setState.bind(this)}
            />
          );
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setColAlignment(packTable, deleteBtnCol, gbtc.hAlign.center);
      /* Needed when entering text. */
      gbtu.setMinAllowedValByCol(packTable, itemNameColumn, 0);
      gbtu.setMaxAllowedValByCol(packTable, itemNameColumn, 0);
      /* Hide the first column so the user can type in the first visible column. */
      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, itemNameColumn, Theme.itemNameColWidth);
      if (allowEditsBool) {
        gbtu.setColWidth(packTable, deleteBtnCol, Theme.dataColWidthSmall);
      }
      gbtu.setRowHeight(packTable, firstRow, 120);
      gbtu.setWordWrappedCol(packTable, 0, true);

      if (!allowEditsBool) {
        gbtu.lockPackTable(packTable, true);
      }
      gbtu.lockCol(packTable, deleteBtnCol, true, false);

      if (rDec.length === 0) {
        gbtu.setRDecs(packTable, 1);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIContVisitScheduleTable");
        console.log("ModVar(s):");
        console.log(pisc.contVisitSchedLiteMVTag);
        console.log(objList);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          focusedCell={focusedCell}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          frameworkComponents={frameworkComponents}
          gridKey={tableKey}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{
            ...packTable,
            GBFixedCols: 0,
            GBRowHeights: packTable.GBRowHeights.map((rowHeight, index) => (index === 0 ? rowHeight : -1)),
          }}
          types={generateTypes(packTable, [
            ...repeat("s", packTable.GBFixedCols + 1),
            ...repeat("n", packTable.GBColCount - packTable.GBFixedCols - 2),
            "cm",
          ])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={selectedRegions}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          limitWidthToContainer={true}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIContVisitScheduleTable");
  };

  render() {
    return (
      <>
        {this.renderTable()}
        {
          <AddButton
            itemType={pic.contVisitSchedItems}
            props={this.props}
            state={this.state}
            setState={this.setState.bind(this)}
          />
        }
      </>
    );
  }
}
