// @ts-check
import React from "react";
import { useState } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as gbtu from "../../GB/GBTableUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbtc from "../../GB/GBTableConst";
import * as pitu from "../NonComponents/PITableUtil";

import * as Theme from "../../../app/Theme";

import { sortPopFn } from "../NonComponents/PIPriorPopUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

import { pseIncidenceCategories } from "../NonComponents/PIUtil";
import { combinePriorPops, contCurveCombinedPops, combinedPopsByCategory } from "../NonComponents/PIPriorPopUtil";

const priorPopCol = 0;
const mediumIncCol = 1;
const highIncCol = 2;
const veryHighIncCol = 3;
const potUsersCol = 4;
const firstRow = 0;

const PIPopulationsTargTablePSE = (props) => {
  const modVarObjList = props.modVarObjList;

  /** @type {any[]} */
  const allPops = structuredClone(piasu.getModVarValue(modVarObjList, "PI_PriorityPop")).sort((a, b) =>
    sortPopFn(a.mstID, b.mstID)
  );
  let priorPops = allPops;

  const categories = Object.keys(pseIncidenceCategories);
  const baseCombineInfo = contCurveCombinedPops;
  const genAndKeyPopsWithCategories = combinedPopsByCategory(baseCombineInfo, allPops, categories);

  const combined = combinePriorPops(modVarObjList, genAndKeyPopsWithCategories);
  priorPops = combined["PI_PriorityPop"];

  const popBaseIDs = Object.keys(baseCombineInfo).sort(sortPopFn);

  // State
  const [rDec, setRDecs] = useState([]);

  const isPSEMode = piasu.isPSEMode(modVarObjList);
  if (!isPSEMode) {
    return <div>PIPopulationsTargTablePSE requires PSE mode</div>;
  }

  // Callbacks
  const onPackTableChange = (newPackTable) => {
    setRDecs(structuredClone(newPackTable.RDec));
  };

  // Table
  const numCols = 5;
  const numRows = popBaseIDs.length + 1;

  const packTable = gbtu.resizePackTable(gbtu.getNewPackTable(), numRows, numCols);

  // Headings
  gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));
  gbtu.setValue(packTable, firstRow, potUsersCol, RS(SC.GB_stTotalPotentialUsers));
  gbtu.setValue(packTable, firstRow, mediumIncCol, RS(SC.GB_stMediumIncidence));
  gbtu.setValue(packTable, firstRow, highIncCol, RS(SC.GB_stHighIncidence));
  gbtu.setValue(packTable, firstRow, veryHighIncCol, RS(SC.GB_stVeryHighIncidence));

  // Data
  for (let pp = 0; pp < popBaseIDs.length; ++pp) {
    const popBaseID = popBaseIDs[pp];

    const totals = Object.fromEntries(
      Object.keys(pseIncidenceCategories).map((cat) => [
        cat,
        priorPops.find((v) => v.mstID === `${popBaseID}_${cat.toUpperCase()}`)?.potential_users_pse ?? 0,
      ])
    );

    gbtu.setValue(packTable, pp + 1, priorPopCol, baseCombineInfo[popBaseID].newPopName());
    gbtu.setValue(
      packTable,
      pp + 1,
      potUsersCol,
      Object.values(totals).reduce((a, b) => a + b, 0)
    );
    gbtu.setValue(packTable, pp + 1, mediumIncCol, totals.moderate ?? 0);
    gbtu.setValue(packTable, pp + 1, highIncCol, totals.high ?? 0);
    gbtu.setValue(packTable, pp + 1, veryHighIncCol, totals["very high"] ?? 0);

    gbtu.lockCell(packTable, pp + 1, potUsersCol, true);
    gbtu.lockCell(packTable, pp + 1, mediumIncCol, true);
    gbtu.lockCell(packTable, pp + 1, highIncCol, true);
    gbtu.lockCell(packTable, pp + 1, veryHighIncCol, true);
  }

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
  gbtu.setRowHeight(packTable, firstRow, 75);
  gbtu.setColWidths(packTable, Theme.dataColWidthMed);
  gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
  gbtu.setWordWrappedCol(packTable, priorPopCol, true);

  gbtu.restoreRDecsFromCopy(packTable, rDec, 0);

  return (
    // @ts-ignore
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      key={"populationsTable"}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={onPackTableChange}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        tableFont: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
      undoDisabled={false}
    />
  );
};

PIPopulationsTargTablePSE.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
};

export default PIPopulationsTargTablePSE;
