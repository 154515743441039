import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TTabs2 from "../../common/TTabs2";

import Divider from "@material-ui/core/Divider";

import * as Theme from "../../../app/Theme";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";

import PICostsLiteResultContent from "../ResultContent/PICostsLiteResultContent";
import PICostsLiteAggResultContent from "../ResultContent/PICostsLiteAggResultContent";
import PIAGYWResultContent from "../ResultContent/PIAGYWResultContent";
import PITargetsResultContent from "../ResultContent/PITargetsResultContent";
import PIDisagTargetsResultContent from "../ResultContent/PIDisagTargetsResultContent";
import PICommodityForecastingResultContent from "../ResultContent/PICommodityForecastingResultContent";

import * as php from "../NonComponents/PIHelp";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import { onCalculate } from "../NonComponents/PICalc";
import * as gbu from "../../GB/GBUtil";

import PINavBtnDiv from "../Other/PINavBtnDiv";
import { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import { getModVarValue } from "../NonComponents/PIAppStateUtil";
import PIProgDataResultContent from "../ResultContent/PIProgDataResultContent";

/* Tab areas */
const progDataArea = 1;
const costsLiteArea = 2;
const AGYWArea = 3;
const targetsArea = 4;
const disagTargArea = 5;
const commForecArea = 6;

class PIResultsDashboardForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.capacityFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),
  };

  constructor(props) {
    super(props);

    const modVarObjArr = this.props[pias.modVarObjList];

    /* Timeframe for indicators will start the same as the Program data period. It can be
           changed to be a subset of it. We only care about it locally. */
    const indProgDataPeriodObj = structuredClone(piasu.getProgDataPeriodObj(modVarObjArr));

    this.state = {
      selectedTabIdx: 0,
      [pip.indProgDataPeriodObj]: indProgDataPeriodObj,
    };
  }

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.resultDashboard_CostTB_HP, () => {
      this.onTabIdxChange(this.state.selectedTabIdx);
    });
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  showFullAGYWResultsForDashboard = () => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];
    return piasu.showTargets(modVarObjArr);
  };

  getTabIdx = (tabArea) => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];

    const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

    const moreThanInitsCheckedBool = piasu.moreThanInitsChecked(modVarObjArr);
    const templateUploadedBool = piasu.getModVarValue(modVarObjArr, pisc.progDataTemplateUploadedMVTag);

    const pseMode = piasu.isPSEMode(modVarObjArr);

    let tabIdx = -1;
    let foundTab = false;

    const progDataShowing = piasu.showProgramData(modVarObjArr);

    // Temporarily have false here to always hide Program data results.
    // MARK -- multiple areas in this form do this!!!
    if (false && progDataShowing && templateUploadedBool && moreThanInitsCheckedBool) {
      tabIdx++;

      if (tabArea === progDataArea) {
        foundTab = true;
      }
    }

    const costsLiteTabShowing = piasu.showCostsLite(modVarObjArr, false, true);

    if (!foundTab && costsLiteTabShowing) {
      tabIdx++;

      if (tabArea === costsLiteArea) {
        foundTab = true;
      }
    }

    const AGYWTabShowing = piasu.showAGYWTool(modVarObjArr) && appModeMstIDStr !== pisc.aggregateToolMstID;

    if (!foundTab && AGYWTabShowing) {
      tabIdx++;

      if (tabArea === AGYWArea) {
        foundTab = true;
      }
    }

    const targetsTabShowing = piasu.showTargets(modVarObjArr, false, true);

    if (!foundTab && targetsTabShowing) {
      tabIdx++;

      if (tabArea === targetsArea) {
        foundTab = true;
      }
    }

    const disagTargetsTabShowing =
      piasu.showDisagTargets(modVarObjArr) && (pseMode || appModeMstIDStr === pisc.aggregateToolMstID);

    if (!foundTab && disagTargetsTabShowing) {
      tabIdx++;

      if (tabArea === disagTargArea) {
        foundTab = true;
      }
    }

    const commForecTabShowing =
      piasu.showCommoditiesForecasting(modVarObjArr) && appModeMstIDStr === pisc.aggregateToolMstID;

    if (!foundTab && commForecTabShowing) {
      tabIdx++;

      if (tabArea === commForecArea) {
        foundTab = true;
      }
    }

    if (foundTab) {
      return tabIdx;
    } else {
      return -1;
    }
  };

  getTabs = () => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];
    //const costingModeMstID = piasu.getModVarValue(modVarObjArr, pisc.costingModuleMVTag);

    //const showAGYWToolBool = piasu.showAGYWTool(modVarObjArr);
    const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);
    const templateUploadedBool = piasu.getModVarValue(modVarObjArr, pisc.progDataTemplateUploadedMVTag);

    const moreThanInitsCheckedBool = piasu.moreThanInitsChecked(modVarObjArr);

    const pseMode = piasu.isPSEMode(modVarObjArr);

    let tabs1DStrArr = [];

    // Temporarily have false here to always hide Program data results.
    // MARK -- multiple areas in this form do this!!!
    const progDataShowing = piasu.showProgramData(modVarObjArr);

    if (false && progDataShowing && templateUploadedBool && moreThanInitsCheckedBool) {
      tabs1DStrArr.push(RS(SC.GB_stProgramData));
    }

    if (piasu.showCostsLite(modVarObjArr, false, true)) {
      tabs1DStrArr.push(RS(SC.GB_stCostsLite));
    }

    if (piasu.showAGYWTool(modVarObjArr) && appModeMstIDStr !== pisc.aggregateToolMstID) {
      tabs1DStrArr.push(RS(SC.GB_stAGYW));
    }

    if (piasu.showTargets(modVarObjArr, false, true)) {
      tabs1DStrArr.push(RS(SC.GB_stTargets));
    }

    if (piasu.showDisagTargets(modVarObjArr) && (pseMode || appModeMstIDStr === pisc.aggregateToolMstID)) {
      tabs1DStrArr.push(RS(SC.GB_stSubnationalTargets));
    }

    if (piasu.showCommoditiesForecasting(modVarObjArr) && appModeMstIDStr === pisc.aggregateToolMstID) {
      tabs1DStrArr.push(RS(SC.GB_stCommoditiesForecasting));
    }

    return tabs1DStrArr;
  };

  getNumTabs = () => {
    return this.getTabs().length;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArr = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const onCalculatingChange = props[pias.onCalculatingChange];

    const appModeMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.appModeMVTag);

    if (appModeMstIDStr === pisc.aggregateToolMstID) {
      this.setState({
        selectedTabIdx: tabIdx,
      });
    } else {
      onCalculatingChange(true, () => {
        onCalculate(
          modVarObjArr,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false, () => {
                this.setState({
                  selectedTabIdx: tabIdx,
                });
              });
            });
          },
          () => onCalculatingChange(false)
        );
      });
    }
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    const state = this.state;
    let selectedTabIdx = state.selectedTabIdx;

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === 0) {
        const backPageID = piasu.getPageID(modVarObjArray, pic.resultsDashboardFormOrder, pic.back);
        onPageChangeEvent = () => onPageChange(backPageID);
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx !== this.getNumTabs() - 1) {
        selectedTabIdx++;
      } else {
        const nextPageID = piasu.getPageID(modVarObjArray, pic.resultsDashboardFormOrder, pic.next);
        onPageChangeEvent = () => onPageChange(nextPageID);
      }
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  /* Timeframe for indicators must be a subset of the program data timeframe. */
  onIndProgDataPeriodChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      const state = this.state;
      let indProgDataPeriodObj = structuredClone(state[pip.indProgDataPeriodObj]);

      piasu.setTimeframeData(indProgDataPeriodObj, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.indProgDataPeriodObj]: indProgDataPeriodObj,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderSelectedTabContent = () => {
    const fn = () => {
      const props = this.props;
      const onCalculatingChange = props[pias.onCalculatingChange];
      const modVarObjArr = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const selectedTabIdx = state.selectedTabIdx;
      const indProgDataPeriodObj = state[pip.indProgDataPeriodObj];

      const appModeMstIDStr = getModVarValue(modVarObjArr, pisc.appModeMVTag);

      const aggModeBool = appModeMstIDStr === pisc.aggregateToolMstID;

      let tabContent = null;

      if (selectedTabIdx === this.getTabIdx(progDataArea)) {
        tabContent = (
          <PIProgDataResultContent
            {...{
              [pip.indProgDataPeriodObj]: indProgDataPeriodObj,
              [pip.onIndProgDataPeriodChange]: this[pip.onIndProgDataPeriodChange],

              [pias.modVarObjList]: modVarObjArr,
            }}
          />
        );
      } else if (selectedTabIdx === this.getTabIdx(costsLiteArea)) {
        if (aggModeBool) {
          tabContent = (
            <PICostsLiteAggResultContent
              {...{
                [pias.modVarObjList]: modVarObjArr,
                [pias.onModVarsChange]: onModVarsChange,
              }}
            />
          );
        } else {
          tabContent = (
            <PICostsLiteResultContent
              {...{
                [pias.modVarObjList]: modVarObjArr,
                [pias.onModVarsChange]: onModVarsChange,
                [pip.showMethodComboBox]: true,
              }}
            />
          );
        }
      } else if (selectedTabIdx === this.getTabIdx(AGYWArea)) {
        tabContent = (
          <PIAGYWResultContent
            {...{
              [pias.modVarObjList]: modVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.showInDashboardAreaBool]: this.showFullAGYWResultsForDashboard(),
            }}
          />
        );
      } else if (selectedTabIdx === this.getTabIdx(targetsArea)) {
        tabContent = (
          <PITargetsResultContent
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.modVarObjList]: modVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.showReportingPeriodDiv]: true,

              [pip.showTargetSettingPeriodLab]: true,

              [pip.showInDashboardAreaBool]: true,
            }}
          />
        );
      } else if (selectedTabIdx === this.getTabIdx(disagTargArea)) {
        tabContent = (
          <PIDisagTargetsResultContent
            modVarObjList={modVarObjArr}
            origModVarObjArr={props[pias.origModVarObjArr]}
            onModVarsChange={onModVarsChange}
            onCalculatingChange={onCalculatingChange}
            onDialogChange={props[pias.onDialogChange]}
          />
        );
      } else if (selectedTabIdx === this.getTabIdx(commForecArea)) {
        tabContent = (
          <PICommodityForecastingResultContent
            {...{
              [pias.modVarObjList]: modVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,
              [pip.showMethodComboBox]: true,
            }}
          />
        );
      }

      return <React.Fragment>{tabContent}</React.Fragment>;
    };

    return gbu.tryRenderFn(fn, "renderSelectedTabContent");
  };

  render() {
    try {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const selectedTabIdx = state.selectedTabIdx;

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stResultsDashboard)}
        </p>
      );

      let tabContents1DStrArr = new Array(this.getNumTabs());
      tabContents1DStrArr.fill("");

      const tabs = (
        <TTabs2
          onChange={this.onTabIdxChange}
          selectedTabIdx={selectedTabIdx}
          style={{
            marginTop: 10,
          }}
          tabBackgroundColor={"inherit"}
          tabBarOutline={"none"}
          tabContents={tabContents1DStrArr}
          tabTitles={this.getTabs()}
        />
      );

      const selectedTabContent = this.renderSelectedTabContent();

      const backPageID = piasu.getPageID(modVarObjList, pic.resultsDashboardFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.resultsDashboardFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {tabs}
          {selectedTabContent}

          {navBtnDiv}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIResultsDashboardForm;
