import { Component } from "react";

class PICapacityForm extends Component {
  render() {
    // TODO: Remove. Capacity no longer required.
    return false;
  }
}

export default PICapacityForm;
